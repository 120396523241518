var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStack",
    { attrs: { label: _vm.field.label, required: _vm.field.required } },
    [
      _c("VcFormSelect", {
        attrs: {
          options: _vm.field.options,
          value: _vm.field.value,
          prompt: _vm.field.placeholder,
          invalid: _vm.invalid,
          valueProp: _vm.valueProp,
          labelProp: _vm.labelProp,
          valueType: _vm.valueType
        },
        on: {
          change: function($event) {
            return _vm.$emit("change", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }