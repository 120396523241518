import SectionTypeComponents from "../../shared/wizard/constants/SectionTypeComponents";
import SectionTypes from "./SectionTypes";
import SectionContentServices from "../SectionContentServices.vue";
import SectionContentDate from "../SectionContentDate.vue";
import SectionContentAddons from "../SectionContentAddons.vue";

export default {
  ...SectionTypeComponents,
  [SectionTypes.Services]: SectionContentServices,
  [SectionTypes.Date]: SectionContentDate,
  [SectionTypes.Addons]: SectionContentAddons
};
