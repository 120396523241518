var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i3-filter__facets-view" },
    _vm._l(_vm.facets, function(facet) {
      return _c(
        "FacetItem",
        {
          key: facet.id,
          attrs: {
            facet: facet,
            iconRight: _vm.navigateToFacet,
            selected: _vm.expandedFacets.includes(facet.id)
          },
          on: {
            select: function($event) {
              return _vm.$emit("selectFacet", facet)
            }
          }
        },
        [
          !_vm.navigateToFacet && _vm.expandedFacets.includes(facet.id)
            ? _c(
                "div",
                { staticClass: "i3-filter__facet-children" },
                [
                  _c("FacetItemContent", {
                    attrs: { facet: facet },
                    on: {
                      selectFilter: function($event) {
                        return _vm.$emit("selectFilter", $event)
                      },
                      selectRangeFilter: function($event) {
                        return _vm.$emit("selectRangeFilter", $event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }