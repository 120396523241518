var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "i3-filter__filter form-group form-check",
      class: {
        "i3-filter__filter--selected": _vm.filter.selected,
        "form-check--lg": _vm.largeCheckboxes
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("select", _vm.filter)
        }
      }
    },
    [
      _c("input", {
        staticClass: "form-check__input",
        class: {
          "form-check__input--semi-checked":
            !_vm.filter.selected && _vm.hasSelectedChildren
        },
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.filter.selected }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "form-check__label" }, [
        _vm._v("\n    " + _vm._s(_vm.filter.displayName) + "\n\n    "),
        _c("span", { staticClass: "i3-filter__filter-count" }, [
          _vm._v("\n      (" + _vm._s(_vm.filter.count) + ")\n    ")
        ])
      ]),
      _vm._v(" "),
      (_vm.filter.children.length > 1 && _vm.filter.selected) ||
      _vm.filter.children.find(function(c) {
        return c.selected
      })
        ? _c(
            "div",
            { staticClass: "i3-filter__filter-children" },
            _vm._l(_vm.filter.children, function(child) {
              return _c("filter-item", {
                key: child.query,
                attrs: { filter: child },
                on: {
                  select: function($event) {
                    return _vm.$emit("select", child)
                  }
                }
              })
            }),
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }