import pageHeader from '../components/page-header';
import pageHeaderRollupProgress from '../components/page-header-rollup-progress';
import pageHeaderRollupFilter from '../components/page-header-rollup-filter';
import vehicleContactModal from "../apps/vehicle-contact-modal";
import mountApp from "../utils/mount-app";

export default {
  init() {
    pageHeader.init();
    pageHeaderRollupProgress.init();
    pageHeaderRollupFilter.init();
    this.modifyDOM();
  },

  modifyDOM() {
    this.mountVehicleContactModal();
  },

  mountVehicleContactModal() {
    mountApp({
      app: vehicleContactModal,
      selector: "[data-app=vehicle-contact-modal]",
    });
  },
};
