import Axios from "../../axios";
import gtm from "../../utils/gtm";
import ContactRequestTypes from "../../constants/ContactRequestTypes";
import ContactResponseTypes from "../../constants/ContactResponseTypes";
import { EventBus } from "../../utils/event-bus";
import FinanceOptions from '../../constants/FinanceOptions';

export default {
  state: {
    config: null,
    calculation: null,
    vehicle: null,
    financingConfig: null,
    financingSummary: null,
    tradeIn: null,
    contact: null,
    regNr: null,
    tyreData: null,
    tyres: [],
    needTyres: false,
    selectedFinancingOption: "",
    valuation: null,
    serviceAgreement: null,
    fittings: null,
    calculateFinanceOptions: null,
    serviceAgreementSummation: null,
    fittingOptions: [],
    displayTax: false,
    chargeBox: null,
    needChargeBox: false
  },

  async fetchConfigAction() {
    const { data } = await Axios.get(
      `/api/vehicleConfigurator/${this.state.regNr}`
    );
    this.setConfigAction(data);
    return this.state.config;
  },

  setConfigAction(data) {
    this.state.config = data?.configurator;
    this.state.contact = data?.contact;
    this.state.vehicle = data?.vehicle;
    this.state.financingConfig = data?.financing;
    this.state.financingSummary = {
      financing: data?.financing,
      vehicle: data?.financing
    };
    this.state.tradeIn = data?.tradeIn;
    this.state.serviceAgreement = data?.serviceAgreement;
    this.state.fittings = data?.fittings;
    this.state.tyreData = data?.tyres;
    this.state.calculation = data?.calculation;
    this.state.chargeBox = data?.chargeBox;
  },

  setTyresAction(tyres) {
    this.state.tyres = tyres;
  },

  setNeedTyres(need) {
    this.state.needTyres = need;
  },

  setNeedChargeBox(need) {
    this.state.needChargeBox = need;
  },

  setRegNrAction(regNr) {
    this.state.regNr = regNr;
  },

  alterTax(shouldDisplayTax) {
    this.state.displayTax = shouldDisplayTax;
  },

  trackSubmitAction(contactData) {
    gtm.configuratorSubmit({
      responseType:
        parseInt(contactData.requestType, 10) === ContactRequestTypes.Callup
          ? ContactResponseTypes.Phone
          : ContactResponseTypes.Email,
      requestType: contactData.requestType,
      departmentNr: contactData.departmentNr
    });
  },

  setselectedFinancingOption(financeOption) {
    this.state.selectedFinancingOption = financeOption;
  },

  setValuation(valuation) {
    this.state.valuation = valuation;
  },

  setServiceAgreement(serviceAgreement) {
    this.state.serviceAgreementSummation = serviceAgreement;
    this.calculate();
  },

  clearServiceAgreement() {
    this.state.serviceAgreementSummation = null;
    this.calculate();
  },

  setFittingOptions(fitting) {
    this.state.fittingOptions = fitting;
  },

  alterTyreOptions(tyre) {
    const alteredTyreOptions = this.state.tyres.map(tyreOption => {
      if (tyreOption.id === tyre.id) {
        return {
          ...tyreOption,
          selected: !tyreOption.selected
        };
      } else {
        return tyreOption;
      }
    });
    this.state.tyres = alteredTyreOptions;
    this.calculate();
  },

  async calculate(financingData) {
    if (financingData) {
      this.state.calculateFinanceOptions = financingData;
    }

    let addOns = [];
    let tyres = [];
    let serviceAgreement = {};

    if (this.state.serviceAgreementSummation) {
      const totalServiceAgreementCost = this.state.serviceAgreementSummation.additionalProducts
        .filter(product => product.selected)
        .map(product => product.monthlyCost)
        .reduce((total, num) => total + num, 0);

      serviceAgreement = {
        monthlyCost:
          this.state.serviceAgreementSummation.monthlyCost +
          totalServiceAgreementCost,
        selectedPeriod: this.state.serviceAgreementSummation.months
      };
    }

    if (this.state.config.displayTyres) {
      tyres = this.state.tyres.map(tyre => {
        return {
          currentPrice: tyre.financing["cash"].currentPrice,
          ordinaryPrice: tyre.financing["cash"].ordinaryPrice,
          id: tyre.id,
          selected: tyre.selected
        };
      });
    }
    const financingDataToBeCalculated =
      financingData || this.state.calculateFinanceOptions;

    let body = {
      addOns,
      financing: {
        ...financingDataToBeCalculated,
        financingTemplateId: this.state.financingConfig.financingTemplateId,
        selectedFinancingOption: this.state.selectedFinancingOption
      },
      tyres,
      serviceAgreement
    };
    const { data } = await Axios.post(
      `/api/vehicleConfigurator/${this.state.regNr}/calculate`,
      body
    );
    this.state.calculation = data;
    EventBus.$emit("set-vehicle-data", {
      contact: this.state.contact,
      vehicle: this.state.vehicle,
      data,
      selectedFinancingOption: this.state.selectedFinancingOption,
      regNr: this.state.regNr,
      isTransport: false,
      isPurchasable: this.state.contact.displayPurchase
    });
    this.state.financingSummary = data;
  },

  submitAction(contactData) {
    let financingData = {
      financingTemplateId: this.state.financingConfig.financingTemplateId,
      isNoVat: !this.state.displayTax
    };
    if (this.state.selectedFinancingOption !== FinanceOptions.CASH) {
      financingData = {
        ...financingData,
        ...this.state.calculateFinanceOptions
      };
    }

    const selectedFinancingObject = this.state.financingSummary.financing[
      this.state.selectedFinancingOption
    ];
    const financing = {
      totalCash: selectedFinancingObject.totalCash,
      totalMonthlyCost: selectedFinancingObject.totalMonthlyCost,
      totalOrdinaryCash: selectedFinancingObject.totalOrdinaryCash,
      totalOrdinaryMonthlyCost: selectedFinancingObject.totalOrdinaryMonthlyCost
    };

    let selectedTyres =
      this.state.tyres && this.state.tyres.length > 0
        ? this.state.tyres.filter(tyre => tyre.selected)
        : [];
    if (selectedTyres && selectedTyres.length == 0 && this.state.needTyres) {
      const needTyreData = {
        id: 0,
        heading: "Behöver vinterdäck",
        headingMeta: "",
        image: "",
        modalContent: "",
        selected: true,
        hidePrice: true,
        financing: {
          cash: {
            currentPrice: 0,
            currentPriceNoVat: 0,
            ordinaryPrice: 0,
            ordinaryPriceNoVat: 0,
            registrationFee: 0,
            registrationFeeNoVat: 0,
            totalMonthlyCost: 0,
            totalCash: 0,
            totalOrdinaryMonthlyCost: 0,
            totalOrdinaryCash: 0,
            totalMonthlyCostNoVat: 0,
            totalCashNoVat: 0,
            totalOrdinaryMonthlyCostNoVat: 0,
            totalOrdinaryCashNoVat: 0
          },
          installment: {
            monthlyCost: 0,
            ordinaryMonthlyCost: 0,
            downpayment: 0,
            ordinaryDownpayment: 0,
            residualValue: 0,
            period: 0,
            rate: 0,
            ordinaryRate: 0,
            effectiveRate: 0,
            arrangementFee: 0,
            registrationFee: 0,
            administrationFee: 0,
            totalMonthlyCost: 0,
            totalCash: 0,
            totalOrdinaryMonthlyCost: 0,
            totalOrdinaryCash: 0,
            totalMonthlyCostNoVat: 0,
            totalCashNoVat: 0,
            totalOrdinaryMonthlyCostNoVat: 0,
            totalOrdinaryCashNoVat: 0
          },
          leasing: {
            monthlyCost: 0,
            ordinaryMonthlyCost: 0,
            downpayment: 0,
            ordinaryDownpayment: 0,
            residualValue: 0,
            period: 0,
            rate: 0,
            ordinaryRate: 0,
            effectiveRate: 0,
            arrangementFee: 0,
            registrationFee: 0,
            administrationFee: 0,
            totalMonthlyCost: 0,
            totalCash: 0,
            totalOrdinaryMonthlyCost: 0,
            totalOrdinaryCash: 0,
            totalMonthlyCostNoVat: 0,
            totalCashNoVat: 0,
            totalOrdinaryMonthlyCostNoVat: 0,
            totalOrdinaryCashNoVat: 0
          }
        }
      };
      selectedTyres.push(needTyreData);
    }

    const selectedFittings = this.state.fittingOptions.filter(
      fitting => fitting.selected
    );

    const configuratorData = {
      vehicle: this.state.vehicle,
      tradeIn: this.state.valuation,
      fittings: selectedFittings,
      serviceAgreement: this.state.serviceAgreementSummation,
      addons: this.state.financingSummary.addOns,
      tyres: selectedTyres,
      chargeBox: this.state.needChargeBox,
      financingData,
      financing,
      selectedFinancing: this.state.selectedFinancingOption
    };
    const body = {
      contactData,
      currentPageUrl: window.location.toString(),
      registrationNumber: this.regNr,
      configuratorData
    };
    if (
      this.state.valuation &&
      this.state.valuation.registrationNumber &&
      this.state.valuation.registrationNumber.length > 0
    ) {
      gtm.configuratorQuickTradeIn();
    }
    return Axios.post(
      `/api/vehicleConfigurator/${this.state.regNr}/contact`,
      body
    );
  }
};
