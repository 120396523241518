<template>
  <div class="product-main-action__section">
    <div class="product-main-action__row">
      <div class="product-main-action__row__action-container" @click="$emit('onOpenRow', rowKey)">
        <div class="product-main-action__row__title">
          {{ rowTitle }}
        </div>
        <div class="product-main-action__row__value-container">
          <div v-if="isRowOpen" class="product-main-action__row__value product-main-action__row__value--static">
            Dölj
          </div>
          <div v-else>
            <slot name="row-value"></slot>
          </div>
          <font-awesome-icon
            class="product-main-action__row__arrow"
            :class="{
              'product-main-action__row__arrow--open': isRowOpen,
            }"
            :icon="faChevronDown"
          ></font-awesome-icon>
        </div>
      </div>
      <div v-if="isRowOpen" >
        <slot name="row-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    rowTitle: String,
    isRowOpen: Boolean,
    rowKey: String,
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faChevronDown,
    }
  }
}
</script>