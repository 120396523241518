import Breakpoints from "../constants/Breakpoints";
import Swiper from "../swiper";

const selectors = {
  mainSwiper: ".bloglist-page__filter .swiper-container",
};

export default {
  init() {
    this.initSwiper();
  },

  initSwiper() {
    if (this.swiper) {
      return;
    }

    this.swiper = new Swiper(selectors.mainSwiper, {
      spaceBetween: 8,
      slidesPerView: 'auto',
      noSwiping: true,

      breakpoints: {
        [Breakpoints.md - 2]: {
          slidesPerView: 'auto',
          spaceBetween: 8,
          noSwiping: false,
        },
      },
    });
  },
};
