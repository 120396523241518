import I3Channel from "./I3Channel";

export default {
  channels: [],

  createChannels(config) {
    const preference = config.userKey;
    this.channels = config.channels.map(channel => new I3Channel(channel, preference));
  },

  getByRef(ref) {
    return this.channels.find(c => c.ref === ref);
  },
};
