<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h4 class="u-fw-medium">Ange registreringsnummer</h4>
      </div>
      <div class="col-md-4">
        <div class="regnr-input">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="regnr-input__symbol"></div>
            </div>
            <VcFormStack label="Regnr">
              <input
                v-model="store.regNr"
                placeholder="ABC123"
                :class="{ 'form-control--invalid': store.lookupInvalid }"
                @keyup="changeRegNr()"
                type="text"
                class="form-control"
              />
            </VcFormStack>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div
          class="feedback-message feedback-message--slim feedback-message--success"
          v-if="store.lookupResult"
        >
          <div class="lookup-result">
            <div class="lookup-result__item">
              <label>
                Fordonsinfo
              </label>
              {{ store.lookupResult.carName }}
            </div>
          </div>
        </div>
        <div v-else class="u-c-muted">
          <small
            >Ange ditt registreringsnummer så letar vi upp ett serviceavtal som
            passar just ditt fordon.</small
          >
        </div>
      </div>
    </div>
    <div class="row u-margin-top" v-if="store.noPackages">
      <div class="col-md-12">
        <div
          class="feedback-message feedback-message--slim feedback-message--danger"
        >
          <div class="feedback-message__text">
            <small
              >Tyvärr kan vi inte beräkna ett pris på din bil just nu men fyll i
              formuläret och skicka in så kommer vi återkomma med ett
              prisförslag till dig.</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcFormStack } from "@holmgrensbil/vue-components";
import store from "./store";
export default {
  components: {
    VcFormStack
  },
  data() {
    return {
      store: store.state
    };
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('regNr')){
      this.store.regNr = urlParams.get('regNr');
      this.changeRegNr();
    }
    
  },
  methods: {
    async changeRegNr() {
      await store.lookupRegNr();
    }
  }
};
</script>
