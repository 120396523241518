var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-row__col",
      class: {
        "service-agreement-option":
          this.option.id === "signServiceAgreement" && this.option.selected
      }
    },
    [
      _c(
        "VcOptionBox",
        {
          attrs: {
            selected: _vm.option.selected,
            heading: _vm.option.heading,
            smallText: _vm.option.headingMeta,
            smallBlock:
              _vm.option.headingMeta && _vm.option.headingMeta.length > 15,
            checkbox: _vm.multiselect,
            image: _vm.option.image,
            attentionRibbon: _vm.ribbonText,
            largeImage: _vm.option.largeImage
          },
          on: { click: _vm.onClick, linkClick: _vm.onLinkClick }
        },
        [
          _vm.price && _vm.price.attentionText && !_vm.option.image
            ? _c(
                "div",
                {
                  staticClass: "price-tag__campaign",
                  attrs: { slot: "header-aside" },
                  slot: "header-aside"
                },
                [
                  _c("div", { staticClass: "price-tag__campaign__tag" }, [
                    _c("span", {
                      staticClass: "price-tag--discount",
                      domProps: { innerHTML: _vm._s(_vm.price.attentionText) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("VcPriceTag", {
                        attrs: { price: _vm.price, showIfZero: "" }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm.price
            ? _c(
                "div",
                { attrs: { slot: "header-aside" }, slot: "header-aside" },
                [
                  _c("VcPriceTag", {
                    attrs: { price: _vm.price, "show-if-zero": _vm.showIfZero }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.option.subOptionsText
            ? _c(
                "div",
                { attrs: { slot: "header-link" }, slot: "header-link" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.option.subOptionsText))]),
                  _vm._v(" "),
                  _c("FontAwesomeIcon", {
                    attrs: {
                      icon: _vm.expanded ? _vm.faAngleUp : _vm.faAngleDown
                    }
                  })
                ],
                1
              )
            : _vm.option.modalContent
            ? _c(
                "div",
                { attrs: { slot: "header-link" }, slot: "header-link" },
                [_c("span", [_vm._v("Läs mer")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayContent
            ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c(
                  "div",
                  { staticClass: "form-row" },
                  [
                    _c("ConfiguratorSubSelect", {
                      attrs: {
                        selectedValue: _vm.months,
                        option: _vm.monthsOptions
                      },
                      on: { change: _vm.setMonths }
                    }),
                    _vm._v(" "),
                    _c("ConfiguratorSubSelect", {
                      attrs: {
                        selectedValue: _vm.mileage,
                        option: _vm.mileageOptions
                      },
                      on: { change: _vm.setMileage }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn--primary wizard--service-booking__select-service-submit",
                        on: { click: _vm.getServiceAgreement }
                      },
                      [_vm._v("\n          Beräkna\n        ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.fetchedAgreement
                  ? _c("div", { staticClass: "form-row service-addons" }, [
                      _c("div", { staticClass: "main-info" }, [
                        _c("div", { staticClass: "main-info__heading" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.lang.Summation.ServiceAgreementOfferHeading
                              ) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.fetchedAgreement.price
                          ? _c("div", { staticClass: "main-info__data" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$formatPriceNoSuffix(
                                      _vm.fetchedAgreement.price.current
                                    ) + " kr/mån"
                                  ) +
                                  "\n          "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "main-info__meta" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.lang.Summation.ServiceAgreementOfferText
                              ) +
                              "\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "configurator-section__completed__sub-action"
                        },
                        [
                          _c("div", { staticClass: "main-info__heading" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.lang.Summation
                                    .ServiceAgreementAddonsHeading
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.fetchedAgreement.options, function(
                            additionalProduct
                          ) {
                            return _c(
                              "VcOptionBox",
                              {
                                key: additionalProduct.id,
                                attrs: {
                                  selected: _vm.addedAdditionalProducts.includes(
                                    additionalProduct.id
                                  ),
                                  heading: additionalProduct.heading,
                                  checkbox: true
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeAdditionalProduct(
                                      additionalProduct.id,
                                      additionalProduct
                                    )
                                  }
                                }
                              },
                              [
                                true
                                  ? _c(
                                      "div",
                                      {
                                        attrs: { slot: "header-meta" },
                                        slot: "header-meta"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "link link--underline link--muted",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.onLinkClick(
                                                  additionalProduct.heading,
                                                  additionalProduct.description
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.lang.SiteGeneral.ReadMore
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                additionalProduct.price
                                  ? _c(
                                      "div",
                                      {
                                        attrs: { slot: "header-aside" },
                                        slot: "header-aside"
                                      },
                                      [
                                        _c("VcPriceTag", {
                                          attrs: {
                                            price: additionalProduct.price,
                                            "show-if-zero": true
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.modalOpen
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.modalOpen = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("\n      " + _vm._s(_vm.option.heading) + "\n    ")
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.option.modalContent) },
                slot: "content"
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }