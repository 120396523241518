<template>
  <div class="quickbuy__actions-container">
    <div class="quickbuy__actions">
      
      <a
        v-if="product.available"
        :href="product.permalink"
        class="btn btn--outline offset"
        @click.prevent="onMoreInfoClick"
      >
        Läs mer
      </a>
      <a
        class="btn btn--primary"
        @click.prevent="onContactClick"
      >
        Ta kontakt
      </a>

      <VcModal v-if="modalOpen" @close="modalOpen = false">
        <div slot="header">
          Ta kontakt
        </div>
        <div slot="content" v-html="modalContent"></div>
      </VcModal>
    </div>
  </div>
</template>

<script>
import { VcModal } from "@holmgrensbil/vue-components";
import { EventBus } from "../../utils/event-bus";
import gtm from "../../utils/gtm";

export default {
  props: {
    product: Object
  },
  data() {
    return {
      modalOpen: false,
      modalContent: null
    };
  },
  components: {
    VcModal
  },
  methods: {
    onContactClick() {
      EventBus.$emit('open-vehicle-contact-modal', { regNr: this.product.regNr, productUrl: this.product.permalink });

      const {
        departmentNr,
        carStatus,
        vehicleCondition,
        brandName,
        vehicleType
      } = this.product;
      const label = this.contactLabel;

      gtm.vehicleContactClick({
        departmentNr,
        carStatus,
        label,
        vehicleCondition,
        brandName,
        vehicleType
      });
    },

    onMoreInfoClick() {
      gtm.vehicleMoreInfoClick({
        departmentNr: this.product.departmentNr,
        vehicleCondition: this.product.vehicleCondition,
        brandName: this.product.brandName,
        vehicleType: this.product.vehicleType
      });
      window.location.href = this.product.permalink;
    }
  }
};
</script>
