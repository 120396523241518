var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isRange
    ? _c("RangeFilterItem", {
        attrs: { facet: _vm.facet },
        on: {
          select: function($event) {
            return _vm.$emit("selectRangeFilter", $event)
          }
        }
      })
    : _vm.isColor
    ? _c(
        "div",
        _vm._l(_vm.facet.filters, function(filter) {
          return _c("ColorFilterItem", {
            key: filter.query,
            attrs: { filter: filter },
            on: {
              select: function($event) {
                return _vm.$emit("selectFilter", $event)
              }
            }
          })
        }),
        1
      )
    : _c(
        "div",
        _vm._l(_vm.facet.filters, function(filter) {
          return _c("FilterItem", {
            key: filter.query,
            attrs: { filter: filter },
            on: {
              select: function($event) {
                return _vm.$emit("selectFilter", $event)
              }
            }
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }