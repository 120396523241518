import Swiper from "../swiper";
import Breakpoints from "../constants/Breakpoints";

export default {
  init() {
    if (!$(".campaigngridblock").length) {
      return;
    }

    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {},

  modifyDOM() {
    this.initSwiper();
  },

  initSwiper() {
    this.swiper = new Swiper(".campaigngridblock .swiper-container", {
      autoHeight: true,
      slidesPerView: "auto",
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets"
      }
    });
  }
};
