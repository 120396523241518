var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.gridItems.length
    ? _c(
        "div",
        [
          _c(
            "div",
            { ref: "grid", staticClass: "campaign-list__items" },
            _vm._l(_vm.gridItems, function(gridItem) {
              return _c("GridItem", {
                key: "" + gridItem.type + gridItem.document._id,
                attrs: { type: gridItem.type, document: gridItem.document }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("VcIntersectObserver", {
            attrs: { options: { rootMargin: "200px" } },
            on: { intersect: _vm.onIntersect }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }