<template>
  <a :href="page.permalink">
    <div class="search-response-link">
      <div class="u-c-muted search-response-link__container">
        <span v-if="ancestors" class="search-response-link__route">
          {{ ancestors + ' /' }}
        </span>
        <span class="search-response-link__route search-response-link__route--primary">
          {{ heading }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
import I3FederatedSearchComponents from "../../constants/I3FederatedSearchComponents";
import I3FederatedSearchTypes from "../../constants/I3FederatedSearchTypes";

export default {
  props: {
    document: Object,
    resultType: String,
  },

  computed: {
    page() {
      return new I3FederatedSearchComponents[this.resultType](
        this.document
      );
    },
    ancestors() {
      switch (this.resultType) {
        case I3FederatedSearchTypes.Episerver: return this.page.ancestors && this.page.ancestors.length > 0 ? this.page.ancestors.join(' / ') : '';
        case I3FederatedSearchTypes.Employees: return '';
        case I3FederatedSearchTypes.FacetPages: return '';
        //case I3FederatedSearchTypes.Departments: return `${this.page.location} / ${this.page.serviceTag}`;
        default: return '';
      }
    },
    heading() {
      switch (this.resultType) {
        case I3FederatedSearchTypes.Episerver: return this.page.heading;
        case I3FederatedSearchTypes.FacetPages: return this.page.name;
        case I3FederatedSearchTypes.Employees: return `${this.page.firstName} ${this.page.lastName} ${this.page.title} ${this.page.location}`
        //case I3FederatedSearchTypes.Departments: return this.page.name;
        default: return this.page.heading;
      }
    }
  }
};
</script>
