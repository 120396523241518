var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onPurchase($event)
        }
      }
    },
    [
      _c("h4", { staticClass: "configurator-section__title" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.lang.Summation.ContactRequestHeader) + "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "configurator-app__contact-section" },
        _vm._l(_vm.requestTypeOptions, function(option) {
          return _c(
            "VcOptionBox",
            {
              key: option.name,
              class: {
                "selected-contact-option": option.value === _vm.requestType
              },
              attrs: {
                selected: option.value === _vm.requestType,
                heading: option.name
              },
              on: {
                click: function($event) {
                  return _vm.onRequestTypeChange(option.value)
                }
              }
            },
            [
              option.value === _vm.requestType &&
              option.value !== _vm.ContactRequestTypes.Purchase
                ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm.done
                      ? _c(
                          "div",
                          [
                            _c(
                              "VcFeedbackMessage",
                              { attrs: { success: "" } },
                              [
                                _c(
                                  "div",
                                  { attrs: { slot: "header" }, slot: "header" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.lang.Summation.FeedbackHeader
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.lang.Summation.FeedbackText) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _vm.requestType ===
                            _vm.ContactRequestTypes.AskQuestion
                              ? _c(
                                  "VcFormStack",
                                  {
                                    attrs: {
                                      required: "",
                                      label: "Vad har du för fråga?"
                                    }
                                  },
                                  [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.message,
                                          expression: "message"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      domProps: { value: _vm.message },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.message = $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.requestType ===
                              _vm.ContactRequestTypes.BookMeeting ||
                            _vm.requestType ===
                              _vm.ContactRequestTypes.TestDrive
                              ? _c(
                                  "VcFormStack",
                                  { attrs: { label: "Datum", required: "" } },
                                  [
                                    _c(
                                      "VcDatePicker",
                                      {
                                        attrs: {
                                          value: _vm.date,
                                          placeholder: "Välj en dag"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onDateChange(
                                              $event.target.value
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fal fa-calendar-alt",
                                          attrs: { slot: "icon" },
                                          slot: "icon"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v("\n              Namn\n            ")
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.name,
                                      expression: "name"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    autocomplete: "name",
                                    placeholder: "Vad heter du?",
                                    type: "text",
                                    required: ""
                                  },
                                  domProps: { value: _vm.name },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.name = $event.target.value
                                    }
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-row__col form-row__col--md-50"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-stack form-stack--required"
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                  Telefonnummer\n                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.phone,
                                            expression: "phone"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          autocomplete: "tel",
                                          placeholder:
                                            "Ange ditt telefonnummer",
                                          type: "tel",
                                          required: ""
                                        },
                                        domProps: { value: _vm.phone },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.phone = $event.target.value
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-row__col form-row__col--md-50"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-stack form-stack--required"
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                  E-post\n                "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.email,
                                            expression: "email"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          autocomplete: "email",
                                          placeholder: "Ange din e-postadress",
                                          type: "email",
                                          required: ""
                                        },
                                        domProps: { value: _vm.email },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.email = $event.target.value
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.isTransport
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "configurator-app__contact-section__regnr"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "configurator-app__contact-section__regnr__title"
                                      },
                                      [
                                        _vm._v(
                                          "\n              Har du ett fordon som du vill byta in?\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "configurator-app__contact-section__regnr__text"
                                      },
                                      [
                                        _vm._v(
                                          "\n              Vi återkommer med en värdering på ditt fordon.\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "regnr-input" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input-group-prepend"
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "regnr-input__symbol"
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "form-stack" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  "\n                    Regnummer\n                  "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.regNr,
                                                    expression: "regNr"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "ABC123",
                                                  pattern:
                                                    "^\\w{3}\\d{2}(\\w{1}|\\d{1})$"
                                                },
                                                domProps: { value: _vm.regNr },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.regNr =
                                                      $event.target.value
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--primary btn--block",
                                class: { disabled: _vm.loading },
                                on: { click: _vm.onSubmit }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.lang.Summation.SubmitButtonText
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "form-text",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.lang.General.PrivacyPolicyText
                                )
                              }
                            })
                          ],
                          1
                        )
                  ])
                : option.value === _vm.requestType &&
                  option.value === _vm.ContactRequestTypes.Purchase &&
                  _vm.displayPurchase
                ? _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    [
                      _c("ConfigSummationFooter", {
                        attrs: {
                          requestType: _vm.ContactRequestTypes.Purchase,
                          purchaseInformationText:
                            _vm.contact.purchaseInformation,
                          lang: _vm.lang,
                          submitState: _vm.submitState,
                          customActionLabel: _vm.lang.Summation.PurchaseAction
                        },
                        on: { submit: _vm.onPurchase }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }