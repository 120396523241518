import EonProductTypes from "./EonProductTypes";
import SectionTypes from "./SectionTypes";

export default {
  [EonProductTypes.CompleteWheel]: [
    SectionTypes.Diameter,
  ],
  [EonProductTypes.Rim]: [
    SectionTypes.Diameter,
    SectionTypes.Condition,
    SectionTypes.Season,
    SectionTypes.TyreProperties,
  ],
  [EonProductTypes.Tyre]: [
    SectionTypes.Condition,
    SectionTypes.Diameter,
    SectionTypes.RimProperties,
  ],
  [EonProductTypes.TyreBuild]: [],
}
