const state = {
  items: [],
};

const getters = {
  byType: ({ items }) => type => {
    return items.find(item => item.type === type);
  },

  parents({ items }) {
    return items.filter(item => !item.parent);
  },

  children({ items }) {
    return items.filter(item => item.parent);
  },

  childrenByParent: (_, getters) => parent => {
    return getters.children.filter(item => item.parent === parent);
  },
};

const actions = {
  load({ commit }, items) {
    commit('received', items);
  },

  set({ commit }, item) {
    commit('set', item);
  },
};

const mutations = {
  received(state, items) {
    state.items = items;
  },

  set(state, { type, ...props }) {
    state.items = state.items.map(item => {
      return (item.type === type) ? {
        ...item,
        ...props,
      } : item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
