<template>
  <div class="configurator-app__summation-footer">
    <div v-if="done">
      <VcFeedbackMessage success>
        <div slot="header">
          {{lang.Summation.FeedbackHeader}}
        </div>
        {{lang.Summation.FeedbackText}}
      </VcFeedbackMessage>
    </div>
    <div v-else>
      <div v-if="isPurchasing" v-html="purchaseInformationText" class="configurator-app__summation-footer-info">
      </div>
      <button
        :class="{'disabled': loading}"
        type="submit"
        class="btn btn--primary btn--block">
        {{actionLabel}}
      </button>
      <div class="form-text" v-if="!isPurchasing" v-html="lang.General.PrivacyPolicyText">
      </div>
    </div>
  </div>
</template>

<script>
import { VcFeedbackMessage } from '@holmgrensbil/vue-components';
import RequestStates from '../../../constants/RequestStates';
import ContactRequestTypes from '../../../constants/ContactRequestTypes';

export default {
  components: {
    VcFeedbackMessage,
  },
  computed: {
    done() {
      return this.submitState === RequestStates.Success;
    },

    loading() {
      return this.submitState === RequestStates.Request;
    },

    isPurchasing() {
      return this.requestType === ContactRequestTypes.Purchase;
    },
    actionLabel() {
      if (this.customActionLabel) {
        return this.customActionLabel;
      } else {
        return this.isPurchasing ? this.lang.Summation.SubmitPurchaseButtonText : this.lang.Summation.SubmitButtonText
      }
    }
  },
  props: {
    requestType: Number,
    submitState: Number,
    lang: Object,
    purchaseInformationText: String,
    customActionLabel: String,
  },
}
</script>
