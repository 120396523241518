<template>
  <div class="eon-product-spot">
    <div class="eon-product-spot__desc">
      <div class="eon-product-spot__info">
        <div class="eon-product-spot__meta">
          <span class="mark mark--black mark--sm mark--uppercase">{{product.shortConditionType}}</span>
          {{product.productTypeDisplayName}} {{product.hasTpms ? 'med TPMS' : ''}}

        </div>
        <EonProductTitle :product="product" @onClick="onClick">
        </EonProductTitle>
      </div>
      <div class="eon-product-spot__image" @click="onClick">
        <img :src="product.imageThumbnailUrl" />
      </div>
    </div>
    <div class="eon-product-spot__usps" :class="{'eon-product-spot__usps--empty': !product.usps.length}">
      <div v-for="usp in product.usps"
        :key="usp.icon"
        :data-ttjs-title="usp.text"
        class="eon-product-spot__usps__item ttjs">
        <i :class="usp.icon"></i>
        <span>{{usp.label}}</span>
      </div>
    </div>

    <div class="eon-product-spot__buy">
      <div class="eon-product-spot__price">
        <div class="eon-product-spot__price__section">
          <div class="eon-product-spot__price__details">
            <div class="price-tag" :class="{'discount': product.discounted(currentQuantity)}">
              {{$formatPrice(product.totalPriceWithVat(currentQuantity))}} / st
            </div>
            <div v-if="product.discounted(currentQuantity) && product.discount <= 100" class="product-tag red">
              {{ product.discount }}% rabatt
            </div>
            <div v-else-if="product.discounted(currentQuantity) && product.discount > 100" class="product-tag red">
              {{ product.discount * currentQuantity }}kr rabatt
            </div>
            
          </div>
          <div class="eon-product-spot__price__meta">
            <span class="eon-product-spot__price--obsolete" v-if="product.discounted(currentQuantity)">{{$formatPrice(product.totalOrdinaryPriceWithVat)}} / st<br></span>
            Avbetalning från {{$formatPrice(product.lowestPartPaymentPrice(currentQuantity))}}/mån <br>
            <DeliveryStatusCircle :deliveryStatus="deliveryStatus" small></DeliveryStatusCircle> {{deliveryStatusLabel}}
          </div>
        </div>
      </div>

      <EonProductBuyActions
        :product="product"
        :regNr="regNr"
        :isBuildWheel="isBuildWheel"
        :selectedProductType="selectedProductType"
        :selectedProduct="selectedProduct"
        @quantity="onQuantity"
        @added="onAdded">
      </EonProductBuyActions>
    </div>

    <portal to="eon-product-services-modal">
      <EonProductServicesModal v-if="servicesModalOpen"
        :product="product"
        :addedQuantity="addedQuantity"
        @close="servicesModalOpen = false">
      </EonProductServicesModal>
    </portal>
  </div>
</template>

<script>
import EonProductTitle from './EonProductTitle.vue';
import EonProductBuyActions from './EonProductBuyActions.vue';
import EonProductServicesModal from './EonProductServicesModal.vue';
import LineItemDeliveryStatusLabels from '../../../constants/LineItemDeliveryStatusLabels';
import DeliveryStatusCircle from './DeliveryStatusCircle.vue';
import LineItemDeliveryStatuses from '../../../constants/LineItemDeliveryStatuses';
import EonProductTypeToGtmList from '../../../constants/EonProductTypeToGtmList';
import gtm from '../../../utils/gtm';
import GtmEvents from '../../../constants/GtmEvents';

export default {
  props: {
    product: Object,
    regNr: String,
    selectedProductType: String,
    isBuildWheel: Boolean,
    selectedProduct: Object,
  },
  components: {
    EonProductTitle,
    EonProductBuyActions,
    EonProductServicesModal,
    DeliveryStatusCircle,
  },
  data() {
    return {
      servicesModalOpen: false,
      addedQuantity: null,
      currentQuantity: null,
    };
  },
  computed: {
    deliveryStatus() {
      if (this.currentQuantity) {
        return this.product.getDeliveryStatus(this.currentQuantity);
      }

      return LineItemDeliveryStatuses.None;
    },

    deliveryStatusLabel() {
      return LineItemDeliveryStatusLabels[this.deliveryStatus];
    }
  },
  methods: {
    onQuantity({ value }) {
      this.currentQuantity = value;
    },

    onAdded(data) {
      this.addedQuantity = data.quantity;
      this.servicesModalOpen = true;
      const gtmData = {
        currencyCode: 'SEK',
        value: this.addedQuantity * this.product.tireData.price,
        add: {
          products: [{...{quantity: this.addedQuantity}, ...this.product.tireData}]
        }
      }
      gtm.ecommerceEvent(GtmEvents.AddToCart, gtmData);
    },
    onClick() {
      const data = {
        'click': {
          'actionField': {
            'list': EonProductTypeToGtmList[this.selectedProductType],
          },
          'products': [this.product.tireData]
        },
      }
      const callback = () => {
        document.location = this.product.permalink
      }
      gtm.tireCardEvent(GtmEvents.ProductClick, data, callback)
    }
  },
}
</script>
