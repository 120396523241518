const state = {
  options: [],
  title: null,
  selected: null,
};

const getters = {};

const actions = {
  load({ commit }, data) {
    commit('received', data);
  },

  select({ commit }, item) {
    commit('selected', item);
  },

  deselect({ commit, state }) {
    if (state.selected) {
      commit('deselected');
    }
  },
};

const mutations = {
  received(state, data) {
    state.options = data.options;
    state.title = data.title;
  },

  selected(state, item) {
    state.selected = item;
  },

  deselected(state) {
    state.selected = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
