<template>
  <div
    class="configurator-text-input"
    :class="{
      'configurator-text-input--textarea': isLongText,
    }"
  >
    <div class="configurator-text-input__image-container">
      <img v-if="useRegNrIcon" :src="'/Static/images/bigregnr@2x.png'" class="configurator-text-input__regnr-image" />
    </div>
    <textarea
      v-if="isLongText"
      class="configurator-text-input__input"
      :name="inputKey"
      v-model="input"
      :placeholder="placeholder"
    ></textarea>
    <input
      v-else
      :type="type || 'text'"
      :name="inputKey"
      v-model="input"
      :placeholder="placeholder"
      :autocomplete="type"
      class="configurator-text-input__input"
      :class="{
        'configurator-text-input__input--regnr-input': useRegNrIcon,
      }"
    />
  </div>
</template>
<script>
export default {
  props: {
    inputKey: String,
    placeholder: String,
    useRegNrIcon: Boolean,
    isLongText: Boolean,
    type: String,
  },
  data() {
    return {
      input: '',
    }
  },
  watch: {
    input() {
      this.$emit('onChange', this.input);
    }
  }
}
</script>
