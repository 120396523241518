import Axios from '../axios';
import gtm from '../utils/gtm';

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $('body').
      on('submit', '.vehicle-contact form', this.onSubmit.bind(this)).
      on('click', '.js-purchase-button', this.onPurchaseClick.bind(this)).
      on('change', '.vehicle-contact [name="requestType"]', this.onRequestTypeChange.bind(this)).
      on('change', '.vehicle-contact .option-box .option-box__header [type="radio"]', this.onOptionBoxChange.bind(this));
  },

  modifyDOM() {
  },

  onSubmit({ currentTarget }) {
    const $el = $(currentTarget);
    const $submitBtn = $el.find('[type="submit"]');
    const $feedback = $el.find('.feedback-message');

    const data = {
      currentPageUrl: window.location.toString(),
    };

    $el.serializeArray().forEach(param => {

      if(param.value && param.value.length) {
        data[param.name] = param.value;
      }
    });

    $submitBtn.addClass('disabled');

    Axios.post('/api/leads/ContactForm', data).then(() => {
      $submitBtn.removeClass('disabled');
      $submitBtn.hide();
      $feedback.show();
    }).catch(() => {
      $submitBtn.removeClass('disabled');
    });

    gtm.vehicleContactSubmit({
      departmentNr: data.departmentNr,
      requestType: data.requestType,
      responseType: data.responseType,
      vehicleCondition: data.vehicleCondition,
      brandName: data.brandName,
      vehicleType: data.vehicleType
    });

    return false;
  },

  onPurchaseClick({ currentTarget }) {
    const $form = $(currentTarget).parent('.vehicle-contact__form');
    const data = {
      currentPageUrl: window.location.toString(),
    };

    $form.serializeArray().forEach(param => {

      if(param.value && param.value.length) {
        data[param.name] = param.value;
      }
    });

    gtm.vehiclePurchaseSubmit({
      departmentNr: data.departmentNr,
      requestType: data.requestType,
      vehicleCondition: data.vehicleCondition,
      brandName: data.brandName,
      vehicleType: data.vehicleType
    });
  },

  onOptionBoxChange({ currentTarget }) {
    const $target = $(currentTarget);
    const $optionBox = $target.closest('.option-box');
    $optionBox.siblings('.option-box').find('.option-box__content').each(function() {
      $(this).find('input').attr('required', false);
      $(this).hide();
    });
    if($target.prop('checked')) {
      $optionBox.find('.option-box__content').show();
      $optionBox.find('.option-box__content input').attr('required', true);
    } else {
      $optionBox.find('.option-box__content').hide();
      $optionBox.find('.option-box__content input').attr('required', false);
    }
  },

  onRequestTypeChange({ currentTarget }) {
    const val = $(currentTarget).val();
    if (val === '1'){
      $('.js-contact__form').hide();
    } else {
      $('.js-contact__form').show();
    }
    $('[data-request-type]').hide();
    if(val && val.length) {
      $('[data-request-type]').each(function() {
        let requestType = $(this).data('request-type').toString();
        
        if(requestType.indexOf(val) !== -1) {
          $(this).show();
        }
      });
    }
  },
}
