<template>
  <div>
    <label v-if="field.label">
      {{field.label}}
    </label>
    <div v-for="option in field.options"
      :key="option.value"
      class="form-check form-check--sm form-check--inline"
      @click.stop="onChange(option)">
      <input
        :checked="field.value === option.value"
        class="form-check__input"
        type="radio"
      />
      <div class="form-check__label">
        {{option.name}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
  },
  methods: {
    onChange(option) {
      let value = option.value;

      if (this.field.value === option.value) {
        if (this.field.required) {
          return;
        }

        value = null;
      }

      this.$emit('change', value);
    },
  },
}
</script>
