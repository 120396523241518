var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "checkout-section",
      class: {
        "checkout-section--arrow": _vm.arrow,
        "checkout-section--summary": _vm.$slots["summary"]
      }
    },
    [
      _vm.$slots["header"]
        ? _c(
            "h3",
            { staticClass: "checkout-section__header" },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots["content"]
        ? _c(
            "div",
            { staticClass: "checkout-section__content" },
            [_vm._t("content")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots["summary"]
        ? _c(
            "div",
            { staticClass: "checkout-section__summary" },
            [_vm._t("summary")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }