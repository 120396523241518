import debounce from 'debounce';
import { getPageYOffset } from '../utils/scrolling';

const classes = {
  active: 'scrolled',
  activeDown: 'scrolled--down',
  activeUp: 'scrolled--up',
  timeoutDown: 'scrolled--down-timeout'
};

export default {
  init() {
    this.lastScrollTop = undefined;
    this.headerHeight = $('.site-header__main').offset().top;
    this.alert = $('.site-alerts');
    this.$body = $('body');
    this.addEvents();
  },

  addEvents() {
    window.addEventListener('scroll', debounce(this.onScroll.bind(this), 1));
  },

  onScroll() {
    const scrollTop = getPageYOffset();
    const alertHeight = $('body').hasClass('has-site-alerts') || $('body').hasClass('has-large-site-alerts') ? this.alert.outerHeight() : 0;

    if (this.$body.hasClass('overlay-open')) {
      return;
    }

    const breakpoint = this.headerHeight + alertHeight

    if (scrollTop <= breakpoint) {
      this.clear();
    } else if (!this.lastScrollTop && scrollTop > breakpoint) {
      this.setDown(this.$body.hasClass(classes.activeUp));
    } else if (this.lastScrollTop > scrollTop) {
      this.setUp();
    } else if (this.lastScrollTop < scrollTop && scrollTop > breakpoint) {
      this.setDown(this.$body.hasClass(classes.activeUp));
    }

    this.lastScrollTop = scrollTop;
  },

  clear() {
    this.$body.removeClass(`${classes.active} ${classes.activeDown} ${classes.activeUp}`);
  },

  setDown(addTimeoutClass = false) {
    this.$body.removeClass(classes.activeUp);
    this.$body.addClass(`${classes.active} ${classes.activeDown} ${addTimeoutClass ? classes.timeoutDown : ''}`);
    if(addTimeoutClass) {
      setTimeout(() => {
        this.$body.removeClass(classes.timeoutDown);
      }, 500);
    }

  },

  setUp() {
    const shouldBlockScrollTop = sessionStorage.getItem('blockScrollTop');

    if (!shouldBlockScrollTop) {
      this.$body.removeClass(classes.activeDown);
      this.$body.addClass(`${classes.active} ${classes.activeUp}`);
    }
  },
}
