<template>
  <ul
    class="list-styled list-styled--sm list-styled--inline list-styled--checklist list-styled--checklist--success"
  >
    <li v-for="(field, index) in definedFields" :key="index">
      {{ getString(field) }}
    </li>
  </ul>
</template>

<script>
import isDefined from "../../../utils/is-defined";
import InputTypes from "./constants/InputTypes";

const hideValueFrom = [InputTypes.Image, InputTypes.Textarea];

const hideLabelFrom = [InputTypes.OptionBoxes, InputTypes.OptionBoxesMulti];

export default {
  props: {
    fields: Array
  },
  computed: {
    definedFields() {
      return this.fields.filter(field => isDefined(field.value));
    }
  },
  methods: {
    getString(field) {
      const label = hideLabelFrom.includes(field.inputType)
        ? ""
        : this.getLabel(field);
      const value = hideValueFrom.includes(field.inputType)
        ? ""
        : this.getValue(field);
      const separator = label && value ? ": " : "";

      return `${label}${separator}${value}`;
    },

    getLabel(field) {
      return field.shortLabel || field.label;
    },

    getValue(field) {
      if (
        (field.inputType === InputTypes.OptionBoxes ||
          field.inputType === InputTypes.OptionBoxesMulti) &&
        field.nestedOption
      ) {
        const headings = [];
        if (field.value) {
          field.value.forEach(v => {
            field.options.forEach(fv => {
              fv.options.forEach(nestedValue => {
                if (v === nestedValue.id) {
                  headings.push(nestedValue.heading);
                }
              });
            });
          });
          return headings.join(", ");
        }
      } else if (
        field.inputType === InputTypes.OptionBoxes ||
        field.inputType === InputTypes.OptionBoxesMulti
      ) {
        return field.value
          ? field.value
              .map(v => field.options.find(fo => fo.name === v.name).heading)
              .join(", ")
          : "";
      } else if (
        field.inputType === InputTypes.SelectObject ||
        field.inputType === InputTypes.SelectObjectNumber
      ) {
        return field.value ? field.value.name : "";
      } else if (field.inputType === InputTypes.Boolean) {
        return field.value ? "Ja" : "Nej";
      } else if (Array.isArray(field.value)) {
        return field.value.length;
      } else if (
        field.inputType === InputTypes.Time &&
        field.value === "00:00"
      ) {
        let timeOption = field.options.find(option =>
          option.times.some(time => time.start === "00:00")
        );
        let returnValue = timeOption
          ? timeOption.times[0].name
          : "Lämna kl 07:00, hämta 16:00";
        return returnValue;
      } else {
        return field.value;
      }
    }
  }
};
</script>
