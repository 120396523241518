<template>
  <VcModal @close="$emit('close')" small fixed>
    <div slot="header">
      Tillagd i varukorg
    </div>
    <div slot="content" class="eon-product-services-modal">
      <VcFeedbackMessage success slim>
        <i class="fas fa-check"></i>
        {{product.name}} tillagd i varukorgen!
      </VcFeedbackMessage>

      <VcFeedbackMessage success slim v-for="addedService in previouslyAddedServices" :key="addedService.id">
        <i class="fas fa-check"></i>
        {{addedService.name}} tillagd i varukorgen!
      </VcFeedbackMessage>

      <VcFeedbackMessage success slim v-for="addedService in addedServices" :key="addedService.id">
        <i class="fas fa-check"></i>
        {{addedService.name}} tillagd i varukorgen!
      </VcFeedbackMessage>

      <label v-if="servicesLeftToAdd">
        Vill du lägga till ytterligare tjänster eller produkter?
      </label>

      <EonProductServiceSpot v-for="service in services"
        :key="service.id"
        :service="service"
        :isAdded="Boolean(addedServices.find(s => s.id === service.id))"
        @add="onAdd(service)">
      </EonProductServiceSpot>
    </div>
    <div class="eon-product-services-modal__actions" slot="footer">
      <a class="btn btn--white" @click="$emit('close')">
        Fortsätt handla
      </a>
      <a :href="checkoutUrl" class="btn btn--primary btn--block">
        Till kassan
      </a>
    </div>
  </VcModal>
</template>

<script>
import { VcModal, VcFeedbackMessage } from '@holmgrensbil/vue-components';
import EonProductServiceSpot from './EonProductServiceSpot.vue';
import CartApi from '../../../models/CartApi';
import ProductsApi from '../../../models/ProductsApi';
import Axios from '../../../axios';
import LineItemProductTypes from '../../../constants/LineItemProductTypes';
import GtmEvents from '../../../constants/GtmEvents';
import gtm from '../../../utils/gtm';

export default {
  components: {
    VcModal,
    VcFeedbackMessage,
    EonProductServiceSpot,
  },
  props: {
    product: Object,
    addedQuantity: Number,
    previouslyAddedServices: Array,
  },
  mounted() {
    this.onMounted();
  },
  data() {
    return {
      services: [],
      addedServices: [],
      checkoutUrl: window.EonConfig.checkoutUrl,
    };
  },

  computed: {
    servicesLeftToAdd() {
      return this.addedServices.length !== this.services.length;
    },
  },
  methods: {
    async onMounted() {
      try {
        const params = {
          productId: this.product.encodedId,
          quantity: this.addedQuantity,
        };
        const res = await ProductsApi.fetchAdditionalServices(params);
        const previouslyAddedServices = this.previouslyAddedServices || [];
        let services = res.data;
        res.data.forEach((s, i) => {
          s.subOptions.forEach(so => {
            services = [...services.slice(0, i + 1), so, ...services.slice(i + 1)];
          });
        });
        services = services.filter(s => !Boolean(previouslyAddedServices.find(ps => ps.id === s.id)));
        this.services = this.getUniqueServices(services);
      } catch(e) {
        console.log("failed", e);
      }
    },

    getUniqueServices(arr, comp = 'id') {

      const unique = arr
        .map(e => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);

      return unique;
    },

    async onAdd(service) {
      try {
        let data = {
          productId: service.id,
          parentProductId: this.product.id,
        };
        if(parseInt(service.productType, 10) === LineItemProductTypes.CartUpsell) {
          data.isUpsellProduct = true;
          data.quantity = this.addedQuantity;
          delete data.parentProductId;
        }
        const res = await CartApi.addItem(data);
        this.addedServices.push(service);
        const gtmData = {
          currencyCode: 'SEK',
          value: service.price.current * this.addedQuantity,
          add: {
            products: [{
              name: service.name,
              id: service.id,
              price: service.price.current,
              category: 'Tilläggstjänst',
            }]
          }
        }
        gtm.ecommerceEvent(GtmEvents.AddToCart, gtmData);
      } catch (e) {
        console.log("err", e);
      }
    },
  },
}
</script>
