import pageHeader from "../components/page-header";
import pageHeaderI3Filter from "../components/page-header-i3-filter";
import pageHeaderI3Sort from "../components/page-header-i3-sort";

export default {
  init() {
    pageHeader.init();
    pageHeaderI3Filter.init();
    pageHeaderI3Sort.init();

    this.scrollObserver();
  },

  scrollObserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let topObserver = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
          $("#floating-filter").removeClass("u-d-none");
        } else {
          $("#floating-filter").addClass("u-d-none");
        }
      });

      let footerObserver = new IntersectionObserver(entries => {
        if (entries[0].intersectionRatio !== 0) {
          $("#floating-filter").addClass("u-d-none");
        } else {
          $("#floating-filter").removeClass("u-d-none");
        }
      });

      footerObserver.observe(document.querySelector("#site-footer"));
      topObserver.observe(document.querySelector("#vehicle-list-pixel"));
    }
  }
};
