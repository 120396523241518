import SparePartsModal from '../apps/spare-parts-modal';
import mountApp from '../utils/mount-app';
export default {
  init() {
    this.departmentNr = null;
    this.appSelector = '[data-app=spare-parts-modal]'
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $(document).on('shown.wdk.modal', '.modal', this.onMount.bind(this));
    $('[data-modal-source="spare-parts"]').on('click', this.onClick.bind(this));
  },

  modifyDOM() {
  },
  onMount() {
    mountApp({
      app: SparePartsModal,
      selector: this.appSelector,
      props: {
        departmentNr: this.departmentNr,
      },
    });
  },

  onClick({ currentTarget }) {
    this.departmentNr = $(currentTarget).data('department-nr');
    const $modal = $(currentTarget).closest('.card').find('.modal');
    $modal.modal('show');
  },
};
