import Axios from '../../../../axios';
import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../../constants/FieldTypes';
import optionsToFieldValue from '../../../shared/wizard/util/options-to-field-value';

const state = {
  items: [],
  message: null,
  fetchState: null,
};

const getters = {};

const actions = {
  async fetch({ commit, dispatch, state, rootGetters }, { regNr, regDate, mileage }) {
    commit('fetchRequest');

    try {
      const data = {
        registrationNumber: regNr,
        registrationDate: regDate,
        mileage,
      };
      const res = await Axios.post(`${window.location.pathname}GetServiceAgreementPrice`, data);
      commit('fetchSuccess', res.data);

      dispatch('fields/set', {
        type: FieldTypes.Period,
        options: state.items,
        value: optionsToFieldValue(state.items),
      }, { root: true });

      // Did not receive defined price for periods, so hide addon prices also
      if (state.items.find(item => !item.price)) {
        const periodAddonsField = rootGetters['fields/byType'](FieldTypes.PeriodAddons);

        dispatch('fields/set', {
          type: FieldTypes.PeriodAddons,
          options: periodAddonsField.options.map(opt => ({
            ...opt,
            price: null
          })),
        }, { root: true });
      }
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, { message, prices }) {
    state.fetchState = RequestStates.Success;
    state.items = prices;
    state.message = message;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
