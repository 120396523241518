import CompareVehicles from "../apps/compare-vehicles";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    this.mountCompareVehicles();
  },

  mountCompareVehicles() {
    mountApp({
      app: CompareVehicles,
      selector: "[data-app=compare-vehicles]"
    });
  },
};
