import mountApp from "../utils/mount-app";
import ReleasingBlock from "../apps/releasing-block";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: ReleasingBlock,
      selector: "[data-app=releasing-block]",
      extractProps: el => ({
        originalCustomer: el.getAttribute("data-original-customer") == "True"
      })
    });
  },
}
