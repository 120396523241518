var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact__tabs" },
    _vm._l(_vm.sections, function(section) {
      return _c(
        "div",
        {
          key: section.sectionHandle,
          staticClass: "tab-button",
          class: { "tab-button--active": _vm.isSelected(section) },
          on: {
            click: function($event) {
              return _vm.$emit("select", section)
            }
          }
        },
        [
          _c("i", { class: [section.icon] }),
          _vm._v(" "),
          _c("span", [_vm._v("\n      " + _vm._s(section.name) + "\n    ")])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }