<template>
  <div>
    <label>
      Kampanjkod
    </label>
    <div class="form-row">
      <div class="form-row__col">
        <SectionField @clickchange="onChange" :type="FieldTypes.CampaignCode">
        </SectionField>
      </div>
    </div>
    <div
      class="feedback-message feedback-message--success feedback-message--slim"
      v-if="validCode"
    >
      <div class="feedback-message__header">
        {{ code.campaignCode }}
      </div>
      <div class="feedback-message__text">
        {{ code.description }}
      </div>
    </div>
    <div
      class="feedback-message feedback-message--danger feedback-message--slim"
      v-if="fetched && !validCode"
    >
      <div class="feedback-message__header">
        {{ inputCode }}
      </div>
      <div class="feedback-message__text">
        Den här kampanjkoden är tyvärr inte giltig!
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FieldTypes from "./constants/FieldTypes";
import SectionField from "../shared/wizard/SectionField.vue";
import ConfigGroupOption from "../shared/configurator/ConfigGroupOption.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    SectionField,
    ConfigGroupOption,
    FontAwesomeIcon
  },
  data() {
    return {
      FieldTypes,
      faSpinnerThird,
      fetched: false,
      inputCode: null
    };
  },
  computed: {
    ...mapGetters({
      fieldByType: "fields/byType",
      invalidFields: "fields/invalid",
      sectionByType: "sections/byType"
    }),
    ...mapState({
      service: state => state.service.service,
      validCode: state => state.campaigncode.validCode,
      code: state => state.campaigncode.code
    }),

    field() {
      return this.fieldByType(FieldTypes.CampaignCode);
    }
  },
  methods: {
    ...mapActions({
      setField: "fields/set",
      validateCode: "campaigncode/validateCode"
    }),
    async onChange(input) {
      this.fetched = false;
      this.inputCode = input.value;
      const data = {
        service: this.service.value,
        departmentNr: this.fieldByType(FieldTypes.DepartmentNr).value,
        regNr: this.fieldByType(FieldTypes.RegNr).value,
        code: input.value
      };
      await this.validateCode(data);
      this.fetched = true;
      if (!this.validCode) input.value = "";
    }
  }
};
</script>
