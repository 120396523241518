var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reviewData
    ? _c(
        "div",
        { staticClass: "reviews-block" },
        [
          _c("div", { staticClass: "reviews-block__list" }, [
            _c(
              "div",
              {
                ref: "swiperContainer",
                staticClass: "reviews-block__swiper swiper-container"
              },
              [
                _c(
                  "div",
                  { staticClass: "swiper-wrapper" },
                  [
                    _c(
                      "div",
                      { staticClass: "reviews-block__card swiper-slide" },
                      [
                        _c("div", { staticClass: "reviews-widget" }, [
                          _c(
                            "div",
                            { staticClass: "reviews-widget__container" },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("StarList", {
                                attrs: {
                                  "list-id": "star",
                                  rating: _vm.reviewData.averageRating,
                                  width: 24
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "reviews-widget__container__content"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "reviews-widget__average-rating"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.reviewData.averageRating.toFixed(
                                              1
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "reviews-widget__divider"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "reviews-widget__total-reviews reviews-widget__total-reviews--cta",
                                      on: { click: _vm.openReviewsModal }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.ctaText) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.reviewData.reviews.slice(0, 3), function(
                      review
                    ) {
                      return _c(
                        "div",
                        {
                          key: review.reviewId,
                          staticClass: "reviews-block__card swiper-slide"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "reviews-widget__review__rating-container"
                            },
                            [
                              _c("StarList", {
                                attrs: {
                                  "list-id": review.reviewId,
                                  rating: review.starRating,
                                  width: 16
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "reviews-widget__review__date" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.moment(review.updateTime).fromNow()
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          review.comment
                            ? _c(
                                "div",
                                { staticClass: "reviews-block__card__comment" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.reviewsWithExpandedComment.includes(
                                          review.reviewId
                                        )
                                          ? review.comment
                                          : review.comment.substring(0, 60)
                                      ) +
                                      "\n            "
                                  ),
                                  !_vm.reviewsWithExpandedComment.includes(
                                    review.reviewId
                                  ) && review.comment.length > 60
                                    ? _c("span", [
                                        _vm._v("... "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "reviews-widget__review__read-more",
                                            on: {
                                              click: function($event) {
                                                return _vm.reviewsWithExpandedComment.push(
                                                  review.reviewId
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Läs mer")]
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", {
                              staticClass:
                                "fas fa-badge-check reviews-block__card__icon"
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "reviews-block__card__name" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(review.reviewer.displayName) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.reviewsModalOpen
            ? _c("ReviewsModal", {
                attrs: {
                  prefetchedReviewData: _vm.reviewData,
                  lang: _vm.lang,
                  locationId: _vm.locationId,
                  heading: _vm.heading,
                  address: _vm.address,
                  reviewsUrl: _vm.reviewsUrl
                },
                on: {
                  close: function($event) {
                    _vm.reviewsModalOpen = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reviews-widget__google-logo" }, [
      _c("img", { attrs: { src: "/Static/images/google_logo.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }