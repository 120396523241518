<template>
  <div class="checkout-section"
    :class="{'checkout-section--arrow': arrow,
             'checkout-section--summary': $slots['summary']}">
    <h3 class="checkout-section__header" v-if="$slots['header']">
      <slot name="header"></slot>
    </h3>
    <div class="checkout-section__content" v-if="$slots['content']">
      <slot name="content"></slot>
    </div>
    <div class="checkout-section__summary" v-if="$slots['summary']">
      <slot name="summary"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    arrow: Boolean,
  },
}
</script>
