var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "overlay-scrollable" }, [
    _vm.$slots["header"]
      ? _c("div", { ref: "header" }, [_vm._t("header")], 2)
      : _vm._e(),
    _vm._v(" "),
    _vm.$slots["content"]
      ? _c(
          "div",
          { staticClass: "overlay-scrollable__content" },
          [_vm._t("content")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$slots["footer"]
      ? _c("div", { ref: "footer" }, [_vm._t("footer")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }