<template>
  <div class="i3-filter__range-filter">
    <div class="i3-filter__range-filter-inputs">
      <input type="number" class="form-control" :min="facet.initialMin" v-model.number="minValue" />
      <div>
        -
      </div>
      <input type="number" class="form-control" :max="initialMax" v-model.number="maxValue" />
    </div>
    <VueSlider
      class="i3-filter__range-filter-slider"
      :value="sliderValue"
      :min="min"
      :max="max"
      :enable-cross="false"
      :duration="0"
      :marks="marks"
      :interval="interval"
      :dotSize="32"
      tooltip="none"
      @change="onSliderChange">
      <template v-slot:dot="{ value, focus }">
        <div :class="['vue-slider-dot-handle', { 'vue-slider-dot-handle-focus': focus }]">
          <i class="fal fa-angle-left"></i>
          <i class="fal fa-angle-right"></i>
        </div>
      </template>
    </VueSlider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import debounce from 'debounce';
import values from './values';

const { rangeFilterTopValue } = values;

export default {
  props: {
    facet: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueSlider,
  },
  created() {
    this.emitSelectDebounced = debounce(this.emitSelect.bind(this), 1000);
  },
  data() {
    return {
      minValue: this.facet.minSelected || this.facet.initialMin,
      maxValue: this.getMaxValue(this.facet.maxSelected) || this.getMaxValue(this.facet.initialMax),
    };
  },
  watch: {
    minValue() {
      this.emitSelectDebounced();
    },

    maxValue() {
      this.emitSelectDebounced();
    },

    facet() {
      if (!this.facet.minSelected) {
        this.minValue = this.facet.initialMin;
      }

      if (!this.facet.maxSelected) {
        this.maxValue = this.initialMax;
      }
    },
  },
  computed: {
    marks() {
      return {
        [this.facet.initialMin]: {
          label: `${this.facet.initialMin.toLocaleString().replace(/,/g," ",)} ${this.facet.unit}`,
        },
        [this.initialMax]: {
          label: this.maxLabel,
        },
      };
    },

    sliderValue() {
      return [
        this.minValue,
        this.maxValue,
      ];
    },

    maxLabel() {
      return `${this.initialMax.toLocaleString().replace(/,/g," ",)}${ this.doesExceedMaxValue(this.initialMax) ? '+' : '' } ${this.facet.unit}`
    },
    interval() {
      const range = this.facet.initialMax - this.facet.initialMin;
      return range > 200000 ? 10000 : (range > 15000 ? 500 : 100);
    },
    initialMax() {
      return this.getMaxValue(this.facet.initialMax);
    },
    max() {
      return Math.ceil(this.initialMax / this.interval) * this.interval;
    },
    min() {
      return Math.floor(this.facet.initialMin / this.interval) * this.interval;
    },
  },
  methods: {
    onSliderChange([ min, max ]) {
      this.minValue = min;
      this.maxValue = max;
    },

    emitSelect() {
      this.$emit('select', {
        facet: this.facet,
        min: this.minValue,
        max: this.maxValue,
      });
    },
    doesExceedMaxValue(value) {
      return value >= rangeFilterTopValue;
    },
    getMaxValue(value) {
      return this.doesExceedMaxValue(value) ? rangeFilterTopValue : value;
    },
  },
}
</script>
