export default (string) => {
  const ratings = {
    'ONE': 1,
    'TWO': 2,
    'THREE': 3,
    'FOUR': 4,
    'FIVE': 5,
  }

  return ratings[string];
}
