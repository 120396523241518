var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.upsellProducts, function(upsellProduct) {
      return _c("UpsellProduct", {
        key: upsellProduct.id,
        attrs: { product: upsellProduct },
        on: { added: _vm.onAdded }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }