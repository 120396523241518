import Swiper from '../swiper';
import Breakpoints from '../constants/Breakpoints';

export default {
  init() {
    this.$modal = null;
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $('.image-video-grid__item').on('click', this.onClick.bind(this));
  },

  modifyDOM() {
    this.applySwiper();
  },

  applySwiper() {
    $('.imagevideogridblock > .swiper-container').each((i, el) => {
      const numberOfItems = $(el).find('.image-video-grid__item');
      if (numberOfItems.length <= 5) {
        $('.image-slider__nav--next').addClass('swiper-button-hidden');
        $('.image-slider__nav--prev').addClass('swiper-button-hidden');
      }

      new Swiper(el, {
        autoHeight: true,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.image-slider__nav--next',
          prevEl: '.image-slider__nav--prev',
        },
      });
    });
  },

  onClick({ currentTarget }) {
    const $modal = $(currentTarget).closest('.imagevideogridblock').find('.modal');
    $modal.one('shown.wdk.modal', () => {
      $modal.find('.swiper-container').addClass('image-slider');
      new Swiper($modal.find('.swiper-container').first()[0], {
        autoHeight: true,
        initialSlide: $(currentTarget).index(),
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
        navigation: {
          nextEl: '.image-slider__nav--next',
          prevEl: '.image-slider__nav--prev',
        },
        loop: true
      });
    });
    $modal.modal('show');
  },
};