<template>
  <div v-if="selectedFinancingOption && vehicleData" class="product-main-action__container vehicle-configurator">
    <div class="product-main-action__main">
      <div class="product-main-action__pricing-meta">
        <div>
          {{ priceMetaString }}
        </div>
      </div>
      <div class="product-main-action__pricing">
        <div>
          <div>
            <div
              class="price-tag product-main-action__pricing__current-price"
              :class="{
                'price-tag--discount': primaryPrice.current < primaryPrice.ordinary
              }"
            >
              {{ $formatPriceNoSuffix(primaryPrice.current) + primaryPrice.suffix }}
            </div>
            <div
              v-if="primaryPrice.current < primaryPrice.ordinary"
              class="price-tag price-tag--obsolete product-main-action__pricing__obsolete-price"
            >
              {{ $formatPriceNoSuffix(primaryPrice.ordinary) + primaryPrice.suffix }}
            </div>
          </div>
        </div>
      </div>
      <Configurator
        :lang="lang"
        :regNr="regNr"
        :priceDisplay="primaryPrice"
        :displayTax="shouldDisplayTax"
        :position="position"
        :selectedFinancingOption="selectedFinancingOption"
        @onChangeFinanceOption="onChangeFinanceOption"
      />
      <div class="product-main-action__actions">
        <button class="btn btn--primary btn--block" @click="onOpenContactModal">
          <font-awesome-icon :icon="faEnvelope" class="vehicle-configurator__btn-icon"></font-awesome-icon>
          {{ 'Ta kontakt' }}
        </button>
        <a
          :href="showNumber ? `tel:${departmentPhoneNumber}` : '#'"
          class="btn btn--muted btn--block"
          @click="onPhoneClick"
        >
          <font-awesome-icon :icon="faPhone" class="vehicle-configurator__btn-icon"></font-awesome-icon>
          <span v-if="!showNumber">
            {{ 'Visa telefonnummer' }}
          </span>
          <span v-else>
            {{ departmentPhoneNumber }}
          </span>
        </a>
      </div>
    </div>
  </div>
  <div v-else class="skeleton-loader"></div>
</template>

<script>
import { EventBus } from "../../utils/event-bus";
import gtm from "../../utils/gtm";
import SearchWatchButton from "../search-watch-button/App.vue";
import FinanceOptions from '../../constants/FinanceOptions';
import store from './store';
import Configurator from './Configurator.vue';
import Cookies from "js-cookie";
import { faEnvelope, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FinanceSection from './configurator-sections/FinanceSection.vue'
import ConfiguratorSubSelect from "./configurator-components/ConfiguratorSubSelect.vue";
import ConfiguratorSection from "./configurator-components/ConfiguratorSection.vue";
import ConfiguratorTextInput from "./configurator-components/ConfiguratorTextInput.vue";
import TradeInSection from "./configurator-sections/TradeInSection.vue";
import ServiceAgreementSection from "./configurator-sections/ServiceAgreementSection.vue";
import SummationList from "./configurator-components/SummationList.vue";
import TyreSection from "./configurator-sections/TyreSection.vue";
import AccessoriesSection from "./configurator-sections/AccessoriesSection.vue";
import { VcModal } from "@holmgrensbil/vue-components";
import ConfiguratorDateSelect from "./configurator-components/ConfiguratorDateSelect.vue";
import { isSameDay } from "../../utils/dates";
import Breakpoints from "../../constants/Breakpoints";
import { getPrice, monthlyCost } from "../../utils/priceCalculations";

export default {
  components: {
    FontAwesomeIcon,
    SearchWatchButton,
    FinanceSection,
    ConfiguratorSubSelect,
    ConfiguratorSection,
    ConfiguratorTextInput,
    TradeInSection,
    ServiceAgreementSection,
    SummationList,
    TyreSection,
    AccessoriesSection,
    Configurator,
    VcModal,
    ConfiguratorDateSelect
},
  props: {
    lang: Object,
    carUrl: String,
    searchQuery: String,
    tags: String,
    regNr: String,
    departments: Array,
    deviceType: String,
    position: String,
  },
  data() {
    return {
      storeState: store.state,
      vehicleData: null,
      showNumber: false,
      contact: null,
      selectedFinancingOption: null,
      appLoaded: false,
      shouldDisplayTax: true,
      screenWidth: 0,
      FinanceOptions,
      faEnvelope,
      faPhone,
      desktopLoaded: false,
      mobileLoaded: false,
    };
  },

  computed: {
    departmentPhoneNumber() {
      return this.contact ? this.contact.departmentPhone : "";
    },
    financeOptions() {
      return !!this.storeState && !!this.storeState.financeOptions
        ? this.storeState.financeOptions
        : [];
    },
    priceMetaString() {
      let nameSuffix = this.selectedFinancingOption.nameSuffix;
      if (this.selectedFinancingOption.vehicleType === 'Husbil') {
        return this.selectedFinancingOption.id === FinanceOptions.CASH ? `Objektets pris ${nameSuffix}` : `Finansiering av objekt ${nameSuffix}`;
      } else {
        return this.selectedFinancingOption.id === FinanceOptions.CASH ? `Bilens pris ${nameSuffix}` : `Finansiering av bil ${nameSuffix}`;
      }
    },
    primaryPrice() {
      if (this.selectedFinancingOption) {
        return getPrice(this.selectedFinancingOption.financing, this.selectedFinancingOption.calculationType);
      }
    },
    financingData() {
      return this.storeState.calculation || null;
    },
    isReserved() {
      const now = new Date();
      const bookedTo = new Date(this.vehicleData.bookedTo)
      return now < bookedTo || isSameDay(now, bookedTo);
    },
    isMobile() {
      return this.screenWidth < Breakpoints.lg;
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
    if (this.isMobile && this.deviceType === 'mobile') {
      this.loadData();
      this.mobileLoaded = true;
    } else if (!this.isMobile && this.deviceType === 'desktop') {
      this.loadData();
      this.desktopLoaded = true;
    }
  },
  watch: {
    isMobile() {
      if (this.isMobile && this.deviceType === 'mobile' && !this.mobileLoaded) {
        this.loadData();
        this.mobileLoaded = true;
      } else if (!this.isMobile && this.deviceType === 'desktop' && !this.desktopLoaded) {
        this.loadData();
        this.desktopLoaded = true;
      }
    }
  },
  methods: {
    async loadData() {
      if (this.appLoaded) {
        return true;
      } else {
        await store.fetchConfigAction(this.regNr);
        store.setDepartments(this.departments);
        this.appLoaded = true;

        this.shouldDisplayTax = true;

        this.contact = this.storeState.contact;
        this.vehicleData = this.storeState.vehicle;
        this.selectedFinancingOption = this.storeState.selectedFinancingOption;
        EventBus.$on("alter-tax", this.alterDisplayTax.bind(this));
        EventBus.$on("open-vehicle-page-contact-form", this.onOpenContactModal.bind(this));
        EventBus.$on('change-finance-option', this.onChangeFinanceOption.bind(this));
      }
    },
    alterDisplayTax(shouldDisplayTax) {
      this.shouldDisplayTax = shouldDisplayTax;
    },
    onPhoneClick(e) {
      if (!this.showNumber) {
        e.preventDefault();
      }

      const departmentNr = this.contact.departmentNr;
      const phoneNr = this.contact.departmentPhone;
      const vehicleCondition = this.vehicleData.condition;
      const brandName = this.vehicleData.brandName;

      gtm.phoneNumberClick({
        departmentNr,
        phoneNr,
        vehicleCondition: vehicleCondition,
        brandName: brandName
      });

      this.showNumber = true;
    },
    async onChangeFinanceOption(selected) {
      let shouldDisplayTax = this.shouldDisplayTax;
      if (selected === FinanceOptions.PRIVATELEASING || selected === FinanceOptions.OPLEASING || selected === FinanceOptions.BLLEASING) {
        store.clearServiceAgreement();
        store.setTyresAction([]);
      }

      await store.setSelectedFinancingOption(selected);

      this.selectedFinancingOption = selected;
      if (selected === FinanceOptions.CASH) {
        shouldDisplayTax = true;
      } else if (
        selected === FinanceOptions.INSTALLMENT ||
        selected === FinanceOptions.PRIVATELEASING
      ) {
        shouldDisplayTax = true;
      } else if (selected === FinanceOptions.LEASING || selected === FinanceOptions.OPLEASING || selected === FinanceOptions.BLLEASING) {
        shouldDisplayTax = false;
      }
      this.alterDisplayTax(shouldDisplayTax);
      EventBus.$emit("alter-tax", shouldDisplayTax);
      store.alterTax(shouldDisplayTax);
    },
    onOpenContactModal() {
      const productUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      EventBus.$emit('open-vehicle-contact-modal', { regNr: this.regNr, productUrl });
    },
  }
};
</script>
