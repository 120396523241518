var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TyreBuildSectionWrapper",
    { attrs: { isAddon: _vm.isAddon, title: _vm.title } },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "build-tyre__main" },
          _vm._l(_vm.addons, function(addon) {
            return _c(
              "div",
              { key: addon.id, staticClass: "build-tyre__addon" },
              [
                _c("div", { staticClass: "build-tyre__addon__content" }, [
                  _c(
                    "div",
                    { staticClass: "build-tyre__addon__title-container" },
                    [
                      _c("div", { staticClass: "build-tyre__addon__label" }, [
                        _vm._v("\n              Tillägg\n            ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "build-tyre__addon__title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(addon.name) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "build-tyre__addon__description",
                        domProps: { innerHTML: _vm._s(addon.description) }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "build-tyre__addon__image-container" },
                    [
                      _c("img", {
                        attrs: {
                          src: addon.imageUrl,
                          height: "34",
                          width: "34"
                        }
                      })
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "VcOptionBox",
                      {
                        attrs: {
                          selected: _vm.selectedAddons.includes(addon.id),
                          heading: "Ja, tack",
                          checkbox: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.onSelectAddon(addon.id)
                          }
                        }
                      },
                      [
                        addon.price
                          ? _c(
                              "div",
                              {
                                attrs: { slot: "header-aside" },
                                slot: "header-aside"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "build-tyre__addon__price-container"
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "build-tyre__addon__price",
                                        class: {
                                          "build-tyre__addon__price--discount":
                                            addon.price.current <
                                            addon.price.ordinary
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$formatPrice(
                                                addon.price.current
                                              )
                                            ) +
                                            " / hjul\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    addon.price.current < addon.price.ordinary
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "build-tyre__addon__price build-tyre__addon__price--obsolete"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$formatPrice(
                                                    addon.price.ordinary
                                                  )
                                                ) +
                                                " / hjul\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.addons.length > 0
          ? _c("div", { staticClass: "build-tyre__footer" }, [
              _c(
                "div",
                {
                  staticClass:
                    "build-tyre__footer__content build-tyre__footer__content--price"
                },
                [
                  _c("div", { staticClass: "build-tyre__footer__price-row" }, [
                    _c(
                      "div",
                      { staticClass: "build-tyre__footer__price-title" },
                      [_vm._v("\n            TOTALKOSTNAD\n          ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "build-tyre__footer__price" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$formatPrice(_vm.totalPrice)) +
                          "\n          "
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "build-tyre__footer__action" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary btn--block btn--icon",
                    class: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.$emit("onAddToCart")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "far fa-shopping-cart" }),
                    _vm._v("\n          Lägg i varukorgen\n        ")
                  ]
                )
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }