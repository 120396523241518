<template>
  <div
    class="tyre-row"
    :class="{
      'tyre-row--danger': tyre.tread_depth <= 4
    }"
  >
    <div
      class="tyre-row__header"
      :class="{
        'tyre-row__header--open': isOpen,
        'tyre-row__header--danger': tyre.tread_depth <= 4
      }"
      @click="toggleRow"
    >
      <div>
        <FontAwesomeIcon
          :icon="tyre.tread_depth <= 4 ? faExclamationTriangle : faCheck"
          :class="{
            'tyre-row__icon--green': tyre.tread_depth >= 6,
            'tyre-row__icon--yellow':
              tyre.tread_depth < 6 && tyre.tread_depth > 4,
            'tyre-row__icon--red': tyre.tread_depth <= 4
          }"
        />
        {{ placements[tyre.placement] }} · {{ tyre.tread_depth }} mm
      </div>
      <div>
        <FontAwesomeIcon
          :icon="isOpen ? faChevronUp : faChevronDown"
          class="tyre-row__chevron"
        />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="tyre-row__content"
      :class="{
        'tyre-row__content--danger': tyre.tread_depth <= 4
      }"
    >
      <div class="tyre-row__content__title">{{ tyre.brand }}</div>
      <div class="tyre-row__content__text">{{ tyre.dimension }}</div>
      <FontAwesomeIcon v-if="tyre.has_tpms" :icon="faTirePressureWarning" />
    </div>
    <div
      v-if="isOpen && tyre.tread_depth <= 4"
      class="tyre-row__content"
      :class="{
        'tyre-row__content--danger': tyre.tread_depth <= 4
      }"
    >
      <div class="tyre-row__content__title">
        {{ "Vi rekommenderar att du byter däck." }}
      </div>
      <div class="tyre-row__content__text">
        {{ "Klicka på köp nya däck för att se däck som passar ditt fordon." }}
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheck,
  faExclamationTriangle,
  faChevronUp,
  faChevronDown,
  faTirePressureWarning
} from "@fortawesome/pro-solid-svg-icons";

const placements = {
  RF: "Höger fram",
  LF: "Vänster fram",
  RR: "Höger bak",
  LR: "Vänster bak"
};

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    tyre: Object
  },
  data() {
    return {
      isOpen: false,
      faCheck,
      faExclamationTriangle,
      faChevronUp,
      faChevronDown,
      faTirePressureWarning,
      placements
    };
  },
  methods: {
    toggleRow() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>
<style></style>
