var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VcFormSelect", {
    attrs: {
      options: _vm.field.options,
      prompt: _vm.field.placeholder,
      value: _vm.valueObject,
      valueProp: "value",
      labelProp: "name"
    },
    on: {
      change: function($event) {
        return _vm.$emit("change", $event ? $event.value : null)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }