import Axios from '../axios';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    $('[data-i3-total-count-url]').each(this.setI3TotalCountForElem);
  },

  setI3TotalCountForElem() {
    const $elem = $(this);
    const i3Url = $elem.data('i3-total-count-url');
    if(i3Url && i3Url.length) {
      Axios.get(i3Url).then(res => {
        const totalCount = res.data.stats.totalHits;
        $elem.text(totalCount);
      }, () => {
        // console.info(`Malformed i3 url: ${i3Url}`);
      });
    }
  }

}
