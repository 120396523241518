<template>
  <div class="info-block">
    <i class="info-block__icon fas fa-users">
    </i>

    <div class="info-block__header">
      {{department.staffCount}} medarbetare hittades på anläggningen
    </div>

    <div>
      {{department.address}}
    </div>

    <div>
      {{department.postalCode}} {{department.city}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    department: Object,
  },
}
</script>
