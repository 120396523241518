var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "u-align-center" },
        [
          _c("FontAwesomeIcon", {
            staticClass: "wizard--service-booking__loader",
            attrs: { icon: _vm.faSpinnerThird, spin: true }
          })
        ],
        1
      )
    : _c("div", [
        _vm.field.options.length
          ? _c(
              "div",
              _vm._l(_vm.field.options, function(group) {
                return _c("div", { key: group.name }, [
                  _c("label", [
                    _vm._v("\n        " + _vm._s(group.name) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    _vm._l(group.options, function(option) {
                      return _c(
                        "div",
                        { key: option.id, staticClass: "form-row__col" },
                        [
                          _c("ConfigGroupOption", {
                            attrs: {
                              option: option,
                              multiselect: option.inputType === 0
                            },
                            on: {
                              optionChange: function($event) {
                                return _vm.onOptionChange(option, $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                ])
              }),
              0
            )
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }