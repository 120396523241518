var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcModal",
    {
      on: {
        close: function($event) {
          return _vm.$emit("modalClose")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("\n    " + _vm._s(_vm.modalHeader) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "choose-dimensions",
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.manualSelect
            ? _c("SelectManual", {
                attrs: { tyreSize: _vm.tyreSize },
                on: { change: _vm.onChange }
              })
            : _c("SelectRecommended", {
                attrs: {
                  sizes: _vm.TyreSpec.recommendedSizes,
                  tyreSize: _vm.tyreSize,
                  carName: _vm.TyreSpec.carName
                },
                on: { change: _vm.onChange }
              }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn btn--primary btn--block",
              class: { disabled: _vm.buttonDisabled },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "form-text" }, [
            _vm._v(
              "\n      När man byter tum-storlek och endast köper lösa däck är det viktigt att man kontrollerar så att däckets tum stämmer överens med ens nuvarande fälgar. Annars kommer det inte gå att montera däcket på den gamla fälgen.\n    "
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }