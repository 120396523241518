var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configurator-section accessories-selector" },
    [
      _vm.displayChargeBox
        ? _c("div", { staticClass: "accessories-selector__input-row" }, [
            _c("div", { staticClass: "accessories-selector__heading" }, [
              _vm._v("\n        Laddbox\n        "),
              _vm.chargeBoxData.informationModalUrl
                ? _c(
                    "a",
                    {
                      staticClass:
                        "accessories-selector__link link link--underline",
                      attrs: {
                        href: "#",
                        "data-target": _vm.chargeBoxData.informationModalUrl,
                        "data-modal-source": "ajax"
                      }
                    },
                    [_vm._v(_vm._s(_vm.lang.SiteGeneral.ReadMore))]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("ConfiguratorCheckbox", {
                  attrs: {
                    text: "Ja, jag vill ha prisförslag på laddbox",
                    defaultChecked: _vm.needChargeBox
                  },
                  on: { change: _vm.setChargeBox }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.displayFittings
        ? _c("div", { staticClass: "accessories-selector__input-row" }, [
            _vm.fetchedFittings
              ? _c(
                  "div",
                  [
                    _c("ConfiguratorSubSelect", {
                      attrs: {
                        options: _vm.fittingsList,
                        heading: "Inredning",
                        defaultValue: _vm.fittingsList[0].value
                      },
                      on: { change: _vm.onChangeFittings }
                    }),
                    _vm._v(" "),
                    _vm.selectedFitting.modalContent
                      ? _c(
                          "div",
                          {
                            staticClass: "accessories-selector__link-container"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "accessories-selector__link link link--underline",
                                on: {
                                  click: function(e) {
                                    return _vm.onOpenModal(
                                      e,
                                      _vm.selectedFitting
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    "Läs mer om inredning: " +
                                      _vm.selectedFitting.heading
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.modalOpen
        ? _c("VcModal", { on: { close: _vm.onCloseModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("\n        " + _vm._s(_vm.modalHeader) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", {
              attrs: { slot: "content" },
              domProps: { innerHTML: _vm._s(_vm.modalContent) },
              slot: "content"
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }