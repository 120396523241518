export default class I3DocPage {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc._id;
  }

  get heading() {
    return this._doc.Name;
  }

  get permalink() {
    return this._doc.Url;
  }

  get ancestors() {
    return this._doc.AncestorNames;
  }
}
