<template>
  <div class="eon-product-buy-actions">
    <div class="eon-product-buy-actions__row">
      <VcFormStack
        label="Antal"
        small>
        <VcFormSelect
          :options="quantityOptions"
          :value="quantity"
          labelProp="name"
          valueProp="value"
          valueType="number"
          small
          @change="onQuantityChange">
        </VcFormSelect>
      </VcFormStack>
      <a class="btn btn--primary btn--block"
        :class="{
          'disabled': loading,
        }"
        @click="onClick">
        Köp
      </a>
    </div>
    <VcFeedbackMessage danger slim v-if="error">
      Produkten kunde inte läggas till i varukorgen.
    </VcFeedbackMessage>
  </div>
</template>

<script>
import { VcFormSelect, VcFormStack, VcFeedbackMessage } from '@holmgrensbil/vue-components';
import CartApi from '../../models/CartApi';
import RequestStates from '../../constants/RequestStates';
import siteHeaderCartBadge from '../../components/site-header-cart-badge';
import LineItemCustomDataKeys from '../../constants/LineItemCustomDataKeys';

export default {
  props: {
    product: Object,
    regNr: String,
  },
  components: {
    VcFormSelect,
    VcFormStack,
    VcFeedbackMessage,
  },
  mounted() {
    this.$emit('quantity', this.quantity);
  },
  data() {
    return {
      quantity: this.product.quantityDefaultOption,
      quantityOptions: this.product.quantityOptions,
      requestState: null,
    };
  },
  computed: {
    loading() {
      return this.requestState === RequestStates.Request;
    },

    error() {
      return this.requestState === RequestStates.Failure;
    },
  },
  methods: {
    onQuantityChange(value) {
      this.quantity = value;
      this.$emit('quantity', this.quantity);
    },

    onClick() {
      this.addToCart();
    },

    async addToCart() {
      this.requestState = RequestStates.Request;


      try {
        const customData = [];
        if (this.regNr) {
          customData.push({
            name: LineItemCustomDataKeys.RegistrationNumber,
            value: this.regNr,
          });
        }

        const data = {
          quantity: this.quantity.value,
          productId: this.product.id,
          customData,
        };
        const res = await CartApi.addItem(data);
        this.requestState = RequestStates.Success;
        siteHeaderCartBadge.setCount(res.data.lineItems.filter(li => !li.parentLineItem).length);
      } catch (e) {
        this.requestState = RequestStates.Failure;
      }
    },
  },
}
</script>
