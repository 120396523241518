import EonSpeedIndices from "../apps/eon-filter-page/constants/EonSpeedIndices";
import EonSpeedIndexSpeeds from "../apps/eon-filter-page/constants/EonSpeedIndexSpeeds";
import EonLoadIndices from "../apps/eon-filter-page/constants/EonLoadIndices";
import EonLoadIndexLoads from "../apps/eon-filter-page/constants/EonLoadIndexLoads";

const loadIndexMinValue = EonLoadIndices[0];
const loadIndexMaxValue = EonLoadIndices[EonLoadIndices.length - 1];
const speedIndexMinValue = EonSpeedIndices.Q;
const speedIndexMaxValue = EonSpeedIndices.Y;
const noiseDecibelMinValue = 80;
const noiseDecibelMaxValue = 64;
const treadDepthMinValue = 3;
const treadDepthMaxValue = 10;

const progressKeySpeedIndexScoreMap = {
  Q: 0,
  R: 1,
  S: 2,
  T: 3,
  U: 4,
  H: 5,
  V: 6,
  W: 7,
  Y: 8,
  VR: 5,
  ZR: 6,
};

const progressKeyAGGradeScoreMap = {
  A: 6,
  B: 5,
  C: 4,
  D: 3,
  E: 2,
  F: 1,
  G: 0,
};

const progressKeyAGGrade = {
  minLabel: 'Mindre bra',
  maxLabel: 'Mycket bra',
  minValue: 'G',
  maxValue: 'A',
  getProgressPercent: v => progressKeyAGGradeScoreMap[v] * (100 / (Object.keys(progressKeyAGGradeScoreMap).length - 1)),
};

const progressKeyMap = {
  wetGrip: progressKeyAGGrade,
  rollingResistance: progressKeyAGGrade,
  noiseDecibel: {
    minLabel: 'Mindre tyst',
    maxLabel: 'Mycket tyst',
    minValue: noiseDecibelMinValue,
    maxValue: noiseDecibelMaxValue,
    formatValue: v => `${v} dB`,
    getProgressPercent: v => {
      const normMax = noiseDecibelMinValue - noiseDecibelMaxValue;
      const normVal = noiseDecibelMinValue - v;
      const percent = (normVal / normMax) * 100;
      return percent > 0 ? percent : 0;
    },
  },
  loadIndex: {
    minLabel: 'Låg vikt',
    maxLabel: 'Hög vikt',
    minValue: loadIndexMinValue,
    maxValue: loadIndexMaxValue,
    formatValue: v => `${EonLoadIndexLoads[parseInt(v, 10)]} kg/däck`,
    getProgressPercent: v => {
      const max = EonLoadIndexLoads[loadIndexMaxValue];
      const min = EonLoadIndexLoads[loadIndexMinValue];
      const val = EonLoadIndexLoads[v];
      const normMax = max - min;
      const normVal = val - min;
      const percent = (normVal / normMax) * 100;
      return percent > 0 ? percent : 0;
    },
  },
  speedIndex: {
    minLabel: 'Låg hastighet',
    maxLabel: 'Hög hastighet',
    minValue: speedIndexMinValue,
    maxValue: speedIndexMaxValue,
    formatValue: v => `${EonSpeedIndexSpeeds[v]} km/h`,
    getProgressPercent: v => progressKeySpeedIndexScoreMap[v] * (100 / (Object.keys(progressKeySpeedIndexScoreMap).length - 4)),
  },
  treadDepth: {
    minLabel: 'Mindre bra',
    maxLabel: 'Mycket bra',
    minValue: treadDepthMinValue,
    maxValue: treadDepthMaxValue,
    formatValue: v => `${v} mm`,
    getProgressPercent: v => {
      const normMax = treadDepthMinValue - treadDepthMaxValue;
      const normVal = treadDepthMinValue - v;
      const percent = (normVal / normMax) * 100;
      return percent > 0 ? percent : 0;
    },
  }
};

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    const $progressFeatures = $('.tyre-features__item[data-feature-key][data-feature-value]');

    $progressFeatures.each((i, el) => {
      const key = el.getAttribute('data-feature-key');
      const value = el.getAttribute('data-feature-value');
      const markup = this.getMarkup(this.getMarkupParams(key, value));
      $(el).append(markup);
    });
  },

  getMarkupParams(key, value) {
    const {
      minLabel,
      maxLabel,
      minValue,
      maxValue,
      formatValue,
      getProgressPercent,
    } = progressKeyMap[key];
    const fValue = value => formatValue ? formatValue(value) : value;

    return {
      minLabel,
      maxLabel,
      minValue: fValue(minValue),
      maxValue: fValue(maxValue),
      progressPercent: getProgressPercent(value),
      tooltipText: fValue(value),
    };
  },

  getMarkup({ minLabel, maxLabel, minValue, maxValue, progressPercent, tooltipText } = {}) {
    return `
      <div class="tyre-features__rating">
        <div class="tyre-features__rating-progress">
          <div class="progress-labels">
            <label>
              ${minLabel}
            </label>
            <label>
              ${maxLabel}
            </label>
          </div>
          <div class="progress-bar">
            <div class="progress-bar__progress progress-bar__progress--yellow" style="width: ${progressPercent}%"></div>
          </div>
          <div class="progress-values">
            <div>
              ${minValue}
            </div>
            <div>
              ${maxValue}
            </div>
          </div>
        </div>
        <div class="tyre-features__rating-tooltip">
          ${tooltipText}
        </div>
      </div>`;
  },
};
