<template>
  <form @submit.prevent="onPurchase">
    <h4 class="configurator-section__title">
      {{ lang.Summation.ContactRequestHeader }}
    </h4>
    <div class="configurator-app__contact-section">
      <VcOptionBox
        v-for="option in requestTypeOptions"
        :key="option.name"
        :selected="option.value === requestType"
        :heading="option.name"
        @click="onRequestTypeChange(option.value)"
        :class="{
          'selected-contact-option': option.value === requestType
        }"
      >
        <div
          slot="content"
          v-if="
            option.value === requestType &&
              option.value !== ContactRequestTypes.Purchase
          "
        >
          <div v-if="done">
            <VcFeedbackMessage success>
              <div slot="header">
                {{ lang.Summation.FeedbackHeader }}
              </div>
              {{ lang.Summation.FeedbackText }}
            </VcFeedbackMessage>
          </div>
          <div v-else>
            <VcFormStack
              v-if="requestType === ContactRequestTypes.AskQuestion"
              required
              label="Vad har du för fråga?"
            >
              <textarea v-model="message" class="form-control"></textarea>
            </VcFormStack>
            <VcFormStack
              v-if="
                requestType === ContactRequestTypes.BookMeeting ||
                  requestType === ContactRequestTypes.TestDrive
              "
              label="Datum"
              required
            >
              <VcDatePicker
                :value="date"
                placeholder="Välj en dag"
                @change="onDateChange($event.target.value)"
              >
                <i class="fal fa-calendar-alt" slot="icon"></i>
              </VcDatePicker>
            </VcFormStack>
            <div class="form-stack form-stack--required">
              <label>
                Namn
              </label>
              <input
                class="form-control"
                v-model="name"
                autocomplete="name"
                placeholder="Vad heter du?"
                type="text"
                required
              />
            </div>
            <div class="form-row">
              <div class="form-row__col form-row__col--md-50">
                <div class="form-stack form-stack--required">
                  <label>
                    Telefonnummer
                  </label>
                  <input
                    class="form-control"
                    v-model="phone"
                    autocomplete="tel"
                    placeholder="Ange ditt telefonnummer"
                    type="tel"
                    required
                  />
                </div>
              </div>
              <div class="form-row__col form-row__col--md-50">
                <div class="form-stack form-stack--required">
                  <label>
                    E-post
                  </label>
                  <input
                    class="form-control"
                    v-model="email"
                    autocomplete="email"
                    placeholder="Ange din e-postadress"
                    type="email"
                    required
                  />
                </div>
              </div>
            </div>
            <div
              v-if="isTransport"
              class="configurator-app__contact-section__regnr"
            >
              <div class="configurator-app__contact-section__regnr__title">
                Har du ett fordon som du vill byta in?
              </div>
              <p class="configurator-app__contact-section__regnr__text">
                Vi återkommer med en värdering på ditt fordon.
              </p>
              <div class="regnr-input">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="regnr-input__symbol"></div>
                  </div>
                  <div class="form-stack">
                    <label>
                      Regnummer
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ABC123"
                      pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
                      v-model="regNr"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              :class="{ disabled: loading }"
              class="btn btn--primary btn--block"
              @click="onSubmit"
            >
              {{ lang.Summation.SubmitButtonText }}
            </button>
            <div
              class="form-text"
              v-html="lang.General.PrivacyPolicyText"
            ></div>
          </div>
        </div>
        <div
          slot="content"
          v-else-if="
            option.value === requestType &&
              option.value === ContactRequestTypes.Purchase &&
              displayPurchase
          "
        >
          <ConfigSummationFooter
            :requestType="ContactRequestTypes.Purchase"
            :purchaseInformationText="contact.purchaseInformation"
            :lang="lang"
            :submitState="submitState"
            :customActionLabel="lang.Summation.PurchaseAction"
            @submit="onPurchase"
          >
          </ConfigSummationFooter>
        </div>
      </VcOptionBox>
    </div>
  </form>
</template>

<script>
import {
  VcOptionBox,
  VcFormStack,
  VcDatePicker,
  VcFeedbackMessage
} from "@holmgrensbil/vue-components";
import ContactRequestTypes from "../../constants/ContactRequestTypes";
import ConfigSummationFooter from "../shared/configurator/ConfigSummationFooter.vue";
import ConfigSummationContact from "../shared/configurator/ConfigSummationContact.vue";
import RequestStates from "../../constants/RequestStates";
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
import store from "./store";
import Breakpoints from "../../constants/Breakpoints";
import { EventBus } from "../../utils/event-bus";

export default {
  components: {
    ConfigSummationFooter,
    ConfigSummationContact,
    VcOptionBox,
    VcFormStack,
    VcDatePicker,
    VcFeedbackMessage
  },
  props: {
    department: Number,
    lang: Object,
    isTransport: Boolean,
    selectedFinancingOption: String,
    calculation: Object,
    isPurchasable: Boolean
  },
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      message: null,
      date: null,
      regNr: null,
      selectedDepartment: null,
      submitState: null,
      requestType: ContactRequestTypes.Callup,
      ContactRequestTypes
    };
  },
  computed: {
    done() {
      return this.submitState === RequestStates.Success;
    },
    loading() {
      return this.submitState === RequestStates.Request;
    },
    requestTypeOptions() {
      return [
        // {
        //   name: this.lang.Summation.ContactRequestPurchase,
        //   value: ContactRequestTypes.Purchase
        // },
        {
          name: this.lang.Summation.ContactRequestCallup,
          value: ContactRequestTypes.Callup
        },
        {
          name: this.lang.Summation.ContactRequestAskQuestion,
          value: ContactRequestTypes.AskQuestion
        },
        {
          name: this.lang.Summation.ContactRequestPriceSuggestion,
          value: ContactRequestTypes.PriceSuggestion
        },
        {
          name: this.lang.Summation.ContactRequestTestDrive,
          value: ContactRequestTypes.TestDrive
        },
        {
          name: this.lang.Summation.ContactRequestBookMeeting,
          value: ContactRequestTypes.BookMeeting
        }
      ].filter(option =>
        option.value === ContactRequestTypes.Purchase
          ? this.displayPurchase
          : Boolean
      );
    },
    storeState() {
      return store.state;
    },
    displayPurchase() {
      return this.storeState
        ? this.storeState.contact.displayPurchase &&
            this.selectedFinancingOption === "installment"
        : false;
    },
    contact() {
      return this.storeState && this.storeState.contact;
    }
  },
  mounted() {
    // if (this.isPurchasable) {
    //   this.requestType = ContactRequestTypes.Purchase;
    // }

    EventBus.$on("set-section-type", sectionType => {
      if (sectionType === "purchase") {
        this.requestType = ContactRequestTypes.Purchase;
      } else if (sectionType === "contact") {
        this.requestType = ContactRequestTypes.Callup;
      }
    });
  },
  methods: {
    onRequestTypeChange(value) {
      this.requestType = parseInt(value, 10);
      const offset = window.innerWidth < Breakpoints.md ? -79 : -107;
      Vue.nextTick(function() {
        VueScrollTo.scrollTo(".selected-contact-option", 500, { offset });
      });
    },

    onDateChange(value) {
      this.date = value;
    },
    async onSubmit() {
      if (this.email.length === 0 || this.phone.length === 0) return false;
      const contactData = {
        customerName: this.name,
        customerEmail: this.email,
        customerPhone: this.phone,
        customerMessage: this.message,
        departmentNr: this.department || null,
        requestDate: this.date,
        requestType: this.requestType
      };

      if (contactData.requestType === ContactRequestTypes.Purchase) {
        const purchaseUrl = this.calculation.vehicle?.purchaseUrl || "";
        contactData.departmentNr = this.department;

        if (purchaseUrl) {
          window.open(purchaseUrl, "_blank");
          this.submitState = RequestStates.Success;
        }
      } else {
        this.submitState = RequestStates.Request;
        try {
          await store.submitAction(contactData);
          this.submitState = RequestStates.Success;
        } catch (e) {
          console.error(e);
          this.submitState = RequestStates.Failure;
        }
      }
      store.trackSubmitAction(contactData);
    },
    async onPurchase() {
      if (this.requestType === ContactRequestTypes.Purchase) {
        const purchaseUrl = this.calculation.vehicle?.purchaseUrl || "";

        window.open(purchaseUrl, "_newtab");
        this.submitState = RequestStates.Success;
      }
    }
  }
};
</script>
