<template>
  <div class="configurator-checkbox">
    <label
      ref="checkboxInput"
      class="configurator-checkbox__container"
      :class="{
        'configurator-checkbox__container--checked': checked,
      }"
      :style="{'height': imageSource ? containerHeight : '42px'}"
    >
      <div class="configurator-checkbox__title">
        {{ imageSource ? '' : text }}
        <span v-if="subText">{{ subText }}</span>
      </div>
      <div v-if="imageSource" class="configurator-checkbox__image-container" :style="{ backgroundImage: 'url(' + imageSource + ')' }">
        <div v-if="imageTags && imageTags.length" class="configurator-checkbox__tag-container">
          <div
            v-for="tag in imageTags"
            class="product-tag"
            :class="{
              [tag.tagTheme]: true,
            }"
          >
            <i :class="{
              [`far fa-${tag.icon}`]: tag.icon
            }"></i>
            {{ tag.title }}
          </div>
        </div>
      </div>
      <input
        class="configurator-checkbox__input"
        type="checkbox"
        :checked="checked"
        @change="onCheck"
      />
      <span
        class="configurator-checkbox__checkmark"
        :style="{'top': imageSource ? '12px' : 'auto'}"
      ></span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    subText: String,
    defaultChecked: Boolean,
    imageSource: String,
    imageTags: Array,
  },
  data() {
    return {
      checked: false,
      containerHeight: 0,
    }
  },
  mounted() {
    this.checked = this.defaultChecked;
    this.containerHeight = `${this.$refs.checkboxInput.clientWidth - 2}px`;
  },
  watch: {
    checked() {
      this.$emit('change', this.checked);
    },
  },
  methods: {
    onCheck(e) {
      this.checked = e.target.checked;
    },
    toggleCheckbox() {
      this.checked = !this.checked;
    }
  }
}
</script>