<template>
  <div class="my-pages__section my-vehicles">
    <div class="my-pages__section__title-container">
      <span class="my-pages__section__title">{{ `${ sectionData.length ? sectionData.length + ' FORDON' : 'Du har inga fordon registrerade' }` }}</span>
    </div>
    <div class="saved-vehicles" v-for="product in sectionData" :key="product.id">
      <div class="product-info">
        <span class="product-info__title">{{ product.brandName }}</span>
        <span class="product-info__subtitle">{{ product.modelName }} {{ product.trimPackage }}</span>
        <div class="product-info__description-container">
          <span class="product-info__description-container__text">{{product.registrationNumber }} |</span>
          <span class="product-info__description-container__text">{{ product.modelYear}}</span>
          <span v-if="product.mileage > 0" class="product-info__description-container__text">| {{ $formatNumber(product.mileage)}} mil</span>
        </div>
        <small v-if="product.forSaleDate">
          <b>Önskat försäljningspris: {{$formatPrice(product.forSalePrice)}}</b>
        </small>
        <button @click="openTradeInModal(product)" class="btn btn--primary btn--block btn--sm" v-if="!product.estimationRequested">
          Få ett inbytespris
        </button>
        <div class="feedback-message feedback-message--info feedback-message--slim" v-if="product.estimationRequested && !product.estimated">
          <div class="feedback-message__text">
            <i class="fas fa-circle attention-circle attention-circle--secondary"></i> Vi hanterar din inbytesförfrågan
          </div>
        </div>
        <div v-if="product.estimated">
          <div v-if="checkIfEstimationIsValid(product.estimatedPriceDate)">
            <div class="feedback-message feedback-message--success feedback-message--slim">
              <div class="feedback-message__text">
                <i class="fas fa-circle attention-circle attention-circle--success"></i> Vi har hanterat din inbytesförfrågan <br>
                Ditt fordon är värderat till: <b> {{ $formatPrice(product.estimatedPrice) }} </b><br>
                Datum: <b>  {{$formatYYYYMMDD(product.estimatedPriceDate)}} </b><br>
              </div>
            </div>

            <a v-if="!product.forSale" @click="toggleForSale(product)" class="btn btn--muted btn--block btn--sm u-margin-top">
              Jag är intresserad av att sälja bilden
            </a>

            <div class="u-margin-top" v-if="product.forSale && !product.forSaleDate">
              <VcFormStack label="Önskat försäljningspris">
                <input
                  v-model="product.forSalePrice"
                  placeholder="Ange önskat försäljningspris"
                  type="number"
                  class="form-control">
              </VcFormStack>
              <button @click="sendForSalePrice(product)" class="btn btn--primary btn--block btn--sm u-margin-top">
                Skicka
              </button>
            </div>
          </div>
          <div v-else>
            <div class="feedback-message feedback-message--danger feedback-message--slim">
              <div class="feedback-message__text">
                <i class="fas fa-circle attention-circle"></i> Din fordonsvärdering gick ut <b>{{ $formatYYYYMMDD(product.estimatedPriceDate) }}</b>, var god begär en ny värdering<br>
              </div>
            </div>
            <button @click="openTradeInModal(product)" class="btn btn--primary btn--block btn--sm u-margin-top">
              Få ett inbytespris
            </button>
          </div>
        </div>

        <a @click.prevent="goToService('ServiceReparation', product)" class="btn btn--muted btn--block btn--sm u-margin-top">
          Boka service
        </a>
        <a @click.prevent="goToService('Skadebesiktning', product)" class="btn btn--muted btn--block btn--sm u-margin-top">
          Boka skadebesiktning
        </a>
        <a @click.prevent="goToService('Glasreparation', product)" class="btn btn--muted btn--block btn--sm u-margin-top">
          Boka glasreparation
        </a>
        <a :href="similarCarsLink(product)" class="btn btn--muted btn--block btn--sm u-margin-top">
          Sök efter liknande bilar i lager
        </a>
        <a @click.prevent="goToService('Däcktjänster', product)" class="btn btn--muted btn--block btn--sm u-margin-top">
          Boka däckbyte
        </a>
        <!-- <a href="" class="btn btn--muted btn--block btn--sm u-margin-top">
          Se kampanjer på passande däck
        </a> -->
      </div>
      <div class="product-info__card-actions">
        <div @click="$emit('deleteMyVehicle', product.id)" class="product-info__card-actions__btn">
          <i class="fas fa-trash blue"></i>
        </div>
      </div>
    </div>


    <a class="my-vehicles__add" @click="showAddNewView()">
      <i class="fal fa-plus"></i> Lägg till fordon <i class="fal fa-chevron-right"></i>
    </a>

    <span class="my-pages__section__title">FÖRDELAR MED ATT SPARA DITT FORDON</span>
    <div v-html="myVehiclesUsps"></div>

    <VcModal v-if="showTradeInModal"
    @close="onModalClose()"
    modal-body-class="modal-body--no-padding">
      <div slot="header">Få ett inbytespris på ditt fordon </div>
      <div slot="content">
        <div ref="tradeinholder">
          <div ref="tradein"></div>
        </div>
      </div>
    </VcModal>
  </div>
</template>

<script>
import { VcModal, VcFormStack } from '@holmgrensbil/vue-components';
import { EventBus } from '../../../utils/event-bus';
import Views from '../constants/Views';
import mountApp from '../../../utils/mount-app';
import tradeIn from '../../trade-in';
import Axios from 'axios';
import moment from 'moment';

export default {
  components: {
    VcModal,
    VcFormStack
  },
  props: {
    sectionData: Array,
    currentUser: Object,
  },
  data() {
    return {
      showTradeInModal: false,
      tradeInApp: null,
      cachedLinks: {},
      myVehiclesUsps: window.myVehiclesUsps
    };
  },
  mounted() {

  },
  methods: {
    toggleForSale(product) {
      product.forSale = true;
    },
    async sendForSalePrice(product) {
      try {
        const res = await Axios.post('/api/mypages/myvehicles/forsale', {
          vehicleId: product.id,
          forSalePrice: product.forSalePrice
        });
        EventBus.$emit('my-vehicle-added');
      } catch(e) {

      }

    },
    similarCarsLink(product) {
      return `${window.MainVehicleSearchPage}?brandmodelfacet=${product.brandName}§${product.modelName}`;
    },
    async goToService(service, product) {
      try {
        const res = await Axios.get(`/api/mypages/department?mainServiceName=${service}&registrationNumber=${product.registrationNumber}`);
        window.location.href = res.data;
      } catch(e) {
        return null;
      }

      return null;
    },
    showAddNewView() {
      EventBus.$emit('logged-in-view', Views.AddOrEditVehicle);
    },
    onModalClose() {
      this.showTradeInModal = false;
    },
    openTradeInModal(vehicle) {
      this.showTradeInModal = true;
      this.$nextTick(() => {
        const app = tradeIn({
          vehicleId: vehicle.id,
          regNr: vehicle.registrationNumber,
          email: this.currentUser.email,
        });
        this.tradeInApp = new app();
        this.tradeInApp.$mount(this.$refs.tradein);
      })
    },
    checkIfEstimationIsValid(date) {
      const todayDate = moment();
      const expirationDate = moment(date).add(30, 'd');
      const diff = todayDate.diff(expirationDate);

      return diff < 0;
    }
  },
}
</script>
