var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "field-input-images" }, [
    _c(
      "div",
      { staticClass: "field-input-images__grid" },
      _vm._l(_vm.sectionFields, function(field) {
        return _c(
          "div",
          { key: field.type, staticClass: "field-input-images__grid-item" },
          [_c("SectionField", { attrs: { type: field.type } })],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }