import ServicesApi from '../../../../models/ServicesApi';

const state = {
  item: null,
};

const getters = {};

const actions = {
  async select({ commit, dispatch }, item) {
    try {
      const res = await ServicesApi.fetchCarInfo({
        regNr: item,
      });
      
      commit('selected', res.data);
      dispatch('departments/fetchDepartmentsByRegNr', { regNr: item }, { root: true });
    } catch (e) {
      console.error(e);
    }
  },

  deselect({ commit }) {
    commit('deselected');
  },
};

const mutations = {
  selected(state, item) {
    state.item = item;
  },

  deselected(state) {
    state.item = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
