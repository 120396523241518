import Cookies from 'js-cookie';
import pick from 'lodash/pick';

const COOKIE_NAME = 'TyreSpecData';
const defaultData = {
  eonCar: null,
  regNr: null,
  tyreSize: {
    width: null,
    aspectRatio: null,
    diameter: null,
  },
};

export default {
  data: {
    ...defaultData,
  },

  get isEmpty() {
    const {
      diameter,
      width,
      aspectRatio,
    } = this.tyreSize;
    return !`${this.regNr || ''}${width || ''}${aspectRatio || ''}${diameter || ''}`.length;
  },

  get regNr() {
    return this.data.regNr;
  },

  get tyreSize() {
    return this.data.tyreSize;
  },

  get comment() {
    const { eonCar } = this.data;

    if (eonCar && eonCar.comment) {
      return this.data.comment;
    }

    return null;
  },

  get carName() {
    const { eonCar } = this.data;

    if (eonCar && eonCar.description) {
      return eonCar.description;
    }

    return null;
  },

  get vehicleId() {
    const { eonCar } = this.data;

    if (eonCar && eonCar.vehicleId) {
      return String(eonCar.vehicleId);
    }

    return null;
  },

  get searchVehicleId() {
    return this.data.searchVehicleId
  },

  get shortDisplayName() {
    const {
      diameter,
      width,
      aspectRatio,
    } = this.tyreSize;
    return this.regNr ? `regnr ${this.regNr}` : `däckdimensioner ${diameter}" ${width}/${aspectRatio}R${diameter}`;
  },

  get recommendedSizes() {
    const { eonCar } = this.data;
    let sizes = [];

    if (eonCar && eonCar.tyreSizes) {
      sizes = [ ...eonCar.tyreSizes ];
    }

    return sizes;
  },

  setCarManually(eonCar, searchVehicleId) {
    const {
      width,
      aspectRatio,
      diameter,
    } = eonCar.tyreSizes[0];

    this.clear()

    this.set({
      searchVehicleId,
      eonCar,
      tyreSize: {
        width: String(width),
        aspectRatio: String(aspectRatio),
        diameter: String(diameter),
      },
    });
  },

  setCar(eonCar) {
    const {
      width,
      aspectRatio,
      diameter,
    } = eonCar.tyreStandardSize;

    eonCar = pick(eonCar, ['brand', 'model', 'comment', 'description', 'tyreSizes']);

    this.set({
      eonCar,
      tyreSize: {
        width: String(width),
        aspectRatio: String(aspectRatio),
        diameter: String(diameter),
      },
    });
  },

  set(props) {    
    this.data = {
      ...this.data,
      ...props
    };
    this.save();
  },

  clear() {
    this.set(defaultData);
    Cookies.remove(COOKIE_NAME)
  },

  load() {
    const data = Cookies.get(COOKIE_NAME);

    if (data) {
      this.data = JSON.parse(data);
    }
  },

  save() {
    Cookies.set(COOKIE_NAME, this.data, { expires: 365 });
  },
};
