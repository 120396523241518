var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.periodsMessage) } }),
      _vm._v(" "),
      _vm.periodsItems.length
        ? _c("SectionField", { attrs: { type: _vm.FieldTypes.Period } })
        : _vm._e(),
      _vm._v(" "),
      _c("SectionField", {
        staticClass: "wizard--service-agreement__addons",
        attrs: { type: _vm.FieldTypes.PeriodAddons }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }