<template>
  <div class="i3-filter__filter form-group form-check"
    :class="{ 'i3-filter__filter--selected': filter.selected,
              'form-check--lg': largeCheckboxes }"
    @click.stop="$emit('select', filter)">
    <input type="checkbox" class="form-check__input"
      :class="{'form-check__input--semi-checked': !filter.selected && hasSelectedChildren}"
      :checked="filter.selected" />

    <div class="form-check__label">
      {{filter.displayName}}

      <span class="i3-filter__filter-count">
        ({{filter.count}})
      </span>
    </div>

    <div class="i3-filter__filter-children" v-if="filter.children.length > 1 && filter.selected || filter.children.find(c => c.selected)">
      <filter-item v-for="child in filter.children"
        :filter="child"
        :key="child.query"
        @select="$emit('select', child)">
      </filter-item>
    </div>
  </div>
</template>

<script>
import Breakpoints from '../../constants/Breakpoints';

export default {
  name: 'filter-item',
  props: {
    filter: Object,
  },
  computed: {
    largeCheckboxes() {
      return this.windowWidth < Breakpoints.md;
    },

    hasSelectedChildren() {
      return this.filter.children.length && this.filter.children.find(c => c.selected);
    },
  },
}
</script>
