<template>
  <FieldInputOptionBoxes
    v-bind="$props"
    v-on="$listeners"
    multiselect>
  </FieldInputOptionBoxes>
</template>

<script>
import FieldInputOptionBoxes from './FieldInputOptionBoxes.vue';

export default {
  components: {
    FieldInputOptionBoxes,
  },
  props: {
    invalid: Boolean,
    field: Object,
  },
}
</script>
