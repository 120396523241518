<template>
  <OverlayScrollable>
    <OverlayHeader
      slot="header"
      text="Varukorg"
      :meta="`(${cartLineParents.length} varor)`"
      @close="onClose">
    </OverlayHeader>

    <div slot="content" class="site-cart">
      <div v-if="cartIsEmpty" class="feedback-message feedback-message--info feedback-message--slim feedback-message--center">
        <div class="feedback-message__text">
          Du har inte lagt till några produkter i varukorgen.
        </div>
      </div>
      <Cart :isAccessoryCart="isAccessoryCart" v-else></Cart>
    </div>

    <OverlayFooter v-if="cartIsEmpty"
      slot="footer"
      text="Fortsätt handla"
      @click="onClose">
    </OverlayFooter>
    <OverlayFooter v-else
      slot="footer"
      text="Gå till kassan"
      yellowBg
      @click="onGoToCheckout">
      <CartSummary></CartSummary>
    </OverlayFooter>
  </OverlayScrollable>
</template>

<script>
import OverlayScrollable from '../shared/OverlayScrollable.vue';
import OverlayHeader from '../shared/OverlayHeader.vue';
import OverlayFooter from '../shared/OverlayFooter.vue';
import Cart from '../shared/ecom/Cart.vue';
import CartSummary from '../shared/ecom/CartSummary.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import siteHeaderCartBadge from '../../components/site-header-cart-badge';

export default {
  components: {
    OverlayScrollable,
    OverlayHeader,
    OverlayFooter,
    Cart,
    CartSummary,
  },
  props: {
    onClose: Function,
  },
  data() {
    return {
      cartOverlayOpen: null,
      checkoutUrl: window.EonConfig.checkoutUrl,
      isAccessoryCart: false,
    };
  },
  watch: {
    cartOverlayOpen() {
      this.fetchCart();
    },

    cartLineParents() {
      if (this.cartHasFetched) {
        siteHeaderCartBadge.setCount(this.cartLineParents.length);
      }
    },
  },
  created() {
    const storeKey = window.ecomConfig?.storeKey ?? '';
    if (storeKey === 'accessory') {
      this.checkoutUrl = window.ecomConfig?.accessoriesCheckoutUrl ?? '/';
      this.isAccessoryCart = true;
    }
  },
  computed: {
    ...mapState({
      cartHasFetched: state => state.cart.hasFetched,
    }),

    ...mapGetters({
      cartIsEmpty: 'cart/isEmpty',
      cartLineParents: 'cart/lineParents',
    }),
  },
  methods: {
    ...mapActions({
      fetchCart: 'cart/fetch',
    }),

    onGoToCheckout() {
      window.location.href = this.checkoutUrl;
    },
  },
}
</script>
