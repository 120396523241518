export default {
  Ssn: 'governmentId',
  FirstName: 'firstName',
  LastName: 'lastName',
  Address: 'addressRow1',
  CareOf: 'addressRow2',
  Zip: 'postalCode',
  City: 'city',
  Email: 'email',
  Phone: 'phone',
}
