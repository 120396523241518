<template>
  <div v-if="reviewData" class="reviews-widget">
    <div class="reviews-widget__container">
      <div class="reviews-widget__google-logo">
        <img src="/Static/images/google_logo-white.png" />
      </div>
      <StarList list-id="star" :rating="reviewData.averageRating" :width="inBlock && !isMobile ? 32 : 16" />
      <div class="reviews-widget__container__content">
        <div class="reviews-widget__average-rating">
          {{ reviewData.averageRating.toFixed(1) }}
        </div>
        <div class="reviews-widget__divider"></div>
        <div class="reviews-widget__total-reviews reviews-widget__total-reviews--cta" @click="openReviewsModal">
          {{ ctaText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarList from "./StarList.vue";
import Breakpoints from "../../constants/Breakpoints";
import { EventBus } from "../../utils/event-bus";
import Axios from "../../axios";

export default {
  components: {
    StarList,
  },
  props: {
    lang: Object,
    inBlock: Boolean,
    locationId: String,
    heading: String,
    address: String,
    reviewsUrl: String,
  },
  data() {
    return {
      reviewData: null,
      reviewsModalOpen: false,
    };
  },
  mounted() {
    
  },
  async created() {
    EventBus.$on('reviews-block-loaded', () => this.fetchReviews());
  },
  computed: {
    ctaText() {
      return this.lang.Contact.ReviewsWidgetCta.replace('{{quantity}}', this.reviewData.totalReviewCount);
    },
    isMobile() {
      return this.windowWidth < Breakpoints.md;
    },
  },
  methods: {
    openReviewsModal() {
      EventBus.$emit('open-reviews-modal')
    },
    async fetchReviews() {
      try {
        const { data } = await Axios.get(`/api/Contact/GetReviews?locationId=${this.locationId}&orderBy=updateTime desc&pageSize=1`);
        this.reviewData = data;
      } catch (e) {
        console.warn(e);
      }
    }
  }
};
</script>
