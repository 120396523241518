<template>
  <component :is="InputTypeComponents[field.inputType]"
    v-bind="$props"
    v-on="$listeners">
  </component>
</template>

<script>
import InputTypeComponents from './constants/InputTypeComponents';

export default {
  props: {
    invalid: Boolean,
    field: Object,
  },
  data() {
    return {
      InputTypeComponents,
    };
  },
}
</script>
