var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "campaign-list" },
    [
      _c("div", { staticClass: "vehicle-list__header" }, [
        _c("div", { staticClass: "vehicle-list__header__title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$formatNumber(_vm.hits)) +
              " kampanjer\n      "
          )
        ])
      ]),
      _vm._v(" "),
      _c("TheGrid", {
        attrs: {
          channel: _vm.channel,
          documents: _vm.documents,
          queriedAt: _vm.queriedAt
        },
        on: {
          onNext: function($event) {
            return _vm.$emit("onNext")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }