import FieldTypes from '../constants/FieldTypes';
import SectionTypes from '../constants/SectionTypes';
import RegExPatterns from '../../../constants/RegExPatterns';

export default [
  {
    section: SectionTypes.SsnLookup,
    type: FieldTypes.Ssn,
    label: 'Personnummer',
    placeholder: 'ÅÅÅÅMMDD-NNNN',
    inputType: 'text',
    inputPattern: RegExPatterns.ResursSsn,
    required: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.FirstName,
    label: 'Förnamn',
    placeholder: 'Ditt förnamn',
    inputType: 'text',
    autocomplete: 'given-name',
    required: true,
    readonly: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.LastName,
    label: 'Efternamn',
    placeholder: 'Ditt efternamn',
    inputType: 'text',
    autocomplete: 'family-name',
    required: true,
    readonly: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.CareOf,
    label: 'C/O',
    placeholder: 'Frivilligt',
    inputType: 'text',
    required: false,
    readonly: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.Address,
    label: 'Adress',
    placeholder: 'Din adress',
    inputType: 'text',
    autocomplete: 'shipping street-address',
    required: true,
    readonly: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.Zip,
    label: 'Postnummer',
    placeholder: 'Ditt postnummer',
    inputType: 'text',
    autocomplete: 'shipping postal-code',
    required: true,
    readonly: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.City,
    label: 'Ort',
    placeholder: 'Din ort',
    inputType: 'text',
    autocomplete: 'shipping address-level2',
    required: true,
    readonly: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.Email,
    label: 'E-post',
    placeholder: 'Din e-post',
    inputType: 'text',
    inputPattern: RegExPatterns.ResursEmail,
    autocomplete: 'email',
    required: true,
  },
  {
    section: SectionTypes.ContactDetails,
    type: FieldTypes.Phone,
    label: 'Mobilnummer',
    placeholder: '070-123 45 67',
    inputType: 'tel',
    inputPattern: RegExPatterns.cellPhone,
    autocomplete: 'tel',
    required: true,
  },
];
