<template>
  <div class="container">
    <div class="service-booking-selector--container">
      <div class="form-step" :class="{ 'form-step--completed': (validCarInfo && selectedDepartment) }">
          <div class="service-booking-selector--content">
            <div class="service-booking-selector" :class="{ 'service-booking-selector--readonly': readOnlyMode }">
              <div class="service-booking-selector__columns" :class="{
                'service-booking-selector__columns--readonly': readOnlyMode,
                'service-booking-selector__columns--hidden': !showSelectService
              }">
                <div class="service-booking-selector__column service-booking-selector__column--service" :class="{
                  'service-booking-selector__column--readonly': readOnlyMode,
                  'service-booking-selector__column--notify': selectServiceNotifier,
                  'service-booking-selector__column--hidden': !showSelectService
                }">
                  <VcFormStack v-if="!readOnlyMode" label="Tjänst">
                    <VcFormSelect :options="availableServices" :value="selectedService" prompt="Välj verkstadstjänst"
                      labelProp="name" valueProp="value" valueType="string" :tabindex="100" @change="onServiceChange">
                    </VcFormSelect>
                  </VcFormStack>
                  <div v-else>
                    <FontAwesomeIcon :icon="faCheckLight"></FontAwesomeIcon>
                    {{ selectedService.name }}
                  </div>
                </div>
                <div class="service-booking-selector__columns__main-actions">
                  <div class="service-booking-selector__column service-booking-selector__column--regnr" :class="{
                    'service-booking-selector__column--readonly': readOnlyMode,
                    'service-booking-selector__column--notify': selectRegnrNotifier
                  }">
                    <div class="regnr-input" v-if="!readOnlyMode" @click="onRegNrClick()">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="regnr-input__symbol"></div>
                        </div>
                        <div class="form-stack">
                          <label>
                            Regnummer
                          </label>
                          <input id="regNr-input" type="text" class="form-control" placeholder="ABC123" required
                            :disabled="!serviceCompleted" pattern="^\w{3}\d{2}(\w{1}|\d{1})$" :tabindex="101"
                            :value="selectedRegNr" @input="evt => (selectedRegNr = evt.target.value)"
                            v-on:keyup="onRegNrChange" />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <FontAwesomeIcon :icon="faCheckLight"></FontAwesomeIcon>
                      {{ selectedRegNr }}
                    </div>
                  </div>
                  <div @click="onDepartmentClick()"
                    class="service-booking-selector__column service-booking-selector__column--department" :class="{
                      'service-booking-selector__column--readonly': readOnlyMode,
                      'service-booking-selector__column--invalid': noAvailableDepartments
                    }">
                    <VcFormStack v-if="!readOnlyMode" label="Anläggning">
                      <VcFormSelect :options="availableDepartments" :value="selectedDepartment"
                        :disabled="departmentSelectDisabled" :prompt="selectDepartmentPrompt"
                        :invalid="noAvailableDepartments" labelProp="name" valueProp="value" valueType="string"
                        :tabindex="102" @change="onDepartmentChange">
                      </VcFormSelect>
                      <div class="regnr-input__tooltip regnr-input__tooltip--error"
                        :class="{ 'regnr-input__tooltip--visible': (selectedDepartment && selectedDepartment.message) || showFetchedDepartmentError }">
                        {{ (selectedDepartment && selectedDepartment.message) || lang.Lookup.NoDepartment }}
                      </div>
                    </VcFormStack>
                    <div v-else>
                      <FontAwesomeIcon :icon="faCheckLight"></FontAwesomeIcon>
                      {{ selectedDepartment.name }}
                    </div>
                  </div>
                </div>
                <div class="service-booking-selector__column service-booking-selector__column--submit"
                  :class="{ 'service-booking-selector__column--readonly': readOnlyMode }">
                  <button v-if="!readOnlyMode && !fromSpecificServicePage" :disabled="btnDisabled"
                    class="btn btn--primary btn--block" @click="moveOn()">
                    Gå vidare med bokning
                  </button>
                  <button v-else-if="!readOnlyMode" :disabled="btnDisabled" class="btn btn--primary btn--block"
                    @click="moveOnInternal()">
                    Fortsätt
                  </button>
                  <button v-else class="btn btn--outline btn--xs" @click="readOnlyMode = !readOnlyMode">
                    Ändra
                  </button>
                </div>
              </div>

            </div>
          <div class="feedback feedback--error feedback--mobile"
            v-if="(selectedDepartment && selectedDepartment.message) || showFetchedDepartmentError">
            {{ (selectedDepartment && selectedDepartment.message) || lang.Lookup.NoDepartment }}
          </div>
          <div class="feedback feedback--success" v-if="validCarInfo">
            {{ carInfoDisplayName }}
          </div>
          <div class="feedback feedback--standard" v-else>
            {{ startMessage }}
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import Axios from "../../axios";
import { VcFormSelect, VcFormStack } from "@holmgrensbil/vue-components";
import ServicesApi from "../../models/ServicesApi";
import { getQueryParam } from "../../utils/query-params";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck as faCheckLight } from "@fortawesome/pro-light-svg-icons";
import ServiceBookingManager from "../../models/ServiceBookingManager";
import VueScrollTo from "vue-scrollto";

const regNrRegexp = /^\w{3}\d{2}(\w{1}|\d{1})$/;
export default {
  components: {
    VcFormSelect,
    VcFormStack,
    FontAwesomeIcon
  },
  props: {
    preselectedRegNr: String,
    preselectedService: String,
    preselectedDepartment: String,
    startMessage: String,
    fromSpecificServicePage: Boolean,
    lang: Object,
  },
  data() {
    return {
      availableServices: [],
      selectedService: null,
      availableDepartments: [],
      selectedDepartment: null,
      selectedRegNr: "",
      carInfo: null,
      readOnlyMode: false,
      faCheckLight,
      selectServiceNotifier: false,
      selectRegnrNotifier: false,
      showService: true,
      noAvailableDepartments: false,
      inputPrefilled: false,
      showFetchedDepartmentError: false,
      screenWidth: 0,
    };
  },

  async mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth;
    });
    await this.fetchServices();

    if (
      this.availableServices.length &&
      (getQueryParam("service") || this.preselectedService)
    ) {
      const param = getQueryParam("service") || this.preselectedService;
      this.selectedService = this.availableServices.find(
        s => s.value === param
      );

      await this.fetchDepartments();
    }

    if (this.availableDepartments.length) {
      const param = getQueryParam("department") || this.preselectedDepartment;
      this.selectedDepartment = this.availableDepartments.find(
        d => d.value === param
      );
    }

    const regNrParam = getQueryParam("regNr");
    if (regNrRegexp.test(regNrParam)) {
      this.selectedRegNr = regNrParam;
      await this.onRegNrChange();
    }

    if (getQueryParam("readOnly") === "true" && this.departmentCompleted) {
      this.readOnlyMode = true;
    }

    if (this.selectedDepartment && this.selectedService && this.selectedRegNr) {
      ServiceBookingManager.setService(this.selectedService);
      ServiceBookingManager.setDepartment(this.selectedDepartment);
    }
  },

  computed: {
    isMobile() {
      return this.screenWidth < 768;
    },
    showSelectService() {
      return !this.preselectedService && this.showService;
    },
    serviceActive() {
      return !this.selectedService;
    },
    regNrActive() {
      return !this.serviceActive && !this.validRegNr;
    },
    departmentActive() {
      return this.regNrCompleted && !this.selectedDepartment;
    },
    serviceCompleted() {
      return this.selectedService;
    },
    regNrCompleted() {
      return this.serviceCompleted && this.validRegNr && this.validCarInfo;
    },
    departmentCompleted() {
      return this.regNrCompleted && this.selectedDepartment;
    },
    btnDisabled() {
      return (
        !this.selectedService || !this.validCarInfo || !this.selectedDepartment
      );
    },
    departmentSelectDisabled() {
      return (
        !this.selectedService ||
        // !this.validRegNr ||
        !this.availableDepartments.length
      );
    },
    validRegNr() {
      return regNrRegexp.test(this.selectedRegNr);
    },
    validCarInfo() {
      return this.carInfo && this.carInfo.registerNumber;
    },
    selectDepartmentPrompt() {
      return this.noAvailableDepartments
        ? "Inga tillgängliga anläggningar för valt fordon"
        : "Välj anläggning";
    },
    carInfoDisplayName() {
      if (!this.validCarInfo) {
        return "";
      }
      const { brand, model, version, modelYear } = this.carInfo;
      return `${brand} ${model} ${version} ${modelYear}`;
    }
  },

  methods: {
    onServiceChange(value) {
      this.selectedService = value;
      this.onRegNrChange();
    },
    onDepartmentChange(value) {
      this.selectedDepartment = value;
    },
    async onRegNrChange() {
      if (this.selectedRegNr.length > 6) {
        this.selectedRegNr = this.selectedRegNr
          .split("")
          .slice(0, 6)
          .join("");
        return;
      }

      if (this.validRegNr) {
        await this.fetchCarInfo();
        if (this.serviceCompleted && this.validCarInfo) {
          await this.fetchDepartments();
          ServiceBookingManager.setCarInfo(this.carInfo);
          ServiceBookingManager.setRegNr(this.selectedRegNr);
          const availableSelectedDepartment = this.availableDepartments.find(department => department.value === this.selectedDepartment?.value);
          if (!availableSelectedDepartment) {
            this.showFetchedDepartmentError = this.selectedDepartment?.value.length > 0;
            this.selectedDepartment = null;
          } else {
            this.selectedDepartment = availableSelectedDepartment
          }
        }
      } else {
        this.carInfo = null;
      }
    },
    onRegNrClick() {
      if (!this.serviceCompleted) {
        this.selectServiceNotifier = true;
        setTimeout(() => {
          this.selectServiceNotifier = false;
        }, 200);
      }
    },
    onDepartmentClick() {
      this.onRegNrClick();
    },
    moveOn() {
      const { selectedService, selectedDepartment, selectedRegNr } = this;

      const serviceUrl = window.servicesConfig.serviceLinks.find(
        l => l.service === this.selectedService.value
      );
      if (!serviceUrl) {
        console.error("Servicepage not created");
        return;
      }
      const url = `${serviceUrl.servicePageUrl}?department=${selectedDepartment.value}&regNr=${selectedRegNr}`;
      window.location.assign(url);
    },
    moveOnInternal() {
      const { selectedService, selectedDepartment, selectedRegNr } = this;

      ServiceBookingManager.setService(selectedService);
      ServiceBookingManager.setDepartment(selectedDepartment);
      VueScrollTo.scrollTo(
          ".service-booking-wizard"
        );
    },
    async fetchCarInfo() {
      try {
        const res = await ServicesApi.fetchCarInfo({
          regNr: this.selectedRegNr
        });
        this.carInfo = res.data;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchServices() {
      try {
        const res = await ServicesApi.fetchServices();
        this.availableServices = res.data;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchDepartments() {
      this.noAvailableDepartments = false;
      try {
        const params = {
          regNr: this.selectedRegNr,
          service: this.selectedService?.value
        };
        const res = await ServicesApi.fetchDepartments(params);
        this.availableDepartments = res.data;
      } catch (e) {
        console.error(e);
      }
      if (!this.availableDepartments || !this.availableDepartments.length) {
        this.noAvailableDepartments = true;
      }
    }
  }
};
</script>
