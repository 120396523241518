import I3ChannelManager from "../models/I3ChannelManager";
import EonChannelManager from "../models/EonChannelManager";

export default ({ app, selector, setI3Channel = false, setEonChannel = false, props = {}, extractProps = () => ({}) }) => {
  const nodes = document.querySelectorAll(selector);
  const mounted = [];

  Array.prototype.forEach.call(nodes, el => {
    const appProps = {
      ...props,
      ...extractProps(el),
    };

    if (setI3Channel) {
      appProps.channel = I3ChannelManager.getByRef(el.getAttribute('data-i3-ref'));
    }

    if (setEonChannel) {
      appProps.channel = EonChannelManager.getByRef(el.getAttribute('data-eon-ref'));
    }

    const PropsApp = app(appProps);
    mounted.push(new PropsApp().$mount(el));
  });

  return mounted;
}
