var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcModal",
    {
      on: {
        close: function($event) {
          return _vm.$emit("modalClose")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("\n    " + _vm._s(_vm.modalHeader) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "choose-vehicle",
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _c(
            "VcFormStack",
            { attrs: { label: "Bilmärke" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  prompt: "Välj bilmärke",
                  options: _vm.manufacturerOptions,
                  value: _vm.manufacturer,
                  valueProp: "id",
                  valueType: "number",
                  labelProp: "name"
                },
                on: { change: _vm.selectManufacturer }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VcFormStack",
            { attrs: { label: "Bilmodell" } },
            [
              _c("VcFormSelect", {
                ref: "modelSelector",
                attrs: {
                  prompt: "Välj bilmodell",
                  options: _vm.modelOptions,
                  value: _vm.model,
                  valueProp: "id",
                  valueType: "string",
                  labelProp: "name"
                },
                on: { change: _vm.selectModel }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.displaySelectVariant
            ? _c(
                "VcFormStack",
                { attrs: { label: "Bilmotor" } },
                [
                  _c("VcFormSelect", {
                    attrs: {
                      prompt: "Välj bilmotor",
                      options: _vm.variantOptions,
                      value: _vm.variant,
                      valueProp: "id",
                      valueType: "string",
                      labelProp: "name"
                    },
                    on: { change: _vm.selectVariant }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displaySelectPackageType
            ? _c(
                "VcFormStack",
                { attrs: { label: "Paket" } },
                [
                  _c("VcFormSelect", {
                    attrs: {
                      prompt: "Välj paket",
                      options: _vm.packageOptions,
                      value: _vm.packageType,
                      valueProp: "label",
                      valueType: "string",
                      labelProp: "label"
                    },
                    on: { change: _vm.selectPackageType }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "VcFormStack",
            { attrs: { label: "Produkt" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: [
                    { value: "tyre", label: _vm.lang.Tyre.TyreLinkBtn },
                    { value: "wheel", label: _vm.lang.Tyre.WheelLinkBtn },
                    { value: "rim", label: _vm.lang.Tyre.RimLinkBtn }
                  ],
                  value: _vm.productType,
                  labelProp: "label",
                  valueProp: "value",
                  valueType: "string",
                  required: ""
                },
                on: {
                  change: function($event) {
                    _vm.productType = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VcFormStack",
            { attrs: { label: "Säsong" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: [
                    { value: _vm.EonTyreTypes.Summer, label: "Sommar" },
                    {
                      value: _vm.EonTyreTypes.WinterStudded,
                      label: "Vinter dubb"
                    },
                    {
                      value: _vm.EonTyreTypes.WinterFriction,
                      label: "Vinter friktion"
                    }
                  ],
                  value: _vm.tyreType,
                  labelProp: "label",
                  valueProp: "value",
                  valueType: "number",
                  required: ""
                },
                on: {
                  change: function($event) {
                    _vm.tyreType = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "btn btn--primary btn--block choose-spec__multi__btn",
              class: { disabled: !_vm.buttonEnabled },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [_vm._v("\n      " + _vm._s("Se produkter") + "\n    ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }