var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-forms--quick-contact" }, [
    _c("div", { staticClass: "contact-forms" }, [
      _c(
        "div",
        {
          staticClass: "contact-forms__item",
          class: {
            "contact-forms__item--selected": _vm.selectedTab === "phone"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "contact-forms__item-name",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.selectPhone($event)
                }
              }
            },
            [_vm._v("\n        Ring oss\n      ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "contact-forms__item-icon" }, [
            _vm.selectedTab === "phone"
              ? _c("i", { staticClass: "fal fa-angle-up" })
              : _c("i", { staticClass: "fal fa-angle-down" })
          ]),
          _vm._v(" "),
          _vm.selectedTab === "phone"
            ? _c("PhoneContactSection", {
                attrs: {
                  cities: _vm.cities,
                  department: _vm.department,
                  submittedSelectedLocation: _vm.selectedLocation
                },
                on: { onLocationChange: _vm.onLocationChange }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "contact-forms__item",
          class: {
            "contact-forms__item--selected": _vm.selectedTab === "message"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "contact-forms__item-name",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.selectMessage($event)
                }
              }
            },
            [_vm._v("\n        Skicka meddelande\n      ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "contact-forms__item-icon" }, [
            _vm.selectedTab === "message"
              ? _c("i", { staticClass: "fal fa-angle-up" })
              : _c("i", { staticClass: "fal fa-angle-down" })
          ]),
          _vm._v(" "),
          _vm.selectedTab === "message"
            ? _c("MessageContactSection", {
                attrs: {
                  cities: _vm.cities,
                  department: _vm.department,
                  service: _vm.service,
                  submittedSelectedLocation: _vm.selectedLocation
                },
                on: { onLocationChange: _vm.onLocationChange }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }