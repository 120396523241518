import Vue from 'vue';
import App from './App.vue';
import createStore from './store';
import fields from './data/fields';
import sections from './data/sections';
import { l10n } from '../../utils/l10n';

export default (props) => {
  const store = createStore();
  const lang = {
    Tyre: l10n.Tyre,
  };

  props.lang = lang;

  return Vue.extend({
    store,
    created() {
      const config = {
        fields,
        sections,
      };
      store.dispatch('config/load', config);
    },
    render: h => h(App, { props })
  });
};
