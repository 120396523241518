<template>
  <VcModal v-if="contactModalOpen" @close="onCloseContactModal" small>
    <div slot="header">
      Personligt prisförslag
    </div>
    <div v-if="!formSent" slot="content" class="vehile-list-simple-contact-form">
      <div class="contact-forms">
        <form class="contact-forms__form form-feedback-host">
          <h5>
            <strong>
              Kontakta oss för att få prisförslag
            </strong>
          </h5>

          <p>
            <small>
              Fyll i dina uppgifter så återkommer vi med ett personligt erbjudande.
            </small>
          </p>

          <div class="form-row">
            <div class="form-row__col">
              <div class="form-stack form-stack--required">
                <label>
                  Namn
                </label>
                <input v-model="inputName" class="form-control" type="text" autocomplete="name" placeholder="Ditt namn"
                  required>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-row__col">
              <div class="form-stack form-stack--required">
                <label>
                  E-post
                </label>
                <input v-model="inputEmail" class="form-control" type="email" autocomplete="email"
                  placeholder="Din e-postadress" required>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-row__col">
              <div class="form-stack form-stack--required">
                <label>
                  Mobilnummer
                </label>
                <input v-model="inputPhone" class="form-control" type="tel" placeholder="Ditt telefonnummer" required
                  autocomplete="tel">
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-row__col">
              <div class="form-stack form-stack--required">
                <label>
                  Närmaste anläggning
                </label>
                <div class="form-select">
                  <select class="form-control" v-model="selectedLocation">
                    <option value="">
                      Välj ort
                    </option>
                    <option v-for="location in locations" :value="location" :key="location">
                      {{ location }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-row__col">
              <div class="form-check form-check--sm form-check--inline">
                <input id="acceptedTerms" v-model="acceptedTerms" class="form-check__input" type="checkbox" />
                <label class="form-check__label" for="acceptedTerms">
                  Jag godkänner att Holmgrens Bil behandlar mina uppgifter som en del i hanteringen av detta ärende.
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-row__col">
              <button @click.prevent="sendForm" :disabled="!acceptedTerms || formSent"
                class="btn btn--primary btn--block" type="submit">Skicka förfrågan</button>
            </div>
          </div>

          <div class="form-text">
            <p>Alla personuppgifter som skickas in till Holmgrens Bil kommer att behandlas enligt bestämmelserna i EU:s
              dataskyddsförordningen (GDPR).&nbsp;<span style="text-decoration: underline;"><a
                  title="Läs om hur vi behandlar din personuppgifter"
                  href="/om-oss/personuppgiftspolicy">Här</a></span>&nbsp;kan du läsa mer om hur vi
              behandlar dina personuppgifter.</p>
          </div>
        </form>
      </div>
    </div>
    <div v-else slot="content">
      <div v-if="isLoading" class="vehile-list-simple-contact-form__loader">
        <FontAwesomeIcon :icon="faSpinnerThird" :spin="true">
        </FontAwesomeIcon>
      </div>
      <div v-else>
        <div class="form-feedback">
          <div class="loader loader--success" v-if="!error">
            <div class="loader__shape">
              <div class="loader__symbol">
              </div>
            </div>
            <div class="loader__header">
              Tack för ditt intresse!
            </div>
            <div class="loader__text">
              Vi hör av oss inom kort.
            </div>
          </div>
          <div class="loader loader--error" v-else>
            <div class="loader__shape">
              <div class="loader__symbol">
              </div>
            </div>
            <div class="loader__header">
              Något gick fel
            </div>
            <div class="loader__text">
              Vänligen försök igen eller kontakta oss
            </div>
          </div>
        </div>
      </div>
    </div>
  </VcModal>
</template>

<script>
import { VcModal } from "@holmgrensbil/vue-components";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Axios from "axios";
import { EventBus } from "../../utils/event-bus";
import gtm from "../../utils/gtm";

export default {
  components: {
    VcModal,
    FontAwesomeIcon
  },
  data() {
    return {
      acceptedTerms: false,
      contactModalOpen: false,
      formSent: false,
      isLoading: false,
      error: false,
      inputName: "",
      inputEmail: "",
      inputPhone: "",
      vehicleId: "",
      endDate: "",
      brandName: "",
      modelName: "",
      modelYear: "",
      color: "",
      selectedLocation: "",
      faSpinnerThird,
      locations: [
        "Jönköping",
        "Kalmar",
        "Karlskrona",
        "Linköping",
        "Norrköping",
        "Nyköping",
        "Nässjö",
        "Skövde",
        "Vetlanda",
        "Vimmerby",
        "Värnamo",
        "Växjö",
        "Örebro"
      ]
    };
  },
  async created() {
    EventBus.$on("open-vehicle-list-simple-contact-modal-pl", this.onOpenModal.bind(this));
  },
  methods: {
    async onOpenModal({ vehicleId, endDate, brandName, modelName, modelYear, color }) {
      this.vehicleId = vehicleId;
      this.endDate = endDate;
      this.brandName = brandName;
      this.modelName = modelName;
      this.modelYear = modelYear;
      this.color = color;
      this.contactModalOpen = true;
    },
    onCloseContactModal() {
      this.contactModalOpen = false;
      this.clearInputs();
    },
    clearInputs() {
      this.inputName = "";
      this.inputEmail = "";
      this.inputPhone = "";
      this.vehicleId = "";
      this.brandName = "";
      this.modelName = "";
      this.modelYear = "";
      this.color = "";
      this.endDate = "";
      this.isLoading = false;
      this.formSent = false;
      this.error = false;
      this.selectedLocation = "";
    },
    async sendForm() {
      if (this.acceptedTerms && this.vehicleId && this.inputEmail && this.inputEmail !== '' && this.$validateEmail(this.inputEmail)) {
        try {
          this.isLoading = true;
          this.formSent = true;
          let data = {
            customerName: this.inputName,
            customerEmail: this.inputEmail,
            customerPhone: this.inputPhone,
            genericCustomer: true,
            departmentCity: this.selectedLocation,
            vehicleId: this.vehicleId,
            registrationNumber: this.regNr,
            endDate: this.endDate,
            brandName: this.brandName,
            modelName: this.modelName,
            modelYear: this.modelYear,
            color: this.color
          }
          const { result } = await Axios.post("/api/Contact/SendReleasingForm", data);
          this.formSent = true;
          gtm.incomingSubmitPLForm({ brandName: this.brandName, location: this.selectedLocation });
        } catch (e) {
          console.warn(e);
          this.error = false;
        } finally {
          this.isLoading = false;
        }
      }
    },
  }
};
</script>