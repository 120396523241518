import Vuex from 'vuex';
import config from '../../shared/wizard/modules/config';
import fields from '../../shared/wizard/modules/fields';
import form from '../../shared/wizard/modules/form';
import lookup from '../../shared/wizard/modules/lookup';
import sections from '../../shared/wizard/modules/sections';
import periods from './modules/periods';

const sharedModules = {
  config,
  fields,
  form,
  lookup,
  sections,
};

const strict = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
  strict,
  modules: {
    ...sharedModules,
    periods,
  },
});
