var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.inModal
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  return _vm.$emit("modalClose")
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(_vm._s(_vm.modalHeader))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("TheForm", {
                    attrs: {
                      promoBg: _vm.promoBg,
                      promo: _vm.promo,
                      meta: _vm.meta,
                      header: _vm.header,
                      buttonText: _vm.buttonText,
                      carLookupError: _vm.carLookupError
                    },
                    on: {
                      change: _vm.onChange,
                      submit: _vm.onSubmit,
                      chooseVehicleClick: function($event) {
                        _vm.modalOpen = "choose-vehicle"
                      },
                      chooseTyreDimensionsClick: function($event) {
                        _vm.modalOpen = "choose-tyre-dimensions"
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        : _c("TheForm", {
            attrs: {
              promoBg: _vm.promoBg,
              promo: _vm.promo,
              meta: _vm.meta,
              header: _vm.header,
              buttonText: _vm.buttonText,
              carLookupError: _vm.carLookupError
            },
            on: {
              change: _vm.onChange,
              submit: _vm.onSubmit,
              chooseVehicleClick: function($event) {
                _vm.modalOpen = "choose-vehicle"
              },
              chooseTyreDimensionsClick: function($event) {
                _vm.modalOpen = "choose-tyre-dimensions"
              }
            }
          }),
      _vm._v(" "),
      _vm.modalOpen === "choose-vehicle"
        ? _c("ChooseVehicleApp", {
            attrs: {
              TyreSpec: _vm.TyreSpec,
              buttonHref: _vm.buttonHref,
              modalHeader: "Välj bilmodell",
              buttonText: "Se produkter"
            },
            on: {
              modalClose: function($event) {
                _vm.modalOpen = null
              }
            }
          })
        : _vm.modalOpen === "choose-tyre-dimensions"
        ? _c("ChooseTyreDimensionsApp", {
            attrs: {
              TyreSpec: _vm.TyreSpec,
              forceManual: _vm.forceManualTyreSize,
              buttonHref: _vm.buttonHref,
              modalHeader: "Ange däckdimensioner",
              buttonText: "Se produkter"
            },
            on: {
              modalClose: function($event) {
                _vm.modalOpen = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }