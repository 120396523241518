var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "contact-forms__form form-feedback-host" }, [
    _vm.cities.length
      ? _c("div", [
          _c("div", { staticClass: "form-stack" }, [
            _c("label", [_vm._v(" Välj anläggning ")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedLocation,
                      expression: "selectedLocation"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedLocation = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.onLocationChange()
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Välj anläggning")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cities, function(location) {
                    return _c(
                      "option",
                      {
                        key: location.value,
                        domProps: { value: location.value }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(location.name) +
                            "\n          "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.department
      ? _c("div", { staticClass: "info-block" }, [
          _c("i", { staticClass: "info-block__icon fas fa-map-marker-alt" }),
          _vm._v(" "),
          _c("div", { staticClass: "info-block__header" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.department.departmentName) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "u-c-muted" }, [
            _vm._v("\n      " + _vm._s(_vm.department.departmentAddress) + " "),
            _c("br"),
            _vm._v(
              "\n      " +
                _vm._s(_vm.department.departmentZip) +
                " " +
                _vm._s(_vm.department.departmentCity) +
                "\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.department
      ? _c("div", [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-row__col form-row__col--md-50" }, [
              _c("div", { staticClass: "form-stack form-stack--required" }, [
                _c("label", [_vm._v(" Namn ")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.name,
                      expression: "name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    autocomplete: "name",
                    name: "customerName",
                    placeholder: "Ditt för- & efternamn",
                    required: ""
                  },
                  domProps: { value: _vm.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.name = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row__col form-row__col--md-50" }, [
              _c("div", { staticClass: "form-stack form-stack--required" }, [
                _c("label", [_vm._v(" E-post ")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "email",
                    autocomplete: "email",
                    name: "customerEmail",
                    placeholder: "Din e-postadress",
                    required: ""
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                })
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-stack form-stack--required" }, [
            _c("label", [_vm._v(" Meddelande ")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message,
                  expression: "message"
                }
              ],
              staticClass: "form-control",
              attrs: {
                name: "customerMessage",
                placeholder: "Skriv ditt meddelande...",
                required: ""
              },
              domProps: { value: _vm.message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.message = $event.target.value
                }
              }
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.department && !_vm.messageSuccess
      ? _c(
          "button",
          {
            staticClass: "btn btn--primary btn--block",
            class: { disabled: !_vm.maySend },
            attrs: { type: "button" },
            on: { click: _vm.sendContactForm }
          },
          [_vm._v("\n    Skicka meddelande\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.department
      ? _c("div", { staticClass: "form-text" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-feedback", class: { active: _vm.messageSuccess } },
      [_vm._m(1)]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Alla personuppgifter som skickas in till Holmgrens Bil kommer att\n      behandlas enligt bestämmelserna i EU:s dataskyddsförordningen\n      (GDPR). "
      ),
      _c("span", { staticStyle: { "text-decoration": "underline" } }, [
        _c(
          "a",
          {
            attrs: {
              title: "Läs om hur vi behandlar din personuppgifter",
              href: "/om-oss/personuppgiftspolicy"
            }
          },
          [_vm._v("Här")]
        )
      ]),
      _vm._v(" kan du läsa mer om hur vi behandlar dina personuppgifter.\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader loader--success" }, [
      _c("div", { staticClass: "loader__shape" }, [
        _c("div", { staticClass: "loader__symbol" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loader__header" }, [
        _vm._v("Tack för ditt intresse!")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "loader__text" }, [
        _vm._v("Vi hör av oss inom kort.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }