<template>
  <div
    class="build-tyre__section"
    :class="{
      'build-tyre__section--selected': selected,
      'build-tyre__section--addon': isAddon,
    }"
  >
    <div class="build-tyre__section__header">
      {{ title }}
    </div>
    <div class="build-tyre__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: Boolean,
    isAddon: Boolean,
    title: String,
  }
}
</script>

<style>

</style>