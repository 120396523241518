import SelectionTypes from './SelectionTypes';
import SelectDepartment from '../SelectDepartment.vue';
import SelectService from '../SelectService.vue';
import SelectBrand from '../SelectBrand.vue';
import SelectRegNr from '../SelectRegNr.vue';

export default {
  [SelectionTypes.Services]: SelectService,
  [SelectionTypes.Departments]: SelectDepartment,
  [SelectionTypes.Brands]: SelectBrand,
  [SelectionTypes.RegNr]: SelectRegNr,
}
