<template>
  <div class="site-header__search">
    <div
      class="site-header__search__input"
      :class="{ 'site-header__search__input--open': searchOpen }"
    >
      <VcSearchBar
        placeholder="Sök fordon, anläggningar och mer..."
        noInputBorders
        :disableCancel="disableSearchCancel"
        v-model="searchString"
        @input="onSearchInput"
        @focus="onFocus"
        @submit="onClickAllResults(true)"
      >
      </VcSearchBar>
    </div>
    <transition name="search-field">
      <div v-if="searchOpen" class="search-content">
        <div v-if="loading" class="search-content__loading">
          <FontAwesomeIcon
            :icon="faSpinnerThird"
            :spin="true"
            class="search-content__loading__spinner"
          >
          </FontAwesomeIcon>
        </div>
        <div
          class="search-content__results__popular"
          v-else-if="showSearchResultContent && !searchHits"
        >
          <div class="search-content__results__heading">
            Populära sökningar
          </div>
          <div class="search-content__results__popular__list">
            <div
              class="search-content__results__popular__item"
              v-for="search in popularSearches"
              :key="search.query"
              @click="onPopularSearchSelect(search)"
            >
              {{ search.name }}
            </div>
          </div>
          <div
            class="search-content__results__popular__empty"
            v-if="!searchHits && hasMadeSearches"
          >
            Hittade inget resultat
          </div>
        </div>
        <div class="search-content__empty" v-else-if="!searchHits">
          Hittade inget resultat
        </div>
        <div
          v-else-if="
            !loading &&
              ((carResults.documents && carResults.documents.length > 0) ||
                (pageResults.documents && pageResults.documents.length > 0) ||
                // (departmentResults.documents &&
                //   departmentResults.documents.length > 0) ||
                (employeeResults.documents &&
                  employeeResults.documents.length > 0))
          "
        >
          <div class="search-content__results">
            <div
              v-if="carResults.documents.length > 0"
              class="search-content__results__column"
            >
              <div>
                <div class="search-content__results__heading">
                  Fordon
                </div>
                <div class="search-content__results__list">
                  <div
                    v-for="document in carResults.documents.slice(0, 4)"
                    :key="document.id"
                    class="search-content__results__column__product-spot"
                  >
                    <ResultItemCar :document="document" />
                  </div>
                </div>
              </div>
              <div
                v-if="carResults.documents.length > 4"
                class="search-content__btn"
              >
                <div
                  class="btn btn--primary btn--block"
                  @click="onClickAllResults()"
                >
                  {{ `Visa alla bilar (${carResults.numberOfHits})` }}
                </div>
              </div>
            </div>
            <div
              class="search-content__results__column search-content__results__column--scrollable"
            >
              <div
                v-if="facetPageResults.documents.length > 0"
                class="search-content__results__section"
              >
                <div
                  v-for="document in facetPageResults.documents"
                  :key="document.id"
                  class="search-content__results__column__search-spot"
                >
                  <ResultItem
                    :document="document"
                    :resultType="I3FederatedSearchTypes.FacetPages"
                  />
                </div>
              </div>
              <div
                v-if="pageResults.documents.length > 0"
                class="search-content__results__section"
              >
                <div class="search-content__results__heading">
                  Sidor
                </div>
                <div
                  v-for="document in pageResults.documents"
                  :key="document.id"
                  class="search-content__results__column__search-spot"
                >
                  <ResultItem
                    :document="document"
                    :resultType="I3FederatedSearchTypes.Episerver"
                  />
                </div>
              </div>
              <!-- <div
                v-if="departmentResults.documents.length > 0"
                class="search-content__results__section"
              >
                <div class="search-content__results__heading">
                  Anläggningar
                </div>
                <div
                  v-for="document in departmentResults.documents"
                  :key="document.id"
                  class="search-content__results__column__search-spot"
                >
                  <ResultItem
                    :document="document"
                    :resultType="I3FederatedSearchTypes.Departments"
                  />
                </div>
              </div> -->
              <div
                v-if="employeeResults.documents.length > 0"
                class="search-content__results__section"
              >
                <div
                  class="search-content__results__heading search-content__results__heading--in-list"
                >
                  Personal
                </div>
                <div
                  v-for="document in employeeResults.documents"
                  :key="document.id"
                  class="search-content__results__column__search-spot"
                >
                  <ResultItem
                    :document="document"
                    :resultType="I3FederatedSearchTypes.Employees"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { VcSearchBar } from "@holmgrensbil/vue-components";
import ChannelEvents from "../../constants/ChannelEvents";
import I3QueryParams from "../../constants/I3QueryParams";
import debounce from "debounce";
import ChannelQuery from "../../models/ChannelQuery";
import ResultItemCar from "../i3-site-search/ResultItemCar.vue";
import ResultItem from "../i3-site-search/ResultItem.vue";
import I3DocProduct from "../../models/I3DocProduct";
import Overlay from "../../models/Overlay";
import { EventBus } from "../../utils/event-bus";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import I3FederatedSearchTypes from "../../constants/I3FederatedSearchTypes";
import gtm from '../../utils/gtm';

export default {
  components: {
    VcSearchBar,
    ResultItemCar,
    ResultItem,
    FontAwesomeIcon
  },
  data() {
    return {
      components: [],
      searchOpen: false,
      searchString: null,
      disableSearchCancel: true,
      popularSearches: [],
      searchResults: [],
      loading: false,
      faSpinnerThird,
      searchHits: 0,
      executedQueries: [],
      I3FederatedSearchTypes
    };
  },
  props: {
    channel: Object
  },
  created() {
    this.searchDebounced = debounce(this.search.bind(this), 1000);
    this.popularSearches = this.channel.popularSearches || [];
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();

    $(".site-header__search__overlay").on("click", this.onCloseSearchResults);
    $(".site-overlay").on("click", this.onCloseSearchResults);
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  computed: {
    showSearchResultContent() {
      return !!this.searchString || this.popularSearches.length > 0;
    },
    carResults() {
      return (
        this.components.find(component => component.id === "documentsCars") ||
        {}
      );
    },
    facetPageResults() {
      return (
        this.components.find(
          component => component.id === "documentsFacetPages"
        ) || {}
      );
    },
    pageResults() {
      return (
        this.components.find(
          component => component.id === "documentsEpiserver"
        ) || {}
      );
    },
    departmentResults() {
      return (
        this.components.find(
          component => component.id === "documentsDepartments"
        ) || {}
      );
    },
    employeeResults() {
      return (
        this.components.find(
          component => component.id === "documentsEmployees"
        ) || {}
      );
    },
    hasMadeSearches() {
      return this.executedQueries.length > 0;
    }
  },
  methods: {
    onSearchInput(value) {
      this.loading = true;
      this.selectedPopularSearch = false;
      this.searchDebounced(value);
    },
    search(str) {
      str = str.replaceAll('-', ' ');
      const query = new ChannelQuery(this.channel.defaultQuery);
      if (str) {
        const searchString = str.length >= 3 ? `${str}*` : str;

        query.setParam(I3QueryParams.FreeText, searchString);
        
        gtm.searchEvent({searchTerm: str});
        this.channel.execute(query);
      } else {
        this.components = [];
        this.searchHits = 0;
        this.executedQueries = [];
        this.loading = false;
      }
    },
    onFocus() {
      if (!this.searchOpen) {
        this.searchOpen = true;
        EventBus.$emit("site-header-search-focused", { overlayIsOpen: false });
      }
    },
    onCloseSearchResults() {
      if (this.searchOpen) {
        EventBus.$emit("site-header-search-blurred", { overlayIsOpen: true });
        this.searchOpen = false;
      }
    },
    onQuerySuccess() {
      this.components = this.channel.data.components.components || [];
      this.searchHits = this.channel.data.stats
        ? this.channel.data.stats.totalHits || 0
        : 0;
      this.executedQueries = this.channel.executedQueries;
      this.loading = false;
    },
    onClickAllResults(isEnterPress) {
      if (this.searchString?.match(/[A-Za-z]{3}[0-9]{2}[A-Za-z0-9]{1}/) && isEnterPress) {
        if (this.carResults?.documents?.length === 1) {
          const product = new I3DocProduct(this.carResults.documents[0]);
          window.location.href = product.permalink;
        }
      } else if (!isEnterPress) {
        const { origin, MainVehicleSearchPage } = window;
        window.location.href = `${origin}${MainVehicleSearchPage}?q=${this.searchString.replaceAll('-', ' ')}*`;
      }
    },
    onPopularSearchSelect(popularSearch) {
      const { origin, MainVehicleSearchPage } = window;
      window.location.href = `${origin}${MainVehicleSearchPage}${popularSearch.query}`;
    }
  }
};
</script>
