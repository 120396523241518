import events from "../mixins/events";
import SaveVehicleEvents from "../constants/SaveVehicleEvents";

let SaveVehicleManager = {
  alterVehicleList() {
    this.emit(SaveVehicleEvents.AlterVehicleList);
  },
  onAlterVehicleList(cb) {
    this.on(SaveVehicleEvents.AlterVehicleList, cb);
  },
};

Object.assign(SaveVehicleManager, events);

export default SaveVehicleManager;