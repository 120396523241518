var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn btn--white btn--xs",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [_vm._v("\n  Ändra\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }