<template>
  <div class="field-input-images">
    <label>
      {{field.label}}
    </label>

    <div class="field-input-images__grid">
      <div v-for="image in field.value"
        :key="image"
        class="field-input-images__grid-item">
        <div class="file-thumbnail"
          :style="{'background-image': `url(${image}?${ImageResizerPresets.FileThumbnail})`}">
          <div class="file-thumbnail__delete"
            @click="onDeleteImage(image)">
            <i class="fal fa-times"></i>
          </div>
        </div>
      </div>

      <div class="field-input-images__grid-item">
        <VcInputFile
          :label="uploading ? 'Laddar upp...' : field.placeholder"
          @change="onFileChange(field, $event)">
          <div slot="icon">
            <font-awesome-icon
              :icon="uploading ? faSpinnerThird : faCameraAlt"
              :spin="uploading ? true : false">
            </font-awesome-icon>
          </div>
        </VcInputFile>
      </div>
    </div>
  </div>
</template>

<script>
import { VcInputFile } from '@holmgrensbil/vue-components';
import { mapGetters } from 'vuex';
import { uploadImage, deleteImage } from '../../../utils/image-service';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ImageResizerPresets from '../../../constants/ImageResizerPresets';
import FieldTypes from './constants/FieldTypes';
import { faSpinnerThird, faCameraAlt } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    VcInputFile,
    FontAwesomeIcon,
  },
  props: {
    field: Object,
  },
  data() {
    return {
      deleteKeys: {},
      ImageResizerPresets,
      uploading: false,
      faSpinnerThird,
      faCameraAlt,
    };
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
    }),
  },
  methods: {
    onFileChange(field, file) {
      const folder = this.fieldByType(FieldTypes.RegNr).value;

      this.uploading = true;
      uploadImage(file, folder).then(res => {
        const {
          imageUrl,
          deleteKey,
        } = res.data;

        this.deleteKeys[imageUrl] = deleteKey;
        this.uploading = false;
        this.$emit('change', [
          ...this.field.value,
          imageUrl,
        ]);
      });
    },

    onDeleteImage(image) {
      const deleteKey = this.deleteKeys[image];

      deleteImage(deleteKey);
      this.$emit('change', this.field.value.filter(img => img !== image));
    },
  },
}
</script>
