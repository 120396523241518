import Overlay from "../models/Overlay";
import OverlayEvents from '../constants/OverlayEvents';
import I3Filter from "../apps/i3-filter";
import I3FilterButton from "../apps/i3-filter-button";
import mountApp from "../utils/mount-app";
import ChannelEvents from "../constants/ChannelEvents";
import I3ChannelManager from "../models/I3ChannelManager";

export default {
  init() {
    this.i3FilterSelector = '[data-app=i3-filter]';
    this.$i3Filter = $(this.i3FilterSelector);
    this.filterOverlay = new Overlay({ aside: true });
    this.ref = this.$i3Filter.data('i3-ref');

    this.channel = I3ChannelManager.getByRef(this.ref);
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $('body')
      .on('click', '.page-header__control--filter', this.onFilterClick.bind(this))
      .on('click', '.filter-bar__btn', this.onFilterClick.bind(this))
      .on('click', '#floating-filter', this.onFilterClick.bind(this));
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess.bind(this));
    this.onQuerySuccess();
    this.filterOverlay.on(OverlayEvents.BackdropClose, () => {
      this.channel.emit(ChannelEvents.SendSearchSignal);
    });

    $('.vehicle-listing-filter__top-filter').on('scroll')
  },

  modifyDOM() {
    const vehicleFilterButtonSelector = '[data-app=i3-filter-button]';

    mountApp({
      app: I3FilterButton,
      selector: vehicleFilterButtonSelector,
      setI3Channel: true,
      props: {
        channel: this.channel,
      },
      extractProps: el => ({
        hideWatchButton: el.getAttribute('data-hide-watch-button') == "true",
        mobileMenu: el.getAttribute('data-mobile-menu') == "true",
      }),
    });
  },

  onQuerySuccess() {
    this.updateFilterBadge();
  },

  onFilterClick() {
    if (!this.filterOverlay.initialized) {
      this.filterOverlay.init({ content: this.$i3Filter });

      mountApp({
        app: I3Filter,
        selector: this.i3FilterSelector,
        props: {
          channel: this.channel,
          onClose: () => {
            this.channel.emit(ChannelEvents.SendSearchSignal);
            this.filterOverlay.close();
          },
        },
        extractProps: el => ({
          btnText: el.getAttribute('data-filter-button-text'),
        }),
      });
    }

    setTimeout(() => {
      this.filterOverlay.open();
    }, 1);

    return false;
  },

  updateFilterBadge() {
    const badgeCount = this.channel.getQueryFilters().length;
    const $badge = $('.vehicle-listing-filter__btn .badge');

    if (badgeCount) {
      $badge.text(badgeCount).show();
    } else {
      $badge.empty().hide();
    }
  },
}
