var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SearchWatch", {
        attrs: {
          header: _vm.header,
          text: _vm.text,
          tags: _vm.tags,
          feedbackHeader: _vm.feedbackHeader,
          feedbackText: _vm.feedbackText,
          feedbackContactHeader: _vm.feedbackContactHeader,
          feedbackContactText: _vm.feedbackContactText,
          privacyPolicyText: _vm.lang.General.PrivacyPolicyText,
          done: _vm.done,
          loading: _vm.loading,
          error: _vm.error,
          brandToWatch: _vm.brandToWatch
        },
        on: { saveSearches: _vm.onSaveSearches }
      }),
      _vm._v(" "),
      _vm.showModal
        ? _c("TheSearchWatchModal", {
            attrs: {
              "email-found": _vm.emailFound,
              email: _vm.email,
              feedbackHeader: _vm.feedbackHeader,
              feedbackText: _vm.feedbackText,
              feedbackContactHeader: _vm.feedbackContactHeader,
              feedbackContactText: _vm.feedbackContactText,
              privacyPolicyText: _vm.lang.General.PrivacyPolicyText,
              isContactSubmitProp: _vm.isContactSubmit
            },
            on: {
              close: function($event) {
                return _vm.close()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }