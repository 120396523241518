var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "filter-bar__tag",
      class: {
        "filter-bar__tag--child": _vm.isChild
      },
      on: { click: _vm.remove }
    },
    [
      _vm._v("\n  " + _vm._s(_vm.label) + "\n  "),
      !_vm.locked
        ? _c(
            "span",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("remove")
                }
              }
            },
            [_c("i", { staticClass: "fal fa-times filter-bar__tag__icon" })]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }