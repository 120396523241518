<template>
  <div class="product-images" @click="$emit('onImageClick')">
    <div class="product-images__item"
      :class="{
        'product-images__item--overlay': overlay,
        'product-images__item--fullscreen': isQuickBuy,
      }">
      <img v-bind:src="image.src" v-bind:alt="image.alt" v-bind:title="image.alt" />
      <span v-if="overlayLabel">
        {{overlayLabel}}
      </span>
      <div class="product-images__item__actions" v-if="shouldShowSaveBtns">
        <div
          class="product-images__item__actions__btn"
          :class="{
            'product-images__item__actions__btn--selected': isVehicleCompared,
          }"
          @click.stop="compareVehicle"
        >
          <FontAwesomeIcon :icon="falBalance"></FontAwesomeIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBalanceScale as falBalance } from "@fortawesome/pro-light-svg-icons";
import { EventBus } from '../../utils/event-bus';
export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    overlayLabel: {
      type: String,
    },
    regNr: {
      type: String,
    },
    shouldShowSaveBtns: {
      type: Boolean,
      required: false,
      default: false,
    },
    isQuickBuy: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      falBalance,
      comparedVehicles: [],
    }
  },
  computed: {
    isVehicleCompared() {
      return this.comparedVehicles.includes(this.regNr);
    }
  },
  mounted() {
    this.getComparedVehicles();
    EventBus.$on('comparedVehicleRemoved', this.getComparedVehicles.bind(this))
  },
  methods: {
    compareVehicle() {
      EventBus.$emit('compareVehicle', this.regNr)
      this.getComparedVehicles();
    },
    getComparedVehicles() {
      if (window.sessionStorage)
      {
        const data = sessionStorage.getItem('comparedVehicles');
        let comparedVehicles = [];
        if (data) {
          comparedVehicles = JSON.parse(data);
        }
        this.comparedVehicles = comparedVehicles;
      }
      else {
        this.comparedVehicles = [];
      }
    }
  },
}
</script>
