var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.products.length
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              ref: "grid",
              staticClass: "eon-result__items",
              class: { "eon-result__querying": _vm.querying }
            },
            _vm._l(_vm.products, function(product) {
              return _c(
                "div",
                { key: product.id, staticClass: "eon-result__item" },
                [
                  _c("EonProductSpot", {
                    attrs: {
                      product: product,
                      regNr: _vm.regNr,
                      selectedProductType: _vm.selectedProductType,
                      selectedProduct: _vm.selectedProduct,
                      isBuildWheel: _vm.isBuildWheel
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("VcIntersectObserver", {
            attrs: { options: { rootMargin: "200px" } },
            on: { intersect: _vm.onIntersect }
          }),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "eon-product-services-modal" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }