const selectors = {
  el: ".link-toggler",
  expanderEl: ".expand-toggler"
};

const classes = {
  itemActive: "link-toggler--active",
  expandedToggler: "expand-toggler--active",
  expanded: "expanded"
};

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $("body")
      .on("click", ".link-toggler .link-toggler-click", this.onClick.bind(this))
      .on("click", ".expand-toggler", this.expand.bind(this));
  },

  onClick({ currentTarget }) {
    $(currentTarget)
      .closest(selectors.el)
      .toggleClass(classes.itemActive);
    return false;
  },

  expand({ currentTarget }) {
    var expandTarget = $(currentTarget).data("expandtarget");
    $(currentTarget).toggleClass(classes.expandedToggler);
    $(expandTarget).toggleClass(classes.expanded);
    return false;
  }
};
