var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "overlay-footer",
      class: { "overlay-footer--yellow-bg": _vm.yellowBg }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "overlay-footer__actions" }, [
        _vm.showBack
          ? _c(
              "a",
              {
                staticClass: "btn btn--outline",
                on: {
                  click: function($event) {
                    return _vm.$emit("back")
                  }
                }
              },
              [
                _c("i", { staticClass: "fal fa-angle-left" }),
                _vm._v("\n      Tillbaka\n    ")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn--primary",
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }