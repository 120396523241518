// import quicklink from 'quicklink';
import lazySizes from "lazysizes";
import linkToggler from "../components/link-toggler";
import navTree from "../components/nav-tree";
import scrolled from "../components/scrolled";
import siteHeader from "../components/site-header";
import siteUsps from "../components/site-usps";
import I3ChannelManager from "../models/I3ChannelManager";
import EonChannelManager from "../models/EonChannelManager";
import productImages from "../components/product-images";
import promoTeasers from "../components/promo-teasers";
import vehicleHistoryForms from "../components/vehicle-history-forms";
import configuratorTeaser from "../components/configurator-teaser";
import gallerySlideshow from "../components/gallery-slldeshow";
import infoBlock from "../components/info-block";
import employeeList from "../components/employee-list";
import ttjs from "../components/ttjs";
import slider from "../components/slider";
import epiForms from "../components/epi-forms";
import modalSourceAjax from "../components/modal-source-ajax";
import modalSourceElement from "../components/modal-source-element";
import sparePartsModal from "../components/spare-parts-modal";
import imageVideoGrid from "../components/image-video-grid";
import transparentHeader from "../components/transparent-header";
import contactForms from "../components/contact-forms";
import datepicker from "../components/datepicker";
import scrollTo from "../components/scroll-to";
import vehicleContact from "../components/vehicle-contact";
import tallCardSlider from "../components/tall-card-slider";
import i3TotalCount from "../components/i3-total-count";
import commonApps from "../components/common-apps";
import siteAlerts from "../components/site-alerts";
import servicebookingblock from "../components/servicebookingblock";
import departmentSelect from "../components/department-select";
import callAction from "../components/call-action";
import departmentInformation from "../components/department-information";
import vdnReportLink from "../components/vdn-report-link";
import productMain from "../components/product-main";
import toggler from "../components/toggler";
import departmentActions from "../components/department-actions";
import TyreSpec from "../models/TyreSpec";
import reviews from "../components/reviews";
import unsubscribeEmail from "../components/unsubscribe-email";
import sharePage from "../components/share-page";
import campaignGridBlock from "../components/campaign-grid-block";
import getClaim from "../components/getClaim";
import reviewsWidget from "../components/reviews-widget";
import reviewsBlock from "../components/reviews-block";
import releasingBlock from "../components/releasing-block";


export default {
  components: [
    siteAlerts,
    lazySizes,
    linkToggler,
    navTree,
    scrolled,
    siteHeader,
    siteUsps,
    ttjs,
    productImages,
    promoTeasers,
    vehicleHistoryForms,
    configuratorTeaser,
    gallerySlideshow,
    infoBlock,
    employeeList,
    slider,
    epiForms,
    modalSourceAjax,
    modalSourceElement,
    sparePartsModal,
    imageVideoGrid,
    transparentHeader,
    contactForms,
    datepicker,
    scrollTo,
    vehicleContact,
    tallCardSlider,
    i3TotalCount,
    commonApps,
    servicebookingblock,
    departmentSelect,
    callAction,
    departmentInformation,
    vdnReportLink,
    productMain,
    departmentActions,
    toggler,
    reviews,
    unsubscribeEmail,
    sharePage,
    campaignGridBlock,
    getClaim,
    reviewsWidget,
    reviewsBlock,
    releasingBlock
  ],
  init() {
    TyreSpec.load();

    if (window.I3Config) {
      I3ChannelManager.createChannels(window.I3Config);
    }

    if (window.EonConfig) {
      EonChannelManager.createChannels(window.EonConfig);
    }

    this.components.forEach(c => c.init());
  }
};
