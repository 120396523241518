var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [_vm._v("\n    Teknisk information\n  ")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c(
          "div",
          { staticClass: "form-row__col" },
          [
            _c("SectionField", {
              attrs: { type: _vm.FieldTypes.EquipmentLevel }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.displayEquipmentDescription
        ? _c(
            "div",
            { staticClass: "field-input-box" },
            [
              _c("SectionField", {
                attrs: { type: _vm.FieldTypes.EquipmentText }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SectionField", { attrs: { type: _vm.FieldTypes.City } }),
      _vm._v(" "),
      _c("SectionField", { attrs: { type: _vm.FieldTypes.WinterTires } }),
      _vm._v(" "),
      _vm.displayWinterTiresDescription
        ? _c(
            "div",
            { staticClass: "field-input-box" },
            [
              _c("SectionField", {
                attrs: { type: _vm.FieldTypes.WinterTiresText }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SectionField", { attrs: { type: _vm.FieldTypes.ServiceBook } }),
      _vm._v(" "),
      _vm.displayServiceBookDescription
        ? _c(
            "div",
            { staticClass: "field-input-box" },
            [
              _c("SectionField", {
                attrs: { type: _vm.FieldTypes.ServiceBookText }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SectionField", { attrs: { type: _vm.FieldTypes.Damage } }),
      _vm._v(" "),
      _vm.displayDamageDescription
        ? _c(
            "div",
            { staticClass: "field-input-box" },
            [
              _c("SectionField", {
                attrs: { type: _vm.FieldTypes.DamageText }
              }),
              _vm._v(" "),
              _c("SectionField", {
                attrs: { type: _vm.FieldTypes.DamageImages }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SectionField", { attrs: { type: _vm.FieldTypes.AvailableNow } }),
      _vm._v(" "),
      _c("SectionField", { attrs: { type: _vm.FieldTypes.ExtraInfo } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }