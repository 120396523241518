<template>
  <div class="eon-product-buy-actions">
    <div class="eon-product-buy-actions__row">
      <VcFormStack v-if="!isBuildWheel" label="Antal" small>
        <VcFormSelect :options="quantityOptions" :value="quantity" labelProp="name" valueProp="value" valueType="number"
          small @change="onQuantityChange">
        </VcFormSelect>
      </VcFormStack>
      <div v-if="this.selectedProduct && this.selectedProduct.id === this.product.id"
        class="eon-product-buy-actions__selected">
        <i class="fas fa-check"></i>
        {{ typeText.selected }}
      </div>
      <a v-else class="btn btn--primary btn--block" :class="{
        'disabled': loading,
        'btn--build-tyre': isBuildWheel,
      }" @click="onClick">
        {{ isBuildWheel ? typeText.normal : 'Lägg i varukorgen' }}
      </a>
    </div>
    <VcFeedbackMessage danger slim v-if="error">
      Produkten kunde inte läggas till i varukorgen.
    </VcFeedbackMessage>
  </div>
</template>

<script>
import { VcFormSelect, VcFormStack, VcFeedbackMessage } from '@holmgrensbil/vue-components';
import CartApi from '../../../models/CartApi';
import RequestStates from '../../../constants/RequestStates';
import LineItemCustomDataKeys from '../../../constants/LineItemCustomDataKeys';
import siteHeaderCartBadge from '../../../components/site-header-cart-badge';
import { EventBus } from '../../../utils/event-bus';
import TyreBuildSteps from '../../eon-filter-page/constants/TyreBuildSteps';
import TyreSpec from '../../../models/TyreSpec';

export default {
  props: {
    product: Object,
    regNr: String,
    selectedServices: {
      type: Array,
      default: () => [],
    },
    isBuildWheel: Boolean,
    selectedProduct: Object,
    selectedProductType: String,
  },
  components: {
    VcFormSelect,
    VcFormStack,
    VcFeedbackMessage,
  },
  mounted() {
    this.$emit('quantity', this.quantity);
  },
  data() {
    return {
      quantity: this.product.quantityDefaultOption,
      quantityOptions: this.product.quantityOptions,
      requestState: null,
    };
  },
  computed: {
    loading() {
      return this.requestState === RequestStates.Request;
    },

    error() {
      return this.requestState === RequestStates.Failure;
    },
    typeText() {
      if (this.selectedProductType === TyreBuildSteps.Tyre.toString()) {
        return {
          selected: 'Valt däck',
          normal: 'Välj däck',
        };
      } else {
        return {
          selected: 'Vald fälg',
          normal: 'Välj fälg',
        }
      }
    }
  },
  methods: {
    onQuantityChange(value) {
      this.quantity = value;
      this.$emit('quantity', this.quantity);
    },

    onClick() {
      if (this.isBuildWheel) {
        if (!this.selectedProduct || this.selectedProduct.id !== this.product.id) {
          EventBus.$emit('selectEonProduct', this.product)
        }
      } else {
        this.addToCart();
      }
    },

    async addToCart() {
      this.requestState = RequestStates.Request;
      TyreSpec.load();

      try {
        const customData = [];

        if (this.regNr) {
          customData.push({
            name: LineItemCustomDataKeys.RegistrationNumber,
            value: this.regNr,
          });
        }

        if (!TyreSpec.isEmpty) {
          customData.push({
            name: LineItemCustomDataKeys.CarBrand,
            value: TyreSpec.data?.eonCar?.brand?.name,
          });
        }

        customData.push({
          name: LineItemCustomDataKeys.Brand,
          value: this.product.brandName
        });

        customData.push({
          name: LineItemCustomDataKeys.Variant,
          value: this.product.gtmVariantName
        });

        if (this.product.hasInternalSupplier) {
          customData.push({
            name: LineItemCustomDataKeys.InternalSupplier,
            value: true
          });
        }

        const data = {
          quantity: this.quantity.value,
          productId: this.product.id,
          customData,
          childProducts: this.selectedServices.map(id => ({ productId: id, linkedQuantity: true })),
        };
        const res = await CartApi.addItem(data);
        this.requestState = RequestStates.Success;
        this.$emit('added', data);
        siteHeaderCartBadge.setCount(res.data.lineItems.filter(li => !li.parentLineItem).length);
      } catch (e) {
        this.requestState = RequestStates.Failure;
      }
    },
  },
}
</script>
