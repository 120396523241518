var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c("div", { staticClass: "regnr-input" }, [
          _c(
            "div",
            { staticClass: "input-group" },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("VcFormStack", { attrs: { label: "Regnr" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.store.regNr,
                      expression: "store.regNr"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "form-control--invalid": _vm.store.lookupInvalid },
                  attrs: { placeholder: "ABC123", type: "text" },
                  domProps: { value: _vm.store.regNr },
                  on: {
                    keyup: function($event) {
                      return _vm.changeRegNr()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.store, "regNr", $event.target.value)
                    }
                  }
                })
              ])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-8" }, [
        _vm.store.lookupResult
          ? _c(
              "div",
              {
                staticClass:
                  "feedback-message feedback-message--slim feedback-message--success"
              },
              [
                _c("div", { staticClass: "lookup-result" }, [
                  _c("div", { staticClass: "lookup-result__item" }, [
                    _c("label", [
                      _vm._v("\n              Fordonsinfo\n            ")
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.store.lookupResult.carName) +
                        "\n          "
                    )
                  ])
                ])
              ]
            )
          : _c("div", { staticClass: "u-c-muted" }, [
              _c("small", [
                _vm._v(
                  "Ange ditt registreringsnummer så letar vi upp ett serviceavtal som\n          passar just ditt fordon."
                )
              ])
            ])
      ])
    ]),
    _vm._v(" "),
    _vm.store.noPackages
      ? _c("div", { staticClass: "row u-margin-top" }, [_vm._m(2)])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c("h4", { staticClass: "u-fw-medium" }, [
        _vm._v("Ange registreringsnummer")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12" }, [
      _c(
        "div",
        {
          staticClass:
            "feedback-message feedback-message--slim feedback-message--danger"
        },
        [
          _c("div", { staticClass: "feedback-message__text" }, [
            _c("small", [
              _vm._v(
                "Tyvärr kan vi inte beräkna ett pris på din bil just nu men fyll i\n            formuläret och skicka in så kommer vi återkomma med ett\n            prisförslag till dig."
              )
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }