var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: _vm.page.permalink } }, [
    _c("div", { staticClass: "search-response-link" }, [
      _c("div", { staticClass: "u-c-muted search-response-link__container" }, [
        _vm.ancestors
          ? _c("span", { staticClass: "search-response-link__route" }, [
              _vm._v("\n        " + _vm._s(_vm.ancestors + " /") + "\n      ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "search-response-link__route search-response-link__route--primary"
          },
          [_vm._v("\n        " + _vm._s(_vm.heading) + "\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }