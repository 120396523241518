<template>
  <TyreBuildSectionWrapper
    :isAddon="isAddon"
    :title="title"
  >
    <div>
      <div class="build-tyre__main">
        <div v-for="addon in addons" :key="addon.id" class="build-tyre__addon">
          <div class="build-tyre__addon__content">
            <div class="build-tyre__addon__title-container">
              <div class="build-tyre__addon__label">
                Tillägg
              </div>
              <div class="build-tyre__addon__title">
                {{ addon.name }}
              </div>
              <div v-html="addon.description" class="build-tyre__addon__description"></div>
            </div>
            <div class="build-tyre__addon__image-container">
              <img :src="addon.imageUrl" height="34" width="34" />
            </div>
          </div>
          <div>
            <VcOptionBox
              :selected="selectedAddons.includes(addon.id)"
              :heading="'Ja, tack'"
              checkbox
              @click="onSelectAddon(addon.id)"
            >
              <div slot="header-aside" v-if="addon.price">
                <div class="build-tyre__addon__price-container">
                  <span
                    class="build-tyre__addon__price"
                    :class="{ 'build-tyre__addon__price--discount': addon.price.current < addon.price.ordinary }">
                    {{ $formatPrice(addon.price.current) }} / hjul
                  </span>
                  <span
                    v-if="addon.price.current < addon.price.ordinary"
                    class="build-tyre__addon__price build-tyre__addon__price--obsolete">
                    {{ $formatPrice(addon.price.ordinary) }} / hjul
                  </span>
                </div>
              </div>
            </VcOptionBox>
          </div>
        </div>
      </div>
      <div v-if="addons.length > 0" class="build-tyre__footer">
        <div class="build-tyre__footer__content build-tyre__footer__content--price">
          <div class="build-tyre__footer__price-row">
            <div class="build-tyre__footer__price-title">
              TOTALKOSTNAD
            </div>
            <div class="build-tyre__footer__price">
              {{ $formatPrice(totalPrice) }}
            </div>
          </div>
          <!-- <div class="build-tyre__footer__price-row build-tyre__footer__price-row--meta">
            <div class="build-tyre__footer__price-title">
              (inkl. moms)
            </div>
            <div class="build-tyre__footer__price">
              Avbetalning: 0 kr/mån
            </div>
          </div> -->
        </div>
        <div class="build-tyre__footer__action">
          <button class="btn btn--primary btn--block btn--icon" @click="$emit('onAddToCart')" :class="{ disabled: loading }">
            <i class="far fa-shopping-cart"></i>
            Lägg i varukorgen
          </button>
        </div>
      </div>
    </div>
  </TyreBuildSectionWrapper>
</template>

<script>
import TyreBuildSectionWrapper from './TyreBuildSectionWrapper.vue';
import { VcOptionBox, VcPriceTag } from "@holmgrensbil/vue-components";
export default {
  components: {
    TyreBuildSectionWrapper,
    VcOptionBox,
    VcPriceTag,
  },
  props: {
    isAddon: Boolean,
    title: String,
    totalPrice: Number,
    loading: Boolean,
    addons: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedAddons: [],
    }
  },
  methods: {
    onSelectAddon(id) {
      if (this.selectedAddons.includes(id)) {
        this.selectedAddons = [...this.selectedAddons.filter(addonId => id !== addonId)];
      } else {
        this.selectedAddons = [...this.selectedAddons, id];
      }

      this.$emit('onAddonsSelected', this.selectedAddons);
    }
  }
}
</script>

<style>

</style>