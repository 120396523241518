var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eon-product-buy-actions" },
    [
      _c(
        "div",
        { staticClass: "eon-product-buy-actions__row" },
        [
          !_vm.isBuildWheel
            ? _c(
                "VcFormStack",
                { attrs: { label: "Antal", small: "" } },
                [
                  _c("VcFormSelect", {
                    attrs: {
                      options: _vm.quantityOptions,
                      value: _vm.quantity,
                      labelProp: "name",
                      valueProp: "value",
                      valueType: "number",
                      small: ""
                    },
                    on: { change: _vm.onQuantityChange }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.selectedProduct && this.selectedProduct.id === this.product.id
            ? _c("div", { staticClass: "eon-product-buy-actions__selected" }, [
                _c("i", { staticClass: "fas fa-check" }),
                _vm._v("\n      " + _vm._s(_vm.typeText.selected) + "\n    ")
              ])
            : _c(
                "a",
                {
                  staticClass: "btn btn--primary btn--block",
                  class: {
                    disabled: _vm.loading,
                    "btn--build-tyre": _vm.isBuildWheel
                  },
                  on: { click: _vm.onClick }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.isBuildWheel
                          ? _vm.typeText.normal
                          : "Lägg i varukorgen"
                      ) +
                      "\n    "
                  )
                ]
              )
        ],
        1
      ),
      _vm._v(" "),
      _vm.error
        ? _c("VcFeedbackMessage", { attrs: { danger: "", slim: "" } }, [
            _vm._v("\n    Produkten kunde inte läggas till i varukorgen.\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }