var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom-action-bar" }, [
    _vm.vehicleData
      ? _c("div", { staticClass: "bottom-action-bar__data" }, [
          _c("div", { staticClass: "bottom-action-bar__vehicle" }, [
            _c("div", { staticClass: "bottom-action-bar__image" }, [
              _c("img", { attrs: { src: _vm.vehicleData.image } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom-action-bar__vehicle__info" }, [
              _c("div", { staticClass: "bottom-action-bar__vehicle__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.vehicleData.brandName +
                        " " +
                        _vm.vehicleData.modelName
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bottom-action-bar__vehicle__subtitle" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.vehicleData.modelText) +
                      "\n        "
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm.priceDisplay
            ? _c("div", { staticClass: "bottom-action-bar__price" }, [
                _c(
                  "div",
                  { staticClass: "bottom-action-bar__price__container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "price-tag",
                        class: {
                          "price-tag--discount":
                            _vm.priceDisplay.current < _vm.priceDisplay.ordinary
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$formatPriceNoSuffix(
                                _vm.priceDisplay.current.toFixed()
                              ) + _vm.priceDisplay.suffix
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.priceDisplay.current < _vm.priceDisplay.ordinary
                      ? _c("div", { staticClass: "price-tag--obsolete" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$formatPriceNoSuffix(
                                  _vm.priceDisplay.ordinary.toFixed()
                                ) + _vm.priceDisplay.suffix
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "bottom-action-bar__price__pricing-meta" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.priceMetaString) + "\n      "
                    )
                  ]
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.contact
      ? _c("div", { staticClass: "bottom-action-bar__actions" }, [
          _c(
            "a",
            {
              staticClass: "btn btn--primary",
              attrs: { href: "#" },
              on: { click: _vm.onOpenContactModal }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "vehicle-configurator__btn-icon",
                attrs: { icon: _vm.faEnvelope }
              }),
              _vm._v(
                "\n        " + _vm._s(_vm.lang.Contact.ContactUs) + "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn--muted btn--block",
              attrs: {
                href: _vm.showNumber ? "tel:" + _vm.departmentPhoneNumber : "#"
              },
              on: { click: _vm.onPhoneClick }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "vehicle-configurator__btn-icon",
                attrs: { icon: _vm.faPhone }
              }),
              _vm._v(" "),
              !_vm.showNumber
                ? _c("span", [
                    _vm._v(
                      "\n        " + _vm._s("Visa telefonnummer") + "\n      "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.departmentPhoneNumber) +
                        "\n      "
                    )
                  ])
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }