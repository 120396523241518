var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reviews-widget__star-list" },
    _vm._l(_vm.getStarArray(_vm.rating), function(star, index) {
      return _c(
        "div",
        {
          key: _vm.listId + index,
          staticClass: "reviews-widget__star-container"
        },
        [
          _c("img", {
            staticClass: "reviews-widget__star",
            attrs: { src: "/Static/images/pale-star.svg" }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "reviews-widget__star reviews-widget__star--active",
            style: "width: " + _vm.width * star + "px",
            attrs: { src: "/Static/images/gold-star.svg" }
          })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }