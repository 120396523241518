var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStack",
    { attrs: { label: _vm.option.heading, required: _vm.option.required } },
    [
      _c("div", { staticClass: "form-select" }, [
        _c(
          "select",
          {
            staticClass: "form-control",
            domProps: { value: _vm.selectedValue },
            on: {
              change: function($event) {
                return _vm.$emit("change", $event.target.value)
              }
            }
          },
          _vm._l(_vm.option.values, function(value) {
            return _c(
              "option",
              { key: value.name, domProps: { value: value.value } },
              [_vm._v("\n        " + _vm._s(value.name) + "\n      ")]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }