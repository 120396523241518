<template>
  <div>
    <SectionField :type="FieldTypes.RimType">
    </SectionField>
    <SectionField :type="FieldTypes.Message">
    </SectionField>
    
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    SectionField,
    FontAwesomeIcon,
  },
  data() {
    return {
      FieldTypes,
      faSpinnerThird,
    };
  },
  computed: {
    ...mapState({
      reportTypesFetchState: state => state.rimTypes.fetchState,
    }),

    ...mapGetters({
      fieldByType: 'fields/byType',

    }),
    loading() {
      return this.reportTypesFetchState === 1;
    }
  },
}
</script>
