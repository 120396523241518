import mountApp from "../utils/mount-app";
import EonSingleProduct from "../apps/eon-single-product";
import AssociatedProductSlider from "../apps/associated-eon-products";
import tyreFeatures from '../components/tyre-features';

export default {
  init() {
    this.modifyDOM();
    tyreFeatures.init();
  },

  modifyDOM() {
    this.mountEonSingleProduct();
    this.mountAssociatedProductSlider();


  },

  mountEonSingleProduct() {
    mountApp({
      app: EonSingleProduct,
      selector: '[data-app=eon-single-product]',
      extractProps: el => ({
        productObject: JSON.parse(el.getAttribute('data-product')),
        productUsps: el.getAttribute('data-product-usps')
      }),
    });
  },

  mountAssociatedProductSlider() {
    mountApp({
      app: AssociatedProductSlider,
      selector: '[data-app=associated-eon-products]',
      extractProps: el => ({
        productObject: JSON.parse(el.getAttribute('data-product')),
        title: el.getAttribute('data-title'),
        link: el.getAttribute('data-link'),
        linkTitle: el.getAttribute('data-link-title'),
      }),
    });
  },
};
