import Axios from '../axios';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').
      on('click', '.optout-form button', this.onClick.bind(this));
  },

  onClick({ currentTarget }) {
    const $currentTarget = $(currentTarget);
    const optKey = $currentTarget.data('optoutkey');
    this.unsubscribe(optKey, $currentTarget);
  },

  async unsubscribe(optKey, $currentTarget) {
    try {
      const response = await Axios.post(`/api/mypages/searches/unsubscribe/${optKey}`);
      $currentTarget.parent().hide();
      $currentTarget.parent().siblings('.success-message').removeClass('u-d-none').show();
      return response.status === 200;
    } catch(e) {
      return false;
    }

  }
}
