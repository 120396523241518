export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
  },
}
