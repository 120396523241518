<template>
  <div>
    <div v-if="isExpress" class="form-row">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.DamageText">
        </SectionField>
      </div>
    </div>
    <div v-if="isExpress" class="form-row">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.DamagePlacement">
        </SectionField>
      </div>
    </div>
    <div class="form-row">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.DamageNumber">
        </SectionField>
      </div>
    </div>
    <div v-if="isExpress" class="form-row">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.Mileage">
        </SectionField>
      </div>
    </div>
    <small v-if="isExpress" class="u-c-muted">{{ lang.App.ImagesHeadline }}</small>
    <div class="field-input-images" v-if="isExpress">
      <div class="field-input-images__grid">
        <div v-for="field in imageFields"
          :key="field.type"
          class="field-input-images__grid-item">
          <SectionField :type="field.type">
          </SectionField>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SectionField from '../shared/wizard/SectionField.vue';
import InputTypes from '../shared/wizard/constants/InputTypes';
import FieldTypes from './constants/FieldTypes';

export default {
  components: {
    SectionField,
  },
  props: {
    section: Object,
  },
  data() {
    return {
      FieldTypes,
    }
  },
  computed: {
    ...mapGetters({
      fieldsBySection: 'fields/bySection',
      fieldsByInputType: 'fields/byInputType',
      fieldByType: 'fields/byType',
    }),
    ...mapState({
      lang: state => state.config.lang,
    }),
    sectionFields() {
      return this.fieldsBySection(this.section.type);
    },
    imageFields() {
      return this.fieldsByInputType(InputTypes.Image);
    },
    isExpress() {
      return this.fieldByType(FieldTypes.IsExpress).value;
    },
  },
}
</script>
