var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "quickbuy__actions-container" }, [
    _c(
      "div",
      { staticClass: "quickbuy__actions" },
      [
        _vm.product.available
          ? _c(
              "a",
              {
                staticClass: "btn btn--outline offset",
                attrs: { href: _vm.product.permalink },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onMoreInfoClick($event)
                  }
                }
              },
              [_vm._v("\n      Läs mer\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn--primary",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onContactClick($event)
              }
            }
          },
          [_vm._v("\n      Ta kontakt\n    ")]
        ),
        _vm._v(" "),
        _vm.modalOpen
          ? _c(
              "VcModal",
              {
                on: {
                  close: function($event) {
                    _vm.modalOpen = false
                  }
                }
              },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v("\n        Ta kontakt\n      ")
                ]),
                _vm._v(" "),
                _c("div", {
                  attrs: { slot: "content" },
                  domProps: { innerHTML: _vm._s(_vm.modalContent) },
                  slot: "content"
                })
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }