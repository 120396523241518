<template>
  <div class="star-rating">
    <FontAwesomeIcon v-for="r in range"
      :key="r"
      :icon="r <= field.value ? faStarSolid : faStarLight"
      class="star-rating__item"
      @click="onClick(r)">
    </FontAwesomeIcon>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faStar as faStarLight } from "@fortawesome/pro-light-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/pro-solid-svg-icons";

export default {
  props: {
    field: Object,
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    const range = [...Array(5).keys()].map(v => String(v + 1));

    return {
      range,
      faStarLight,
      faStarSolid,
    };
  },
  methods: {
    onClick(value) {
      if (value === this.field.value) {
        this.$emit('change', null);
      } else {
        this.$emit('change', value);
      }
    },
  },
}
</script>
