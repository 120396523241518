var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "campaign-list__item", attrs: { href: _vm.article.url } },
    [
      _c("div", [
        _c("div", { staticClass: "campaign-list__item__image" }, [
          _c("img", { attrs: { src: _vm.image.src, alt: _vm.image.alt } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "campaign-list__item__content" }, [
          _c("div", [
            _c("a", { attrs: { href: _vm.article.url } }, [
              _c("h3", { staticClass: "campaign-list__item__title" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.article.heading) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "blog-list__item-intro" }, [
              _vm._v("\n          " + _vm._s(_vm.introText) + "\n        ")
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "blog-list__category-listing" },
            _vm._l(_vm.article.blogTags, function(categoryItem) {
              return _c(
                "div",
                {
                  key: categoryItem + "-categoryItemBlogListing",
                  staticClass: "blog-category-item"
                },
                [_vm._v("\n          " + _vm._s(categoryItem) + "\n        ")]
              )
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }