<template>
  <div class="my-pages__section my-tyres">
    <div v-if="sectionData.length && selectedLocation">
      <div class="saved-vehicles" v-for="vehicle in sectionData" :key="vehicle.vehicleData.id">
        <div class="product-info">
          <span class="product-info__title">{{ vehicle.vehicleData.brandName }}</span>
          <span class="product-info__subtitle">{{ vehicle.vehicleData.modelName }} {{ vehicle.vehicleData.trimPackage }}</span>
        </div>
        <div v-if="vehicle.tyreData.data.tyres.summer" class="product-info product-info--sub-container">
          <div>
            <div>
              <span class="product-info__title">{{ 'Sommardäck' }}</span>
            </div>
            <div class="product-info__tyre-header">
              <div
                class="section-item__status-icon "
                :class="{
                  'section-item__status-icon--yellow': vehicle.tyreData.data.in_stock === 'summer',
                  'section-item__status-icon--green': vehicle.tyreData.data.in_stock !== 'summer',
                }"
              ></div>
              <span class="product-info__subtitle">
                {{ vehicle.tyreData.data.in_stock === 'summer' ? 'På däckhotell' : 'På bil' }}
              </span>
            </div>
            <div>
              <TyreRow v-for="tyre in vehicle.tyreData.data.tyres.summer" :key="tyre.id" :tyre="tyre" />
            </div>
            <div class="product-info__sub-action">
              <a
                class="btn btn--muted btn--block" 
                @click="goToPurchase(vehicle.vehicleData, EonTyreTypes.Summer)">
                {{ 'Köp nya sommardäck' }}
              </a>
            </div>
          </div>
        </div>
        <div v-if="vehicle.tyreData.data.tyres.winter" class="product-info product-info--sub-container">
          <div>
          <div>
            <span class="product-info__title">{{ 'Vinterdäck' }}</span>
          </div>
            <div class="product-info__tyre-header">
              <div
                class="section-item__status-icon "
                :class="{
                  'section-item__status-icon--yellow': vehicle.tyreData.data.in_stock === 'winter',
                  'section-item__status-icon--green': vehicle.tyreData.data.in_stock !== 'winter',
                }"
              ></div>
              <span class="product-info__subtitle">
                {{ vehicle.tyreData.data.in_stock === 'winter' ? 'På däckhotell' : 'På bil' }}
              </span>
            </div>
            <div>
              <TyreRow v-for="tyre in vehicle.tyreData.data.tyres.winter" :key="tyre.id" :tyre="tyre" />
            </div>
            <div class="product-info__sub-action">
              <a
                class="btn btn--muted btn--block" 
                @click="goToPurchase(vehicle.vehicleData, EonTyreTypes.WinterFriction)">
                {{ 'Köp nya vinterdäck' }}
              </a>
            </div>
          </div>
        </div>
        <div class="product-info product-info--sub-container">
          <a
            class="btn btn--primary btn--block" 
            @click="() => goToService(vehicle.vehicleData)">
            {{ 'Boka hjulskifte' }}
          </a>
        </div>
      </div>
    </div>
    <div v-else class="my-pages__section__tyre-empty">
      <div class="my-pages__section__text-container">
        <span class="my-pages__section__text">
          {{ tyreError ? 'Inga däckhotellsavtal hittades för dina fordon på vald ort' : 'För att se din information om eventuellt däckhotell, lägg till ditt fordon under Mina fordon och välj den ort som du har dina däck.' }}
        </span>
      </div>
      <div v-if="locations.length">
        <div class="form-stack">
          <label>
          Din ort
          </label>
          <div class="form-select">
            <select class="form-control" v-model="selectedLocation">
              <option value="">
                Välj ort
              </option>
              <option v-for="location in locations" :value="location.value" :key="location.value">
                {{location.name}}
              </option>
            </select>
          </div>
        </div>
        <a
          class="btn btn--primary btn--block" 
          @click="onLocationChange">
          {{ 'Spara' }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { EventBus } from '../../../utils/event-bus';
import TyreRow from '../TyreRow.vue';
import Axios from 'axios';
import TyreSpec from '../../../models/TyreSpec';
import EonApi from "../../../models/EonApi";
import EonTyreTypes from "../../eon-filter-page/constants/EonTyreTypes";

export default {
  components: {
    FontAwesomeIcon,
    TyreRow,
  },
  props: {
    sectionData: Array,
    currentUser: Object,
    lang: Object,
    tyreError: Boolean
  },
  data() {
    return {
      locations: [],
      selectedLocation: this.currentUser.location,
      faCheck,
      faExclamationTriangle,
      EonTyreTypes,
    };
  },
  async created() {
    try {
      const res = await Axios.get('/api/contact/GetLocations');
      this.locations = res.data;
    } catch(e) {
      console.warn(e)
    }
  },
  methods: {
    async onLocationChange() {
      try {
        await Axios.post('/api/mypages/location', {
          myLocation: this.selectedLocation
        });
        this.$emit('onChangeLocation', this.selectedLocation)
        EventBus.$emit('updated-user', {
          ...this.currentUser,
            location: this.selectedLocation
        });
      } catch(e) {

      }
    },
    async goToService(product) {
      try {
        const res = await Axios.get(`/api/mypages/department?mainServiceName=Däcktjänster&registrationNumber=${product.registrationNumber}`);
        window.location.href = res.data;
      } catch(e) {
        return null;
      }
      return null;
    },
    async goToPurchase(vehicle, type) {
      try {
        const res = await EonApi.getCar(vehicle.registrationNumber);

        TyreSpec.set({ regNr: vehicle.registrationNumber });
        TyreSpec.setCar(res.data);

        window.location.href = `/handla/dack/produkter?licenseplate=${vehicle.registrationNumber}&tyreType=${type}`;
      } catch(e) {
        return null;
      }
      return null;
    },
  },
}
</script>
