<template>
  <VcModal modalBodyClass="search-watch__modal" @close="$emit('close')">
    <div slot="header">
      Bevakning
    </div>
    <div slot="content" v-if="!hasCustomContent">
      <VcFeedbackMessage success v-if="isContactSubmit || isContactSubmitProp">
        <div slot="header">
          {{ feedbackContactHeader }}
        </div>
        {{ feedbackContactText }}
      </VcFeedbackMessage>
      <VcFeedbackMessage success v-else>
        <div slot="header">
          {{ feedbackHeader }}
        </div>
        {{ feedbackText }}
      </VcFeedbackMessage>

      <form @submit.prevent="submit" style="margin-top: 15px;" class="search-watch__modal__form form-feedback-host">
        <h5>
          <strong v-if="emailFound">
            OBS! {{email}} är kopplad till ett konto
          </strong>
          <strong v-else>
           Vill du även skapa ett konto?
          </strong>
        </h5>
        <p>
          <small v-if="emailFound">
            Logga in för att hantera dina bevakningar. Har du glömt ditt lösenord? <a class="link" @click.prevent="onForgotPasswordClick"> Klicka här</a>
          </small>
          <small v-else>
            <i class="fas fa-check"></i> Favoritmarkera bilar
            <i class="fas fa-check"></i> Spara och hantera bevakningar
            <i class="fas fa-check"></i> Se dina ordrar
          </small>
        </p>

        <div class="form-row">
          <div class="form-row__col form-row__col--md-50">
            <div class="form-stack form-stack--required">
              <label>
                E-post
              </label>
              <input v-model="inputEmail" class="form-control" type="email" autocomplete="email" placeholder="E-post" required>
            </div>
          </div>
          <div class="form-row__col form-row__col--md-50">
            <div class="form-stack form-stack--required">
              <label>
                Lösenord
              </label>
              <input v-model="password" class="form-control" type="password" :placeholder="emailPlaceHolder" required>
            </div>
          </div>
        </div>
        <button :disabled="btnDisabled" type="submit" class="btn btn--primary btn--block">
            {{btnText}}
        </button>

        <VcFeedbackMessage danger v-if="errorMessage.length" slim>
          {{errorMessage}}
        </VcFeedbackMessage>

        <VcFeedbackMessage success v-if="registerSuccess" slim>
          Registreringen lyckades. Ett verifieringsmail har nu skickats till er {{this.inputEmail}} där det finns intruktioner om hur ni verifierar er e-post.
        </VcFeedbackMessage>

        <div class="form-text" v-html="privacyPolicyText"></div>
      </form>
    </div>
    <slot name="customContent" slot="content" v-else>
    </slot>
  </VcModal>
</template>

<script>
import { VcModal, VcFeedbackMessage } from '@holmgrensbil/vue-components';
import SignInStatuses from '../my-pages/constants/SignInStatuses';
import Axios from '../../axios';
import { EventBus } from '../../utils/event-bus';
const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

export default {
  components: {
    VcModal,
    VcFeedbackMessage,
  },

  props: {
    emailFound: Boolean,
    email: String,
    privacyPolicyText: String,
    hasCustomContent: Boolean,
    feedbackHeader: String,
    feedbackText: String,
    feedbackContactHeader: String,
    feedbackContactText: String,
    isContactSubmitProp: Boolean,
  },

  data() {
    return {
      password: '',
      inputEmail: this.email,
      registerError: false,
      errorMessage: '',
      btnDisabled: false,
      registerSuccess: false,
      isContactSubmit: false,
    }
  },
  mounted() {
    EventBus.$on('isContactSubmit', (isContact) => this.isContactSubmit = isContact)
  },

  computed: {
    emailPlaceHolder() {
      return this.emailFound ? 'Ange lösenord' : 'Välj lösenord';
    },
    btnText() {
      return this.emailFound ? 'Logga in' : 'Skapa konto';
    }
  },

  methods: {
    onForgotPasswordClick() {
      this.$emit('close');
      document.dispatchEvent(new CustomEvent("toggle-mypages"));
    },
    async submit() {
      if(this.emailFound) {
        await this.signIn();
      } else {
        await this.register();
      }
    },
    async register() {
      this.errorMessage = '';
      if(!pattern.test(this.password)) {
        this.registerError = true;
        this.errorMessage = "Lösenordet måste innehålla minst 6 tecken, en stor bokstav, en liten bokstav, en siffra och ett specialtecken (!, #, &%, ? eller liknande)"
        return;
      }
      this.btnDisabled = true;
      try {
        const result = await Axios.post('/api/login/register', {
          email: this.inputEmail,
          password: this.password,
          confirmPassword: this.password,
        });
        if(result && result.data && result.data.signInStatus === SignInStatuses.Success) {
          this.registerSuccess = true;
        } else {
          this.errorMessage = "Det gick tyvärr inte att skapa ett konto just nu.";
        }
      } catch(e) {
        this.errorMessage = "Det gick tyvärr inte att skapa ett konto just nu.";
      } finally {
        this.btnDisabled = false;
      }
    },
    async signIn() {
      this.errorMessage = '';

      this.btnDisabled = true;
      try {
        const result = await Axios.post('/api/login/login', {
          email: this.inputEmail,
          password: this.password,
          rememberMe: true,
        });

        if(result && result.data && result.data.signInStatus === SignInStatuses.Success) {
          window.location.reload();
        } else {
          if(result.data.message) {
            this.errorMessage = result.data.message;
            return;
          }
          if(result.data.signInStatus === SignInStatuses.RequiresVerification) {
            this.errorMessage = 'Du behöver verifiera din e-post innan du kan logga in';
            return;
          }
          this.errorMessage = 'Det gick ej att logga in. Kontrollera att inloggningsuppgifterna stämmer.';
        }
      } catch(e) {
        console.error(e);
        this.errorMessage = 'Det gick ej att logga in. Kontrollera att inloggningsuppgifterna stämmer.';
      } finally {
        this.btnDisabled = false;
      }
    }
  }
}

</script>
