export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {},

  modifyDOM() {
    this.initActionbar();
  },

  initActionbar() {
    const $actionbar = $(".bottom-action-bar");
    const $hideElem = $(".sticky-stopper");

    $(window).scroll(() => {
      const windowTop = $(window).scrollTop();
      const hidePosition = $hideElem.offset().top;
      const height = $(window).height();

      if (hidePosition + 200 > windowTop + height) {
        $actionbar.removeClass('bottom-action-bar--visible');
      } else {
        $actionbar.addClass('bottom-action-bar--visible');
      }
    });
  }
};
