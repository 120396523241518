import Axios from '../../../../axios';
import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../../constants/FieldTypes';

const state = {
  items: [],
  fetchState: null,
  locked: false,
};

const getters = {};

const actions = {
  async fetch({ state, commit, dispatch }) {
    if (state.locked) {
      return;
    }

    commit('fetchRequest');

    try {
      const res = await Axios.get('/api/vehicle/GetTradeInDepartments');
      commit('fetchSuccess', res.data);

      dispatch('fields/set', {
        type: FieldTypes.City,
        options: res.data,
        value: null,
      }, { root: true });
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },

  lock({ commit, dispatch }, { email, name }) {
    commit('locked', true);

    const departmentObject =  { name, value: email };

    dispatch('fields/set', {
      type: FieldTypes.City,
      label: 'Inbytet kommer hanteras av',
      placeholder: null,
      options: [ departmentObject ],
      value: departmentObject,
    }, { root: true });
  }
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, items) {
    state.fetchState = RequestStates.Success;
    state.items = items;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },

  locked(state, value) {
    state.locked = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
