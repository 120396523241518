<template>
    <div>
        <div ref="list" class="vehicle-table">
            <div class="row table-header">
                <div class="table-column col-3">Fordon</div>
                <div class="table-column col-2">Årsmodell</div>
                <div class="table-column col-1">Färg</div>
                <div class="table-column col-2">Registrerad</div>
                <div class="table-column col-2">Tillgänglig</div>
                <div class="table-column col-2"></div>
            </div>
            <ListItem v-for="listItem in listItems" :key="listItem.document._id" :vehicle="listItem.vehicle"
                @click="onItemClick(listItem)">
            </ListItem>
        </div>
        <VcIntersectObserver v-if="listItems.length" :options="{ rootMargin: '200px' }" @intersect="onIntersect">
        </VcIntersectObserver>
        <ContactModalRental></ContactModalRental>
        <ContactModalPrivateLeasing></ContactModalPrivateLeasing>
    </div>
</template>

<script>
import Vue from "vue";
import plVehicle from "../../models/plVehicle";
import { EventBus } from "../../utils/event-bus";
import ListItem from "./ListItem.vue";
import { VcIntersectObserver } from "@holmgrensbil/vue-components";
import ContactModalRental from "./ContactModalRental.vue";
import ContactModalPrivateLeasing from "./ContactModalPrivateLeasing.vue";

export default {
    props: {
        channel: Object,
        documents: {
            type: Array,
            default: () => []
        },
        queriedAt: {
            type: Date
        }
    },
    components: {
        ListItem,
        VcIntersectObserver,
        ContactModalRental,
        ContactModalPrivateLeasing
    },
    data() {
        return {
        };
    },
    methods: {
        onIntersect() {
            this.keepSelectedItemsOnNextQuery = true;
            this.$emit("onNext");
        },
        async onItemClick(item) {
            // if (item.vehicle.isRental) {
            //     EventBus.$emit('open-vehicle-list-simple-contact-modal-rental', { vehicle: item.vehicle });
            // } else {
                EventBus.$emit('open-vehicle-list-simple-contact-modal-pl', { vehicleId: item.vehicle.id, endDate: item.vehicle.endDate, brandName: item.vehicle.brandName, modelName: item.vehicle.modelName, modelYear: item.vehicle.modelYear, color: item.vehicle.color });
            //}
        },
    },

    computed: {
        listItems() {
            let items = this.documents.map(document => ({
                document,
                vehicle: new plVehicle(document)
            }));

            return items;
        }
    }
};
</script>
