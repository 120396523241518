<template>
  <div>
    <div class="toolbar toolbar--arrow" :class="{'toolbar--danger': TyreSpec.isEmpty}">
      <div class="container">
        <div class="inline-bullets" v-if="TyreSpec.isEmpty">
          <div class="inline-bullets__item">
            <a class="link link--underline" @click="modalOpen = 'choose-spec'">Välj fordon</a>
            så anpassar vi sökresultatet åt dig
          </div>
        </div>
        <div class="inline-bullets" v-else>
          <div class="inline-bullets__item" v-if="TyreSpec.regNr">
            <strong>
              {{TyreSpec.regNr}}
            </strong>
          </div>
          <div class="inline-bullets__item" v-if="TyreSpec.carName">
            {{TyreSpec.carName}}
          </div>
          <div class="inline-bullets__item inline-bullets__item--btn">
            Däckdimension:
            {{TyreSpec.tyreSize.diameter}}" {{TyreSpec.tyreSize.width}}/{{TyreSpec.tyreSize.aspectRatio}}R{{TyreSpec.tyreSize.diameter}}
          </div>
          <div class="inline-bullets__item inline-bullets__item--btn">
            <a class="link link--primary link--underline" @click="modalOpen = 'choose-tyre-dimensions'">
              Ändra däckdimension
            </a>
          </div>
          <div class="inline-bullets__item">
            <a class="link link--primary link--underline" @click="modalOpen = 'choose-spec'">
              Byt fordon
            </a>
          </div>
        </div>
        <div>
          <a class="link link--underline clear-search" @click="clearSearch" :href="landingPageUrl">
            Nollställ
          </a>

        </div>
      </div>
      <TheTooltip
        v-if="tooltipOpen"
        @markAsSeen="markAsSeen"
        @openTyreSelector="openTyreSelector"
        :TyreSpec="TyreSpec"
      >

      </TheTooltip>
    </div>
    <div v-if="TyreSpec.comment">
      <div class="toolbar toolbar--arrow toolbar--warning">
        <div class="container">
          {{ TyreSpec.comment }}
        </div>
      </div>
    </div>

    <ChooseSpecApp v-if="modalOpen === 'choose-spec'"
      :TyreSpec="TyreSpec"
      :buttonHref="buttonHref"
      header="Fyll i ditt regnummer"
      :buttonText="`${TyreSpec.isEmpty ? 'Välj' : 'Byt'} fordon`"
      :modalHeader="`${TyreSpec.isEmpty ? 'Välj' : 'Byt'} fordon`"
      forceManualTyreSize
      inModal
      @modalClose="modalOpen = null">
    </ChooseSpecApp>
    <ChooseTyreDimensionsApp v-else-if="modalOpen === 'choose-tyre-dimensions'"
      :TyreSpec="TyreSpec"
      :buttonHref="buttonHref"
      modalHeader="Byt däckdimension"
      buttonText="Byt däckdimension"
      :channel="channel"
      @modalClose="modalOpen = null">
    </ChooseTyreDimensionsApp>
  </div>
</template>

<script>
import { VcModal } from '@holmgrensbil/vue-components';
import ChooseSpecApp from '../choose-spec/App.vue';
import ChooseTyreDimensionsApp from '../choose-tyre-dimensions/App.vue';
import TheTooltip from './TheTooltip.vue';
import Cookies from 'js-cookie';
import ChannelEvents from '../../constants/ChannelEvents';

const TOOLTIP_SEEN_COOKIE = 'UserHasSeenTooltip';

export default {
  components: {
    VcModal,
    ChooseSpecApp,
    ChooseTyreDimensionsApp,
    TheTooltip,
  },
  props: {
    TyreSpec: Object,
    channel: Object,
  },
  data() {
    return {
      modalOpen: null,
      tooltipOpen: false,
      buttonHref: window.location.toString().split('?')[0],
      tyreSpec: this.TyreSpec,
      landingPageUrl: window.EonConfig.landingPageUrl,
    };
  },
  mounted() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    const tooltipSeenCookie = Cookies.get(TOOLTIP_SEEN_COOKIE);
    if (!tooltipSeenCookie) {
      this.tooltipOpen = true;
    }
  },
  methods: {
    async markAsSeen() {
      await Cookies.set(TOOLTIP_SEEN_COOKIE, {
        date: new Date(),
      }, {
        expires: 365,
      });

      this.tooltipOpen = false;
    },
    openTyreSelector() {
      this.tooltipOpen = false;
      this.modalOpen = 'choose-tyre-dimensions'
    },
    clearSearch() {
      this.TyreSpec.clear();
    },
    onQuerySuccess() {
      this.tyreSpec = this.TyreSpec;
    }
  }
}
</script>
