import Axios from '../../../../axios';
import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../../constants/FieldTypes';

const state = {
  items: [],
  fetchState: null,
};

const getters = {};

const actions = {
  async fetch({ commit, dispatch }, { departmentNr }) {
    commit('fetchRequest');

    try {
      const data = {
        departmentNr,
      };

      const res = await Axios.get('/api/service/GetBlockedDays', { params: data });
      commit('fetchSuccess', res.data);

      dispatch('fields/set', {
        type: FieldTypes.Date,
        options: res.data,
        value: null,
      }, { root: true });
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, items) {
    state.fetchState = RequestStates.Success;
    state.items = items;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
