<template>
  <div class="trade-in-valuation">
    <div class="form-row">
      <div class="trade-in-valuation__col trade-in-valuation__col--w-25">
        <SectionField :type="FieldTypes.RegNr">
        </SectionField>
      </div>
      <div class="trade-in-valuation__col trade-in-valuation__col--w-25">
        <SectionField :type="FieldTypes.Mileage">
        </SectionField>
      </div>
      <div class="trade-in-valuation__col trade-in-valuation__col--w-50">
        <SectionField :type="FieldTypes.Email">
        </SectionField>
      </div>
      <div class="trade-in-valuation__col trade-in-valuation__col--w-50">
        <SectionField :type="FieldTypes.Phone">
        </SectionField>
      </div>
    </div>
    <div class="trade-in-valuation__condition">
      <SectionField :type="FieldTypes.VehicleCondition">
      </SectionField>
    </div>
  </div>
</template>

<script>
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';

export default {
  components: {
    SectionField,
  },
  data() {
    return {
      FieldTypes,
    };
  },
}
</script>
