import RequestStates from "../../../../constants/RequestStates";
import CartApi from "../../../../models/CartApi";

const state = {
  items: [],
  fetchState: {},
  selectState: null,
};

const getters = {
  byId: ({ items }) => paymentId => {
    return items.find(d => d.id === paymentId);
  },
};

const actions = {
  async fetch({ commit }) {
    commit('fetchRequest');

    try {
      const res = await CartApi.fetchCheckoutPayments();
      commit('fetchSuccess', res.data);
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },

  async select({ commit, dispatch }, payment) {
    commit('selectRequest');

    try {
      const res = await CartApi.setCheckoutPayment(payment.id);
      commit('selectSuccess', { payment, cart: res.data });
      dispatch('cart/setSelectedPayment', payment.id, {root: true});
    } catch (e) {
      commit('selectFailure');
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = {
      state: RequestStates.Request,
    };
  },

  fetchSuccess(state, items) {
    const selectedItem = items.find(i => i.selected);
    state.selected = selectedItem && selectedItem.id;
    state.items = items;
    state.fetchState = {
      state: RequestStates.Success,
    };
  },

  fetchFailure(state) {
    state.fetchState = {
      state: RequestStates.Failure,
      // message: error.response.data && error.response.data.message,
    };
  },

  selectRequest(state) {
    state.selectState = RequestStates.Request;
  },

  selectSuccess(state) {
    state.selectState = RequestStates.Success;
  },

  selectFailure(state) {
    state.selectState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
