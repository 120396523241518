var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("SectionField", { attrs: { type: _vm.FieldTypes.RegNr } }),
          _vm._v(" "),
          _vm.lookupResultSuccess === false
            ? _c(
                "div",
                { staticClass: "regnr-input-sibling" },
                [_c("SectionField", { attrs: { type: _vm.FieldTypes.Brand } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "regnr-input-sibling btn btn--primary",
              class: { disabled: _vm.lookupLoading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("\n      Fortsätt\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.lookupResultSuccess === false && !_vm.section.validate
        ? _c("VcFeedbackMessage", { attrs: { info: "" } }, [
            _vm._v(
              "\n    Kunde inte slå upp din bil. Ändra regnummer eller välj märke.\n  "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }