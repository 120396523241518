import isDefined from "../../../../utils/is-defined";
import PaymentInputTypePatterns from "../../constants/PaymentInputTypePatterns";

const state = {
  items: [],
};

const getters = {
  invalid({ items }) {
    return items.filter(item => {
      const errors = [];

      if (!isDefined(item.value)) {
        errors.push('required');
      }

      const pattern = PaymentInputTypePatterns[item.type];

      if (pattern && !pattern.test(item.value)) {
        errors.push('pattern');
      }

      return !!errors.length;
    });
  },

  export({ items }) {
    const data = {};

    items.forEach(item => {
      data[item.parameterName] = item.value;
    });

    return data;
  },
};

const actions = {
  load({ commit }, items) {
    commit('received', items);
  },

  set({ commit }, item) {
    commit('set', item);
  },
};

const mutations = {
  received(state, items) {
    state.items = items || [];
  },

  set(state, { parameterName, ...props }) {
    state.items = state.items.map(item => {
      return (item.parameterName === parameterName) ? {
        ...item,
        ...props,
      } : item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
