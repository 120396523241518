var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          ref: "swiperContainer",
          staticClass: "swiper-container product-swiper-container"
        },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.images, function(image, index) {
              return _c(
                "div",
                {
                  key: image.original,
                  staticClass: "swiper-slide",
                  on: {
                    click: function($event) {
                      return _vm.onOpenImageGallery(index)
                    }
                  }
                },
                [_c("img", { attrs: { src: image.webshop_sidebar } })]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPagination,
                expression: "showPagination"
              }
            ],
            staticClass: "swiper-pagination"
          }),
          _vm._v(" "),
          _vm._m(0)
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showThumbnails,
              expression: "showThumbnails"
            }
          ],
          ref: "swiperThumbsContainer",
          staticClass: "swiper-container product-swiper-container"
        },
        [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.images, function(image) {
              return _c(
                "div",
                {
                  key: image.webshop_thumb,
                  staticClass: "swiper-slide product-swiper-slide"
                },
                [_c("img", { attrs: { src: image.webshop_thumb } })]
              )
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _vm.imageGalleryOpen
        ? _c("ImageGallery", {
            attrs: {
              images: _vm.deconstructedImages,
              imageIndex: _vm.imageIndex
            },
            on: { close: _vm.onCloseImageGallery }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "magnifying-glass" }, [
      _c("i", { staticClass: "fal fa-search" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }