var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "form-control",
    attrs: { type: "number", placeholder: _vm.field.placeholder },
    domProps: { value: _vm.field.value },
    on: {
      change: function($event) {
        return _vm.$emit("change", $event.target.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }