<template>
    <div class="row table-row">
        <div v-if="vehicle._doc.trimText" class="table-column col-3"><span>Fordon: </span>{{ vehicle._doc.brandName }} {{ vehicle._doc.trimText }}</div>
        <div v-else class="table-column col-3"><span>Fordon: </span>{{ vehicle._doc.brandName }} {{ vehicle._doc.modelName }} {{ vehicle._doc.engineName }}</div>
        <div class="table-column col-2"><span>Årsmodell: </span>{{ vehicle._doc.modelYear }}</div>
        <div class="table-column col-1"><span>Färg: </span>{{ vehicle.color }}</div>
        <div class="table-column col-2"><span>Registrerad: </span>{{ vehicle.registrationDate }}</div>
        <div class="table-column col-2"><span>Tillgänglig: </span>{{ vehicle._doc.arrivalDate }}</div>
        <div class="table-column col-2"><button class="btn btn--primary btn--sm" @click="onClick">Prisförslag</button></div>
    </div>
</template>

<script>
export default {
  props: {
    vehicle: Object,
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>
