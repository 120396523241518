var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "eon-result__empty" }, [
    _c("h3", { staticClass: "eon-result__empty__title" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            "Ingen träff på " +
              _vm.getSizeString(_vm.tyreSize) +
              "." +
              (_vm.filteredSizes.length > 0
                ? " Prova ändra till dessa däckdimensioner som passar din " +
                  _vm.carName +
                  ":"
                : "")
          ) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _vm.filteredSizes.length > 0
      ? _c(
          "div",
          { staticClass: "facet-filter-bar" },
          _vm._l(_vm.filteredSizes, function(size, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "filter-bar__tag filter-bar__tag--bordered",
                on: {
                  click: function($event) {
                    return _vm.onClick(size)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.getSizeString(size)) + "\n    ")]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "eon-result__empty__text" }, [
      _vm._v(
        "\n    OBS! När man byter tum-storlek och endast köper lösa däck är det viktigt att man kontrollerar så att däckets tum stämmer överens med ens nuvarande fälgar. Annars kommer det inte gå att montera däcket på den gamla fälgen.\n  "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }