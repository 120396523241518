var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "configurator-select",
      class: {
        "configurator-select__dropdown-open": _vm.dropdownOpen
      }
    },
    [
      _vm.dropdownOpen
        ? _c("div", {
            staticClass: "configurator-select__overlay",
            on: {
              click: function($event) {
                _vm.dropdownOpen = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "configurator-select__button" }, [
        _vm.heading
          ? _c("div", { staticClass: "configurator-select__heading" }, [
              _vm._v(_vm._s(_vm.heading))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "configurator-select__container" }, [
          _c(
            "div",
            {
              staticClass: "configurator-select__input",
              on: { click: _vm.openDropdown }
            },
            [
              _c("div", { staticClass: "configurator-select__input__value" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      (_vm.selectedValue && _vm.selectedValue.name) ||
                        _vm.placeholder
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "configurator-select__input__icon-container" },
                [
                  _vm.isDepartmentSelect && _vm.selectedValue
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "configurator-select__availability-container"
                        },
                        [
                          _c("div", {
                            staticClass: "delivery-indicator",
                            class: {
                              "delivery-indicator--available":
                                _vm.options[0].value ===
                                _vm.selectedValue.value,
                              "delivery-indicator--unavailable":
                                _vm.options[0].value !== _vm.selectedValue.value
                            }
                          }),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.options[0].value === _vm.selectedValue.value
                                  ? "Omgående"
                                  : "3-7 dagar"
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    staticClass: "configurator-select__input__icon",
                    attrs: { icon: _vm.faSort }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "configurator-select__dropdown",
              class: {
                "configurator-select__dropdown--open": _vm.dropdownOpen
              }
            },
            [
              _vm.dropdownOpen
                ? _c("div", [
                    _vm.isDepartmentSelect
                      ? _c(
                          "div",
                          _vm._l(_vm.options, function(option, index) {
                            return _c("ConfiguratorDepartmentDropdownItem", {
                              key: option.name + "-department-item",
                              attrs: {
                                option: option,
                                selected:
                                  _vm.selectedValue &&
                                  option.value === _vm.selectedValue.value,
                                available: index === 0
                              },
                              on: { click: _vm.onItemClick }
                            })
                          }),
                          1
                        )
                      : _c(
                          "div",
                          _vm._l(_vm.options, function(option) {
                            return _c("ConfiguratorSimpleDropdownItem", {
                              key: option.name + "-simple-item",
                              attrs: {
                                option: option,
                                selected:
                                  _vm.selectedValue &&
                                  option.value === _vm.selectedValue.value
                              },
                              on: { click: _vm.onItemClick }
                            })
                          }),
                          1
                        )
                  ])
                : _vm._e()
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }