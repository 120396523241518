import Swiper from "../swiper";

const selectors = {
  productImages:
    ".product-images .swiper-container .swiper-wrapper .swiper-slide",
  targetIndex: ".swiper-slide-active",
  imageCounter: ".product-images__counter__text"
};

export default {
  init() {
    if (!$(".product-images").length) {
      return;
    }

    this.modifyDOM();
  },

  modifyDOM() {
    this.initSwiper();
    this.setImageCount();
  },

  initSwiper() {
    this.swiper = new Swiper(".product-images .swiper-container", {
      autoHeight: true,
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true
      },
      navigation: {
        nextEl: ".product-images__nav--next",
        prevEl: ".product-images__nav--prev",
        disabledClass: "product-images__nav--disabled"
      },
      on: {
        slideChangeTransitionEnd: () => this.setImageCount()
      }
    });
  },

  setImageCount() {
    const totalAmount = $(selectors.productImages).length;
    const currentImage =
      $(selectors.productImages).index($(selectors.targetIndex)) + 1;

    $(selectors.imageCounter).text(`${currentImage} / ${totalAmount}`);
  }
};
