var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-stack reg-nr" }, [
    _c("div", { staticClass: "reg-nr__field" }, [
      _c("div", { staticClass: "input-group-prepend" }, [
        _c("div", {
          staticClass: "regnr-input__symbol reg-nr__field__symbol",
          class: { "reg-nr__field__symbol--rounded": !_vm.carInfoDisplayName }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-stack reg-nr__field__container",
          class: { "form-stack--lg": false }
        },
        [
          _c("label", [_vm._v("\n        Regnummer\n      ")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.regNr,
                expression: "regNr"
              }
            ],
            staticClass: "form-control reg-nr__field__input",
            staticStyle: { color: "#18191a" },
            attrs: {
              id: "regNr-input",
              type: "text",
              placeholder: "ABC123",
              required: "",
              pattern: "^\\w{3}\\d{2}(\\w{1}|\\d{1})$",
              autocomplete: "off"
            },
            domProps: { value: _vm.regNr },
            on: {
              keyup: function($event) {
                return _vm.onChange()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.regNr = $event.target.value
              }
            }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _vm.validRegNr && _vm.carInfoDisplayName
      ? _c("div", { staticClass: "reg-nr__specs" }, [
          _vm._v("\n    " + _vm._s(_vm.carInfoDisplayName) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }