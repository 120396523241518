import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../../constants/FieldTypes';
import optionsToFieldValue from '../../../shared/wizard/util/options-to-field-value';
import ServicesApi from '../../../../models/ServicesApi';

const state = {
  items: [],
  fetchState: null,
};

const getters = {};


const actions = {
  async fetch({ commit, dispatch }, { departmentNr, regNr, service }) {
    commit('fetchRequest');

    try {
      const data = {
        departmentNr,
        regNr,
        service,
      };

      const res = await ServicesApi.fetchTyreServices(data);
      commit('fetchSuccess', res.data);

      dispatch('fields/set', {
        type: FieldTypes.TyreServices,
        options: res.data,
        value: optionsToFieldValue(res.data),
      }, { root: true });
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, items) {
    state.fetchState = RequestStates.Success;
    state.items = items;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
