<template>
  <VcFormStep
    :completed="section.order < order"
    :feedbackDanger="displaySectionValidationError"
    :title="getTitle(section)"
    :disabled="disabled"
    :subtitle="getSubtitle(section)"
    intro>
    <div slot="content" v-show="!disabled">
      <component :is="SectionTypeComponents[section.type]"
        :section="section"
        :sectionValid="valid"
        @submit="onSubmit">
      </component>
    </div>

    <SectionValidationError v-if="displaySectionValidationError"
      slot="feedback"
      :fields="invalidSectionFields">
    </SectionValidationError>
    <div v-else-if="section.order < order && lookupResultItems.length" slot="feedback" class="lookup-result">
      <div v-for="item in lookupResultItems"
       :key="item.label"
       class="lookup-result__item">
        <label>
          {{item.label}}
        </label>
        {{item.value}}
      </div>
    </div>
  </VcFormStep>
</template>

<script>
import { VcFormStep } from '@holmgrensbil/vue-components';
import { mapState, mapGetters, mapActions } from 'vuex';
import SectionValidationError from './SectionValidationError.vue';
import FieldTypes from './constants/FieldTypes';
import FieldChecklist from './FieldChecklist.vue';
import SectionTypeComponents from './constants/SectionTypeComponents';

export default {
  components: {
    VcFormStep,
    SectionValidationError,
    FieldChecklist,
  },
  props: {
    section: Object,
    footerLabel: String,
  },
  data() {
    return {
      SectionTypeComponents,
    };
  },
  computed: {
    ...mapState({
      lang: state => state.config.lang,
      order: state => state.sections.order,
    }),

    ...mapGetters({
      fieldByType: 'fields/byType',
      fieldsBySection: 'fields/bySection',
      invalidFieldsBySection: 'fields/invalidBySection',
    }),

    disabled() {
      return this.section.order > this.order;
    },

    sectionFields() {
      return this.fieldsBySection(this.section.type);
    },

    invalidSectionFields() {
      return this.invalidFieldsBySection(this.section.type);
    },

    displaySectionValidationError() {
      return this.section.validate && !!this.invalidSectionFields.length;
    },

    valid() {
      return !this.invalidSectionFields.length;
    },

    brandFieldValue() {
      return this.fieldByType(FieldTypes.Brand).value;
    },

    modelFieldValue() {
      return this.fieldByType(FieldTypes.Model).value;
    },

    versionFieldValue() {
      return this.fieldByType(FieldTypes.Version).value;
    },

    modelYearFieldValue() {
      return this.fieldByType(FieldTypes.ModelYear).value;
    },

    lookupResultItems() {
      const items = [];

      if (this.brandFieldValue) {
        items.push({
          label: 'Märke',
          value: this.brandFieldValue.name,
        });
      }

      if (this.modelFieldValue || this.versionFieldValue) {
        items.push({
          label: 'Modell och version',
          value: `${this.modelFieldValue} ${this.versionFieldValue}`,
        });
      }

      if (this.modelYearFieldValue) {
        items.push({
          label: 'Produktionsår',
          value: this.modelYearFieldValue,
        });
      }

      return items;
    },
  },

  methods: {
    ...mapActions({
      validateSection: 'sections/validate',
    }),

    onSubmit() {
      this.validateSection({ section: this.section });

      if (this.valid) {
        this.$emit('done');
        return true;
      }

      return false;
    },

    getTitle(section) {
      const obj = this.lang.App.Sections && this.lang.App.Sections[section.type];
      return obj && obj.Title;
    },

    getSubtitle(section) {
      const obj = this.lang.App.Sections && this.lang.App.Sections[section.type];
      return obj && obj.Subtitle;
    },
  }
}
</script>
