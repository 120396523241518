<template>
  <div class="product-images">
    <div class="product-images__counter">
      <i class="fas fa-images"></i> {{ currentIndex + 1 }} / {{ imageCount }}
    </div>
    <div
      class="product-images__fullscreen"
      @click="$emit('onImageClick', currentIndex)"
    >
      <i class="fal fa-expand-wide"></i> Helskärm
    </div>
    <div class="swiper-container" ref="swiperContainer">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="image in images" :key="image.image">
          <div class="product-images__item"
            :class="{
              'product-images__item--fullscreen': isQuickBuy,
            }"
            @click="isQuickBuy && $emit('onImageClick', currentIndex)"
          >
            <img
              v-bind:src="image.src"
              v-bind:alt="image.alt"
              v-bind:title="image.alt"
            />
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="product-images__nav product-images__nav--prev">
        <i class="fal fa-angle-left"></i>
      </div>
      <div class="product-images__nav product-images__nav--next">
        <i class="fal fa-angle-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "../../swiper";

export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    product: {
      type: Object
    },
    isQuickBuy: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      swiper: null,
      currentIndex: 0,
      imageCount: 0
    };
  },
  mounted() {
    this.imageCount = this.images.length;

    this.swiper = new Swiper(this.$refs.swiperContainer, {
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true
      },
      on: {
        transitionEnd: () => {
          this.currentIndex = this.swiper.realIndex;
        }
      },
      navigation: {
        nextEl: ".product-images__nav--next",
        prevEl: ".product-images__nav--prev",
        disabledClass: "product-images__nav--disabled"
      }
    });
  }
};
</script>
