var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStep",
    {
      attrs: {
        completed: _vm.completed || _vm.valuationComplete,
        disabled: _vm.disabled,
        feedbackDanger: _vm.displaySectionValidationError,
        arrow: !_vm.displaySectionValidationError,
        title: _vm.getTitle(_vm.section),
        subtitle: _vm.getSubtitle(_vm.section),
        summary: _vm.section.summary
      }
    },
    [
      (_vm.completed || _vm.valuationComplete) && !_vm.submitted
        ? _c("SectionEditButton", {
            attrs: { slot: "header-aside" },
            on: { click: _vm.onEdit },
            slot: "header-aside"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _vm.valuationComplete
          ? _c(
              "div",
              [
                _c("FieldChecklist", { attrs: { fields: _vm.sectionFields } }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "vehicle-history-forms__request-info__result"
                  },
                  [
                    _c("div", { staticClass: "main-info__heading" }, [
                      _vm._v("\n          Estimerad värdering\n        ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-info__data" }, [
                      _vm._v(
                        "\n          ca. " +
                          _vm._s(
                            _vm.$formatPrice(
                              _vm.fetchedValuation.price.prediction
                            )
                          ) +
                          "*\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "main-info__meta" }, [
                      _vm._v(
                        "\n          *Preliminärt pris från inbytesbil. Du kommer få ett exakt pris när\n          vårt värderingsteam värderar din inbytesbil.\n        "
                      )
                    ])
                  ]
                )
              ],
              1
            )
          : _vm.active || _vm.section.summary
          ? _c(
              "div",
              [
                _c(_vm.SectionTypeComponents[_vm.section.type], {
                  tag: "component",
                  attrs: { section: _vm.section, sectionValid: _vm.valid },
                  on: { submit: _vm.onSubmit }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.active && !_vm.hideFooter
        ? _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _vm.valuationComplete
              ? _c("div", { staticClass: "valuation-confirmation-container" }, [
                  _c("div", { staticClass: "form-step__header" }, [
                    _c("div", [
                      _c("div", { staticClass: "form-step__title" }, [
                        _vm._v(
                          "\n            Gå vidare för att få värderingen bekräftad\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-step__subtitle" }, [
                        _vm._v(
                          "\n            Estimerade priset är endast en uppskattning. Gå vidare för att få\n            värdering validerade av vårt värderingsteam.\n          "
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--block btn--primary",
                      on: { click: _vm.onNext }
                    },
                    [_vm._v("\n        Bekräfta värdering\n      ")]
                  )
                ])
              : _c("div", [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--block btn--primary",
                      class: {
                        disabled: _vm.loading
                      },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v("\n        Värdera gratis nu\n        "),
                      _vm.loading
                        ? _c("FontAwesomeIcon", {
                            attrs: { icon: _vm.faSpinnerThird, spin: true }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.displaySectionValidationError
        ? _c("SectionValidationError", {
            attrs: { slot: "feedback", fields: _vm.invalidSectionFields },
            slot: "feedback"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }