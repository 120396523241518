var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-gallery" }, [
    _c("div", { staticClass: "image-gallery__container" }, [
      _c(
        "div",
        {
          staticClass: "circle-action",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("i", { staticClass: "fal fa-times" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "image-gallery__main" }, [
        _c("div", { ref: "swiperContainer", staticClass: "swiper-container" }, [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            _vm._l(_vm.images, function(image) {
              return _c("div", { key: image, staticClass: "swiper-slide" }, [
                _c("img", {
                  staticClass: "image-gallery__main-image",
                  attrs: { src: image }
                })
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "swiper-pagination" })
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "image-gallery__nav image-gallery__nav--next" },
      [_c("i", { staticClass: "fal fa-angle-right" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "image-gallery__nav image-gallery__nav--prev" },
      [_c("i", { staticClass: "fal fa-angle-left" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }