import Vue from 'vue';
import App from './App.vue';
import createStore from './store';

export default (props) => {
  const store = createStore();

  return Vue.extend({
    store,
    render: h => h(App, { props })
  });
};
