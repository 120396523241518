var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "comparison-mobile-table" },
      [
        _c(
          "tr",
          {
            staticClass:
              "comparison-mobile-table__row comparison-mobile-table__row--header"
          },
          [
            _c("th", {
              staticClass:
                "comparison-mobile-table__cell comparison-mobile-table__cell--header"
            }),
            _vm._v(" "),
            _c("th", {
              staticClass:
                "comparison-mobile-table__cell comparison-mobile-table__cell--header"
            }),
            _vm._v(" "),
            _vm._l(_vm.filteredLabels, function(label) {
              return _c(
                "th",
                {
                  key: label.key,
                  staticClass:
                    "comparison-mobile-table__cell comparison-mobile-table__cell--header"
                },
                [
                  _c(
                    "div",
                    { staticClass: "comparison-mobile-table__header-content" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(label.label) + "\n        "
                      )
                    ]
                  )
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm._l(_vm.rows, function(row) {
          return _c(
            "tr",
            {
              key: row.title + "-" + _vm.uuidv4(),
              staticClass: "comparison-mobile-table__row"
            },
            [
              _c(
                "td",
                {
                  staticClass:
                    "comparison-mobile-table__cell comparison-mobile-table__cell--label"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "comparison-mobile-table__cell__content-wrapper"
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "comparison-mobile-table__cell__content",
                          attrs: { href: row.carUrl }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "comparison-mobile-table__cell__content__brand"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.title) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "comparison-mobile-table__cell__content__text"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(row.subTitle) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("td", { staticClass: "comparison-mobile-table__cell" }, [
                _c(
                  "div",
                  { staticClass: "comparison-mobile-table__cell__content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "delete-btn",
                        on: {
                          click: function($event) {
                            return _vm.$emit("delete-vehicle", row.regNr)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-trash" })]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _vm._l(row.vehicleCells, function(cell) {
                return _c(
                  "td",
                  {
                    key: cell.primaryValue + "-" + _vm.uuidv4(),
                    staticClass: "comparison-mobile-table__cell"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "comparison-mobile-table__cell__content",
                        class: {
                          "comparison-mobile-table__cell__content__single-line":
                            cell.hasUnit,
                          "comparison-mobile-table__cell__content__discount":
                            cell.secondaryValue
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(cell.primaryValue) +
                            "\n          "
                        ),
                        cell.secondaryValue
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "comparison-mobile-table__cell__secondary-value"
                              },
                              [_vm._v(_vm._s(cell.secondaryValue))]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                )
              })
            ],
            2
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }