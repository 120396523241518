<template>
  <div ref="panel" class="i3-filter__panel" :class="{'i3-filter__panel--minimized': !showControls}" :style="{'max-height': maxHeightStyle}">
    <slot name="search">
    </slot>
    <div class="i3-filter__panel-controls">
      <div v-if="queryFilters.length">
        <span v-for="qf in queryFilters"
          :key="qf.key">
          <TagGroup v-if="qf.children && qf.children.length"
            :key="qf.key"
            :label="qf.label">
            <TagItem v-for="child in qf.children"
              :key="child.key"
              :label="child.label"
              isChild
              @remove="onRemove(child)">
            </TagItem>
          </TagGroup>
          <TagItem v-else
            :key="qf.key"
            :label="qf.label"
            :locked="qf.locked"
            @remove="onRemove(qf)">
          </TagItem>
        </span>
      </div>
      <div v-else class="i3-filter__panel-meta">
        Inga filter valda
      </div>
      <div class="i3-filter__panel-clear"
        :class="{'i3-filter__panel-clear--disabled': !queryFilters.length}"
        @click="$emit('clearAll')">
        <i class="fas fa-trash"></i>
      </div>
    </div>
  </div>
</template>

<script>
import TagItem from './TagItem.vue';
import TagGroup from './TagGroup.vue';
import I3QueryFilterTypes from '../../constants/I3QueryFilterTypes';

export default {
  props: {
    queryFilters: Array,
    showControls: Boolean,
  },
  components: {
    TagItem,
    TagGroup,
  },
  data() {
    return {
      maxHeightStyle: 'none',
    };
  },
  mounted() {
    this.setMaxHeight();
  },
  watch: {
    queryFilters() {
      this.setMaxHeight();
    },
  },
  methods: {
    setMaxHeight() {
      this.maxHeightStyle = 'none';

      setTimeout(() => {
        this.maxHeightStyle = `${this.$refs.panel.offsetHeight}px`;
      }, 1);
    },

    onRemove(queryFilter) {
      this.$emit('onClearQueryFilter', queryFilter);
    },
  },
}
</script>
