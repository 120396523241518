<template>
  <v-component :is="wrapperComponent">
    <div class="vehicle-list__full-item-wrapper">
      <div class="vehicle-list__full-item" ref="container" @click="$emit('click')">
        <div class="quickbuy">
          <div class="quickbuy__aside">
            <ProductImage v-if="(productImages && productImages.length === 1) ||
              !product.available
              " :image="productImages[0]" :overlay="!product.available || product.noImage"
              :overlayLabel="product.availableLabel" :isQuickBuy="true" @onImageClick="onOpenImageGallery">
            </ProductImage>
            <ProductImageSlider v-else-if="productImages.length > 1" :images="productImages" :product="product"
              :isQuickBuy="true" @onImageClick="onOpenImageGallery">
            </ProductImageSlider>
          </div>
          <div class="quickbuy__main">
            <div class="quickbuy__main-content">
              <div class="product-meta">
                <ProductMetaLinks :product="product"></ProductMetaLinks>
                <div class="product-meta-actions">
                  <div :data-ttjs-title="!isLoggedIn
                    ? 'Logga in för att kunna spara fordon'
                    : 'Tillagd i dina sparade fordon. Under Mina sidor kan du se och ta bort sparade fordon'
                    " class="bookmark-top" :class="{
    ttjs: !isLoggedIn || productSaved,
    'save-vehicle__user-not-logged-in': !isLoggedIn
  }" @click="onSaveVehicle">
                    <div class="bookmark-top__icon" :class="{
                      'bookmark-bottom__icon--selected': productSaved
                    }">
                      <FontAwesomeIcon :icon="productSaved ? fasHeart : falHeart"></FontAwesomeIcon>
                    </div>
                    <span class="link bookmark-top__link" :class="{
                      'bookmark-bottom__link--selected': productSaved
                    }">{{ productSaved ? "Sparad" : "Spara" }}</span>
                  </div>

                  <div class="bookmark-top u-d-internal-user">
                    <div class="bookmark-top__icon">
                      <FontAwesomeIcon :icon="falPrint"></FontAwesomeIcon>
                    </div>
                    <a :href="product.permalink + '/print'" class="link bookmark-top__link">Skriv ut</a>
                  </div>
                  <div class="bookmark-top">
                    <div class="bookmark-top__icon">
                      <FontAwesomeIcon :icon="falShareSquare"></FontAwesomeIcon>
                    </div>
                    <a class="link bookmark-top__link js-share-this-url" :data-currenturl="product.permalink"
                      data-toggle="modal" data-target="[data-share-page-modal]">Dela</a>
                  </div>
                  <div class="bookmark-top" @click="compareVehicle">
                    <div class="bookmark-top__icon">
                      <FontAwesomeIcon :icon="falBalance"></FontAwesomeIcon>
                    </div>
                    <span class="link bookmark-top__link">
                      Jämför
                    </span>
                  </div>
                </div>
              </div>
              <div class="product-description">
                <h3 class="product-description__header">
                  {{ product.brandModelTitle }}
                </h3>
                <div class="product-description__text">
                  {{ product.shortModelText }}
                </div>
              </div>
              <ProductDetailModals :product="product"></ProductDetailModals>
              <div class="swiper-container" style="width: 100%;" v-if="product.available">
                <div class="swiper-wrapper"
                  v-if="product.dicount || (product.usps && ((product.usps.campaignUsps && product.usps.campaignUsps.length > 0) || (product.usps.usps && product.usps.usps.length > 0)))">
                  <div v-if="product.discount && priceMode === 'regular'" class="product-tag red swiper-slide">
                    <FontAwesomeIcon :icon="fasTag"></FontAwesomeIcon>
                    Prissänkt {{ $formatPrice(product.discount) }}
                  </div>
                  <div v-if="product.discount && priceMode === 'noTax'" class="product-tag red swiper-slide">
                    <FontAwesomeIcon :icon="fasTag"></FontAwesomeIcon>
                    Prissänkt {{ $formatPrice(product.discountNoVat) }}
                  </div>
                  <div class="product-tag swiper-slide" :class="usp.colour" v-for="usp in product.usps.campaignUsps"
                    :key="usp.name">
                    <a v-if="usp.modalUrl" class="link" :data-target="usp.modalUrl" data-modal-source="ajax"><i
                        v-if="usp.faIcon" :class="usp.faIcon"></i>{{ usp.name }}</a>
                    <template v-else><i v-if="usp.faIcon" :class="usp.faIcon"></i>{{ usp.name }}</template>
                  </div>
                  <div class="product-tag swiper-slide" :class="usp.colour" v-for="usp in product.usps.usps"
                    :key="usp.name">
                    <a v-if="usp.modalUrl" class="link" :data-target="usp.modalUrl" data-modal-source="ajax"><i
                        v-if="usp.faIcon" :class="usp.faIcon"></i>{{ usp.name }}</a>
                    <template v-else><i v-if="usp.faIcon" :class="usp.faIcon"></i>{{ usp.name }}</template>
                  </div>
                </div>
              </div>
              <div class="product-facts product-facts--sm">
                <div class="product-facts__item">
                  <div class="meta-tag black rounded">
                    {{ product.shortVehicleCondition }}
                  </div>
                  <div>{{ product.modelYear }}</div>
                </div>
                <div class="product-facts__item" v-if="product.vehicleType != 'Husvagn'">
                  <i class="fal fa-road"></i>
                  <div>{{ product.mileage }} mil</div>
                </div>
                <div class="product-facts__item" v-if="product.taxAmount && product.vehicleType != 'Husvagn'">
                  <i class="fal fa-coins"></i>
                  <div>{{ product.taxAmount }} kr/år</div>
                </div>
                <div class="product-facts__item" v-if="product.fuelName">
                  <i class="fal fa-charging-station" v-if="product.usesChargingStation"></i>
                  <i class="fal fa-gas-pump" v-else></i>
                  <div>{{ product.fuelName }}</div>
                </div>
                <div class="product-facts__item" v-if="product.totalWeight && product.vehicleType == 'Husvagn'">
                  <i class="fal fa-weight-hanging"></i>
                  <div>{{ product.totalWeight }} kg</div>
                </div>

                <div class="product-facts__item" v-if="product.batteryCapacity">
                  <i class="fal fa-battery-bolt"></i>
                  <div>{{ product.batteryCapacity }} kWh</div>
                </div>
                <div class="product-facts__item" v-if="product.electricRange > 0">
                  <i class="fal fa-bolt"></i>
                  <div>{{ product.electricRange }} km</div>
                </div>
                <div v-if="product.loadCapacity &&
                  (product.vehicleType == 'Transportbil' ||
                    product.vehicleType == 'Husvagn')
                  " class="product-facts__item">
                  <i class="fal fa-truck-loading"></i>
                  <div>{{ product.loadCapacity }} kg</div>
                </div>
                <div v-if="product.loadSpaceWidth &&
                  product.vehicleType == 'Transportbil'
                  " class="product-facts__item">
                  <div class="bordered">
                    <i class="fal fa-arrows-h"></i>
                  </div>
                  <div>{{ product.loadSpaceWidth }} mm</div>
                </div>
                <div class="product-facts__item" v-if="product.bodyNameNoPrefix && product.vehicleType === 'Husbil'
                  ">
                  <i class="fal fa-rv"></i>
                  <div>{{ product.bodyNameNoPrefix }}</div>
                </div>
                <div class="product-facts__item" v-if="product.bodyNameNoPrefix &&
                  product.vehicleType === 'Husvagn'
                  ">
                  <i class="fal fa-caravan"></i>
                  <div>{{ product.bodyNameNoPrefix }}</div>
                </div>
                <div class="product-facts__item" v-if="product.floorPlan">
                  <i class="fal fa-bed-alt"></i>
                  <div>{{ product.floorPlan }}</div>
                </div>

                <div v-if="product.trailerWeight && product.fuelName != 'El'" class="product-facts__item">
                  <i class="fal fa-trailer"></i>
                  <div>{{ product.trailerWeight }} kg</div>
                </div>

                <div class="product-facts__item" v-if="product.length && product.vehicleType == 'Husvagn'">
                  <i class="fal fa-arrows-h"></i>
                  <div>{{ product.length }} mm</div>
                </div>
                <div class="product-facts__item" v-if="product.gearBoxName && product.vehicleType !== 'Husvagn'
                  ">
                  <i class="fal fa-cogs"></i>
                  <div>{{ product.gearBoxName }}</div>
                </div>
                <div v-if="product.seats && Number(product.seats) > 0" class="product-facts__item">
                  <i class="fal fa-users"></i>
                  <div>{{ product.seats }} säten</div>
                </div>
              </div>
            </div>
            <div class="quickbuy__main-aside">
              <div class="quickbuy__pricing">
                <div class="product-spot__price" v-if="product.available">
                  <div class="product-spot__price-item">
                    <div class="product-spot__price-item__name">
                      Pris
                    </div>
                    <div class="product-spot__price-item__meta">
                      {{ priceMode != "noTax" ? "Inkl. moms" : "Exkl. moms" }}
                    </div>
                    <div class="price-tag" :class="{ 'price-tag--discount': product.discount }">
                      {{
                        $formatPrice(
                          priceMode != "noTax"
                            ? product.currentPrice
                            : product.currentPriceNoVat
                        )
                      }}
                    </div>
                  </div>
                  <div class="product-spot__price-item" v-if="!displayOnlyCash">
                    <div class="product-spot__price-item__name" v-if="product.flexCode == 'mgflex'">
                      MG100
                    </div>
                    <div class="product-spot__price-item__name" v-else>
                      Finansiering
                    </div>
                    <div class="product-spot__price-item__meta">
                      Inkl. moms
                    </div>
                    <div class="price-tag" :class="{ 'price-tag--discount': product.discount }">
                      {{ $formatPriceNoSuffix(product.monthlyCost) }} kr/mån
                    </div>
                  </div>
                  <div class="product-spot__price-item" :class="{ 'offset-4': product.taxCode === 1 }"
                    v-if="!displayOnlyCash && product.privateLeasingPrice > 0">
                    <div class="product-spot__price-item__name">
                      Privatleasing
                    </div>
                    <div class="product-spot__price-item__meta">
                      Inkl. moms
                    </div>
                    <div class="price-tag">
                      {{ $formatPriceNoSuffix(product.privateLeasingPrice) }} kr/mån
                    </div>
                  </div>
                  <div class="product-spot__price-item" :class="{ 'offset-3': !product.privateLeasingPrice }"
                    v-if="!displayOnlyCash && this.product.tcoMonthlyCost > 0">
                    <div class="product-spot__price-item__name">
                      Operationell leasing
                    </div>
                    <div class="product-spot__price-item__meta">
                      TCO Exkl. moms
                    </div>
                    <div class="price-tag">
                      {{ $formatPriceNoSuffix(product.tcoMonthlyCost) }} kr/mån
                    </div>
                  </div>
                  <div class="product-spot__price-item" :class="{ 'offset-3': !product.privateLeasingPrice }"
                    v-else-if="!displayOnlyCash && this.product.blMonthlyCost > 0">
                    <div class="product-spot__price-item__name">
                      Business lease
                    </div>
                    <div class="product-spot__price-item__meta">
                      Exkl. moms
                    </div>
                    <div class="price-tag">
                      {{ $formatPriceNoSuffix(product.blMonthlyCost) }} kr/mån
                    </div>
                  </div>
                  <div class="product-spot__price-item" :class="{ 'offset-3': !product.privateLeasingPrice }"
                    v-else-if="!displayOnlyCash && this.product.taxCode === 1">
                    <div class="product-spot__price-item__name">
                      Företagsleasing
                    </div>
                    <div class="product-spot__price-item__meta">
                      Exkl. moms
                    </div>
                    <div class="price-tag" :class="{ 'price-tag--discount': product.discount }">
                      {{ $formatPriceNoSuffix(product.leasing) }} kr/mån
                    </div>
                  </div>
                </div>

              </div>
              <GridItemQuickbuyActions :product="product"></GridItemQuickbuyActions>
              <div class="quickbuy__bookmarks">
                <div :data-ttjs-title="!isLoggedIn
                  ? 'Logga in för att kunna spara fordon'
                  : 'Tillagd i dina sparade fordon. Under Mina sidor kan du se och ta bort sparade fordon'
                  " class="bookmark-bottom" :class="{
    ttjs: !isLoggedIn || productSaved,
    'save-vehicle__user-not-logged-in': !isLoggedIn
  }" @click="onSaveVehicle">
                  <div class="bookmark-bottom__icon" :class="{ 'bookmark-bottom__icon--selected': productSaved }">
                    <FontAwesomeIcon :icon="productSaved ? fasHeart : falHeart"></FontAwesomeIcon>
                  </div>
                  <span class="bookmark-bottom__link" :class="{
                    'bookmark-bottom__link--selected': productSaved
                  }">
                    {{ productSaved ? "Sparad" : "Spara" }}
                  </span>
                </div>
                <div class="bookmark-bottom">
                  <div class="bookmark-bottom__icon">
                    <FontAwesomeIcon :icon="falShareSquare"></FontAwesomeIcon>
                  </div>
                  <a class="link bookmark-bottom__link js-share-this-url" :data-currenturl="product.permalink"
                    data-toggle="modal" data-target="[data-share-page-modal]">Dela</a>
                </div>
                <div class="bookmark-bottom" @click="compareVehicle">
                  <div class="bookmark-bottom__icon">
                    <FontAwesomeIcon :icon="isVehicleCompared ? fasBalance : falBalance"></FontAwesomeIcon>
                  </div>
                  <span class="bookmark-bottom__link" :class="{
                    'bookmark-bottom__link--selected': isVehicleCompared
                  }">
                    Jämför
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ImageGallery v-if="imageGalleryOpen" :images="product.images" :imageIndex="imageIndex" :product="product"
        @close="onCloseImageGallery"></ImageGallery>
    </div>
  </v-component>
</template>

<script>
import { scrollToEl } from "../../utils/scrolling";
import ProductImageSlider from "./ProductImageSliderPagination.vue";
import ProductImage from "./ProductImage.vue";
import ProductMetaLinks from "./ProductMetaLinks.vue";
import ProductDetailModals from "./ProductDetailModals.vue"
import GridItemQuickbuyActions from "./GridItemQuickbuyActions.vue";
import TransitionExpand from "./TransitionExpand.vue";
import DivDummy from "./DivDummy.vue";
import ImageResizerPresets from "../../constants/ImageResizerPresets";
import Axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHeart as falHeart,
  faBalanceScale as falBalance,
  faShareSquare as falShareSquare,
  faPrint as falPrint
} from "@fortawesome/pro-light-svg-icons";
import {
  faHeart as fasHeart,
  faBalanceScale as fasBalance,
  faTag as fasTag
} from "@fortawesome/pro-solid-svg-icons";
import ImageGallery from "../shared/ImageGallery.vue";
import SaveVehicleManager from "../../models/SaveVehicleManager";
import ttjs from "../../components/ttjs";
import { EventBus } from "../../utils/event-bus";
import Breakpoints from "../../constants/Breakpoints";
import Swiper from "../../swiper";

export default {
  props: {
    product: Object,
    transitionOnOpen: Boolean,
    savedVehicles: Array,
  },
  components: {
    ProductImageSlider,
    ProductImage,
    ProductMetaLinks,
    ProductDetailModals,
    GridItemQuickbuyActions,
    FontAwesomeIcon,
    ImageGallery
  },
  data() {
    return {
      falHeart,
      fasHeart,
      falPrint,
      fasTag,
      falBalance,
      fasBalance,
      falShareSquare,
      imageGalleryOpen: false,
      imageIndex: 0,
      loggedIn: window.CurrentUser !== undefined && window.CurrentUser !== null,
      comparedVehicles: []
    };
  },
  async mounted() {
    if (this.transitionOnOpen) {
      setTimeout(() => {
        scrollToEl(this.$refs.container, true);
      });
    }
    EventBus.$on("logged-in", () => (this.loggedIn = true));
    this.getComparedVehicles();
    this.initLinkSwiper();
    EventBus.$on("comparedVehicleRemoved", this.getComparedVehicles.bind(this));
  },
  computed: {
    wrapperComponent() {
      return this.transitionOnOpen ? TransitionExpand : DivDummy;
    },

    productImages() {
      let productName = this.product.title;
      let fallbackImage = this.product.fallbackImage;
      return this.product.images.map(function (image, i) {
        const img = {
          src: `${image}?${ImageResizerPresets.Quickbuy}&404=${fallbackImage}`,
          alt: `${productName}${i > 0 ? "_" + i : ""}`
        };
        return img;
      });
    },

    productSaved() {
      return this.savedVehicles.includes(this.product.regNr);
    },
    isLoggedIn() {
      return this.loggedIn;
    },
    isVehicleCompared() {
      return this.comparedVehicles.includes(this.product.regNr);
    },
    displayOnlyCash() {
      if (this.product.vehicleType == "Husbil" || this.product.vehicleType == "Husvagn") {
        return this.product.modelYear <= (new Date().getFullYear() - 10);
      }
      return false;
    },
    priceMode() {
      if (this.product.vehicleType.includes("Transport") && this.product.taxCode === 1) return "noTax";
      return "regular";
    }
  },
  methods: {
    async onSaveVehicle() {
      if (this.isLoggedIn) {
        if (this.productSaved) {
          await Axios.delete(`/api/MyPages/vehicles/${this.product.regNr}`);

          const tooltip = $(".ttjs-tooltip");
          tooltip.remove();
          const bookmarkTop = $(".bookmark-top");
          const bookmarkBottom = $(".bookmark-bottom");
          const tt = ttjs.tooltips.find(
            t =>
              t.reference === bookmarkTop[0] ||
              t.reference === bookmarkBottom[0]
          );
          ttjs.remove(tt);
        } else {
          const res = await Axios.post("/api/MyPages/vehicles", {
            registrationNumber: this.product.regNr
          });
        }
        SaveVehicleManager.alterVehicleList();
      }
    },
    initLinkSwiper() {
      this.linkSwiper = new Swiper(".quickbuy__main-content .swiper-container", {
        autoHeight: false,
        slidesPerView: 'auto',
        spaceBetween: 8,
        lazy: true,
        enabled: false,
        breakpoints: {
          [Breakpoints.md - 2]: {
            enabled: true,
          },
        },
      });
    },
    onOpenImageGallery(index) {
      this.imageIndex = index || 0;
      this.imageGalleryOpen = true;
    },
    onCloseImageGallery() {
      this.imageGalleryOpen = false;
    },
    compareVehicle() {
      EventBus.$emit("compareVehicle", this.product.regNr);
    },
    getComparedVehicles() {
      const data = sessionStorage.getItem("comparedVehicles");
      let comparedVehicles = [];
      if (data) {
        comparedVehicles = JSON.parse(data);
      }

      this.comparedVehicles = comparedVehicles;
    },

  }
};
</script>

<style>
.expand-enter-active,
.expand-leave-active {
  transition: all 0.22s ease-out;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
}</style>
