<template>
  <div>
    <div v-for="(value, index) in option.values"
      :key="index"
      class="toggle-switch-control">
      <div class="toggle-switch">
        <input
          type="checkbox"
          :id="getInputId(index)"
          :checked="checked"
          @change="$emit('change', $event.target.checked)" />
        <label :for="getInputId(index)">
        </label>
      </div>
      <div class="toggle-switch-control__label">
        {{value.name}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: Object,
    default: () => {}
  },
  computed: {
    checked() {
      const selected = this.option.values.find(v => v.selected);
      return selected && selected.value === 1;
    },
  },
  methods: {
    getInputId(index) {
      return `subopt-${this.option.name}-${index}`;
    },
  },
}
</script>
