var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OverlayScrollable",
    [
      _c("OverlayHeader", {
        attrs: { slot: "header", text: _vm.headerText, showBack: _vm.hasBack },
        on: { back: _vm.onBack, close: _vm.onClose },
        slot: "header"
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "my-pages",
          class: { "my-pages--logged-in": _vm.isLoggedIn },
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _c(
            "keep-alive",
            [
              _c(_vm.currentViewComponent, {
                tag: "component",
                attrs: { "current-user": _vm.currentUser, lang: _vm.lang },
                on: {
                  signOut: _vm.signOut,
                  signIn: _vm.signIn,
                  view: _vm.onView
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }