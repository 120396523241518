import RequestStates from "../../../../constants/RequestStates";
import CartApi from '../../../../models/CartApi';

const emptyCart = {
  lineItems: [],
  additionalFees: [],
  promotionCodes: [],
};

const state = {
  cart: {
    ...emptyCart,
  },
  hasFetched: false,
  fetchState: {},
  setQuantityState: {},
  applyPromotionState: {},
  removePromotionState: null,
};

const getters = {
  isEmpty({ cart, hasFetched }, getters) {
    return hasFetched && cart.lineItems.length === 0 && !getters.isFetching;
  },

  isFetching({ fetchState }) {
    return fetchState.state === RequestStates.Request;
  },

  lines({ cart }) {
    return cart.lineItems;
  },

  lineParents({ cart }) {
    return cart.lineItems.filter(li => !li.parentLineItem || li.parentLineItem === 0);
  },

  lineChildren({ cart }) {
    return cart.lineItems.filter(li => li.parentLineItem);
  },

  lineChildrenByParent: (_, getters) => parent => {
    return getters.lineChildren.filter(li => li.parentLineItem === parent);
  },

  selectedPayment({ cart }) {
    return cart.selectedPayment;
  },

  productIds({ cart }) {
    return cart.lineItems.map(li => li.productId);
  },
};

const actions = {
  async fetch({ commit }) {
    commit('fetchRequest');

    try {
      const res = await CartApi.fetch();
      commit('cartReceived', res.data);
      commit('fetchSuccess');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit('cartReceived', { ...emptyCart });
        commit('fetchSuccess');
      } else {
        commit('fetchFailure', { error });
      }
    }
  },

  async fetchById({ commit }, id) {
    commit('fetchRequest');

    try {
      const res = await CartApi.fetchById(id);
      commit('cartReceived', res.data);
      commit('fetchSuccess');
      commit('fetchSuccessById');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        commit('cartReceived', { ...emptyCart });
        commit('fetchSuccess');
      } else {
        commit('fetchFailure', { error });
      }
    }
  },

  async setSelectedPayment({ commit }, id) {
    commit('setSelectedPayment', id)
  },

  async setQuantity({ commit }, { id, quantity }) {
    commit('setQuantityRequest', id);

    try {
      const res = await CartApi.updateItem({ id, quantity });
      commit('cartReceived', res.data);
      commit('setQuantitySuccess', id);
    } catch (error) {
      commit('setQuantityFailure', { id, error });
    }
  },

  async applyPromotion({ commit }, code) {
    commit('applyPromotionRequest');

    try {
      const res = await CartApi.addPromotion({ code });
      commit('cartReceived', res.data);
      commit('applyPromotionSuccess');
    } catch (error) {
      commit('applyPromotionFailure', error);
    }
  },

  async removePromotion({ commit }) {
    commit('removePromotionRequest');

    try {
      const res = await CartApi.removePromotion();
      commit('cartReceived', res.data);
      commit('removePromotionSuccess');
    } catch (error) {
      commit('removePromotionFailure', error);
    }
  },
};

const mutations = {
  cartReceived(state, cart) {
    state.cart = cart;
  },

  fetchRequest(state) {
    state.fetchState = {
      state: RequestStates.Request,
    };
  },

  fetchSuccess(state) {
    state.fetchState = {
      state: RequestStates.Success,
    };
    state.hasFetched = true;
  },

  fetchSuccessById() {

  },

  fetchFailure(state) {
    state.fetchState = {
      state: RequestStates.Failure,
      // message: error.response.data && error.response.data.message,
    };
  },

  setQuantityRequest(state, id) {
    state.setQuantityState = {
      ...state.setQuantityState,
      [id]: {
        state: RequestStates.Request,
      },
    };
  },

  setQuantitySuccess(state, id) {
    state.setQuantityState = {
      ...state.setQuantityState,
      [id]: {
        state: RequestStates.Success,
      },
    };
  },

  setQuantityFailure(state, { id }) {
    state.setQuantityState = {
      ...state.setQuantityState,
      [id]: {
        state: RequestStates.Failure,
        // message: error.response.data && error.response.data.message,
      },
    };
  },

  applyPromotionRequest(state) {
    state.applyPromotionState = {
      state: RequestStates.Request,
    };
  },

  applyPromotionSuccess(state) {
    state.applyPromotionState = {
      state: RequestStates.Success,
    };
  },

  applyPromotionFailure(state) {
    state.applyPromotionState = {
      state: RequestStates.Failure,
      // message: error.response.data && error.response.data.message,
    };
  },

  removePromotionRequest(state) {
    state.removePromotionState = RequestStates.Request;
  },

  removePromotionSuccess(state) {
    state.removePromotionState = RequestStates.Success;
  },

  removePromotionFailure(state) {
    state.removePromotionState = RequestStates.Failure;
  },

  setSelectedPayment(state, id) {
    state.cart.selectedPayment = id;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
