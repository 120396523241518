const classes = {
  productColorSelected: 'product-color--selected',
};

export default {
  init() {
    this.$preview = $('.configurator-teaser__preview');
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $('body').on('click', '.configurator-teaser__colors .product-color', this.onColorClick.bind(this));
  },

  modifyDOM() {
  },

  onColorClick({ currentTarget }) {
    const $el = $(currentTarget);

    $el.siblings().removeClass(classes.productColorSelected);
    $el.addClass(classes.productColorSelected);
    this.$preview.find('img').prop('src', $el.data('preview-image'));

    // TODO: Maybe change the CTA link to preselect color in configurator...
  },
}
