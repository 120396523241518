var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OverlayScrollable",
    [
      _c("OverlayHeader", {
        attrs: { slot: "header", text: _vm.headerText, showBack: _vm.hasBack },
        on: { back: _vm.onBack, close: _vm.onClose },
        slot: "header"
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "main",
          staticClass: "i3-filter",
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.hasPanel
            ? _c(
                "ThePanel",
                {
                  attrs: {
                    queryFilters: _vm.queryFilters,
                    showControls: _vm.view !== _vm.Views.Search
                  },
                  on: {
                    clearAll: _vm.onClear,
                    onClearQueryFilter: _vm.onClearQueryFilter
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "search" }, slot: "search" },
                    [
                      _c("VcSearchBar", {
                        attrs: {
                          placeholder: "Fritextsök",
                          blurOnSubmit: "",
                          disableClear: "",
                          small: ""
                        },
                        on: {
                          submit: _vm.onSearchSubmit,
                          focus: _vm.onSearchFocus,
                          blur: _vm.onSearchBlur
                        },
                        model: {
                          value: _vm.searchString,
                          callback: function($$v) {
                            _vm.searchString = $$v
                          },
                          expression: "searchString"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "i3-filter__view" },
            [
              _c(
                "transition",
                { attrs: { name: _vm.transitionName } },
                [
                  _vm.view === _vm.Views.Facets
                    ? _c("FacetsView", {
                        attrs: {
                          facets: _vm.facets,
                          expandedFacets: _vm.expandedFacets,
                          navigateToFacet: _vm.navigateToFacet
                        },
                        on: {
                          selectFacet: _vm.onFacetSelect,
                          selectFilter: _vm.onFilterSelect,
                          selectRangeFilter: _vm.onRangeFilterSelect
                        }
                      })
                    : _vm.view === _vm.Views.Facet
                    ? _c("FacetView", {
                        attrs: {
                          facet: _vm.facets.find(function(f) {
                            return f.id === _vm.expandedFacets[0]
                          })
                        },
                        on: {
                          selectFilter: _vm.onFilterSelect,
                          selectRangeFilter: _vm.onRangeFilterSelect
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.view === _vm.Views.Search
                ? _c("SearchView", {
                    attrs: { popularSearches: _vm.popularSearches },
                    on: { select: _vm.onPopularSearchSelect }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasFooter
        ? _c("OverlayFooter", {
            attrs: {
              slot: "footer",
              showBack: _vm.hasBack,
              text: _vm.footerBtnText + " (" + _vm.resultHits + ")"
            },
            on: { back: _vm.onBack, click: _vm.onClose },
            slot: "footer"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }