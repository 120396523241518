var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "list", staticClass: "vehicle-table" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.listItems, function(listItem) {
            return _c("ListItem", {
              key: listItem.document._id,
              attrs: { vehicle: listItem.vehicle },
              on: {
                click: function($event) {
                  return _vm.onItemClick(listItem)
                }
              }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.listItems.length
        ? _c("VcIntersectObserver", {
            attrs: { options: { rootMargin: "200px" } },
            on: { intersect: _vm.onIntersect }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ContactModalRental"),
      _vm._v(" "),
      _c("ContactModalPrivateLeasing")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row table-header" }, [
      _c("div", { staticClass: "table-column col-3" }, [_vm._v("Fordon")]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column col-2" }, [_vm._v("Årsmodell")]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column col-1" }, [_vm._v("Färg")]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column col-2" }, [_vm._v("Registrerad")]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column col-2" }, [_vm._v("Tillgänglig")]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column col-2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }