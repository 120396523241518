<template>
  <div class="regnr-input">
    <div class="input-group">
      <div class="input-group-prepend">
        <div class="regnr-input__symbol"></div>
      </div>
      <VcFormStack :label="field.label">
        <input
          :value="field.value"
          :placeholder="field.placeholder"
          :class="{'form-control--invalid': invalid}"
          @change="$emit('change', $event.target.value)"
          type="text"
          class="form-control">
      </VcFormStack>
    </div>
  </div>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormStack,
  },
  props: {
    invalid: Boolean,
    field: Object,
  },
}
</script>
