var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-margin-top" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("table", { staticClass: "table table--striped table--bordered" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.store.instances, function(instance) {
              return _c("tr", { key: instance.months }, [
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(instance.date) +
                      "\n              "
                  ),
                  _c("small", [
                    _vm._v("(månad " + _vm._s(instance.months) + ")")
                  ]),
                  _vm._v(" "),
                  _c(
                    "small",
                    {
                      staticClass: "ttjs u-c-muted",
                      attrs: { "data-ttjs-title": instance.tooltip }
                    },
                    [_c("i", { staticClass: "far fa-question-circle" })]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", { staticClass: "date-info" }, [
                    _vm._v("Datumet är ej exakt")
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    {
                      staticClass: "form-check",
                      class: { "form-check--disabled": !instance.selectable }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: instance.selected,
                            expression: "instance.selected"
                          }
                        ],
                        staticClass: "form-check__input",
                        attrs: {
                          type: "checkbox",
                          disabled: !instance.selectable
                        },
                        domProps: {
                          checked: Array.isArray(instance.selected)
                            ? _vm._i(instance.selected, null) > -1
                            : instance.selected
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = instance.selected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      instance,
                                      "selected",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      instance,
                                      "selected",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(instance, "selected", $$c)
                              }
                            },
                            function($event) {
                              return _vm.calculate()
                            }
                          ]
                        }
                      })
                    ]
                  )
                ])
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "u-fw-medium" }, [
      _vm._v("\n        Serviceavtal"),
      _c("br"),
      _vm._v(" "),
      _c("small", { staticClass: "u-c-muted" }, [
        _vm._v(
          "Nedan ser du en sammanfattning av serviceavtalet, när service\n          utförs och vad som ingår. Du kan även välja att inkludera\n          serviceintervall som vi inte är helt säkra på om redan har\n          utfört."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [
          _vm._v("Servicetillfälle "),
          _c("small", [_vm._v("(bilens ålder)")])
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "80px" } }, [
          _c(
            "div",
            {
              staticClass: "ttjs",
              attrs: {
                "data-ttjs-title":
                  "Servicetillfällen som är nära i tid vet vi ej om du redan har utfört på din bil, de är därför valbara nedan."
              }
            },
            [
              _vm._v("\n                Vald "),
              _c("i", { staticClass: "far fa-question-circle" })
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }