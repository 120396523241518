import Sections from './sections';
import SavedVehicles from '../sections/SavedVehicles.vue';
import Subscriptions from '../sections/Subscriptions.vue';
import TyreOrders from '../sections/TyreOrders.vue';
import Settings from '../sections/Settings.vue';
import MyVehicles from '../sections/MyVehicles.vue';
import MyTyres from '../sections/MyTyres.vue';
export default {
  [Sections.SavedVehicles]: SavedVehicles,
  [Sections.Subscriptions]: Subscriptions,
  [Sections.TyreOrders]: TyreOrders,
  [Sections.Settings]: Settings,
  [Sections.MyVehicles]: MyVehicles,
  [Sections.MyTyres]: MyTyres,
}
