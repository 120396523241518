import Swiper from '../swiper';
import Breakpoints from '../constants/Breakpoints';

export default {
  init() {
    if (!$('.reviews').length) {
      return;
    }
    if($('.col-md-12 .reviews').length && $(window).width() > Breakpoints.md) {
      return;
    }

    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    this.initSwiper();
  },

  initSwiper() {
    this.swiper = new Swiper('.reviews .swiper-container', {
      spaceBetween: 40,
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        dynamicBullets: true,
      },
    });
  },
}
