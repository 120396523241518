var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-block info-block--hours info-block--hours--expanded" },
    [
      _c("i", { staticClass: "info-block__icon fas fa-clock" }),
      _vm._v(" "),
      _c("div", { staticClass: "info-block__header" }, [
        _vm._v("\n    Öppettider\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        _vm._l(_vm.department.openHours, function(hours) {
          return _c(
            "div",
            {
              key: hours.name,
              staticClass: "info-block--hours__row",
              class: {
                "info-block--hours__row--active": hours.name.includes("Idag")
              }
            },
            [
              _c("div", [
                _vm._v("\n        " + _vm._s(hours.name) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v("\n        " + _vm._s(hours.value) + "\n      ")
              ])
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }