var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-images" }, [
    _c("div", { staticClass: "product-images__counter" }, [
      _c("i", { staticClass: "fas fa-images" }),
      _vm._v(
        " " +
          _vm._s(_vm.currentIndex + 1) +
          " / " +
          _vm._s(_vm.imageCount) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "product-images__fullscreen",
        on: {
          click: function($event) {
            return _vm.$emit("onImageClick", _vm.currentIndex)
          }
        }
      },
      [_c("i", { staticClass: "fal fa-expand-wide" }), _vm._v(" Helskärm\n  ")]
    ),
    _vm._v(" "),
    _c("div", { ref: "swiperContainer", staticClass: "swiper-container" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.images, function(image) {
          return _c("div", { key: image.image, staticClass: "swiper-slide" }, [
            _c(
              "div",
              {
                staticClass: "product-images__item",
                class: {
                  "product-images__item--fullscreen": _vm.isQuickBuy
                },
                on: {
                  click: function($event) {
                    _vm.isQuickBuy &&
                      _vm.$emit("onImageClick", _vm.currentIndex)
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: image.src, alt: image.alt, title: image.alt }
                })
              ]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-pagination" }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "product-images__nav product-images__nav--prev" },
      [_c("i", { staticClass: "fal fa-angle-left" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "product-images__nav product-images__nav--next" },
      [_c("i", { staticClass: "fal fa-angle-right" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }