var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i3-filter-tabs tab-bar" },
    _vm._l(_vm.filters, function(filter) {
      return _c(
        "div",
        {
          key: filter.name,
          staticClass: "tab-bar__item",
          class: {
            "tab-bar__item--active": _vm.lastFilterName === filter.name
          },
          on: {
            click: function($event) {
              return _vm.onFilterSelect(filter)
            }
          }
        },
        [_vm._v("\n    " + _vm._s(filter.name) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }