<template>
  <div class="u-margin-top">
    <div class="row">
      <div class="col-md-12">
        <h4 class="u-fw-medium">
          Tillval<br />
          <small class="u-c-muted"
            >Nedan finns de eventuella tillval som du kan välja i ditt
            serviceavtal.</small
          >
        </h4>

        <table class="table table--striped table--bordered">
          <thead>
            <tr>
              <th>Tillval</th>
              <th style="width: 120px;">
                Pris
              </th>
              <th style="width: 60px;">
                Vald
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in store.additionalProducts" :key="product.id">
              <td>
                {{ product.name }}
                <small
                  class="ttjs u-c-muted"
                  :data-ttjs-title="product.description"
                >
                  <i class="far fa-question-circle"></i> </small
                ><br />
              </td>
              <td>{{ $formatPrice(product.monthlyCost) }} / mån</td>
              <td>
                <div class="form-check">
                  <input
                    type="checkbox"
                    @change="calculate()"
                    v-model="product.selected"
                    class="form-check__input"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import store from "./store";
import { VcFormStack } from "@holmgrensbil/vue-components";

export default {
  components: {
    VcFormStack
  },
  data() {
    return {
      store: store.state
    };
  },

  methods: {
    calculate() {
      store.calculate();
    }
  }
};
</script>
