<template>
  <div class="i3-filter__facet-view">
    <FacetItemContent
      :facet="facet"
      @selectFilter="$emit('selectFilter', $event)"
      @selectRangeFilter="$emit('selectRangeFilter', $event)">
    </FacetItemContent>
  </div>
</template>

<script>
import FacetItemContent from './FacetItemContent.vue';

export default {
  props: {
    facet: Object,
  },
  components: {
    FacetItemContent,
  },
}
</script>
