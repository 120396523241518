var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-margin-top" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "VcFormStack",
                { attrs: { label: "Avtalsperiod", required: "" } },
                [
                  _c("VcFormSelect", {
                    attrs: {
                      prompt: "Välj avtalsperiodperiod",
                      options: _vm.months,
                      value: _vm.store.additionalInfo.months,
                      valueProp: "value",
                      labelProp: "label",
                      valueType: "number",
                      required: ""
                    },
                    on: { change: _vm.onMonthsChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "VcFormStack",
                { attrs: { label: "Körsträcka (mil per år)", required: "" } },
                [
                  _c("VcFormSelect", {
                    attrs: {
                      prompt: "Välj körsträcka",
                      options: _vm.mileage,
                      value: _vm.store.additionalInfo.mileage,
                      valueProp: "value",
                      labelProp: "label",
                      valueType: "number",
                      required: ""
                    },
                    on: { change: _vm.onMileageChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "VcFormStack",
                { attrs: { label: "Mätarställning (mil)", required: true } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.store.additionalInfo.currentMileage,
                        expression: "store.additionalInfo.currentMileage"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Ange mätarställning i mil",
                      required: true,
                      type: "number"
                    },
                    domProps: {
                      value: _vm.store.additionalInfo.currentMileage
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.store.additionalInfo,
                          "currentMileage",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.store.hasPackages
            ? _c("div", { staticClass: "col-md-4 u-margin-top" }, [
                !_vm.hasFetched
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn--primary btn--block",
                        attrs: { disabled: !_vm.store.canFetchAgreement },
                        on: {
                          click: function($event) {
                            return _vm.fetchAgreement(true)
                          }
                        }
                      },
                      [_vm._v("\n            Hämta serviceavtal\n          ")]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "u-fw-medium" }, [
      _vm._v("\n        Ytterligare uppgifter"),
      _c("br"),
      _vm._v(" "),
      _c("small", { staticClass: "u-c-muted" }, [
        _vm._v(
          "För att kunna ge dig ett så korrekt serviceavtal som möjligt\n          behöver du fylla i följande uppgifter."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }