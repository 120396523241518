var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard wizard--service-booking" },
    [
      _c("SectionList", {
        attrs: {
          sections: _vm.sections,
          SectionTypeComponents: _vm.SectionTypeComponents
        },
        on: { completed: _vm.onSectionCompleted }
      }),
      _vm._v(" "),
      _c(
        "FormSubmit",
        {
          attrs: { lastSection: _vm.lastSection, requireTerms: "" },
          on: { completed: _vm.onSectionCompleted }
        },
        [
          _c("div", { staticClass: "form-group form-check" }, [
            _c("input", {
              staticClass: "form-check__input",
              attrs: { type: "checkbox" },
              domProps: { checked: _vm.newsletterField.value === true },
              on: {
                change: function($event) {
                  return _vm.onNewsletterChange($event.target.checked)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-check__label",
                on: {
                  click: function($event) {
                    return _vm.onNewsletterChange(!_vm.newsletterField.value)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.newsletterField.label) + "\n      "
                )
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }