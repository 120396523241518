<template>
  <div>
    <div v-if="!pickupFromShareUrl">
      <VcFormStack label="Anläggning" required>
        <VcFormSelect
          prompt="Välj anläggning"
          :options="pickupItems"
          :value="pickupSelected"
          valueProp="departmentNr"
          valueType="number"
          labelProp="name"
          required
          @change="onChange"
        >
        </VcFormSelect>
      </VcFormStack>

      <div
        v-if="selectedDepartment && !departmentLoading"
        class="row checkout__selected-department"
      >
        <div
          class="col-sm-6 order-last order-sm-first checkout__selected-department-delivery"
        >
          <div class="delivery-status-circle">
            <DeliveryStatusCircle
              :deliveryStatus="deliveryStatus.status"
            ></DeliveryStatusCircle>
          </div>
          <div>
            <h4>
              {{ deliveryStatus.title }}
            </h4>
            <p>
              {{ deliveryStatus.description }}
            </p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="checkout__selected-department-map">
            <div
              class="checkout__selected-department-map-image"
              :style="{
                'background-image': `url(${
                  selectedDepartment.googleMapsImageUrl
                })`
              }"
            >
              <a
                :href="selectedDepartment.directionsUrl"
                class="btn btn--white btn--sm"
                target="_blank"
              >
                {{ lang.Checkout.Department.OpenMap }}
              </a>
            </div>

            <VcFeedbackMessage v-if="cartHasServices" success slim>
              <i class="fas fa-check"></i>
              {{ lang.Checkout.Department.Eligible }}
            </VcFeedbackMessage>
          </div>
        </div>
        <div v-if="!isAccessoryCheckout" class="col-sm-12">
          <div class="form-check form-check--sm form-check--inline checkout__selected-department-tyre-hotel-order">
            <input
              id="tyreHotelOrder"
              v-model="isTyreHotelOrder"
              class="form-check__input"
              type="checkbox"
              @change="onTyreHotelOrderChange"
            />
            <label class="form-check__label" for="tyreHotelOrder">
              {{ lang.Checkout.TyreHotel.Check }}
              <a
                href="#"
                :data-target="checkoutTyreHotelModal"
                data-modal-source="ajax"
                ><i class="far fa-question-circle"></i
              ></a>
            </label>
          </div>
        </div>

      </div>
    </div>
    <div v-else>
      <div
        v-if="selectedDepartment && !departmentLoading"
        class="row checkout__selected-department"
      >
        <div
          class="col-sm-6 order-last order-sm-first checkout__selected-department-delivery"
        >
          <div>
            <h4>
              {{ selectedDepartment.name }}
            </h4>
            <p>
              {{ lang.Checkout.TyreHotel.Info }}
            </p>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="checkout__selected-department-map">
            <div
              class="checkout__selected-department-map-image"
              :style="{
                'background-image': `url(${
                  selectedDepartment.googleMapsImageUrl
                })`
              }"
            >
              <a
                :href="selectedDepartment.directionsUrl"
                class="btn btn--white btn--sm"
                target="_blank"
              >
                {{ lang.Checkout.Department.OpenMap }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VcFormSelect,
  VcFormStack,
  VcFeedbackMessage
} from "@holmgrensbil/vue-components";
import { mapGetters, mapState, mapActions } from "vuex";
import RequestStates from "../../constants/RequestStates";
import LineItemDeliveryStatusLabels from "../../constants/LineItemDeliveryStatusLabels";
import LineItemDeliveryStatusDescriptions from "../../constants/LineItemDeliveryStatusDescriptions";
import DeliveryStatusCircle from "../shared/ecom/DeliveryStatusCircle.vue";
import Steps from "./constants/Steps";
import SessionStorageUtil from "../../utils/session-storage";
import { getQueryParam } from "../../utils/query-params";

export default {
  components: {
    VcFormStack,
    VcFormSelect,
    VcFeedbackMessage,
    DeliveryStatusCircle
  },
  data() {
    return {
      isTyreHotelOrder: false
    };
  },
  props: {
    lang: {
      type: Object,
      required: true
    },
    urlSiteId: {
      type: String,
      default: null
    },
    isAccessoryCheckout: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.onMounted();
  },
  computed: {
    ...mapState({
      pickupItems: state => state.pickup.items,
      pickupSelected: state => state.pickup.selected,
      pickupFetchState: state => state.pickup.fetchState,
      pickupFetchDepartmentState: state => state.pickup.fetchDepartmentState
    }),

    ...mapGetters({
      pickupByDepartmentNr: "pickup/byDepartmentNr",
      pickupByDestinationSiteId: "pickup/byDestinationSiteId",
      pickupDepartmentByDepartmentNr: "pickup/departmentByDepartmentNr",
      cartLineParents: "cart/lineParents",
      cartLineChildren: "cart/lineChildren"
    }),

    cartHasServices() {
      return this.cartLineChildren.length;
    },

    deliveryStatus() {
      const sortedItems = this.cartLineParents.sort(
        (a, b) => b.deliveryStatus - a.deliveryStatus
      );
      const longestDeliveryStatus = sortedItems[0].deliveryStatus;
      const title = LineItemDeliveryStatusLabels[longestDeliveryStatus];
      const description =
        LineItemDeliveryStatusDescriptions[longestDeliveryStatus];

      return {
        status: longestDeliveryStatus,
        title,
        description
      };
    },

    selectedDepartment() {
      if (this.pickupSelected) {
        return this.pickupDepartmentByDepartmentNr(
          this.pickupSelected.departmentNr
        );
      }
    },

    departmentLoading() {
      return this.pickupFetchDepartmentState.state === RequestStates.Request;
    },

    loading() {
      return this.pickupFetchState.state === RequestStates.Request;
    },
    checkoutTyreHotelModal() {
      return window.EonConfig.checkoutTyreHotelModal;
    },
    pickupFromShareUrl() {
      return this.urlSiteId && this.urlSiteId.length;
    }
  },
  methods: {
    ...mapActions({
      selectPickup: "pickup/select",
      fetchPickup: "pickup/fetch",
      setTyreHotelOrder: "pickup/setTyreHotelOrder"
    }),
    async onChange(department) {
      if (department?.departmentNr) {
        this.$emit("changePickup", Steps.department, null, department.name);
        await SessionStorageUtil.set(
          "checkout-department",
          department.departmentNr
        );

        this.selectPickup(department);
      }
    },

    onTyreHotelOrderChange() {
      setTimeout(() => {
        this.setTyreHotelOrder({ tyreHotelOrder: this.isTyreHotelOrder });
      }, 50);
    },

    async onMounted() {
      await this.fetchPickup();
      if (this.urlSiteId && this.urlSiteId.length) {
        const department = this.pickupByDestinationSiteId(this.urlSiteId);
        this.selectPickup(department);
        this.setTyreHotelOrder({ tyreHotelOrder: true });
        return;
      }
      const departmentNr = await SessionStorageUtil.get("checkout-department");
      if (departmentNr) {
        const department = this.pickupByDepartmentNr(departmentNr);
        this.selectPickup(department);
      }
    }
  }
};
</script>
