import qs from "query-string";
import events from "../mixins/events";
import ChannelEvents from "../constants/ChannelEvents";
import ChannelQuery from "./ChannelQuery";
import EonApi from "./EonApi";
import EonQueryParams from "../constants/EonQueryParams";
import EonProductTypes from "../apps/eon-filter-page/constants/EonProductTypes";
import EonTyreTypes from "../apps/eon-filter-page/constants/EonTyreTypes";
import EonProductConditionTypes from "../constants/EonProductConditionTypes";
import Cookies from "js-cookie";

class EonChannel {
  constructor({ ref, queryString, persist, lazy }, TyreSpec) {
    this.eventPrefix = `EonChannel.${ref}.`;
    this.ref = ref;
    this.data = {};
    this.persist = persist;
    this.TyreSpec = TyreSpec;
    this.executedQueries = [];

    if (queryString) {
      this.defaultQuery = new ChannelQuery(queryString);
    }

    if (!lazy) {
      this.init();
    }
  }

  init() {
    let initialQuery;

    if (this.persist) {
      this.on(ChannelEvents.QuerySuccess, this.setQueryString.bind(this));
      const qs = this.getQueryString();
      if (qs.loadLastQuery && this.lastCookieQuery) {
        initialQuery = new ChannelQuery(this.lastCookieQuery);
      } else {
        initialQuery = new ChannelQuery(qs);
      }
    }

    this.execute(initialQuery);
  }

  async execute(query, { appendDocuments } = {}) {
    if (typeof query === "undefined") {
      query = new ChannelQuery();
    } else if (typeof query === "string") {
      query = new ChannelQuery(query);
    }

    if (this.defaultQuery) {
      query = new ChannelQuery(
        Object.assign({}, this.defaultQuery.params, query.params)
      );
    }

    this.emit(ChannelEvents.QueryRequest, { channel: this });
    this.setDefaultParams(query);
    console.info("EON QUERY PARAMS: " + query.toString());
    try {
      const data = await EonApi.getProducts(query.params);

      if (appendDocuments) {
        data.data.products = [
          ...this.data.data.products,
          ...data.data.products
        ];
      }

      this.data = data;
      this.executedQueries.push(query);
      Cookies.set(this.ref, query.toString());
      this.emit(ChannelEvents.QuerySuccess, { channel: this });
    } catch (e) {
      this.emit(ChannelEvents.QueryFailure, { channel: this });
    }
  }

  get lastQuery() {
    return this.executedQueries[this.executedQueries.length - 1];
  }

  get lastCookieQuery() {
    return Cookies.get(this.ref);
  }

  getSelectedFilters() {
    return this.lastQuery ? this.lastQuery.params : {};
  }

  getQueryFilters() {
    const sel = this.getSelectedFilters();

    return Object.entries(sel)
      .filter(([key, value]) => key && value)
      .map(([key, value]) => {
        return {
          param: key,
          value
        };
      });
  }

  setDefaultParams(query) {
    let vehicleParam = {
      name: EonQueryParams.VehicleId,
      value: this.TyreSpec.searchVehicleId
    };

    if (this.TyreSpec.regNr) {
      vehicleParam = {
        name: EonQueryParams.Query,
        value: this.TyreSpec.regNr
      };
    }

    const params = [
      vehicleParam,
      { name: EonQueryParams.SearchMode, value: 4 },
      { name: EonQueryParams.Page, value: 1 },
      { name: EonQueryParams.Width, value: this.TyreSpec.tyreSize.width },
      {
        name: EonQueryParams.AspectRatio,
        value: this.TyreSpec.tyreSize.aspectRatio
      },
      { name: EonQueryParams.Diameter, value: this.TyreSpec.tyreSize.diameter },
      { name: EonQueryParams.ProductType, value: String(EonProductTypes.Tyre) }
    ];

    params.forEach(d => {
      if (!query.getParam(d.name)) {
        query.setParam(d.name, d.value);
      }
    });

    // Default winter/summer
    this.setDefaultTyreTypeParam(query);

    // Default tyretype params
    this.setDefaultProductTypeParams(query);
  }

  setDefaultTyreTypeParam(query) {
    if (!query.getParam(EonQueryParams.TyreType)) {
      const val = window.EonConfig.isWinterSeason
        ? String(EonTyreTypes.WinterFriction)
        : String(EonTyreTypes.Summer);
      query.setParam(EonQueryParams.TyreType, val);
    }
  }

  setDefaultProductTypeParams(query) {
    const productTypeParam = query.getParam(EonQueryParams.ProductType);
    if (productTypeParam === String(EonProductTypes.Rim)) {
      query.unsetParam(EonQueryParams.Width);
      query.unsetParam(EonQueryParams.AspectRatio);
      query.setParam(EonQueryParams.Condition, [EonProductConditionTypes.New]);
      query.setParam(EonQueryParams.MinQuantityInStock, 0);
    } else if (productTypeParam === String(EonProductTypes.Tyre)) {
      query.setParam(EonQueryParams.Condition, [EonProductConditionTypes.New]);
      query.setParam(EonQueryParams.MinQuantityInStock, 4);
    } else if (productTypeParam === String(EonProductTypes.CompleteWheel)) {
      query.setParam(EonQueryParams.MinQuantityInStock, 4);
    }
  }

  hasTouchedParam(key) {
    if (!this.lastQuery) {
      return false;
    }

    if (!this.defaultQuery) {
      return this.lastQuery.params[key];
    }

    return this.lastQuery.params[key] !== this.defaultQuery.params[key];
  }

  get hasNextPage() {
    if (!this.data.count || !this.data.data) {
      return false;
    }

    return this.data.count > this.data.data.products.length;
  }

  queryNextPage() {
    if (!this.hasNextPage) {
      return;
    }

    const query = new ChannelQuery(this.lastQuery);
    const lastPage = query.getParam(EonQueryParams.Page) || 1;
    const page = parseInt(lastPage, 10) + 1;

    query.setParam(EonQueryParams.Page, page);

    return this.execute(query, {
      appendDocuments: true
    });
  }

  getQueryString() {
    return qs.parse(window.location.search);
  }

  setQueryString() {
    const { lastQuery, defaultQuery } = this;
    const compareQuery = defaultQuery ? defaultQuery : new ChannelQuery();
    const query = new ChannelQuery(lastQuery);
    const blacklist = [EonQueryParams.Page, EonQueryParams.VehicleId];

    blacklist.forEach(param => query.unsetParam(param));

    const search =
      query.toString() === compareQuery.toString() ? "" : query.toString();
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${search}`
    );
  }
}

Object.assign(EonChannel.prototype, events);

export default EonChannel;
