<template>
  <ul class="list-unstyled">
    <li v-for="question in questions"
      :key="question.id">
      <div class="contact__faq-question link link--icon" @click="onClick(question)">
        <span>{{question.text}}</span>
        <div v-show="!expanded.includes(question.id)">
          <i class="fal fa-angle-down"></i>
        </div>
        <div v-show="expanded.includes(question.id)">
          <i class="fal fa-angle-up"></i>
        </div>
      </div>
      <div class="contact__faq-answer" v-if="expanded.includes(question.id)">
        {{question.answer}}
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    questions: Array,
  },
  data() {
    return {
      expanded: [],
    };
  },
  methods: {
    onClick({ id }) {
      if (this.expanded.includes(id)) {
        this.expanded = this.expanded.filter(e => e !== id);
      } else {
        this.expanded = [
          ...this.expanded,
          id,
        ];
      }
    },
  }
}
</script>
