<template>
  <div class="wizard wizard--trade-in">
    <SectionList
      :sections="sections"
      :SectionTypeComponents="SectionTypeComponents"
      @completed="onSectionCompleted"
    >
    </SectionList>
    <FormSubmit :lastSection="lastSection" @completed="onSectionCompleted">
    </FormSubmit>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SectionTypes from "./constants/SectionTypes";
import FieldTypes from "./constants/FieldTypes";
import FormSubmit from "../shared/wizard/FormSubmit.vue";
import SectionList from "../shared/wizard/SectionList.vue";
import SectionTypeComponents from "./constants/SectionTypeComponents";
import gtm from "../../utils/gtm";
import { EventBus } from "../../utils/event-bus";

export default {
  components: {
    SectionList,
    FormSubmit
  },
  props: {
    sourceRegNr: String,
    regNr: String,
    pageName: String,
    departmentEmail: String,
    departmentName: String,
    vehicleId: Number | null,
    email: String
  },
  data() {
    return {
      SectionTypeComponents
    };
  },
  created() {
    // Initial regnr passed by vehicle page
    this.setField({ type: FieldTypes.RegNr, value: this.regNr });
    // Regnr for currently browsed vehicle
    this.setField({ type: FieldTypes.SourceRegNr, value: this.sourceRegNr });
    this.setField({ type: FieldTypes.Email, value: this.email });
    // Id for my vehicle id
    if (this.vehicleId) {
      this.setField({ type: FieldTypes.VehicleId, value: this.vehicleId });
    }

    // Set current page name
    this.setField({ type: FieldTypes.PageName, value: this.pageName });
    // Forced department
    if (this.departmentEmail) {
      this.lockDepartments({
        email: this.departmentEmail,
        name: this.departmentName
      });
    }

  },
  computed: {
    ...mapState({
      sections: state => state.sections.items
    }),

    ...mapGetters({
      sectionByType: "sections/byType",
      fieldByType: "fields/byType"
    }),

    lastSection() {
      return this.sectionByType(SectionTypes.Contact);
    },
  },
  methods: {
    ...mapActions({
      setEndpoint: "form/load",
      setField: "fields/set",
      setSection: "sections/set",
      fetchDepartments: "departments/fetch",
      lockDepartments: "departments/lock",
      filterSections: "sections/filterSections",
      goTo: "sections/goTo"
    }),

    onSectionCompleted(section) {
      if (section.type === SectionTypes.Lookup) {
        this.fetchDepartments();
      }
      if (section.type === SectionTypes.Contact) {
        EventBus.$emit("trade-in-completed");
      }
      gtm.tradeInSectionCompleted({ section });
    }
  }
};
</script>
