<template>
  <div
    v-show="vehiclesLength > 0"
    class="compare-vehicles"
  >
    <div class="compare-vehicles__toolbar-item">
      Jämför fordon
      <div class="compare-vehicles__toolbar-item__counter">{{vehiclesLength}}</div>
    </div>
    <div class="compare-vehicles__toolbar-item link link--underline" @click="onCompareVehiclesModalOpen">
      Visa jämförelse
    </div>
    <div class="compare-vehicles__toolbar-item link link--underline" @click="clearCompare">
      Rensa
    </div>
    <VcModal v-if="activeCompareVehiclesModal" @close="onCompareVehiclesModalClose">
      <div slot="header">Jämför fordon</div>
      <div slot="content">
        <MobileComparison v-if="isMobile" :labels="labels" :vehicles="vehicles" @delete-vehicle="deleteVehicle"/>
        <DesktopComparison v-else :labels="labels" :vehicles="vehicles" @delete-vehicle="deleteVehicle"/>
      </div>
    </VcModal>
  </div>
</template>

<script>
import { VcModal } from "@holmgrensbil/vue-components";
import Axios from '../../axios';
import DesktopComparison from './DesktopComparison.vue'
import MobileComparison from './MobileComparison.vue'
import { EventBus } from '../../utils/event-bus';
import Breakpoints from '../../constants/Breakpoints';
export default {
  components: {
    VcModal,
    DesktopComparison,
    MobileComparison,
  },
  data() {
    return {
      activeCompareVehiclesModal: false,
      labels: [],
      vehicles: [],
    }
  },
  computed: {
    vehiclesLength() {
      return this.vehicles.length
    },
    isMobile() {
      return this.windowWidth < Breakpoints.md;
    },
  },
  async mounted() {
    window.addEventListener('compareVehicleWindow', this.windowAddVehicleEvent.bind(this))
    EventBus.$on('compareVehicle', this.addVehicle.bind(this))
    const data = sessionStorage.getItem('comparedVehicles');
    let comparedVehicles = [];
    if (data) {
      comparedVehicles = JSON.parse(data);
    }
    if (comparedVehicles.length > 0) {
      await this.getData(comparedVehicles);
    }
  },
  methods: {
    async getData(regNrs) {
      try {
        const stringyfiedRegNrs = regNrs.join(',')
        const { data } = await Axios.get(`/api/vehicle/compare?registrationNumbers=${stringyfiedRegNrs}`)

        this.labels = data.fields;
        this.vehicles = data.vehicles;
      } catch (e) {
        console.error(e);
      }
    },
    onCompareVehiclesModalOpen() {
      this.activeCompareVehiclesModal = true;
    },
    onCompareVehiclesModalClose() {
      this.activeCompareVehiclesModal = false;
    },
    addVehicle(regNr) {
      const data = sessionStorage.getItem('comparedVehicles');
      let comparedVehicles = [];
      if (data) {
        comparedVehicles = JSON.parse(data);
      }
      if (!comparedVehicles.includes(regNr)) {
        const modifiedComparedVehicles = [...comparedVehicles, regNr]
        sessionStorage.setItem('comparedVehicles', JSON.stringify(modifiedComparedVehicles))

        this.getData(modifiedComparedVehicles);
      }

      if (comparedVehicles.length > 0) {
        this.activeCompareVehiclesModal = true;
      }
    },
    windowAddVehicleEvent(e) {
      this.addVehicle(e.detail)
    },
    deleteVehicle(regNr) {
      const data = sessionStorage.getItem('comparedVehicles');
      let comparedVehicles = [];
      if (data) {
        comparedVehicles = JSON.parse(data);
      }

      const filteredVehicles = comparedVehicles.filter(vehicle => vehicle !== regNr);

      sessionStorage.setItem('comparedVehicles', JSON.stringify(filteredVehicles))

      EventBus.$emit('comparedVehicleRemoved')
      if (filteredVehicles.length > 0) {
        this.getData(filteredVehicles);
      } else {
        this.clearCompare()
      }
    },
    clearCompare() {
      sessionStorage.removeItem('comparedVehicles');
      this.activeCompareVehiclesModal = false;
      this.labels = [];
      this.vehicles = [];
      EventBus.$emit('comparedVehicleRemoved')
    }
  }
}
</script>

<style>

</style>