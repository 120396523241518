var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "btn btn--primary",
        attrs: { target: "_blank", href: _vm.externalUrl },
        on: { click: _vm.onExternalClick }
      },
      [_vm._v("\n    Gå till bokning\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }