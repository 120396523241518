import FieldTypes from '../constants/FieldTypes';
import InputTypes from '../../shared/wizard/constants/InputTypes';
import fields from '../../shared/wizard/data/fields';
import SectionTypes from '../constants/SectionTypes';

export default [
  ...fields,
  {
    section: SectionTypes.Services,
    label: 'Tjänster',
    type: FieldTypes.Services,
    inputType: InputTypes.OptionBoxesMulti,
    required: true,
  },
  {
    section: SectionTypes.Department,
    label: 'Verkstad',
    placeholder: 'Välj verkstad',
    type: FieldTypes.Department,
    inputType: InputTypes.SelectObject,
    options: [],
    required: true,
  },
  {
    section: SectionTypes.Date,
    label: 'Önskat datum',
    shortLabel: 'Datum',
    placeholder: 'Välj datum',
    type: FieldTypes.Date,
    inputType: InputTypes.Date,
    required: true,
    options: [],
  },
  {
    section: SectionTypes.Addons,
    label: 'Tillval',
    type: FieldTypes.Addons,
    inputType: InputTypes.OptionBoxesMulti,
    options: [],
    required: false,
  },
  {
    section: SectionTypes.Promotion,
    label: 'Kampanjkod',
    placeholder: 'Kampanjkod',
    type: FieldTypes.PromotionCode,
    inputType: InputTypes.Text,
    required: false,
  },
]
