<template>
  <VcFormStack :label="field.label" :required="field.required">
    <input
      :value="field.value"
      @change="$emit('change', $event.target.value)"
      class="form-control"
      :class="{'form-control--invalid': invalid}"
      :placeholder="field.placeholder"
      :autocomplete="field.autocomplete"
      :required="field.required"
      :type="type"
      :min="min" />
      <i v-if="field.tooltip" class="form-tooltip ttjs far fa-question-circle" :data-ttjs-title="field.tooltip"></i>
  </VcFormStack>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormStack,
  },
  props: {
    invalid: Boolean,
    field: Object,
    type: {
      type: String,
      default: 'text',
    },
    min: String,
  },
}
</script>
