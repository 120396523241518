<template>
  <div class="cart-upsell-item" :class="{'cart-item--loading': loading}">
    <div class="cart-upsell-item__main">
      <img v-if="product.imageUrl" :src="product.imageUrl" />
      <div class="cart-upsell-item__product-name">
        {{product.name}}
        <div v-if="product.description">
          <a
            class="link link--underline link--muted-light link--icon"
            @click="modalOpen = !modalOpen"
          >
            <span class="u-fw-regular">Mer info</span>
          </a>
        </div>
      </div>
    </div>
    <div class="cart-upsell-item__actions">
      <div class="cart-upsell-item__price">
        <VcPriceTag :price="product.price" showIfZero></VcPriceTag>
      </div>
      <div class="cart-upsell-item__quantity">
        <VcFormSelect
          :options="quantityOptions"
          :value="quantityValue"
          labelProp="name"
          valueProp="value"
          valueType="number"
          small
          @change="onQuantityChange"
        ></VcFormSelect>
      </div>
      <div class="cart-upsell-item__action">
        <a class="btn btn--white btn--icon btn--sm btn--block" @click="onAddClick">
          Lägg till
          <FontAwesomeIcon :icon="faPlus"></FontAwesomeIcon>
        </a>
      </div>
    </div>

    <VcModal v-if="modalOpen" @close="modalOpen = false">
      <div slot="header">{{product.name}}</div>
      <div slot="content" v-html="product.modalContent"></div>
    </VcModal>
  </div>
</template>

<script>
import { VcPriceTag, VcFormSelect, VcModal } from "@holmgrensbil/vue-components";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faPlus
} from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import CartApi from "../../models/CartApi";

export default {
  components: {
    FontAwesomeIcon,
    VcPriceTag,
    VcFormSelect,
    VcModal,
  },
  props: {
    product: Object
  },
  data() {
    return {
      modalOpen: false,
      faAngleDown,
      faAngleUp,
      faPlus,
      faCheck,
      quantity: 1,
      loading: false
    };
  },

  computed: {
    quantityOptions() {
      const options = [...Array(8).keys()].map(i => ({
        name: `${i + 1} st`,
        value: i + 1
      }));

      return options;
    },
    quantityValue() {
      return this.quantityOptions.find(qo => qo.value === this.quantity);
    }
  },

  methods: {
    onQuantityChange(data) {
      this.quantity = data.value;
    },
    async onAddClick() {
      this.loading = true;

      try {
        await CartApi.addItem({
          productId: this.product.id,
          quantity: this.quantity,
          isUpsellProduct: true
        });
        this.$emit("added", this.product);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
