<template>
  <div class="properties">
    <div class="properties__item properties__item--header">
      <div class="properties__label">
        {{row.heading}}
      </div>
    </div>
    <div v-for="line in displayLines" :key="line.name">
      <div v-if="row.heading === 'Fordon'" class="vehicle-line">
        <ConfigSummationLineVehicle :line="line"></ConfigSummationLineVehicle>
      </div>
      <ConfigSummationLine :line="line" v-else></ConfigSummationLine>
    </div>
    <div v-if="collapsible"
      class="link link--muted-light link--underline link--icon"
      @click="onToggleCollapse">
        <span v-show="collapsed">Visa alla detaljer</span>
        <span v-show="!collapsed">Dölj alla detaljer</span>
        <i v-show="collapsed">
          <i class="fal fa-angle-down"></i>
        </i>
        <i v-show="!collapsed">
          <i class="fal fa-angle-up"></i>
        </i>
    </div>
  </div>
</template>

<script>
import ConfigSummationLine from './ConfigSummationLine.vue';
import ConfigSummationLineVehicle from './ConfigSummationLineVehicle.vue';

export default {
  components: {
    ConfigSummationLine,
    ConfigSummationLineVehicle,
  },
  props: {
    row: Object,
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    collapsible() {
      return this.row.summationLines.length > 4;
    },

    displayLines() {
      return this.collapsed ? this.row.summationLines.slice(0, 4) : this.row.summationLines;
    },
  },
  methods: {
    onToggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
}
</script>
