const state = {
  items: [],
  order: 1,
  originalItems: [],
};

const getters = {
  byType: ({ items }) => type => {
    return items.find(section => section.type === type);
  },

  currentItems: ({ items, order }) => {
    return items.filter(item => item.order === order);
  },

  maxOrder: ({ items }) => {
    return Math.max(...items.filter(i => !i.hidden && !i.dormant).map(item => item.order));
  }
};

const actions = {
  filterSections({ commit, state }, cb) {
    const filtered = state.originalItems.filter(cb).map((i, index) => ({ ...i, order: index + 1 }));
    commit('setItems', filtered);
  },

  load({ commit }, items) {
    commit('received', items);
  },

  next({ commit, state }) {
    commit('order', state.order + 1);
  },

  goTo({ commit }, order) {
    commit('order', order);
  },

  validate({ commit }, { section, value = true }) {
    commit('set', { type: section.type, validate: value });
  },

  set({ commit }, section) {
    commit('set', section);
  },
};

const mutations = {
  received(state, items) {
    state.items = items;
    state.originalItems = items;
  },

  setItems(state, items) {
    state.items = items;
  },

  order(state, order) {
    state.order = order;
  },

  set(state, { type, ...props }) {
    state.items = state.items.map(item => {
      return (item.type === type) ? {
        ...item,
        ...props,
      } : item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
