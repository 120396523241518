import { pxToInt } from "../utils/numbers";
import siteHeaderOverlays from "./site-header-overlays";
import siteHeaderCartBadge from "./site-header-cart-badge";
import Cookies from "js-cookie";
import mountApp from "../utils/mount-app";
import SiteHeaderSearch from "../apps/site-header-search";
import { EventBus } from "../utils/event-bus";
import Breakpoints from "../constants/Breakpoints";

const selectors = {
  navBorder: ".site-header__nav-border",
  navItem: ".site-header__nav-item:not(.site-header__nav-item--no-dropdown)",
  navItemActive: ".site-header__nav-item--active",
  actionItemActive: ".site-header__action-item--active",
  actionItemNav: ".site-header__action-item--nav",
  metaItem: ".site-header__meta .tab-bar__item",
};

const classes = {
  navBorderIn: "site-header__nav-border--in",
  navItemActive: "site-header__nav-item--active",
  metaItemActive: "tab-bar__item--active",
  actionItemActive: "site-header__action-item--active",
  siteHeaderNavOverlayOpen: "site-header--nav-overlay-open"
};

export default {
  init() {
    if (window.location.href.includes("fritid")) {
      Cookies.set("site", "camping", { expires: 30 });
    }
    this.$siteHeader = $(".site-header");
    this.addEvents();
    this.modifyDOM();
    siteHeaderOverlays.init({
      navOverlayOnClose: this.navOverlayOnClose.bind(this)
    });
    siteHeaderCartBadge.init();
  },

  addEvents() {
    $("body")
      .on("click", selectors.navItem, this.onNavItemClick.bind(this))
      .on("click", selectors.metaItem, this.onMetaClick.bind(this))
      .on("click", selectors.actionItemNav, this.onActionItemClick.bind(this));
  },

  modifyDOM() {
    if ($(window).width() >= Breakpoints.md) {
      mountApp({
        app: SiteHeaderSearch,
        selector: "[data-app=site-header-search]",
        setI3Channel: true
      });
    }

    EventBus.$on(
      "site-header-search-focused",
      this.toggleSearchOverlay.bind(this)
    );
    EventBus.$on(
      "site-header-search-blurred",
      this.toggleSearchOverlay.bind(this)
    );
  },

  onNavItemClick({ currentTarget }) {
    const $el = $(currentTarget);
    const wasActive = $el.hasClass(classes.navItemActive);

    $el.siblings().removeClass(classes.navItemActive);

    if (wasActive) {
      $el.removeClass(classes.navItemActive);
    } else {
      $el.addClass(classes.navItemActive);
    }

    this.updateNavBorder($el, wasActive);
    this.updateSiteHeaderClass();

    return false;
  },

  onMetaClick({ currentTarget }) {
    const $target = $(currentTarget);
    const site = $target.data("site");
    const url = $target.data("url");

    Cookies.set("site", site, { expires: 30 });

    window.location.href = url;
  },

  onActionItemClick({ currentTarget }) {
    const $el = $(currentTarget);

    $el.toggleClass(classes.actionItemActive);
    this.updateSiteHeaderClass();

    return false;
  },

  updateNavBorder($el, hide) {
    const $a = $el.find("a");
    const paddingLeft = pxToInt($a.css("padding-left"));
    const paddingRight = pxToInt($a.css("padding-right"));
    const width = hide ? 0 : $el.outerWidth() - paddingLeft - paddingRight;
    const left =
      $el.offset().left - $(".site-header__nav").offset().left + paddingLeft;

    if (hide) {
      $(selectors.navBorder).removeClass(classes.navBorderIn);
    } else {
      setTimeout(() => {
        $(selectors.navBorder).addClass(classes.navBorderIn);
      }, 1);
    }

    $(selectors.navBorder).css({
      width,
      left
    });
  },

  updateSiteHeaderClass() {
    if (
      $(selectors.navItemActive).length ||
      $(selectors.actionItemActive).length
    ) {
      this.$siteHeader.addClass(classes.siteHeaderNavOverlayOpen);
    } else {
      this.$siteHeader.removeClass(classes.siteHeaderNavOverlayOpen);
    }
  },

  navOverlayOnClose() {
    const $activeEl = $(selectors.navItemActive);

    $activeEl.removeClass(classes.navItemActive);
    this.updateNavBorder($activeEl, true);
    this.updateSiteHeaderClass();
  },
  toggleSearchOverlay({ overlayIsOpen }) {
    if (overlayIsOpen) {
      const $headerEl = $(".site-header__search__overlay");
      $headerEl.removeClass("site-header__search__overlay--visible");
      const $siteEl = $(".site-overlay");
      $siteEl.removeClass("site-overlay--visible");
    } else {
      const $headerEl = $(".site-header__search__overlay");
      $headerEl.addClass("site-header__search__overlay--visible");
      const $siteEl = $(".site-overlay");
      $siteEl.addClass("site-overlay--visible");
    }
  }
};
