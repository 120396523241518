var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eon-product-spot" },
    [
      _c("div", { staticClass: "eon-product-spot__desc" }, [
        _c(
          "div",
          { staticClass: "eon-product-spot__info" },
          [
            _c("div", { staticClass: "eon-product-spot__meta" }, [
              _c(
                "span",
                { staticClass: "mark mark--black mark--sm mark--uppercase" },
                [_vm._v(_vm._s(_vm.product.shortConditionType))]
              ),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.product.productTypeDisplayName) +
                  " " +
                  _vm._s(_vm.product.hasTpms ? "med TPMS" : "") +
                  "\n\n      "
              )
            ]),
            _vm._v(" "),
            _c("EonProductTitle", {
              attrs: { product: _vm.product },
              on: { onClick: _vm.onClick }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "eon-product-spot__image",
            on: { click: _vm.onClick }
          },
          [_c("img", { attrs: { src: _vm.product.imageThumbnailUrl } })]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "eon-product-spot__usps",
          class: { "eon-product-spot__usps--empty": !_vm.product.usps.length }
        },
        _vm._l(_vm.product.usps, function(usp) {
          return _c(
            "div",
            {
              key: usp.icon,
              staticClass: "eon-product-spot__usps__item ttjs",
              attrs: { "data-ttjs-title": usp.text }
            },
            [
              _c("i", { class: usp.icon }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(usp.label))])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "eon-product-spot__buy" },
        [
          _c("div", { staticClass: "eon-product-spot__price" }, [
            _c("div", { staticClass: "eon-product-spot__price__section" }, [
              _c("div", { staticClass: "eon-product-spot__price__details" }, [
                _c(
                  "div",
                  {
                    staticClass: "price-tag",
                    class: {
                      discount: _vm.product.discounted(_vm.currentQuantity)
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$formatPrice(
                            _vm.product.totalPriceWithVat(_vm.currentQuantity)
                          )
                        ) +
                        " / st\n          "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.product.discounted(_vm.currentQuantity) &&
                _vm.product.discount <= 100
                  ? _c("div", { staticClass: "product-tag red" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.product.discount) +
                          "% rabatt\n          "
                      )
                    ])
                  : _vm.product.discounted(_vm.currentQuantity) &&
                    _vm.product.discount > 100
                  ? _c("div", { staticClass: "product-tag red" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.product.discount * _vm.currentQuantity) +
                          "kr rabatt\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "eon-product-spot__price__meta" },
                [
                  _vm.product.discounted(_vm.currentQuantity)
                    ? _c(
                        "span",
                        { staticClass: "eon-product-spot__price--obsolete" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$formatPrice(
                                _vm.product.totalOrdinaryPriceWithVat
                              )
                            ) + " / st"
                          ),
                          _c("br")
                        ]
                      )
                    : _vm._e(),
                  _vm._v(
                    "\n          Avbetalning från " +
                      _vm._s(
                        _vm.$formatPrice(
                          _vm.product.lowestPartPaymentPrice(
                            _vm.currentQuantity
                          )
                        )
                      ) +
                      "/mån "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("DeliveryStatusCircle", {
                    attrs: { deliveryStatus: _vm.deliveryStatus, small: "" }
                  }),
                  _vm._v(" " + _vm._s(_vm.deliveryStatusLabel) + "\n        ")
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("EonProductBuyActions", {
            attrs: {
              product: _vm.product,
              regNr: _vm.regNr,
              isBuildWheel: _vm.isBuildWheel,
              selectedProductType: _vm.selectedProductType,
              selectedProduct: _vm.selectedProduct
            },
            on: { quantity: _vm.onQuantity, added: _vm.onAdded }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "portal",
        { attrs: { to: "eon-product-services-modal" } },
        [
          _vm.servicesModalOpen
            ? _c("EonProductServicesModal", {
                attrs: {
                  product: _vm.product,
                  addedQuantity: _vm.addedQuantity
                },
                on: {
                  close: function($event) {
                    _vm.servicesModalOpen = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }