<template>
  <form @submit.prevent="onSubmit">
    <label>
      {{lang.App.CreateAccount}}
    </label>
    <VcFormStack label="E-post" required>
      <input
        v-model="email"
        class="form-control"
        placeholder="Ange din epost"
        required
        type="email" />
    </VcFormStack>
    <VcFormStack label="Lösenord" required>
      <input
        v-model="password"
        class="form-control"
        placeholder="Minst 6 tecken"
        required
        type="password" />
    </VcFormStack>
    <VcFormStack label="Upprepa lösenord" required>
      <input
        v-model="confirmPassword"
        class="form-control"
        placeholder="Ange samma lösenord igen"
        required
        type="password" />
    </VcFormStack>
    <button :class="{'disabled': btnDisabled}" type="submit" class="btn btn--primary btn--block">
      {{lang.App.CreateAccount}}
    </button>
    <div v-if="registerError" class="feedback-message feedback-message--danger feedback-message--slim">
      <div class="feedback-message__text">
        {{errorMessage}}
      </div>
    </div>
    <div v-if="registerSuccess" class="feedback-message feedback-message--success feedback-message--slim">
      <div class="feedback-message__text">
        {{successMessage}}
      </div>
    </div>
    <p class="form-text" v-html="policyText">
    </p>
  </form>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';
import Axios from '../../axios';
const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{6,}$/;

export default {
  components: {
    VcFormStack,
  },
  props: {
    lang: Object,
  },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      policyText: 'Genom att skapa konto godkänner jag Holmgrens villkor och bekräftar att jag läst dataskyddspolicyn.',
      errorMessage: '',
      registerError: false,
      registerSuccess: false,
      successMessage: this.lang.App.RegisterSuccess,
      btnDisabled: false,
    };
  },
  methods: {
    async onSubmit() {
      this.errorMessage = '';
      this.registerError = false;
      this.registerSuccess = false;
      if(!pattern.test(this.password)) {
        this.registerError = true;
        this.errorMessage = this.lang.App.PasswordPatternText;
        return;
      }
      if(this.password !== this.confirmPassword) {
        this.registerError = true;
        this.errorMessage = this.lang.App.PasswordMatchText;
        return;
      }
      const {
        email,
        password,
        confirmPassword,
      } = this;

      this.btnDisabled = true;
      try {
        const result = await Axios.post('/api/login/register', { email, password, confirmPassword });
        if(result && result.data && result.data.signInStatus === "Success") {
          this.registerSuccess = true;
        } else {
          if(result.data.message) {
            this.errorMessage = result.data.message;
          } else {
            this.errorMessage = this.lang.App.RegisterFailedGeneric;
          }
          this.registerError = true;
        }
      } catch(e) {
        console.error(e);
        this.registerError = true;
        this.errorMessage = this.lang.App.RegisterFailedGeneric;
      } finally {
        this.btnDisabled = false;
      }
    },
  },
}
</script>
