<template>
  <div class="choose-spec">
    <div class="choose-spec__content">
      <div v-if="!vehicleSelected">
        <div>
          <div class="regnr-input">
              <div class="input-group">
                  <div class="input-group-prepend">
                      <div class="regnr-input__symbol"></div>
                  </div>
                  <div class="form-stack">
                      <label>
                          Regnummer
                      </label>
                      <input id="regNr-input" type="text" class="form-control" placeholder="ABC123" v-model="regNr">
                  </div>
              </div>
          </div>
          <button
            class="btn btn--primary regnr-input-sibling"
            :disabled="!vehicleData"
            @click="() => onFindProducts()"
          >
              Hitta produkter
          </button>
        </div>
        <div class="choose-spec__options">
          <div class="choose-spec__options-header">
            Har du inget regnummer?
          </div>
          <div class="inline-bullets">
            <div
              class="inline-bullets__item"
              @click="manualSelectModalOpen = true"
            >
              <a class="link link--underline">Välj bilmodell</a>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="choose-spec__options-header">
          Valt fordon: {{ vehicleData }}
        </div>
        <div class="inline-bullets">
          <div
            class="inline-bullets__item"
            @click="clearVehicle"
          >
            <a class="link link--underline">Rensa fordon</a>
          </div>
        </div>
      </div>
    </div>
    <ManualVehicleSelect
      v-if="manualSelectModalOpen"
      modalHeader="Välj bilmodell"
      buttonText="Se produkter"
      @setCarManually="setCarManually"
      @modalClose="manualSelectModalOpen = null"
    />
  </div>
</template>

<script>
import I3QueryParams from "../../constants/I3QueryParams";
import Tooltip from 'tooltip.js';
import ServicesApi from '../../models/ServicesApi';
import debounce from 'debounce';
import ManualVehicleSelect from './ManualVehicleSelect.vue';
import ChannelEvents from '../../constants/ChannelEvents';
import Cookies from "js-cookie";

export default {
  components: {
    ManualVehicleSelect

  },
  props: {
    channel: Object,
    lang: {
      type: Object
    },
  },
  data() {
    return {
      regNr: '',
      tooltipInstance: null,
      vehicleData: '',
      manualSelectModalOpen: false,
      vehicleSelected: false,
    };
  },
  created() {
    this.channel.one(ChannelEvents.QuerySuccess, this.oneQuerySuccess);
    this.fetchCarDebounced = debounce(this.fetchCarInfo.bind(this), 1000);
  },
  watch: {
    regNr() {
      if (this.regNr.match(/^\w{3}\d{2}(\w{1}|\d{1})$/g)) {
        this.fetchCarDebounced();
      }
    },
  },  
  methods: {
    async oneQuerySuccess() {
      const { params } = this.channel.lastQuery;
      if (params.regNr) {
        await this.fetchCarInfo(params.regNr);
        await this.onFindProducts(params.regNr);
      } else if (
        params.brandId &&
        params.modelId &&
        params.engineId
      ) {
        try {
          const res = await ServicesApi.fetchVehicleWithIds(
            params.brandId,
            params.modelId,
            params.engineId,
          );
          this.vehicleSelected = true;
          this.vehicleData = res.data;
        } catch (e) {
          console.warn(e);
        }
      }
    },
    createTooltipInstance(title, status) {
      this.tooltipInstance && this.tooltipInstance.hide();
      const el = document.querySelector("#regNr-input");
      const instance = new Tooltip(el, {
        title,
        html: true,
        trigger: "manual",
        placement: "top",
        closeOnClickOutside: true,
        arrowSelector: ".ttjs-tooltip-arrow",
        innerSelector: ".ttjs-tooltip-inner",
        template: `
          <div class="ttjs-tooltip ttjs-tooltip--landing-${status}" role="tooltip">
            <div class="ttjs-tooltip-arrow ttjs-tooltip-arrow--landing-${status}"></div>
            <div class="ttjs-tooltip-inner"></div>
          </div>`
      });

      return (this.tooltipInstance = instance);
    },
    async fetchCarInfo(regNr) {
      try {
        const setRegNr = regNr || this.regNr;
        Cookies.set('AccessoryRegNr', setRegNr, { expires: 7 });
        const res = await ServicesApi.fetchVehicle({
          regNr: setRegNr,
        });
  
        let status = '';
        let title = '';

        if (res.data && res.data.partsBrand) {
          status = "succ";
          title = `${res.data.brand} ${res.data.series} ${res.data.engineName} (${res.data.generation})`
          this.vehicleData = `${res.data.brand} ${res.data.series} ${res.data.engineName} (${res.data.generation})`;

        } else if (res.data && !res.data.partsBrand) {
          status = "err";
          title = 'Tyvärr har vi inga tillbehör till din bil.';
        } else {
          status = "err";
          title = 'Tyvärr kunde vi inte få information om din bil. Sök med hjälp av din bilmodell istället.';
        }
        await this.createTooltipInstance(title, status);
        this.tooltipInstance.show();
      } catch (e) {
        const status = "err";
        const title = 'Tyvärr kunde vi inte få information om din bil. Sök med hjälp av din bilmodell istället.';
      
        await this.createTooltipInstance(title, status);
        this.tooltipInstance.show();
      }
    },
    onClearTooltip(e) {
      const classes =
      e.target.offsetParent && Object.values(e.target.offsetParent.classList);
      if (classes && !classes.includes("ttjs-tooltip")) {
        this.tooltipInstance && this.tooltipInstance.hide();
        this.tooltipInstance = null;
      }
    },
    setCarManually(data) {
      const query = this.channel.lastQuery;
      query.setParam(I3QueryParams.BrandId, data.brandId);
      query.setParam(I3QueryParams.ModelId, data.modelId);
      query.setParam(I3QueryParams.EngineId, data.engineId);

      this.channel.execute(query)
      this.vehicleData = `${data.brand} ${data.model} ${data.engine} (${data.generation})`
      this.manualSelectModalOpen = false;
      this.vehicleSelected = true;
    },
    async onFindProducts(regNr) {
      const setRegNr = regNr || this.regNr;
      if (setRegNr) {
        const query = this.channel.lastQuery;
        query.setParam(I3QueryParams.RegNr, setRegNr);
  
        this.channel.execute(query)
        this.vehicleSelected = true;
      }
    },
    clearVehicle() {
      this.regNr = '';
      Cookies.remove('AccessoryRegNr')
      this.vehicleData = '';
      this.vehicleSelected = false;
      
      const query = this.channel.lastQuery;
      query.unsetParam(I3QueryParams.BrandId);
      query.unsetParam(I3QueryParams.ModelId);
      query.unsetParam(I3QueryParams.EngineId);
      query.unsetParam(I3QueryParams.RegNr);

      this.channel.execute(query)
    }
  }
};
</script>
