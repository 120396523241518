<template>
  <div>
    <div class="product-meta__license-plate">
      <div class="product-meta__license-plate__symbol"></div>
      <span v-if="product.displayReport"><a class="link js-regnr-history"
          @click.prevent="openModal(ModalTypes.History)">{{ product.regNr }}</a></span>
      <span v-else>{{ product.regNr }}</span>
    </div>
    <div class="product-meta__links">
      <div class="product-meta__links__item">
        <i class="fas fa-map-marker-alt"></i> {{ product.locationName }}
      </div>
    </div>

    <VcModal v-if="activeModal" @close="closeModal">
      <div slot="header">
        {{ activeModal.header }}
      </div>
      <div slot="content" v-html="activeModal.content"></div>
    </VcModal>
  </div>
</template>

<script>
import Axios from "../../axios";
import { VcModal } from "@holmgrensbil/vue-components";

const ModalTypes = {
  History: 3
};

const modals = [
  {
    type: ModalTypes.History,
    fetchUrl: regNr => `/fordon/${regNr}/GetVehicleReport`,
    header: "Historik"
  }
];

export default {
  props: {
    product: Object
  },
  components: {
    VcModal
  },
  data() {
    return {
      activeModal: null,
      ModalTypes,
      modals
    };
  },
  methods: {
    openModal(type) {
      const modal = this.modals.find(m => m.type === type);

      Axios.get(modal.fetchUrl(this.product.regNr)).then(res => {
        this.activeModal = {
          header: modal.header,
          content: res.data
        };
      });
    },

    closeModal() {
      this.activeModal = null;
    }
  }
};
</script>
