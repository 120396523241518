export default class I3DocProduct {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc.vehicleIdentifier;
  }

  get registrationDate() {
    var d = new Date(this._doc.registrationDate),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

    return [year, month].join('-');
  }

  get endDate() {
    return this._doc.arrivalDate;
  }

  get color() {
    return this._doc.baseColorName === "Okänd" ? this._doc.factoryColor : this._doc.baseColorName;
  }

  get isRental() {
    return this._doc.internalProperties.includes('Hyrbil');
  }

  get brandName() {
    return this._doc.brandName;
  }

  get modelName() {
    return this._doc.modelName ? this._doc.modelName : this._doc.trimText;
  }
}
