import Axios from '../axios';
import Swiper from "../swiper";

export default {
  init() {
    this.resolved = {};
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '[data-modal-source="ajax"]', this.onAjaxDataSourceModalClick.bind(this));
    $(document).on('click', '.js-ajax-modal', this.onAjaxModalLinkClick.bind(this));
  },

  onAjaxModalLinkClick({ currentTarget }) {
    const $el = $(currentTarget);
    if($el.closest('.modal').length) {
      return false;
    }
    const url = $el.attr('href');
    this.openModal($el, url);
    return false;
  },

  onAjaxDataSourceModalClick({ currentTarget }) {
    const $el = $(currentTarget);
    if($el.closest('.modal').length) {
      return false;
    }
    const url = $el.data('target');
    this.openModal($el, url);
    return false;
  },

  openModal($el, url) {
    if (this.resolved[url]) {
      this.resolved[url].modal('show');
      return;
    }

    const isButton = $el.hasClass('btn');

    if (isButton) {
      $el.addClass('disabled');
    }

    Axios.get(url).then(({data}) => {
      const $modal = $(data).modal();
      this.resolved[url] = $modal;
      this.initSwiper();
      if (isButton) {
        $el.removeClass('disabled');
      }
    });
    
  },

  initSwiper() {
    this.swiper = new Swiper(".modal .swiper-container", {
      autoHeight: true,
      slidesPerView: 2,
      spaceBetween: 16,
      pagination: {
        el: ".modal .swiper-pagination",
        dynamicBullets: true
      },
      navigation: {
        nextEl: ".product-images__nav--next",
        prevEl: ".product-images__nav--prev",
        disabledClass: "product-images__nav--disabled"
      },
    });
  }
};
