var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.storeState.config
    ? _c("div", { staticClass: "campaign-configurator configurator-app" }, [
        _c(
          "div",
          { staticClass: "section__content section__content--no-padding" },
          [
            _c("h3", { staticClass: "campaign-configurator__header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.storeState.config.selectionHeading) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _vm.storeState.config.carConfigurations.length > 1
              ? _c(
                  "div",
                  { staticClass: "campaign-configurator__models" },
                  _vm._l(_vm.storeState.config.carConfigurations, function(
                    configuration,
                    index
                  ) {
                    return _c(
                      "div",
                      {
                        key: configuration.modelDetails,
                        staticClass: "campaign-configurator__model-item",
                        class: {
                          "campaign-configurator__model-item--selected":
                            _vm.storeState.selected &&
                            _vm.storeState.selected.modelDetails ===
                              configuration.modelDetails
                        },
                        on: {
                          click: function($event) {
                            return _vm.onConfigSelect(index, configuration)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "campaign-configurator__thumb-image" },
                          [
                            _c("img", {
                              attrs: {
                                src: configuration.mainImage,
                                alt: "Produktbild"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(configuration.modelDetails) +
                              "\n        "
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.storeState.selected
          ? _c("div", [
              _c(
                "div",
                {
                  ref: "main",
                  staticClass: "section__content campaign-configurator__main"
                },
                [
                  _vm.storeState.selected.ribbonText
                    ? _c(
                        "div",
                        {
                          ref: "ribbon",
                          staticClass: "ribbon ribbon--primary ribbon--right"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.storeState.selected.ribbonText) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "campaign-configurator__product" },
                    [
                      _c("h2", { staticClass: "sm" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.storeState.selected.brand) +
                            " " +
                            _vm._s(_vm.storeState.selected.model) +
                            "\n          " +
                            _vm._s(_vm.storeState.selected.modelDetails) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.storeState.selected.modals.length
                        ? _c("ConfigMetaLinks", {
                            attrs: { modals: _vm.storeState.selected.modals }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "campaign-configurator__main-image" },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.storeState.mainImage,
                              alt: "Produktbild"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(
                    _vm.storeState.selected.carConfigurationGroups,
                    function(group) {
                      return _c("ConfigGroup", {
                        key: group.parameterName,
                        attrs: { group: group },
                        on: { change: _vm.onChange }
                      })
                    }
                  ),
                  _vm._v(" "),
                  _vm.storeState.selected.footerText
                    ? _c("div", {
                        staticClass: "campaign-configurator__main__text",
                        domProps: {
                          innerHTML: _vm._s(_vm.storeState.selected.footerText)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ConfigSummation", {
                    attrs: {
                      summation:
                        _vm.storeState.selected.carConfigurationSummation,
                      ctaActive: true,
                      ctaFakeLoading: false,
                      lang: _vm.lang,
                      departmentOptions:
                        _vm.storeState.selected.departmentOptions,
                      submitState: _vm.submitState,
                      isPurchasable: _vm.storeState.selected.isPurchasable
                    },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }