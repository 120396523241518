var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout" },
    [
      _c(
        "div",
        { staticClass: "checkout__container" },
        [
          _vm.paymentError
            ? _c(
                "VcFeedbackMessage",
                {
                  staticClass: "checkout__payment-error",
                  attrs: { danger: "" }
                },
                [
                  _c("strong", [
                    _c("i", { staticClass: "fas fa-exclamation-triangle" }),
                    _vm._v(" " + _vm._s(_vm.genericPaymentError) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n        Kontrollera att du har tillräckligt med pengar på ditt konto om du\n        betalade med kort.\n        "
                    ),
                    _vm.selectedDepartment
                      ? _c("span", [
                          _vm._v(
                            "\n          Ring till Holmgrens Bil " +
                              _vm._s(_vm.selectedDepartment.name) +
                              " på\n          " +
                              _vm._s(_vm.selectedDepartment.phoneNr) +
                              " eller läs mer\n          "
                          ),
                          _c(
                            "a",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              attrs: {
                                target: "_blank",
                                href: _vm.selectedDepartment.departmentUrl
                              }
                            },
                            [_vm._v("här.")]
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("CheckoutSection", { attrs: { arrow: "" } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("i", { staticClass: "fal fa-shopping-cart" }),
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.lang.Checkout.CheckoutTitle +
                      " " +
                      _vm.checkoutTypeHeader
                  ) +
                  "\n\n        "
              ),
              _c(
                "span",
                {
                  staticClass: "link link--primary checkout__send-cart",
                  on: {
                    click: function($event) {
                      _vm.activeSendCartModal = true
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fal fa-share-square" }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.lang.Checkout.SendCart.SendCartLinkText)
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("Cart", {
                  attrs: { isAccessoryCart: _vm.isAccessoryCheckout }
                }),
                _vm._v(" "),
                _vm.hasUpsellProducts
                  ? _c("UpsellProducts", {
                      attrs: { upsellProducts: _vm.upsellProducts },
                      on: { added: _vm.onUpsellAdded }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("CartSummary")
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.shouldShowRegNrInput
            ? _c(
                "CheckoutSection",
                { attrs: { arrow: "" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("i", { staticClass: "fal fa-car" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.lang.Checkout.VehicleInformation.Title))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("ChooseVehicle", {
                    attrs: { slot: "content", lang: _vm.lang },
                    on: {
                      setRegNumber: _vm.onSetRegNumber,
                      setMultipleRegNumbers: _vm.onSetMultipleRegNumbers
                    },
                    slot: "content"
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "CheckoutSection",
            { attrs: { arrow: "" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("i", { staticClass: "fal fa-car-garage" }),
                _vm._v(" "),
                !_vm.urlSiteId
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.lang.Checkout.Department.Title))
                    ])
                  : _c("span", [_vm._v("Däckhotell")])
              ]),
              _vm._v(" "),
              _c("ChoosePickup", {
                attrs: {
                  slot: "content",
                  "url-site-id": _vm.urlSiteId,
                  lang: _vm.lang,
                  isAccessoryCheckout: _vm.isAccessoryCheckout
                },
                on: { changePickup: _vm.sendGTMEvent },
                slot: "content"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "CheckoutSection",
            { attrs: { arrow: "" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("i", { staticClass: "fal fa-user" }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Checkout.CustomerDetails.Title) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("CustomerDetails", {
                attrs: { slot: "content", lang: _vm.lang },
                on: { ssnChange: _vm.ssnChange },
                slot: "content"
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "CheckoutSection",
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("i", { staticClass: "fal fa-credit-card" }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Checkout.Payment.Title) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("ChoosePayment", {
                attrs: {
                  slot: "content",
                  lang: _vm.lang,
                  prefilledSsn: _vm.ssn
                },
                on: { changePayment: _vm.sendGTMEvent },
                slot: "content"
              }),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "summary" }, slot: "summary" },
                [
                  _c("CartSummary"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "\n            form-check form-check--sm form-check--inline\n            checkout__accept-terms\n          "
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.acceptedTerms,
                            expression: "acceptedTerms"
                          }
                        ],
                        staticClass: "form-check__input",
                        attrs: { id: "acceptedTerms", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.acceptedTerms)
                            ? _vm._i(_vm.acceptedTerms, null) > -1
                            : _vm.acceptedTerms
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.acceptedTerms,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.acceptedTerms = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.acceptedTerms = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.acceptedTerms = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check__label",
                          attrs: { for: "acceptedTerms" }
                        },
                        [
                          _vm._v("\n            Jag godkänner\n            "),
                          _c(
                            "a",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              attrs: {
                                target: "_blank",
                                href: _vm.integrityUrl
                              }
                            },
                            [_vm._v("integritetspolicyn")]
                          ),
                          _vm._v("\n            och\n            "),
                          _c(
                            "a",
                            {
                              staticStyle: { "text-decoration": "underline" },
                              attrs: {
                                target: "_blank",
                                href: _vm.purchaseTermUrl
                              }
                            },
                            [_vm._v("köpvillkoren")]
                          ),
                          _vm._v("\n            för Holmgrens Bil.\n          ")
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.valid
                    ? _c(
                        "VcFeedbackMessage",
                        { attrs: { danger: "", slim: "", center: "" } },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.lang.Checkout.Payment.Options) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.submitError
                    ? _c(
                        "VcFeedbackMessage",
                        { attrs: { danger: "", slim: "", center: "" } },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.submitError) +
                                "\n          "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--lg btn--block btn--primary",
                      class: { disabled: !_vm.valid },
                      attrs: { id: "checkout-submit" },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.lang.Checkout.SubmitLabel) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "form-text",
                    domProps: {
                      innerHTML: _vm._s(_vm.lang.General.PrivacyPolicyText)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeSendCartModal
        ? _c("VcModal", { on: { close: _vm.onShareModalClose } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.lang.Checkout.SendCart.SendCartLinkText) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "content" }, slot: "content" }, [
              !_vm.cartShared
                ? _c("div", [
                    _c("h5", { staticClass: "u-fw-regular" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.lang.Checkout.SendCart.SendCartModalText) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.shareCart($event)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-stack form-stack--required" },
                          [
                            _c("label", [_vm._v(" E-post ")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shareToEmail,
                                  expression: "shareToEmail"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "email",
                                placeholder: "Ange e-post...",
                                required: "required"
                              },
                              domProps: { value: _vm.shareToEmail },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.shareToEmail = $event.target.value
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--primary",
                            attrs: { type: "submit" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.lang.Checkout.SendCart.SendCartLinkText
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "feedback-message feedback-message--success"
                      },
                      [
                        _c("div", { staticClass: "feedback-message__header" }, [
                          _vm._v("Varukorg delad")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "feedback-message__text" }, [
                          _vm._v(
                            "\n            Varukorgen har delats med e-posten " +
                              _vm._s(_vm.shareToEmail) +
                              ".\n          "
                          )
                        ])
                      ]
                    )
                  ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }