<template>
  <div class="eon-sort" @click="$emit('clicked')">
    <VcFormSelect
      :options="sortOptions"
      :value="selected"
      prompt="Sortera"
      valueProp="value"
      labelProp="name"
      @change="onChange">
    </VcFormSelect>
  </div>
</template>

<script>
import { VcFormSelect } from '@holmgrensbil/vue-components';
import ChannelEvents from '../../../constants/ChannelEvents';
import EonQueryParams from '../../../constants/EonQueryParams';
import ChannelQuery from '../../../models/ChannelQuery';

export default {
  components: {
    VcFormSelect,
  },
  props: {
    channel: Object,
  },
  data() {
    return {
      sortOptions: [
        { name: 'Sortera på tillgänglighet och pris', value: 'source' },
        { name: 'Sortera på enbart pris', value: 'price' },
      ],
      selected: null,
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  methods: {
    onQuerySuccess() {
    },

    onChange(value) {
      this.selected = value;

      const sortValue = this.selected ? this.selected.value : '';

      const query = new ChannelQuery(this.channel.lastQuery);
      query.setParam(EonQueryParams.SortBy, sortValue);
      this.channel.execute(query);
    },
  },
}
</script>
