<template>
  <div class="u-margin-top-lg">
    <div class="row">
      <div class="col-md-12">
        <h4 class="u-fw-medium">Dina uppgifter</h4>
        <div class="form-row" v-if="diplayDepartmentSelector">
          <div class="form-row__col">
            <VcFormStack label="Välj din närmaste aläggning" :required="diplayDepartmentSelector">
              <VcFormSelect
                prompt="Välj din anläggning"
                :options="store.departments"
                :value="store.selectedDepartment"
                valueProp="value"
                labelProp="label"
                valueType="string"
                :required="diplayDepartmentSelector"
                @change="onDepartmentChange"
              >
              </VcFormSelect>
            </VcFormStack>
          </div>
        </div>
        <div class="form-row">
          <div class="form-row__col form-row__col--md-50">
            <VcFormStack label="Förnamn" :required="true">
              <input
                v-model="store.customer.firstName"
                class="form-control"
                placeholder="Förnamn"
                :required="true"
                type="text"
              />
            </VcFormStack>
          </div>
          <div class="form-row__col form-row__col--md-50">
            <VcFormStack label="Efternamn" :required="true">
              <input
                v-model="store.customer.lastName"
                class="form-control"
                placeholder="Efternamn"
                :required="true"
                type="text"
              />
            </VcFormStack>
          </div>
        </div>

        <div class="form-row">
          <div class="form-row__col form-row__col--md-50">
            <VcFormStack label="Telefonnummer" :required="true">
              <input
                v-model="store.customer.phone"
                class="form-control"
                placeholder="Telefonnummer"
                :required="true"
                type="text"
              />
            </VcFormStack>
          </div>
          <div class="form-row__col form-row__col--md-50">
            <VcFormStack label="E-post" :required="true">
              <input
                v-model="store.customer.email"
                class="form-control"
                placeholder="E-post"
                :required="true"
                type="email"
              />
            </VcFormStack>
          </div>
        </div>

        <button
          v-if="!store.submitted"
          class="u-margin-top u-margin-bottom btn btn--primary btn--block"
          :disabled="!store.readyForSubmit"
          @click="submit()"
        >
          Skicka
          <FontAwesomeIcon
            v-if="submitting"
            :icon="faSpinnerThird"
            :spin="true"
          >
          </FontAwesomeIcon>
        </button>

        <div
          v-if="store.submitted"
          class="u-margin-top u-margin-bottom feedback-message feedback-message--slim feedback-message--success"
        >
          Din ansökan om serviceavtal har nu skickats in och vi kommer återkomma
          till dig med ytterligare detaljer.
        </div>
        <div
          v-if="store.submitError"
          class="u-margin-top u-margin-bottom feedback-message feedback-message--slim feedback-message--danger"
        >
          Ett fel har tyvärr uppstått. Kontakta kundtjänst för att få hjälp med
          ditt serviceavtal.
        </div>
        <small
          class="u-c-muted u-margin-top"
          v-html="store.lang.General.PrivacyPolicyText"
        ></small>
      </div>
    </div>
  </div>
</template>

<script>
import store from "./store";
import { VcFormStack, VcFormSelect } from "@holmgrensbil/vue-components";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  components: {
    VcFormStack,
    VcFormSelect,
    faSpinnerThird,
    FontAwesomeIcon
  },
  data() {
    return {
      store: store.state,
      submitting: false
    };
  },

  methods: {
    submit() {
      this.submitting = true;
      store.submit();
    },
    onDepartmentChange(e) {
      this.store.selectedDepartment = e;
    }
  },
  computed: {
    diplayDepartmentSelector() {
      return !store.hasPackages && (this.store.lookupResult?.brand == 'BMW' || this.store.lookupResult?.brand == 'MINI');
    }
  }
};
</script>
