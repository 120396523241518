<template>
  <div class="campaign-configurator configurator-app" v-if="storeState.config">
    <div class="section__content section__content--no-padding">
      <h3 class="campaign-configurator__header">
        {{ storeState.config.selectionHeading }}
      </h3>
      <div class="campaign-configurator__models" v-if="storeState.config.carConfigurations.length > 1">
        <div v-for="(configuration, index) in storeState.config.carConfigurations"
          class="campaign-configurator__model-item" :class="{
    'campaign-configurator__model-item--selected':
      storeState.selected &&
      storeState.selected.modelDetails === configuration.modelDetails
  }" :key="configuration.modelDetails" @click="onConfigSelect(index, configuration)">
          <div class="campaign-configurator__thumb-image">
            <img :src="configuration.mainImage" alt="Produktbild" />
          </div>
          <div>
            {{ configuration.modelDetails }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="storeState.selected">
      <div ref="main" class="section__content campaign-configurator__main">
        <div v-if="storeState.selected.ribbonText" class="ribbon ribbon--primary ribbon--right" ref="ribbon">
          {{ storeState.selected.ribbonText }}
        </div>
        <div class="campaign-configurator__product">
          <h2 class="sm">
            {{ storeState.selected.brand }} {{ storeState.selected.model }}
            {{ storeState.selected.modelDetails }}
          </h2>
          <ConfigMetaLinks v-if="storeState.selected.modals.length" :modals="storeState.selected.modals">
          </ConfigMetaLinks>
          <div class="campaign-configurator__main-image">
            <img :src="storeState.mainImage" alt="Produktbild" />
          </div>
        </div>

        <ConfigGroup v-for="group in storeState.selected.carConfigurationGroups" :group="group"
          :key="group.parameterName" @change="onChange">
        </ConfigGroup>
        <div v-if="storeState.selected.footerText" class="campaign-configurator__main__text"
          v-html="storeState.selected.footerText"></div>
        <ConfigSummation :summation="storeState.selected.carConfigurationSummation" :ctaActive="true"
          :ctaFakeLoading="false" :lang="lang" :departmentOptions="storeState.selected.departmentOptions"
          :submitState="submitState" :isPurchasable="storeState.selected.isPurchasable" @submit="onSubmit">
        </ConfigSummation>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ConfigSummation from "../shared/configurator/ConfigSummation.vue";
import ConfigGroup from "./ConfigGroup.vue";
import ConfigMetaLinks from "./ConfigMetaLinks.vue";
import store from "./store";
import RequestStates from "../../constants/RequestStates";
import { scrollToEl } from "../../utils/scrolling";

export default {
  components: {
    ConfigSummation,
    ConfigGroup,
    ConfigMetaLinks
  },
  props: {
    lang: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      storeState: store.state,
      submitState: null
    };
  },
  created() {
    this.onCreated();
  },
  methods: {
    async onCreated() {
      const config = await store.fetchConfigAction();

      if (config.carConfigurations.length > 0) {
        store.setSelectedIndexAction(0);
      }
    },

    onChange() {
      store.updateCalculationAction();
    },

    onConfigSelect(index, config) {
      if (config && config.externalLink) {
        window.open(config.externalLink, "_blank");
        return;
      }
      store.setSelectedIndexAction(index);

      Vue.nextTick(() => {
        this.onScrollToClickWithHeaderOffset();
      });
    },

    onScrollToClickWithHeaderOffset() {
      const $scrollTarget = $(this.$refs.main);
      $([document.documentElement, document.body]).animate({
        scrollTop: $($scrollTarget).offset().top - 106,
      });
    },

    onSubmit({ contactData, configuratorData }) {
      const data = {
        contactData,
        configuratorData,
        currentPageUrl: window.location.toString()
      };

      // console.log("onSubmit", data);
      this.submitState = RequestStates.Request;

      store.submitAction(data).then(() => {
        this.submitState = RequestStates.Success;
      });
      store.trackSubmitAction(data);
    }
  }
};
</script>
