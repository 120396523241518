import Views from './Views';
import IndexView from '../IndexView.vue';
import BankidView from '../BankidView.vue';
import RestoreView from '../RestoreView.vue';
import RegisterView from '../RegisterView.vue';
import LoggedInView from '../LoggedInView.vue';
import ResetView from '../ResetView.vue';
import AddOrEditVehicleView from '../AddOrEditVehicleView.vue';

export default {
  [Views.Index]: IndexView,
  [Views.Bankid]: BankidView,
  [Views.Restore]: RestoreView,
  [Views.Register]: RegisterView,
  [Views.LoggedIn]: LoggedInView,
  [Views.Reset]: ResetView,
  [Views.AddOrEditVehicle]: AddOrEditVehicleView,
}
