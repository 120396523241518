<template>
  <VcFormStack :label="title">
    <VcFormSelect
      :options="availableServices"
      :value="selected"
      labelProp="name"
      valueProp="value"
      prompt="Ingen tjänst vald"
      @change="select">
    </VcFormSelect>
  </VcFormStack>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { VcFormSelect, VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormSelect,
    VcFormStack,
  },
  computed: {
    ...mapState({
      options: state => state.services.options,
      title: state => state.services.title,
      selected: state => state.services.selected,
    }),
    ...mapGetters({
      availableServices: 'services/availableServices',
    }),
  },
  methods: {
    ...mapActions({
      select: 'services/select',
    }),
  }
}
</script>
