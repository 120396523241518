var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OverlayScrollable",
    { staticClass: "i3-site-search" },
    [
      _c("OverlayHeader", {
        attrs: { slot: "header", text: "Sök" },
        on: { close: _vm.onClose },
        slot: "header"
      }),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("VcSearchBar", {
            attrs: {
              placeholder: "Sök fordon, anläggningar och mer...",
              noInputBorders: "",
              disableCancel: _vm.disableSearchCancel
            },
            on: { input: _vm.onSearchInput },
            model: {
              value: _vm.searchString,
              callback: function($$v) {
                _vm.searchString = $$v
              },
              expression: "searchString"
            }
          }),
          _vm._v(" "),
          (_vm.searchString || _vm.selectedPopularSearch) && !_vm.loading
            ? _c("TheResults", {
                attrs: {
                  components: _vm.components,
                  searchString: _vm.searchString
                }
              })
            : !_vm.searchString
            ? _c("TheSuggestions", {
                attrs: { popularSearches: _vm.popularSearches },
                on: { select: _vm.onPopularSearchSelect }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }