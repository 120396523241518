var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FontAwesomeIcon", {
    class:
      "attention-circle " +
      _vm.circleClass +
      " " +
      (_vm.small ? "attention-circle--sm" : ""),
    attrs: { icon: _vm.faCircle }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }