var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.carResults.documents.length > 0 ||
    _vm.pageResults.documents.length > 0 ||
    // departmentResults.documents.length > 0 ||
    _vm.employeeResults.documents.length > 0
      ? _c(
          "div",
          { staticClass: "search-results" },
          [
            _c("ResultList", {
              attrs: { component: _vm.carResults },
              on: { onClick: _vm.onClickAllResults }
            }),
            _vm._v(" "),
            _c("ResultList", { attrs: { component: _vm.pageResults } }),
            _vm._v(" "),
            _c("ResultList", { attrs: { component: _vm.employeeResults } })
          ],
          1
        )
      : _c("div", { staticClass: "i3-site-search__no-results" }, [
          _vm._v("\n    Hittade inget sökresultat.\n  ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }