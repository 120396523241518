import SectionTypes from '../constants/SectionTypes';

export default [
  {
    order: 1,
    type: SectionTypes.Lookup,
  },
  {
    order: 2,
    type: SectionTypes.CampingDetails,
  },
  {
    order: 3,
    type: SectionTypes.Images,
  },
  {
    order: 4,
    type: SectionTypes.Contact,
  },
];