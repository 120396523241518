<template>
  <div class="contact__department-actions">
    <a v-for="action in actions"
      class="btn btn--sm"
      :class="action.class"
      :href="action.url"
      @click="onActionClick(action)"
      :target="isUrlExternal(action.url) ? '_blank' : ''"
      :key="`${action.url}${action.text}`">
      {{action.text}}
    </a>
    <VcModal v-if="hasSparePartsModal && modalActive"
      @close="modalActive = false">
      <div slot="header">
        Skicka en förfrågan på reservdel
      </div>
      <div slot="content">
        <div ref="sparePartsAppHolder">
          <div ref="sparePartsApp"></div>
        </div>
      </div>
    </VcModal>
  </div>
</template>

<script>
import gtm from '../../utils/gtm';
import {VcModal} from '@holmgrensbil/vue-components';
import sparePartsModal from '../spare-parts-modal';
import mountApp from '../../utils/mount-app';
export default {
  components: {
    VcModal,
  },
  props: {
    section: Object,
    department: Object,
    regNr: String,
    hideSections: Boolean,
  },
  data() {
    return {
      modalActive: false,
      sparePartsApp: null,
    }
  },
  computed: {
    actions() {

      const url = this.regNr ?
        `${ this.department[this.section.primaryAction] }?regNr=${ this.regNr }&department=${ this.department.departmentNr }` :
        this.department[this.section.primaryAction];

      const actions = [
        this.section.primaryAction ? {
          actionType: this.section.primaryAction,
          url,
          text: this.section.primaryAction === 'phoneUrl' ? this.department.phoneNr : this.section.primaryActionText,
          class: 'btn--primary',
        } : false,
        this.section.secondaryAction ? {
          actionType: this.section.secondaryAction,
          url: this.department[this.section.secondaryAction],
          text: this.section.secondaryActionText,
          class: 'btn--white',
        } : false,
      ].filter(Boolean);

      return this.hideSections ? [actions[0]] : actions;
    },
    hasSparePartsModal() {
      return this.section.primaryAction === 'sparePartsModal' || this.section.secondaryAction === 'sparePartsModal';
    },
  },
  methods: {
    onActionClick(action) {

      if(action.actionType === 'sparePartsModal') {
        this.openSparePartsModal();
        return;
      }
      const formType = 'contactAppBlock';
      if(action.actionType === 'phoneUrl') {
        const {
          departmentNr,
          phoneNr
        } = this.department;

        gtm.phoneNumberClick({departmentNr, phoneNr, formType});
      } else if(action.actionType === 'directionsUrl') {
        const {
          departmentNr,
        } = this.department;

        gtm.directionsLinkClick({departmentNr, formType});
      }
    },
    openSparePartsModal() {
      this.modalActive = true;
      this.$nextTick(() => {
        const app = sparePartsModal({
          departmentNr: this.department.departmentNr,
        });
        this.sparePartsApp = new app();
        this.sparePartsApp.$mount(this.$refs.sparePartsApp);
      })
    },
    isUrlExternal(url) {
      if (!url) {
        return false;
      }

      if (url[0] === '/') {
        return false;
      }

      return url.indexOf(window.location.hostname) === -1 && url.indexOf("tel:") === -1;
    },
  },
}
</script>
