<template>
  <div class="vehicle-configurator">
    <VcModal v-if="contactModalOpen" @close="onCloseContactModal">
      <div slot="header">
        {{ 'Ta kontakt' }}
      </div>
      <div v-if="loadingData" slot="content" class="vehicle-configurator__modal vehicle-configurator__modal--loading">
        <font-awesome-icon :icon="faSpinnerThird" :spin="true" class="vehicle-configurator__modal__loader"></font-awesome-icon>
      </div>
      <div v-else slot="content" class="vehicle-configurator__modal">
        <div class="vehicle-configurator__modal__container">
          <div v-if="vehicleData && selectedFinancingOption" class="vehicle-configurator__modal__column">
            <div class="vehicle-configurator__modal__vehicle-container">
              <div class="vehicle-configurator__modal__vehicle-image">
                <img :src="vehicleData.image" alt="" />
              </div>
              <div class="vehicle-configurator__modal__vehicle-info">
                <div class="vehicle-configurator__modal__vehicle-title">{{ `${vehicleData.brandName} ${vehicleData.modelName}` }}</div>
                <div class="vehicle-configurator__modal__vehicle-sub-title">{{ vehicleData.modelText }}</div>
                <div class="vehicle-configurator__modal__vehicle-data-container">
                  <span class="vehicle-configurator__modal__vehicle-data-item">{{ `${ vehicleData.condition } ${ vehicleData.modelYear } • ` }}</span>
                  <span class="vehicle-configurator__modal__vehicle-data-item">{{ `${ vehicleData.mileage } mil • ` }}</span>
                  <span class="vehicle-configurator__modal__vehicle-data-item">{{ `${ vehicleData.fuelName } • ` }}</span>
                  <span class="vehicle-configurator__modal__vehicle-data-item">{{ vehicleData.gearboxName }}</span>
                </div>
              </div>
            </div>
            <Configurator
              :priceMetaString="priceMetaString"
              :lang="lang"
              :regNr="regNr"
              :departments="departments"
              :priceDisplay="priceDisplay"
              :displayTax="displayTax"
              :selectedFinancingOption="selectedFinancingOption"
              @onChangeFinanceOption="onChangeFinanceOption"
            />
          </div>
          <div class="vehicle-configurator__modal__column">
            <div class="vehicle-configurator__contact-form">
              <div class="vehicle-configurator__tab-bar tab-bar">
                <div
                  class="vehicle-configurator__tab-bar__item tab-bar__item"
                  :class="{
                    'tab-bar__item--active': activeContactTab === 'contactForm',
                  }"
                  @click="setContactTab('contactForm')"
                >
                  Ta kontakt
                </div>
                <div
                  class="vehicle-configurator__tab-bar__item tab-bar__item"
                  :class="{
                    'tab-bar__item--active': activeContactTab === 'location',
                  }"
                  @click="setContactTab('location')"
                >
                  Var finns bilen?
                </div>
              </div>
              <div v-if="activeContactTab === 'contactForm'" class="vehicle-configurator__tab-container">
                <div v-if="done" class="vehicle-configurator__feedback vehicle-configurator__feedback--success">
                  <font-awesome-icon :icon="faCircleCheck"></font-awesome-icon>
                  <div>
                    <div class="vehicle-configurator__feedback__title">
                      {{ lang.Summation.FeedbackHeader }}
                    </div>
                    <div class="vehicle-configurator__feedback__text">
                      {{ lang.Summation.FeedbackText }}
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="vehicle-configurator__contact-form__input-section">
                    <div class="vehicle-configurator__text-input-headline">
                      {{ 'E-post' }}
                    </div>
                    <div>
                      <ConfiguratorTextInput
                        inputKey="email"
                        placeholder="E-postadress"
                        type="email"
                        @onChange="(input) => contactEmail = input"
                      />
                    </div>
                  </div>
                  <div class="vehicle-configurator__contact-form__input-section">
                    <div class="vehicle-configurator__text-input-headline">
                      {{ 'Telefonnummer' }}
                    </div>
                    <div>
                      <ConfiguratorTextInput
                        inputKey="phonenumber"
                        placeholder="Telefonnummer"
                        type="tel"
                        @onChange="(input) => contactPhoneNumber = input"
                      />
                    </div>
                  </div>
                  <div class="vehicle-configurator__contact-form__input-section">
                    <ConfiguratorSubSelect
                      :options="contactOptions"
                      :heading="'Vad ser du som nästa steg?'"
                      @change="onChangeContactOption"
                      :defaultValue="selectedContactOption"
                    />
                  </div>

                  <div v-if="selectedContactOption === ContactRequestTypes.AskQuestion"  class="vehicle-configurator__contact-form__input-section">
                    <div class="vehicle-configurator__text-input-headline">
                      {{ 'Meddelande' }}
                    </div>
                    <div>
                      <ConfiguratorTextInput
                        isLongText
                        inputKey="textarea"
                        placeholder="Skriv ditt meddelande..."
                        @onChange="(input) => contactText = input"
                      />
                    </div>
                  </div>
                  <div v-if="selectedContactOption === ContactRequestTypes.BookMeeting || selectedContactOption === ContactRequestTypes.TestDrive" class="vehicle-configurator__contact-form__input-section">
                    <ConfiguratorDateSelect
                      :heading="'Önskat datum?'"
                      :defaultPlaceholder="'Välj önskat datum'"
                      @change="date => contactDate = date"
                    />
                  </div>
                  <div v-if="selectedContactOption === ContactRequestTypes.BookMeeting || selectedContactOption === ContactRequestTypes.TestDrive" class="vehicle-configurator__contact-form__input-section">
                    <ConfiguratorSubSelect
                      :options="contactTimeOptions"
                      :heading="'Önskad tid'"
                      @change="time => contactTime = time.value"
                      :defaultValue="contactTime"
                    />
                  </div>
                  <button class="btn btn--primary btn--block" :disabled="loading || !contactFormValid" @click="submitContactRequest">
                    <font-awesome-icon :icon="loading ? faSpinnerThird : faEnvelope" :spin="loading" class="vehicle-configurator__btn-icon"></font-awesome-icon>
                    {{ 'Skicka meddelande' }}
                  </button>
                  <div class="form-text">
                    <p>
                      Alla personuppgifter som skickas in till Holmgrens Bil kommer att
                      behandlas enligt bestämmelserna i EU:s dataskyddsförordningen
                      (GDPR).&nbsp;<span style="text-decoration: underline"
                        ><a
                          title="Läs om hur vi behandlar din personuppgifter"
                          href="/om-oss/personuppgiftspolicy"
                          >Här</a
                        ></span
                      >&nbsp;kan du läsa mer om hur vi behandlar dina personuppgifter.
                    </p>
                  </div>
                </div>
              </div>
              <div v-else-if="activeContactTab === 'location'" class="vehicle-configurator__tab-container">
                <div class="vehicle-configurator__department-container">
                  <div class="vehicle-configurator__department">
                    <div class="vehicle-configurator__department__title">
                      {{ contact.departmentName }}
                    </div>
                    <div class="vehicle-configurator__department__adress">
                      {{ `${ contact.departmentAddress }, ${ contact.departmentPostCode }, ${ contact.departmentCity }` }}
                    </div>
                    <a class="link link--underline vehicle-configurator__link" @click="onDirectionsClick" :href="contact.directionsUrl" target="_blank">
                      Vägbeskrivning
                    </a>
                  </div>
                  <div class="vehicle-configurator__department-map" :style="`background-image: url(${contact.googleMapsImageUrl})`">
                    <a :href="contact.directionsUrl" target="_blank"></a>
                  </div>
                </div>
                <a
                  :href="showNumber ? `tel:${departmentPhoneNumber}` : '#'"
                  class="btn btn--primary btn--block"
                  @click="onPhoneClick"
                >
                  <font-awesome-icon :icon="faPhone" class="vehicle-configurator__btn-icon"></font-awesome-icon>
                  <span v-if="!showNumber">
                    {{ 'Visa telefonnummer' }}
                  </span>
                  <span v-else>
                    {{ departmentPhoneNumber }}
                  </span>
                </a>
                <div class="vehicle-configurator__department-links">
                  <div class="vehicle-configurator__department-links__item">
                    <div class="opening-hours">
                      <font-awesome-icon :icon="faClock" class="vehicle-configurator__department-links__icon vehicle-configurator__btn-icon opening-hours__clock"></font-awesome-icon>
                      <div class="opening-hours__container">
                        <div @click="displayOpeningHours = !displayOpeningHours">
                          <span v-if="contact.openingHours.isOpenNow">
                            <span class="opening-hours__indicator">Öppet</span> •
                          </span>
                          <span>
                            <a class="link link--underline vehicle-configurator__link">
                              {{ getNextOpeningStatus(contact.openingHours.dates[0], contact.openingHours.dates[1]) }}
                            </a>
                          </span>
                          <font-awesome-icon :icon="displayOpeningHours ? faChevronUp : faChevronDown" class="vehicle-configurator__department-links__chevron"></font-awesome-icon>
                        </div>
                        <div v-if="displayOpeningHours">
                          <div class="opening-hours__row" v-for="date in contact.openingHours.dates" :key="`${date.day}-${date.month}-opening-hour-date`">
                            <span>
                              {{ `${date.description} ${date.day}/${date.month}` }}
                            </span>
                            <span>
                              {{ date.isClosed ? 'Stängt' : `${date.openTime} - ${date.closeTime}` }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <a :href="contact.departmentUrl" target="_blank">
                      <div class="vehicle-configurator__department-links__item">
                        <font-awesome-icon :icon="faHome" class="vehicle-configurator__department-links__icon vehicle-configurator__btn-icon"></font-awesome-icon>
                        <span class="vehicle-configurator__department-links__link"><a class="link link--underline vehicle-configurator__link">Gå till avdelningen</a></span>
                      </div>
                    </a>
                    <a :href="contact.reviewUrl" target="_blank">
                      <div class="vehicle-configurator__department-links__item">
                        <font-awesome-icon :icon="faStar" class="vehicle-configurator__department-links__icon vehicle-configurator__btn-icon"></font-awesome-icon>
                        <span class="vehicle-configurator__department-links__link"><a class="link link--underline vehicle-configurator__link">{{lang.Contact.GiveReview}}</a></span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </VcModal>
  </div>
</template>

<script>
import { faChevronDown, faChevronUp, faPhone, faClock, faEnvelope, faHome, faSpinnerThird, faStar } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { VcModal } from "@holmgrensbil/vue-components";
import Cookies from "js-cookie";
import FinanceOptions from '../../constants/FinanceOptions';
import store from '../main-action/store';
import Configurator from '../main-action/Configurator.vue';
import ConfiguratorTextInput from '../main-action/configurator-components/ConfiguratorTextInput.vue';
import ConfiguratorSubSelect from '../main-action/configurator-components/ConfiguratorSubSelect.vue';
import ConfiguratorDateSelect from '../main-action/configurator-components/ConfiguratorDateSelect.vue';
import { EventBus } from "../../utils/event-bus";
import gtm from "../../utils/gtm";
import Axios from "axios";
import ContactRequestTypes from "../../constants/ContactRequestTypes";
import RequestStates from "../../constants/RequestStates";
import moment from "moment";
import { getPrice } from "../../utils/priceCalculations";
export default {
  props: {
    lang: Object,
  },
  components: {
    VcModal,
    FontAwesomeIcon,
    Configurator,
    ConfiguratorTextInput,
    ConfiguratorSubSelect,
    ConfiguratorDateSelect,
  },
  data() {
    return {
      selectedFinancingOption: null,
      regNr: '',
      contactModalOpen: false,
      storeState: store.state,
      activeContactTab: 'contactForm',
      contactEmail: '',
      contactPhoneNumber: '',
      contactOptions: [
        {name: 'Be oss ringa upp', value: ContactRequestTypes.Callup},
        {name: 'Skicka meddelande', value: ContactRequestTypes.AskQuestion},
        {name: 'Boka möte', value: ContactRequestTypes.BookMeeting},
        {name: 'Boka provkörning', value: ContactRequestTypes.TestDrive},
      ],
      contactDate: '',
      contactTimeOptions: [
        {name: 'Förmiddag', value: 'morning'},
        {name: 'Eftermiddag', value: 'afternoon'},
      ],
      contactTime: 'morning',
      contactText: '',
      selectedContactOption: ContactRequestTypes.Callup,
      showNumber: false,
      displayTax: true,
      displayOpeningHours: false,
      vehicleData: null,
      contact: null,
      departments: [],
      loadingData: false,
      FinanceOptions,
      faEnvelope,
      faSpinnerThird,
      faChevronDown,
      faClock,
      faHome,
      faPhone,
      faStar,
      faChevronUp,
      faCircleCheck,
      submitState: null,
      ContactRequestTypes,
    };
  },
  computed: {
    done() {
      return this.submitState === RequestStates.Success;
    },
    loading() {
      return this.submitState === RequestStates.Request;
    },
    contactFormValid() {
      if (this.selectedContactOption === ContactRequestTypes.BookMeeting || this.selectedContactOption === ContactRequestTypes.TestDrive) {
        return !!this.contactEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && !!this.contactPhoneNumber && !!this.contactDate && !!this.contactTime;
      } else if (this.selectedContactOption === ContactRequestTypes.AskQuestion) {
        return !!this.contactEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && !!this.contactPhoneNumber && !!this.contactText;
      } else {
        return !!this.contactEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && !!this.contactPhoneNumber;
      }
    },
    departmentPhoneNumber() {
      return this.contact ? this.contact.departmentPhone : "";
    },
    priceMetaString() {
      let nameSuffix = this.selectedFinancingOption.nameSuffix;
      if (this.selectedFinancingOption.vehicleType === 'Husbil') {
        return this.selectedFinancingOption.id === FinanceOptions.CASH ? `Objektets pris ${nameSuffix}` : `Finansiering av objekt ${nameSuffix}`;
      } else {
        return this.selectedFinancingOption.id === FinanceOptions.CASH ? `Bilens pris ${nameSuffix}` : `Finansiering av bil ${nameSuffix}`;
      }
    },
    priceDisplay() {
      if (this.selectedFinancingOption) {
        return getPrice(this.selectedFinancingOption.financing, this.selectedFinancingOption.calculationType);
      }
    },
    financingData() {
      return this.storeState.calculation || null;
    }
  },
  async created() {
    EventBus.$on("open-vehicle-contact-modal", this.onOpenModal.bind(this));
    EventBus.$on("alter-tax", this.alterDisplayTax.bind(this));
  },
  methods: {

    async onOpenModal({ regNr, productUrl }) {
      this.regNr = regNr;
      this.contactModalOpen = true;
      this.loadingData = true;
      await this.fetchDepartments(productUrl);
      if (!store.state.hasFetchedVehicle) {
        await store.fetchConfigAction(this.regNr);
      }

      const taxCode = Cookies.get("taxcode");

      store.alterTax(taxCode === "0" ? true : false);

      this.contact = this.storeState.contact;
      this.vehicleData = this.storeState.vehicle;
      this.selectedFinancingOption = this.storeState.selectedFinancingOption;
      this.loadingData = false;
    },
    onCloseContactModal() {
      this.contactModalOpen = false;
      this.clearInputs();
    },
    clearInputs() {
      this.submitState = null;
      this.activeContactTab = 'contactForm';
      this.contactEmail = '';
      this.contactPhoneNumber = '';
      this.contactDate = '';
      this.contactTime = 'morning';
      this.contactText = '';
      this.selectedContactOption = ContactRequestTypes.Callup;
      this.showNumber = false;
      this.displayOpeningHours = false;
      store.clearStore();
    },
    onChangeContactOption(input) {
      this.selectedContactOption = input.value;
      this.contactDate = '';
      this.contactTime = 'morning';
      this.contactText = '';
    },
    alterDisplayTax(shouldDisplayTax) {
      this.displayTax = shouldDisplayTax;
    },
    async fetchDepartments(productUrl) {
      try {
        const { data } = await Axios.get(productUrl + '/getdepartmentsavailable');

        this.departments = data;
      } catch (e) {
        console.warn(e);
      }
    },
    async submitContactRequest() {
      if (this.contactFormValid) {
        try {

          let contactData = {
            currentPageUrl: window.location.toString(),
            customerEmail: this.contactEmail,
            customerPhone: this.contactPhoneNumber,
            departmentNr: this.contact.departmentNr,
            requestType: this.selectedContactOption,
          };

          if (this.selectedContactOption === ContactRequestTypes.BookMeeting || this.selectedContactOption === ContactRequestTypes.TestDrive) {
            contactData = {
              ...contactData,
              requestDate: this.contactDate,
              requestTime: this.contactTime,
            }
          } else if (this.selectedContactOption === ContactRequestTypes.AskQuestion) {
            contactData = {
              ...contactData,
              customerMessage: this.contactText,
            }
          }

          if (contactData.requestType === ContactRequestTypes.Purchase) {
            const purchaseUrl = this.financingData.vehicle?.purchaseUrl || "";

            if (purchaseUrl) {
              window.open(purchaseUrl, "_blank");
              this.submitState = RequestStates.Success;
            }
          } else {
            this.submitState = RequestStates.Request;
            try {
              await store.submitAction(contactData);
              this.submitState = RequestStates.Success;
            } catch (e) {
              console.error(e);
              this.submitState = RequestStates.Failure;
            }
          }
          store.trackSubmitAction(contactData);
        } catch (e) {
          console.error(e);
        }
      }
    },
    setContactTab(key) {
      this.activeContactTab = key;
    },
    onDirectionsClick() {

      gtm.directionsLinkClick({
        departmentNr: this.contact.departmentNr,
        vehicleCondition: this.vehicleData.condition,
        brandName: this.vehicleData.brandName,
        formType: 'configForm'
      });
    },
    onPhoneClick(e) {
      if (!this.showNumber) {
        e.preventDefault();
      }

      const departmentNr = this.contact.departmentNr;
      const phoneNr = this.contact.departmentPhone;
      const vehicleCondition = this.vehicleData.condition;
      const brandName = this.vehicleData.brandName;

      gtm.phoneNumberClick({
        departmentNr,
        phoneNr,
        vehicleCondition: vehicleCondition,
        brandName: brandName
      });

      this.showNumber = true;
    },
    async onChangeFinanceOption(selected) {
      const taxCode = Cookies.get("taxcode");
      let shouldDisplayTax = this.displayTax;
      if (selected === FinanceOptions.PRIVATELEASING || selected === FinanceOptions.OPLEASING || selected === FinanceOptions.BLLEASING) {
        store.clearServiceAgreement();
        store.setTyresAction([]);
      }
      await store.setSelectedFinancingOption(selected);
      EventBus.$emit('change-finance-option', selected);

      this.selectedFinancingOption = selected;
      if (selected.id === FinanceOptions.CASH) {
        shouldDisplayTax = taxCode === "0" ? true : false;
      } else if (
        selected.id === FinanceOptions.INSTALLMENT ||
        selected.id === FinanceOptions.PRIVATELEASING
      ) {
        shouldDisplayTax = true;
      } else if (selected === FinanceOptions.LEASING || selected === FinanceOptions.OPLEASING || selected === FinanceOptions.BLLEASING) {
        shouldDisplayTax = false;
      }
      this.alterDisplayTax(shouldDisplayTax);
      store.alterTax(shouldDisplayTax);
    },
    getNextOpeningStatus(todayDate, tomorrowDate) {
      if (todayDate.isClosed) {
        return 'Stängt';
      } else if (moment().isBefore(moment(todayDate.openTime, 'HH:mm'))) {
        return `Öppnar ${todayDate.openTime}`;
      } else if (moment().isSameOrAfter(moment(todayDate.openTime, 'HH:mm')) && moment().isBefore(moment(todayDate.closeTime, 'HH:mm'))) {
        return `Stänger ${todayDate.closeTime}`;
      } else if (moment().isSameOrAfter(moment(todayDate.closeTime, 'HH:mm')) && !tomorrowDate.isClosed) {
        return `Öppnar imorgon ${tomorrowDate.openTime}`;
      } else if (tomorrowDate.isClosed) {
        return 'Stängt';
      }
    }
  }
}
</script>
