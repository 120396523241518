var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.option.name,
      staticClass: "configurator-select__item",
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.option)
        }
      }
    },
    [_vm._v("\n    " + _vm._s(_vm.option.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }