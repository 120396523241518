<template>
  <div class="contact__tabs">
    <div v-for="section in sections"
      :key="section.sectionHandle"
      class="tab-button"
      :class="{'tab-button--active': isSelected(section)}"
      @click="$emit('select', section)">
      <i :class="[section.icon]"></i>
      <span>
        {{section.name}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sections: Array,
    selected: Object,
  },
  methods: {
    isSelected(section) {
      return section && this.selected && section.sectionHandle === this.selected.sectionHandle;
    },
  },
}
</script>
