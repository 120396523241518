var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStack",
    { attrs: { label: _vm.option.heading, required: _vm.option.required } },
    [
      _c("textarea", {
        staticClass: "form-control",
        attrs: {
          maxlength: _vm.option.maxLength > 0 ? _vm.option.maxLength : ""
        },
        on: {
          change: function($event) {
            return _vm.$emit("change", $event.target.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }