<template>
  <div class="vehicle-list">
    <div class="container">
      <div class="vehicle-list__header">
        <div class="vehicle-list__header__title">
          {{ $formatNumber(hits) }} fordon
        </div>
        <div class="share-page-filterbar__btn ">
          <i class="fal fa-share-square"></i>
          <a
            class="link link--primary js-share-current-url"
            data-toggle="modal"
            data-target="[data-share-page-modal]"
            >Dela</a
          >
        </div>
      </div>
      <TheGrid
        :gridState="gridState"
        :channel="channel"
        :documents="documents"
        :queriedAt="queriedAt"
        @onNext="$emit('onNext')"
        @stateChange="onGridStateChange"
      >
      </TheGrid>
    </div>
    <CompareVehicles />
  </div>
</template>

<script>
import TheGrid from "./TheGrid.vue";
import Cookies from "js-cookie";
import I3QueryParams from "../../constants/I3QueryParams";
import CompareVehicles from "../../apps/compare-vehicles/App.vue";
import qs from "query-string";
const GRID_STATE_COOKIE = "VehicleListGridState";

export default {
  props: {
    channel: Object,
    queriedAt: Date
  },
  components: {
    TheGrid,
    CompareVehicles
  },
  data() {
    return {
      documents: [],
      hits: 0,
      gridState: this.getInitialGridState(),
    };
  },
  watch: {
    queriedAt() {
      this.documents = this.channel.data.documents.documents;
      this.hits = this.channel.data.documents.numberOfHits;
    }
  },
  methods: {
    onGridStateChange(gridState) {
      Cookies.set(
        GRID_STATE_COOKIE,
        {
          date: new Date(),
          location: window.location.toString(),
          ...gridState
        },
        {
          expires: 1
        }
      );
    },

    getInitialGridState() {
      const gridStateCookie = Cookies.get(GRID_STATE_COOKIE);

      if (!gridStateCookie) {
        return undefined;
      }

      const gridState = JSON.parse(gridStateCookie);

      Cookies.remove(GRID_STATE_COOKIE);

      if (gridState.location !== window.location.toString()) {
        return undefined;
      }

      return gridState;
    },
  }
};
</script>
