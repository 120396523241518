import SectionTypeComponents from "../../shared/wizard/constants/SectionTypeComponents";
import SectionTypes from "./SectionTypes";
import SectionContentRimSize from "../SectionContentRimSize.vue";
import SectionContentRimType from "../SectionContentRimType.vue";

export default {
  ...SectionTypeComponents,
  [SectionTypes.RimSize]: SectionContentRimSize,
  [SectionTypes.RimType]: SectionContentRimType
};
