import SectionTypes from '../../shared/wizard/constants/SectionTypes';

export default {
  ...SectionTypes,
  Services: 'Services',
  Department: 'Department',
  Date: 'Date',
  Addons: 'Addons',
  Promotion: 'Promotion',
  ExternalUrl: 'ExternalUrl',
}
