var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list-unstyled" },
    _vm._l(_vm.questions, function(question) {
      return _c("li", { key: question.id }, [
        _c(
          "div",
          {
            staticClass: "contact__faq-question link link--icon",
            on: {
              click: function($event) {
                return _vm.onClick(question)
              }
            }
          },
          [
            _c("span", [_vm._v(_vm._s(question.text))]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.expanded.includes(question.id),
                    expression: "!expanded.includes(question.id)"
                  }
                ]
              },
              [_c("i", { staticClass: "fal fa-angle-down" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expanded.includes(question.id),
                    expression: "expanded.includes(question.id)"
                  }
                ]
              },
              [_c("i", { staticClass: "fal fa-angle-up" })]
            )
          ]
        ),
        _vm._v(" "),
        _vm.expanded.includes(question.id)
          ? _c("div", { staticClass: "contact__faq-answer" }, [
              _vm._v("\n      " + _vm._s(question.answer) + "\n    ")
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }