var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.RenderComponent, {
    tag: "component",
    attrs: { channel: _vm.channel, queriedAt: _vm.queriedAt, lang: _vm.lang },
    on: { onNext: _vm.onNext }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }