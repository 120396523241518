var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.lineParents, function(line) {
      return _c("CartItem", {
        key: line.id,
        attrs: {
          line: line,
          lineChildren: _vm.lineChildrenByParent(line.id),
          currentRegNr: _vm.currentRegNr
        },
        on: { quantity: _vm.onQuantity, remove: _vm.onRemove }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }