<template>
  <VcModal @close="$emit('close')" small fixed>
    <div slot="header">
      Tillagd i varukorg
    </div>
    <div slot="content" class="eon-product-services-modal">
      <VcFeedbackMessage success slim>
        <i class="fas fa-check"></i>
        {{rim.name}} tillagd i varukorgen!
      </VcFeedbackMessage>
      <VcFeedbackMessage success slim>
        <i class="fas fa-check"></i>
        {{tyre.name}} tillagd i varukorgen!
      </VcFeedbackMessage>
      <VcFeedbackMessage v-for="addon in addons" :key="addon.id" success slim>
        <i class="fas fa-check"></i>
        {{addon.name}} tillagd i varukorgen!
      </VcFeedbackMessage>
    </div>
    <div class="eon-product-services-modal__actions" slot="footer">
      <a class="btn btn--white" @click="$emit('close')">
        Fortsätt handla
      </a>
      <a :href="checkoutUrl" class="btn btn--primary btn--block">
        Till kassan
      </a>
    </div>
  </VcModal>
</template>
<script>
import { VcModal, VcFeedbackMessage } from '@holmgrensbil/vue-components';
export default {
  components: {
    VcModal,
    VcFeedbackMessage,
  },
  props: {
    rim: Object,
    tyre: Object,
    addons: Array,
  },
  data() {
    return {
      checkoutUrl: window.EonConfig.checkoutUrl,
    }
  }
}
</script>