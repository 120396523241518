var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [_vm._t("default")], 2),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("VcFormSelect", {
          attrs: {
            options: _vm.quantityOptions,
            value: _vm.quantityValue,
            disabled: _vm.quantityDisabled,
            labelProp: "name",
            valueProp: "value",
            valueType: "number",
            small: ""
          },
          on: {
            change: function($event) {
              return _vm.$emit("quantity", $event.value)
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "cart-item__line-price" },
      [
        _c("VcPriceTag", {
          attrs: {
            vatPercentage: _vm.line.vatPercentage,
            price: _vm.line.totalPrice,
            showIfZero: "",
            hideOrdinaryPriceIfDiscount: ""
          }
        }),
        _vm._v(" "),
        _c("VcPriceTag", {
          attrs: {
            vatPercentage: _vm.line.vatPercentage,
            price: _vm.line.price,
            hideOrdinaryPriceIfDiscount: ""
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm.line.isDeletable
      ? _c(
          "td",
          {
            staticClass: "cart-item__line-remove",
            on: {
              click: function($event) {
                return _vm.$emit("remove")
              }
            }
          },
          [_c("i", { staticClass: "fas fa-trash" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }