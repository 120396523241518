<template>
  <div
    class="eon-filter"
    :class="{
      'eon-filter--column': isColumn,
    }"
  >
    <FilterSection v-for="(section) in displaySections"
      :key="section.type"
      :section="section"
      :mainSpecSelector="mainSpecSelector"
      :isInjected="section.isInjected"
      @fieldChange="onFieldChange"
      @fieldsClear="onFieldsClear">
    </FilterSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ChannelEvents from '../../../constants/ChannelEvents';
import FilterSection from './FilterSection.vue';
import ChannelQuery from '../../../models/ChannelQuery';
import EonQueryParams from '../../../constants/EonQueryParams';
import EonTyreTypes from '../constants/EonTyreTypes';
import EonProductTypes from '../constants/EonProductTypes';
import ProductTypeSectionBlacklists from '../constants/ProductTypeSectionBlacklists';
import FilterConfigurationSections from '../constants/FilterConfigurationSections';
import FieldTypes from '../constants/FieldTypes';
import BuildWheelTypes from '../constants/BuildWheelTypes';

export default {
  components: {
    FilterSection,
  },
  props: {
    channel: Object,
    filterConfiguration: Number,
    isColumn: Boolean,
    mainSpecSelector: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    this.setInitialSeasonType();
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
      fieldsBySection: 'fields/bySection',
      fieldsExport: 'fields/export',
      sectionByType: 'sections/byType',
      parentSections: 'sections/parents',
      childSections: 'sections/children',
    }),

    selectedProductType() {
      return this.fieldByType(EonQueryParams.ProductType).value;
    },

    selectedSeasonType() {
      return this.fieldByType(EonQueryParams.TyreType).value;
    },

    configurationSections() {
      const configSections = FilterConfigurationSections[this.filterConfiguration];

      if (configSections.length === 0) {
        return this.parentSections;
      } else {
        return configSections.map(cs => this.sectionByType(cs));
      }
    },

    displaySections() {
      const blacklist = this.mainSpecSelector && this.fieldByType(FieldTypes.BuildWheel).value === String(BuildWheelTypes.Selected) ? [] : ProductTypeSectionBlacklists[this.selectedProductType];

      return this.configurationSections.filter(section => !blacklist.includes(section.type));
    },
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),
    setInitialSeasonType() {
      if(this.selectedSeasonType === null || typeof this.selectedSeasonType === 'undefined') {
        this.setField({
          type: EonQueryParams.TyreType,
          value: window.EonConfig.isWinterSeason ? String(EonTyreTypes.WinterFriction) : String(EonTyreTypes.Summer),
        });
      }
    },
    onFieldChange({ type, value }) {
      if (type === EonQueryParams.ProductType) {
        this.clearBlacklistedSectionFields(value);
      }

      this.executeQuery();
      this.$emit('onFieldChange', type, value);
    },

    onFieldsClear() {
      this.executeQuery();
    },

    executeQuery() {
      const query = new ChannelQuery(this.fieldsExport);
      this.channel.execute(query);
    },

    clearBlacklistedSectionFields(productType) {
      const blacklist = ProductTypeSectionBlacklists[productType];
      const blSections = this.parentSections.filter(section => blacklist.includes(section.type));
      const blSectionTypes = blSections.map(section => section.type);
      const blSectionChildren = this.childSections.filter(cs => blSectionTypes.includes(cs.parent));
      const blChildFields = blSectionChildren.map(cs => this.fieldsBySection(cs.type)).flat();
      const blFields = blSections.map(section => this.fieldsBySection(section.type)).flat();
      const blJoinedFields = [
        ...blChildFields,
        ...blFields,
      ];

      blJoinedFields.filter(f => f.value).forEach(field => {
        this.setField({
          type: field.type,
          value: field.defaultValue,
        });
      });
    },
  },
}
</script>
