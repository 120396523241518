<template>
  <div>
    <label v-if="field.label">
      {{ field.label }}
    </label>
    <div
      v-for="option in field.options"
      :key="option.value"
      class="form-check form-check--sm form-check--inline"
      @click.stop="onChange(option)"
    >
      <input
        :checked="isSelected(option)"
        class="form-check__input"
        type="checkbox"
      />
      <div class="form-check__label">
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: Object
  },
  methods: {
    isSelected(option) {
      return this.field.value && this.field.value.includes(option.value);
    },

    onChange(option) {
      let currentValue = this.field.value || [];
      if (!Array.isArray(currentValue)) {
        currentValue = [currentValue];
      }
      let value;

      if (this.isSelected(option)) {
        value = currentValue.filter(v => v !== option.value);
      } else {
        value = [...currentValue, option.value];
      }

      this.$emit("change", value);
    }
  }
};
</script>
