var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "u-align-center" },
        [
          _c("FontAwesomeIcon", {
            staticClass: "wizard--service-booking__loader",
            attrs: { icon: _vm.faSpinnerThird, spin: true }
          })
        ],
        1
      )
    : _c("div", [
        _vm.field.options.length
          ? _c(
              "div",
              _vm._l(_vm.field.options, function(group) {
                return _c("div", { key: group.name }, [
                  _c("label", [
                    _vm._v("\n        " + _vm._s(group.name) + "\n      ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _vm._l(
                        _vm.getRowOptions(
                          _vm.groupedOptions(group.name),
                          group.name
                        ),
                        function(row, index) {
                          return _c(
                            "div",
                            { key: "row-" + index, staticClass: "group-row" },
                            _vm._l(row, function(option) {
                              return _c(
                                "div",
                                {
                                  key: option.id,
                                  staticClass: "form-row__col",
                                  class:
                                    option.largeImage &&
                                    "form-row__col--md-50 form-row__col--large-image"
                                },
                                [
                                  _c("ServiceOptionBox", {
                                    attrs: {
                                      option: option,
                                      multiselect: option.inputType === 2
                                    },
                                    on: {
                                      optionChange: function($event) {
                                        return _vm.onOptionChange(
                                          option,
                                          $event
                                        )
                                      },
                                      subOptionChange: _vm.onSubOptionChange
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        }
                      ),
                      _vm._v(" "),
                      !_vm.expandRowIsExcluded(group.name) &&
                      _vm.groupedOptions(group.name).length > 4
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "link link--primary link--icon wizard--service-booking__show-more-link",
                              on: {
                                click: function($event) {
                                  return _vm.onOpenExpandedRow(group.name)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.expandedRowOpen === group.name
                                      ? "Se färre tillval"
                                      : "Se fler tillval"
                                  ) +
                                  "\n          "
                              ),
                              _c("FontAwesomeIcon", {
                                attrs: {
                                  icon:
                                    _vm.expandedRowOpen === group.name
                                      ? _vm.faAngleUp
                                      : _vm.faAngleDown
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.serviceSelected
          ? _c("label", [_vm._v("\n    Övrigt\n  ")])
          : _vm._e(),
        _vm._v(" "),
        _vm.serviceSelected
          ? _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-row__col" },
                [
                  _c("SectionField", {
                    attrs: { type: _vm.FieldTypes.ServiceMessage }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }