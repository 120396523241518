var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "configurator-app__summary__wrapper" }, [
        _c(
          "div",
          [
            _vm._l(_vm.summation.summationRows, function(row) {
              return _c("ConfigSummationRow", {
                key: row.heading,
                attrs: { row: row }
              })
            }),
            _vm._v(" "),
            _vm.showTotalMonthlyCostSummation
              ? _c("ConfigSummationTotal", {
                  attrs: { total: _vm.summation.totalMonthlyCost }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showTotalPriceSummation
              ? _c("ConfigSummationTotal", {
                  attrs: { total: _vm.summation.totalPrice }
                })
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "section__header js-configurator-actions" }, [
        _vm._v("\n    Vad ser du som nästa steg?\n  ")
      ]),
      _vm._v(" "),
      _vm.isPurchasable
        ? _c("div", [
            _c("div", { staticClass: "tab-bar" }, [
              _c(
                "div",
                {
                  staticClass: "tab-bar__item",
                  class: { "tab-bar__item--active": _vm.activeTab === "order" },
                  on: {
                    click: function($event) {
                      return _vm.onTabClick("order")
                    }
                  }
                },
                [_vm._v("\n        Köpa online\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tab-bar__item",
                  class: {
                    "tab-bar__item--active": _vm.activeTab === "contact"
                  },
                  on: {
                    click: function($event) {
                      return _vm.onTabClick("contact")
                    }
                  }
                },
                [_vm._v("\n        Kontakta oss\n      ")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tabs-content" },
              [
                _vm.activeTab === "order"
                  ? _c("ConfigSummationFooter", {
                      attrs: {
                        requestType: _vm.ContactRequestTypes.Purchase,
                        purchaseInformationText: _vm.purchaseInformationText,
                        lang: _vm.lang,
                        submitState: _vm.submitState
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeTab === "contact"
                  ? _c("ConfigSummationContact", {
                      attrs: {
                        department: _vm.department,
                        departmentOptions: _vm.departmentOptions,
                        lang: _vm.lang,
                        isTransport: _vm.isTransport,
                        submitState: _vm.submitState
                      },
                      on: { change: _vm.onContactChange }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _c("ConfigSummationContact", {
            attrs: {
              department: _vm.department,
              departmentOptions: _vm.departmentOptions,
              lang: _vm.lang,
              isTransport: _vm.isTransport,
              submitState: _vm.submitState
            },
            on: { change: _vm.onContactChange }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }