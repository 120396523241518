import FieldTypes from '../../shared/wizard/constants/FieldTypes';

export default {
  ...FieldTypes,
  Services: 'services',
  Department: 'department',
  DepartmentNr: 'departmentNr',
  Date: 'date',
  Time: 'time',
  Addons: 'addons',
  PromotionCode: 'promotioncode',
}
