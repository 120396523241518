import qs from 'query-string';
import mountApp from "../utils/mount-app";
import DepartmentMainAction from '../apps/department-main-action';
import Swiper from "../swiper";
import Breakpoints from "../constants/Breakpoints";

const selectors = {
  images:
    ".department-images .swiper-container .swiper-wrapper .swiper-slide",
  targetIndex: ".swiper-slide-active",
  imageCounter: ".department-images__counter__text"
};

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    this.mountMainAction();
    this.initImageSwiper();
    this.initLinkSwiper();
    this.setImageCount();
  },

  mountMainAction() {
    mountApp({
      app: DepartmentMainAction,
      selector: '[data-app=department-main-action]',
      extractProps: el => ({
        location: el.getAttribute('data-location'),
        service: el.getAttribute('data-service'),
      }),
    });
  },

  initImageSwiper() {
    this.imageSwiper = new Swiper(".department-images .swiper-container", {
      autoHeight: true,
      preloadImages: false,
      lazy: true,
      navigation: {
        nextEl: ".department-images__nav--next",
        prevEl: ".department-images__nav--prev",
        disabledClass: "department-images__nav--disabled"
      },
      breakpoints: {
        [Breakpoints.md - 2]: {
          slidesPerView: 'auto',
          spaceBetween: 2,
        },
      },
      on: {
        slideChangeTransitionEnd: () => this.setImageCount()
      }
    });
  },
  initLinkSwiper() {
    this.linkSwiper = new Swiper(".department-link-bar .swiper-container", {
      autoHeight: false,
      slidesPerView: 'auto',
      spaceBetween: 8,
      lazy: true,
      enabled: false,
      breakpoints: {
        [Breakpoints.md - 2]: {
          enabled: true,
        },
      },
    });
  },

  setImageCount() {
    const totalAmount = $(selectors.images).length;
    const currentImage =
      $(selectors.images).index($(selectors.targetIndex)) + 1;

    $(selectors.imageCounter).text(`${currentImage} av ${totalAmount}`);
  }
};
