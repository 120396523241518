import EonChannel from "./EonChannel";
import TyreSpec from './TyreSpec';

export default {
  channels: [],

  createChannels(config) {
    this.channels = config.channels.map(channel => new EonChannel(channel, TyreSpec));
  },

  getByRef(ref) {
    return this.channels.find(c => c.ref === ref);
  },
};
