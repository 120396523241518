var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("label", [_vm._v("\n    Återställ lösenord\n  ")]),
      _vm._v(" "),
      _c("VcFormStack", { attrs: { label: "Nytt lösenord", required: "" } }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          staticClass: "form-control",
          attrs: {
            placeholder: "Nytt lösenord",
            required: "",
            type: "password"
          },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("VcFormStack", { attrs: { label: "Nytt lösenord", required: "" } }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.confirmPassword,
              expression: "confirmPassword"
            }
          ],
          staticClass: "form-control",
          attrs: {
            placeholder: "Upprepa lösenord",
            required: "",
            type: "password"
          },
          domProps: { value: _vm.confirmPassword },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.confirmPassword = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn--primary btn--block",
          class: { disabled: _vm.btnDisabled },
          attrs: { type: "submit" }
        },
        [_vm._v("\n    Återställ lösenord\n  ")]
      ),
      _vm._v(" "),
      _vm.restoreSuccess
        ? _c(
            "div",
            {
              staticClass:
                "feedback-message feedback-message--success feedback-message--slim"
            },
            [
              _c("div", { staticClass: "feedback-message__text" }, [
                _vm._v("\n      " + _vm._s(_vm.successMessage) + "\n    ")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.restoreError
        ? _c(
            "div",
            {
              staticClass:
                "feedback-message feedback-message--danger feedback-message--slim"
            },
            [
              _c("div", { staticClass: "feedback-message__text" }, [
                _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }