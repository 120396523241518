var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.option.values, function(value, index) {
      return _c("div", { key: index, staticClass: "toggle-switch-control" }, [
        _c("div", { staticClass: "toggle-switch" }, [
          _c("input", {
            attrs: { type: "checkbox", id: _vm.getInputId(index) },
            domProps: { checked: _vm.checked },
            on: {
              change: function($event) {
                return _vm.$emit("change", $event.target.checked)
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: _vm.getInputId(index) } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "toggle-switch-control__label" }, [
          _vm._v("\n      " + _vm._s(value.name) + "\n    ")
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }