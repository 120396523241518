<template>
  <div class="u-margin-top">
    <div class="row">
      <div class="col-md-12">
        <div class="summary u-padding-top-lg vehicle-configurator">
          <div class="vehicle-configurator__summation">
            <div class="configurator-section">
              <h4 class="configurator-section__title">
                <i class="fal fa-receipt configurator-section__icon"></i>
                Sammanställning
              </h4>
              <div>
                <div class="vehicle-configurator__summation__row">
                  <div class="properties">
                    <div class="properties__item properties__item--header">
                      <div class="properties__label">
                        Fordon
                      </div>
                    </div>
                    <div class="vehicle-line">
                      <div class="properties__item">
                        <div class="properties__item__text-container">
                          <div class="properties__item__label">
                            {{
                              store.agreementResult.vehicle.registrationNumber
                            }}
                          </div>
                          <div class="properties__item__sub-label">
                            {{ store.agreementResult.vehicle.carName }}
                          </div>
                        </div>
                        <div
                          class="properties__value vehicle-configurator__summation__price"
                        >
                          <div class="price-tag">
                            {{
                              $formatNumber(
                                store.agreementResult.currentMileage
                              )
                            }}
                            mil
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vehicle-configurator__summation__row">
                  <div class="properties">
                    <div class="properties__item properties__item--header">
                      <div class="properties__label">
                        Serviceavtal
                      </div>
                    </div>
                    <div class="summation-list">
                      <div class="summation-list__row">
                        <div class="text">Avtalsperiod</div>
                        <div class="text text--value">
                          {{ store.agreementResult.months }} månader
                        </div>
                      </div>
                      <div class="summation-list__row">
                        <div class="text">Miltal</div>
                        <div class="text text--value">
                          {{ store.agreementResult.mileage }} mil/år
                        </div>
                      </div>
                      <div class="summation-list__row">
                        <div class="text">Servicetillfällen</div>
                        <div class="text text--value">
                          {{ store.numberOfSelectedInstances }} st
                        </div>
                      </div>
                      <div
                        class="summation-list__row"
                        v-if="store.hasSelectedAdditionalProducts"
                      >
                        <div class="text">Valda tillägg</div>
                        <div class="text text--value">
                          {{ store.additionalProductsDisplay }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="vehicle-configurator__summation__row vehicle-configurator__summation__row--black-border"
              >
                <div class="total-price">
                  <div class="total-price__row">
                    <div class="total-price__title">
                      TOTAL MÅNADSKOSTNAD
                    </div>
                    <div class="price-tag">
                      {{ $formatPrice(store.agreementResult.monthlyCost) }} /
                      mån
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "./store";
import { VcFormStack } from "@holmgrensbil/vue-components";

export default {
  components: {
    VcFormStack
  },
  data() {
    return {
      store: store.state
    };
  },

  methods: {}
};
</script>
