var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-pages__logged-in" }, [
    _c(
      "div",
      { staticClass: "my-pages__facets-view" },
      _vm._l(_vm.lines, function(item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "my-pages__facet",
            class: {
              "my-pages__facet--selected": _vm.selectedFacets.includes(item.id)
            },
            attrs: { "current-user": _vm.currentUser }
          },
          [
            _c(
              "div",
              {
                staticClass: "my-pages__facet-pane",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.select(item.id)
                  }
                }
              },
              [
                _c("div", { staticClass: "my-pages__facet-name" }, [
                  _vm._v(_vm._s(item.title))
                ]),
                _vm._v(" "),
                item.section === _vm.MY_VEHICLES ||
                item.section === _vm.SAVED_VEHICLES ||
                item.section === _vm.SUBSCRIPTIONS ||
                item.section === _vm.TYRE_ORDERS ||
                item.section === _vm.MY_TYRES
                  ? _c("div", { staticClass: "my-pages__facet-counter" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.getSectionData(item.section).length))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(0, true)
              ]
            ),
            _vm._v(" "),
            _vm.selectedFacets.includes(item.id)
              ? _c(
                  "div",
                  [
                    _c(_vm.getCurrentSection(item.section), {
                      tag: "component",
                      attrs: {
                        "current-user": _vm.currentUser,
                        lang: _vm.lang,
                        sectionData: _vm.getSectionData(item.section),
                        tyreError: _vm.tyreError
                      },
                      on: {
                        signOut: function($event) {
                          return _vm.$emit("signOut")
                        },
                        deleteVehicle: _vm.onDeleteVehicle,
                        deleteMyVehicle: _vm.onDeleteMyVehicle,
                        deleteSubscription: _vm.onDeleteSubscription,
                        clickVehicle: _vm.onClickVehicle,
                        clickSubscription: _vm.onClickSubscription,
                        onChangeLocation: _vm.onChangeLocation
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "my-pages__logout",
        on: {
          click: function($event) {
            return _vm.$emit("signOut")
          }
        }
      },
      [_vm._m(1)]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-pages__facet-icon" }, [
      _c("i", { staticClass: "fal fa-angle-down" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v("\n      Logga ut\n      "),
      _c("i", { staticClass: "fal fa-sign-out-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }