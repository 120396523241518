var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-app__summation-footer" }, [
    _vm.done
      ? _c(
          "div",
          [
            _c("VcFeedbackMessage", { attrs: { success: "" } }, [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Summation.FeedbackHeader) +
                    "\n      "
                )
              ]),
              _vm._v(
                "\n      " + _vm._s(_vm.lang.Summation.FeedbackText) + "\n    "
              )
            ])
          ],
          1
        )
      : _c("div", [
          _vm.isPurchasing
            ? _c("div", {
                staticClass: "configurator-app__summation-footer-info",
                domProps: { innerHTML: _vm._s(_vm.purchaseInformationText) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--primary btn--block",
              class: { disabled: _vm.loading },
              attrs: { type: "submit" }
            },
            [_vm._v("\n      " + _vm._s(_vm.actionLabel) + "\n    ")]
          ),
          _vm._v(" "),
          !_vm.isPurchasing
            ? _c("div", {
                staticClass: "form-text",
                domProps: {
                  innerHTML: _vm._s(_vm.lang.General.PrivacyPolicyText)
                }
              })
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }