export default {
  Textarea: 1,
  Boolean: 2,
  Select: 3,
  SelectObject: 4,
  Number: 5,
  Text: 6,
  Phone: 7,
  Email: 8,
  Image: 9,
  Images: 10,
  RegNr: 11,
  OptionBoxes: 12,
  OptionBoxesMulti: 13,
  Date: 14,
  SelectObjectNumber: 15,
  Time: 16,
  TextAppendButton: 20,
}
