export default {
  RegNr: 'registrationNumber',
  RegDate: 'registrationDate',
  Brand: 'brand',
  Model: 'model',
  Version: 'version',
  ModelYear: 'modelYear',
  EquipmentLevel: 'package',
  Name: 'customerName',
  Email: 'customerEmail',
  Phone: 'customerPhone',
  Newsletter: 'newsletter',
  Time: 'time',
  Date: 'date',
  VehicleName: 'vehicleName',
  VehicleInfoRegNr: 'vehicleInfoRegNr',
  DepartmentName: 'departmentName',
  CustomerIsWaiting: 'customerIsWaiting',
  CampaignCode: 'campaignCode',
  Mileage: 'mileage',
  Amount: 'amount',
}
