<template>
  <div>
    <label>
      Teknisk information
    </label>
    <div class="form-row">
      <div class="form-row__col form-row__col--md-50">
        <SectionField :type="FieldTypes.EquipmentLevel">
        </SectionField>
      </div>
      <div class="form-row__col form-row__col--md-50">
        <SectionField :type="FieldTypes.Mileage">
        </SectionField>
      </div>
    </div>

    <div v-if="displayEquipmentDescription"
      class="field-input-box">
      <SectionField :type="FieldTypes.EquipmentText">
      </SectionField>
    </div>

    <SectionField :type="FieldTypes.City">
    </SectionField>

    <SectionField :type="FieldTypes.DampDamage">
    </SectionField>

    <div v-if="displayDampDamageDescription"
      class="field-input-box">
      <SectionField :type="FieldTypes.DampDamageText">
      </SectionField>
    </div>

    <SectionField :type="FieldTypes.GasTest">
    </SectionField>

    <div v-if="displayGasTestDescription"
      class="field-input-box">
      <SectionField :type="FieldTypes.GasTestText">
      </SectionField>
    </div>

    <SectionField :type="FieldTypes.Damage">
    </SectionField>

    <div v-if="displayDamageDescription"
      class="field-input-box">
      <SectionField :type="FieldTypes.DamageText">
      </SectionField>
      <SectionField :type="FieldTypes.DamageImages">
      </SectionField>
    </div>

    <SectionField :type="FieldTypes.AvailableNow">
    </SectionField>

    <SectionField :type="FieldTypes.ExtraInfo">
    </SectionField>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';

export default {
  components: {
    SectionField,
  },
  data() {
    return {
      FieldTypes,
    };
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
    }),

    displayEquipmentDescription() {
      const value = this.fieldByType(FieldTypes.EquipmentLevel).value;
      const required = this.fieldByType(FieldTypes.EquipmentLevel).required;

      if (!required) {
        return true;
      }

      return value && value.value === 'Annan nivå/paket';
    },

    displayDampDamageDescription() {
      const value = this.fieldByType(FieldTypes.DampDamage).value;
      return value || value === false;
    },

    displayDamageDescription() {
      return this.fieldByType(FieldTypes.Damage).value;
    },

    displayGasTestDescription() {
      return this.fieldByType(FieldTypes.GasTest).value;
    },
  },
}
</script>
