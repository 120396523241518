import CartApi from '../../../../models/CartApi';
import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../../constants/FieldTypes';

const propertyMap = {
  addressRow1: FieldTypes.Address,
  firstName: FieldTypes.FirstName,
  lastName: FieldTypes.LastName,
  postalArea: FieldTypes.City,
  postalCode: FieldTypes.Zip,
};

const state = {
  fetchState: null,
  fields: [],
};

const getters = {
};

const actions = {
  async autofill({ commit }, ssn) {
    commit('fetchRequest');

    try {
      const res = await CartApi.fetchCheckoutAddress(ssn);
      const fields = Object.entries(res.data)
        .filter(([key]) => propertyMap[key])
        .map(([key, value]) => ({
          type: propertyMap[key],
          value,
        }));

      commit('fetchSuccess', fields);
    } catch (e) {
      commit('fetchFailure');
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, fields) {
    state.fields = fields;
    state.fetchState = RequestStates.Success;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
