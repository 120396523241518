<template>
  <div v-if="products.length">
    <div
      ref="grid"
      class="eon-result__items"
      :class="{ 'eon-result__querying': querying }"
    >
      <div v-for="product in products"
        :key="product.id"
        class="eon-result__item">
        <EonProductSpot
          :product="product"
          :regNr="regNr"
          :selectedProductType="selectedProductType"
          :selectedProduct="selectedProduct"
          :isBuildWheel="isBuildWheel">          
        </EonProductSpot>
      </div>
    </div>
    <VcIntersectObserver :options="{rootMargin: '200px'}" @intersect="onIntersect"></VcIntersectObserver>
    <portal-target name="eon-product-services-modal"></portal-target>
  </div>
</template>

<script>
import { VcIntersectObserver } from '@holmgrensbil/vue-components';
import EonProductSpot from '../shared/ecom/EonProductSpot.vue';

export default {
  props: {
    products: {
      type: Array,
      default: () => []
    },
    regNr: String,
    selectedProductType: String,
    querying: Boolean,
    isBuildWheel: Boolean,
    selectedProduct: Object,
  },
  components: {
    EonProductSpot,
    VcIntersectObserver,
  },
  methods: {
    onIntersect() {
      this.$emit('onNext');
    },
  },
}
</script>
