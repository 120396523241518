<template>
  <div class="configurator-section tyre-selector">
    <div class="configurator-section__title-container">
      <h4 class="configurator-section__title">
        <i class="fal fa-tire configurator-section__icon"></i>
        {{ chargeBoxData.heading }}
      </h4>
      <a
        v-if="chargeBoxData.informationModalUrl"
        class="link link--muted-light link--underline"
        href="#"
        :data-target="chargeBoxData.informationModalUrl"
        data-modal-source="ajax"
      >
        {{ lang.SiteGeneral.ReadMore }}
      </a>
    </div>
    <VcOptionBox
      :selected="!doesWantChargingBox"
      :heading="chargeBoxData.denyOption"
      @click="onHideTyreOptions"
    ></VcOptionBox>
    <VcOptionBox
      :selected="doesWantChargingBox"
      :heading="chargeBoxData.acceptOption"
      @click="onShowTyreOptions"
    ></VcOptionBox>
  </div>
</template>

<script>
import ProductUpsell from "./VehicleConfiguratorUpsell.vue";
import { VcOptionBox } from "@holmgrensbil/vue-components";
import store from "./store";

export default {
  components: {
    ProductUpsell,
    VcOptionBox
  },
  props: {
    chargeBoxData: Object,
    lang: Object,
  },
  data() {
    return {
      doesWantChargingBox: false,
    };
  },
  computed: {
    storeState() {
      return store.state;
    },
  },
  methods: {
    onShowTyreOptions() {
      this.doesWantChargingBox = true;
      store.setNeedChargeBox(true);
    },
    onHideTyreOptions() {
      this.doesWantChargingBox = false;
      store.setNeedChargeBox(false);
    }
  },
};
</script>
