var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === _vm.GridItemTypes.ProductSpot
    ? _c("GridItemProductSpot", {
        attrs: {
          product: _vm.product,
          selected: _vm.selected,
          loading: _vm.loading,
          savedVehicles: _vm.savedVehicles
        },
        on: {
          click: function($event) {
            return _vm.$emit("select")
          }
        }
      })
    : _vm.type === _vm.GridItemTypes.Quickbuy
    ? _c("GridItemQuickbuy", {
        attrs: {
          product: _vm.product,
          transitionOnOpen: _vm.transitionOnOpen,
          savedVehicles: _vm.savedVehicles
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }