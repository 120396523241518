var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configurator-app__summary" },
    [
      _c("h3", { staticClass: "section__header" }, [
        _vm._v("\n    Din sammanställning\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "configurator-app__summary__container" }, [
        !_vm.showSummationInModal
          ? _c(
              "div",
              [
                _c("ConfigSummationContent", {
                  attrs: {
                    summation: _vm.summation,
                    lang: _vm.lang,
                    department: _vm.department,
                    departmentOptions: _vm.departmentOptions,
                    isPurchasable: _vm.isPurchasable,
                    isTransport: _vm.isTransport,
                    purchaseInformationText: _vm.purchaseInformationText,
                    submitState: _vm.submitState
                  },
                  on: { submit: _vm.onSubmit }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.showStickyCta
        ? _c("VcStickyCta", {
            attrs: { text: _vm.stickyLabel, fakeLoading: _vm.ctaFakeLoading },
            on: { click: _vm.onStickyClick }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.activeModal
        ? _c(
            "VcModal",
            {
              attrs: { modalBodyClass: "modal-body--yellow-bg" },
              on: {
                close: function($event) {
                  _vm.activeModal = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("\n      Ta kontakt\n    ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c("ConfigSummationContent", {
                    attrs: {
                      summation: _vm.summation,
                      lang: _vm.lang,
                      departmentOptions: _vm.departmentOptions,
                      isPurchasable: _vm.isPurchasable,
                      isTransport: _vm.isTransport,
                      purchaseInformationText: _vm.purchaseInformationText,
                      submitState: _vm.submitState
                    },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }