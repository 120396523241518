<template>
  <div v-if="gridItems.length">
    <div ref="grid" class="campaign-list__items">
      <GridItem v-for="gridItem in gridItems"
        :key="`${gridItem.type}${gridItem.document._id}`"
        :type="gridItem.type"
        :document="gridItem.document">
      </GridItem>
    </div>
    <VcIntersectObserver :options="{rootMargin: '200px'}" @intersect="onIntersect"></VcIntersectObserver>
  </div>
</template>

<script>
import GridItem from './GridItem.vue';
import { VcIntersectObserver } from '@holmgrensbil/vue-components';
import GridItemTypes from './constants/GridItemTypes';

export default {
  props: {
    channel: Object,
    documents: {
      type: Array,
      default: () => []
    },
    queriedAt: {
      type: Date,
    },
  },
  components: {
    GridItem,
    VcIntersectObserver,
  },
  methods: {
    onIntersect() {
      this.$emit('onNext');
    },
  },
  computed: {
    gridItems() {
      let items = this.documents.map((document, index) => ({
        document,
        type: (index + 1) % 4 !== 0 ? GridItemTypes.PromoSmall : GridItemTypes.PromoLarge,
      }));

      return items;
    },
  },
}
</script>
