var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "i3-filter__facet",
      class: { "i3-filter__facet--selected": _vm.selected }
    },
    [
      _c(
        "div",
        {
          staticClass: "i3-filter__facet-name",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("select")
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.facet.displayName) + "\n  ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "i3-filter__facet-icon" }, [
        _vm.iconRight
          ? _c("i", { staticClass: "fal fa-angle-right" })
          : _c("i", { staticClass: "fal fa-angle-down" })
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }