var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-section finance-selector" }, [
    _c(
      "div",
      _vm._l(_vm.financeOptions, function(option) {
        return _c("div", { key: option.id, staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c("ConfiguratorOptionBox", {
                attrs: {
                  selected: _vm.selected.id === option.id,
                  calculationType: option.calculationType,
                  heading: option.name,
                  smallText: option.nameSuffix,
                  financing: option.financing,
                  lang: _vm.lang,
                  modalContent: option.modalContent,
                  ribbonText: option.ribbonText
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("change-finance-option", option)
                  }
                }
              }),
              _vm._v(" "),
              _vm.selected.id === option.id
                ? _c("div", [
                    option.calculationType === _vm.CalculationKeys.Monthly
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-row configurator-section__input-row"
                          },
                          [
                            _c("ConfiguratorSubSelect", {
                              attrs: {
                                options: _vm.downPaymentOptions.values,
                                heading: _vm.downPaymentOptions.heading,
                                defaultValue: parseInt(
                                  option.financing.selectedDownPayment
                                )
                              },
                              on: {
                                change: function(val) {
                                  return _vm.onChangeDownPayment(val, option)
                                }
                              }
                            }),
                            _vm._v(" "),
                            option.financing.periods
                              ? _c("ConfiguratorSubSelect", {
                                  attrs: {
                                    options: _vm.periodOptions(
                                      option.financing.periods
                                    ),
                                    heading: "Avtalsperiod",
                                    defaultValue:
                                      option.financing.periods.find(function(
                                        p
                                      ) {
                                        return p.selected
                                      }).months || null
                                  },
                                  on: {
                                    change: function(val) {
                                      return _vm.onChangePeriod(val, option)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    option.calculationType !== _vm.CalculationKeys.Set
                      ? _c(
                          "div",
                          { staticClass: "configurator-section__summary" },
                          [
                            _c("SummationList", {
                              attrs: {
                                lang: _vm.lang,
                                selectedFinancing: _vm.selected
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }