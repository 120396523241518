import gtm from "../utils/gtm";

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').on('click', '.servicebookingblock .regnr-input-sibling', this.onClick.bind(this));
  },

  async onClick(e) {
    e.preventDefault();

    await gtm.openServiceBookingFromBlock();

    const $el = $(e.currentTarget);
    const $input = $el.parent().find('input');
    const regNr = $input.val();
    const href = $el.attr('href');
    const regNrHref = `${href}?regNr=${regNr}`

    window.location.href = regNrHref;
  },
}
