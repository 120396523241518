<template>
  <div class="service-spot" v-if="!isAdded">
    <div class="service-spot__main">
      <img :src="service.imageUrl" />

      <div>
        <!-- <label>
          {{service.productType}}
        </label> -->
        <strong>
          {{service.name}}
        </strong>
      </div>
      <div>
        <VcPriceTag :price="service.price" showIfZero></VcPriceTag>
      </div>
    </div>

    <div class="service-spot__desc">
      <span v-html="service.description"></span>

      <div v-if="descExpanded" v-html="service.modalContent">
      </div>

      <div v-if="service.modalContent">
        <a class="link link--underline link--muted-light link--icon"
          @click="descExpanded = !descExpanded">
          <span>Läs mer</span>
          <FontAwesomeIcon :icon="descExpanded ? faAngleUp : faAngleDown">
          </FontAwesomeIcon>
        </a>
      </div>
    </div>

    <VcFeedbackMessage v-if="isAdded" success slim>
      <FontAwesomeIcon :icon="faCheck"></FontAwesomeIcon>
      {{service.name}} ligger nu i din varukorg!
    </VcFeedbackMessage>
    <div v-else
      class="btn btn--white btn--icon btn--sm btn--block"
      @click="$emit('add')">
      Lägg till
      <FontAwesomeIcon :icon="faPlus"></FontAwesomeIcon>
    </div>

    <div v-if="service.productMeta" v-html="service.productMeta" class="service-spot__meta">
    </div>
  </div>
</template>

<script>
import { VcFeedbackMessage, VcPriceTag } from '@holmgrensbil/vue-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleDown, faAngleUp, faPlus } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

export default {
  components: {
    VcFeedbackMessage,
    VcPriceTag,
    FontAwesomeIcon,
  },
  props: {
    service: Object,
    isAdded: Boolean,
  },
  data() {
    return {
      descExpanded: false,
      faAngleDown,
      faAngleUp,
      faPlus,
      faCheck,
    };
  },
}
</script>
