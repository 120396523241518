export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document)
      .on(
        "click",
        ".js-share-page-copylink",
        this.copyLinkToClipboard.bind(this)
      )
      .on(
        "click",
        ".js-share-current-url",
        this.replaceWithCurrentUrl.bind(this)
      )
      .on(
        "click",
        ".js-share-this-url",
        this.replaceWithSpecificUrl.bind(this)
      );
  },

  copyLinkToClipboard() {
    const copyText = document.getElementById("input__share-url");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  },

  replaceWithCurrentUrl() {
    const currentUrl = location.href;
    this.replaceShareUrls(currentUrl);
  },

  replaceWithSpecificUrl({ currentTarget }) {
    const $el = $(currentTarget);
    const url = $el.data("currenturl");
    this.replaceShareUrls(url);
  },

  replaceShareUrls(url) {
    $(".js-replace-url-fb").attr(
      "href",
      "https://facebook.com/sharer/sharer.php?u=" + url
    );
    $(".js-replace-url-tw").attr(
      "href",
      "https://twitter.com/intent/tweet/?url=" + url
    );
    $(".js-replace-url-li").attr(
      "href",
      "https://www.linkedin.com/shareArticle?mini=true&url=" + url
    );
    $(".js-replace-url-link").val(url);
  }
};
