<template>
  <form @submit.prevent="onSubmit">
    <div class="configurator-app__summary__wrapper">
      <div>
        <ConfigSummationRow v-for="row in summation.summationRows"
          :key="row.heading"
          :row="row">
        </ConfigSummationRow>
        <ConfigSummationTotal v-if="showTotalMonthlyCostSummation" :total="summation.totalMonthlyCost"></ConfigSummationTotal>
        <ConfigSummationTotal v-if="showTotalPriceSummation" :total="summation.totalPrice"></ConfigSummationTotal>
      </div>
    </div>

    <h3 class="section__header js-configurator-actions">
      Vad ser du som nästa steg?
    </h3>

    <div v-if="isPurchasable">
      <div class="tab-bar">
        <div class="tab-bar__item"
          @click="onTabClick('order')"
          :class="{'tab-bar__item--active': activeTab === 'order'}">
          Köpa online
        </div>
        <div class="tab-bar__item"
          @click="onTabClick('contact')"
          :class="{'tab-bar__item--active': activeTab === 'contact'}">
          Kontakta oss
        </div>
      </div>

      <div class="tabs-content">
        <ConfigSummationFooter
          v-if="activeTab === 'order'"
          :requestType="ContactRequestTypes.Purchase"
          :purchaseInformationText="purchaseInformationText"
          :lang="lang"
          :submitState="submitState">
        </ConfigSummationFooter>
        <ConfigSummationContact
          v-if="activeTab === 'contact'"
          :department="department"
          :departmentOptions="departmentOptions"
          :lang="lang"
          :isTransport="isTransport"
          @change="onContactChange"
          :submitState="submitState">
        </ConfigSummationContact>
      </div>
    </div>
    <ConfigSummationContact
      v-else
      :department="department"
      :departmentOptions="departmentOptions"
      :lang="lang"
      :isTransport="isTransport"
      @change="onContactChange"
      :submitState="submitState">
    </ConfigSummationContact>
  </form>
</template>

<script>
import ConfigSummationContact from './ConfigSummationContact.vue';
import ConfigSummationFooter from './ConfigSummationFooter.vue';
import ConfigSummationRow from './ConfigSummationRow.vue';
import ConfigSummationTotal from './ConfigSummationTotal.vue';
import ContactRequestTypes from '../../../constants/ContactRequestTypes';

export default {
  components: {
    ConfigSummationContact,
    ConfigSummationFooter,
    ConfigSummationRow,
    ConfigSummationTotal,
  },
  props: {
    summation: Object,
    lang: Object,
    department: Object,
    departmentOptions: Array,
    submitState: Number,
    isPurchasable: Boolean,
    isTransport: Boolean,
    purchaseInformationText: String,
  },
  data() {
    return {
      contactData: {requestType: ContactRequestTypes.Purchase},
      activeTab: 'order',
      ContactRequestTypes,
    };
  },
  computed: {
    showTotalPriceSummation() {
      return this.summation.totalPrice && this.summation.totalPrice.price.current > 0;
    },
    showTotalMonthlyCostSummation() {
      return this.summation.totalMonthlyCost && this.summation.totalMonthlyCost.price.current > 0;
    },
  },
  methods: {
    onContactChange(values) {
      this.contactData = values;
    },

    onSubmit() {
      this.$emit('submit', this.contactData);
    },
    onTabClick(tab) {
      this.activeTab = tab;
      if (this.activeTab == 'order') {
        this.contactData = {requestType: ContactRequestTypes.Purchase};
      }
    },
    setActiveTab(e) {
      const tabName = e.detail.tab;
      this.activeTab = tabName;
      if (this.activeTab == 'order') {
        this.contactData = {requestType: ContactRequestTypes.Purchase};
      }
    }
  },
  mounted() {
    window.addEventListener('displayContact', this.setActiveTab)
  },
  destroyed() {
    window.removeEventListener('displayContact', this.setActiveTab, false);
  }
}
</script>
