import Configurator from "../apps/vehicle-configurator";
import CompareVehicles from "../apps/compare-vehicles";
import mountApp from "../utils/mount-app";
import TradeIn from "../apps/trade-in";
import autouncleWidget from "../components/autouncle-widget";
import stickyDiv from "../components/sticky-div";
import actionbar from "../components/sticky-actionbar";
import gtm from "../utils/gtm";
import Cookies from "js-cookie";
import Axios from "../axios";
import searchWatchButton from "../apps/search-watch-button";
import mainAction from "../apps/main-action";
import { EventBus } from "../utils/event-bus";
import { formatNumber } from "../utils/numbers";
import jsonUtil from "../utils/json";
import VehicleContactModal from "../apps/vehicle-contact-modal";
import bottomActionBar from "../apps/bottom-action-bar";

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
    autouncleWidget.init();
    stickyDiv.init();
    actionbar.init();
  },

  addEvents() {
    $("[data-trade-in-modal]").on("show.wdk.modal", this.onTradeInModalShow);
    $("body")
      .on(
        "submit",
        ".js-vehicle-dynamicblock-form",
        this.onSubmitDynamicBlockForm.bind(this)
      )
      .on("click", "a[data-tab]", this.setTab.bind(this))
      .on(
        "click",
        ".js-more-like-this .product-spot",
        this.trackSimilarVehiclesClick.bind(this)
      );

    $('.bottom-action-bar-contact').on('click', this.openVehiclePageContactForm.bind(this))

    EventBus.$on("configurator-update", this.updatePrices.bind(this));
  },

  modifyDOM() {
    this.mountConfigurator();
    this.mountVehicleContactModal();
    this.mountCompareVehicles();
    this.mountSearchWatchButton();
    this.mountMainAction();
    this.mountBottomActionBar();
  },

  updatePrices(configuratorData) {
    let financingGroup = jsonUtil(
      configuratorData,
      "parameterName",
      "financing"
    )[0];
    let selectedFinancingOption = financingGroup.carConfigurationGroupOptions.filter(
      function(option) {
        return option.selected == true;
      }
    )[0];

    let taxOption = jsonUtil(configuratorData, "name", "taxFree")[0];
    let noTax = taxOption && taxOption.values[0].selected === true;

    switch (selectedFinancingOption.type) {
    case "cash": {
      let installmentOption = jsonUtil(
        configuratorData,
        "type",
        "installment"
      )[0];

      let leasingOption = jsonUtil(configuratorData, "type", "leasing")[0];

      if (noTax) {
        $(".product-main-action__pricing-meta").html("Exkl. moms");
      } else {
        $(".product-main-action__pricing-meta").html("Inkl. moms");
      }

      if (leasingOption) {
        $(".js-product-main-action__subpricing").html(
          leasingOption.heading +
              ' <span class="product-main-action__pricing__financing__highlight">' +
              formatNumber(leasingOption.price.current) +
              "</span>" +
              leasingOption.price.suffix
        );
      }

      if (installmentOption) {
        $(".js-product-main-action__subpricing").html(
          installmentOption.heading +
              ' <span class="product-main-action__pricing__financing__highlight">' +
              formatNumber(installmentOption.price.current) +
              "</span>" +
              installmentOption.price.suffix
        );
      }

      break;
    }

    case "installment":
    case "leasing": {
      if (selectedFinancingOption.type === "leasing") {
        $(".product-main-action__pricing-meta").html("Exkl. moms");
      } else {
        $(".product-main-action__pricing-meta").html("Inkl. moms");
      }

      let cashOption = jsonUtil(configuratorData, "type", "cash")[0];

      let cashPrice = formatNumber(cashOption.price.current);

      if (selectedFinancingOption.type === "leasing" && !noTax) {
        cashPrice = formatNumber(cashOption.price.current * 0.8);
      }

      $(".js-product-main-action__subpricing").html(
        `${cashOption.heading} <span class="product-main-action__pricing__financing__highlight">${cashPrice}</span>${cashOption.price.suffix}`
      );
      break;
    }
    }
    $(".product-main-action__pricing__current-price").html(
      formatNumber(selectedFinancingOption.price.current) +
        selectedFinancingOption.price.suffix
    );
    $(".product-main-action__pricing__obsolete-price").html(
      formatNumber(selectedFinancingOption.price.ordinary) +
        selectedFinancingOption.price.suffix
    );
  },

  onSubmitDynamicBlockForm({ currentTarget }) {
    const $el = $(currentTarget);
    const $submitBtn = $el.find('[type="submit"]');
    const $vehicleData = $(".vehicle-page__main");
    const $feedback = $el.find(".form-feedback");
    const data = {
      currentPageUrl: window.location.toString(),
      customerName: $el.find('[name="form-name"]').val(),
      customerEmail: $el.find('[name="form-email"]').val(),
      registrationNumber: $vehicleData.data("registrationnumber"),
      departmentNr: $vehicleData.data("departmentnr"),
      requestType: $el.data("requesttype"),
      responseType: 2
    };

    if ($el.find('[name="form-date"]').length) {
      data.requestDate = $el.find('[name="form-date"]').val();
    }

    $submitBtn.addClass("disabled");

    Axios.post("/api/leads/ContactForm", data)
      .then(() => {
        $submitBtn.removeClass("disabled");
        $feedback.addClass("active");
      })
      .catch(() => {
        $submitBtn.removeClass("disabled");
      });

    gtm.contactFormSubmit({
      departmentNr: data.departmentNr,
      requestType: data.requestType
    });

    return false;
  },

  onTradeInModalShow() {
    mountApp({
      app: TradeIn,
      selector: "[data-trade-in-modal] [data-app=trade-in]",
      props: {
        pageName: window.document.title,
        regNr: $(".js-trade-in-modal-regnr").val()
      },
      extractProps: el => ({
        sourceRegNr: el.getAttribute("data-source-regnr"),
        departmentEmail: el.getAttribute("data-department-email"),
        departmentName: el.getAttribute("data-department-name"),
        tradeInType: el.getAttribute("data-tradein-type")
      })
    });

    gtm.openTradeInFromVehiclePage();
  },

  mountConfigurator() {
    mountApp({
      app: Configurator,
      selector: "[data-app=configurator]",
      extractProps: el => ({
        regNr: el.getAttribute("data-regnr"),
        carStatus: el.getAttribute("data-car-status")
      })
    });
  },

  mountVehicleContactModal() {
    mountApp({
      app: VehicleContactModal,
      selector: "[data-app=vehicle-contact-modal]",
    });
  },

  mountCompareVehicles() {
    mountApp({
      app: CompareVehicles,
      selector: "[data-app=compare-vehicles]"
      // extractProps: el => ({
      //   regNr: el.getAttribute("data-regnr"),
      //   carStatus: el.getAttribute("data-car-status")
      // })
    });
  },

  mountSearchWatchButton() {
    mountApp({
      app: searchWatchButton,
      selector: "[data-app=search-watch-button]",
      extractProps: el => ({
        searchQuery: el.getAttribute("data-search-query"),
        tags: el.getAttribute("data-tags")
      })
    });
  },

  mountMainAction() {
    mountApp({
      app: mainAction,
      selector: "[data-app=main-action]",
      extractProps: el => ({
        carUrl: el.getAttribute("data-car-url"),
        deviceType: el.getAttribute("data-device-type"),
        position: el.getAttribute("data-position"),
        searchQuery: el.getAttribute("data-search-query"),
        tags: el.getAttribute("data-tags"),
        regNr: el.getAttribute("data-regnr"),
        departments: JSON.parse(el.getAttribute("data-departments")),
      })
    });
  },
  mountBottomActionBar() {
    mountApp({
      app: bottomActionBar,
      selector: "[data-app=bottom-action-bar]",
    });
  },

  setTab({ currentTarget }) {
    const $target = $(currentTarget);
    const tabName = $target.data("tab");
    var event = new CustomEvent("displayContact", {
      detail: {
        tab: tabName
      }
    });
    window.dispatchEvent(event);
  },
  trackSimilarVehiclesClick({ currentTarget }) {
    const $target = $(currentTarget);
    const departmentNr = $target.data("departmentnr");
    const brandName = $target.data("brand");
    const vehicleCondition = $target.data("condition");
    const vehicleType = $target.data("vehicletype");

    var vehicleData = {
      departmentNr,
      vehicleCondition,
      brandName,
      vehicleType
    };
    gtm.vehicleSimilarVehicleClick(vehicleData);
    return true;
  },
  openVehiclePageContactForm() {
    EventBus.$emit('open-vehicle-page-contact-form');
  }
};
