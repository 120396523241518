var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "product-spot",
      class: {
        "product-spot--active": _vm.selected,
        "product-spot--loading": _vm.loading,
        "product-spot--interactive": _vm.interactive,
        "product-spot--card": _vm.card
      },
      attrs: {
        href: _vm.product.permalink,
        "data-brand": _vm.product.brandName,
        "data-condition": _vm.product.vehicleCondition,
        "data-vehicletype": _vm.product.vehicleType,
        "data-departmentnr": _vm.product.departmentNr
      },
      on: {
        click: _vm.clickVehicle,
        mouseover: function($event) {
          _vm.isHovering = true
        },
        mouseleave: function($event) {
          _vm.isHovering = false
        }
      }
    },
    [
      _c("div", { staticClass: "product-spot__tags" }, [
        _vm.product.blackWeek
          ? _c("div", { staticClass: "product-tag black" }, [
              _vm._v("\n      Black Week\n    ")
            ])
          : _vm.product.newYearBoost
          ? _c("div", { staticClass: "product-tag black" }, [
              _vm._v("\n      NyårsBOOST\n    ")
            ])
          : _vm.product.trissIncluded
          ? _c("div", { staticClass: "product-tag yellow" }, [
              _vm._v("\n      100 Triss ingår\n    ")
            ])
          : _vm.product.outlet
          ? _c("div", { staticClass: "product-tag red" }, [
              _vm._v("\n      Outlet\n    ")
            ])
          : _vm.product.begWeeks
          ? _c("div", { staticClass: "product-tag blueblue" }, [
              _vm._v("\n      Begagnatveckor\n    ")
            ])
          : _vm.product.discount > 0
          ? _c(
              "div",
              { staticClass: "product-tag red" },
              [
                _c("FontAwesomeIcon", { attrs: { icon: _vm.fasTag } }),
                _vm._v("\n      Prissänkt\n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.product.isPremium
          ? _c(
              "div",
              { staticClass: "product-tag black" },
              [
                _c("FontAwesomeIcon", { attrs: { icon: _vm.falStar } }),
                _vm._v("\n      Premium\n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.product.campaignTagPrivate && _vm.isPrivateStore
          ? _c("div", { staticClass: "product-tag red" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.product.campaignTagPrivate) + "\n    "
              )
            ])
          : _vm.product.campaignTagCompany && !_vm.isPrivateStore
          ? _c("div", { staticClass: "product-tag red" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.product.campaignTagCompany) + "\n    "
              )
            ])
          : _vm.product.campaignTag === "Releasing"
          ? _c("div", { staticClass: "product-tag green" }, [
              _vm._v("\n      " + _vm._s(_vm.product.campaignTag) + "\n    ")
            ])
          : _vm.product.campaignTag
          ? _c("div", { staticClass: "product-tag red" }, [
              _vm._v("\n      " + _vm._s(_vm.product.campaignTag) + "\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "product-spot__images-holder" },
        [
          _c("div", { staticClass: "product-spot__images__actions" }, [
            _c(
              "div",
              {
                staticClass: "product-spot__bookmark",
                class: { "product-spot__bookmark--marked": _vm.isBookmarked }
              },
              [
                _c("FontAwesomeIcon", {
                  staticClass: "product-spot__bookmark__icon",
                  class: {
                    "product-spot__bookmark__icon--marked": _vm.isBookmarked,
                    ttjs: !_vm.isLoggedIn || _vm.isBookmarked
                  },
                  attrs: {
                    "data-ttjs-title": !_vm.isLoggedIn
                      ? "Logga in för att kunna spara fordon"
                      : "Tillagd i dina sparade fordon. Under Mina sidor kan du se och ta bort sparade fordon",
                    icon: _vm.isBookmarked ? _vm.fasHeart : _vm.falHeart
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.onSaveVehicle($event)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "product-spot__bookmark",
                class: {
                  "product-spot__bookmark--marked": _vm.isVehicleCompared
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.compareVehicle($event)
                  }
                }
              },
              [
                _c("FontAwesomeIcon", {
                  staticClass: "product-spot__bookmark__icon",
                  class: {
                    "product-spot__bookmark__icon--marked":
                      _vm.isVehicleCompared
                  },
                  attrs: {
                    icon: _vm.isVehicleCompared
                      ? _vm.fasBalance
                      : _vm.falBalance
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          (_vm.productImages && _vm.productImages.length === 1) ||
          !_vm.product.available ||
          _vm.windowWidth > 768
            ? _c("ProductImage", {
                attrs: {
                  image: _vm.productImages[0],
                  overlay: !_vm.product.available || _vm.product.noImage,
                  overlayLabel: _vm.product.availableLabel
                }
              })
            : _vm.productImages.length > 1
            ? _c("ProductImageSlider", {
                attrs: { images: _vm.productImages, product: _vm.product }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "product-spot__content" }, [
        _c("div", { staticClass: "product-spot__location" }, [
          _c("i", { staticClass: "fas fa-map-marker-alt" }),
          _vm._v(" " + _vm._s(_vm.product.locationName) + "\n    ")
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "product-spot__title" }, [
          _vm._v("\n      " + _vm._s(_vm.product.brandModelTitle) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-spot__description" }, [
          _vm._v("\n      " + _vm._s(_vm.product.shortModelText) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "product-spot__info" }, [
          _c("div", [
            _c("span", { staticClass: "meta-tag black rounded" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.product.vehicleCondition) +
                  "\n        "
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product-spot__info--item" }, [
            _vm._v("\n        " + _vm._s(_vm.product.modelYear) + "\n      ")
          ]),
          _vm._v(" "),
          _vm.product.vehicleType !== "Husvagn"
            ? _c("div", { staticClass: "product-spot__info--item" }, [
                _c("span", [_vm._v("•")]),
                _vm._v(" " + _vm._s(_vm.product.mileage) + " mil\n      ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.fuelName && _vm.product.vehicleType !== "Husvagn"
            ? _c("div", { staticClass: "product-spot__info--item" }, [
                _c("span", [_vm._v("•")]),
                _vm._v(
                  " " +
                    _vm._s(_vm.doReplaceForDisplay(_vm.product.fuelName)) +
                    "\n      "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.product.available
          ? _c("div", { staticClass: "product-spot__price" }, [
              _c("div", { staticClass: "product-spot__price-item" }, [
                _c("div", { staticClass: "product-spot__price-item__name" }, [
                  _vm._v("\n          Pris\n        ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product-spot__price-item__meta" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.priceMode != "noTax" ? "Inkl. moms" : "Exkl. moms"
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "price-tag",
                    class: { "price-tag--discount": _vm.product.discount }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$formatPrice(
                            _vm.priceMode != "noTax"
                              ? _vm.product.currentPrice
                              : _vm.product.currentPriceNoVat
                          )
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              !_vm.displayOnlyCash
                ? _c("div", { staticClass: "product-spot__price-item" }, [
                    _vm.product.flexCode == "mgflex"
                      ? _c(
                          "div",
                          { staticClass: "product-spot__price-item__name" },
                          [_vm._v("\n          MG100\n        ")]
                        )
                      : _c(
                          "div",
                          { staticClass: "product-spot__price-item__name" },
                          [_vm._v("\n          Finansiering\n        ")]
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__meta" },
                      [_vm._v("\n          Inkl. moms\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "price-tag",
                        class: { "price-tag--discount": _vm.product.discount }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$formatPriceNoSuffix(_vm.product.monthlyCost)
                            ) +
                            " kr/mån\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.displayOnlyCash && _vm.product.privateLeasingPrice > 0
                ? _c("div", { staticClass: "product-spot__price-item" }, [
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__name" },
                      [_vm._v("\n          Privatleasing\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__meta" },
                      [_vm._v("\n          Inkl. moms\n        ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "price-tag" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$formatPriceNoSuffix(
                              _vm.product.privateLeasingPrice
                            )
                          ) +
                          " kr/mån\n        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.displayOnlyCash && this.product.tcoMonthlyCost > 0
                ? _c("div", { staticClass: "product-spot__price-item" }, [
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__name" },
                      [_vm._v("\n          Operationell leasing\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__meta" },
                      [_vm._v("\n          TCO Exkl. moms\n        ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "price-tag" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$formatPriceNoSuffix(_vm.product.tcoMonthlyCost)
                          ) +
                          " kr/mån\n        "
                      )
                    ])
                  ])
                : !_vm.displayOnlyCash && this.product.blMonthlyCost > 0
                ? _c("div", { staticClass: "product-spot__price-item" }, [
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__name" },
                      [_vm._v("\n          Business lease\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__meta" },
                      [_vm._v("\n          Exkl. moms\n        ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "price-tag" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$formatPriceNoSuffix(_vm.product.blMonthlyCost)
                          ) +
                          " kr/mån\n        "
                      )
                    ])
                  ])
                : !_vm.displayOnlyCash && this.product.taxCode === 1
                ? _c("div", { staticClass: "product-spot__price-item" }, [
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__name" },
                      [_vm._v("\n          Företagsleasing\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-spot__price-item__meta" },
                      [_vm._v("\n          Exkl. moms\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "price-tag",
                        class: { "price-tag--discount": _vm.product.discount }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$formatPriceNoSuffix(_vm.product.leasing)
                            ) +
                            " kr/mån\n        "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }