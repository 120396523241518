var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.error
      ? _c(
          "div",
          [
            _c("VcFeedbackMessage", { attrs: { danger: "" } }, [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.lang.App.SubmitErrorHeading
                        ? _vm.lang.App.SubmitErrorHeading
                        : "Ett fel uppstod."
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.lang.App.SubmitErrorText
                      ? _vm.lang.App.SubmitErrorText
                      : "Vänligen kontakta vår kundtjänst eller försök igen."
                  ) +
                  "\n    "
              )
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.done
      ? _c(
          "div",
          [
            _c("VcFeedbackMessage", { attrs: { success: "" } }, [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.alternateTranslationKeys
                        ? _vm.getAlternateFeedbackMessage().header
                        : _vm.lang.App.SubmitFeedbackHeader
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.alternateTranslationKeys
                      ? _vm.getAlternateFeedbackMessage().text
                      : _vm.lang.App.SubmitFeedbackText
                  ) +
                  "\n    "
              )
            ])
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "wizard__submit" },
          [
            _vm._t("default"),
            _vm._v(" "),
            _vm.requireTerms
              ? _c("div", { staticClass: "form-group form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.agreedTerms,
                        expression: "agreedTerms"
                      }
                    ],
                    staticClass: "form-check__input",
                    attrs: { id: "agreedTerms", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.agreedTerms)
                        ? _vm._i(_vm.agreedTerms, null) > -1
                        : _vm.agreedTerms
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.agreedTerms,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.agreedTerms = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.agreedTerms = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.agreedTerms = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "form-check__label",
                    domProps: { innerHTML: _vm._s(_vm.lang.General.TermsText) },
                    on: {
                      click: function($event) {
                        _vm.agreedTerms = !_vm.agreedTerms
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.displayAgreementError
              ? _c("VcFeedbackMessage", { attrs: { info: "" } }, [
                  _vm._v(
                    "\n      Du måste godkänna villkoren för att skicka bokningen/förfrågan.\n    "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn btn--primary btn--lg btn--block",
                class: { disabled: _vm.btnDisabled },
                on: { click: _vm.onSubmit }
              },
              [_vm._v("\n      " + _vm._s(_vm.btnText) + "\n    ")]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "form-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.lang.General && _vm.lang.General.PrivacyPolicyText
                )
              }
            })
          ],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }