var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "properties" },
    [
      _c("div", { staticClass: "properties__item properties__item--header" }, [
        _c("div", { staticClass: "properties__label" }, [
          _vm._v("\n      " + _vm._s(_vm.row.heading) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.displayLines, function(line) {
        return _c(
          "div",
          { key: line.name },
          [
            _vm.row.heading === "Fordon"
              ? _c(
                  "div",
                  { staticClass: "vehicle-line" },
                  [_c("ConfigSummationLineVehicle", { attrs: { line: line } })],
                  1
                )
              : _c("ConfigSummationLine", { attrs: { line: line } })
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.collapsible
        ? _c(
            "div",
            {
              staticClass: "link link--muted-light link--underline link--icon",
              on: { click: _vm.onToggleCollapse }
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.collapsed,
                      expression: "collapsed"
                    }
                  ]
                },
                [_vm._v("Visa alla detaljer")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.collapsed,
                      expression: "!collapsed"
                    }
                  ]
                },
                [_vm._v("Dölj alla detaljer")]
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.collapsed,
                      expression: "collapsed"
                    }
                  ]
                },
                [_c("i", { staticClass: "fal fa-angle-down" })]
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.collapsed,
                      expression: "!collapsed"
                    }
                  ]
                },
                [_c("i", { staticClass: "fal fa-angle-up" })]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }