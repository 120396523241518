import Cookies from 'js-cookie';

const COOKIE_NAME = 'siteHeaderCartBadgeValue-';

export default {
  init() {
    this.$badge = $('.site-header__action-item--cart .badge');
    if($('body').hasClass('ecom-thankyou')) {
      this.resetOnPurchase();
    }
    this.setCount(this.getStoredValue());
  },

  setCount(value) {
    if (!value) {
      this.$badge.html('');
      this.$badge.removeClass('active');
    } else {
      this.$badge.html(value);
      this.$badge.addClass('active');
    }

    this.setStoredValue(value);
  },

  getStoredValue() {
    const storeKeyCookie = COOKIE_NAME + window.ecomConfig.storeKey;
    const value = Cookies.get(storeKeyCookie);

    if (value) {
      return parseInt(value, 10);
    }
  },

  setStoredValue(value) {
    const storeKeyCookie = COOKIE_NAME + window.ecomConfig.storeKey;
    Cookies.set(storeKeyCookie, value, {
      expires: 365,
    });
  },

  resetOnPurchase() {
    const storeKeyCookie = COOKIE_NAME + window.ecomConfig.storeKey;
    Cookies.remove(storeKeyCookie);
  },
}
