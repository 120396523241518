<template>
  <div v-if="loading" class="u-align-center">
    <FontAwesomeIcon
      :icon="faSpinnerThird"
      :spin="true"
      class="wizard--service-booking__loader"
    >
    </FontAwesomeIcon>
  </div>
  <SectionField v-else :type="FieldTypes.ServiceTypes" @change="onChange">
  </SectionField>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FieldTypes from "./constants/FieldTypes";
import SectionField from "../shared/wizard/SectionField.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    SectionField,
    FontAwesomeIcon
  },
  data() {
    return {
      FieldTypes,
      faSpinnerThird
    };
  },
  computed: {
    ...mapState({
      serviceTypesRequestState: state => state.serviceTypes.fetchState,
      sections: state => state.sections.items
    }),
    loading() {
      return this.serviceTypesRequestState === 1;
    }
  },
  methods: {
    ...mapActions({
      setField: "fields/set"
    }),

    onChange(input) {
      const serviceValues =
        input.value.forEach(value =>
          value.value.forEach(subOption => {
            this.setField({ type: subOption.name, value: subOption.value });
          })
        ) || [];
    }
  }
};
</script>
