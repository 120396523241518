<template>
  <VcFormStack :label="field.label" :required="field.required">
    <VcDatePicker
      :value="field.value"
      :disabledDates="field.options"
      :placeholder="field.placeholder"
      :disableWeekends="false"
      @change="$emit('change', $event.target.value)"
    >
      <i class="fal fa-calendar-alt" slot="icon"></i>
    </VcDatePicker>
  </VcFormStack>
</template>

<script>
import { VcFormStack, VcDatePicker } from "@holmgrensbil/vue-components";

export default {
  components: {
    VcFormStack,
    VcDatePicker
  },
  props: {
    field: Object,
    invalid: Boolean
  }
};
</script>
