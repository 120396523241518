var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-pages__section my-tyres" }, [
    _vm.sectionData.length && _vm.selectedLocation
      ? _c(
          "div",
          _vm._l(_vm.sectionData, function(vehicle) {
            return _c(
              "div",
              { key: vehicle.vehicleData.id, staticClass: "saved-vehicles" },
              [
                _c("div", { staticClass: "product-info" }, [
                  _c("span", { staticClass: "product-info__title" }, [
                    _vm._v(_vm._s(vehicle.vehicleData.brandName))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "product-info__subtitle" }, [
                    _vm._v(
                      _vm._s(vehicle.vehicleData.modelName) +
                        " " +
                        _vm._s(vehicle.vehicleData.trimPackage)
                    )
                  ])
                ]),
                _vm._v(" "),
                vehicle.tyreData.data.tyres.summer
                  ? _c(
                      "div",
                      {
                        staticClass: "product-info product-info--sub-container"
                      },
                      [
                        _c("div", [
                          _c("div", [
                            _c("span", { staticClass: "product-info__title" }, [
                              _vm._v(_vm._s("Sommardäck"))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "product-info__tyre-header" },
                            [
                              _c("div", {
                                staticClass: "section-item__status-icon ",
                                class: {
                                  "section-item__status-icon--yellow":
                                    vehicle.tyreData.data.in_stock === "summer",
                                  "section-item__status-icon--green":
                                    vehicle.tyreData.data.in_stock !== "summer"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "product-info__subtitle" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        vehicle.tyreData.data.in_stock ===
                                          "summer"
                                          ? "På däckhotell"
                                          : "På bil"
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            _vm._l(vehicle.tyreData.data.tyres.summer, function(
                              tyre
                            ) {
                              return _c("TyreRow", {
                                key: tyre.id,
                                attrs: { tyre: tyre }
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "product-info__sub-action" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--muted btn--block",
                                  on: {
                                    click: function($event) {
                                      return _vm.goToPurchase(
                                        vehicle.vehicleData,
                                        _vm.EonTyreTypes.Summer
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s("Köp nya sommardäck") +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                vehicle.tyreData.data.tyres.winter
                  ? _c(
                      "div",
                      {
                        staticClass: "product-info product-info--sub-container"
                      },
                      [
                        _c("div", [
                          _c("div", [
                            _c("span", { staticClass: "product-info__title" }, [
                              _vm._v(_vm._s("Vinterdäck"))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "product-info__tyre-header" },
                            [
                              _c("div", {
                                staticClass: "section-item__status-icon ",
                                class: {
                                  "section-item__status-icon--yellow":
                                    vehicle.tyreData.data.in_stock === "winter",
                                  "section-item__status-icon--green":
                                    vehicle.tyreData.data.in_stock !== "winter"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "product-info__subtitle" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        vehicle.tyreData.data.in_stock ===
                                          "winter"
                                          ? "På däckhotell"
                                          : "På bil"
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            _vm._l(vehicle.tyreData.data.tyres.winter, function(
                              tyre
                            ) {
                              return _c("TyreRow", {
                                key: tyre.id,
                                attrs: { tyre: tyre }
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "product-info__sub-action" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--muted btn--block",
                                  on: {
                                    click: function($event) {
                                      return _vm.goToPurchase(
                                        vehicle.vehicleData,
                                        _vm.EonTyreTypes.WinterFriction
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s("Köp nya vinterdäck") +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product-info product-info--sub-container" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--primary btn--block",
                        on: {
                          click: function() {
                            return _vm.goToService(vehicle.vehicleData)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s("Boka hjulskifte") +
                            "\n        "
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          }),
          0
        )
      : _c("div", { staticClass: "my-pages__section__tyre-empty" }, [
          _c("div", { staticClass: "my-pages__section__text-container" }, [
            _c("span", { staticClass: "my-pages__section__text" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.tyreError
                      ? "Inga däckhotellsavtal hittades för dina fordon på vald ort"
                      : "För att se din information om eventuellt däckhotell, lägg till ditt fordon under Mina fordon och välj den ort som du har dina däck."
                  ) +
                  "\n      "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.locations.length
            ? _c("div", [
                _c("div", { staticClass: "form-stack" }, [
                  _c("label", [_vm._v("\n        Din ort\n        ")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-select" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedLocation,
                            expression: "selectedLocation"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedLocation = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("\n              Välj ort\n            ")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.locations, function(location) {
                          return _c(
                            "option",
                            {
                              key: location.value,
                              domProps: { value: location.value }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(location.name) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--primary btn--block",
                    on: { click: _vm.onLocationChange }
                  },
                  [_vm._v("\n        " + _vm._s("Spara") + "\n      ")]
                )
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }