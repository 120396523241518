var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "comparison-desktop-table" },
      [
        _c(
          "tr",
          {
            staticClass:
              "comparison-desktop-table__row comparison-desktop-table__row--header"
          },
          [
            _c("td", {
              staticClass:
                "comparison-desktop-table__cell comparison-desktop-table__cell--header comparison-desktop-table__cell--label"
            }),
            _vm._v(" "),
            _vm._l(_vm.vehicles, function(vehicle) {
              return _c(
                "td",
                {
                  key: vehicle._id,
                  staticClass:
                    "comparison-desktop-table__cell comparison-desktop-table__cell--header"
                },
                [
                  _c(
                    "div",
                    { staticClass: "comparison-desktop-table__header-image" },
                    [
                      _c("a", { attrs: { href: vehicle.carUrl } }, [
                        _c("img", {
                          attrs: {
                            src:
                              vehicle.mainImage +
                              "?" +
                              _vm.ImageResizerPresets.ProductSpot +
                              "&404=" +
                              vehicle.fallbackimage
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "delete-btn",
                          on: {
                            click: function($event) {
                              return _vm.$emit(
                                "delete-vehicle",
                                vehicle.registrationnumber
                              )
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-trash" })]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "comparison-desktop-table__header-content" },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "comparison-desktop-table__header-content__title",
                          attrs: { href: vehicle.carUrl }
                        },
                        [
                          _vm._v(
                            _vm._s(vehicle.brandname + " " + vehicle.modelname)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "comparison-desktop-table__header-content__sub-title"
                        },
                        [_vm._v(_vm._s(vehicle.modeltext_nobrand))]
                      )
                    ]
                  )
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm._l(_vm.rows, function(row, index) {
          return _c(
            "tr",
            {
              key: row.label + "-" + _vm.uuidv4(),
              staticClass: "comparison-desktop-table__row",
              class: {
                "white-bg": index % 2 === 1
              }
            },
            [
              _c(
                "td",
                {
                  staticClass:
                    "comparison-desktop-table__cell comparison-desktop-table__cell--label",
                  class: {
                    "white-bg": index % 2 === 1
                  }
                },
                [_vm._v("\n        " + _vm._s(row.label) + "\n      ")]
              ),
              _vm._v(" "),
              _vm._l(row.vehicleCells, function(cell) {
                return _c(
                  "td",
                  {
                    key: cell + "-" + _vm.uuidv4(),
                    staticClass: "comparison-desktop-table__cell"
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(cell.primaryValue) +
                          "\n          "
                      ),
                      cell.secondaryValue
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "comparison-desktop-table__cell__secondary-value"
                            },
                            [_vm._v(_vm._s(cell.secondaryValue))]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              })
            ],
            2
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }