<template>
  <component
    :is="RenderComponent"
    :channel="channel"
    :queriedAt="queriedAt"
    :lang="lang"
    @onNext="onNext"
  >
  </component>
</template>

<script>
import ChannelEvents from "../../constants/ChannelEvents";
import Axios from "../../axios";
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
    lang: Object,
    RenderComponent: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      queriedAt: null,
      queryingNext: false
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.channel.on(ChannelEvents.SendSearchSignal, this.sendSearchSignal);
    if (this.channel.executedQueries.length) {
      this.onQuerySuccess();
    }
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  methods: {
    onQuerySuccess() {
      this.queriedAt = new Date();
    },

    sendSearchSignal() {
      if (
        this.channel.sendStatistics &&
        this.channel.data?.searchSignal?.searchSignalAction &&
        this.channel.lastQuery?.shouldSendSearchSignal &&
        window.I3Config.searchRestBaseUrl
      ) {
        const { searchSignalAction } = this.channel.data.searchSignal;
        try {
          Axios.post(
            window.I3Config.searchRestBaseUrl + searchSignalAction.url,
            searchSignalAction.payload
          );
        } catch (e) {
          console.log(e);
        }
      }
    },

    async onNext() {
      if (this.queryingNext || !this.queriedAt || !this.channel.hasNextPage) {
        return;
      }

      this.queryingNext = true;
      await this.channel.queryNextPage();

      this.queryingNext = false;
    }
  }
};
</script>
