import FinanceOptions from "../constants/FinanceOptions";

export const getPriceMetaString = (displayTax, lang, selectedFinancingOption) => {
  let priceMetaLabel = '';
  switch (selectedFinancingOption) {
  case FinanceOptions.CASH: priceMetaLabel = `${lang.Financing.Cash}`; break;
  case FinanceOptions.LEASING: priceMetaLabel = `${lang.Financing.Leasing}`; break;
  case FinanceOptions.INSTALLMENT: priceMetaLabel = `${lang.Financing.Installment}`; break;
  case FinanceOptions.PRIVATELEASING: priceMetaLabel = `${lang.Financing.PrivateLeasing}`; break;
  default: priceMetaLabel = `${lang.Financing.Cash}`;
  }
  return displayTax
    ? `${priceMetaLabel} ${lang.Financing.WithTaxMetaSimple}`
    : `${priceMetaLabel} ${lang.Financing.WithoutTaxMetaSimple}`
}