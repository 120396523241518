var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.payments, function(payment) {
        return _c(
          "VcOptionBox",
          {
            key: payment.id,
            attrs: {
              selected:
                payment.id === _vm.cartSelectedPayment ||
                (!_vm.cartSelectedPayment && payment.selected),
              heading: payment.name
            },
            on: {
              click: function($event) {
                return _vm.onClick(payment)
              }
            }
          },
          [
            payment.modalContent
              ? _c(
                  "div",
                  { attrs: { slot: "header-meta" }, slot: "header-meta" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "link link--underline link--muted",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.onModalClick(payment)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.lang.Checkout.Payment.ReadMore) +
                            "\n      "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "checkout__payment-additional-details",
                attrs: { slot: "header-aside" },
                slot: "header-aside"
              },
              [
                payment.images && payment.images.length
                  ? _c(
                      "div",
                      _vm._l(payment.images, function(image) {
                        return _c("img", { key: image, attrs: { src: image } })
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "fee-text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$formatPrice(payment.price)) +
                      "\n      "
                  )
                ])
              ]
            ),
            _vm._v(" "),
            payment.id === _vm.cartSelectedPayment && _vm.paymentInputs.length
              ? _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _c(
                    "div",
                    { staticClass: "form-row" },
                    _vm._l(_vm.paymentInputs, function(input) {
                      return _c(
                        "div",
                        {
                          key: input.parameterName,
                          staticClass: "form-row__col form-row__col--md-50"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-stack form-stack--required" },
                            [
                              _c("label", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(input.heading) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  required: "",
                                  placeholder: input.placeholder
                                },
                                domProps: {
                                  value: _vm.isSsnInput(payment)
                                    ? _vm.ssn || input.value
                                    : input.value
                                },
                                on: {
                                  keyup: function($event) {
                                    return _vm.onInputChange(
                                      input.parameterName,
                                      $event.target.value,
                                      payment.id
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ]
        )
      }),
      _vm._v(" "),
      _vm.activeModal
        ? _c(
            "VcModal",
            {
              attrs: { modalBodyClass: "payment-method-info-modal" },
              on: {
                close: function($event) {
                  _vm.activeModal = null
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("\n      " + _vm._s(_vm.activeModal.header) + "\n    ")
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.activeModal.content) },
                slot: "content"
              })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }