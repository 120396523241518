var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "eon-sort",
      on: {
        click: function($event) {
          return _vm.$emit("clicked")
        }
      }
    },
    [
      _c("VcFormSelect", {
        attrs: {
          options: _vm.sortOptions,
          value: _vm.selected,
          prompt: "Sortera",
          valueProp: "value",
          labelProp: "name"
        },
        on: { change: _vm.onChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }