var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contactModalOpen
    ? _c(
        "VcModal",
        { attrs: { small: "" }, on: { close: _vm.onCloseContactModal } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("\n    Personligt prisförslag\n  ")
          ]),
          _vm._v(" "),
          !_vm.formSent
            ? _c(
                "div",
                {
                  staticClass: "vehile-list-simple-contact-form",
                  attrs: { slot: "content" },
                  slot: "content"
                },
                [
                  _c("div", { staticClass: "contact-forms" }, [
                    _c(
                      "form",
                      { staticClass: "contact-forms__form form-feedback-host" },
                      [
                        _c("h5", [
                          _c("strong", [
                            _vm._v(
                              "\n            Kontakta oss för att få prisförslag\n          "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("small", [
                            _vm._v(
                              "\n            Fyll i dina uppgifter så återkommer vi med ett personligt erbjudande.\n          "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                E-post\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.customerEmail,
                                      expression: "customerEmail"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    autocomplete: "email",
                                    name: "customerEmail",
                                    placeholder: "Din e-post",
                                    required: ""
                                  },
                                  domProps: { value: _vm.customerEmail },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.customerEmail = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn--primary btn--block",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.submitContactRequest($event)
                              }
                            }
                          },
                          [_vm._v("\n          Skicka förfrågan\n        ")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-text" }, [
                          _c("p", [
                            _vm._v(
                              "Alla personuppgifter som skickas in till Holmgrens Bil kommer att behandlas enligt bestämmelserna i EU:s\n            dataskyddsförordningen (GDPR). "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: { "text-decoration": "underline" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      title:
                                        "Läs om hur vi behandlar din personuppgifter",
                                      href: "/om-oss/personuppgiftspolicy"
                                    }
                                  },
                                  [_vm._v("Här")]
                                )
                              ]
                            ),
                            _vm._v(
                              " kan du läsa mer om hur vi\n            behandlar dina personuppgifter."
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              )
            : _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "vehile-list-simple-contact-form__loader"
                      },
                      [
                        _c("FontAwesomeIcon", {
                          attrs: { icon: _vm.faSpinnerThird, spin: true }
                        })
                      ],
                      1
                    )
                  : _c("div", [
                      _c("div", { staticClass: "form-feedback" }, [
                        !_vm.error
                          ? _c(
                              "div",
                              { staticClass: "loader loader--success" },
                              [
                                _c("div", { staticClass: "loader__shape" }, [
                                  _c("div", { staticClass: "loader__symbol" })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "loader__header" }, [
                                  _vm._v(
                                    "\n            Tack för ditt intresse!\n          "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "loader__text" }, [
                                  _vm._v(
                                    "\n            Vi hör av oss inom kort.\n          "
                                  )
                                ])
                              ]
                            )
                          : _c("div", { staticClass: "loader loader--error" }, [
                              _c("div", { staticClass: "loader__shape" }, [
                                _c("div", { staticClass: "loader__symbol" })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "loader__header" }, [
                                _vm._v(
                                  "\n            Något gick fel\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "loader__text" }, [
                                _vm._v(
                                  "\n            Vänligen försök igen eller kontakta oss\n          "
                                )
                              ])
                            ])
                      ])
                    ])
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }