<template>
  <form @submit.prevent="onSubmit">
    <label>
      Återställ lösenord
    </label>
    <VcFormStack label="Nytt lösenord" required>
      <input
        v-model="password"
        class="form-control"
        placeholder="Nytt lösenord"
        required
        type="password" />
    </VcFormStack>
    <VcFormStack label="Nytt lösenord" required>
      <input
        v-model="confirmPassword"
        class="form-control"
        placeholder="Upprepa lösenord"
        required
        type="password" />
    </VcFormStack>
    <button :class="{'disabled': btnDisabled}" type="submit" class="btn btn--primary btn--block">
      Återställ lösenord
    </button>
    <div v-if="restoreSuccess" class="feedback-message feedback-message--success feedback-message--slim">
      <div class="feedback-message__text">
        {{successMessage}}
      </div>
    </div>
    <div v-if="restoreError" class="feedback-message feedback-message--danger feedback-message--slim">
      <div class="feedback-message__text">
        {{errorMessage}}
      </div>
    </div>
  </form>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';
import Axios from '../../axios';
import qs from 'query-string';
import Views from './constants/Views';
const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

export default {
  components: {
    VcFormStack,
  },
  props: {
    lang: Object,
  },
  data() {
    return {
      email: '',
      code: '',
      password: '',
      confirmPassword: '',
      restoreError: false,
      restoreSuccess: false,
      errorMessage: this.lang.App.ResetFailedGeneric,
      successMessage: this.lang.App.ResetPasswordSuccess,
      btnDisabled: false,
    };
  },
  mounted() {
    const queryString = qs.parse(window.location.search);
    this.email = queryString.email;
    this.code = queryString.rToken;
  },
  methods: {

    async onSubmit() {
      this.restoreError = false;
      this.restoreSuccess = false;
      this.errorMessage = this.lang.App.ResetFailedGeneric;
      if(!pattern.test(this.password)) {
        this.restoreError = true;
        this.errorMessage = this.lang.App.PasswordPatternText;
        return;
      }
      if(this.password !== this.confirmPassword) {
        this.registerError = true;
        this.errorMessage = this.lang.App.PasswordMatchText;
        return;
      }
      this.btnDisabled = true;
      try {
        const {
          email,
          password,
          code,
        } = this;
        const result = await Axios.post('/api/login/resetpassword', { email, password, code });
        if(result.status === 200) {
          this.restoreSuccess = true;
          window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
          setTimeout(() => {
            this.$emit('view', Views.Index)
          }, 1000);
        } else {
          this.restoreError = true;
        }

      } catch(e) {
        this.restoreError = true;
      } finally {
        this.btnDisabled = false;
      }
    },
  },
}
</script>
