var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "configurator-section additional-service-selector additional-service-selector--fittings"
    },
    [
      _c("div", { staticClass: "configurator-section__title-container" }, [
        _c("h4", { staticClass: "configurator-section__title" }, [
          _c("i", {
            staticClass: "fal fa-swatchbook configurator-section__icon"
          }),
          _vm._v("\n      " + _vm._s(_vm.fittingsData.heading) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.fittingsData.informationModalUrl
          ? _c(
              "a",
              {
                staticClass: "link link--muted-light link--underline",
                attrs: {
                  href: "#",
                  "data-target": _vm.fittingsData.informationModalUrl,
                  "data-modal-source": "ajax"
                }
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.lang.SiteGeneral.ReadMore) + "\n    "
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("VcOptionBox", {
            attrs: {
              selected: !_vm.wantsFittings,
              heading: _vm.fittingsData.denyOption
            },
            on: {
              click: function($event) {
                return _vm.onWantsFittings(false)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "ConfiguratorOptionBox",
            {
              attrs: {
                selected: _vm.wantsFittings,
                heading: _vm.fittingsData.acceptOption,
                hasSubOption: true,
                yellowBg: _vm.wantsFittings,
                lang: _vm.lang
              },
              on: {
                click: function($event) {
                  return _vm.onWantsFittings(true)
                }
              }
            },
            [
              _vm.fetchedFittings
                ? _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    _vm._l(_vm.fetchedFittings, function(fitting) {
                      return _c(
                        "div",
                        { key: fitting.id, staticClass: "form-row" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-row__col" },
                            [
                              _c("ConfiguratorOptionBox", {
                                attrs: {
                                  selected: fitting.selected,
                                  heading: fitting.heading,
                                  smallText: fitting.headingMeta,
                                  price: _vm.getPrice(fitting.financing),
                                  hidePrice: fitting.hidePrice,
                                  lang: _vm.lang
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeFittingOption(fitting.id)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }