<template>
  <div class="accessories-list">
    <div class="container">
      <div class="accessories-list__header">
        <div class="accessories-list__header__title">
          {{ $formatNumber(hits) }} produkter
        </div>
      </div>
      <TheGrid
        :channel="channel"
        :documents="documents"
        :queriedAt="queriedAt"
        @onNext="$emit('onNext')"
      >
      </TheGrid>
    </div>
  </div>
</template>

<script>
import CartStoreKeys from '../../constants/CartStoreKeys';
import CartApi from '../../models/CartApi';
import TheGrid from "./TheGrid.vue";

export default {
  props: {
    channel: Object,
    queriedAt: Date
  },
  components: {
    TheGrid,
  },
  data() {
    return {
      documents: [],
      hits: 0,
    };
  },
  watch: {
    queriedAt() {
      this.documents = this.channel.data.documents.documents;
      this.hits = this.channel.data.documents.numberOfHits;
    }
  },
  created() {
    CartApi.setCommonHeader('storeKey', CartStoreKeys.Accessories);
  }
};
</script>
