import ServiceAgreement from "../apps/service-agreement";
import mountApp from "../utils/mount-app";
import qs from 'query-string';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    const regNr = qs.parse(window.location.search).regNr;

    mountApp({
      app: ServiceAgreement,
      selector: '[data-app=service-agreement]',
      props: {
        regNr,
      },
    });
  },
};
