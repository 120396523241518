<template>
  <form @submit.prevent="onSubmit">
    <label>
      {{lang.App.ResetPassword}}
    </label>
    <VcFormStack label="E-post" required>
      <input
        v-model="email"
        class="form-control"
        placeholder="Ange din epost"
        required
        type="email" />
    </VcFormStack>
    <button :class="{'disabled': btnDisabled}" type="submit" class="btn btn--primary btn--block">
      {{lang.App.ResetPassword}}
    </button>
    <div v-if="restoreSuccess" class="feedback-message feedback-message--success feedback-message--slim">
      <div class="feedback-message__text">
        {{successMessage}}
      </div>
    </div>
    <div v-if="restoreError" class="feedback-message feedback-message--danger feedback-message--slim">
      <div class="feedback-message__text">
        {{errorMessage}}
      </div>
    </div>
  </form>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';
import Axios from '../../axios';

export default {
  components: {
    VcFormStack,
  },
  props: {
    lang: Object,
  },
  data() {
    return {
      email: '',
      restoreError: false,
      restoreSuccess: false,
      errorMessage: this.lang.App.ResetFailedGeneric,
      successMessage: this.lang.App.ResetPasswordEmailNotice,
      btnDisabled: false,
    };
  },
  methods: {
    async onSubmit() {
      this.restoreError = false;
      this.restoreSuccess = false;
      this.btnDisabled = true;
      try {
        const {
          email,
        } = this;
        const result = await Axios.post('/api/login/requestresetpassword', { email });
        if(result.status === 200) {
          this.restoreSuccess = true;
        } else {
          this.restoreError = true;
        }

      } catch(e) {
        this.restoreError = true;
      } finally {
        this.btnDisabled = false;
      }
    },
  },
}
</script>
