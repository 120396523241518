<template>
  <div ref="container" class="overlay-scrollable">
    <div v-if="$slots['header']" ref="header">
      <slot name="header"></slot>
    </div>
    <div v-if="$slots['content']" class="overlay-scrollable__content">
      <slot name="content"></slot>
    </div>
    <div v-if="$slots['footer']" ref="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  mounted() {
    if (this.$slots['header']) {
      const headerHeight = this.$refs.header.querySelector('.overlay-header').clientHeight + 1;
      this.$refs.container.style.paddingTop = `${headerHeight}px`;
    }

    if (this.$slots['footer']) {
      const footerHeight = this.$refs.footer.querySelector('.overlay-footer').clientHeight;
      this.$refs.container.style.paddingBottom = `${footerHeight}px`;
    }
  },
}
</script>
