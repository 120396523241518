<template>
  <div>
    <div>
      <VcOptionBox
        :selected="selected"
        :heading="heading"
        :smallText="smallText"
        :smallBlock="smallText && smallText.length > 15"
        :attentionRibbon="ribbonText"
        :class="{
          'selected-option': yellowBg,
        }"
        @click="$emit('click')"
        @linkClick="onLinkClick">
        <div v-if="price && !hidePrice" slot="header-aside">
          <VcPriceTag :price="price" :show-if-zero="showIfZero">
          </VcPriceTag>
        </div>
        <div slot="header-link" v-if="modalContent">
          <span>{{lang.SiteGeneral.ReadMore}}</span>
        </div>
        <slot v-if="hasSubOption && selected" class="form-row" slot="content" name="content">
        </slot>
      </VcOptionBox>
    </div>
    <VcModal v-if="modalOpen"
      @close="modalOpen = false">
      <div slot="header">
        {{heading}}
      </div>
      <div slot="content" v-html="modalContent">
      </div>
    </VcModal>
  </div>
</template>

<script>
import { VcOptionBox, VcModal, VcPriceTag } from '@holmgrensbil/vue-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SubOptionComponents from '../shared/option-box/constants/SubOptionComponents';
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    VcOptionBox,
    VcModal,
    VcPriceTag,
    FontAwesomeIcon,
  },
  data() {
    return {
      SubOptionComponents,
      expanded: false,
      modalOpen: false,
      faAngleUp,
      faAngleDown,
    };
  },
  props: {
    selected: {
      type: Boolean,
    },
    yellowBg: Boolean,
    ribbonText: {
      type: String,
    },
    heading: {
      type: String,
    },
    smallText: {
      type: String,
    },
    modalContent: {
      type: String,
    },
    price: {
      type: Object,
    },
    hasSubOption: {
      type: Boolean,
      default: false,
    },
    hidePrice: Boolean,
    lang: Object,
  },
  computed: {
    showIfZero() {
      return this.price.showIfZero;
    }
  },
  methods: {
    onLinkClick() {
      if (!this.selected) {
        this.$emit('click');
      }

      if (this.modalContent) {
        this.modalOpen = true;
      }
    },
  },
}
</script>
