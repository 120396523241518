var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "list-unstyled properties" }, [
    _c("li", { staticClass: "properties__item properties__item--header" }, [
      _c("div", { staticClass: "properties__label" }, [
        _vm._v("\n      " + _vm._s(_vm.total.heading) + "\n\n      "),
        _c("div", { staticClass: "properties__meta" }, [
          _vm._v("\n        " + _vm._s(_vm.total.subHeading) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "properties__value" },
        [_c("VcPriceTag", { attrs: { price: _vm.total.price, total: "" } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }