<template>
  <div v-if="loading" class="u-align-center">
    <FontAwesomeIcon
      :icon="faSpinnerThird"
      :spin="true"
      class="wizard--service-booking__loader">
    </FontAwesomeIcon>
  </div>
  <div v-else>
    <div v-if="field.options.length">
      <div
        v-for="group in field.options"
        :key="group.name">
        <label>
          {{group.name}}
        </label>

        <div class="form-row">
          <div v-for="option in group.options"
            class="form-row__col"
            :key="option.id">
            <ConfigGroupOption
              :option="option"
              :multiselect="option.inputType === 0"
              @optionChange="onOptionChange(option, $event)">
            </ConfigGroupOption>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';
import ConfigGroupOption from '../shared/configurator/ConfigGroupOption.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    SectionField,
    ConfigGroupOption,
    FontAwesomeIcon,
  },
  data() {
    return {
      FieldTypes,
      faSpinnerThird,
    };
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
      invalidFields: 'fields/invalid',
      sectionByType: 'sections/byType',
    }),
    ...mapState({
      addonsRequestState: state => state.addons.fetchState,
    }),

    field() {
      return this.fieldByType(FieldTypes.Addons);
    },
    loading() {
      return this.addonsRequestState === 1;
    },
    serviceSelected() {
      return this.fieldByType(FieldTypes.ServiceTypes).value[0].id === 'service';
    },
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),
    onOptionChange(option, event) {
      const alteredOptions = this.field.options.map(item => {
        if(!item.options.find(subItem => subItem.id === option.id)) {
          return item;
        }
        const subOptions = item.options.map(subItem => {

          if (subItem.id === option.id) {
            return {
              ...subItem,
              selected: event,
            };
          } else {
            return {
              ...subItem,
              selected: subItem.inputType === 1 ? !event : subItem.selected,
            };
          }
        });
        return {
          ...item,
          options: subOptions
        };
      })
      this.setField({
        type: FieldTypes.Addons,
        options: alteredOptions,
      });
    },
  },
}
</script>
