var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "form-check form-check--sm",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("change", !_vm.field.value ? true : null)
          }
        }
      },
      [
        _c("input", {
          staticClass: "form-check__input",
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.field.value }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-check__label" }, [
          _vm._v("\n      " + _vm._s(_vm.field.label) + "\n    ")
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }