import FieldTypes from '../../shared/wizard/constants/FieldTypes';

export default {
  ...FieldTypes,
  DepartmentNr: 'departmentNr',
  PageName: 'pageName',
  TypeOfReport: 'typeOfReport',
  DamageNumber: 'damageNumber',
  DamagePlacement: 'damagePlacement',
  DamageText: 'comments',
  DamageImages: 'damageimages',
  ImageFrontLeft: 'imageFrontLeft',
  ImageFrontRight: 'imageFrontRight',
  ImageBackLeft: 'imageBackLeft',
  ImageBackRight: 'imageBackRight',
  ImageOverview: 'imageOverview',
  ImageZoom: 'imageZoomedIn',
  ImageExtra1: 'imageExtra1',
  ImageExtra2: 'imageExtra2',
  ImageExtra3: 'imageExtra3',
  ImageMeter: 'imageMeter',
  IsExpress: 'isExpress',
  Mileage: 'mileage',
  Addons: 'options',
}
