import Axios from "../../axios";
import InputTypes from "../../constants/InputTypes";
import gtm from "../../utils/gtm";

export default {
  state: {
    config: null,
    selectedIndex: -1,
    selected: null,
    mainImage: ""
  },

  async fetchConfigAction() {
    let url = window.location.toString().split("?")[0];

    if (url[url.length - 1] !== "/") {
      url += "/";
    }

    const res = await Axios.get(`${url}GetConfiguratorData`);
    this.setConfigAction(res.data);
    return this.state.config;
  },

  setConfigAction(config) {
    // console.log("setConfigAction", config);
    this.state.config = config;
  },

  setSelectedIndexAction(selectedIndex) {
    // console.log("setSelectedIndexAction", selectedIndex);
    this.state.selectedIndex = selectedIndex;
    this.setSelectedAction(
      this.state.config.carConfigurations[selectedIndex],
      true
    );
  },

  setSelectedAction(selected, setImage) {
    // console.log("setSelectedAction", selected);
    this.state.selected = selected;
    if (setImage) this.state.mainImage = selected.mainImage;
  },

  async updateCalculationAction() {
    const res = await Axios.post(
      "/api/Configurator/CalculateCampaignConfiguration",
      this.state.selected
    );
    this.setSelectedAction(res.data, false);
  },

  submitAction(data) {
    return Axios.post("/api/Configurator/CampaignContact", data);
  },

  trackSubmitAction({ contactData }) {
    gtm.configuratorSubmit({
      responseType: contactData.responseType,
      requestType: contactData.requestType,
      departmentNr: contactData.departmentNr
    });
  },

  setGroupOptionSelected(group, option, multiselect) {
    group.carConfigurationGroupOptions = group.carConfigurationGroupOptions.map(
      opt => {
        let selected;

        if (opt.id === option.id) {
          if (multiselect) {
            selected = !opt.selected;
          } else {
            selected = true;
            if (opt.summationImage) this.state.mainImage = opt.summationImage;
            else this.state.mainImage = this.state.selected.mainImage;
          }
        } else {
          if (multiselect) {
            selected = opt.selected;
          } else {
            selected = false;
          }
        }

        return {
          ...opt,
          selected
        };
      }
    );
  },

  setGroupSubOptionSelected(group, option, subOption, subOptionValue) {
    group.carConfigurationGroupOptions = group.carConfigurationGroupOptions.map(
      opt => ({
        ...opt,
        subOptions:
          opt.heading === option.heading
            ? opt.subOptions.map(subOpt => ({
                ...subOpt,
                values:
                  subOpt.heading === subOption.heading
                    ? subOpt.values.map(subOptVal => {
                        let selected;

                        if (subOpt.inputType === InputTypes.Toggle) {
                          selected = !subOptVal.selected;
                        } else {
                          selected =
                            String(subOptVal.value) === String(subOptionValue);
                        }

                        return {
                          ...subOptVal,
                          selected
                        };
                      })
                    : subOpt.values
              }))
            : opt.subOptions
      })
    );
  }
};
