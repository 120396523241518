var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-margin-top" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("table", { staticClass: "table table--striped table--bordered" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.store.additionalProducts, function(product) {
              return _c("tr", { key: product.id }, [
                _c("td", [
                  _vm._v(
                    "\n              " +
                      _vm._s(product.name) +
                      "\n              "
                  ),
                  _c(
                    "small",
                    {
                      staticClass: "ttjs u-c-muted",
                      attrs: { "data-ttjs-title": product.description }
                    },
                    [_c("i", { staticClass: "far fa-question-circle" })]
                  ),
                  _c("br")
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.$formatPrice(product.monthlyCost)) + " / mån"
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: product.selected,
                          expression: "product.selected"
                        }
                      ],
                      staticClass: "form-check__input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(product.selected)
                          ? _vm._i(product.selected, null) > -1
                          : product.selected
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = product.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    product,
                                    "selected",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    product,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(product, "selected", $$c)
                            }
                          },
                          function($event) {
                            return _vm.calculate()
                          }
                        ]
                      }
                    })
                  ])
                ])
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "u-fw-medium" }, [
      _vm._v("\n        Tillval"),
      _c("br"),
      _vm._v(" "),
      _c("small", { staticClass: "u-c-muted" }, [
        _vm._v(
          "Nedan finns de eventuella tillval som du kan välja i ditt\n          serviceavtal."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Tillval")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "120px" } }, [
          _vm._v("\n              Pris\n            ")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "60px" } }, [
          _vm._v("\n              Vald\n            ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }