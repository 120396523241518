<template>
  <div>
    <VcOptionBox v-for="payment in payments"
      :key="payment.id"
      :selected="payment.id === cartSelectedPayment || (!cartSelectedPayment && payment.selected)"
      :heading="payment.name"
      @click="onClick(payment)">

      <div slot="header-meta" v-if="payment.modalContent">
        <a class="link link--underline link--muted" @click.prevent.stop="onModalClick(payment)">
          {{ lang.Checkout.Payment.ReadMore }}
        </a>
      </div>


      <div class="checkout__payment-additional-details" slot="header-aside">

        <div v-if="payment.images && payment.images.length">
          <img v-for="image in payment.images" :key="image" :src="image" />
        </div>
        <div class="fee-text">
          {{ $formatPrice(payment.price) }}
        </div>
      </div>

      <div slot="content" v-if="payment.id === cartSelectedPayment && paymentInputs.length">

        <div class="form-row">
          <div v-for="input in paymentInputs"
            :key="input.parameterName"
            class="form-row__col form-row__col--md-50">
            <div class="form-stack form-stack--required">
              <label>
                {{input.heading}}
              </label>
              <input class="form-control"
                type="text"
                required
                :placeholder="input.placeholder"
                :value="isSsnInput(payment) ? (ssn || input.value) : input.value"
                @keyup="onInputChange(input.parameterName, $event.target.value, payment.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </VcOptionBox>

    <VcModal v-if="activeModal"
      modalBodyClass="payment-method-info-modal"
      @close="activeModal = null">
      <div slot="header">
        {{activeModal.header}}
      </div>
      <div slot="content" v-html="activeModal.content">
      </div>
    </VcModal>
  </div>
</template>

<script>
import { VcOptionBox, VcModal } from '@holmgrensbil/vue-components';
import { mapActions, mapState, mapGetters } from 'vuex';
import Steps from './constants/Steps';
import SessionStorageUtil from '../../utils/session-storage';
import FieldTypes from './constants/FieldTypes';
import PaymentTypes from './constants/PaymentTypes';

export default {
  components: {
    VcOptionBox,
    VcModal,
  },
  mounted() {
    this.onMount();
  },
  data() {
    return {
      activeModal: null,
      ssn: '',
    };
  },
  props: {
    lang: {
      type: Object,
      required: true,
    },
    prefilledSsn: String,
  },
  computed: {
    ...mapState({
      payments: state => state.payment.items,
      paymentInputs: state => state.paymentInputs.items,
    }),

    ...mapGetters({
      cartSelectedPayment: 'cart/selectedPayment',
      paymentById: 'payment/byId',
      fieldsExport: 'fields/export',
      fieldByType: 'fields/byType',
    }),
  },
  watch: {
    prefilledSsn() {
      this.ssn = this.prefilledSsn;
      if (this.cartSelectedPayment === PaymentTypes.Invoice) {
        this.onInputChange(FieldTypes.Ssn, this.ssn, PaymentTypes.Invoice);
      }
    }
  },
  methods: {
    ...mapActions({
      fetchPayments: 'payment/fetch',
      selectPayment: 'payment/select',
      setPaymentInput: 'paymentInputs/set',
    }),

    isSsnInput(payment) {
      return [PaymentTypes.Invoice, PaymentTypes.Partpayment].includes(payment.id);
    },

    async onClick(payment) {
      await this.selectPayment(payment);

      await SessionStorageUtil.set('checkout-payment', { id: payment.id })
      this.$emit('changePayment', Steps.payment, null, payment.name)

      if (payment.id === PaymentTypes.Invoice) {
        this.setPaymentInput({ parameterName: FieldTypes.Ssn, value: this.fieldsExport.governmentId });
      }
    },

    async onInputChange(parameterName, value, id) {
      await SessionStorageUtil.set('checkout-payment', { id, parameterName, value })
      this.ssn = '';
      this.setPaymentInput({ parameterName, value });
    },

    onModalClick(payment) {
      this.activeModal = {
        header: payment.name,
        content: payment.modalContent,
      };
    },
    async onMount() {
      this.ssn = this.prefilledSsn;
      await this.fetchPayments();

      const storedPayment = await SessionStorageUtil.get('checkout-payment')

      if (storedPayment) {
        const payment = this.paymentById(storedPayment.id)
        await this.selectPayment(payment);

        if (storedPayment.parameterName === FieldTypes.Ssn || storedPayment.id === PaymentTypes.Invoice) {

          const value = this.fieldsExport.governmentId || storedPayment.value || this.prefilledSsn;
          this.setPaymentInput({ parameterName: FieldTypes.Ssn, value: value });
        } else if (storedPayment.parameterName) {
          this.setPaymentInput({ parameterName: storedPayment.parameterName, value: storedPayment.value });
        }
      } else if(!this.cartSelectedPayment) {
        const preSelectedPayment = this.payments.find(p => p.selected);
        if(preSelectedPayment) {
          await this.selectPayment(preSelectedPayment);
          if(preSelectedPayment.id === PaymentTypes.Invoice) {
            this.setPaymentInput({ parameterName: FieldTypes.Ssn, value: this.prefilledSsn });
          }
        }
      }
    }
  },
}
</script>
