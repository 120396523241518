<template>
  <OverlayScrollable>
    <OverlayHeader
      slot="header"
      :text="headerText"
      :showBack="hasBack"
      @back="onBack"
      @close="onClose"
    >
    </OverlayHeader>

    <div slot="content" ref="main" class="i3-filter">
      <ThePanel
        v-if="hasPanel"
        :queryFilters="queryFilters"
        :showControls="view !== Views.Search"
        @clearAll="onClear"
        @onClearQueryFilter="onClearQueryFilter"
      >
        <div slot="search">
          <VcSearchBar
            placeholder="Fritextsök"
            blurOnSubmit
            disableClear
            small
            v-model="searchString"
            @submit="onSearchSubmit"
            @focus="onSearchFocus"
            @blur="onSearchBlur"
          >
          </VcSearchBar>
        </div>
      </ThePanel>

      <div class="i3-filter__view">
        <transition :name="transitionName">
          <FacetsView
            v-if="view === Views.Facets"
            :facets="facets"
            :expandedFacets="expandedFacets"
            :navigateToFacet="navigateToFacet"
            @selectFacet="onFacetSelect"
            @selectFilter="onFilterSelect"
            @selectRangeFilter="onRangeFilterSelect"
          >
          </FacetsView>
          <FacetView
            v-else-if="view === Views.Facet"
            :facet="facets.find(f => f.id === expandedFacets[0])"
            @selectFilter="onFilterSelect"
            @selectRangeFilter="onRangeFilterSelect"
          >
          </FacetView>
        </transition>

        <SearchView
          v-if="view === Views.Search"
          :popularSearches="popularSearches"
          @select="onPopularSearchSelect"
        >
        </SearchView>
      </div>
    </div>

    <OverlayFooter
      slot="footer"
      v-if="hasFooter"
      :showBack="hasBack"
      :text="`${footerBtnText} (${resultHits})`"
      @back="onBack"
      @click="onClose"
    >
    </OverlayFooter>
  </OverlayScrollable>
</template>

<script>
import Vue from "vue";
import { VcSearchBar } from "@holmgrensbil/vue-components";
import ChannelEvents from "../../constants/ChannelEvents";
import I3QueryFilterTypes from "../../constants/I3QueryFilterTypes";
import I3QueryParams from "../../constants/I3QueryParams";
import Breakpoints from "../../constants/Breakpoints";
import ChannelQuery from "../../models/ChannelQuery";
import OverlayHeader from "../shared/OverlayHeader.vue";
import OverlayFooter from "../shared/OverlayFooter.vue";
import OverlayScrollable from "../shared/OverlayScrollable.vue";
import ThePanel from "./ThePanel.vue";
import FacetsView from "./FacetsView.vue";
import FacetView from "./FacetView.vue";
import SearchView from "./SearchView.vue";
import values from "./values";
import CartStoreKeys from "../../constants/CartStoreKeys";

const { rangeFilterTopValue } = values;

const Views = {
  Facets: 1,
  Facet: 2,
  Search: 3
};

export default {
  props: {
    channel: Object,
    onClose: Function,
    btnText: String
  },
  components: {
    OverlayHeader,
    OverlayFooter,
    OverlayScrollable,
    ThePanel,
    FacetsView,
    FacetView,
    SearchView,
    VcSearchBar
  },
  data() {
    return {
      searchString: null,
      view: Views.Facets,
      Views,
      facets: [],
      resultHits: null,
      queryFilters: [],
      expandedFacets: [],
      transition: null,
      popularSearches: []
    };
  },
  created() {
    this.popularSearches = this.channel.popularSearches;
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  computed: {
    transitionName() {
      switch (this.transition) {
        case "forward":
          return "slide";
        case "back":
          return "slide-back";
        default:
          return "none";
      }
    },

    hasBack() {
      return this.view === Views.Facet;
    },

    hasPanel() {
      return this.view !== Views.Facet;
    },

    hasFooter() {
      return this.view !== Views.Search;
    },

    footerBtnText() {
      return this.btnText || "Visa bilar";
    },

    headerText() {
      switch (this.view) {
        case Views.Facet:
          return this.facets.find(f => f.id === this.expandedFacets[0])
            .displayName;
        default:
          return "Filtrera";
      }
    },

    navigateToFacet() {
      return this.windowWidth < Breakpoints.md;
    },
    defaultQueryParams() {
      return this.channel.defaultQuery?.params || {};
    },

    hasResult() {
      return this.resultHits > 0 ? true : !this.channel.isUrlBased;
    }
  },
  methods: {
    onQuerySuccess() {
      this.facets = this.filterFacets();
      this.resultHits = this.channel.data.documents.numberOfHits;
      this.queryFilters = this.channel.getQueryFilters();
    },

    filterFacets() {
      return this.channel.data.facets.filter(f => {
        const defaultKeys = Object.keys(this.defaultQueryParams);
        if (defaultKeys.includes(f.id)) {
          return false;
        } else {
          if (
            f.selectionType !== "AND" ||
            f.filters.length > 1 ||
            !f.filters.length
          ) {
            return true;
          }
          return !f.filters[0].selected;
        }
      });
    },

    onFacetSelect(facet) {
      if (this.expandedFacets.includes(facet.id)) {
        this.expandedFacets = this.expandedFacets.filter(id => id !== facet.id);
      } else {
        this.expandedFacets = [...this.expandedFacets, facet.id];
      }

      if (this.navigateToFacet) {
        this.transition = "forward";
        this.view = Views.Facet;
        this.$refs.main.scrollTo(0, 0);
      }
    },

    onFilterSelect(filter) {
      this.channel.execute(filter.query);
    },

    onRangeFilterSelect({ facet, min, max }) {
      const query = this.channel.lastQuery;
      const initialMinQueryParam = `initial_${facet.minQuery}`;
      const initialMaxQueryParam = `initial_${facet.maxQuery}`;
      let initialMin = query.getParam(initialMinQueryParam);
      let initialMax = query.getParam(initialMaxQueryParam);

      if (typeof initialMin === "undefined") {
        initialMin = facet.min;
        query.setParam(initialMinQueryParam, initialMin);
      }

      if (typeof initialMax === "undefined") {
        initialMax = facet.max;
        query.setParam(initialMaxQueryParam, initialMax);
      }

      if (facet.min === min) {
        query.unsetParam(facet.minQuery);
      } else {
        query.setParam(facet.minQuery, min);
      }

      if (max >= rangeFilterTopValue || facet.max === max) {
        query.unsetParam(facet.maxQuery);
      } else {
        query.setParam(facet.maxQuery, max);
      }

      query.unsetParam(I3QueryParams.Offset);

      this.channel.execute(query);
    },

    onPopularSearchSelect(popularSearch) {
      this.channel.execute(popularSearch.query);
    },

    onClear() {
      const params = Object.keys(this.channel.defaultQuery.params).filter(
        param => param !== I3QueryParams.FreeText
      );

      if (params.length) {
        this.channel.execute(new ChannelQuery()).then(() => {
          this.expandedFacets = [];
        });
        const url =
          window.ecomConfig.storeKey === CartStoreKeys.Accessories
            ? window.MainAccessoriesSearchPage
            : window.MainVehicleSearchPage;
        window.location.href = url;
      } else {
        this.channel.execute(this.channel.defaultQuery).then(() => {
          this.expandedFacets = [];
        });
      }
    },

    onClearQueryFilter(queryFilter) {
      this.getClearQueryFilterFn(queryFilter)(queryFilter);
    },

    getClearQueryFilterFn(queryFilter) {
      switch (queryFilter.type) {
        case I3QueryFilterTypes.Filter:
          return this.clearFilter;
        case I3QueryFilterTypes.RangeFacet:
          return this.clearRangeFacet;
        case I3QueryFilterTypes.FreeText:
          return this.clearFreeText;
      }
    },

    clearFilter({ filter }) {
      if (filter.navigationFilter) {
        window.location.href = filter.url;
      } else {
        this.channel.execute(filter.query);
      }
    },

    clearRangeFacet({ facet }) {
      const query = this.channel.lastQuery;

      query.unsetParam(facet.maxQuery);
      query.unsetParam(facet.minQuery);
      this.channel.execute(query);
    },

    clearFreeText() {
      const query = new ChannelQuery(this.channel.lastQuery);

      query.unsetParam(I3QueryParams.FreeText);
      this.channel.execute(query);
    },

    onBack() {
      this.expandedFacets = [];
      this.transition = "back";
      this.view = Views.Facets;
    },

    onSearchFocus() {
      this.transition = null;

      Vue.nextTick(() => {
        this.view = Views.Search;
      });
    },

    onSearchBlur() {
      this.view = Views.Facets;
    },

    onSearchSubmit() {
      this.search();
    },

    search() {
      const str = this.searchString;
      const query = new ChannelQuery(this.channel.lastQuery);

      if (str) {
        query.setParam(I3QueryParams.FreeText, str);
      } else {
        query.unsetParam(I3QueryParams.FreeText);
      }

      this.searchString = null;

      this.channel.execute(query).then(() => {
        this.view = Views.Facets;
      });
    }
  }
};
</script>
