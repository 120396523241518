import Vue from 'vue';
import App from './App.vue';
import { l10n } from '../../utils/l10n';

export default (props) => {
  const lang = {
    General: l10n.General,
    Contact: l10n.Contact,
  };

  props.lang = lang;
  return Vue.extend({
    render: h => h(App, { props })
  });
};
