<template>
  <div class="overlay-footer" :class="{'overlay-footer--yellow-bg': yellowBg}">
    <slot></slot>
    <div class="overlay-footer__actions">
      <a v-if="showBack" class="btn btn--outline" @click="$emit('back')">
        <i class="fal fa-angle-left"></i>
        Tillbaka
      </a>
      <a class="btn btn--primary" @click="$emit('click')">
        {{text}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBack: Boolean,
    text: String,
    yellowBg: Boolean,
  },
}
</script>
