const classes = {
  itemActive: 'nav-tree__item--active',
  headerActive: 'nav-tree__header--active',
};

export default {
  init() {

    this.addEvents();
  },

  addEvents() {
    $('body').
      on('click', '.nav-tree__item--parent', this.onParentClick);
    $('body').
      on('click', '.nav-tree__header', this.onHeaderClick.bind(this));
  },

  onParentClick(e) {
    e.stopPropagation();
    $(this).toggleClass(classes.itemActive);
    return false;
  },
  onHeaderClick({ currentTarget }) {
    const $target = $(currentTarget);
    if ($target.hasClass('nav-tree__header--link'))
    {
      location.href = $target.find('a').attr('href');
      return true;
    }
    else
    {
      $target.toggleClass(classes.headerActive);
      $target.nextUntil('.nav-tree__header').toggleClass('shown');
      return false;
    }
    
  },
}
