<template>
  <VcFormStack
    :label="field.label"
    :required="field.required">
    <textarea
      class="form-control banan"
      :class="{'form-control--invalid': invalid}"
      :placeholder="field.placeholder"
      :value="field.value"
      :required="field.required"
      @change="$emit('change', $event.target.value)">
    </textarea>
  </VcFormStack>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormStack,
  },
  props: {
    invalid: Boolean,
    field: Object,
  },
}
</script>
