import isDefined from '../../../../utils/is-defined';
import ServicesApi from '../../../../models/ServicesApi';
import FieldTypes from '../constants/FieldTypes';
const state = {
  items: [],
};

const getters = {
  byType: ({ items }) => type => {
    return items.find(item => item.type === type);
  },

  bySection: ({ items }) => section => {
    return items.filter(item => item.section === section);
  },

  byInputType: ({ items }) => inputType => {
    return items.filter(item => item.inputType === inputType);
  },

  valid(_, getters) {
    return !getters.invalid.length;
  },

  invalid({ items }) {
    return items.filter(item => {
      const errors = [];

      if (item.required && !isDefined(item.value)) {
        errors.push('required');
      }

      if (item.inputPattern && !item.inputPattern.test(item.value)) {
        errors.push('pattern');
      }

      // Check if item is selected and there are required suboptions with missing values
      if (item.options) {
        item.options.forEach(opt => {
          if (Array.isArray(item.value) && item.value.find(v => v.name === opt.name) && opt.subOptions) {
            opt.subOptions.forEach(subOpt => {
              if (subOpt.required) {
                const subOptValue = item.value.find(i => i.name === opt.name);

                if (!subOptValue || !isDefined(subOptValue.value)) {
                  errors.push('subOption:required');
                }
              }
            });
          }
        });
      }

      return !!errors.length;
    });
  },

  invalidBySection: (_, getters) => section => {
    return getters.invalid.filter(field => field.section === section);
  },

  export({ items }) {
    const data = {};

    items.forEach(item => {
      if (!item.hidden) {
        data[item.type] = item.value;
      }
    });

    return data;
  },
};

const actions = {
  load({ commit }, items) {
    commit('received', items);
  },

  set({ commit }, item) {
    commit('set', item);
  },

  async setRegNr({ commit }, regNr) {

    try {
      const res = await ServicesApi.fetchCarInfo({ regNr });
      const {
        brand,
        model,
        version,
        modelYear,
      } = res.data;

      const vehicleName = `${brand} ${model} ${version} ${modelYear}`;
      commit('set', { type: FieldTypes.VehicleName, value: vehicleName });
      commit('set', { type: FieldTypes.ModelYear, value: modelYear });
      commit('set', { type: FieldTypes.VehicleInfoRegNr, value: regNr.toUpperCase() });
    } catch(e) {
      console.error(e);
    }
  }
};

const mutations = {
  received(state, items) {
    state.items = items;
  },

  set(state, { type, ...props }) {
    state.items = state.items.map(item => {
      return (item.type === type) ? {
        ...item,
        ...props,
      } : item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
