<template>
  <div class="filter-bar__tag filter-bar__tag--group">
    <span>
      {{label}}
    </span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
  },
}
</script>
