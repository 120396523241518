import gtm from "../utils/gtm";

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body')
      .on('click', '.directions-link', this.onDirectionsClick.bind(this));
  },

  async onDirectionsClick(e) {
    e.preventDefault();

    const $el = $(e.currentTarget);

    await gtm.directionsLinkClick({
      departmentNr: $el.data('department-nr'),
      vehicleCondition: $el.data('vehicle-condition'),
      brandName: $el.data('brandname'),
    });
    window.open($el.attr('href'), '_blank');
  },
};
