var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "properties__item" }, [
    _c("div", { staticClass: "properties__label" }, [
      _vm._v("\n    " + _vm._s(_vm.line.name) + "\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "properties__value" },
      [
        _c("VcPriceTag", {
          attrs: { price: _vm.line.price, "show-if-zero": !_vm.line.hidePrice }
        }),
        _vm._v(" "),
        _vm.line.message
          ? _c("div", {
              staticClass: "u-c-attention",
              domProps: { innerHTML: _vm._s(_vm.line.message) }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }