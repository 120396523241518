<template>
  <div class="blog-list">
    <div class="blog-list__header">
      <div class="blog-list__header__title">
        {{ $formatNumber(hits) }} artiklar
      </div>
      <div class="blog-list__header__sorting" @click="setSortingDirection">
        Senaste
        <FontAwesomeIcon
          class="blog-list__header__icon"
          :icon="sortByLatest ? faChevronDown : faChevronUp"
        >
        </FontAwesomeIcon>
      </div>
    </div>
    <div class="campaign-list__items">
      <ArticleSpot
        v-for="article in articles"
        :key="article.id"
        :article="article"
      />
    </div>
    <VcIntersectObserver
      :options="{ rootMargin: '200px' }"
      @intersect="$emit('onNext')"
    ></VcIntersectObserver>
  </div>
</template>

<script>
import moment from "moment";
import i3TotalCount from "../../components/i3-total-count";
import I3DocBlogArticle from "../../models/I3DocBlogArticle";
import ArticleSpot from "./ArticleSpot.vue";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VcIntersectObserver } from "@holmgrensbil/vue-components";
export default {
  props: {
    channel: Object,
    queriedAt: Date
  },
  components: {
    FontAwesomeIcon,
    ArticleSpot,
    VcIntersectObserver
  },
  data() {
    return {
      documents: [],
      hits: 0,
      sortByLatest: true,
      faChevronDown,
      faChevronUp
    };
  },
  watch: {
    queriedAt() {
      this.documents = this.channel.data.documents.documents;
      this.hits = this.channel.data.documents.numberOfHits;

      setTimeout(() => {
        i3TotalCount.init();
      }, 50);
    }
  },
  computed: {
    articles() {
      return this.documents
        .map(doc => new I3DocBlogArticle(doc))
        .sort((a, z) =>
          this.sortByLatest
            ? moment(z.created) - moment(a.created)
            : moment(a.created) - moment(z.created)
        );
    }
  },
  methods: {
    setSortingDirection() {
      this.sortByLatest = !this.sortByLatest;
    }
  }
};
</script>
