import ChooseSpec from "../apps/choose-spec";
import mountApp from "../utils/mount-app";
import TyreSpec from "../models/TyreSpec";

export default {
  init() {
    //this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: ChooseSpec,
      selector: '[data-app=choose-spec]',
      props: {
        TyreSpec
      },
      extractProps: el => ({
        header: el.getAttribute('data-header'),
        meta: el.getAttribute('data-meta'),
        promo: el.getAttribute('data-promo') !== null,
        promoBg: el.getAttribute('data-promo-bg'),
        buttonText: el.getAttribute('data-button-text'),
        buttonHref: el.getAttribute('data-button-href'),
        productTypeValue: el.getAttribute('data-product-type')
      }),
    });
  },
};
