var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "configurator-text-input",
      class: {
        "configurator-text-input--textarea": _vm.isLongText
      }
    },
    [
      _c("div", { staticClass: "configurator-text-input__image-container" }, [
        _vm.useRegNrIcon
          ? _c("img", {
              staticClass: "configurator-text-input__regnr-image",
              attrs: { src: "/Static/images/bigregnr@2x.png" }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.isLongText
        ? _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input"
              }
            ],
            staticClass: "configurator-text-input__input",
            attrs: { name: _vm.inputKey, placeholder: _vm.placeholder },
            domProps: { value: _vm.input },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.input = $event.target.value
              }
            }
          })
        : (_vm.type || "text") === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input"
              }
            ],
            staticClass: "configurator-text-input__input",
            class: {
              "configurator-text-input__input--regnr-input": _vm.useRegNrIcon
            },
            attrs: {
              name: _vm.inputKey,
              placeholder: _vm.placeholder,
              autocomplete: _vm.type,
              type: "checkbox"
            },
            domProps: {
              checked: Array.isArray(_vm.input)
                ? _vm._i(_vm.input, null) > -1
                : _vm.input
            },
            on: {
              change: function($event) {
                var $$a = _vm.input,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.input = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.input = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.input = $$c
                }
              }
            }
          })
        : (_vm.type || "text") === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input"
              }
            ],
            staticClass: "configurator-text-input__input",
            class: {
              "configurator-text-input__input--regnr-input": _vm.useRegNrIcon
            },
            attrs: {
              name: _vm.inputKey,
              placeholder: _vm.placeholder,
              autocomplete: _vm.type,
              type: "radio"
            },
            domProps: { checked: _vm._q(_vm.input, null) },
            on: {
              change: function($event) {
                _vm.input = null
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.input,
                expression: "input"
              }
            ],
            staticClass: "configurator-text-input__input",
            class: {
              "configurator-text-input__input--regnr-input": _vm.useRegNrIcon
            },
            attrs: {
              name: _vm.inputKey,
              placeholder: _vm.placeholder,
              autocomplete: _vm.type,
              type: _vm.type || "text"
            },
            domProps: { value: _vm.input },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.input = $event.target.value
              }
            }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }