<template>
  <div v-if="loading" class="u-align-center">
    <FontAwesomeIcon
      :icon="faSpinnerThird"
      :spin="true"
      class="wizard--service-booking__loader"
    >
    </FontAwesomeIcon>
  </div>
  <div v-else>
    <div v-if="field.options.length">
      <div v-for="group in field.options" :key="group.name">
        <label>
          {{ group.name }}
        </label>

        <div class="form-row">
          <div class="group-row" v-for="(row, index) in groupedOptions(group.name)" :key="`row-${index}`">
            <div
              v-for="option in row"
              class="form-row__col"
              :class="
                option.largeImage &&
                  'form-row__col--md-50 form-row__col--large-image'
              "
              :key="option.id"
            >
              <ConfigGroupOption
                :option="option"
                :multiselect="option.inputType === 2"
                @optionChange="onOptionChange(option, $event)"
              >
              </ConfigGroupOption>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <label v-if="serviceSelected">
      Övrigt
    </label>
    <div class="form-row" v-if="serviceSelected">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.ServiceMessage"> </SectionField>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FieldTypes from "./constants/FieldTypes";
import SectionField from "../shared/wizard/SectionField.vue";
import ConfigGroupOption from "../shared/configurator/ConfigGroupOption.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import groupBy from "lodash/groupBy";
import json from "../../utils/json";

export default {
  components: {
    SectionField,
    ConfigGroupOption,
    FontAwesomeIcon
  },
  data() {
    return {
      FieldTypes,
      faSpinnerThird
    };
  },
  computed: {
    ...mapGetters({
      fieldByType: "fields/byType",
      invalidFields: "fields/invalid",
      sectionByType: "sections/byType"
    }),
    ...mapState({
      addonsRequestState: state => state.addons.fetchState
    }),

    field() {
      return this.fieldByType(FieldTypes.Addons);
    },
    loading() {
      return this.addonsRequestState === 1;
    },
    // serviceSelected() {
    //   return (
    //     this.fieldByType(FieldTypes.ServiceTypes).value[0].id === "service"
    //   );
    // },
    
  },
  methods: {
    ...mapActions({
      setField: "fields/set"
    }),
    groupedOptions(groupName) {
      var group = json(this.field.options, 'name', groupName)[0];
      if (group) {
        return groupBy(group.options, 'rowIndex');
      } else {
        return this.field.options
      }
    },
    onOptionChange(option, event) {
      const alteredOptions = this.field.options.map(item => {
        if (!item.options.find(subItem => subItem.id === option.id)) {
          return item;
        }
        const subOptions = item.options.map(subItem => {
          if (subItem.id === option.id) {
            return {
              ...subItem,
              selected: event
            };
          } else {
            return {
              ...subItem,
              selected: subItem.inputType === 1 ? !event : subItem.selected
            };
          }
        });
        return {
          ...item,
          options: subOptions
        };
      });
      this.setField({
        type: FieldTypes.Addons,
        options: alteredOptions
      });
    }
  }
};
</script>
