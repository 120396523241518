import i3ChannelManager from "../models/I3ChannelManager";
import ChannelEvents from "../constants/ChannelEvents";
import I3DocProduct from '../models/I3DocProduct';

export default {
  init() {
    this.ref = $('.page-header__progress').data('i3-ref');
    this.channel = i3ChannelManager.getByRef(this.ref);
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess.bind(this));
    this.onQuerySuccess();
  },

  modifyDOM() {
  },

  onQuerySuccess() {
    // Don't update progress if filtering - keep total counts
    if (this.channel.hasTouchedParam('webpublish')) {
      return;
    }

    this.updateProgressBar();
  },

  updateProgressBar() {
    const products = this.getProducts();
    const totalCount = products.length;
    const availableCount = products.filter(p => !p.sold).length;
    const progressWidth = (availableCount / totalCount) * 100;

    $('.page-header__progress__available-count').text(availableCount);
    $('.page-header__progress__total-count').text(totalCount);
    $('.page-header__progress .progress-bar__progress').css('width', `${progressWidth}%`);
  },

  getProducts() {
    if (!this.channel.data.documents.documents) {
      return [];
    }

    return this.channel.data.documents.documents.map(doc => new I3DocProduct(doc));
  },
}
