var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStep",
    {
      attrs: {
        completed: _vm.section.order < _vm.order,
        feedbackDanger: _vm.displaySectionValidationError,
        title: _vm.getTitle(_vm.section),
        disabled: _vm.disabled,
        subtitle: _vm.getSubtitle(_vm.section),
        intro: ""
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.disabled,
              expression: "!disabled"
            }
          ],
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _c(_vm.SectionTypeComponents[_vm.section.type], {
            tag: "component",
            attrs: { section: _vm.section, sectionValid: _vm.valid },
            on: { submit: _vm.onSubmit }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.displaySectionValidationError
        ? _c("SectionValidationError", {
            attrs: { slot: "feedback", fields: _vm.invalidSectionFields },
            slot: "feedback"
          })
        : _vm.section.order < _vm.order && _vm.lookupResultItems.length
        ? _c(
            "div",
            {
              staticClass: "lookup-result",
              attrs: { slot: "feedback" },
              slot: "feedback"
            },
            _vm._l(_vm.lookupResultItems, function(item) {
              return _c(
                "div",
                { key: item.label, staticClass: "lookup-result__item" },
                [
                  _c("label", [
                    _vm._v("\n        " + _vm._s(item.label) + "\n      ")
                  ]),
                  _vm._v("\n      " + _vm._s(item.value) + "\n    ")
                ]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }