import CampaignConfigurator from "../apps/campaign-configurator";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: CampaignConfigurator,
      selector: '[data-app=campaign-configurator]',
    });
  },
};
