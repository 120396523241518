<template>
  <div class="configurator-section additional-service-selector additional-service-selector--fittings">
    <div class="configurator-section__title-container">
      <h4 class="configurator-section__title">
        <i class="fal fa-swatchbook configurator-section__icon"></i>
        {{ fittingsData.heading }}
      </h4>
      <a
        v-if="fittingsData.informationModalUrl"
        class="link link--muted-light link--underline"
        href="#"
        :data-target="fittingsData.informationModalUrl"
        data-modal-source="ajax"
      >
        {{ lang.SiteGeneral.ReadMore }}
      </a>
    </div>
    <div>
      <VcOptionBox
        :selected="!wantsFittings"
        :heading="fittingsData.denyOption"
        @click="onWantsFittings(false)"></VcOptionBox>
      <ConfiguratorOptionBox
        :selected="wantsFittings"
        :heading="fittingsData.acceptOption"
        :hasSubOption="true"
        @click="onWantsFittings(true)"
        :yellowBg="wantsFittings"
        :lang="lang"
      >
        <div v-if="fetchedFittings" slot="content">
          <div class="form-row" v-for="fitting in fetchedFittings" :key="fitting.id">
            <div class="form-row__col">
              <ConfiguratorOptionBox
                :selected="fitting.selected"
                :heading="fitting.heading"
                :smallText="fitting.headingMeta"
                :price="getPrice(fitting.financing)"
                :hidePrice="fitting.hidePrice"
                :lang="lang"
                @click="changeFittingOption(fitting.id)">
              </ConfiguratorOptionBox>
            </div>
          </div>
        </div>
      </ConfiguratorOptionBox>
    </div>
  </div>
</template>

<script>
import { VcOptionBox } from '@holmgrensbil/vue-components';
import ConfiguratorOptionBox from './ConfiguratorOptionBox.vue'
import ConfiguratorSubSelect from './ConfiguratorSubSelect.vue';
import Axios from '../../axios';
import store from './store';
import FinanceOptions from '../../constants/FinanceOptions';

export default {
  components: {
    ConfiguratorOptionBox,
    VcOptionBox,
    ConfiguratorSubSelect,
  },
  props: {
    fittingsData: Object,
    selectedFinancingOption: String,
    displayTax: Boolean,
    lang: Object,
  },
  data() {
    return {
      wantsFittings: false,
      fetchedFittings: null,
      selectedFitting: null,
    };
  },
  methods: {
    async onWantsFittings(wantsFittings) {
      this.wantsFittings = wantsFittings;
      if (wantsFittings) {
        const calculateFinanceOptions = store.state.calculateFinanceOptions;
        const financingTemplateId = store.state.financingConfig.financingTemplateId;
  
        const { data } = await Axios.post(`/api/vehicleConfigurator/BKR03B/fittings`, {
          ...calculateFinanceOptions,
          financingTemplateId,
        })
  
        this.fetchedFittings = data;
  
        store.setFittingOptions(data);
      } else {
        this.fetchedFittings = null;
        store.setFittingOptions([]);
      }
    },
    getPrice(financing) {
      const selectedFinancing = financing[this.selectedFinancingOption];
      
      if (this.selectedFinancingOption !== FinanceOptions.CASH) {
        return {
          current: selectedFinancing.monthlyCost,
          ordinary: selectedFinancing.ordinaryMonthlyCost,
          suffix: ' kr/månad',
        }
      } else {
        return {
          current: this.displayTax ? selectedFinancing.currentPrice : selectedFinancing.currentPriceNoVat,
          ordinary: this.displayTax ? selectedFinancing.ordinaryPrice : selectedFinancing.ordinaryPriceNoVat,
          suffix: ' kr',
        }
      }
    },
    changeFittingOption(id) {
      const fetchedFittins = this.fetchedFittings.map(fitting => {
        return {
          ...fitting,
          selected: fitting.id === id ? true : false,
        }
      })
      this.fetchedFittings = fetchedFittins;
      store.setFittingOptions(fetchedFittins);
    }
  }
}
</script>
