<template>
  <div
    class="wizard wizard--service-top wizard--service-booking"
    v-if="departmentSelected"
  >
    <SectionList
      :sections="sections"
      :SectionTypeComponents="SectionTypeComponents"
      @completed="onSectionCompleted"
    >
    </SectionList>
    <FormSubmit :lastSection="lastSection" @completed="onSectionCompleted">
    </FormSubmit>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SectionTypes from "./constants/SectionTypes";
import FieldTypes from "./constants/FieldTypes";
import SectionList from "../shared/wizard/SectionList.vue";
import FormSubmit from "../shared/wizard/FormSubmit.vue";
import SectionTypeComponents from "./constants/SectionTypeComponents";
import gtm from "../../utils/gtm";
import ServiceBookingManager from "../../models/ServiceBookingManager";
import isDefined from "../../utils/is-defined";

export default {
  components: {
    SectionList,
    FormSubmit
  },
  data() {
    return {
      SectionTypeComponents,
      regNr: null,
      departmentNr: null,
      availableDays: [],
      service: null,
      carInfo: null,
    };
  },
  created() {
if(ServiceBookingManager.carInfo) {
      this.carInfo = ServiceBookingManager.carInfo;
    }

    ServiceBookingManager.onCarInfoSet(e => {
      if(e.detail.carInfo) {
        this.carInfo = e.detail.carInfo;
      }
    });

    if (ServiceBookingManager.service) {
      this.service = ServiceBookingManager.service;
      this.setService({ service: ServiceBookingManager.service });
    }
    ServiceBookingManager.onServiceSet(e => {
      if (e.detail.service) {
        this.setEndpoint(`/api/services/${e.detail.service.value}`);
        this.service = e.detail.service;
        this.setService({ service: e.detail.service });
      }
    });

    ServiceBookingManager.onRegNrSet(e => {
      const regNr = e.detail.regNr;

      if (this.regNr && this.regNr !== regNr) {
        this.resetState();
        this.departmentNr = null;
      }

      this.setField({ type: FieldTypes.RegNr, value: regNr });
      this.regNr = regNr;
      this.setRegNr(this.regNr);
      let section = { type: "Vehicle", order: 0 };
      gtm.tyreBookingSectionCompleted({ section });
    });

    ServiceBookingManager.onDepartmentSet(e => {
      if (e.detail.department) {
        const departmentNr = e.detail.department.value;
        const departmentName = e.detail.department.name;
        this.departmentNr = departmentNr;
        this.setField({ type: FieldTypes.DepartmentNr, value: departmentNr });
        this.fetchTyreServices({
          departmentNr: departmentNr,
          regNr: this.regNr,
          service: this.service.value
        });
        this.setField({
          type: FieldTypes.DepartmentName,
          value: departmentName
        });
        let section = { type: "Department", order: 0 };
        gtm.tyreBookingSectionCompleted({ section });
      } else {
        this.departmentNr = null;
      }
    });
  },
  computed: {
    ...mapState({
      sections: state => state.sections.items
    }),

    ...mapGetters({
      fieldByType: "fields/byType",
      sectionByType: "sections/byType"
    }),

    lastSection() {
      return this.sectionByType(SectionTypes.Summary);
    },
    departmentSelected() {
      return isDefined(this.departmentNr);
    }
  },
  methods: {
    ...mapActions({
      setField: "fields/set",
      fetchAddons: "addons/fetch",
      fetchAvailableDays: "dates/fetchDates",
      fetchTyreServices: "tyreServices/fetch",
      filterSections: "sections/filterSections",
      setEndpoint: "form/load",
      setService: "service/setService",
      setRegNr: "fields/setRegNr",
      resetState: "config/reset"
    }),

    onSectionCompleted(section) {
      const departmentNr = this.departmentNr;
      const brandName = this.carInfo ? this.carInfo.brand : null;
      const selectedTyreService = this.fieldByType(
          FieldTypes.TyreServices
        ).options.find(
          option =>
            option.name ===
            this.fieldByType(FieldTypes.TyreServices).value[0].name
        );
        const serviceType = selectedTyreService.name;
      const selectedAddons = [];
      const addonTypes = this.fieldByType(FieldTypes.Addons).options.forEach(option => option.options.forEach(subOption => {
          if (subOption.selected) {
            selectedAddons.push(subOption.id)
          }
        })) || [];
      const addonType = selectedAddons.join(',');

      if (section.type === SectionTypes.TyreServices) {
        this.fetchAddons({
          departmentNr: this.departmentNr,
          service: this.service.value,
          regNr: this.regNr,
          amount: this.fieldByType(FieldTypes.Amount).value,
          serviceType: selectedTyreService.id
        });
        this.setField({
          type: FieldTypes.ServiceMessage,
          hiddenInSummary: !this.serviceSelected
        });
      }

      if (section.type === SectionTypes.Addons) {
        
        this.fetchAvailableDays({
          departmentNr: this.departmentNr,
          addons: selectedAddons,
          regNr: this.regNr,
          service: this.service.value,
          serviceType: selectedTyreService.id
        });
        this.setField({
          type: FieldTypes.Addons,
          value: selectedAddons
        });
      }

      gtm.tyreBookingSectionCompleted({ section, departmentNr, serviceType, addonType, brandName });
    }
  }
};
</script>
