var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "grid", staticClass: "vehicle-list__items" },
        _vm._l(_vm.gridItems, function(gridItem) {
          return _c("GridItem", {
            key: "" + gridItem.type + gridItem.document._id,
            attrs: {
              product: gridItem.product,
              type: gridItem.type,
              selected: _vm.selectedItems.includes(gridItem.document._id),
              loading: _vm.loadingItems.includes(gridItem.document._id),
              transitionOnOpen: _vm.transitionOnOpen,
              savedVehicles: _vm.savedVehicles,
              isPrivateStore: _vm.isPrivateStore
            },
            on: {
              select: function($event) {
                return _vm.onItemClick(gridItem)
              }
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.gridItems.length
        ? _c("VcIntersectObserver", {
            attrs: { options: { rootMargin: "200px" } },
            on: { intersect: _vm.onIntersect }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }