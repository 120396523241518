var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("NestedOptionBox", {
    attrs: {
      selected: _vm.option.selected,
      heading: _vm.option.heading,
      smallText: _vm.option.headingMeta,
      expandText: _vm.option.subOptionsText,
      modalContent: _vm.option.modalContent,
      ribbonText: _vm.ribbonText,
      price: _vm.option.price,
      checkbox: _vm.multiselect,
      subOptions: _vm.option.subOptions,
      image: _vm.option.image,
      largeImage: _vm.option.largeImage,
      summationImage: _vm.option.summationImage
    },
    on: { click: _vm.onClick, subOptionChange: _vm.onSubOptionChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }