import Cookies from "js-cookie";
import Breakpoints from "../constants/Breakpoints";

export default {
  init() {
    this.modifyDOM();
    this.addEvents();
  },

  addEvents() {
    $("body").on(
      "click",
      ".alert-bar__close",
      this.setSiteAlertsClass.bind(this)
    );
  },

  modifyDOM() {
    setTimeout(this.setSiteAlertsClass, 300);
  },

  setSiteAlertsClass() {
    const closedAlertBars = Cookies.getJSON("closed-alert-bars") || [];
    const alertBars = $(".alert-bar");
    const siteUspsExist = $(".site-usps").length > 0;

    const largeSiteAlert = $(".site-alerts.large");

    const activeClosedAlertBars = alertBars
      .map(function() {
        const data = $(this).data("id");
        return closedAlertBars.includes(data);
      })
      .get();

    const hasActiveAlertBars = activeClosedAlertBars.includes(false);
    window.hasSiteAlerts = siteUspsExist || hasActiveAlertBars;

    const hasSiteAlertSelector =
      largeSiteAlert.length > 0 ? "has-large-site-alerts" : "has-site-alerts";

    if (
      siteUspsExist ||
      ($(".site-alerts:visible").length && hasActiveAlertBars)
    ) {
      $("body").addClass(hasSiteAlertSelector);
    } else {
      $("body").removeClass(hasSiteAlertSelector);
    }
  }
};
