<template>
  <div class="product-upsell" v-if="hasSelectableOptions()">
    <div class="swiper-container product-upsell__container" ref="swiperContainer">
      <div class="swiper-wrapper">
        <div
          v-for="option in optionsWithPrice.filter(opt => !opt.hidden)"
          :key="option.id"
          class="product-upsell__item swiper-slide">
          <div class="product-upsell__content-header">
            <div v-if="option.headingMeta" class="ribbon ribbon--attention ribbon--right">{{ option.headingMeta }}</div>
            <div>
              <div class="product-upsell__content-header__text">{{ option.heading }}</div>
              <VcPriceTag :price="option.price"/>
            </div>
            <img
              v-if="option.image"
              :src="option.image"
              class="product-upsell__content-header__image product-upsell__content-header__image--interactive"
              @click="onOpenImageGallery(option.id)">
          </div>
          <div class="product-upsell__content" v-html="option.modalContent"></div>
          <a
            :class="{
              'btn btn--primary btn--icon btn--sm btn--block': option.selected,
              'btn btn--white btn--icon btn--sm btn--block': !option.selected,
            }" 
            @click="onAddClick(option)">
            {{ option.selected ? 'Vald' : 'Lägg till' }}
            <FontAwesomeIcon :icon="option.selected ? faCheck : faPlus"></FontAwesomeIcon>
          </a>
          <div v-if="option.productUrl" class="product-upsell__link-btn">
            <i class="far fa-shopping-cart link--icon"></i>
            <a
              class="link link--underline link--icon" 
              :href="option.productUrl">
              {{ lang.VehiclePage.TyreBuyActionButton }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <ImageGallery
      v-if="imageGalleryOpen"
      :images="selectedImages"
      @close="onCloseImageGallery"
    ></ImageGallery>
  </div>
</template>
<script>

import { VcPriceTag } from '@holmgrensbil/vue-components';
import Swiper from '../../swiper';
import Breakpoints from '../../constants/Breakpoints';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheck,
  faPlus
} from "@fortawesome/pro-light-svg-icons";
import ImageGallery from '../shared/ImageGallery.vue';
import EonApi from '../../models/EonApi';

export default {
  components: {
    VcPriceTag,
    FontAwesomeIcon,
    ImageGallery,
  },
  props: {
    options: Array,
    heading: String,
    selectedFinance: String,
    lang: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      swiper: null,
      faPlus,
      faCheck,
      imageGalleryOpen: false,
      selectedImages: [],
    }
  },
  computed: {
    optionsWithPrice() {
      return this.options.map(option => {
        const selectedFinanceData = option.financing[this.selectedFinance];
        if (this.selectedFinance === 'cash') {
          return {
            price: {
              current: selectedFinanceData.currentPrice,
              ordinary: selectedFinanceData.ordinaryPrice,
              suffix: ' kr',
            },
            ...option,
          }
        } else {
          return {
            price: {
              current: selectedFinanceData.monthlyCost,
              ordinary: selectedFinanceData.ordinaryMonthlyCost,
              suffix: ' kr/månad',
            },
            ...option,
          }
        }
      })
    }
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiperContainer, {
      slidesPerView: 2,
      spaceBetween: 40,
      autoHeight: true,
      breakpoints: {
        [Breakpoints.md - 2]: {
          slidesPerView: 'auto',
          spaceBetween: 8,
        },
      },
    });
  },
  methods: {
    onAddClick(option) {
      this.$emit('optionChange', option)
    },
    hasSelectableOptions() {
      return this.options.filter(opt => !opt.hidden).length > 0;
    },
    async onOpenImageGallery(id) {
      const res = await EonApi.getProductImages(id);
      const product = res.data?.products[0];
      this.selectedImages = product && product.images.map(image => image.webshop_sidebar) || [];
      if (this.selectedImages.length > 0) {
        this.imageGalleryOpen = true;
      }
    },
    onCloseImageGallery() {
      this.imageGalleryOpen = false;
      this.selectedImages = [];
    }
  },
}
</script>