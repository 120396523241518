var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.displaySections, function(section) {
      return _c(_vm.getSectionComponent(section), {
        key: section.type,
        ref: _vm.getSectionRefName(section),
        refInFor: true,
        tag: "component",
        attrs: {
          section: section,
          SectionTypeComponents: _vm.SectionTypeComponents,
          hideFooter:
            section.order === _vm.sectionsMaxOrder || section.hideFooter,
          footerLabel: "Steg " + section.order + " av " + _vm.sectionsMaxOrder
        },
        on: {
          done: function($event) {
            return _vm.onSectionDone(section)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }