<template>
  <div class="properties properties--borders">
    <div v-for="fee in cart.additionalFees"
      :key="fee.id"
      class="properties__item properties__item--header">
      <div class="properties__label">
        {{fee.name}} <FontAwesomeIcon @click="onAdditionalFeeClick(fee)" :icon="faQuestionCircle"></FontAwesomeIcon>
      </div>
      <div class="properties__value">
        <VcPriceTag :vatPercentage="fee.vatPercentage" :price="fee.price" hideOrdinaryPriceIfDiscount></VcPriceTag>
      </div>
    </div>
    <div v-if="cart.totalPromotions && cart.totalPromotions.current"
      class="properties__item properties__item--header">
      <div class="properties__label">
        Rabatter på detta köp
      </div>
      <div class="properties__value">
        <div class="price-tag price-tag--discount">
          -{{$formatPrice(cart.totalPromotions.current)}}
        </div>
      </div>
    </div>
    <div class="properties__item properties__item--header">
      <div class="properties__label">
        Totalkostnad

        <div class="properties__meta properties__meta--muted-light">
          (inkl. {{ $formatPrice(cart.totalVat) }} moms)
        </div>
      </div>
      <div class="properties__value" v-if="totalPrice">
        <VcPriceTag :price="totalPrice" total></VcPriceTag>
        <div class="properties__meta properties__meta--muted-light">
          Avbetalning från {{ $formatPrice(lowestPartPaymentPrice) }}/mån
        </div>
      </div>
    </div>

    <VcModal v-if="modalOpen" @close="modalOpen = false">
      <div slot="header">Info</div>
      <div slot="content" v-html="modalContent"></div>
    </VcModal>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { mapState, mapGetters } from 'vuex';
import { VcPriceTag, VcModal } from '@holmgrensbil/vue-components';
import CartApi from '../../../models/CartApi';

export default {
  components: {
    VcPriceTag,
    FontAwesomeIcon,
    VcModal,
  },

  data() {
    return {
      faQuestionCircle,
      modalContent: null,
      modalOpen: false,
    };
  },
  computed: {
    ...mapState({
      cart: state => state.cart.cart,
    }),
    totalPrice() {
      if (this.cart.totalPrice) {
        const totalCurrentPrice = this.cart.totalPrice.current + this.cart.totalVat;
        const totalOrdinaryPrice = this.cart.totalPrice.ordinary + this.cart.totalVat;
        return {
          ...this.cart.totalPrice,
          current: totalCurrentPrice,
          ordinary: totalOrdinaryPrice,
        }
      }
      return {};
    },

    lowestPartPaymentPrice() {
      if(this.totalPrice && this.totalPrice.current) {
        const { annuityFactors } = window.EonConfig;
        const lowestAnnuityFactor = Math.min(...(annuityFactors.map(i => i.factor)));
        return (lowestAnnuityFactor * this.totalPrice.current).toFixed(0);
      }
    },
  },

  methods: {
    async onAdditionalFeeClick(fee) {
      try {
        const res = await CartApi.fetchAdditionalService(fee.productId);
        if(res && res.data) {
          this.modalContent = res.data.modalContent;
          this.modalOpen = true;
        }
      } catch(e) {
        console.log(e);
      }
    }
  }
}
</script>
