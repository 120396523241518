<template>
  <div>
    <CartItem v-for="line in lineParents"
      :key="line.id"
      :line="line"
      :lineChildren="lineChildrenByParent(line.id)"
      :currentRegNr="currentRegNr"
      @quantity="onQuantity"
      @remove="onRemove">
    </CartItem>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import TyreSpec from '../../../models/TyreSpec';
import CartItem from './CartItem.vue';
import CartPromotionCode from './CartPromotionCode.vue';
import gtm from '../../../utils/gtm';
import GtmEvents from '../../../constants/GtmEvents';
import LineItemCustomDataKeys from '../../../constants/LineItemCustomDataKeys';
import Cookies from "js-cookie";

export default {
  components: {
    CartItem,
    CartPromotionCode,
  },
  props: {
    isAccessoryCart: Boolean,
  },
  data() {
    return {
      currentRegNr: TyreSpec.regNr,
    };
  },
  mounted() {
    if (this.isAccessoryCart) {
      const regNr = Cookies.get('AccessoryRegNr');
      this.currentRegNr = regNr;
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart.cart,
    }),

    ...mapGetters({
      lineParents: 'cart/lineParents',
      lineChildrenByParent: 'cart/lineChildrenByParent',
    }),
  },
  methods: {
    ...mapActions({
      setQuantity: 'cart/setQuantity',
    }),

    getEventObject(line, newQuantity) {
      let addOrRemove;
      let event;
      let quantityDiff;

      if (line.quantity >=  newQuantity) {
        addOrRemove = 'remove';
        event = GtmEvents.RemoveFromCart;
        quantityDiff = line.quantity -  newQuantity;
      } else if (line.quantity <  newQuantity) {
        addOrRemove = 'add';
        event = GtmEvents.AddToCart;
        quantityDiff =  newQuantity - line.quantity;
      }

      const brandCustomData = line.customData.find(l => l.name === LineItemCustomDataKeys.Brand);
      const variantCustomData = line.customData.find(l => l.name === LineItemCustomDataKeys.Variant);
      const data = {
        'currencyCode': 'SEK',
        'value': Math.round(line.price.current * (1 + (line.vatPercentage / 100))) * quantityDiff,
        [addOrRemove]: {
          'products': [{
            name: line.name,
            id: line.productId,
            price: Math.round(line.price.current * (1 + (line.vatPercentage / 100))),
            brand: brandCustomData && brandCustomData.value,
            category: line.productType,
            quantity: quantityDiff,
            variant: variantCustomData && variantCustomData.value,
          }]
        }
      }

      return {
        event,
        data,
      }
    },

    onQuantity({ line, quantity }) {
      this.setQuantity({ id: line.id, quantity });

      const { event, data } = this.getEventObject(line, quantity)
      gtm.ecommerceEvent(event, data)
    },

    onRemove({ line }) {
      this.setQuantity({ id: line.id, quantity: 0 });

      const { event, data } = this.getEventObject(line, 0)
      gtm.ecommerceEvent(event, data)
    },
  },
}
</script>
