<template>
  <div class="wizard wizard--service-booking">
    <SectionList
      :sections="sections"
      :SectionTypeComponents="SectionTypeComponents"
      @completed="onSectionCompleted">
    </SectionList>
    <FormSubmit
      :lastSection="lastSection"
      requireTerms
      @completed="onSectionCompleted">
      <div class="form-group form-check">
        <input
          class="form-check__input"
          type="checkbox"
          :checked="newsletterField.value === true"
          @change="onNewsletterChange($event.target.checked)" />
        <div class="form-check__label" @click="onNewsletterChange(!newsletterField.value)">
          {{newsletterField.label}}
        </div>
      </div>
    </FormSubmit>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SectionTypes from './constants/SectionTypes';
import FieldTypes from './constants/FieldTypes';
import SectionList from '../shared/wizard/SectionList.vue';
import FormSubmit from '../shared/wizard/FormSubmit.vue';
import SectionTypeComponents from './constants/SectionTypeComponents';
import gtm from '../../utils/gtm';

export default {
  components: {
    SectionList,
    FormSubmit,
  },
  props: {
    regNr: String,
  },
  data() {
    return {
      SectionTypeComponents,
    };
  },
  created() {
    this.setField({ type: FieldTypes.RegNr, value: this.regNr });
  },
  computed: {
    ...mapState({
      sections: state => state.sections.items,
    }),

    ...mapGetters({
      fieldByType: 'fields/byType',
      sectionByType: 'sections/byType',
    }),

    lastSection() {
      return this.sectionByType(SectionTypes.Addons);
    },

    newsletterField() {
      return this.fieldByType(FieldTypes.Newsletter);
    },
  },
  methods: {
    ...mapActions({
      fetchServices: 'services/fetch',
      fetchDepartments: 'departments/fetch',
      fetchAddons: 'addons/fetch',
      fetchIsExternal: 'isExternal/fetch',
      fetchBlockedDays: 'blockedDays/fetch',
      setField: 'fields/set',
    }),

    onNewsletterChange(checked) {
      this.setField({ type: this.newsletterField.type, value: checked });
    },

    onSectionCompleted(section) {
      const brand = this.fieldByType(FieldTypes.Brand).value;
      const department = this.fieldByType(FieldTypes.Department).value;
      const departmentNr = department && department.value;
      const servicesValue = this.fieldByType(FieldTypes.Services).value || [];
      const services = servicesValue.map(service => service.name);

      if (section.type === SectionTypes.Lookup) {
        this.fetchServices({ brand });
      }

      if (section.type === SectionTypes.Services) {
        this.fetchDepartments({ brand, services });
      }

      if (section.type === SectionTypes.Department) {
        this.fetchBlockedDays({ departmentNr });
        this.fetchAddons({ brand, departmentNr, services });
        this.fetchIsExternal({ brand, departmentNr, services });
      }

      gtm.serviceBookingSectionCompleted({ section, departmentNr });
    },
  },
}
</script>
