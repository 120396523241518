<template>
  <div>
    <h3>
      Rekommenderade storlekar till {{carName}}
    </h3>
    <div>
      <div v-for="(size, index) in sizes"
        :key="index"
        :class="{'tab-button--active': isSelected(size)}"
        class="tab-button tab-button--horizontal tab-button--inline"
        @click="onSelect(size)">
        {{getSizeString(size)}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    carName: String,
    tyreSize: Object,
    sizes: Array,
  },
  methods: {
    getSizeString(size) {
      return `${size.diameter}" ${size.width}/${size.aspectRatio}R${size.diameter}`;
    },

    isSelected(size) {
      const {
        width,
        aspectRatio,
        diameter,
      } = this.tyreSize;

      return width === size.width &&
             aspectRatio === size.aspectRatio &&
             diameter === size.diameter;
    },

    onSelect(size) {
      this.$emit('change', {
        width: size.width,
        aspectRatio: size.aspectRatio,
        diameter: size.diameter,
      });
    },
  },
}
</script>
