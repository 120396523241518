<template>
  <div
    class="configurator-select"
    :class="{
      'configurator-select__dropdown-open': dropdownOpen,
    }"
  >
    <div class="configurator-select__overlay" v-if="dropdownOpen" @click="dropdownOpen = false"></div>
    <div class="configurator-select__button">
      <div v-if="heading" class="configurator-select__heading">{{ heading }}</div>
      <div class="configurator-select__container">
        <div class="configurator-select__input" @click="openDropdown">
          <div class="configurator-select__input__value">
            {{ selectedValue && selectedValue.name || placeholder }}
          </div>
          <div class="configurator-select__input__icon-container">
            <div v-if="isDepartmentSelect && selectedValue" class="configurator-select__availability-container">
              <div
                class="delivery-indicator"
                :class="{
                  'delivery-indicator--available': options[0].value === selectedValue.value,
                  'delivery-indicator--unavailable': options[0].value !== selectedValue.value,
                }"
              ></div>
              <div>{{ options[0].value === selectedValue.value ? 'Omgående' : '3-7 dagar' }}</div>
            </div>
            <font-awesome-icon class="configurator-select__input__icon" :icon="faSort"></font-awesome-icon>
          </div>
        </div>
        <div
          class="configurator-select__dropdown"
          :class="{
            'configurator-select__dropdown--open': dropdownOpen,
          }"
        >
          <div v-if="dropdownOpen">
            <div v-if="isDepartmentSelect">
              <ConfiguratorDepartmentDropdownItem
                v-for="(option, index) in options"
                :key="option.name + '-department-item'"
                :option="option"
                :selected="selectedValue && option.value === selectedValue.value"
                :available="index === 0"
                @click="onItemClick"
              />
            </div>
            <div v-else>
              <ConfiguratorSimpleDropdownItem
                v-for="option in options"
                :key="option.name + '-simple-item'"
                :option="option"
                :selected="selectedValue && option.value === selectedValue.value"
                @click="onItemClick"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';
import ConfiguratorSimpleDropdownItem from './ConfiguratorSimpleDropdownItem.vue';
import ConfiguratorDepartmentDropdownItem from './ConfiguratorDepartmentDropdownItem.vue';
import { faSort } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    VcFormStack,
    ConfiguratorSimpleDropdownItem,
    ConfiguratorDepartmentDropdownItem,
    FontAwesomeIcon,
},
  props: {
    options: Array,
    heading: String,
    defaultValue: Number | String,
    isDepartmentSelect: Boolean,
    defaultPlaceholder: String,
  },
  data() {
    return {
      selectedValue: null,
      dropdownOpen: false,
      faSort,
    }
  },
  created() {
    this.selectedValue = this.options.find(value => value.value === this.defaultValue) || null;
  },
  watch: {
    defaultValue() {
      this.selectedValue = this.options.find(value => value.value === this.defaultValue) || null;
    },
  },
  computed: {
    placeholder() {
      return this.defaultPlaceholder || 'Välj';
    }
  },
  methods: {
    openDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    onItemClick(value) {
      this.selectedValue = value;
      this.$emit('change', value);
      this.dropdownOpen = false;
    },
  }
}
</script>
