import Axios from '../../../../axios';
import RequestStates from '../../../../constants/RequestStates';

const state = {
  endpoint: '',
  submitState: null,
};

const getters = {};

const actions = {
  load({ commit }, endpoint) {
    commit('received', endpoint);
  },

  async submit({ commit, state }, data) {
    commit('submitRequest');
    try {
      await Axios.post(state.endpoint, data);
      commit('submitSuccess');
      return true;
    } catch (error) {
      commit('submitFailure', { error });
      return false;
    }
  },
};

const mutations = {
  received(state, endpoint) {
    state.endpoint = endpoint;
  },

  submitRequest(state) {
    state.submitState = RequestStates.Request;
  },

  submitSuccess(state) {
    state.submitState = RequestStates.Success;
  },

  submitFailure(state, { error }) {
    state.submitState = RequestStates.Failure;
    console.error(error);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
