var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-color",
      class: { "product-color--selected": _vm.filter.selected },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$emit("select", _vm.filter)
        }
      }
    },
    [
      _vm.colorClass
        ? _c("div", {
            staticClass: "product-color__gradient",
            class: ["product-color__gradient--" + _vm.colorClass]
          })
        : _vm.filter.displayName === "Flerfärgad"
        ? _c("div", {
            staticClass:
              "product-color__gradient product-color__gradient--multi-colored"
          })
        : _c("i", { staticClass: "fas fa-question product-color__icon" }),
      _vm._v(" "),
      _c("div", { staticClass: "product-color__name" }, [
        _vm._v("\n    " + _vm._s(_vm.filter.displayName) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }