export default class I3DocFacetPage {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc._id;
  }

  get name() {
    return this._doc.name;
  }

  get permalink() {
    return this._doc.url;
  }
}
