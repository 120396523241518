var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TyreBuildSectionWrapper",
    {
      attrs: { selected: _vm.selected, isAddon: _vm.isAddon, title: _vm.title }
    },
    [
      _c("div", { staticClass: "build-tyre__content-container" }, [
        _c("div", { staticClass: "build-tyre__main" }, [
          !_vm.mobile
            ? _c(
                "div",
                {
                  staticClass: "build-tyre__main__image",
                  class: {
                    "build-tyre__main__image--placeholder": !_vm.product
                  }
                },
                [
                  _vm.product && _vm.product.imageUrl
                    ? _c("img", { attrs: { src: _vm.product.imageUrl } })
                    : _c("img", {
                        attrs: { src: _vm.image, height: "160", width: "160" }
                      })
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "build-tyre__footer" }, [
          _c("div", { staticClass: "build-tyre__footer__content" }, [
            _c("div", [
              _c("div", { staticClass: "build-tyre__footer__type" }, [
                _vm._v("\n            " + _vm._s(_vm.label) + "\n            "),
                _vm.product && _vm.product.shortConditionType
                  ? _c("span", { staticClass: "meta-tag black rounded" }, [
                      _vm._v(_vm._s(_vm.product.shortConditionType))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.product
                ? _c("div", { staticClass: "build-tyre__vehicle-data" }, [
                    _c(
                      "div",
                      { staticClass: "build-tyre__vehicle-data__specs" },
                      [
                        _c("EonProductTitle", {
                          attrs: { product: _vm.product }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "build-tyre__vehicle-data__price" },
                      [
                        _c("div", { staticClass: "price-tag" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$formatPrice(_vm.product.totalPriceWithVat)
                              ) +
                              "/st\n              "
                          )
                        ])
                      ]
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "build-tyre__vehicle-data__empty" },
                    [_vm._v("\n            Ej valt\n          ")]
                  )
            ]),
            _vm._v(" "),
            _vm.mobile
              ? _c(
                  "div",
                  {
                    staticClass:
                      "build-tyre__main__image build-tyre__main__image--mobile",
                    class: {
                      "build-tyre__main__image--placeholder": !_vm.product
                    }
                  },
                  [
                    _vm.product && _vm.product.imageUrl
                      ? _c("img", { attrs: { src: _vm.product.imageUrl } })
                      : _c("img", {
                          attrs: { src: _vm.image, height: "40", width: "40" }
                        })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "build-tyre__footer__action",
              class: {
                "build-tyre__footer__action--with-selector": _vm.product
              }
            },
            [
              _vm.product
                ? _c(
                    "VcFormStack",
                    { attrs: { label: "Antal", small: "" } },
                    [
                      _c("VcFormSelect", {
                        attrs: {
                          options: _vm.quantityOptions,
                          value: _vm.quantity,
                          labelProp: "name",
                          valueProp: "value",
                          valueType: "number",
                          small: ""
                        },
                        on: { change: _vm.onQuantityChange }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn--muted btn--block",
                  on: {
                    click: function($event) {
                      return _vm.$emit("onSelect", _vm.step)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        "Välj" + _vm.buttonFiller + this.label.toLowerCase()
                      ) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }