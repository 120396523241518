import Axios from '../axios';

export default {
  async fetchServices() {
    return await Axios.get(`/api/services`);
  },
  async fetchCarInfo({ regNr }) {
    let config = {
      headers: {
        ApiKey: "WXRpJoXqZ3"
      }
    }
    return await Axios.get(`https://fordonsapi.azurewebsites.net/api/vehicle/${regNr}`, config);
  },
  async fetchDepartments({regNr, service}) {
    return await Axios.get(`/api/services/${service}/departments?regNr=${regNr}`);
  },
  async fetchAvailableDates({departmentNr, service, addons, regNr, serviceType}) {
    const regNrQuery = `?regNr=${ regNr }`;
    const addonsQuery = addons ? `&options=${ addons }` : '';
    const serviceTypeQuery = serviceType ? `&services=${ serviceType }` : '';
    return await Axios.get(`/api/services/${ service }/departments/${departmentNr}/days${regNrQuery}${addonsQuery}${serviceTypeQuery}`);
  },
  async fetchAvailableTimes({departmentNr, service, date, addons, regNr, serviceType}) {
    const regNrQuery = `?regNr=${ regNr }`;
    const addonsQuery = addons ? `&options=${ addons }` : '';
    const serviceTypeQuery = serviceType ? `&services=${ serviceType }` : '';

    return await Axios.get(`/api/services/${ service }/departments/${departmentNr}/days/${ date }/times${regNrQuery}${addonsQuery}${serviceTypeQuery}`);
  },
  async fetchDepartmentExpress({departmentNr, service}) {
    return await Axios.get(`/api/services/${service}/departments/${departmentNr}`);
  },
  async fetchAddons({ departmentNr, service, regNr, mileage, amount, serviceType }) {
    const mileageQuery = mileage ? `&mileage=${mileage}` : ''
    const amountQuery = amount ? `&amount=${amount}` : ''
    return await Axios.get(`/api/services/${service}/departments/${departmentNr}/addons?regNr=${regNr}${mileageQuery}${amountQuery}&services=${ serviceType }`)
  },
  async validateCode({ departmentNr, service, regNr, code }) {
    return await Axios.get(`/api/services/${service}/departments/${departmentNr}/campaigncode/${code}?regNr=${regNr}`)
  },
  async fetchServiceTypes({ departmentNr, regNr, service }) {
    return await Axios.get(`/api/services/${service}/departments/${departmentNr}/services?regNr=${regNr}`)
  },
  async fetchTyreServices({ regNr, departmentNr, service }) {
    return await Axios.get(`/api/services/${ service }/departments/${ departmentNr }/services?regNr=${ regNr }`)
  },
  async fetchRimRepairTypes({ inch }) {
    return await Axios.get(`/api/services/rim-repair/types/${ inch }`)
  },
  async fetchVehicle({ regNr }) {
    return await Axios.get(`https://serviceagreement.azurewebsites.net/api/vehicle/${regNr}`);
  },
  async fetchBrands() {
    return await Axios.get(`https://serviceagreement.azurewebsites.net/api/brands`);
  },
  async fetchModels(brandId) {
    return await Axios.get(`https://serviceagreement.azurewebsites.net/api/models?brandId=${brandId}`);
  },
  async fetchEngines(modelId) {
    return await Axios.get(`https://serviceagreement.azurewebsites.net/api/engines?modelId=${modelId}`);
  },
  async fetchVehicleWithIds(brandId, modelId, engineId) {
    return await Axios.get(`https://serviceagreement.azurewebsites.net/api/vehicle?brandId=${brandId}&modelId=${modelId}&engineId=${engineId}`);
  } 
}
