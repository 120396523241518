import RequestStates from "../../../../constants/RequestStates";
import CartApi from "../../../../models/CartApi";
import Axios from "../../../../axios";

const state = {
  items: [],
  fetchState: {},
  selectState: null,
  selected: null,
  departments: [],
  fetchDepartmentState: {},
  tyreHotelOrder: false
};

const getters = {
  byDepartmentNr: ({ items }) => departmentNr => {
    return items.find(d => d.departmentNr === departmentNr);
  },
  byDestinationSiteId: ({ items }) => destinationSiteId => {
    return items.find(
      d => d.destinationSiteId === parseInt(destinationSiteId, 10)
    );
  },
  departmentByDepartmentNr: ({ departments }) => departmentNr => {
    return departments.find(d => d.departmentNr === departmentNr);
  }
};

const actions = {
  async fetch({ commit }) {
    commit("fetchRequest");

    try {
      const res = await CartApi.fetchPickup();
      commit("fetchSuccess", res.data);
    } catch (error) {
      commit("fetchFailure", { error });
    }
  },

  select({ commit, dispatch }, pickup) {
    commit("selected", pickup);

    if (!pickup) {
      return;
    }

    dispatch("fetchDepartment", pickup);
  },

  setTyreHotelOrder({ commit }, { tyreHotelOrder }) {
    commit("setTyreHotelOrder", tyreHotelOrder);
  },

  async fetchDepartment({ commit, state }, { departmentNr, service }) {
    if (state.departments.find(d => d.id === departmentNr)) {
      return;
    }

    commit("fetchDepartmentRequest");

    try {
      const data = {
        departmentNr,
        service
      };
      const res = await Axios.post("/api/Contact/GetContactDepartment", data);
      res.data.id = departmentNr; // why no id in response? :(
      commit("fetchDepartmentSuccess", res.data);
    } catch (error) {
      commit("fetchDepartmentFailure", { error });
    }
  }
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = {
      state: RequestStates.Request
    };
  },

  fetchSuccess(state, items) {
    state.items = items;
    state.fetchState = {
      state: RequestStates.Success
    };
  },

  fetchFailure(state) {
    state.fetchState = {
      state: RequestStates.Failure
      // message: error.response.data && error.response.data.message,
    };
  },

  selected(state, pickup) {
    state.selected = pickup;
  },

  setTyreHotelOrder(state, isTyreHotelOrder) {
    state.tyreHotelOrder = isTyreHotelOrder;
  },

  fetchDepartmentRequest(state) {
    state.fetchDepartmentState = RequestStates.Request;
  },

  fetchDepartmentSuccess(state, department) {
    state.fetchDepartmentState = RequestStates.Success;
    state.departments = [...state.departments, department];
  },

  fetchDepartmentFailure(state, { error }) {
    state.fetchDepartmentState = RequestStates.Failure;
    console.error(error);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
