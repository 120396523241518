var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-promo" },
    [
      _c(
        "a",
        {
          staticClass: "link link--underline link--muted-light cart-promo__cta",
          on: {
            click: function($event) {
              _vm.active = !_vm.active
            }
          }
        },
        [_vm._v("\n    Har du en rabattkod?\n  ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.promotionCodes, function(promo) {
        return _c("div", { key: promo.code, staticClass: "cart-promo__code" }, [
          _c("span", [
            _vm._v(
              "\n      Rabattkod " + _vm._s(promo.code) + " aktiverad\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "link link--underline link--muted-light",
              on: { click: _vm.onRemove }
            },
            [_vm._v("\n      Ta bort\n    ")]
          )
        ])
      }),
      _vm._v(" "),
      _vm.active
        ? _c(
            "form",
            {
              staticClass: "cart-promo__form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onApply($event)
                }
              }
            },
            [_vm._m(0)]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group" }, [
      _c("input", {
        staticClass: "form-control form-control--sm",
        attrs: { type: "text", placeholder: "Rabattkod", required: "" }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--primary btn--sm",
            attrs: { type: "submit" }
          },
          [_vm._v("\n          Aktivera\n        ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }