export default {
  DisplayPhoneNumber: 'displayPhoneNumber',
  ClickContact: "clickContact",
  ClickMoreInfo: "clickMoreInfo",
  ClickNavigation: "clickNavigation",
  ClickPhoneNumber: "clickPhoneNr",
  ClickMessage: "clickMessage",
  ClickReview: "clickReview",
  ClickReportLink: "clickReportLink",
  ClickSimilarVehicle: "clickSimilarVehicle",
  GetReport: "getReport",
  ClickVehicleDeclaration: "clickVehicleDeklaration",
  GetContactBox: "getContactBox",
  LoadQuickbuy: "loadQuickBuy",
  SubmitBooking: "submitBooking",
  SubmitExternalBooking: "submitExternalBooking",
  SubmitCallBack: "submitCallBack",
  SubmitPurchase: "submitPurchase",
  SubmitCarImage: "submitCarImage",
  SubmitCarInfo: "submitCarInfo",
  SubmitContactInfo: "submitContactInfo",
  SubmitDate: "submitDate",
  SubmitDepartment: "submitDepartment",
  SubmitEmail: "submitEmail",
  SubmitMileage: "submitMileage",
  SubmitOptional: "submitOptional",
  SubmitOptions: "submitOptions",
  SubmitRegNr: "submitRegNr",
  SubmitSearchWatch: "submitSearchWatch",
  SubmitServiceAgrement: "submitServiceAgrement",
  SubmitServices: "submitServices",
  SubmitAddons: "submitAddons",
  SubmitTradeIn: "submitTradeIn",
  SubmitQuickTradeIn: "submitQuickTradeIn",
  SubmitVoucher: "submitVoucher",
  SubmitSummary: "submitSummary",
  SubmitInfo: "submitInfo",
  SubmitSparePartsDetails: "submitSparePartsDetails",
  SubmitSparePartsForm: "submitSparePartRequest",
  SubmitRimRepairSizeDetails: "submitRimRepairSizeDetails",
  SubmitRimRepairTypeDetails: "submitRimRepairTypeDetails",
  SubmitRimRepairForm: "submitRimRepairRequest",
  SaveContact: "saveContact",
  Share: "share",
  ConfirmValuation: "confirmValuation"
};
