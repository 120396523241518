<template>
  <div>
    <h3>
      Fyll i dina däckdimensioner
    </h3>
    <VisualTyre :text="tyreText" :key="tyreText">
    </VisualTyre>
    <div class="choose-dimensions__form">
      <VcFormStack label="Bredd">
        <VcFormSelect
          :options="widthOptions"
          :value="tyreSize.width"
          prompt="Välj bredd"
          @change="selectWidth">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Profil">
        <VcFormSelect
          :options="aspectRatioOptions"
          :value="tyreSize.aspectRatio"
          prompt="Välj profil"
          @change="selectAspectRatio">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Dimension">
        <VcFormSelect
          :options="diameterOptions"
          :value="tyreSize.diameter"
          prompt="Välj dimension"
          @change="selectDiameter">
        </VcFormSelect>
      </VcFormStack>
    </div>
  </div>
</template>

<script>
import { VcFormSelect, VcFormStack } from '@holmgrensbil/vue-components';
import VisualTyre from './VisualTyre.vue';
import TyreSizes from '../../constants/TyreSizes';

export default {
  components: {
    VcFormStack,
    VcFormSelect,
    VisualTyre,
  },
  props: {
    tyreSize: Object,
  },
  data() {
    return {
      circleType: null,
      widthOptions: TyreSizes.Widths,
      aspectRatioOptions: TyreSizes.AspectRatios,
      diameterOptions: TyreSizes.Diameters,
    };
  },
  computed: {
    tyreText() {
      let {
        width,
        aspectRatio,
        diameter,
      } = this.tyreSize;

      width = width || '215';
      aspectRatio = aspectRatio || '55';
      diameter = diameter || '16';

      return `${width}/${aspectRatio}R/${diameter}91V`;
    },
  },
  methods: {
    selectWidth(width) {
      this.onChange({ width });
    },

    selectAspectRatio(aspectRatio) {
      this.onChange({ aspectRatio });
    },

    selectDiameter(diameter) {
      this.onChange({ diameter });
    },

    onChange(props) {
      this.$emit('change', {
        ...this.tyreSize,
        ...props,
      });
    },
  },
}
</script>
