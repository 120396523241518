var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order
    ? _c(
        "div",
        { staticClass: "tyre-order" },
        [
          _vm._l(_vm.order.items, function(item) {
            return _c(
              "div",
              { key: item.id, staticClass: "cart-item" },
              [
                _c("div", { staticClass: "tyre-order__order-item" }, [
                  _c(
                    "div",
                    { staticClass: "tyre-order__order-item__main-data" },
                    [
                      _c("div", { staticClass: "cart-item__product" }, [
                        item.imageUrl
                          ? _c(
                              "div",
                              { staticClass: "cart-item__product-thumbnail" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.imageUrl,
                                    alt: "Produktbild"
                                  }
                                })
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "cart-item__product-main" }, [
                          _c(
                            "div",
                            { staticClass: "cart-item__product-meta" },
                            [
                              _c("label", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.type) +
                                    "\n              "
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cart-item__product-name" },
                            [
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v(_vm._s(item.title))
                              ]),
                              _vm._v(" "),
                              _c("a", { attrs: { href: "#" } }, [
                                _vm._v(_vm._s(item.description))
                              ])
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "tyre-order__stats-container" }, [
                    _c("div", {
                      staticClass:
                        "tyre-order__stats-container__cell tyre-order__stats-container__cell__divider"
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tyre-order__stats-container__cell tyre-order__stats-container__cell--right-aligned"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "tyre-order__stats-container__quantity-text"
                          },
                          [_vm._v(_vm._s(item.quantity) + " st")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cart-item__line-price tyre-order__stats-container__cell"
                      },
                      [
                        _c("VcPriceTag", {
                          attrs: {
                            price: item.totalPrice,
                            showIfZero: "",
                            hideOrdinaryPriceIfDiscount: ""
                          }
                        }),
                        _vm._v(" "),
                        _c("VcPriceTag", {
                          attrs: {
                            price: item.price,
                            hideOrdinaryPriceIfDiscount: ""
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._l(item.additionalItems, function(line) {
                  return _c(
                    "div",
                    { key: line.id, staticClass: "tyre-order__order-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "tyre-order__stats-container__cell" },
                        [
                          _c("label", { staticClass: "cart-item__line-meta" }, [
                            _vm._v(
                              "\n          " + _vm._s(line.type) + "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cart-item__line-name" }, [
                            _vm._v(
                              "\n          " + _vm._s(line.title) + "\n        "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tyre-order__stats-container__cell" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "tyre-order__stats-container__quantity-text"
                            },
                            [_vm._v(_vm._s(line.quantity) + " st")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tyre-order__stats-container__cell cart-item__line-price"
                        },
                        [
                          _c("VcPriceTag", {
                            attrs: {
                              price: line.totalPrice,
                              showIfZero: "",
                              hideOrdinaryPriceIfDiscount: ""
                            }
                          }),
                          _vm._v(" "),
                          _c("VcPriceTag", {
                            attrs: {
                              price: line.price,
                              hideOrdinaryPriceIfDiscount: ""
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                })
              ],
              2
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "tyre-order__spec" }, [
            _c("div", { staticClass: "tyre-order__spec__row" }, [
              _c("span", { staticClass: "tyre-order__spec__row__title" }, [
                _vm._v("DATUM")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "tyre-order__spec__row__data" }, [
                _vm._v(_vm._s(_vm.order.dateOfPurchase))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tyre-order__spec__row" }, [
              _c("span", { staticClass: "tyre-order__spec__row__title" }, [
                _vm._v("BETALSÄTT")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "tyre-order__spec__row__data" }, [
                _vm._v(_vm._s(_vm.order.typeOfPurchase))
              ])
            ]),
            _vm._v(" "),
            _vm.order.recycleFee > 0
              ? _c("div", { staticClass: "tyre-order__spec__row" }, [
                  _c("span", { staticClass: "tyre-order__spec__row__title" }, [
                    _vm._v("DÄCKÅTERVINNINGSAVGIFT")
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "tyre-order__spec__row__data" }, [
                    _vm._v(_vm._s(_vm.$formatPrice(_vm.order.recycleFee)))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.order.totalDiscount > 0
              ? _c("div", { staticClass: "tyre-order__spec__row" }, [
                  _c("span", { staticClass: "tyre-order__spec__row__title" }, [
                    _vm._v("RABATTER PÅ DETTA KÖP")
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "tyre-order__spec__row__data tyre-order__spec__row__data--discount"
                    },
                    [
                      _vm._v(
                        "-" + _vm._s(_vm.$formatPrice(_vm.order.totalDiscount))
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "tyre-order__spec__row" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tyre-order__spec__row__sub-container" },
                [
                  _c("VcPriceTag", {
                    attrs: {
                      price: _vm.order.totalPrice,
                      showIfZero: "",
                      hideOrdinaryPriceIfDiscount: ""
                    }
                  })
                ],
                1
              )
            ])
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tyre-order__spec__row__sub-container" }, [
      _c("span", { staticClass: "tyre-order__spec__row__title" }, [
        _vm._v("TOTALKOSTNAD")
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "tyre-order__spec__row__sub-info" }, [
        _vm._v("(inkl. moms)")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }