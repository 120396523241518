<template>
  <div class="contact-forms--quick-contact">
    <div class="contact-forms">
      <div
        class="contact-forms__item"
        :class="{ 'contact-forms__item--selected': selectedTab === 'phone' }"
      >
        <div class="contact-forms__item-name" @click.stop="selectPhone">
          Ring oss
        </div>
        <div class="contact-forms__item-icon">
          <i v-if="selectedTab === 'phone'" class="fal fa-angle-up"></i>
          <i v-else class="fal fa-angle-down"></i>
        </div>
        <PhoneContactSection
          v-if="selectedTab === 'phone'"
          :cities="cities"
          :department="department"
          :submittedSelectedLocation="selectedLocation"
          @onLocationChange="onLocationChange"
        />
      </div>

      <div
        class="contact-forms__item"
        :class="{ 'contact-forms__item--selected': selectedTab === 'message' }"
      >
        <div class="contact-forms__item-name" @click.stop="selectMessage">
          Skicka meddelande
        </div>
        <div class="contact-forms__item-icon">
          <i v-if="selectedTab === 'message'" class="fal fa-angle-up"></i>
          <i v-else class="fal fa-angle-down"></i>
        </div>
        <MessageContactSection
          v-if="selectedTab === 'message'"
          :cities="cities"
          :department="department"
          :service="service"
          :submittedSelectedLocation="selectedLocation"
          @onLocationChange="onLocationChange"
        />
      </div>
      <!-- <div
        class="contact-forms__item"
        :class="{ 'contact-forms__item--selected': selectedTab === 'chat' }"
      >
        <div class="contact-forms__item-name" @click.stop="selectChat">
          Chatta med oss
        </div>
        <div class="contact-forms__item-icon">
          <i v-if="selectedTab === 'chat'" class="fal fa-angle-up"></i>
          <i v-else class="fal fa-angle-down"></i>
        </div>
        <div v-if="selectedTab === 'chat'">
          <a class="btn btn--primary" @click="startChat">Starta chatt</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Axios from "../../axios";
import gtm from "../../utils/gtm";
import PhoneContactSection from "./PhoneContactSection.vue";
import MessageContactSection from "./MessageContactSection.vue";
export default {
  components: { PhoneContactSection, MessageContactSection },
  props: {
    pageName: {
      type: String
    },
    contentId: {
      type: String
    },
    locationTag: {
      type: String,
      default: null
    },
    serviceTag: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      cities: [],
      department: null,
      service: null,
      brand: null,
      location: null,
      selectedTab: null,
      selectedLocation: ""
    };
  },

  async mounted() {
    if (
      this.serviceTag &&
      this.serviceTag.length &&
      this.locationTag &&
      this.locationTag.length
    ) {
      this.service = this.serviceTag;
      await this.onLocationChange(this.locationTag);
    }
    if (!this.department) {
      let append = `?contentId=${this.contentId}`;
      let res = await Axios.get("/api/contact/GetQuickContactData" + append);
      res = res?.data;
      this.cities = res?.cities;
      this.department = res?.department;
      this.service = res?.service;
      this.brand = res?.brand;
      this.location = res?.location;
    }
    gtm.quickContactModalClick({ departmentNr: this.department?.departmentNr });
  },
  methods: {
    // startChat() {
    //   let chatAvailable = JSON.parse(sessionStorage.getItem("initChat"));
    //   $("[data-quick-contact-modal]").hide();
    //   $(".floating-action-btn").hide();
    //   // When the "Chat with us" custom trigger button is clicked first we check if
    //   // the chat has been already initialized (this custom trigger button is not clicked for the first time)
    //   if (chatAvailable) {
    //     // if that's the case, the widget will only maximize as it is already available.
    //     window.$$weply.api.maximizeWidget();
    //   } else {
    //     // else the widget will be first injected and than auto-maximized
    //     window.weplyWidgetReady = function() {
    //       window.$$weply.api.maximizeWidget();
    //     };
    //     sessionStorage.setItem("initChat", true);
    //     var e, n;
    //     e = document.createElement("script");
    //     e.type = "text/javascript";
    //     e.src =
    //       "https://app.weply.chat/widget/681f2f9ba44d10e6b3adc9d0566937c4";
    //     e.async = true;
    //     n = document.getElementsByTagName("script")[0];
    //     n.parentNode.insertBefore(e, n);
    //   }
    // },
    selectPhone() {
      this.selectedTab = this.selectedTab === "phone" ? "" : "phone";
      gtm.displayPhoneNumber({ formType: "quickForm" });
    },
    selectMessage() {
      this.selectedTab = this.selectedTab === "message" ? "" : "message";
      gtm.messageClick({
        departmentNr: this.department?.departmentNr,
        formType: "quickForm"
      });
    },
    // selectChat() {
    //   this.selectedTab = this.selectedTab === "chat" ? "" : "chat";
    //   // gtm.messageClick({
    //   //   departmentNr: this.department?.departmentNr,
    //   //   formType: "quickForm"
    //   // });
    // },
    async onLocationChange(location) {
      this.selectedLocation = location;
      const res = await Axios.post(
        "/api/contact/GetContactModalDepartmentData",
        {
          service: this.service,
          location
        }
      );
      this.department = res?.data;
    }
  }
};
</script>
