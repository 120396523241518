<template>
  <div class="filter-bar">
    <div class="filter-bar__btn">
      <i class="fal fa-sliders-v filter-bar__btn__icon"></i>
      Filtrera
    </div>
    <div class="filter-bar__tags">
      <div
        v-if="!isBeginning"
        class="filter-bar__tags__shadow filter-bar__tags__shadow--left"
      ></div>
      <div
        v-if="!isEnd"
        class="filter-bar__tags__shadow filter-bar__tags__shadow--right"
      ></div>
      <div class="swiper-container" ref="swiperContainer">
        <div class="swiper-wrapper">
          <div
            v-if="queryFilters.length > 0 && !hideWatchButton"
            class="swiper-slide filter-bar__tag filter-bar__tag--watch"
            :class="{
              'filter-bar__tag--watch--active': filtersSaved,
              pulsing: highlightBtn
            }"
            @click="onOpenWatchModal"
          >
            <FontAwesomeIcon
              :icon="filtersSaved ? faBellOn : faBell"
              class="filter-bar__tag__icon"
            >
            </FontAwesomeIcon>
            {{ filtersSaved ? "Bevakad" : "Bevaka" }}
          </div>
          <div v-for="qf in queryFilters"
               :key="qf.key + mobileTag"
               class="swiper-slide filter-bar__tag"
               :class="{
               'filter-bar__tag--group' : qf.children && qf.children.length>
            0
            }"
            @click="
            onClearQueryFilter(qf, qf.children && qf.children.length > 0)
            "
            >
            {{ doReplaceForDisplay(qf.label) }}
            <div v-if="
                (qf.type === I3QueryFilterTypes.FreeText && !qf.locked) ||
                  qf.type === I3QueryFilterTypes.RangeFacet ||
                  (qf.children && qf.children.length === 0)
              ">
              <i class="far fa-times filter-bar__tag__icon"></i>
            </div>
            <div v-for="qfc in qf.children"
                 :key="qfc.key + mobileTag"
                 class="filter-bar__tag filter-bar__tag--child"
                 @click.stop="onClearQueryFilter(qfc, false)">
              {{ qfc.label }}
              <div>
                <i class="far fa-times filter-bar__tag__icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheSearchWatchModal
      v-if="showModal"
      :email-found="emailFound"
      :email="email"
      :privacyPolicyText="lang.General.PrivacyPolicyText"
      :feedbackHeader="feedbackHeader"
      :feedbackText="feedbackText"
      :feedbackContactHeader="feedbackContactHeader"
      :feedbackContactText="feedbackContactText"
      @close="close()"
      :hasCustomContent="!email"
    >
      <SearchWatch
        slot="customContent"
        :header="header"
        :text="text"
        :tags="tags"
        :feedbackHeader="feedbackHeader"
        :feedbackText="feedbackText"
        :feedbackContactHeader="feedbackContactHeader"
        :feedbackContactText="feedbackContactText"
        :privacyPolicyText="lang.General.PrivacyPolicyText"
        :done="done"
        :loading="loading"
        :error="error"
        :brandToWatch="brandToWatch"
        @saveSearches="onSaveSearches"
      >
      </SearchWatch>
    </TheSearchWatchModal>
  </div>
</template>

<script>
import ChannelEvents from "../../constants/ChannelEvents";
import I3QueryParams from "../../constants/I3QueryParams";
import ChannelQuery from "../../models/ChannelQuery";
import I3QueryFilterTypes from "../../constants/I3QueryFilterTypes";
import Swiper from "../../swiper";
import TheSearchWatchModal from "../i3-search-watch/TheSearchWatchModal.vue";
import RequestStates from "../../constants/RequestStates";
import Axios from "../../axios";
import SearchWatch from "../shared/SearchWatch.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBellOn, faBell } from "@fortawesome/pro-solid-svg-icons";
import gtm from "../../utils/gtm";
import { EventBus } from "../../utils/event-bus";
import { replaceForDisplay } from "../../utils/replaceForDisplay";
import Cookies from "js-cookie";

export default {
  components: {
    FontAwesomeIcon,
    SearchWatch,
    TheSearchWatchModal
  },
  props: {
    channel: Object,
    lang: {
      type: Object
    },
    hideWatchButton: {
      type: Boolean,
      default: false
    },
    mobileMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBeginning: true,
      isEnd: true,
      queryFilters: [],
      swiper: null,
      showModal: false,
      lastQuery: null,
      emailFound: false,
      email: "",
      submitState: null,
      filtersSaved: false,
      faBellOn,
      faBell,
      I3QueryFilterTypes,
      brandToWatch: null,
      highlightBtn: false,
      initialModalDisplay: false,
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  watch: {
    tags() {
      if (this.tags.length >= 2 && !this.showModal) {
        this.setWatchBtnPulsing();
      } else {
        this.unsetWatchBtnPulsing();
      }
    }
  },
  computed: {
    isLoggedIn() {
      return window.CurrentUser !== undefined && window.CurrentUser !== null;
    },
    tags() {
      const parentTags = this.queryFilters.map(qf => qf.label);
      const childTags = [];
      this.queryFilters.map(
        qf => qf.children && qf.children.map(qfc => childTags.push(qfc.label))
      );
      return [...parentTags, ...childTags];
    },
    done() {
      return this.submitState === RequestStates.Success;
    },
    loading() {
      return this.submitState === RequestStates.Request;
    },
    error() {
      return this.submitState === RequestStates.Failure;
    },
    mobileTag() {
      return this.mobileMenu ? "-mobile" : "";
    },
    header() {
      if (this.isLoggedIn) {
        return this.lang.App.HeaderLoggedIn;
      } else {
        return this.lang.App.Header;
      }
    },
    text() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || "";
        return this.lang.App.TextLoggedIn.replace("{{email}}", email);
      } else {
        return this.lang.App.Text;
      }
    },
    feedbackHeader() {
      if (this.isLoggedIn) {
        return this.lang.App.FeedbackHeaderLoggedIn;
      } else {
        return this.lang.App.FeedbackHeader;
      }
    },
    feedbackText() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || "";
        return this.lang.App.FeedbackTextLoggedIn.replace("{{email}}", email);
      } else {
        return this.lang.App.FeedbackText;
      }
    },
    feedbackContactHeader() {
      if (this.isLoggedIn) {
        return this.lang.App.FeedbackContactHeaderLoggedIn;
      } else {
        return this.lang.App.FeedbackContactHeader;
      }
    },
    feedbackContactText() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || "";
        return this.lang.App.FeedbackContactTextLoggedIn.replace(
          "{{email}}",
          email
        );
      } else {
        return this.lang.App.FeedbackContactText;
      }
    }
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiperContainer, {
      freeMode: true,
      slidesPerView: "auto",
      autoHeight: false,
      on: {
        fromEdge: () => {
          if (this.swiper) {
            if (this.isEnd !== this.swiper.isEnd)
              this.isEnd = this.swiper.isEnd;
            if (this.isBeginning !== this.swiper.isBeginning)
              this.isBeginning = this.swiper.isBeginning;
          }
        },
        reachBeginning: () => (this.isBeginning = true),
        reachEnd: () => (this.isEnd = true),
        resize: function() {
          this.update();
        }
      }
    });

    if (this.queryFilters.length > 0) {
      this.isBeginning = this.swiper.isBeginning;
      this.isEnd = this.swiper.isEnd;
    }
    let urlParams = new URLSearchParams(window.location.search);
    let showSearchWatch = urlParams.get("showSearchWatch");
    if (showSearchWatch && showSearchWatch === "true") {
      this.initialModalDisplay = true;
    }

    EventBus.$on("open-search-watch-modal", () => {
      this.onOpenWatchModal();
    });
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  methods: {
    onQuerySuccess() {
      const brandModelFacets =
        this.channel.data.facets
          .find(facet => facet.id === "brandmodelfacet")
          ?.filters?.filter(
            filter =>
              filter.selected ||
              filter.children.filter(child => child.selected).length === 1
          ) || [];

      this.queryFilters = this.channel.getQueryFilters();

      this.brandToWatch =
        brandModelFacets.length === 1 && !window.location.href.includes("fritid")
          ? brandModelFacets[0]?.displayName
          : null;
      this.lastQuery = this.channel.lastQuery;
      this.clearSavedWatch();
      setTimeout(() => this.swiper.update(), 1000);
      if (this.initialModalDisplay)
      {
        this.onOpenWatchModal();
        this.initialModalDisplay = false;
      }
    },
    onClearQueryFilter(queryFilter, isGroupTag) {
      if (!isGroupTag) {
        this.getClearQueryFilterFn(queryFilter)(queryFilter);
      }
    },
    getClearQueryFilterFn(queryFilter) {
      switch (queryFilter.type) {
        case I3QueryFilterTypes.Filter:
          return this.clearFilter;
        case I3QueryFilterTypes.RangeFacet:
          return this.clearRangeFacet;
        case I3QueryFilterTypes.FreeText:
          return this.clearFreeText;
      }
    },
    clearFilter({ filter }) {
      if (filter.navigationFilter) {
        window.location.href = filter.url;
      } else {
        this.channel.execute(filter.query);
      }
    },
    clearRangeFacet({ facet }) {
      const query = this.channel.lastQuery;

      query.unsetParam(facet.maxQuery);
      query.unsetParam(facet.minQuery);
      this.channel.execute(query);
    },
    clearFreeText() {
      const query = new ChannelQuery(this.channel.lastQuery);
      query.unsetParam(I3QueryParams.FreeText);
      this.channel.execute(query);
    },
    onOpenWatchModal() {
      $("body").addClass("overlay-open");
      this.showModal = true;
      this.unsetWatchBtnPulsing();
    },
    clearSavedWatch() {
      this.emailFound = false;
      this.email = "";
      this.showModal = false;
      this.filtersSaved = false;
      this.submitState = null;
    },
    close() {
      $("body").removeClass("overlay-open");
      this.emailFound = false;
      this.email = "";
      this.showModal = false;
    },
    async onSaveSearches(email, isContact, departmentNr, brandName) {
      var site = Cookies.get("site");
      const searchString = this.channel.isUrlBased
        ? `${this.channel.defaultQuery.toString()}&${this.lastQuery.toString()}`
        : this.lastQuery.toString();
      try {
        this.submitState = RequestStates.Request;
        const res = await Axios.post("/api/MyPages/searches", {
          email,
          searchString,
          contact: isContact,
          departmentNr,
          isCamping: site === "camping"
        });
        this.submitState = RequestStates.Success;
        this.filtersSaved = true;
        if (res?.data?.emailFound) {
          this.emailFound = true;
          this.email = email;
          this.showModal = true;
        } else if (res?.data?.emailFound === false) {
          this.emailFound = false;
          this.email = email;
          this.showModal = true;
        }
        gtm.submitSearchWatch();
        if (isContact) {
          EventBus.$emit("isContactSubmit", true);
          gtm.submitSearchWatchContact({ departmentNr, brandName });
        }
      } catch (error) {
        this.submitState = RequestStates.Failure;
      }
    },
    setWatchBtnPulsing() {
      $(".floating-action-btn--watch").addClass("pulsing");
      this.highlightBtn = true;

      setTimeout(() => this.unsetWatchBtnPulsing(), 10000);
    },
    unsetWatchBtnPulsing() {
      $(".floating-action-btn--watch").removeClass("pulsing");
      this.highlightBtn = false;
    },
    doReplaceForDisplay(value) {
      return replaceForDisplay(value);
    }
  }
};
</script>
