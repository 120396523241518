var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wizard wizard--trade-in" },
    [
      _c("SectionList", {
        attrs: {
          sections: _vm.sections,
          SectionTypeComponents: _vm.SectionTypeComponents
        },
        on: { completed: _vm.onSectionCompleted }
      }),
      _vm._v(" "),
      _c("FormSubmit", {
        attrs: { lastSection: _vm.lastSection },
        on: { completed: _vm.onSectionCompleted }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }