const target = window.document;

export default {
  eventPrefix: '',

  on(name, fn) {
    target.addEventListener(`${this.eventPrefix}${name}`, fn);
  },

  off(name, fn) {
    target.removeEventListener(`${this.eventPrefix}${name}`, fn);
  },

  one(name, fn) {
    const oneFn = (...params) => {
      fn(...params);
      this.off(name, oneFn);
    };

    this.on(name, oneFn);
  },

  emit(name, detail) {
    const event = new CustomEvent(`${this.eventPrefix}${name}`, { detail });
    return target.dispatchEvent(event);
  },
};
