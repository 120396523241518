<template>
  <div>
    <div class="filter-bar__tag filter-bar__tag--watch" :class="{ 'filter-bar__tag--watch--active': filtersSaved }"
      @click="onOpenWatchModal">
      <FontAwesomeIcon :icon="filtersSaved ? faBellOn : faBell" class="filter-bar__tag__icon">
      </FontAwesomeIcon>
      {{ filtersSaved ? 'Bevakad' : 'Bevaka' }}
    </div>
    <TheSearchWatchModal v-if="showModal" :email-found="emailFound" :email="email"
      :privacyPolicyText="lang.General.PrivacyPolicyText" :feedbackHeader="feedbackHeader" :feedbackText="feedbackText"
      :feedbackContactHeader="feedbackContactHeader" :feedbackContactText="feedbackContactText" @close="close()"
      :hasCustomContent="!email">
      <SearchWatch slot="customContent" :header="header" :text="text" :tags="displayTags" :feedbackHeader="feedbackHeader"
        :feedbackText="feedbackText" :feedbackContactHeader="feedbackContactHeader"
        :feedbackContactText="feedbackContactText" :privacyPolicyText="lang.General.PrivacyPolicyText" :done="done"
        :loading="loading" :error="error" :brandToWatch="brandToWatch" :departmentNr="departmentNr"
        @saveSearches="onSaveSearches">
      </SearchWatch>
    </TheSearchWatchModal>
  </div>
</template>

<script>

import TheSearchWatchModal from '../i3-search-watch/TheSearchWatchModal.vue';
import SearchWatch from '../shared/SearchWatch.vue';
import RequestStates from '../../constants/RequestStates';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBellOn, faBell } from "@fortawesome/pro-regular-svg-icons";
import Axios from '../../axios';
import gtm from '../../utils/gtm';
import { EventBus } from '../../utils/event-bus';

export default {
  components: {
    TheSearchWatchModal,
    SearchWatch,
    FontAwesomeIcon,
  },
  props: {
    lang: {
      type: Object,
    },
    searchQuery: {
      type: String,
    },
    tags: {
      type: String,
    },
    brandToWatch: String,
    departmentNr: Number,
  },
  data() {
    return {
      queryFilters: [],
      showModal: false,
      emailFound: false,
      email: '',
      submitState: null,
      filtersSaved: false,
      faBellOn,
      faBell,
    };
  },

  computed: {
    displayTags() {
      return this.tags?.split(",");
    },
    done() {
      return this.submitState === RequestStates.Success;
    },
    loading() {
      return this.submitState === RequestStates.Request;
    },
    error() {
      return this.submitState === RequestStates.Failure;
    },
    isLoggedIn() {
      return window.CurrentUser !== undefined && window.CurrentUser !== null;
    },
    header() {
      if (this.isLoggedIn) {
        return this.lang.App.HeaderLoggedIn;
      } else {
        return this.lang.App.Header;
      }
    },
    text() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || '';
        return this.lang.App.TextLoggedIn.replace("{{email}}", email);
      } else {
        return this.lang.App.Text;
      }
    },
    feedbackHeader() {
      if (this.isLoggedIn) {
        return this.lang.App.FeedbackHeaderLoggedIn;
      } else {
        return this.lang.App.FeedbackHeader;
      }
    },
    feedbackText() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || '';
        return this.lang.App.FeedbackTextLoggedIn.replace("{{email}}", email);
      } else {
        return this.lang.App.FeedbackText;
      }
    },
    feedbackContactHeader() {
      if (this.isLoggedIn) {
        return this.lang.App.FeedbackContactHeaderLoggedIn;
      } else {
        return this.lang.App.FeedbackContactHeader;
      }
    },
    feedbackContactText() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || '';
        return this.lang.App.FeedbackContactTextLoggedIn.replace("{{email}}", email);
      } else {
        return this.lang.App.FeedbackContactText;
      }
    },
  },
  methods: {
    onOpenWatchModal() {
      $('body').addClass('overlay-open');
      $('.product-main-action').addClass('product-main-action--modal-open');
      this.showModal = true;
    },
    clearSavedWatch() {
      this.emailFound = false;
      this.email = '';
      this.showModal = false;
      this.filtersSaved = false;
    },
    close() {
      $('body').removeClass('overlay-open');
      $('.product-main-action').removeClass('product-main-action--modal-open');
      this.emailFound = false;
      this.email = '';
      this.showModal = false;
    },
    async onSaveSearches(email, isContact, departmentNr, brandName) {
      try {
        this.submitState = RequestStates.Request;
        const res = await Axios.post('/api/MyPages/searches', {
          email,
          searchString: this.searchQuery,
          contact: isContact,
          departmentNr,
        })
        this.submitState = RequestStates.Success;
        this.filtersSaved = true;
        if (res?.data?.emailFound) {
          this.emailFound = true;
          this.email = email;
          this.showModal = true;
        } else if (res?.data?.emailFound === false) {
          this.emailFound = false;
          this.email = email;
          this.showModal = true;
        }
        gtm.submitSearchWatch();
        if (isContact) {
          EventBus.$emit('isContactSubmit', true)
          gtm.submitSearchWatchContact({ departmentNr, brandName })
        }
      } catch (error) {
        this.submitState = RequestStates.Failure;
      }
    },
  },
}
</script>
