<template>
  <div class="form-stack" :class="{'form-stack--required': field.required}">
    <label>
      {{field.label}}
    </label>
    <input class="form-control"
      :class="{'form-control--invalid': invalid}"
      :type="field.inputType"
      :placeholder="field.placeholder"
      :autocomplete="field.autocomplete"
      :required="field.required"
      :value="field.value"
      :readonly="field.readonly"
      @blur="$emit('blur')"
      @keyup="onFieldChange(field.type, $event.target.value)" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SessionStorageUtil from '../../utils/session-storage';

export default {
  props: {
    field: Object,
    invalid: Boolean,
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),

    onFieldChange(type, value) {
      this.setField({
        type,
        value,
      });

      this.$emit('change', { type, value });
    },
  },
}
</script>
