var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-pages__section my-vehicles" },
    [
      _c("div", { staticClass: "my-pages__section__title-container" }, [
        _c("span", { staticClass: "my-pages__section__title" }, [
          _vm._v(
            _vm._s(
              "" +
                (_vm.sectionData.length
                  ? _vm.sectionData.length + " FORDON"
                  : "Du har inga fordon registrerade")
            )
          )
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.sectionData, function(product) {
        return _c("div", { key: product.id, staticClass: "saved-vehicles" }, [
          _c("div", { staticClass: "product-info" }, [
            _c("span", { staticClass: "product-info__title" }, [
              _vm._v(_vm._s(product.brandName))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "product-info__subtitle" }, [
              _vm._v(
                _vm._s(product.modelName) + " " + _vm._s(product.trimPackage)
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "product-info__description-container" }, [
              _c(
                "span",
                { staticClass: "product-info__description-container__text" },
                [_vm._v(_vm._s(product.registrationNumber) + " |")]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "product-info__description-container__text" },
                [_vm._v(_vm._s(product.modelYear))]
              ),
              _vm._v(" "),
              product.mileage > 0
                ? _c(
                    "span",
                    {
                      staticClass: "product-info__description-container__text"
                    },
                    [
                      _vm._v(
                        "| " +
                          _vm._s(_vm.$formatNumber(product.mileage)) +
                          " mil"
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            product.forSaleDate
              ? _c("small", [
                  _c("b", [
                    _vm._v(
                      "Önskat försäljningspris: " +
                        _vm._s(_vm.$formatPrice(product.forSalePrice))
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            !product.estimationRequested
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn--primary btn--block btn--sm",
                    on: {
                      click: function($event) {
                        return _vm.openTradeInModal(product)
                      }
                    }
                  },
                  [_vm._v("\n        Få ett inbytespris\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            product.estimationRequested && !product.estimated
              ? _c(
                  "div",
                  {
                    staticClass:
                      "feedback-message feedback-message--info feedback-message--slim"
                  },
                  [_vm._m(0, true)]
                )
              : _vm._e(),
            _vm._v(" "),
            product.estimated
              ? _c("div", [
                  _vm.checkIfEstimationIsValid(product.estimatedPriceDate)
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "feedback-message feedback-message--success feedback-message--slim"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "feedback-message__text" },
                              [
                                _c("i", {
                                  staticClass:
                                    "fas fa-circle attention-circle attention-circle--success"
                                }),
                                _vm._v(
                                  " Vi har hanterat din inbytesförfrågan "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n              Ditt fordon är värderat till: "
                                ),
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatPrice(product.estimatedPrice)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("br"),
                                _vm._v("\n              Datum: "),
                                _c("b", [
                                  _vm._v(
                                    "  " +
                                      _vm._s(
                                        _vm.$formatYYYYMMDD(
                                          product.estimatedPriceDate
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("br")
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        !product.forSale
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "btn btn--muted btn--block btn--sm u-margin-top",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleForSale(product)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            Jag är intresserad av att sälja bilden\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        product.forSale && !product.forSaleDate
                          ? _c(
                              "div",
                              { staticClass: "u-margin-top" },
                              [
                                _c(
                                  "VcFormStack",
                                  {
                                    attrs: { label: "Önskat försäljningspris" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: product.forSalePrice,
                                          expression: "product.forSalePrice"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        placeholder:
                                          "Ange önskat försäljningspris",
                                        type: "number"
                                      },
                                      domProps: { value: product.forSalePrice },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            product,
                                            "forSalePrice",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn--primary btn--block btn--sm u-margin-top",
                                    on: {
                                      click: function($event) {
                                        return _vm.sendForSalePrice(product)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Skicka\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "feedback-message feedback-message--danger feedback-message--slim"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "feedback-message__text" },
                              [
                                _c("i", {
                                  staticClass: "fas fa-circle attention-circle"
                                }),
                                _vm._v(" Din fordonsvärdering gick ut "),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatYYYYMMDD(
                                        product.estimatedPriceDate
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(", var god begär en ny värdering"),
                                _c("br")
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn--primary btn--block btn--sm u-margin-top",
                            on: {
                              click: function($event) {
                                return _vm.openTradeInModal(product)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            Få ett inbytespris\n          "
                            )
                          ]
                        )
                      ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn--muted btn--block btn--sm u-margin-top",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goToService("ServiceReparation", product)
                  }
                }
              },
              [_vm._v("\n        Boka service\n      ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn--muted btn--block btn--sm u-margin-top",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goToService("Skadebesiktning", product)
                  }
                }
              },
              [_vm._v("\n        Boka skadebesiktning\n      ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn--muted btn--block btn--sm u-margin-top",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goToService("Glasreparation", product)
                  }
                }
              },
              [_vm._v("\n        Boka glasreparation\n      ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn--muted btn--block btn--sm u-margin-top",
                attrs: { href: _vm.similarCarsLink(product) }
              },
              [_vm._v("\n        Sök efter liknande bilar i lager\n      ")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn--muted btn--block btn--sm u-margin-top",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.goToService("Däcktjänster", product)
                  }
                }
              },
              [_vm._v("\n        Boka däckbyte\n      ")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product-info__card-actions" }, [
            _c(
              "div",
              {
                staticClass: "product-info__card-actions__btn",
                on: {
                  click: function($event) {
                    return _vm.$emit("deleteMyVehicle", product.id)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-trash blue" })]
            )
          ])
        ])
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "my-vehicles__add",
          on: {
            click: function($event) {
              return _vm.showAddNewView()
            }
          }
        },
        [
          _c("i", { staticClass: "fal fa-plus" }),
          _vm._v(" Lägg till fordon "),
          _c("i", { staticClass: "fal fa-chevron-right" })
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "my-pages__section__title" }, [
        _vm._v("FÖRDELAR MED ATT SPARA DITT FORDON")
      ]),
      _vm._v(" "),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.myVehiclesUsps) } }),
      _vm._v(" "),
      _vm.showTradeInModal
        ? _c(
            "VcModal",
            {
              attrs: { "modal-body-class": "modal-body--no-padding" },
              on: {
                close: function($event) {
                  return _vm.onModalClose()
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Få ett inbytespris på ditt fordon ")
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { ref: "tradeinholder" }, [
                  _c("div", { ref: "tradein" })
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feedback-message__text" }, [
      _c("i", {
        staticClass:
          "fas fa-circle attention-circle attention-circle--secondary"
      }),
      _vm._v(" Vi hanterar din inbytesförfrågan\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }