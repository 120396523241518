<template>
  <div>
    <VcModal v-if="inModal" @close="$emit('modalClose')">
      <div slot="header">{{modalHeader}}</div>
      <div slot="content">
        <TheForm
          :promoBg="promoBg"
          :promo="promo"
          :meta="meta"
          :header="header"
          :buttonText="buttonText"
          :carLookupError="carLookupError"
          @change="onChange"
          @submit="onSubmit"
          @chooseVehicleClick="modalOpen = 'choose-vehicle'"
          @chooseTyreDimensionsClick="modalOpen = 'choose-tyre-dimensions'"
        ></TheForm>
      </div>
    </VcModal>
    <TheForm
      v-else
      :promoBg="promoBg"
      :promo="promo"
      :meta="meta"
      :header="header"
      :buttonText="buttonText"
      :carLookupError="carLookupError"
      @change="onChange"
      @submit="onSubmit"
      @chooseVehicleClick="modalOpen = 'choose-vehicle'"
      @chooseTyreDimensionsClick="modalOpen = 'choose-tyre-dimensions'"
    ></TheForm>

    <ChooseVehicleApp
      v-if="modalOpen === 'choose-vehicle'"
      :TyreSpec="TyreSpec"
      :buttonHref="buttonHref"
      modalHeader="Välj bilmodell"
      buttonText="Se produkter"
      @modalClose="modalOpen = null"
    ></ChooseVehicleApp>
    <ChooseTyreDimensionsApp
      v-else-if="modalOpen === 'choose-tyre-dimensions'"
      :TyreSpec="TyreSpec"
      :forceManual="forceManualTyreSize"
      :buttonHref="buttonHref"
      modalHeader="Ange däckdimensioner"
      buttonText="Se produkter"
      @modalClose="modalOpen = null"
    ></ChooseTyreDimensionsApp>
  </div>
</template>

<script>
import { VcModal } from "@holmgrensbil/vue-components";
import ChooseTyreDimensionsApp from "../choose-tyre-dimensions/App.vue";
import ChooseVehicleApp from "../choose-vehicle/App.vue";
import EonApi from "../../models/EonApi";
import Breakpoints from "../../constants/Breakpoints";
import TheForm from "./TheForm.vue";
import Tooltip from "tooltip.js";
import TyreSpec from '../../models/TyreSpec';

export default {
  components: {
    TheForm,
    VcModal,
    ChooseTyreDimensionsApp,
    ChooseVehicleApp
  },
  props: {
    TyreSpec: Object,
    modalHeader: String,
    header: String,
    meta: String,
    buttonText: String,
    buttonHref: String,
    promo: Boolean,
    promoBg: String,
    forceManualTyreSize: Boolean,
    inModal: Boolean,
  },
  data() {
    return {
      modalOpen: null,
      carLookupError: false,
      car: null,
      regNr: null
    };
  },
  async mounted() {
    window.document.addEventListener("click", e => {
      if (e.target.className === "link link--underline link-style") {
        this.modalOpen = "choose-vehicle";
      }
      const classes =
        e.target.offsetParent && Object.values(e.target.offsetParent.classList);
      if (classes && !classes.includes("ttjs-tooltip")) {
        this.tooltipInstance && this.tooltipInstance.hide();
        this.tooltipInstance = null;
      }
    });
  },
  computed: {},
  methods: {
    getTooltipInstance(status, title) {
      this.tooltipInstance && this.tooltipInstance.hide();
      const el = document.querySelector("#regNr-input");
      const instance = new Tooltip(el, {
        title,
        html: true,
        trigger: "manual",
        placement: "bottom",
        closeOnClickOutside: true,
        arrowSelector: ".ttjs-tooltip-arrow",
        innerSelector: ".ttjs-tooltip-inner",
        template: `
          <div class="ttjs-tooltip ttjs-tooltip--landing-${status}" role="tooltip">
            <div class="ttjs-tooltip-arrow ttjs-tooltip-arrow--landing-${status}"></div>
            <div class="ttjs-tooltip-inner"></div>
          </div>`
      });
      return (this.tooltipInstance = instance);
    },
    async onChange(regNr) {
      if (!regNr.match(/^\w{3}\d{2}(\w{1}|\d{1})$/g)) {
        return;
      }

      const res = await EonApi.getCar(regNr);

      let title;
      let status;

      if (res.err) {
        status = "err";
        title =
          'Tyvärr kunde vi inte få information om din bil. Sök med hjälp av din bilmodell istället. <a class="link link--underline link-style">Sök med bilmodell.</a>';
      } else {
        status = "succ";
        title = res.data.description;
      }

      await this.getTooltipInstance(status, title);

      this.tooltipInstance.show();

      if (res.err) {
        return;
      }

      this.regNr = regNr;
      this.car = res.data;
    },
    onSubmit() {
      this.TyreSpec.set({ regNr: this.regNr });
      this.TyreSpec.setCar(this.car);
      window.location.href = this.buttonHref;
    }
  }
};
</script>
