var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.storeState.config
    ? _c("div", { staticClass: "vehicle-configurator configurator-app" }, [
        _c(
          "div",
          { staticClass: "vehicle-configurator__main" },
          [
            _c("div", { staticClass: "vehicle-configurator__header" }, [
              _c("h3", { staticClass: "vehicle-configurator__header__title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Summation.VehicleConfiguratorHeading) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "vehicle-configurator__header__sub-title" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lang.Summation.VehicleConfiguratorSubHeading) +
                      "\n      "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.financingConfig
              ? _c("FinanceSelector", {
                  attrs: {
                    selected: _vm.selectedFinancingOption,
                    financingConfig: _vm.financingConfig,
                    financingSummary: _vm.financingSummary.financing,
                    displayTax: _vm.displayTax,
                    lang: _vm.lang
                  },
                  on: {
                    "change-finance-option": _vm.onChangeFinanceOption,
                    "change-tax-setting": _vm.onChangeTaxSetting
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.tradeInData
              ? _c("TradeInSelector", {
                  attrs: {
                    tradeInData: _vm.tradeInData,
                    lang: _vm.lang,
                    currentVehicleRegNr: _vm.regNr,
                    department: _vm.department
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.displayServiceAgreement && _vm.serviceAgreementData
              ? _c("ServiceAgreementSelector", {
                  attrs: {
                    serviceAgreementData: _vm.serviceAgreementData,
                    lang: _vm.lang
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.displayTyres && _vm.tyreData
              ? _c("TyreSelector", {
                  attrs: {
                    tyreData: _vm.tyreData,
                    selectedFinancingOption: _vm.selectedFinancingOption,
                    lang: _vm.lang
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.displayChargeBox && _vm.chargeBoxData
              ? _c("ChargeBoxSelector", {
                  attrs: { chargeBoxData: _vm.chargeBoxData, lang: _vm.lang }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.displayFittings && _vm.fittingsData
              ? _c("FittingsSelector", {
                  attrs: {
                    fittingsData: _vm.fittingsData,
                    selectedFinancingOption: _vm.selectedFinancingOption,
                    displayTax: _vm.displayTax,
                    lang: _vm.lang
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "vehicle-configurator__summation" }, [
          _vm.priceSummation
            ? _c("div", { staticClass: "configurator-section" }, [
                _c("h4", { staticClass: "configurator-section__title" }, [
                  _c("i", {
                    staticClass: "fal fa-receipt configurator-section__icon"
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.lang.Summation.VehicleConfiguratorSummationHeading
                      ) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "vehicle-configurator__summation__row" },
                        [
                          _c("SummationVehicle", {
                            attrs: {
                              lang: _vm.lang,
                              vehicle: _vm.vehicle,
                              price: _vm.priceSummation.vehicle,
                              attention:
                                _vm.selectedFinancingOption === "privateLeasing"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.tradeInSummation
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-configurator__summation__row"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "properties" },
                                [
                                  _vm._m(0),
                                  _vm._v(" "),
                                  _c("SummationList", {
                                    attrs: {
                                      lang: _vm.lang,
                                      list: _vm.tradeInSummation
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vehicle-configurator__summation__sub-notice"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.lang.Summation
                                              .VehicleConfiguratorTradeInMeta
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.serviceAgreementSummation
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-configurator__summation__row"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "properties" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "properties__item properties__item--header"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "properties__label" },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.serviceAgreementSummation
                                                  .title
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("SummationList", {
                                    attrs: {
                                      lang: _vm.lang,
                                      list:
                                        _vm.serviceAgreementSummation.summation
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("SummationList", {
                                    attrs: {
                                      lang: _vm.lang,
                                      list:
                                        _vm.serviceAgreementSummation
                                          .addedProducts,
                                      isSubList: true
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.serviceAgreementSummation.meta
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vehicle-configurator__summation__sub-notice"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.serviceAgreementSummation
                                                  .meta
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.addonSummations.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "vehicle-configurator__summation__addons"
                            },
                            _vm._l(_vm.addonSummations, function(addon) {
                              return _c(
                                "div",
                                {
                                  key: addon.title + "-addon-summation",
                                  staticClass:
                                    "vehicle-configurator__summation__row"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "properties" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "properties__item properties__item--header"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "properties__label"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(addon.title) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("SummationList", {
                                        attrs: {
                                          lang: _vm.lang,
                                          list: addon.summation
                                        }
                                      }),
                                      _vm._v(" "),
                                      addon.meta
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vehicle-configurator__summation__sub-notice"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(addon.meta) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "vehicle-configurator__summation__row" },
                        [
                          _c(
                            "div",
                            { staticClass: "properties" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "properties__item properties__item--header"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "properties__label" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.lang.Summation
                                              .FinancingSummationHeading
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("SummationList", {
                                attrs: {
                                  lang: _vm.lang,
                                  financing:
                                    _vm.financingSummary.financing[
                                      _vm.selectedFinancingOption
                                    ],
                                  selectedFinancing: _vm.selectedFinancingOption
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.priceSummation
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "vehicle-configurator__summation__row vehicle-configurator__summation__row--black-border"
                          },
                          [
                            _vm.isFinancing
                              ? _c("div", { staticClass: "total-price" }, [
                                  _c(
                                    "div",
                                    { staticClass: "total-price__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "total-price__title" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.lang.Summation
                                                  .VehicleConfiguratorTotalPerMonth
                                              ) +
                                              "\n                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "total-price__title--meta"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.priceSummation.totals
                                                    .totalMeta
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "price-tag",
                                          class: _vm.attentionTotal
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$formatPriceNoSuffix(
                                                  _vm.priceSummation.totals
                                                    .totalMonthlyCost
                                                ) +
                                                  _vm.priceSummation
                                                    .suffixMonthly
                                              ) +
                                              "\n                "
                                          ),
                                          _vm.priceSummation.totals
                                            .totalOrdinaryMonthly &&
                                          _vm.priceSummation.totals
                                            .totalOrdinaryMonthly >
                                            _vm.priceSummation.totals
                                              .totalMonthlyCost
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "price-tag price-tag--obsolete"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$formatPriceNoSuffix(
                                                        _vm.priceSummation
                                                          .totals
                                                          .totalOrdinaryMonthly
                                                      ) +
                                                        _vm.priceSummation
                                                          .suffixMonthly
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "total-price__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "total-price__title" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.lang.Summation
                                                  .VehicleConfiguratorTotal
                                              ) +
                                              "\n                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "total-price__title--meta"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.priceSummation.totals
                                                    .totalMeta
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "price-tag",
                                          class: {
                                            "price-tag--discount":
                                              _vm.priceSummation.totals
                                                .totalOrdinaryCash &&
                                              _vm.priceSummation.totals
                                                .totalOrdinaryCash >
                                                _vm.priceSummation.totals
                                                  .totalCash
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$formatPriceNoSuffix(
                                                  _vm.priceSummation.totals
                                                    .totalCash
                                                ) + _vm.priceSummation.suffix
                                              ) +
                                              "\n                "
                                          ),
                                          _vm.priceSummation.totals
                                            .totalOrdinaryCash &&
                                          _vm.priceSummation.totals
                                            .totalOrdinaryCash >
                                            _vm.priceSummation.totals.totalCash
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "price-tag price-tag--obsolete"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$formatPriceNoSuffix(
                                                        _vm.priceSummation
                                                          .totals
                                                          .totalOrdinaryCash
                                                      ) +
                                                        _vm.priceSummation
                                                          .suffix
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              : _c("div", { staticClass: "total-price" }, [
                                  _c(
                                    "div",
                                    { staticClass: "total-price__row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "total-price__title" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.lang.Summation
                                                  .VehicleConfiguratorTotal
                                              ) +
                                              "\n                "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "total-price__title--meta"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.priceSummation.totals
                                                    .totalMeta
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "price-tag",
                                          class: {
                                            "price-tag--discount":
                                              _vm.priceSummation.totals
                                                .totalOrdinaryCash &&
                                              _vm.priceSummation.totals
                                                .totalOrdinaryCash >
                                                _vm.priceSummation.totals
                                                  .totalCash
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$formatPriceNoSuffix(
                                                  _vm.priceSummation.totals
                                                    .totalCash
                                                ) + _vm.priceSummation.suffix
                                              ) +
                                              "\n                "
                                          ),
                                          _vm.priceSummation.totals
                                            .totalOrdinaryCash &&
                                          _vm.priceSummation.totals
                                            .totalOrdinaryCash >
                                            _vm.priceSummation.totals.totalCash
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "price-tag price-tag--obsolete"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$formatPriceNoSuffix(
                                                        _vm.priceSummation
                                                          .totals
                                                          .totalOrdinaryCash
                                                      ) +
                                                        _vm.priceSummation
                                                          .suffix
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.priceSummation.totals.totalMonthlyCost > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "total-price__row" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "total-price__title"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.lang.Summation
                                                      .VehicleConfiguratorTotalPerMonth
                                                  ) +
                                                  "\n                "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "total-price__title--meta"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.priceSummation.totals
                                                        .totalMeta
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "price-tag",
                                              class: _vm.attentionTotal
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$formatPriceNoSuffix(
                                                      _vm.priceSummation.totals
                                                        .totalMonthlyCost
                                                    ) +
                                                      _vm.priceSummation
                                                        .suffixMonthly
                                                  ) +
                                                  "\n                "
                                              ),
                                              _vm.priceSummation.totals
                                                .totalOrdinaryMonthly &&
                                              _vm.priceSummation.totals
                                                .totalOrdinaryMonthly >
                                                _vm.priceSummation.totals
                                                  .totalMonthlyCost
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "price-tag price-tag--obsolete"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$formatPriceNoSuffix(
                                                            _vm.priceSummation
                                                              .totals
                                                              .totalOrdinaryMonthly
                                                          ) +
                                                            _vm.priceSummation
                                                              .suffixMonthly
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("SummationContact", {
                      attrs: {
                        department: _vm.department,
                        lang: _vm.lang,
                        selectedFinancingOption: _vm.selectedFinancingOption,
                        calculation: _vm.calculation,
                        isPurchasable: this.storeState.contact.displayPurchase
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "properties__item properties__item--header" },
      [
        _c("div", { staticClass: "properties__label" }, [
          _vm._v("\n                  INBYTESBIL\n                ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }