var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component.documents.length > 0
    ? _c("div", [
        _vm.listHeading
          ? _c("div", { staticClass: "search-results__heading" }, [
              _vm._v("\n    " + _vm._s(_vm.listHeading) + "\n  ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.component.id === _vm.I3FederatedSearchTypes.Cars
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "search-results__container search-results__container--column"
                },
                _vm._l(_vm.component.documents.slice(0, 2), function(document) {
                  return _c(
                    "div",
                    { key: document._id, staticClass: "search-results__item" },
                    [
                      _c(
                        _vm.I3FederatedSearchTypeComponents[_vm.component.id],
                        { tag: "v-component", attrs: { document: document } }
                      )
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.component.documents.length > 2
                ? _c("div", { staticClass: "search-results__btn" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn btn--primary btn--block",
                        on: {
                          click: function($event) {
                            return _vm.$emit("onClick")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              "Visa alla bilar (" +
                                _vm.component.numberOfHits +
                                ")"
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _c(
              "div",
              { staticClass: "search-results__container" },
              _vm._l(_vm.component.documents, function(document) {
                return _c(
                  "div",
                  { key: document._id, staticClass: "search-results__item" },
                  [
                    _c(_vm.I3FederatedSearchTypeComponents[_vm.component.id], {
                      tag: "v-component",
                      attrs: {
                        document: document,
                        resultType: _vm.component.id
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }