<template>
  <div class="i3-filter__search-view" v-if="popularSearches.length">
    <label>
      Populära sökningar
    </label>
    <div class="btn-list">
      <div v-for="search in popularSearches"
        class="btn btn--white btn--sm"
        :key="search.query"
        @click="$emit('select', search)">
        {{search.name}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popularSearches: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
