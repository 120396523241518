<template>
  <a :href="product.permalink">
    <div class="mini-product-spot">
      <div class="mini-product-spot__thumbnail mini-product-spot__thumbnail--rounded">
        <img :src="productImages[0]" alt="Produktbild" />
      </div>
      <div class="mini-product-spot__content mini-product-spot__content--mini">
        <div class="mini-product-spot__header">
          {{product.brandModelTitle}}
        </div>
        <div class="mini-product-spot__sub-title">
          {{product.shortModelText}}
        </div>
        <div>
          <div class="price-tag" :class="{'price-tag--discount': product.discount}">
            {{$formatPrice(product.currentPrice)}}
          </div>

          <div class="price-tag price-tag--obsolete" v-if="product.discount">
            {{$formatPrice(product.ordinaryPrice)}}
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import I3FederatedSearchComponents from '../../constants/I3FederatedSearchComponents';
import I3FederatedSearchTypes from '../../constants/I3FederatedSearchTypes';
import ImageResizerPresets from '../../constants/ImageResizerPresets';

export default {
  props: {
    document: Object,
  },
  computed: {
    product() {
      return new I3FederatedSearchComponents[I3FederatedSearchTypes.Cars](this.document);
    },

    productImages() {
      let fallbackImage = this.product.fallbackImage;
      return this.product.images.map(image => `${image}?${ImageResizerPresets.ProductSpot}&404=${fallbackImage}`);
    },
  },
}
</script>
