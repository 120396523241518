import GtmActions from "../constants/GtmActions";
import GtmEvents from "../constants/GtmEvents";
import ContactRequestTypes from "../constants/ContactRequestTypes";
import ContactResponseTypes from "../constants/ContactResponseTypes";
import TradeInSectionTypes from "../apps/trade-in/constants/SectionTypes";
import ServiceBookingSectionTypes from "../apps/service-booking/constants/SectionTypes";
import ServiceBookingV2SectionTypes from "../apps/service-booking-v2/constants/SectionTypes";
import TyreBookingSectionTypes from "../apps/tyre-booking/constants/SectionTypes";
import DamageInspectionSectionTypes from "../apps/damage-report/constants/SectionTypes";
import ServiceAgreementSectionTypes from "../apps/service-agreement/constants/SectionTypes";
import GlassRepairSectionTypes from "../apps/glass-repair/constants/SectionTypes";
import SparePartsSectionTypes from "../apps/spare-parts/constants/SectionTypes";
import ACCleaningSectionTypes from "../apps/ac-cleaning/constants/SectionTypes";
import RimRepairSectionTypes from "../apps/rim-repair/constants/SectionTypes";
import ServiceControlSectionTypes from "../apps/service-control/constants/SectionTypes";
import debounce from "./debounce";

const getStep = (order, variant = 1) => `${order}:${variant}`;
const tradeInCompletedSectionActions = {
  [TradeInSectionTypes.Valuation]: GtmActions.ConfirmValuation,
  [TradeInSectionTypes.Lookup]: GtmActions.SubmitRegNr,
  [TradeInSectionTypes.Details]: GtmActions.SubmitCarInfo,
  [TradeInSectionTypes.Images]: GtmActions.SubmitCarImage,
  [TradeInSectionTypes.Contact]: GtmActions.SubmitTradeIn
};
const serviceBookingCompletedSectionActions = {
  [ServiceBookingSectionTypes.Lookup]: GtmActions.SubmitRegNr,
  [ServiceBookingSectionTypes.Services]: GtmActions.SubmitServices,
  [ServiceBookingSectionTypes.Department]: GtmActions.SubmitDepartment,
  [ServiceBookingSectionTypes.Date]: GtmActions.SubmitDate,
  [ServiceBookingSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [ServiceBookingSectionTypes.Addons]: GtmActions.SubmitBooking
};
const serviceBookingV2CompletedSectionActions = {
  [ServiceBookingV2SectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [ServiceBookingV2SectionTypes.Department]: GtmActions.SubmitDepartment,
  [ServiceBookingV2SectionTypes.Services]: GtmActions.SubmitServices,
  [ServiceBookingV2SectionTypes.Addons]: GtmActions.SubmitAddons,
  [ServiceBookingV2SectionTypes.Date]: GtmActions.SubmitDate,
  [ServiceBookingV2SectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [ServiceBookingV2SectionTypes.Summary]: GtmActions.SubmitBooking
};
const glassRepairCompletedSectionActions = {
  [GlassRepairSectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [GlassRepairSectionTypes.Department]: GtmActions.SubmitDepartment,
  [GlassRepairSectionTypes.Services]: GtmActions.SubmitServices,
  [GlassRepairSectionTypes.Addons]: GtmActions.SubmitAddons,
  [GlassRepairSectionTypes.Date]: GtmActions.SubmitDate,
  [GlassRepairSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [GlassRepairSectionTypes.Summary]: GtmActions.SubmitBooking
};
const tyreBookingCompletedSectionActions = {
  [TyreBookingSectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [TyreBookingSectionTypes.Department]: GtmActions.SubmitDepartment,
  [TyreBookingSectionTypes.TyreServices]: GtmActions.SubmitServices,
  [TyreBookingSectionTypes.Date]: GtmActions.SubmitDate,
  [TyreBookingSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [TyreBookingSectionTypes.Summary]: GtmActions.SubmitBooking
};
const damageInspectionCompletedSectionActions = {
  [DamageInspectionSectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [DamageInspectionSectionTypes.Department]: GtmActions.SubmitDepartment,
  [DamageInspectionSectionTypes.TypeOfReport]: GtmActions.SubmitServices,
  [DamageInspectionSectionTypes.Date]: GtmActions.SubmitDate,
  [DamageInspectionSectionTypes.Images]: GtmActions.SubmitInfo,
  [DamageInspectionSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [DamageInspectionSectionTypes.Summary]: GtmActions.SubmitBooking
};
const serviceAgreementCompletedSectionActions = {
  [ServiceAgreementSectionTypes.Lookup]: GtmActions.SubmitRegNr,
  [ServiceAgreementSectionTypes.Details]: GtmActions.SubmitMileage,
  [ServiceAgreementSectionTypes.Period]: GtmActions.SubmitOptions,
  [ServiceAgreementSectionTypes.Contact]: GtmActions.SubmitServiceAgrement
};
const sparePartsCompletedSectionActions = {
  [SparePartsSectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [SparePartsSectionTypes.Department]: GtmActions.SubmitDepartment,
  [SparePartsSectionTypes.SpareParts]: GtmActions.SubmitSparePartsDetails,
  [SparePartsSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [SparePartsSectionTypes.Summary]: GtmActions.SubmitSparePartsForm
};
const rimRepairCompletedSectionActions = {
  [RimRepairSectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [RimRepairSectionTypes.Department]: GtmActions.SubmitDepartment,
  [RimRepairSectionTypes.RimSize]: GtmActions.SubmitRimRepairSizeDetails,
  [RimRepairSectionTypes.RimSize]: GtmActions.SubmitRimRepairTypeDetails,
  [RimRepairSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [RimRepairSectionTypes.Summary]: GtmActions.SubmitRimRepairForm
};
const serviceControlCompletedSectionActions = {
  [ServiceControlSectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [ServiceControlSectionTypes.Department]: GtmActions.SubmitDepartment,
  [ServiceControlSectionTypes.RimSize]: GtmActions.SubmitRimRepairSizeDetails,
  [ServiceControlSectionTypes.RimSize]: GtmActions.SubmitRimRepairTypeDetails,
  [ServiceControlSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [ServiceControlSectionTypes.Summary]: GtmActions.SubmitRimRepairForm
};
const acCleaningCompletedSectionActions = {
  [ACCleaningSectionTypes.Vehicle]: GtmActions.SubmitRegNr,
  [ACCleaningSectionTypes.Department]: GtmActions.SubmitDepartment,
  [ACCleaningSectionTypes.Services]: GtmActions.SubmitServices,
  [ACCleaningSectionTypes.Addons]: GtmActions.SubmitAddons,
  [ACCleaningSectionTypes.Date]: GtmActions.SubmitDate,
  [ACCleaningSectionTypes.Contact]: GtmActions.SubmitContactInfo,
  [ACCleaningSectionTypes.Summary]: GtmActions.SubmitBooking
};

export default {
  phoneNumberClick({
    departmentNr,
    phoneNr,
    vehicleCondition,
    brandName,
    formType
  }) {
    return push({
      action: GtmActions.ClickPhoneNumber,
      event: GtmEvents.Contact,
      departmentNr,
      label: phoneNr,
      vehicleCondition,
      brandName,
      formType,
      requestType: ContactRequestTypes.None
    });
  },
  displayPhoneNumber({ formType }) {
    return push({
      action: GtmActions.DisplayPhoneNumber,
      event: GtmEvents.Engagement,
      formType,
      requestType: ContactRequestTypes.None
    });
  },
  serviceAgreementSubmit({ brandName }) {
    return push({
      action: GtmActions.SubmitServiceAgrement,
      event: GtmEvents.Contact,
      brandName
    });
  },
  sparePartsRequestSubmit({ formType }) {
    return push({
      action: GtmActions.SubmitSparePartsForm,
      event: GtmEvents.Contact,
      formType
    });
  },
  rimRepairRequestSubmit({ formType }) {
    return push({
      action: GtmActions.SubmitRimRepairForm,
      event: GtmEvents.Contact,
      formType
    });
  },
  messageClick({ departmentNr, vehicleCondition, brandName, formType }) {
    return push({
      action: GtmActions.ClickMessage,
      event: GtmEvents.Engagement,
      departmentNr,
      vehicleCondition,
      brandName,
      formType,
      requestType: ContactRequestTypes.AskQuestion
    });
  },
  reviewClick({ departmentNr }) {
    return push({
      action: GtmActions.ClickReview,
      event: GtmEvents.Engagement,
      departmentNr
    });
  },
  searchEvent({ searchTerm }) {
    searchTerm = searchTerm.toLowerCase();
    return push({
      event: GtmEvents.Search,
      searchTerm
    });
  },
  submitSearchWatch() {
    return push({
      event: GtmEvents.Engagement,
      action: GtmActions.SubmitSearchWatch,
      formType: "searchWatch"
    });
  },
  submitSearchWatchContact({ departmentNr, brandName }) {
    return push({
      event: GtmEvents.Contact,
      action: GtmActions.SubmitEmail,
      formType: "searchWatch",
      departmentNr,
      brandName
    });
  },
  saveContact({ departmentNr, employeeId }) {
    return push({
      action: GtmActions.SaveContact,
      event: GtmEvents.Engagement,
      departmentNr,
      label: employeeId
    });
  },
  share({ departmentNr, employeeId }) {
    return push({
      action: GtmActions.Share,
      event: GtmEvents.Engagement,
      departmentNr,
      label: employeeId
    });
  },
  directionsLinkClick({ departmentNr, vehicleCondition, brandName, formType }) {
    return push({
      action: GtmActions.ClickNavigation,
      event: GtmEvents.Contact,
      departmentNr,
      vehicleCondition,
      brandName,
      formType
    });
  },
  contactFormSubmit(params) {
    return this.contactSubmit({
      formType: "contactForm",
      ...params
    });
  },
  vehicleContactSubmit(params) {
    return this.contactSubmit({
      formType: "vehicleForm",
      ...params
    });
  },
  vehiclePurchaseSubmit(params) {
    return this.purchaseSubmit({
      formType: "vehicleForm",
      ...params
    });
  },
  valuationFormSubmit(params) {
    return push({
      action: GtmActions.SubmitQuickTradeIn,
      event: GtmEvents.TradeIn,
      pageType: window.CurrentPageType,
      ...params,
    })
  },
  configuratorSubmit(params) {
    switch (params.requestType) {
      case ContactRequestTypes.Purchase:
        return this.purchaseSubmit({
          formType: "configForm",
          ...params
        });

      default:
        return this.contactSubmit({
          formType: "configForm",
          ...params
        });
    }
  },
  contactSubmit({
    formType,
    departmentNr,
    requestType,
    responseType,
    vehicleCondition,
    brandName,
    vehicleType,
    requestTyre,
    requestTradeIn,
    requestServiceAgreement,
    requestAddons,
    financingType
  }) {
    return push({
      action: responseType
        ? parseInt(responseType, 10) === ContactResponseTypes.Email
          ? GtmActions.SubmitEmail
          : GtmActions.SubmitCallBack
        : parseInt(requestType, 10) === ContactRequestTypes.Callup
          ? GtmActions.SubmitCallBack
          : GtmActions.SubmitEmail,
      event: GtmEvents.Contact,
      departmentNr,
      requestType,
      formType,
      vehicleCondition: window.vehicleData
        ? window.vehicleData.vehicleCondition
        : vehicleCondition,
      brandName: window.vehicleData ? window.vehicleData.brandName : brandName,
      vehicleType: window.vehicleData
        ? window.vehicleData.vehicleType
        : vehicleType,
      requestTyre,
      requestTradeIn,
      requestServiceAgreement,
      requestAddons,
      financingType
    });
  },
  purchaseSubmit({
    formType,
    departmentNr,
    requestType,
    vehicleCondition,
    brandName,
    vehicleType
  }) {
    return push({
      action: GtmActions.SubmitPurchase,
      event: GtmEvents.Contact,
      departmentNr,
      requestType,
      formType,
      vehicleCondition: window.vehicleData
        ? window.vehicleData.vehicleCondition
        : vehicleCondition,
      brandName: window.vehicleData ? window.vehicleData.brandName : brandName,
      vehicleType: window.vehicleData
        ? window.vehicleData.vehicleType
        : vehicleType
    });
  },
  vehicleQuickbuyClick({
    departmentNr,
    carStatus,
    vehicleCondition,
    brandName,
    vehicleType
  }) {
    return push({
      action: GtmActions.LoadQuickbuy,
      event: GtmEvents.Engagement,
      departmentNr,
      carStatus,
      vehicleCondition,
      brandName,
      vehicleType
    });
  },
  vehicleMoreInfoClick({
    departmentNr,
    vehicleCondition,
    brandName,
    vehicleType
  }) {
    return push({
      action: GtmActions.ClickMoreInfo,
      event: GtmEvents.Engagement,
      departmentNr,
      vehicleCondition,
      brandName,
      vehicleType
    });
  },
  vehicleContactClick({
    carStatus,
    departmentNr,
    label,
    vehicleCondition,
    brandName,
    vehicleType
  }) {
    return push({
      action: GtmActions.GetContactBox,
      event: GtmEvents.Engagement,
      departmentNr,
      carStatus,
      label,
      vehicleCondition,
      brandName,
      vehicleType
    });
  },
  contactModalClick({ departmentNr, label }) {
    return push({
      action: GtmActions.GetContactBox,
      event: GtmEvents.Engagement,
      departmentNr,
      label
    });
  },
  quickContactModalClick({ departmentNr }) {
    return push({
      action: GtmActions.GetContactBox,
      event: GtmEvents.Engagement,
      departmentNr,
      formType: "quickForm"
    });
  },
  shareModalClick({ departmentNr, label }) {
    return push({
      action: GtmActions.Share,
      event: GtmEvents.Engagement,
      departmentNr,
      label
    });
  },
  configuratorStickyClick({ departmentNr, label, carStatus }) {
    return push({
      action: GtmActions.GetContactBox,
      event: GtmEvents.Engagement,
      departmentNr,
      label,
      carStatus
    });
  },
  sparePartsFormSubmit({ departmentNr, brandName, formType }) {
    return push({
      action: GtmActions.SubmitSparePartsForm,
      event: GtmEvents.Contact,
      departmentNr,
      brandName,
      formType
    });
  },
  serviceBookingV2SectionCompleted({
    section,
    departmentNr,
    serviceType,
    addonType,
    brandName
  }) {
    return push({
      action: serviceBookingV2CompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: section.order,
      bookingService: "service",
      serviceType,
      addonType,
      departmentNr,
      brandName
    });
  },
  glassRepairSectionCompleted({
    section,
    departmentNr,
    serviceType,
    addonType,
    brandName
  }) {
    return push({
      action: glassRepairCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: section.order,
      bookingService: "glas",
      serviceType,
      addonType,
      departmentNr,
      brandName
    });
  },
  acCleaningSectionCompleted({
    section,
    departmentNr,
    serviceType,
    addonType,
    brandName
  }) {
    return push({
      action: acCleaningCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: section.order,
      bookingService: "ac-cleaning",
      serviceType,
      addonType,
      departmentNr,
      brandName
    });
  },
  sparePartsSectionCompleted({
    section,
    departmentNr,
    brandName
  }) {
    return push({
      action: sparePartsCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: section.order,
      bookingService: "spareParts",
      departmentNr,
      brandName
    });
  },
  rimRepairSectionCompleted({
    section,
    departmentNr,
    brandName
  }) {
    return push({
      action: rimRepairCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: section.order,
      bookingService: "rimRepair",
      departmentNr,
      brandName
    });
  },
  serviceControlSectionCompleted({
    section,
    departmentNr,
    brandName
  }) {
    return push({
      action: serviceControlCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: section.order,
      bookingService: "serviceControl",
      departmentNr,
      brandName
    });
  },
  tyreBookingSectionCompleted({
    section,
    departmentNr,
    serviceType,
    addonType,
    brandName
  }) {
    return push({
      action: tyreBookingCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: getStep(section.order),
      bookingService: "tyre",
      serviceType,
      addonType,
      departmentNr,
      brandName
    });
  },
  damageInspectionSectionCompleted({
    section,
    departmentNr,
    serviceType,
    brandName
  }) {
    return push({
      action: damageInspectionCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: getStep(section.order, serviceType === "express" ? 2 : 1),
      bookingService: "damage",
      serviceType,
      departmentNr,
      brandName
    });
  },
  serviceBookingSectionCompleted({ section, departmentNr }) {
    return push({
      action: serviceBookingCompletedSectionActions[section.type],
      event: GtmEvents.Booking,
      step: getStep(section.order),
      bookingService: "service",
      departmentNr
    });
  },
  serviceBookingExternalClick({ brand, department }) {
    return push({
      action: GtmActions.SubmitExternalBooking,
      event: GtmEvents.Booking,
      bookingService: "service",
      brandName: brand,
      departmentNr: department,
    });
  },
  configuratorQuickTradeInActivate() {
    return push({
      action: "activateQuickTradeIn",
      event: GtmEvents.TradeIn
    });
  },
  configuratorQuickTradeIn() {
    return push({
      action: "submitQuickTradeIn",
      event: GtmEvents.TradeIn
    });
  },
  tradeInRegnrSectionCompleted() {
    return push({
      action: tradeInCompletedSectionActions[TradeInSectionTypes.Lookup],
      event: GtmEvents.TradeIn,
      step: getStep(1, 0)
    });
  },
  tradeInSectionCompleted({ section }) {
    return push({
      action: tradeInCompletedSectionActions[section.type],
      event: GtmEvents.TradeIn,
      step: getStep(section.order)
    });
  },
  serviceAgreementSectionCompleted({ section }) {
    return push({
      action: serviceAgreementCompletedSectionActions[section.type],
      event: GtmEvents.ServiceAgreement,
      step: getStep(section.order)
    });
  },
  openServiceBookingFromBlock() {
    return push({
      action:
        serviceBookingCompletedSectionActions[
        ServiceBookingSectionTypes.Lookup
        ],
      event: GtmEvents.Booking,
      step: getStep(1, 2),
      bookingService: "service"
    });
  },
  openTradeInFromVehiclePage() {
    return push({
      action: tradeInCompletedSectionActions[TradeInSectionTypes.Lookup],
      event: GtmEvents.TradeIn,
      step: getStep(1, 2)
    });
  },
  vehicleSimilarVehicleClick(vehicleData) {
    return push({
      action: GtmActions.ClickSimilarVehicle,
      event: GtmEvents.Engagement,
      ...vehicleData
    });
  },
  vehicleHistoryGetReportSubmit(vehicleData) {
    return push({
      action: GtmActions.GetReport,
      event: GtmEvents.Carfax,
      ...vehicleData
    });
  },
  vehicleHistoryShowReportClick(vehicleData) {
    return push({
      action: GtmActions.ClickReportLink,
      event: GtmEvents.Carfax,
      ...vehicleData
    });
  },
  vehicleHistoryContactSubmit(vehicleData) {
    return push({
      action: GtmActions.SubmitCallBack,
      event: GtmEvents.Carfax,
      ...vehicleData
    });
  },
  vehicleDeclarationClick() {
    return push({
      action: GtmActions.ClickVehicleDeclaration,
      event: GtmEvents.Engagement
    });
  },
  tireCardLoad(event, data) {
    return push({
      event,
      ecommerce: data
    });
  },
  tireCardEvent(event, data, callback) {
    return push({
      event,
      ecommerce: data,
      eventCallback: callback,
      eventTimeout: 500
    });
  },
  ecommerceEvent(event, data, callback) {
    return push({
      event,
      ecommerce: data,
      eventCallback: callback && debounce(callback, 500)()
    });
  },
  trackEcommercePurchase(event, data) {
    return push({
      event,
      ecommerce: data
    });
  },
  incomingSubmitPLForm({
    brandName,
    location
  }) {
    return push({
      action: GtmActions.SubmitEmail,
      event: GtmEvents.Contact,
      formType: "incomingForm",
      location,
      brandName
    });
  },
};

const push = data => {
  const deferred = $.Deferred();
  const vehicleData = window.vehicleData || {};
  const params = {
    pageType: window.CurrentPageType,
    eventCallback() {
      deferred.resolve();
    },
    eventTimeout: 2000,
    ...vehicleData,
    ...data
  };

  Object.keys(params).forEach(
    key => params[key] === undefined && delete params[key]
  );

  // console.log('GTM: pushing', params);

  if (window.dataLayer) {
    window.dataLayer.push(params);
    // console.log('GTM: pushed');
  } else {
    deferred.resolve();
    console.error("GTM: dataLayer unavailable!");
  }

  return deferred.promise();
};
