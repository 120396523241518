<template>
  <VcFormStack
    :label="option.heading"
    :required="option.required">
    <div class="form-select">
      <select
        class="form-control"
        :value="selectedValue"
        @change="$emit('change', $event.target.value)">
        <option v-for="value in option.values"
          :key="value.name"
          :value="value.value">
          {{value.name}}
        </option>
      </select>
    </div>
  </VcFormStack>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormStack,
  },
  props: {
    option: Object,
    default: () => {}
  },
  computed: {
    selectedValue() {
      const selected = this.option.values.find(v => v.selected);
      return selected && selected.value;
    },

    onChange() {
      const opt = this.option.values.find(value => value.value === val);
      this.$emit('change', opt);
    },
  },
}
</script>
