export default class I3DocEmployee {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc._id;
  }

  get employeeId() {
    return this._doc.EmployeeId;
  }

  get firstName() {
    return this._doc.FirstName;
  }

  get lastName() {
    return this._doc.LastName;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get image() {
    return this._doc.Image;
  }

  get title() {
    return this._doc.Title;
  }

  get permalink() {
    return this._doc.Url;
  }

  get location() {
    return this._doc.Location;
  }

  get modalUrl() {
    return this._doc.ModalUrl;
  }

  get digitalBusinessCard() {
    return this._doc.DigitalBusinessCard;
  }
}
