<template>
  <component :is="GridItemTypeComponents[type]"
    :document="document">
  </component>
</template>

<script>
import GridItemTypeComponents from './constants/GridItemTypeComponents';

export default {
  props: {
    type: Number,
    document: Object,
  },
  data() {
    return {
      GridItemTypeComponents,
    };
  },
}
</script>
