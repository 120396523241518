<template>
  <VcModal @close="$emit('modalClose')">
    <div slot="header">
      {{modalHeader}}
    </div>
    <div slot="content" class="choose-vehicle">
      <VcFormStack label="Bilmärke">
        <VcFormSelect
          prompt="Välj bilmärke"
          :options="manufacturerOptions"
          :value="manufacturer"
          valueProp="id"
          valueType="number"
          labelProp="name"
          @change="selectManufacturer">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Bilmodell">
        <VcFormSelect
          ref="modelSelector"
          prompt="Välj bilmodell"
          :options="modelOptions"
          :value="model"
          valueProp="id"
          valueType="string"
          labelProp="name"
          @change="selectModel">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Bilmotor" v-if="displaySelectVariant">
        <VcFormSelect
          prompt="Välj bilmotor"
          :options="variantOptions"
          :value="variant"
          valueProp="id"
          valueType="string"
          labelProp="name"
          @change="selectVariant">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Paket" v-if="displaySelectPackageType">
        <VcFormSelect
          prompt="Välj paket"
          :options="packageOptions"
          :value="packageType"
          valueProp="label"
          valueType="string"
          labelProp="label"
          @change="selectPackageType">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Produkt">
        <VcFormSelect
          :options="[{ value: 'tyre', label: lang.Tyre.TyreLinkBtn }, { value: 'wheel', label: lang.Tyre.WheelLinkBtn }, { value: 'rim', label: lang.Tyre.RimLinkBtn }]"
          :value="productType"
          labelProp="label"
          valueProp="value"
          valueType="string"
          required
          @change="productType = $event">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Säsong">
        <VcFormSelect
          :options="[{ value: EonTyreTypes.Summer, label: 'Sommar' }, { value: EonTyreTypes.WinterStudded, label: 'Vinter dubb' }, { value: EonTyreTypes.WinterFriction, label: 'Vinter friktion' }]"
          :value="tyreType"
          labelProp="label"
          valueProp="value"
          valueType="number"
          required
          @change="tyreType = $event">
        </VcFormSelect>
      </VcFormStack>
      <div
        class="btn btn--primary btn--block choose-spec__multi__btn"
        :class="{'disabled': !buttonEnabled}"
        @click="onSubmit()"
      >
        {{ "Se produkter" }}
      </div>
    </div>
  </VcModal>

</template>

<script>
import { VcFormSelect, VcFormStack, VcModal } from '@holmgrensbil/vue-components';
import EonApi from '../../models/EonApi';
import EonTyreTypes from "../eon-filter-page/constants/EonTyreTypes";

export default {
  components: {
    VcFormSelect,
    VcFormStack,
    VcModal,
  },
  props: {
    TyreSpec: Object,
    tyreButtonHref: String,
    rimButtonHref: String,
    wheelButtonHref: String,
    modalHeader: String,
    lang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      manufacturer: null,
      manufacturerOptions: [],

      model: null,
      modelOptions: [],

      variant: null,
      variantOptions: [],

      packageType: null,
      packageOptions: [],

      displayDirectly: false,

      vehicle: null,
      searchVehicleId: null,

      productType: { value: 'tyre', label: this.lang.Tyre.TyreLinkBtn },
      tyreType: { value: EonTyreTypes.Summer, label: "Sommar" },

      EonTyreTypes
    };
  },
  created() {
    this.onCreated();
  },
  computed: {
    buttonEnabled() {
      return (this.manufacturer && this.model && this.variant && this.packageType !== null) || this.displayDirectly;
    },

    displaySelectVariant() {
      if (!this.displayDirectly && this.variantOptions.length === 1) {
        if (this.variantOptions[0].name === "") {
          return false;
        }
      }

      return !!this.variantOptions.length;
    },
    displaySelectPackageType() {
      if (this.packageOptions.length === 1) {
        if (this.packageOptions[0].label === "") {
          return false;
        }
      }

      return !!this.packageOptions.length;
    },
  },
  methods: {
    async onCreated() {
      const res = await EonApi.getManufacturers();
      this.manufacturerOptions = res.data;

      this.tyreType = window.EonConfig.isWinterSeason
        ? { value: EonTyreTypes.WinterFriction, label: 'Vinter friktion' }
        : { value: EonTyreTypes.Summer, label: 'Sommar' };
    },

    async selectManufacturer(manufacturer) {
      this.manufacturer = manufacturer;
      const res = await EonApi.getModels(this.manufacturer.id);
      this.modelOptions = res.data;

      this.model = null;

      this.displayDirectly = false;

      this.variant = null;
      this.variantOptions = [];

      this.packageType = null;
      this.packageOptions = [];

      this.vehicle = null;
      this.$refs.modelSelector.$el.children[0].focus()
    },

    async selectModel(model) {
      this.model = model;

      this.variant = null;

      this.packageType = null;
      this.packageOptions = [];

      this.vehicle = null;

      if (model.vehicleId) {

        this.variantOptions = [];
        const res = await EonApi.getCarByModel(model.vehicleId);

        this.vehicle = res.data;
        this.searchVehicleId = model.vehicleId;
        return this.displayDirectly = true;
      }
      const res = await EonApi.getVariants(this.model.id);
      this.variantOptions = res.data;

      if (this.variantOptions.length === 1) {
        this.selectVariant(this.variantOptions[0]);
      }
    },

    async selectVariant(variant) {
      this.variant = variant;
      const res = await EonApi.getCarByVariant(this.variant.id);

      this.vehicle = res.data
      this.searchVehicleId = this.variant.id;
      this.defaultVehicle = res.data
      this.packageOptions = res.data.options;
      if(!this.packageOptions.length) {
        return this.displayDirectly = true;
      }
    },

    selectPackageType(packageType) {
      this.packageType = packageType;

      if (packageType.default) {
        return this.vehicle = this.defaultVehicle;
      }

      const newVehicle = {
        ...this.vehicle,
        rimSizes: this.vehicle.rimSizes.filter(rimSize => Number(rimSize.diameter) >= Number(packageType.diameterFrom) && Number(rimSize.diameter) <= Number(packageType.diameterTo)),
        tyreSizes: this.vehicle.tyreSizes.filter(tyreSize => Number(tyreSize.diameter) >= Number(packageType.diameterFrom) && Number(tyreSize.diameter) <= Number(packageType.diameterTo))
      };
      this.vehicle = newVehicle;
    },

    async onSubmit() {
      if (this.buttonEnabled) {
        this.TyreSpec.setCarManually(this.vehicle, this.searchVehicleId);
        let href = '';
  
        switch (this.productType.value) {
          case 'tyre': {
            href = this.tyreButtonHref;
            break;
          }
          case 'rim': {
            href = this.rimButtonHref;
            break;
          }
          case 'wheel': {
            href = this.wheelButtonHref;
            break;
          }
          default: break;
        }
  
        window.location.href = href + `&tyreType=${this.tyreType.value}`;
      }
    },
  },
}
</script>
