import Axios from '../axios';

export const uploadImage = (file, folder) => {
  const key = 'b190c6e0af964956ae8f60a439a788b2';
  const endpoint = 'https://imageupload.holmgrensbil.se/api/upload/image';
  const url = `${endpoint}?key=${key}`;
  const headers = { 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();

  formData.append('file', file);
  formData.append('folderName', folder);

  return Axios.post(url, formData, { headers });
}

export const deleteImage = deleteKey => {
  const url = 'https://imageupload.holmgrensbil.se/api/upload/delete';
  const data = {
    deleteKey,
  };

  return Axios.post(url, data);
}
