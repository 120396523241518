import gtm from "../utils/gtm";

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').
      on('click', '.vdn-report-link', this.onClick.bind(this));
  },

  async onClick(e) {
    e.preventDefault();

    await gtm.vehicleDeclarationClick();

    const $el = $(e.currentTarget);
    window.open($el.attr('href'), '_blank');
  },
};
