import { render, staticRenderFns } from "./App.vue?vue&type=template&id=63bb97f0&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\agent\\_work\\1\\s\\src\\Holmgrens.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63bb97f0')) {
      api.createRecord('63bb97f0', component.options)
    } else {
      api.reload('63bb97f0', component.options)
    }
    module.hot.accept("./App.vue?vue&type=template&id=63bb97f0&", function () {
      api.rerender('63bb97f0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/apps/eon-result/App.vue"
export default component.exports