<template>
  <TyreBuildSectionWrapper
    :selected="selected"
    :isAddon="isAddon"
    :title="title"
  >
    <div class="build-tyre__content-container">
      <div class="build-tyre__main">
        <div
          v-if="!mobile"
          class="build-tyre__main__image"
          :class="{
            'build-tyre__main__image--placeholder': !product,
          }"
        >
          <img v-if="product && product.imageUrl" :src="product.imageUrl" />
          <img v-else :src="image" height="160" width="160" />
        </div>
      </div>
      <div class="build-tyre__footer">
        <div class="build-tyre__footer__content">
          <div>
            <div class="build-tyre__footer__type">
              {{ label }}
              <span v-if="product && product.shortConditionType" class="meta-tag black rounded">{{ product.shortConditionType }}</span>
            </div>
            <div v-if="product" class="build-tyre__vehicle-data">
              <div class="build-tyre__vehicle-data__specs">
                <EonProductTitle :product="product"></EonProductTitle>
              </div>
              <div class="build-tyre__vehicle-data__price">
                <div class="price-tag">
                  {{ $formatPrice(product.totalPriceWithVat) }}/st
                </div>
              </div>
            </div>
            <div v-else class="build-tyre__vehicle-data__empty">
              Ej valt
            </div>
          </div>
          <div
            v-if="mobile"
            class="build-tyre__main__image build-tyre__main__image--mobile"
            :class="{
              'build-tyre__main__image--placeholder': !product,
            }"
          >
            <img v-if="product && product.imageUrl" :src="product.imageUrl" />
            <img v-else :src="image" height="40" width="40" />
          </div>
        </div>
        <div
          class="build-tyre__footer__action"
          :class="{
            'build-tyre__footer__action--with-selector': product,
          }"
        >
          <VcFormStack
            v-if="product"
            label="Antal"
            small>
            <VcFormSelect
              :options="quantityOptions"
              :value="quantity"
              labelProp="name"
              valueProp="value"
              valueType="number"
              small
              @change="onQuantityChange">
            </VcFormSelect>
          </VcFormStack>
          <a class="btn btn--muted btn--block" @click="$emit('onSelect', step)">
            {{ 'Välj' + buttonFiller + this.label.toLowerCase() }}
          </a>
        </div>
      </div>
    </div>
  </TyreBuildSectionWrapper>
</template>

<script>
import TyreBuildSectionWrapper from './TyreBuildSectionWrapper.vue';
import EonProductTitle from '../shared/ecom/EonProductTitle.vue';
import { VcFormSelect, VcFormStack, VcFeedbackMessage } from '@holmgrensbil/vue-components';
import TyreBuildSteps from './constants/TyreBuildSteps';
export default {
  components: {
    TyreBuildSectionWrapper,
    EonProductTitle,
    VcFormSelect,
    VcFormStack,
  },
  props: {
    selected: Boolean,
    isAddon: Boolean,
    title: String,
    label: String,
    step: Number,
    product: Object,
    image: String,
    quantity: Object,
    mobile: Boolean,
  },
  created() {
    if (this.product) {
      this.quantityOptions = this.product.quantityOptions;
    }
  },
  data() {
    return {
      quantityOptions: [],
    };
  },
  computed: {
    buttonFiller() {
      if (this.product && this.step === TyreBuildSteps.Rim) {
        return ' annan ';
      } else if (this.product && this.step === TyreBuildSteps.Tyre) {
        return ' annat ';
      } else {
        return ' ';
      }
    }
  },
  watch: {
    product() {
      this.quantityOptions = this.product.quantityOptions;
    },
  },
  methods: {
    onQuantityChange(value) {
      this.$emit('alterBuildWheelQuantity', value);
    },
  }
}
</script>

<style>

</style>