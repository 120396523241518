export default class I3DocEmployee {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc._id;
  }

  get location() {
    return this._doc.LocationTag;
  }

  get name() {
    return this._doc.Name;
  }

  get serviceTag() {
    return this._doc.ServiceTag;
  }

  get permalink() {
    return this._doc.SearchUrl;
  }
}
