<template>
  <div>
    <form @submit.prevent="onSubmit" class="lookup-form">
      <div class="form-row">
        <SectionField :type="FieldTypes.RegNr">
        </SectionField>
        <div v-if="lookupResultSuccess === false"
          class="regnr-input-sibling">
          <SectionField :type="FieldTypes.Brand">
          </SectionField>
        </div>
        <div class="form-row__col form-row__col--md-50">
          <SectionField :type="FieldTypes.Mileage">
          </SectionField>
        </div>
      </div>
      <div>
        <a class="regnr-input-sibling btn btn--primary"
          :class="{'disabled': lookupLoading}"
          @click="onSubmit">
          Fortsätt
        </a>
      </div>
    </form>

    <VcFeedbackMessage v-if="lookupResultSuccess === false && !section.validate" info>
      Kunde inte slå upp din bil. Ändra regnummer eller välj märke.
    </VcFeedbackMessage>
  </div>
</template>

<script>
import { VcFeedbackMessage } from '@holmgrensbil/vue-components';
import { mapActions, mapState, mapGetters } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import RequestStates from '../../../constants/RequestStates';
import SectionField from './SectionField.vue';

export default {
  components: {
    VcFeedbackMessage,
    SectionField,
  },
  props: {
    section: Object,
    sectionValid: Boolean,
  },
  data() {
    return {
      FieldTypes,
    };
  },
  computed: {
    ...mapState({
      lookupFetchState: state => state.lookup.fetchState,
      lookupResultSuccess: state => state.lookup.resultSuccess,
    }),

    ...mapGetters({
      fieldByType: 'fields/byType',
    }),

    lookupLoading() {
      return this.lookupFetchState === RequestStates.Request;
    },

    brandField() {
      return this.fieldByType(FieldTypes.Brand);
    },

    regNrField() {
      return this.fieldByType(FieldTypes.RegNr);
    },
  },
  watch: {
    lookupResultSuccess() {
      if (this.lookupResultSuccess === false) {
        this.validateSection({ section: this.section, value: false });
        this.setBrandRequired(true);
        this.setEquipmentLevelRequired(false);
      } else {
        this.setBrandRequired(false);
        this.setEquipmentLevelRequired(true);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchLookup: 'lookup/fetch',
      validateSection: 'sections/validate',
      goToSection: 'sections/goTo',
      setField: 'fields/set',
    }),

    setBrandRequired(required = true) {
      this.setField({ type: FieldTypes.Brand, required });
    },

    setEquipmentLevelRequired(required = true) {
      this.setField({ type: FieldTypes.EquipmentLevel, required });
    },

    onSubmit() {
      // Make sure we go to this section when modifying the step
      this.goToSection(this.section.order);

      this.validateSection({ section: this.section });

      if (this.sectionValid) {
        this.fetchLookup(this.regNrField.value).then(success => {
          if (success || this.fieldByType(FieldTypes.Brand).value) {
            this.$emit('submit');
          }
        });
      }
    },

    onFieldChange({ type }, value) {
      this.setField({ type, value });
    },
  },
}
</script>
