const state = {
  service: null,
};

const getters = {};

const actions = {
  async setService({ commit}, { service }) {
    commit('setService', service);
  },
};

const mutations = {
  setService(state, service) {
    state.service = service;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

