export default {
  init() {
    this.resolved = {};
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $(document).on('click', '[data-modal-source="element"]', this.onClick.bind(this));
  },

  modifyDOM() {
  },

  getMarkup({ title, content, noPadding, fromTarget }) {
    return `<div class="modal fade" data-from-target="${fromTarget}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header__title">
              ${title}
            </div>
            <div class="modal-header__close" data-dismiss="modal">
              <i class="fal fa-times"></i>
            </div>
          </div>
          <div class="modal-body ${noPadding ? 'modal-body--no-padding' : ''}">
            ${content}
          </div>
        </div>
      </div>
    </div>`;
  },

  onClick({ currentTarget }) {
    const $el = $(currentTarget);
    const target = $el.data('target');

    if (this.resolved[target]) {
      this.resolved[target].modal('show');
      return false;
    }

    const title = $el.data('modal-title') || $el.text();
    const content = document.querySelector(target).outerHTML;
    const noPadding = $el.data('modal-no-padding') === undefined ? false : true;
    const $modal = $(this.getMarkup({ title, content, noPadding, fromTarget: target })).modal();

    this.resolved[target] = $modal;

    return false;
  },
};
