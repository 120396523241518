<template>
  <VcModal @close="$emit('close')" small>
    <div slot="header">
      {{ lang.Contact.ReviewsModalHeadline }}
    </div>
    <div v-if="reviewData" slot="content" class="reviews-widget__modal">
      <div class="reviews-widget__modal__title">{{ heading }}</div>
      <div class="reviews-widget__modal__address">{{ address }}</div>

      <div class="reviews-widget__modal__star-row">
        <div class="reviews-widget__modal__average-rating">
          {{ reviewData.averageRating.toFixed(1) }}
        </div>
        <StarList list-id="modal-average-rating-star" :rating="reviewData.averageRating" :width="32" />
        <div class="reviews-widget__total-reviews">
          {{ ctaText }}
        </div>
      </div>
      <div v-if="reviewsUrl" class="reviews-widget__modal__review-button">
        <a class="btn btn--primary btn--sm" :href="reviewsUrl" target="_blank">Ge ditt omdöme</a>
      </div>
      <div class="reviews-widget__sort">
        <div class="reviews-widget__sort__label">
          {{ lang.Contact.ReviewsSortLabel }}
        </div>
        <div class="reviews-widget__sort__row">
          <div
            class="reviews-widget__sort__tag"
            :class="{
              'reviews-widget__sort__tag--active': activeSorting === sortKeys.Latest,
            }"
            @click="changeSortingOrder(sortKeys.Latest)"
          >
            {{ lang.Contact.ReviewsSortLatest }}
          </div>
          <div
            class="reviews-widget__sort__tag"
            :class="{
              'reviews-widget__sort__tag--active': activeSorting === sortKeys.Highest,
            }"
            @click="changeSortingOrder(sortKeys.Highest)"
          >
            {{ lang.Contact.ReviewsSortHighest }}
          </div>
          <div
            class="reviews-widget__sort__tag"
            :class="{
              'reviews-widget__sort__tag--active': activeSorting === sortKeys.Lowest,
            }"
            @click="changeSortingOrder(sortKeys.Lowest)"
          >
            {{ lang.Contact.ReviewsSortLowest }}
          </div>
        </div>
      </div>
      <div class="reviews-widget__list">
        <Review
          v-for="review in reviewList"
          :key="review.reviewId"
          :review="review"
        />
        <div v-if="reviewData.nextPageToken" class="reviews-widget__load-more" @click="nextPage">Fler recensioner</div>
      </div>
    </div>
    <div v-else slot="content">
      <div v-if="isLoading" class="reviews-widget__modal__loader">
        <FontAwesomeIcon
          :icon="faSpinnerThird"
          :spin="true"
        >
        </FontAwesomeIcon>
      </div>
      <div v-else>
        {{ lang.Contact.ReviewsError }}
      </div>
    </div>
  </VcModal>
</template>

<script>
import { VcModal } from "@holmgrensbil/vue-components";
import StarList from "../reviews-widget/StarList.vue";
import Review from "./Review.vue";
import moment from 'moment';
import Axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

const sortKeys = {
  Latest: 'updateTime desc',
  Highest: 'rating desc',
  Lowest: 'rating',
}

export default {
  components: {
    VcModal,
    StarList,
    Review,
    FontAwesomeIcon
  },
  props: {
    lang: Object,
    inBlock: Boolean,
    open: Boolean,
    locationId: String,
    reviewsUrl: String,
    heading: String,
    address: String,
    prefetchedReviewData: Object,
  },
  data() {
    return {
      reviewData: null,
      reviewList: [],
      reviewsModalOpen: false,
      page: 0,
      activeSorting: sortKeys.Latest,
      isLoading: false,
      moment,
      sortKeys,
      faSpinnerThird,
    };
  },
  mounted() {
    if (!this.prefetchedReviewData) {
      this.fetchReviews();
    } else {
      this.reviewData = this.prefetchedReviewData;
      this.reviewList = this.prefetchedReviewData.reviews;
    }
  },
  computed: {
    ctaText() {
      return this.lang.Contact.ReviewsWidgetCta.replace('{{quantity}}', this.reviewData.totalReviewCount);
    },
  },
  methods: {
    changeSortingOrder(sortingKey) {
      this.activeSorting = sortingKey;
      this.fetchReviews(true);
    },
    nextPage() {
      this.fetchReviews();
    },
    async fetchReviews(isSortRequest) {
      try {
        this.isLoading = true;
        const { data } = await Axios.get(`/api/Contact/GetReviews?locationId=${this.locationId}&orderBy=${this.activeSorting}&pageSize=10&pageToken=${!isSortRequest && this.reviewData ? this.reviewData.nextPageToken : ""}`);

        this.reviewData = data;
        if (isSortRequest) {
          this.reviewList = data.reviews;
        } else {
          this.reviewList = [...this.reviewList, ...data.reviews];
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
  }
};
</script>
