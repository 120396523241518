var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "grid", staticClass: "accessories-list__items" },
        _vm._l(_vm.gridItems, function(gridItem) {
          return _c("GridItem", {
            key: "" + gridItem.type + gridItem.document._id,
            attrs: { product: gridItem.product, regNr: _vm.regNr }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.gridItems.length
        ? _c("VcIntersectObserver", {
            attrs: { options: { rootMargin: "200px" } },
            on: { intersect: _vm.onIntersect }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }