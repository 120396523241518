var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-detail-modals-container" }, [
    _c(
      "div",
      { staticClass: "product-meta__links" },
      [
        _c(
          "div",
          {
            staticClass: "product-meta__links__item",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openModal(_vm.ModalTypes.Equipment)
              }
            }
          },
          [_c("a", { staticClass: "link" }, [_vm._v("Utrustning")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "product-meta__links__item",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openModal(_vm.ModalTypes.Facts)
              }
            }
          },
          [_c("a", { staticClass: "link" }, [_vm._v("Specifikation")])]
        ),
        _vm._v(" "),
        _vm.activeModal
          ? _c("VcModal", { on: { close: _vm.closeModal } }, [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.activeModal.header) + "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.activeModal.content) },
                slot: "content"
              })
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.product.reserved
      ? _c(
          "div",
          {
            staticClass:
              "quickbuy__pricing__option quickbuy__pricing__option--attention"
          },
          [
            _vm._v(
              "\n                Reserverad t.o.m " +
                _vm._s(_vm.$formatYYYYMMDD(_vm.product.bookedTo)) +
                "\n              "
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }