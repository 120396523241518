<template>
  <div v-if="loading" class="u-align-center">
    <FontAwesomeIcon
      :icon="faSpinnerThird"
      :spin="true"
      class="wizard--service-booking__loader">
    </FontAwesomeIcon>
  </div>
  <div v-else>
    <div v-if="hasExpress" class="section">
      <div class="section__content section__content--yellow-bg section__content--padding-sm">
        <img :src="icon" />
        <div v-html="htmlContent"></div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.TypeOfReport">
        </SectionField>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    SectionField,
    FontAwesomeIcon,
  },
  data() {
    return {
      FieldTypes,
      faSpinnerThird,
    };
  },
  computed: {
    ...mapState({
      htmlContent: state => state.reportTypes.htmlContent,
      reportTypesFetchState: state => state.reportTypes.fetchState,
      icon: state => state.reportTypes.icon,
    }),

    ...mapGetters({
      fieldByType: 'fields/byType',
      hasExpress: 'reportTypes/hasExpress',
    }),
    loading() {
      return this.reportTypesFetchState === 1;
    }
  },
}
</script>
