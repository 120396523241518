<template>
  <div class="configurator-section service-agreement-selector">
    <div v-if="fetchedAgreement">
      <div class="configurator-section__completed">
        <div class="details-bar">
          <div class="details-bar__title">
            {{ lang.Summation.ServiceAgreementPeriodHeading }}
            <span>{{ $formatPriceMonthly(fetchedAgreement.monthlyCost) }}</span>
          </div>
          <div class="details-bar__item-list">
            {{
              `Avtalet gäller i ${months} månader på en uppskattad körsträcka om ${mileage} mil/år. Avtalet täcker alla ordinarie servicekostnader enligt ditt fordons serviceschema.`
            }}
          </div>
        </div>
        <div v-if="fetchedAgreement.additionalProducts" class="configurator-section__completed__sub-action">
          <div class="main-info__heading">
            {{ lang.Summation.ServiceAgreementAddonsHeading }}
          </div>
          <div v-for="product in fetchedAgreement.additionalProducts" class="service-agreement-selector__product-row">
            <ConfiguratorCheckbox
              :key="'service-agreement-product-' + product.id"
              :text="product.name"
              :subText="$formatPriceMonthly(product.monthlyCost)"
              :defaultChecked="addedAdditionalProducts.includes(product.id)"
              @change="(checked) => changeAdditionalProduct(product.id, checked)"
            />
          </div>
        </div>
      </div>
      <button class="btn btn--muted btn--block" @click="removeAgreement">
        <font-awesome-icon :icon="faTrashCan" class="vehicle-configurator__btn-icon"></font-awesome-icon>
        {{ lang.Summation.RemoveServiceAgreement }}
      </button>
    </div>
    <div v-else class="service-agreement-selector__input-step">
      <div class="form-row configurator-section__input-row" >
        <ConfiguratorSubSelect
          :options="monthsOptions.values"
          :defaultPlaceholder="monthsOptions.heading"
          @change="onChangeMonths"
        />
        <ConfiguratorSubSelect
          :options="mileageOptions.values"
          :defaultPlaceholder="mileageOptions.heading"
          @change="onChangeMileage"
        />
      </div>
      <div class="form-row">
        <button
          class="btn btn--muted btn--block"
          :class="{
            'disabled': (!mileage || !months) || loading
          }"
          :disable="(!mileage || !months) || loading"
          @click="fetchServiceAgreement"
        >
          <font-awesome-icon v-if="loading" :icon="faSpinnerThird" :spin="true" class="vehicle-configurator__btn-icon"></font-awesome-icon>
          Hämta erbjudande
        </button>
        <div v-if="serviceAgreementError" class="vehicle-configurator__button-error">
          {{ serviceAgreementError }}
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Axios from 'axios';
import ConfiguratorSubSelect from '../configurator-components/ConfiguratorSubSelect.vue';
import store from '../store';
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import ConfiguratorCheckbox from '../configurator-components/ConfiguratorCheckbox.vue';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

export default {
  components: {
    ConfiguratorSubSelect,
    FontAwesomeIcon,
    ConfiguratorCheckbox
},
  props: {
    serviceAgreementData: Object,
    lang: Object
  },
  data() {
    return {
      mileage: null,
      months: null,
      fetchedAgreement: null,
      addedAdditionalProducts: [],
      modalHeading: "",
      modalContent: "",
      modalOpen: false,
      faTrashCan,
      loading: false,
      faSpinnerThird,
      serviceAgreementError: '',
    };
  },
  async mounted() {
    if (store.state.serviceAgreementSummation) {
      this.mileage = store.state.serviceAgreementSummation.mileage;
      this.months = store.state.serviceAgreementSummation.months;
      this.addedAdditionalProducts = await store.state.serviceAgreementSummation.additionalProducts
        .map(product => {
          if (product.selected) {
            return product.id;
          }
        })
        .filter(product => product);
      this.fetchedAgreement = store.state.serviceAgreementSummation;
    };
  },
  computed: {
    monthsOptions() {
      return {
        heading: "Serviceavtalslängd",
        required: true,
        values: this.serviceAgreementData.months
      };
    },
    mileageOptions() {
      return {
        heading: "Körsträcka per år",
        required: true,
        values: this.serviceAgreementData.mileage
      };
    }
  },
  methods: {
    onChangeMonths(months) {
      this.months = Number(months.value);
    },
    onChangeMileage(mileage) {
      this.mileage = Number(mileage.value);
    },
    async fetchServiceAgreement() {
      const regNr = store.state.regNr;
      try {
        this.loading = true;
        const { data } = await Axios.get(
          `/api/vehicleConfigurator/${regNr}/serviceAgreement?mileage=${this.mileage}&months=${this.months}`
        );
        this.fetchedAgreement = data;
        this.addedAdditionalProducts = data.additionalProducts
          .map(product => {
            if (product.selected) {
              return product.id;
            }
          })
          .filter(product => product);

        store.setServiceAgreement({
          ...data,
          mileage: this.mileage
        });
      } catch (e) {
        this.serviceAgreementError = 'Något gick fel, var god försök igen';
      } finally {
        this.loading = false;
      }
    },
    removeAgreement() {
      this.fetchedAgreement = null;
      store.setServiceAgreement(null);
    },
    changeAdditionalProduct(id, checked) {
      if (this.addedAdditionalProducts.includes(id) && !checked) {
        this.addedAdditionalProducts = this.addedAdditionalProducts.filter(
          product => product !== id
        );
      } else {
        this.addedAdditionalProducts = [...this.addedAdditionalProducts, id];
      }

      store.setServiceAgreement({
        ...this.fetchedAgreement,
        additionalProducts: this.fetchedAgreement.additionalProducts.map(
          product => {
            return {
              ...product,
              selected: this.addedAdditionalProducts.includes(product.id)
            };
          }
        ),
        mileage: this.mileage
      });
    },
  }
}
</script>