import MutationSummary from 'mutation-summary';

const observers = [];

export default (selector, cb) => {
  const observer = new MutationSummary({
    callback(summaries) {
      const els = summaries[0];

      els.added.forEach(el => {
        cb(el);
      });
    },
    queries: [{ element: selector }],
  });

  observers.push(observer);
}
