var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "properties__item" }, [
    _vm.line.image
      ? _c("img", {
          staticClass: "properties__item__image",
          attrs: { src: _vm.line.image }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "properties__item__text-container" },
      [
        _c("div", { staticClass: "properties__item__label" }, [
          _vm._v("\n      " + _vm._s(_vm.line.name) + "\n    ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "properties__item__sub-label" }, [
          _vm._v("\n      " + _vm._s(_vm.line.message) + "\n    ")
        ]),
        _vm._v(" "),
        _vm._l(_vm.line.usps, function(usp) {
          return _c("div", { key: usp.name, staticClass: "product-usps" }, [
            _c("div", { staticClass: "product-tag", class: usp.value }, [
              _vm._v(_vm._s(usp.name))
            ])
          ])
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "properties__value" },
      [
        _c("VcPriceTag", {
          attrs: { price: _vm.line.price, "show-if-zero": true }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }