<template>
  <div class="swiper-container" ref="swiperContainer">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="product in products" :key="product.id">
        <EonProductSpot :product="product">
        </EonProductSpot>
      </div>
    </div>
  </div>
</template>

<script>
import Breakpoints from '../../../constants/Breakpoints';
import EonProductSpot from './EonProductSpot.vue';
import Swiper from '../../../swiper';
import EonProduct from '../../../models/EonProduct';

export default {
  props: {
    productObjects: Array,
    default: () => [],
  },
  components: {
    EonProductSpot,
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiperContainer, {
      slidesPerView: 3,
      spaceBetween: 24,
      autoHeight: true,
      breakpoints: {
        [Breakpoints.xl - 2]: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        [Breakpoints.md - 2]: {
          slidesPerView: 'auto',
          spaceBetween: 8,
        },
      },
    });
  },
  updated() {
    this.swiper.update();
  },
  computed: {
    products() {
      return this.productObjects.map(doc => new EonProduct(doc));
    },
  },
}
</script>
