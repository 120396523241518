<template>
  <VcFormStack
    :label="option.heading"
    :required="option.required">
    <textarea
      class="form-control"
      @change="$emit('change', $event.target.value)"
      :maxlength="option.maxLength > 0 ? option.maxLength : ''">
    </textarea>
  </VcFormStack>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormStack,
  },
  props: {
    option: Object,
    default: () => {}
  },
  computed: {
    selectedValue() {
      const selected = this.option.values.find(v => v.selected);
      return selected && selected.value;
    },

    onChange() {
      const opt = this.option.values.find(value => value.value === val);
      this.$emit('change', opt);
    },
  },
}
</script>
