export default ({ commit, dispatch }) => ({
  'payment/selectSuccess': ({ cart, payment }) => {
    commit('cart/cartReceived', cart);
    commit('paymentInputs/received', payment.paymentInputs);
  },
  'cart/fetchSuccessById': () => {
    setTimeout(() => {
      dispatch('payment/fetch');
    }, 500);

  },
  'payment/fetchSuccess': payments => {
    const selected = payments.find(p => p.selected);

    if (selected) {
      commit('paymentInputs/received', selected.paymentInputs);
    }
  },
  'ssnLookup/fetchSuccess': fields => {
    fields.forEach(field => dispatch('fields/set', field));
  },
});

