var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "eon-product-title",
      class: { "eon-product-title--lg": _vm.large },
      on: {
        click: function($event) {
          return _vm.$emit("onClick")
        }
      }
    },
    _vm._l(_vm.product.titleLines, function(line, index) {
      return _c("div", { key: index, staticClass: "eon-product-title__line" }, [
        _vm.large
          ? _c("h1", { staticClass: "eon-product-title__text" }, [
              _vm._v("\n      " + _vm._s(line.title) + "\n    ")
            ])
          : _c("div", { staticClass: "eon-product-title__text" }, [
              _vm._v("\n      " + _vm._s(line.title) + "\n    ")
            ]),
        _vm._v(" "),
        line.meta
          ? _c("div", { staticClass: "eon-product-title__meta" }, [
              line.metaIcon ? _c("i", { class: line.metaIcon }) : _vm._e(),
              _vm._v("\n      " + _vm._s(line.meta) + "\n    ")
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }