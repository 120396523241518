<template>
  <div>
    <div ref="grid" class="accessories-list__items">
      <GridItem
        v-for="gridItem in gridItems"
        :key="`${gridItem.type}${gridItem.document._id}`"
        :product="gridItem.product"
        :regNr="regNr"
      >
      </GridItem>
    </div>
    <VcIntersectObserver
      v-if="gridItems.length"
      :options="{ rootMargin: '200px' }"
      @intersect="onIntersect"
    ></VcIntersectObserver>
  </div>
</template>

<script>
import Vue from "vue";
import { VcIntersectObserver } from "@holmgrensbil/vue-components";
import GridItem from "./GridItem.vue";
import AccessoryProduct from "../../models/AccessoryProduct";
import I3QueryParams from '../../constants/I3QueryParams';

export default {
  props: {
    channel: Object,
    documents: {
      type: Array,
      default: () => []
    },
    queriedAt: {
      type: Date
    },
  },
  components: {
    GridItem,
    VcIntersectObserver
  },
  watch: {
    queriedAt() {
      this.$refs.grid.style = `min-height: auto`;
    }
  },
  methods: {
    onIntersect() {
      this.$emit("onNext");
    },
  },
  computed: {
    gridItems() {
      let items = this.documents.map(document => ({
        document,
        product: new AccessoryProduct(document),
      }));

      return items;
    },
    regNr() {
      return this.channel.lastQuery.getParam(I3QueryParams.RegNr);
    }
  }
};
</script>
