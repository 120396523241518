var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "eon-filter",
      class: {
        "eon-filter--column": _vm.isColumn
      }
    },
    _vm._l(_vm.displaySections, function(section) {
      return _c("FilterSection", {
        key: section.type,
        attrs: {
          section: section,
          mainSpecSelector: _vm.mainSpecSelector,
          isInjected: section.isInjected
        },
        on: { fieldChange: _vm.onFieldChange, fieldsClear: _vm.onFieldsClear }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }