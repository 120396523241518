import axios from 'axios';

const instance = axios.create();

instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

if(typeof window.AuthorizationToken !== 'undefined') {
  instance.interceptors.request.use(config => {
    config.headers['X-RequestVerificationToken'] = window.AuthorizationToken;
    return config;
  });
}



export default instance;
