var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-images" }, [
    _c("div", { ref: "swiperContainer", staticClass: "swiper-container" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.images, function(image) {
          return _c("div", { key: image.image, staticClass: "swiper-slide" }, [
            _c(
              "div",
              {
                staticClass: "product-images__item",
                class: {
                  "product-images__item--fullscreen": _vm.isQuickBuy
                }
              },
              [
                _c("img", {
                  attrs: { src: image.src, alt: image.alt, title: image.alt }
                })
              ]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-pagination" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }