<template>
  <OverlayScrollable>
    <OverlayHeader
      slot="header"
      :text="headerText"
      :showBack="hasBack"
      @back="onBack"
      @close="onClose">
    </OverlayHeader>

    <div slot="content" class="my-pages" :class="{'my-pages--logged-in': isLoggedIn}">
      <keep-alive>
        <component :is="currentViewComponent"
          :current-user="currentUser"
          :lang="lang"
          @signOut="signOut"
          @signIn="signIn"
          @view="onView">
        </component>
      </keep-alive>
    </div>
  </OverlayScrollable>
</template>

<script>
import OverlayScrollable from '../shared/OverlayScrollable.vue';
import OverlayHeader from '../shared/OverlayHeader.vue';
import Views from './constants/Views';
import ViewComponents from './constants/ViewComponents';
import Axios from '../../axios';
import qs from 'query-string';
import { EventBus } from '../../utils/event-bus';

export default {
  components: {
    OverlayScrollable,
    OverlayHeader,
  },
  props: {
    onClose: Function,
    loggedInUser: Object,
    initialView: Number,
    lang: Object,
  },
  data() {
    return {
      currentView: this.loggedInUser ? Views.LoggedIn : Views.Index,
      currentUser: null,
    };
  },
  beforeMount() {
    this.currentUser = this.loggedInUser;
    const queryString = qs.parse(window.location.search);
    if(queryString.rToken) {
      this.currentView = Views.Reset;
    }
  },
  mounted() {
    EventBus.$on('logged-in-view', (view) => {
      this.onView(view || Views.LoggedIn);
    });
    EventBus.$on('updated-user', (user) => {
      this.updateUser(user);
    });
  },
  computed: {
    currentViewComponent() {
      return ViewComponents[this.currentView];
    },

    hasBack() {
      return this.currentView !== Views.Index;
    },

    isLoggedIn() {
      return this.currentUser !== null && this.currentUser.email.length;
    },
    headerText() {
      return this.currentView == Views.AddOrEditVehicle ? "Lägg till fordon" : "Mina sidor";
    }
  },
  methods: {
    onView(view) {
      this.currentView = view;
    },

    onBack() {
      this.currentView = this.isLoggedIn ? Views.LoggedIn : Views.Index;
    },

    updateUser(user) {
      this.currentUser = user;
      window.CurrentUser = user;
    },

    signIn(user) {
      this.currentUser = user;
      window.CurrentUser = user;
      EventBus.$emit('logged-in', user);
      this.currentView = Views.LoggedIn;
    },

    async signOut() {
      try {
        await Axios.post('/api/login/logout');
        window.location.reload();
      } catch(e) {
        console.error(e);
      }


    },
  },
}
</script>
