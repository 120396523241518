import Axios from '../axios';

const baseUrl = '/api/products';

export default {
  fetchAdditionalServices({ productId, quantity }) {
    const params = { quantity };
    return Axios.get(`${baseUrl}/${productId}/additionalservices`, { params });
  },
}
