<template>
  <div class="configurator-app__summary">
    <h3 class="section__header">
      Din sammanställning
    </h3>
    <div class="configurator-app__summary__container">
      <div v-if="!showSummationInModal">
        <ConfigSummationContent
          :summation="summation"
          :lang="lang"
          :department="department"
          :departmentOptions="departmentOptions"
          :isPurchasable="isPurchasable"
          :isTransport="isTransport"
          :purchaseInformationText="purchaseInformationText"
          :submitState="submitState"
          @submit="onSubmit">
        </ConfigSummationContent>
      </div>
    </div>

    <VcStickyCta
      v-if="showStickyCta"
      :text="stickyLabel"
      :fakeLoading="ctaFakeLoading"
      @click="onStickyClick">
    </VcStickyCta>

    <VcModal v-if="activeModal"
      modalBodyClass="modal-body--yellow-bg"
      @close="activeModal = false">
      <div slot="header">
        Ta kontakt
      </div>
      <div slot="content">
        <ConfigSummationContent
          :summation="summation"
          :lang="lang"
          :departmentOptions="departmentOptions"
          :isPurchasable="isPurchasable"
          :isTransport="isTransport"
          :purchaseInformationText="purchaseInformationText"
          :submitState="submitState"
          @submit="onSubmit">
        </ConfigSummationContent>
      </div>
    </VcModal>
  </div>
</template>


<script>
import ConfigSummationContent from './ConfigSummationContent.vue';
import { VcModal, VcStickyCta } from '@holmgrensbil/vue-components';
import Breakpoints from '../../../constants/Breakpoints';

export default {
  components: {
    ConfigSummationContent,
    VcModal,
    VcStickyCta,
  },
  props: {
    summation: {
      type: Object,
      required: true,
    },
    lang: {
      type: Object,
      required: true,
    },
    department: Object,
    departmentOptions: Array,
    submitState: Number,
    ctaActive: Boolean,
    ctaFakeLoading: Boolean,
    isPurchasable: Boolean,
    isTransport: Boolean,
    purchaseInformationText: String,
  },
  data() {
    return {
      activeModal: false,
      stickyLabel: 'Visa sammanställning och ta kontakt'
    };
  },
  computed: {
    showSummationInModal() {
      return false;
    },

    showStickyCta() {
      return this.showSummationInModal && this.ctaActive;
    },
  },
  methods: {
    onSubmit(contactData) {
      this.$emit('submit', { contactData, configuratorData: this.summation });
    },
    onStickyClick() {
      this.activeModal = true;
      const data = {
        label: this.stickyLabel,
        departmentNr: this.department ? this.department.value : null,
      };
      this.$emit('stickyClick', data);
    }
  },
}
</script>
