var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "properties properties--borders" },
    [
      _vm._l(_vm.cart.additionalFees, function(fee) {
        return _c(
          "div",
          {
            key: fee.id,
            staticClass: "properties__item properties__item--header"
          },
          [
            _c(
              "div",
              { staticClass: "properties__label" },
              [
                _vm._v("\n      " + _vm._s(fee.name) + " "),
                _c("FontAwesomeIcon", {
                  attrs: { icon: _vm.faQuestionCircle },
                  on: {
                    click: function($event) {
                      return _vm.onAdditionalFeeClick(fee)
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "properties__value" },
              [
                _c("VcPriceTag", {
                  attrs: {
                    vatPercentage: fee.vatPercentage,
                    price: fee.price,
                    hideOrdinaryPriceIfDiscount: ""
                  }
                })
              ],
              1
            )
          ]
        )
      }),
      _vm._v(" "),
      _vm.cart.totalPromotions && _vm.cart.totalPromotions.current
        ? _c(
            "div",
            { staticClass: "properties__item properties__item--header" },
            [
              _c("div", { staticClass: "properties__label" }, [
                _vm._v("\n      Rabatter på detta köp\n    ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "properties__value" }, [
                _c("div", { staticClass: "price-tag price-tag--discount" }, [
                  _vm._v(
                    "\n        -" +
                      _vm._s(
                        _vm.$formatPrice(_vm.cart.totalPromotions.current)
                      ) +
                      "\n      "
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "properties__item properties__item--header" }, [
        _c("div", { staticClass: "properties__label" }, [
          _vm._v("\n      Totalkostnad\n\n      "),
          _c(
            "div",
            { staticClass: "properties__meta properties__meta--muted-light" },
            [
              _vm._v(
                "\n        (inkl. " +
                  _vm._s(_vm.$formatPrice(_vm.cart.totalVat)) +
                  " moms)\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm.totalPrice
          ? _c(
              "div",
              { staticClass: "properties__value" },
              [
                _c("VcPriceTag", {
                  attrs: { price: _vm.totalPrice, total: "" }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "properties__meta properties__meta--muted-light"
                  },
                  [
                    _vm._v(
                      "\n        Avbetalning från " +
                        _vm._s(_vm.$formatPrice(_vm.lowestPartPaymentPrice)) +
                        "/mån\n      "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.modalOpen
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.modalOpen = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Info")
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.modalContent) },
                slot: "content"
              })
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }