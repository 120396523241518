<template>
  <div v-if="reviewData" class="reviews-block">
    <div class="reviews-block__list">
      <div class="reviews-block__swiper swiper-container" ref="swiperContainer">
        <div class="swiper-wrapper">
          <div class="reviews-block__card swiper-slide">
            <div class="reviews-widget">
              <div class="reviews-widget__container">
                <div class="reviews-widget__google-logo">
                  <img src="/Static/images/google_logo.png" />
                </div>
                <StarList list-id="star" :rating="reviewData.averageRating" :width="24" />
                <div class="reviews-widget__container__content">
                  <div class="reviews-widget__average-rating">
                    {{ reviewData.averageRating.toFixed(1) }}
                  </div>
                  <div class="reviews-widget__divider"></div>
                  <div class="reviews-widget__total-reviews reviews-widget__total-reviews--cta" @click="openReviewsModal">
                    {{ ctaText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="reviews-block__card swiper-slide" v-for="review in reviewData.reviews.slice(0, 3)" :key="review.reviewId">
            <div class="reviews-widget__review__rating-container">
              <StarList :list-id="review.reviewId" :rating="review.starRating" :width="16" />
              <div class="reviews-widget__review__date">
                {{ moment(review.updateTime).fromNow() }}
              </div>
            </div>
            <div v-if="review.comment" class="reviews-block__card__comment">
              {{ reviewsWithExpandedComment.includes(review.reviewId) ? review.comment : review.comment.substring(0, 60) }}
              <span v-if="!reviewsWithExpandedComment.includes(review.reviewId) && review.comment.length > 60">... <span class="reviews-widget__review__read-more" @click="reviewsWithExpandedComment.push(review.reviewId)">Läs mer</span></span>
            </div>
            <div>
              <i class="fas fa-badge-check reviews-block__card__icon"></i>
              <span class="reviews-block__card__name">
                {{ review.reviewer.displayName }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReviewsModal
      v-if="reviewsModalOpen"
      :prefetchedReviewData="reviewData"
      :lang="lang"
      :locationId="locationId"
      :heading="heading"
      :address="address"
      :reviewsUrl="reviewsUrl"
      @close="reviewsModalOpen = false"
    />
  </div>
</template>

<script>
import StarList from "../reviews-widget/StarList.vue";
import ReviewsModal from "../reviews-modal/Modal.vue";
import Breakpoints from "../../constants/Breakpoints";
import moment from "moment";
import Axios from '../../axios';
import Swiper from "../../swiper";
import { EventBus } from "../../utils/event-bus";

export default {
  components: {
    StarList,
    ReviewsModal,
  },
  props: {
    lang: Object,
    locationId: String,
    heading: String,
    address: String,
    reviewsUrl: String,
  },
  data() {
    return {
      reviewData: null,
      reviewsModalOpen: false,
      reviewsWithExpandedComment: [],
      moment,
      swiper: null,
    };
  },
  async mounted() {
    await this.fetchRecentReviews();

    this.swiper = new Swiper(this.$refs.swiperContainer, {
      slidesPerView: 4,
      spaceBetween: 24,
      breakpoints: {
        [Breakpoints.md - 2]: {
          slidesPerView: "auto",
          spaceBetween: 8,
        },
      }
    });

    if (this.reviewData) {
      this.swiper.slideTo(0, 0, false);
    }
  },
  computed: {
    ctaText() {
      return this.lang.Contact.ReviewsWidgetCta.replace('{{quantity}}', this.reviewData.totalReviewCount);
    },
    isMobile() {
      return this.windowWidth < Breakpoints.md;
    },
  },
  methods: {
    openReviewsModal() {
      this.reviewsModalOpen = true;
    },
    async fetchRecentReviews() {
      try {
        const { data } = await Axios.get(`/api/Contact/GetReviews?locationId=${this.locationId}&orderBy=updateTime desc&pageSize=10`);
        this.reviewData = data;
        EventBus.$emit('reviews-block-loaded');
      } catch (e) {
        console.warn(e);
      }
    }
  },
};
</script>
