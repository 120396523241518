<template>
  <div class="my-pages__section" v-if="orders.length">
    <div class="my-pages__section__title-container my-pages__section__title-container--header">
      <div class="section-item__col">
        <span class="my-pages__section__title">ORDERNR</span>
      </div>
      <div class="section-item__col section-item__col--middle-item">
        <span class="my-pages__section__title">DATUM</span>
      </div>
      <div class="section-item__col section-item__col--last-item">
        <span class="my-pages__section__title">SUMMA</span>
      </div>
    </div>
    <a class="section-item" v-for="order in orders" :key="order.id" _target="_blank" :href="order.url">
      <div class="section-item__col">
        <div class="section-item__status-icon" :class="{
          'section-item__status-icon--red': order.status === 'red',
          'section-item__status-icon--green': order.status === 'green',
          'section-item__status-icon--yellow': order.status === 'yellow',
        }"/>
        <span class="my-pages__section__title">{{ order.id }}</span>
      </div>
      <div class="section-item__col section-item__col--middle-item">
        <span class="my-pages__section__title">{{ order.date }}</span>
      </div>
      <div class="section-item__col section-item__col--last-item">
        <span class="my-pages__section__title">{{ $formatPrice(order.cost) }}</span>
      </div>
    </a>
  </div>
  <div class="my-pages__section my-pages__section--empty" v-else>
    <p>Inga ordrar hittades</p>
  </div>
</template>

<script>

import LineItemDeliveryStatuses from '../../../constants/LineItemDeliveryStatuses';
import { formatYYYYMMDD } from '../../../utils/dates';

export default {
  props: {
    sectionData: {
      type: Array,
    }
  },

  methods: {
    getOrderStatus(status) {
      switch(status) {
        case LineItemDeliveryStatuses.Store:
          return 'green';
          break;

        default:
          return 'green';
          break;
      }
    },
  },

  computed: {
    orders() {
      return this.sectionData
      .map(o => {
        return {
          id: o.orderNumber,
          date: formatYYYYMMDD(o.createdAt),
          cost: o.totalPrice,
          url: `${window.EonConfig.orderPageUrl}?orderId=${o.orderGuid}`,
          status: this.getOrderStatus(o.aggregatedDeliveryStatus),
        }
      });
    },
  }
}
</script>
