<template>
  <div class="department-main-action__container">
    <div v-if="loading" class="department-main-action__loader">
      <FontAwesomeIcon :icon="faSpinnerThird" :spin="true">
      </FontAwesomeIcon>
    </div>
    <div v-else>
      <div class="department-main-action__title">
        {{ lang.Contact.DepartmentPageHeading }}
        <div class="contact-print u-d-internal-user" v-if="selectedDepartment && selectedDepartment.printUrl">
          <i class="fal fa-print"></i>
          <a :href="selectedDepartment.printUrl" class="link link--primary">Skriv ut</a>
        </div>
      </div>
      <div class="department-main-action__select">
        <ConfiguratorSubSelect :options="departmentOptions" :defaultPlaceholder="selectedDepartment.name" @change="onChangeDepartment" />
      </div>
      <div class="department-main-action__button-container" v-if="selectedDepartment">
        <a :href="`tel:${selectedDepartment.phoneNr}`" class="btn btn--primary btn--block call-action">
          <i class="far fa-phone"></i>
          <div class="call-action__number call-action__number--toggler">
            {{ selectedDepartment.phoneNr.slice(0, 3) }} - <span>XX XX XX</span>
          </div>
          <div class="call-action__number">
            {{ selectedDepartment.phoneNr }}
          </div>
        </a>
        <a class="btn btn--muted btn--block department-actions__contact" @click.prevent="openModal">
          <i class="fal fa-envelope"></i>
          {{ lang.Contact.ContactUs }}
        </a>
        <a v-if="selectedDepartment.vehicleListHeading" class="btn btn--muted btn--block" target="_blank"
          :href="selectedDepartment.vehicleListUrl">
          <i :class="selectedDepartment.vehicleListIcon"></i>
          {{ selectedDepartment.vehicleListHeading }}
        </a>
        <a v-if="selectedDepartment.serviceHeading" class="btn btn--muted btn--block"
          :href="selectedDepartment.serviceUrl">
          <i :class="selectedDepartment.serviceIcon"></i>
          {{ selectedDepartment.serviceHeading }}
        </a>
      </div>
      <div class="department-info-row__section" v-if="selectedDepartment">
        <i class="far fa-map-marker-alt"></i>
        <div class="department-info-row__line">
          {{ `${selectedDepartment.address}, ${selectedDepartment.postalCode}, ${selectedDepartment.city}` }}
          <span><br /><a class="link" @click.prevent="onDirectionsClick">{{
            lang.Contact.Directions }}</a></span>
        </div>
      </div>
      <div class="department-info-row__section info" v-if="selectedDepartment && selectedDepartment.informationText">
        <i class="fa-light fa-circle-info"></i>
        <div v-html="selectedDepartment.informationText">
        </div>
      </div>

      <div class="department-info-row__section" v-if="selectedDepartment && selectedDepartment.openHours.length > 0">
        <i class="far fa-clock"></i>
        <div class="department-info-row__opening-hours">
          <div class="department-info-row__opening-hours__list">
            <div class="department-info-row__opening-hours__item" :class="{
              'department-info-row__opening-hours__item--open': selectedDepartment.openHours[0].value === 'isOpen',
              'department-info-row__opening-hours__item--closed': selectedDepartment.openHours[0].value === 'isClosed',
            }">
              {{ selectedDepartment.openHours[0].name }}
            </div>
            <div class="department-info-row__opening-hours__item">
              {{ `${selectedDepartment.openHours[1].name} ${selectedDepartment.openHours[1].value}` }}
            </div>
            <div class="department-info-row__opening-hours__item">
              {{ `${selectedDepartment.openHours[2].name} ${selectedDepartment.openHours[2].value}` }}
            </div>
            <div class="department-info-row__opening-hours__item link" @click="toggleOpeningHoursModal">
              {{ lang.Contact.OpeningHoursCta }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedDepartment && selectedDepartment.brands.length"
        class="department-info-row__section department-info-row__section--no-bottom-padding">
        <i class="far fa-badge-check"></i>
        <div class="department-info-row__brands">
          <div class="department-info-row__brands__title">
            {{ selectedDepartment.serviceName }}
          </div>
          <div class="department-info-row__brands__list">
            <div class="department-info-row__brands__item" v-for="brand in selectedDepartment.brands" :key="brand.value">
              <img :src="brand.value" :alt="brand.name" />
            </div>
          </div>
        </div>
      </div>
      <VcModal v-if="openingHoursModalOpen" @close="toggleOpeningHoursModal">
        <div slot="header">{{ 'Öppettider' }}</div>
        <div slot="content">
          <div class="department-main-action__title">
            {{ `${selectedDepartment.companyPrefix} ${selectedDepartment.city}` }}
          </div>
          <div class="department-main-action__select">
            <ConfiguratorSubSelect :options="departmentOptions" @change="onChangeDepartment"
              :defaultValue="selectedDepartment.value" />
          </div>
          <div class="department-main-action__modal-opening-hours">
            <i class="far fa-clock"></i>
            <div class="department-info-row__opening-hours__list">
              <div class="department-info-row__opening-hours__item" :class="{
                'department-info-row__opening-hours__item--open': selectedDepartment.openHours[0].value === 'isOpen',
                'department-info-row__opening-hours__item--closed': selectedDepartment.openHours[0].value === 'isClosed',
              }">
                {{ selectedDepartment.openHours[0].name }}
              </div>
              <div class="department-info-row__opening-hours__item"
                v-for="openHour in selectedDepartment.openHours.slice(1, selectedDepartment.openHours.length)"
                :key="openHour.name">
                <div>
                  {{ openHour.name }}
                </div>
                <div>
                  {{ openHour.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </VcModal>
    </div>
    <div v-else class="department-main-action__error">
      {{ lang.Contact.GenericError }}
    </div>
  </div>
</template>

<script>
import ConfiguratorSubSelect from "../main-action/configurator-components/ConfiguratorSubSelect.vue";
import Axios from "../../axios";
import { VcModal } from "@holmgrensbil/vue-components";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import gtm from "../../utils/gtm";

export default {
  components: {
    ConfiguratorSubSelect,
    VcModal,
    FontAwesomeIcon,
  },
  props: {
    lang: Object,
    location: String,
    service: String,
  },
  data() {
    return {
      departmentOptions: [],
      selectedDepartment: null,
      openingHoursModalOpen: false,
      loading: true,
      faSpinnerThird,
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {

  },
  methods: {
    onChangeDepartment(input) {
      this.selectedDepartment = input;
    },
    async fetchData() {
      try {
        const { data } = await Axios.get(
          `/api/contact/GetDepartmentsByLocation?location=${this.location}&service=${this.service}`
        );

        this.departmentOptions = data.map(department => {
          return {
            ...department,
            value: department.id,
          }
        });
        this.selectedDepartment = this.departmentOptions.find(department => department.selected) || data[0];
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    openModal() {
      Axios.get(this.selectedDepartment.departmentUrl).then(({data}) => {
        const $modal = $(data).modal();
      });
      
    },
    toggleOpeningHoursModal() {
      this.openingHoursModalOpen = !this.openingHoursModalOpen
    },
    async onDirectionsClick() {
      await gtm.directionsLinkClick({
        departmentNr: this.selectedDepartment.departmentNr
      });
      window.open(this.selectedDepartment.directionsUrl, '_blank');
    },
  }
}
</script>
