import TyreSpec from "../models/TyreSpec";
import TyreSpecToolbar from "../apps/tyre-spec-toolbar";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
    this.addEvents();
  },

  addEvents() {
  },

  modifyDOM() {
    mountApp({
      app: TyreSpecToolbar,
      selector: '[data-app=tyre-spec-toolbar]',
      setEonChannel: false,
      props: {
        TyreSpec,
      },
    });
  },
};
