export default {
  Widths: [
    '135',
    '145',
    '155',
    '165',
    '175',
    '185',
    '195',
    '205',
    '215',
    '225',
    '235',
    '245',
    '255',
    '265',
    '275',
    '285',
    '295',
    '305',
    '315',
    '325',
    '335',
    '345',
    '355',
    '365',
  ],
  AspectRatios: [
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
    '60',
    '65',
    '70',
    '75',
    '80',
    '85',
    '90',
  ],
  Diameters: [
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
  ],
}
