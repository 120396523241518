export const pxToInt = unit => parseInt(unit.replace('px', ''), 10);

export const secondsToMsInt = unit => parseFloat(unit.replace('s', '')) * 1000;

export const formatNumber = number => {
  const separator = ' ';
  const decimalMark = ',';

  number = number.toString().replace('.', decimalMark);

  return number.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};
