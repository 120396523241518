const selectors = {
  hours: '.info-block--hours',
};

const classes = {
  hoursExpanded: 'info-block--hours--expanded',
};

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').
      on('click', '.info-block--hours .link', this.onHoursLinkClick.bind(this));
  },

  onHoursLinkClick({ currentTarget }) {
    $(currentTarget).closest(selectors.hours).toggleClass(classes.hoursExpanded);
    return false;
  },
}
