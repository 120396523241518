var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-margin-top" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c(
          "div",
          { staticClass: "summary u-padding-top-lg vehicle-configurator" },
          [
            _c("div", { staticClass: "vehicle-configurator__summation" }, [
              _c("div", { staticClass: "configurator-section" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "vehicle-configurator__summation__row" },
                    [
                      _c("div", { staticClass: "properties" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("div", { staticClass: "vehicle-line" }, [
                          _c("div", { staticClass: "properties__item" }, [
                            _c(
                              "div",
                              {
                                staticClass: "properties__item__text-container"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "properties__item__label" },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(
                                          _vm.store.agreementResult.vehicle
                                            .registrationNumber
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "properties__item__sub-label"
                                  },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(
                                          _vm.store.agreementResult.vehicle
                                            .carName
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "properties__value vehicle-configurator__summation__price"
                              },
                              [
                                _c("div", { staticClass: "price-tag" }, [
                                  _vm._v(
                                    "\n                          " +
                                      _vm._s(
                                        _vm.$formatNumber(
                                          _vm.store.agreementResult
                                            .currentMileage
                                        )
                                      ) +
                                      "\n                          mil\n                        "
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "vehicle-configurator__summation__row" },
                    [
                      _c("div", { staticClass: "properties" }, [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("div", { staticClass: "summation-list" }, [
                          _c("div", { staticClass: "summation-list__row" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("Avtalsperiod")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text text--value" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.store.agreementResult.months) +
                                  " månader\n                      "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "summation-list__row" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("Miltal")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text text--value" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.store.agreementResult.mileage) +
                                  " mil/år\n                      "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "summation-list__row" }, [
                            _c("div", { staticClass: "text" }, [
                              _vm._v("Servicetillfällen")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "text text--value" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.store.numberOfSelectedInstances) +
                                  " st\n                      "
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.store.hasSelectedAdditionalProducts
                            ? _c(
                                "div",
                                { staticClass: "summation-list__row" },
                                [
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v("Valda tillägg")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "text text--value" },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.store.additionalProductsDisplay
                                          ) +
                                          "\n                      "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-configurator__summation__row vehicle-configurator__summation__row--black-border"
                  },
                  [
                    _c("div", { staticClass: "total-price" }, [
                      _c("div", { staticClass: "total-price__row" }, [
                        _c("div", { staticClass: "total-price__title" }, [
                          _vm._v(
                            "\n                    TOTAL MÅNADSKOSTNAD\n                  "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "price-tag" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$formatPrice(
                                  _vm.store.agreementResult.monthlyCost
                                )
                              ) +
                              " /\n                    mån\n                  "
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "configurator-section__title" }, [
      _c("i", { staticClass: "fal fa-receipt configurator-section__icon" }),
      _vm._v("\n              Sammanställning\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "properties__item properties__item--header" },
      [
        _c("div", { staticClass: "properties__label" }, [
          _vm._v("\n                      Fordon\n                    ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "properties__item properties__item--header" },
      [
        _c("div", { staticClass: "properties__label" }, [
          _vm._v("\n                      Serviceavtal\n                    ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }