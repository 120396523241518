var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reviewsModalOpen
    ? _c("Modal", {
        attrs: {
          lang: _vm.lang,
          locationId: _vm.locationId,
          heading: _vm.heading,
          address: _vm.address,
          reviewsUrl: _vm.reviewsUrl
        },
        on: {
          close: function($event) {
            _vm.reviewsModalOpen = false
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }