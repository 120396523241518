var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "field-input-images" }, [
    _c("label", [_vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "field-input-images__grid" },
      [
        _vm._l(_vm.field.value, function(image) {
          return _c(
            "div",
            { key: image, staticClass: "field-input-images__grid-item" },
            [
              _c(
                "div",
                {
                  staticClass: "file-thumbnail",
                  style: {
                    "background-image":
                      "url(" +
                      image +
                      "?" +
                      _vm.ImageResizerPresets.FileThumbnail +
                      ")"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "file-thumbnail__delete",
                      on: {
                        click: function($event) {
                          return _vm.onDeleteImage(image)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fal fa-times" })]
                  )
                ]
              )
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "field-input-images__grid-item" },
          [
            _c(
              "VcInputFile",
              {
                attrs: {
                  label: _vm.uploading ? "Laddar upp..." : _vm.field.placeholder
                },
                on: {
                  change: function($event) {
                    return _vm.onFileChange(_vm.field, $event)
                  }
                }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "icon" }, slot: "icon" },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon: _vm.uploading
                          ? _vm.faSpinnerThird
                          : _vm.faCameraAlt,
                        spin: _vm.uploading ? true : false
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }