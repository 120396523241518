var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-pages__section" }, [
    _c("div", { staticClass: "my-pages__section__title-container" }, [
      _c("span", { staticClass: "my-pages__section__title" }, [
        _vm._v("KONTO")
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          class: { "delete-button": !_vm.deleting },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.onDeleteAccount($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.deleting ? "Raderar konto..." : "Radera konto"))]
      ),
      _vm._v(" "),
      _vm.deleteError
        ? _c(
            "div",
            {
              staticClass:
                "feedback-message feedback-message--danger feedback-message--slim"
            },
            [
              _c("div", { staticClass: "feedback-message__text" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.lang.App.DeleteError) + "\n      "
                )
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", [_c("span", [_vm._v(_vm._s(_vm.currentUser.email))])]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.locations.length
      ? _c("div", [
          _c("div", { staticClass: "form-stack" }, [
            _c("label", [_vm._v("\n       Din ort\n      ")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedLocation,
                      expression: "selectedLocation"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedLocation = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.onLocationChange()
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("\n            Välj ort\n          ")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.locations, function(location) {
                    return _c(
                      "option",
                      {
                        key: location.value,
                        domProps: { value: location.value }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(location.name) +
                            "\n          "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "form-stack form-stack--required" }, [
      _c("label", [_vm._v("\n      Nytt lösenord\n    ")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newPassword,
            expression: "newPassword"
          }
        ],
        staticClass: "form-control",
        attrs: {
          placeholder: "Ange nytt lösenord",
          type: "password",
          required: ""
        },
        domProps: { value: _vm.newPassword },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.newPassword = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-stack form-stack--required" }, [
      _c("label", [_vm._v("\n      Upprepa nytt lösenord\n    ")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.confirmNewPassword,
            expression: "confirmNewPassword"
          }
        ],
        staticClass: "form-control",
        attrs: {
          placeholder: "Upprepa nytt lösenord",
          type: "password",
          required: ""
        },
        domProps: { value: _vm.confirmNewPassword },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.confirmNewPassword = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-stack form-stack--required" }, [
      _c("label", [_vm._v("\n      Nuvarande lösenord\n    ")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentPassword,
            expression: "currentPassword"
          }
        ],
        staticClass: "form-control",
        attrs: {
          placeholder: "Ange ditt nuvarande lösenord",
          type: "password",
          required: ""
        },
        domProps: { value: _vm.currentPassword },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.currentPassword = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn--primary btn--block",
        class: { disabled: _vm.btnDisabled },
        on: { click: _vm.onSubmitPassword }
      },
      [_vm._v("\n    Ändra lösenord\n  ")]
    ),
    _vm._v(" "),
    _vm.updateSuccess
      ? _c(
          "div",
          {
            staticClass:
              "feedback-message feedback-message--success feedback-message--slim"
          },
          [
            _c("div", { staticClass: "feedback-message__text" }, [
              _vm._v("\n      " + _vm._s(_vm.successMessage) + "\n    ")
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.updateError
      ? _c(
          "div",
          {
            staticClass:
              "feedback-message feedback-message--danger feedback-message--slim"
          },
          [
            _c("div", { staticClass: "feedback-message__text" }, [
              _vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-pages__section__title-container" }, [
      _c("span", { staticClass: "my-pages__section__title" }, [_vm._v("ORT")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-pages__section__title-container" }, [
      _c("span", { staticClass: "my-pages__section__title" }, [
        _vm._v("ÄNDRA LÖSENORD")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }