import events from "../mixins/events";
import ServiceEvents from "../constants/ServiceEvents";

let ServiceBookingManager = {
  regNr: null,
  service: null,
  department: null,
  carInfo: null,

  setCarInfo(carInfo) {
    this.carInfo = carInfo;
    this.emit(ServiceEvents.CarInfoSet, { carInfo });
  },
  setRegNr(regNr) {
    this.regNr = regNr;
    this.emit(ServiceEvents.RegNrSet, { regNr });
  },
  setService(service) {
    this.service = service;
    this.emit(ServiceEvents.ServiceSet, { service });
  },
  setDepartment(department) {
    this.department = department;
    this.emit(ServiceEvents.DepartmentSet, { department });
  },
  moveOn() {
    this.emit(ServiceEvents.MoveOn);
  },
  onRegNrSet(cb) {
    this.on(ServiceEvents.RegNrSet, cb);
  },
  onServiceSet(cb) {
    this.on(ServiceEvents.ServiceSet, cb);
  },
  onDepartmentSet(cb) {
    this.on(ServiceEvents.DepartmentSet, cb);
  },
  onMoveOn(cb) {
    this.on(ServiceEvents.MoveOn, cb);
  },
  onCarInfoSet(cb) {
    this.on(ServiceEvents.CarInfoSet, cb);
  }
};

Object.assign(ServiceBookingManager, events);

export default ServiceBookingManager;
