var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", [_vm._v("\n    Kampanjkod\n  ")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-row__col" },
        [
          _c("SectionField", {
            attrs: { type: _vm.FieldTypes.CampaignCode },
            on: { clickchange: _vm.onChange }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.validCode
      ? _c(
          "div",
          {
            staticClass:
              "feedback-message feedback-message--success feedback-message--slim"
          },
          [
            _c("div", { staticClass: "feedback-message__header" }, [
              _vm._v("\n      " + _vm._s(_vm.code.campaignCode) + "\n    ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feedback-message__text" }, [
              _vm._v("\n      " + _vm._s(_vm.code.description) + "\n    ")
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.fetched && !_vm.validCode
      ? _c(
          "div",
          {
            staticClass:
              "feedback-message feedback-message--danger feedback-message--slim"
          },
          [
            _c("div", { staticClass: "feedback-message__header" }, [
              _vm._v("\n      " + _vm._s(_vm.inputCode) + "\n    ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feedback-message__text" }, [
              _vm._v(
                "\n      Den här kampanjkoden är tyvärr inte giltig!\n    "
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }