export default (createMediator, createStore) => {
  const store = createStore();
  const mediator = createMediator(store);

  store.subscribe(({ type, payload }, state) => {
    const handler = mediator[type];

    if (handler) {
      handler(payload, state);
    }
  });

  return store;
}
