<template>
  <div class="form-stack reg-nr">
    <div class="reg-nr__field">
      <div class="input-group-prepend">
        <div
          class="regnr-input__symbol reg-nr__field__symbol"
          :class="{ 'reg-nr__field__symbol--rounded': !carInfoDisplayName }"
        ></div>
      </div>
      <div
        class="form-stack reg-nr__field__container"
        :class="{ 'form-stack--lg': false }"
      >
        <label>
          Regnummer
        </label>
        <input
          id="regNr-input"
          type="text"
          @keyup="onChange()"
          class="form-control reg-nr__field__input"
          placeholder="ABC123"
          style="color: #18191a"
          required
          pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
          autocomplete="off"
          v-model="regNr"
        />
      </div>
    </div>
    <div class="reg-nr__specs" v-if="validRegNr && carInfoDisplayName">
      {{ carInfoDisplayName }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { VcFormSelect, VcFormStack } from "@holmgrensbil/vue-components";
import ServicesApi from "../../models/ServicesApi";
const regNrRegexp = /^\w{3}\d{2}(\w{1}|\d{1})$/;

export default {
  components: {
    VcFormSelect,
    VcFormStack
  },
  data() {
    return {
      regNr: ""
    };
  },
  computed: {
    ...mapState({
      carInfo: state => state.regNr.item
    }),
    validRegNr() {
      return regNrRegexp.test(this.regNr);
    },
    validCarInfo() {
      return this.carInfo && this.carInfo.registerNumber;
    },
    carInfoDisplayName() {
      if (!this.validCarInfo) {
        return "";
      }
      const { brand, model, version, modelYear } = this.carInfo;
      return `${brand} ${model} ${version} ${modelYear}`;
    }
  },
  methods: {
    ...mapActions({
      select: "regNr/select",
      deselect: "regNr/deselect",
      deselectDepartments: "departments/deselect"
    }),
    async onChange() {
      if (this.regNr.length > 6) {
        this.regNr = this.regNr
          .split("")
          .slice(0, 6)
          .join("");
        return;
      }

      if (this.validRegNr) {
        await this.select(this.regNr);
      } else {
        this.deselectDepartments();
        this.deselect();
      }
    }
  }
};
</script>
