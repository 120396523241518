import Axios from '../../../../axios';
import RequestStates from '../../../../constants/RequestStates';
import SectionTypes from '../../constants/SectionTypes';

const state = {
  value: false,
  url: null,
};

const getters = {};

const actions = {
  async fetch({ commit, dispatch }, { brand, services, departmentNr }) {
    commit('fetchRequest');

    try {
      const data = {
        departmentNr,
        brand,
        services,
      };

      const res = await Axios.post('/api/service/CheckExternal', data);
      commit('fetchSuccess', res.data);
      dispatch('flipSections');
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },

  flipSections({ state, dispatch }) {
    const isExternal = state.value && state.url;

    dispatch('sections/set', {
      type: SectionTypes.ExternalUrl,
      dormant: !isExternal,
    }, { root: true });
    dispatch('sections/set', {
      type: SectionTypes.Date,
      dormant: isExternal,
    }, { root: true });
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, { isExternal, externalUrl }) {
    state.fetchState = RequestStates.Success;
    state.value = isExternal;
    state.url = externalUrl;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
