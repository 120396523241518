<template>
  <div class="badge" v-if="count">
    {{count}}
  </div>
</template>

<script>
import ChannelEvents from '../../constants/ChannelEvents';
import SectionTypes from './constants/SectionTypes';
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  computed: {
    ...mapState({
      fields: state => state.fields.items,
    }),

    ...mapGetters({
      childSections: 'sections/children',
    }),
  },
  methods: {
    onQuerySuccess() {
      this.count = this.getCount();
    },

    getDisplayParams() {
      const sections = [
        SectionTypes.TyreProperties,
        SectionTypes.RimProperties,
      ];
      const childSections = this.childSections.filter(s => sections.includes(s.parent)).map(s => s.type);
      const joinedSections = [
        ...sections,
        ...childSections,
      ];
      const sectionFields = this.fields.filter(f => joinedSections.includes(f.section));

      return sectionFields.filter(f => f.value).map(f => f.type);
    },

    getCount() {
      const displayParams = this.getDisplayParams();
      return this.channel.getQueryFilters().filter(({ param }) => displayParams.includes(param)).length;
    },
  },
}
</script>
