import Swiper from '../swiper';
import Breakpoints from '../constants/Breakpoints';

export default {
  init() {
    if (!$('.promo-teasers').length) {
      return;
    }

    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    this.initSwiper();
  },

  initSwiper() {
    this.swiper = new Swiper('.promo-teasers .swiper-container', {
      autoHeight: true,
      slidesPerView: 3,
      spaceBetween: 40,
      breakpoints: {
        [Breakpoints.md - 2]: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        [Breakpoints.sm - 2]: {
          slidesPerView: 'auto',
          spaceBetween: 8,
        },
      },
    });
  },
}
