import MutationSummary from 'mutation-summary';

const WIDGET_SELECTOR = '.au-widget-car-rating';

export default {
  init() {
    this.observer = null;
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    if ($(WIDGET_SELECTOR).length) {
      this.injectSnippet();
    } else {
      this.observer = new MutationSummary({
        callback: this.onWidgetMutation.bind(this),
        queries: [{ element: WIDGET_SELECTOR }]
      });
    }
  },

  onWidgetMutation(summaries) {
    const widgets = summaries[0];

    if (widgets.added.length) {
      this.injectSnippet();
      this.observer.disconnect();
    }
  },

  injectSnippet() {
    // AutoUncle snippet
    /* eslint-disable */
    window.auWidgetConfig = {sourceName: 'Holmgrensbil.se'};
    var s, h;
    s=document.createElement('script');s.async=true;s.src='//www.autouncle.se/widgets/embed.js?source_name='+encodeURIComponent(auWidgetConfig.sourceName);s.setAttribute('data-source-name',auWidgetConfig.sourceName);s.id='au-widget-script';h=document.getElementsByTagName('head')[0];h.insertBefore(s,h.lastChild);
    /* eslint-enable */
  },
};
