<template>
  <div class="i3-filter-tabs tab-bar">
    <div v-for="filter in filters"
      :key="filter.name"
      class="tab-bar__item"
      :class="{'tab-bar__item--active': lastFilterName === filter.name}"
      @click="onFilterSelect(filter)">
      {{filter.name}}
    </div>
  </div>
</template>

<script>
import ChannelEvents from '../../constants/ChannelEvents';
import I3QueryParams from '../../constants/I3QueryParams';
import ChannelQuery from '../../models/ChannelQuery';

export default {
  props: {
    channel: Object,
    filters: Array,
  },
  data() {
    return {
      lastFilterName: null,
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();
    this.onFilterSelect(this.filters[0]);
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  methods: {
    onQuerySuccess() {
      const lastQuery = this.channel.lastQuery;

      if (!lastQuery) {
        return;
      }

      const lastQueryQuery = new ChannelQuery(lastQuery);
      lastQueryQuery.unsetParam(I3QueryParams.Preference);
    },

    onFilterSelect(filter) {
      this.lastFilterName = filter.name;
      this.channel.execute(filter.query);
    },
  },
}
</script>
