import Vue from 'vue';
import App from './App.vue';
import { l10n } from '../../utils/l10n';



export default (props) => {
  const lang = {
    General: l10n.General,
    Contact: l10n.Contact,
    VehiclePage: l10n.VehiclePage,
    Financing: l10n.Financing,
    App: l10n.SearchWatch,
    VehicleTradeIn: l10n.VehicleTradeIn,
    ConfiguratorSummation: l10n.ConfiguratorSummation,
    Transport: l10n.Transport,
    Summation: l10n.ConfiguratorSummation,
    SiteGeneral: l10n.SiteGeneral,
  };

  props.lang = lang;
  return Vue.extend({
    render: h => h(App, { props })
  });
};