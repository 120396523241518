import SectionTypes from "../constants/SectionTypes";

export default [
  {
    type: SectionTypes.ProductType,
    header: "Vad vill du köpa?"
  },
  {
    type: SectionTypes.BuildWheel,
    header: "Bygg hjul",
    isInjected: true,
  },
  {
    type: SectionTypes.Condition,
    header: "Skick"
  },
  {
    type: SectionTypes.Season,
    header: "Välj säsong"
  },
  {
    type: SectionTypes.Diameter,
    header: "Dimension"
  },
  {
    type: SectionTypes.RimProperties,
    header: "Fälg",
    clearButton: true
  },
  {
    type: SectionTypes.TyreProperties,
    header: "Däck",
    clearButton: true
  },
  {
    parent: SectionTypes.RimProperties,
    type: SectionTypes.RimType,
    header: "Material",
    collapsible: true
  },
  {
    parent: SectionTypes.RimProperties,
    type: SectionTypes.RimOptions,
    header: "Egenskaper",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.Brand,
    header: "Märke",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.Quality,
    header: "Kvalitet",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.RollingResistance,
    header: "Rullmotstånd",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.WetGrip,
    header: "Våtgrepp",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.Noise,
    header: "Bullernivå",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.Speed,
    header: "Hastighet",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.Load,
    header: "Belastning",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.ApprovalMark,
    header: "Car approval mark",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.TestScore,
    header: "Testresultat",
    collapsible: true
  },
  {
    parent: SectionTypes.TyreProperties,
    type: SectionTypes.TyreOptions,
    header: "Egenskaper",
    collapsible: true
  }
];
