var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.reviewData
    ? _c("div", { staticClass: "reviews-widget" }, [
        _c(
          "div",
          { staticClass: "reviews-widget__container" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("StarList", {
              attrs: {
                "list-id": "star",
                rating: _vm.reviewData.averageRating,
                width: _vm.inBlock && !_vm.isMobile ? 32 : 16
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "reviews-widget__container__content" }, [
              _c("div", { staticClass: "reviews-widget__average-rating" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.reviewData.averageRating.toFixed(1)) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "reviews-widget__divider" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "reviews-widget__total-reviews reviews-widget__total-reviews--cta",
                  on: { click: _vm.openReviewsModal }
                },
                [_vm._v("\n        " + _vm._s(_vm.ctaText) + "\n      ")]
              )
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "reviews-widget__google-logo" }, [
      _c("img", { attrs: { src: "/Static/images/google_logo-white.png" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }