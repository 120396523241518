import RimRepair from "../apps/rim-repair";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: RimRepair,
      selector: '[data-app=rim-repair]',
      props: {
        pageName: window.document.title,
      },
    });
  },
};
