<template>
  <div>
    <FieldInput
      :field="field"
      class="eon-filter__field"
      @change="onChange(field, $event)">
    </FieldInput>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FieldInput from './FieldInput.vue';
import EonQueryParams from '../../../constants/EonQueryParams';
import FieldTypes from '../constants/FieldTypes';
import BuildWheelTypes from '../constants/BuildWheelTypes';

export default {
  components: {
    FieldInput,
  },
  props: {
    type: String,
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
      sectionByType: 'sections/byType',
    }),

    field() {
      return this.fieldByType(this.type);
    },
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),

    onChange(field, value) {
      if (field.type === EonQueryParams.ProductType) {
        this.setField({ type: FieldTypes.BuildWheel, value: String(BuildWheelTypes.Unselected) });
      }
      this.setField({ type: field.type, value });
      this.$emit('change', { ...field, value });
    },
  },
}
</script>
