import Vue from 'vue';
import App from './App.vue';
import createStore from './store';
import fields from './data/fields';
import createMediator from './store/mediator';
import withMediator from '../../utils/with-mediator';
import { l10n } from '../../utils/l10n';
import SessionStorageUtil from '../../utils/session-storage';

export default (props) => {
  const store = withMediator(createMediator, createStore);

  const lang = {
    Checkout: l10n.Checkout,
    General: l10n.General,
  };

  props.lang = lang;

  return Vue.extend({
    store,
    created() {
      this.onCreated()
    },
    methods: {
      async onCreated() {
        const savedDetails = await SessionStorageUtil.get('checkout-customerDetails') || {};
        const mappedFields = fields.map(field => savedDetails[field.type] ? {
          ...field,
          value: savedDetails[field.type].value,
        } : field);

        store.dispatch('fields/load', mappedFields);
      },
    },
    render: h => h(App, { props })
  });
};
