var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStack",
    { attrs: { label: _vm.field.label, required: _vm.field.required } },
    [
      _c(
        "VcDatePicker",
        {
          attrs: {
            value: _vm.field.value,
            disabledDates: _vm.field.options,
            placeholder: _vm.field.placeholder,
            disableWeekends: false
          },
          on: {
            change: function($event) {
              return _vm.$emit("change", $event.target.value)
            }
          }
        },
        [
          _c("i", {
            staticClass: "fal fa-calendar-alt",
            attrs: { slot: "icon" },
            slot: "icon"
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }