var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStack",
    { attrs: { label: _vm.field.label, required: _vm.field.required } },
    [
      _c("textarea", {
        staticClass: "form-control banan",
        class: { "form-control--invalid": _vm.invalid },
        attrs: {
          placeholder: _vm.field.placeholder,
          required: _vm.field.required
        },
        domProps: { value: _vm.field.value },
        on: {
          change: function($event) {
            return _vm.$emit("change", $event.target.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }