import Vuex from 'vuex';
import config from './modules/config';
import fields from './modules/fields';
import sections from './modules/sections';

const strict = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
  strict,
  modules: {
    config,
    fields,
    sections,
  },
});
