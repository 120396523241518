export default {
  None: 0,
  Radio: 1,
  Checkbox: 2,
  Color: 3,
  Select: 4,
  Toggle: 5,
  Text: 6,
  Textarea: 7,
  Blurb: 8,
  ServiceAgreementInput: 10,
  Mileage: 11,
};
