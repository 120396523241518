<template>
  <div class="u-margin-top">
    <div class="row">
      <div class="col-md-12">
        <h4 class="u-fw-medium">
          Serviceavtal<br />
          <small class="u-c-muted"
            >Nedan ser du en sammanfattning av serviceavtalet, när service
            utförs och vad som ingår. Du kan även välja att inkludera
            serviceintervall som vi inte är helt säkra på om redan har
            utfört.</small
          >
        </h4>

        <table class="table table--striped table--bordered">
          <thead>
            <tr>
              <th>Servicetillfälle <small>(bilens ålder)</small></th>
              <th style="width: 80px;">
                <div
                  class="ttjs"
                  data-ttjs-title="Servicetillfällen som är nära i tid vet vi ej om du redan har utfört på din bil, de är därför valbara nedan."
                >
                  Vald <i class="far fa-question-circle"></i>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="instance in store.instances" :key="instance.months">
              <td>
                {{ instance.date }}
                <small>(månad {{ instance.months }})</small>
                <small
                  class="ttjs u-c-muted"
                  :data-ttjs-title="instance.tooltip"
                >
                  <i class="far fa-question-circle"></i> </small
                ><br />
                <small class="date-info">Datumet är ej exakt</small>
              </td>
              <td>
                <div
                  class="form-check"
                  :class="{ 'form-check--disabled': !instance.selectable }"
                >
                  <input
                    type="checkbox"
                    @change="calculate()"
                    v-model="instance.selected"
                    :disabled="!instance.selectable"
                    class="form-check__input"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import store from "./store";
import { VcFormStack } from "@holmgrensbil/vue-components";

export default {
  components: {
    VcFormStack
  },
  data() {
    return {
      store: store.state
    };
  },

  methods: {
    calculate() {
      store.calculate();
    }
  }
};
</script>
