import Axios from '../axios';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').
      on('change', '.department-select__select', this.onDepartmentSelect.bind(this));
  },

  onDepartmentSelect({ currentTarget }) {
    const $select = $(currentTarget);
    const val = $select.val();
    if(val && val.length && val.includes('-')) {
      const [departmentNr, service, days] = val.split('-');
      Axios.get(`/GetDepartmentPartial?departmentNr=${departmentNr}&service=${service}&days=${days}`).then(res => {
        $select.closest('.department-select').find('.department-select__info').html($(res.data));
      });
    }
  },

}
