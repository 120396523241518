<template>
  <div class="i3-filter-toggle toggle-switch-button" v-if="facet">
    <span>
      {{facet.displayName}}
    </span>

    <div class="toggle-switch">
      <input
        type="checkbox"
        :id="inputId"
        :value="onFilter.displayName"
        :checked="onFilter.displayName === selectedFilter.displayName"
        @change="onFilterToggle" />
      <label
        :for="inputId">
      </label>
    </div>
  </div>
</template>

<script>
import ChannelEvents from '../../constants/ChannelEvents';

export default {
  props: {
    channel: Object,
    facetId: String,
  },
  data() {
    return {
      facets: [],
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  computed: {
    facet() {
      return this.facets.find(f => f.id === this.facetId);
    },

    selectedFilter() {
      return this.facet.filters.filter(f => f.selected);
    },

    onFilter() {
      return this.facet.filters.find(f => f.displayName === '1');
    },

    inputId() {
      return `filter-toggle-${this.onFilter.displayName}`;
    },
  },
  methods: {
    onQuerySuccess() {
      this.facets = this.channel.data.facets;
    },

    onFilterToggle() {
      this.channel.execute(this.onFilter.query);
    },
  },
}
</script>
