<template>
  <div class="overlay-header">
    <transition name="fade">
      <div v-if="showBack" class="overlay-header__back" @click="$emit('back')">
        <i class="fal fa-arrow-left"></i>
      </div>
    </transition>
    <div class="overlay-header__text">
      {{text}}

      <span class="overlay-header__meta" v-if="meta">
        {{meta}}
      </span>
    </div>
    <div class="circle-action" @click="$emit('close')">
      <i class="fal fa-times"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    meta: String,
    showBack: Boolean,
  },
}
</script>
