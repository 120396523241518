import TyreBooking from "../apps/tyre-booking";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: TyreBooking,
      selector: '[data-app=tyre-booking]',
      props: {
        pageName: window.document.title,
      },
    });
  },
};
