var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.productObjects.length
    ? _c("div", { staticClass: "section__content section__content--lg" }, [
        _c("div", { staticClass: "section__header section__header--sm" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
          _c(
            "a",
            {
              staticClass: "header-meta link link--underline link--icon",
              attrs: { href: _vm.link }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.linkTitle))]),
              _vm._v(" "),
              _c("i", { staticClass: "fal fa-angle-right" })
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "eon-product-slider" },
          [
            _c("EonProductSlider", {
              attrs: { productObjects: _vm.productObjects }
            }),
            _vm._v(" "),
            _c("portal-target", {
              attrs: { name: "eon-product-services-modal" }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }