<template>
  <div class="wizard wizard--service-agreement">
    <SectionList
      :sections="sections"
      :SectionTypeComponents="SectionTypeComponents"
      @completed="onSectionCompleted">
    </SectionList>
    <FormSubmit
      :lastSection="lastSection"
      requireTerms
      @completed="onSectionCompleted">
    </FormSubmit>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import SectionTypes from './constants/SectionTypes';
import FieldTypes from './constants/FieldTypes';
import SectionList from '../shared/wizard/SectionList.vue';
import FormSubmit from '../shared/wizard/FormSubmit.vue';
import SectionTypeComponents from './constants/SectionTypeComponents';
import gtm from '../../utils/gtm';

export default {
  components: {
    SectionList,
    FormSubmit,
  },
  props: {
    regNr: String,
  },
  data() {
    return {
      SectionTypeComponents,
    };
  },
  created() {
    this.setField({ type: FieldTypes.RegNr, value: this.regNr });
  },
  computed: {
    ...mapState({
      sections: state => state.sections.items,
    }),

    ...mapGetters({
      fieldByType: 'fields/byType',
      sectionByType: 'sections/byType',
    }),

    lastSection() {
      return this.sectionByType(SectionTypes.Contact);
    },
  },
  methods: {
    ...mapActions({
      fetchPeriods: 'periods/fetch',
      setField: 'fields/set',
    }),

    onSectionCompleted(section) {
      const regNr = this.fieldByType(FieldTypes.RegNr).value;
      const regDate = this.fieldByType(FieldTypes.RegDate).value;
      const mileage = this.fieldByType(FieldTypes.Mileage).value;

      if (section.type === SectionTypes.Details) {
        this.fetchPeriods({ regNr, regDate, mileage });
      }

      gtm.serviceAgreementSectionCompleted({ section });
    },
  },
}
</script>
