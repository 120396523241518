var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleCarConfigurationGroupOptions.length
    ? _c("div", { staticClass: "campaign-configurator__config-group" }, [
        _c("label", [_vm._v("\n    " + _vm._s(_vm.group.heading) + "\n  ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-row" },
          _vm._l(_vm.visibleCarConfigurationGroupOptions, function(option) {
            return _c(
              "div",
              { key: option.parameterName, staticClass: "form-row__col" },
              [
                _c("ConfigGroupOption", {
                  attrs: { option: option, multiselect: _vm.multiselect },
                  on: {
                    optionChange: function($event) {
                      return _vm.onOptionChange(option, $event)
                    },
                    subOptionChange: function($event) {
                      return _vm.onSubOptionChange(option, $event)
                    }
                  }
                })
              ],
              1
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }