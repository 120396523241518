<template>
  <div class="vehicle-configurator configurator-app" v-if="storeState.config">
    <div class="vehicle-configurator__main">
      <div class="vehicle-configurator__header">
        <h3 class="vehicle-configurator__header__title">
          {{ lang.Summation.VehicleConfiguratorHeading }}
        </h3>
        <div class="vehicle-configurator__header__sub-title">
          {{ lang.Summation.VehicleConfiguratorSubHeading }}
        </div>
      </div>
      <FinanceSelector
        v-if="financingConfig"
        :selected="selectedFinancingOption"
        :financingConfig="financingConfig"
        :financingSummary="financingSummary.financing"
        @change-finance-option="onChangeFinanceOption"
        @change-tax-setting="onChangeTaxSetting"
        :displayTax="displayTax"
        :lang="lang"
      />
      <TradeInSelector
        v-if="tradeInData"
        :tradeInData="tradeInData"
        :lang="lang"
        :currentVehicleRegNr="regNr"
        :department="department"
      />
      <ServiceAgreementSelector
        v-if="displayServiceAgreement && serviceAgreementData"
        :serviceAgreementData="serviceAgreementData"
        :lang="lang"
      />
      <TyreSelector
        v-if="displayTyres && tyreData"
        :tyreData="tyreData"
        :selectedFinancingOption="selectedFinancingOption"
        :lang="lang"
      />
      <ChargeBoxSelector
        v-if="displayChargeBox && chargeBoxData"
        :chargeBoxData="chargeBoxData"
        :lang="lang"
      />
      <FittingsSelector
        v-if="displayFittings && fittingsData"
        :fittingsData="fittingsData"
        :selectedFinancingOption="selectedFinancingOption"
        :displayTax="displayTax"
        :lang="lang"
      />
    </div>
    <div class="vehicle-configurator__summation">
      <div class="configurator-section" v-if="priceSummation">
        <h4 class="configurator-section__title">
          <i class="fal fa-receipt configurator-section__icon"></i>
          {{ lang.Summation.VehicleConfiguratorSummationHeading }}
        </h4>
        <div>
          <div>
            <div class="vehicle-configurator__summation__row">
              <SummationVehicle
                :lang="lang"
                :vehicle="vehicle"
                :price="priceSummation.vehicle"
                :attention="selectedFinancingOption === 'privateLeasing'"
              />
            </div>

            <div
              v-if="tradeInSummation"
              class="vehicle-configurator__summation__row"
            >
              <div class="properties">
                <div class="properties__item properties__item--header">
                  <div class="properties__label">
                    INBYTESBIL
                  </div>
                </div>
                <SummationList :lang="lang" :list="tradeInSummation" />
                <div class="vehicle-configurator__summation__sub-notice">
                  {{ lang.Summation.VehicleConfiguratorTradeInMeta }}
                </div>
              </div>
            </div>
            <div
              v-if="serviceAgreementSummation"
              class="vehicle-configurator__summation__row"
            >
              <div class="properties">
                <div class="properties__item properties__item--header">
                  <div class="properties__label">
                    {{ serviceAgreementSummation.title }}
                  </div>
                </div>
                <SummationList
                  :lang="lang"
                  :list="serviceAgreementSummation.summation"
                />
                <SummationList
                  :lang="lang"
                  :list="serviceAgreementSummation.addedProducts"
                  :isSubList="true"
                />
                <div
                  v-if="serviceAgreementSummation.meta"
                  class="vehicle-configurator__summation__sub-notice"
                >
                  {{ serviceAgreementSummation.meta }}
                </div>
              </div>
            </div>
            <div
              v-if="addonSummations.length > 0"
              class="vehicle-configurator__summation__addons"
            >
              <div
                v-for="addon in addonSummations"
                :key="`${addon.title}-addon-summation`"
                class="vehicle-configurator__summation__row"
              >
                <div class="properties">
                  <div class="properties__item properties__item--header">
                    <div class="properties__label">
                      {{ addon.title }}
                    </div>
                  </div>
                  <SummationList :lang="lang" :list="addon.summation" />
                  <div
                    v-if="addon.meta"
                    class="vehicle-configurator__summation__sub-notice"
                  >
                    {{ addon.meta }}
                  </div>
                </div>
              </div>
            </div>
            <div class="vehicle-configurator__summation__row">
              <div class="properties">
                <div class="properties__item properties__item--header">
                  <div class="properties__label">
                    {{ lang.Summation.FinancingSummationHeading }}
                  </div>
                </div>
                <SummationList
                  :lang="lang"
                  :financing="
                    financingSummary.financing[selectedFinancingOption]
                  "
                  :selectedFinancing="selectedFinancingOption"
                />
              </div>
            </div>
          </div>
          <div
            v-if="priceSummation"
            class="vehicle-configurator__summation__row vehicle-configurator__summation__row--black-border"
          >
            <div v-if="isFinancing" class="total-price">
              <div class="total-price__row">
                <div class="total-price__title">
                  {{ lang.Summation.VehicleConfiguratorTotalPerMonth }}
                  <span class="total-price__title--meta">{{
                    priceSummation.totals.totalMeta
                  }}</span>
                </div>
                <div class="price-tag" :class="attentionTotal">
                  {{
                    $formatPriceNoSuffix(
                      priceSummation.totals.totalMonthlyCost
                    ) + priceSummation.suffixMonthly
                  }}
                  <span
                    v-if="
                      priceSummation.totals.totalOrdinaryMonthly &&
                        priceSummation.totals.totalOrdinaryMonthly >
                          priceSummation.totals.totalMonthlyCost
                    "
                    class="price-tag price-tag--obsolete"
                    >{{
                      $formatPriceNoSuffix(
                        priceSummation.totals.totalOrdinaryMonthly
                      ) + priceSummation.suffixMonthly
                    }}</span
                  >
                </div>
              </div>
              <div class="total-price__row">
                <div class="total-price__title">
                  {{ lang.Summation.VehicleConfiguratorTotal }}
                  <span class="total-price__title--meta">{{
                    priceSummation.totals.totalMeta
                  }}</span>
                </div>
                <div
                  class="price-tag"
                  :class="{
                    'price-tag--discount':
                      priceSummation.totals.totalOrdinaryCash &&
                      priceSummation.totals.totalOrdinaryCash >
                        priceSummation.totals.totalCash
                  }"
                >
                  {{
                    $formatPriceNoSuffix(priceSummation.totals.totalCash) +
                      priceSummation.suffix
                  }}
                  <span
                    v-if="
                      priceSummation.totals.totalOrdinaryCash &&
                        priceSummation.totals.totalOrdinaryCash >
                          priceSummation.totals.totalCash
                    "
                    class="price-tag price-tag--obsolete"
                    >{{
                      $formatPriceNoSuffix(
                        priceSummation.totals.totalOrdinaryCash
                      ) + priceSummation.suffix
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div v-else class="total-price">
              <div class="total-price__row">
                <div class="total-price__title">
                  {{ lang.Summation.VehicleConfiguratorTotal }}
                  <span class="total-price__title--meta">{{
                    priceSummation.totals.totalMeta
                  }}</span>
                </div>
                <div
                  class="price-tag"
                  :class="{
                    'price-tag--discount':
                      priceSummation.totals.totalOrdinaryCash &&
                      priceSummation.totals.totalOrdinaryCash >
                        priceSummation.totals.totalCash
                  }"
                >
                  {{
                    $formatPriceNoSuffix(priceSummation.totals.totalCash) +
                      priceSummation.suffix
                  }}
                  <span
                    v-if="
                      priceSummation.totals.totalOrdinaryCash &&
                        priceSummation.totals.totalOrdinaryCash >
                          priceSummation.totals.totalCash
                    "
                    class="price-tag price-tag--obsolete"
                    >{{
                      $formatPriceNoSuffix(
                        priceSummation.totals.totalOrdinaryCash
                      ) + priceSummation.suffix
                    }}</span
                  >
                </div>
              </div>
              <div
                class="total-price__row"
                v-if="priceSummation.totals.totalMonthlyCost > 0"
              >
                <div class="total-price__title">
                  {{ lang.Summation.VehicleConfiguratorTotalPerMonth }}
                  <span class="total-price__title--meta">{{
                    priceSummation.totals.totalMeta
                  }}</span>
                </div>
                <div class="price-tag" :class="attentionTotal">
                  {{
                    $formatPriceNoSuffix(
                      priceSummation.totals.totalMonthlyCost
                    ) + priceSummation.suffixMonthly
                  }}
                  <span
                    v-if="
                      priceSummation.totals.totalOrdinaryMonthly &&
                        priceSummation.totals.totalOrdinaryMonthly >
                          priceSummation.totals.totalMonthlyCost
                    "
                    class="price-tag price-tag--obsolete"
                    >{{
                      $formatPriceNoSuffix(
                        priceSummation.totals.totalOrdinaryMonthly
                      ) + priceSummation.suffixMonthly
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <SummationContact
            :department="department"
            :lang="lang"
            :selectedFinancingOption="selectedFinancingOption"
            :calculation="calculation"
            :isPurchasable="this.storeState.contact.displayPurchase"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { VcIntersectObserver } from "@holmgrensbil/vue-components";
import FinanceSelector from "./FinanceSelector.vue";
import TyreSelector from "./TyreSelector.vue";
import TradeInSelector from "./TradeInSelector.vue";
import SummationVehicle from "./SummationVehicle.vue";
import SummationList from "./SummationList.vue";
import Breakpoints from "../../constants/Breakpoints";
import store from "./store";
import RequestStates from "../../constants/RequestStates";
import gtm from "../../utils/gtm";
import ContactRequestTypes from "../../constants/ContactRequestTypes";
import FinanceOptions from '../../constants/FinanceOptions';
import SummationContact from "./SummationContact.vue";
import ServiceAgreementSelector from "./ServiceAgreementSelector.vue";
import FittingsSelector from "./FittingsSelector.vue";
import { EventBus } from "../../utils/event-bus";
import ChargeBoxSelector from "./AddChargeBoxSelector.vue";

export default {
  components: {
    VcIntersectObserver,
    FinanceSelector,
    TyreSelector,
    TradeInSelector,
    SummationVehicle,
    SummationList,
    SummationContact,
    ServiceAgreementSelector,
    FittingsSelector,
    ChargeBoxSelector
  },
  props: {
    lang: {
      type: Object,
      required: true
    },
    regNr: {
      type: String,
      required: true
    },
    carStatus: String
  },
  data() {
    return {
      storeState: store.state,
      madeChanges: false,
      didIntersect: false,
      selectedFinancingOption: "",
      FinanceOptions,
      displayTotalsWithTax: false
    };
  },
  async created() {
    await store.setRegNrAction(this.regNr);
    await store.fetchConfigAction();

    store.alterTax(taxCode === "0" ? true : false);

    this.onChangeFinanceOption(FinanceOptions.CASH);

    EventBus.$emit("set-vehicle-data", {
      contact: this.storeState.contact,
      data: this.storeState.calculation,
      vehicle: this.storeState.vehicle,
      selectedFinancingOption: this.storeState.selectedFinancingOption,
      regNr: this.storeState.regNr,
      isTransport: false, // TODO: set this dynamic
      isPurchasable: this.storeState.contact.displayPurchase
    });
  },
  computed: {
    attentionTotal: function() {
      return {
        "price-tag price-tag--discount":
          this.selectedFinancingOption === "privateLeasing" ||
          (this.priceSummation.totals.totalOrdinaryMonthly &&
            this.priceSummation.totals.totalOrdinaryMonthly >
              this.priceSummation.totals.totalMonthlyCost)
      };
    },
    isFinancing() {
      return this.selectedFinancingOption !== FinanceOptions.CASH;
    },
    department() {
      return this.storeState.contact.departmentNr
        ? this.storeState.contact.departmentNr
        : null;
    },
    calculation() {
      return this.storeState.calculation || {};
    },
    displayTax() {
      return this.storeState.displayTax;
    },
    vehicle() {
      return !!this.storeState.config && !!this.storeState.vehicle
        ? this.storeState.vehicle
        : null;
    },
    financingConfig() {
      return !!this.storeState && !!this.storeState.financingConfig
        ? this.storeState.financingConfig
        : null;
    },
    financingSummary() {
      return !!this.storeState && !!this.storeState.financingSummary
        ? this.storeState.financingSummary
        : null;
    },
    tyreData() {
      return !!this.storeState.tyreData &&
        this.selectedFinancingOption !== FinanceOptions.PRIVATELEASING &&
        this.selectedFinancingOption !== FinanceOptions.OPLEASING
        ? this.storeState.tyreData
        : null;
    },
    chargeBoxData() {
      return !!this.storeState.chargeBox &&
      this.selectedFinancingOption !== FinanceOptions.OPLEASING
        ? this.storeState.chargeBox
        : null;
    },
    displayTyres() {
      return this.storeState.config.displayTyres;
    },
    displayFittings() {
      return this.storeState.config.displayFittings;
    },
    displayTradeIn() {
      return this.storeState.config.displayTradeIn;
    },
    displayChargeBox() {
      return this.storeState.config.displayChargebox;
    },
    displayServiceAgreement() {
      return (
        this.selectedFinancingOption !== FinanceOptions.PRIVATELEASING &&
        this.selectedFinancingOption !== FinanceOptions.OPLEASING &&
        this.storeState.config.displayServiceAgreement
      );
    },
    serviceAgreementData() {
      return !!this.storeState.serviceAgreement
        ? this.storeState.serviceAgreement
        : null;
    },
    tradeInData() {
      return this.storeState.tradeIn && this.displayTradeIn
        ? this.storeState.tradeIn
        : null;
    },
    fittingsData() {
      return this.storeState.fittings && this.displayFittings
        ? this.storeState.fittings
        : null;
    },
    priceSummation() {
      if (this.financingSummary.financing && this.selectedFinancingOption) {
        const financingKey =
          this.selectedFinancingOption === FinanceOptions.INSTALLMENT ||
          this.selectedFinancingOption === FinanceOptions.LEASING
            ? "vehicle"
            : "financing";
        const selectedFinanceData = this.financingSummary.financing[
          this.selectedFinancingOption
        ];
        const selectedVehicleData = this.financingSummary["vehicle"][
          this.selectedFinancingOption
        ];

        let totals = {};
        let vehicle = {};

        if (this.displayTax) {
          totals = {
            totalCash: selectedFinanceData.totalCash,
            totalMonthlyCost: selectedFinanceData.totalMonthlyCost,
            totalOrdinaryCash: selectedFinanceData.totalOrdinaryCash,
            totalOrdinaryMonthly: selectedFinanceData.totalOrdinaryMonthly,
            totalMeta: "(inkl. moms)"
          };
        } else {
          totals = {
            totalCash: selectedFinanceData.totalCashNoVat,
            totalMonthlyCost: selectedFinanceData.totalMonthlyCostNoVat,
            totalOrdinaryCash: selectedFinanceData.totalOrdinaryCashNoVat,
            totalOrdinaryMonthly: selectedFinanceData.totalOrdinaryMonthlyNoVat,
            totalMeta: "(exkl. moms)"
          };
        }

        if (this.selectedFinancingOption === FinanceOptions.LEASING)
          totals.totalMeta = "(exkl. moms)";

        if (this.selectedFinancingOption === "cash") {
          vehicle = {
            current: this.displayTax
              ? selectedVehicleData.currentPrice
              : selectedVehicleData.currentPriceNoVat,
            ordinary: this.displayTax
              ? selectedVehicleData.ordinaryPrice
              : selectedVehicleData.ordinaryPriceNoVat,
            suffix: " kr"
          };
        } else if (this.selectedFinancingOption === "privateLeasing") {
          vehicle = {
            current: selectedFinanceData.monthlyCost,
            ordinary: selectedFinanceData.monthlyCost,
            suffix: " kr/månad"
          };
        } else {
          vehicle = {
            current: selectedVehicleData.monthlyCost,
            ordinary: selectedVehicleData.ordinaryMonthlyCost,
            suffix: " kr/månad"
          };
        }

        return {
          vehicle,
          totals,
          suffixMonthly: " kr/månad",
          suffix: " kr"
        };
      } else {
        return null;
      }
    },
    addonSummations() {
      const list = [];
      const tyres =
        this.storeState.tyres && this.storeState.tyres.length > 0
          ? this.storeState.tyres
          : [];
      const selectedTyres = tyres.filter(tyre => tyre.selected);
      const tyresSummary = this.financingSummary.tyres;
      const needTyres = this.storeState.needTyres;
      if (needTyres && selectedTyres.length <= 0) {
        const needTyreSummation = {
          title: "Vinterdäck",
          summation: [
            {
              name: "Vill ha vinterdäck",
              value: ""
            }
          ]
        };
        list.push(needTyreSummation);
      }
      const needChargeBox = this.storeState.needChargeBox;
      if (needChargeBox) {
        const needChargeBoxSummation = {
          title: "Laddbox",
          summation: [
            {
              name: "Vill ha prisförslag på laddbox",
              value: ""
            }
          ]
        };
        list.push(needChargeBoxSummation);
      }
      if (selectedTyres.length > 0) {
        const tyreSummation = {
          title: "Däck",
          summation: selectedTyres.map(tyre => {
            const tyreSummary =
              tyresSummary &&
              tyresSummary.find(tyreInSummary => tyreInSummary.id === tyre.id);
            const financing = tyreSummary ? tyreSummary : tyre.financing;
            return {
              name: tyre.heading,
              value: `${financing[this.selectedFinancingOption].currentPrice ||
                financing[this.selectedFinancingOption].monthlyCost}`,
              suffix:
                this.selectedFinancingOption !== FinanceOptions.CASH
                  ? " kr/månad"
                  : " kr"
            };
          }),
          meta: ""
        };
        list.push(tyreSummation);
      }

      const selectedFittings = this.storeState.fittingOptions.filter(
        fitting => fitting.selected
      );

      if (selectedFittings.length > 0) {
        const fittingSummation = {
          title: this.fittingsData.heading,
          summation: selectedFittings.map(fitting => ({
            name: fitting.heading,
            value: ""
          }))
        };
        list.push(fittingSummation);
      }

      return list;
    },
    serviceAgreementSummation() {
      if (this.storeState.serviceAgreementSummation) {
        const addedProducts = this.storeState.serviceAgreementSummation.additionalProducts
          .filter(product => product.selected)
          .map(product => ({
            name: product.name,
            value: product.monthlyCost,
            suffix: " kr/månad"
          }));

        return {
          title: "Serviceavtal",
          summation: [
            {
              name: "Serviceavtal",
              value: this.storeState.serviceAgreementSummation.monthlyCost,
              suffix: " kr/månad"
            }
          ],
          addedProducts,
          meta: ""
        };
      } else {
        return null;
      }
    },
    tradeInSummation() {
      const valuation = this.storeState.valuation;
      if (valuation) {
        return [
          {
            name: valuation.registrationNumber.toUpperCase()
          },
          {
            name:
              "I samband med att du skickar in en intresseförfrågan på denna bil så kommer säljaren ta med värderingen av din bil i kalkylen.",
            discount: false
          }
        ];
      } else {
        return null;
      }
    }
  },
  methods: {
    onIntersect() {
      this.didIntersect = true;
    },
    onChangeTaxSetting(value) {
      store.alterTax(!value);
      EventBus.$emit("alter-tax", !value);
    },
    onStickyClick({ departmentNr, label }) {
      const carStatus = this.carStatus;
      gtm.configuratorStickyClick({ label, departmentNr, carStatus });
    },
    async onChangeFinanceOption(selected) {
      const taxCode = Cookies.get("taxcode");
      let shouldDisplayTax = this.displayTax;

      if (selected === FinanceOptions.PRIVATELEASING || selected === FinanceOptions.OPLEASING) {
        store.clearServiceAgreement();
        store.setTyresAction([]);
      }

      await store.setselectedFinancingOption(selected);

      this.selectedFinancingOption = selected;
      if (selected === FinanceOptions.CASH) {
        shouldDisplayTax = taxCode === "0" ? true : false;
      } else if (
        selected === FinanceOptions.INSTALLMENT ||
        selected === FinanceOptions.PRIVATELEASING
      ) {
        shouldDisplayTax = true;
      } else if (selected === FinanceOptions.LEASING || selected === FinanceOptions.OPLEASING) {
        shouldDisplayTax = false;
      }
      EventBus.$emit("alter-tax", shouldDisplayTax);
      store.alterTax(shouldDisplayTax);
    }
  }
};
</script>
