import SectionTypes from '../constants/SectionTypes';

export default [
  {
    order: 1,
    type: SectionTypes.Lookup,
  },
  {
    order: 2,
    type: SectionTypes.Services,
  },
  {
    order: 3,
    type: SectionTypes.Department,
  },
  {
    order: 4,
    type: SectionTypes.Date,
  },
  {
    order: 4,
    type: SectionTypes.ExternalUrl,
    hideFooter: true,
    dormant: true,
  },
  {
    order: 5,
    type: SectionTypes.Contact,
  },
  {
    order: 6,
    type: SectionTypes.Addons,
  },
  {
    order: 6,
    type: SectionTypes.Summary,
    lazy: true,
    summary: true,
  },
];
