<template>
  <div
    class="vehicle-configurator__summation-list"
    :class="{ 'vehicle-configurator__summation-list--sub-list': isSubList }"
  >
    <div
      v-for="row in rows"
      :key="row.key"
      class="vehicle-configurator__summation-list__row"
      :class="{
        'vehicle-configurator__summation-list__row--cash-summation': selectedFinancing === FinanceOptions.CASH
      }"
    >
      <a
        v-if="row.nameLink"
        class="vehicle-configurator__summation-list__label vehicle-configurator__summation-list__label--link"
        :data-target="row.nameLink"
        data-modal-source="ajax"
      >
        {{ row.name }}
      </a>
      <div v-else class="vehicle-configurator__summation-list__label">
        {{ row.name }}
      </div>

      <div v-if="row.value && row.discount" class="vehicle-configurator__summation-list__value">
        <span v-if="row.ordinaryValue" class="vehicle-configurator__summation-list__value--obsolete">
          {{ `${row.ordinaryValue}${row.suffix || ""}` }}
        </span>
        &nbsp;
        <span class="vehicle-configurator__summation-list__value--discount">
          {{ `${row.value}${row.suffix || ""}` }}
        </span>
      </div>
      <div v-else-if="row.value" class="vehicle-configurator__summation-list__value">
        {{ `${row.value}${row.suffix || ""}` }}
      </div>
    </div>
    <div
      v-if="collapsible"
      class="vehicle-configurator__summation-list__expand"
      @click="onToggleCollapse"
    >
      <div>
        <span v-show="isCollapsed">Visa alla detaljer</span>
        <span v-show="!isCollapsed">Dölj alla detaljer</span>
      </div>
      <div>
        <i v-show="isCollapsed">
          <i class="fal fa-angle-down"></i>
        </i>
        <i v-show="!isCollapsed">
          <i class="fal fa-angle-up"></i>
        </i>
      </div>
    </div>
    <div v-if="inSummation" class="vehicle-configurator__summation-list__totals">
      <div v-if="summary.totalSetCost" class="vehicle-configurator__summation-list__row vehicle-configurator__summation-list__row--cash-total-row">
        <div class="vehicle-configurator__summation-list__label">
          {{ 'Att betala vid leverans:' }}
        </div>
        <div class="vehicle-configurator__summation-list__value">
          {{ `${$formatPriceNoSuffix(summary.totalSetCost)} kr` }}
        </div>
      </div>
      <div v-if="summary.totalMonthlyCost" class="vehicle-configurator__summation-list__row vehicle-configurator__summation-list__row--cash-total-row">
        <div class="vehicle-configurator__summation-list__label">
          {{ 'Att betala per månad:' }}
        </div>
        <div class="vehicle-configurator__summation-list__value">
          {{ `${$formatPriceNoSuffix(summary.totalMonthlyCost)} kr/mån` }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FinanceOptions from '../../../constants/FinanceOptions';
import store from "../store";
import { monthlyCost } from "../../../utils/priceCalculations";
import CalculationKeys from "../constants/calculation-keys";
export default {
  props: {
    selectedFinancing: Object,
    isSubList: Boolean,
    lang: Object,
    displayTax: Boolean,
    inSummation: Boolean,
  },
  data() {
    return {
      isCollapsed: true,
      FinanceOptions,
    };
  },
  computed: {
    collapsible() {
      const modifiedFinancingList = this.financingList || [];
      const list = [...modifiedFinancingList];
      return list.length > 5;
    },
    rows() {
      const modifiedFinancingList = this.financingList || [];
      const list = [...modifiedFinancingList];
      return this.isCollapsed ? list.slice(0, 5) : list;
    },
    summary() {
      return store.getFinanceSummary();
    },
    financingList() {
      let downPaymentName = "";
      let carPrice = this.selectedFinancing.financing.currentPrice;

      switch (this.selectedFinancing.id) {
        case FinanceOptions.CASH: {
          downPaymentName = this.lang.Summation.SummationListDownPayment;
          break;
        }
        case FinanceOptions.INSTALLMENT: {
          downPaymentName = this.lang.Summation.SummationListDownPayment;
          break;
        }
        case FinanceOptions.LEASING:
        case FinanceOptions.BLLEASING:
        case FinanceOptions.OPLEASING: {
          downPaymentName = this.lang.Summation.SummationListLeasingDownPayment;
          break;
        }
        case FinanceOptions.PRIVATELEASING: {
          downPaymentName = this.lang.Summation.SummationListDownPayment;
          break;
        }
      }

      const summary = this.summary;

      let list = [
        {
          name: this.selectedFinancing.name,
          value: this.$formatPriceNoSuffix(summary.currentPrice),
          suffix: this.selectedFinancing.calculationType === CalculationKeys.Set ? " kr" : ' kr/mån',
          typeList: [CalculationKeys.Monthly, CalculationKeys.Set, CalculationKeys.SetMonthly],
          key: `current-price${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListInstallmentCarPrice,
          value: this.$formatPriceNoSuffix(carPrice),
          suffix: " kr",
          typeList: [CalculationKeys.Monthly],
          key: `car-price${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListPeriod,
          value: this.$formatPriceNoSuffix(summary.periods?.find(p => p.selected)?.months),
          suffix: " månader",
          typeList: [CalculationKeys.Monthly],
          key: `period${this.inSummation && '-in-summation'}`
        },
        {
          name: downPaymentName,
          value: this.$formatPriceNoSuffix(summary.downPayment.toFixed()),
          suffix: " kr",
          typeList: [CalculationKeys.Monthly],
          key: `${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListInterest,
          value: this.$formatPriceNoSuffix(summary.rate),
          ordinaryValue: this.$formatPriceNoSuffix(summary.ordinaryRate),
          discount: summary.rate != summary.ordinaryRate,
          suffix: "%",
          typeList: [CalculationKeys.Monthly],
          key: `rate${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListEffectiveRate,
          value: this.$formatPriceNoSuffix(summary.effectiveRate),
          suffix: "%",
          typeList: [CalculationKeys.Monthly],
          key: `effective-rate${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListArrangementFee,
          value: this.$formatPriceNoSuffix(summary.arrangementFee),
          suffix: " kr",
          typeList: [CalculationKeys.Monthly],
          key: `arrangement-fee${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListRegistrationFee,
          value: this.$formatPriceNoSuffix(summary.registrationFee),
          suffix: " kr",
          typeList: [CalculationKeys.Monthly, CalculationKeys.Set],
          key: `registration-fee${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListAdministrationFee,
          value: this.$formatPriceNoSuffix(summary.administrationFee),
          suffix: " kr/mån",
          typeList: [CalculationKeys.Monthly],
          key: `administration-fee${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListResidualValue,
          value: this.$formatPriceNoSuffix(summary.residualValue),
          suffix: " kr",
          typeList: [CalculationKeys.Monthly],
          key: `residual-value${this.inSummation && '-in-summation'}`
        },

        {
          name: downPaymentName,
          value: this.$formatPriceNoSuffix(summary.privateLeasingDownPayment),
          suffix: " kr",
          typeList: [CalculationKeys.SetMonthly],
          key: `privateleasing-down-payment${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListMileage,
          value: this.$formatPriceNoSuffix(summary.privateLeasingMileage),
          suffix: " mil/år",
          typeList: [CalculationKeys.SetMonthly],
          key: `privateleasing-mileage-${this.inSummation && '-in-summation'}`
        },
        {
          name: this.lang.Summation.SummationListPeriod,
          value: this.$formatPriceNoSuffix(summary.privateLeasingPeriod),
          suffix: " månader",
          typeList: [CalculationKeys.SetMonthly],
          key: `privateleasing-period${this.inSummation && '-in-summation'}`
        },
      ]

      return list.filter(summaryItem => summaryItem.typeList.includes(this.selectedFinancing.calculationType) && (!this.inSummation ? !summaryItem.hideInConfigurator : true));
    }
  },
  methods: {
    onToggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
</script>

<style></style>
