var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tyre-row",
      class: {
        "tyre-row--danger": _vm.tyre.tread_depth <= 4
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "tyre-row__header",
          class: {
            "tyre-row__header--open": _vm.isOpen,
            "tyre-row__header--danger": _vm.tyre.tread_depth <= 4
          },
          on: { click: _vm.toggleRow }
        },
        [
          _c(
            "div",
            [
              _c("FontAwesomeIcon", {
                class: {
                  "tyre-row__icon--green": _vm.tyre.tread_depth >= 6,
                  "tyre-row__icon--yellow":
                    _vm.tyre.tread_depth < 6 && _vm.tyre.tread_depth > 4,
                  "tyre-row__icon--red": _vm.tyre.tread_depth <= 4
                },
                attrs: {
                  icon:
                    _vm.tyre.tread_depth <= 4
                      ? _vm.faExclamationTriangle
                      : _vm.faCheck
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.placements[_vm.tyre.placement]) +
                  " · " +
                  _vm._s(_vm.tyre.tread_depth) +
                  " mm\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("FontAwesomeIcon", {
                staticClass: "tyre-row__chevron",
                attrs: {
                  icon: _vm.isOpen ? _vm.faChevronUp : _vm.faChevronDown
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.isOpen
        ? _c(
            "div",
            {
              staticClass: "tyre-row__content",
              class: {
                "tyre-row__content--danger": _vm.tyre.tread_depth <= 4
              }
            },
            [
              _c("div", { staticClass: "tyre-row__content__title" }, [
                _vm._v(_vm._s(_vm.tyre.brand))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tyre-row__content__text" }, [
                _vm._v(_vm._s(_vm.tyre.dimension))
              ]),
              _vm._v(" "),
              _vm.tyre.has_tpms
                ? _c("FontAwesomeIcon", {
                    attrs: { icon: _vm.faTirePressureWarning }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOpen && _vm.tyre.tread_depth <= 4
        ? _c(
            "div",
            {
              staticClass: "tyre-row__content",
              class: {
                "tyre-row__content--danger": _vm.tyre.tread_depth <= 4
              }
            },
            [
              _c("div", { staticClass: "tyre-row__content__title" }, [
                _vm._v(
                  "\n      " +
                    _vm._s("Vi rekommenderar att du byter däck.") +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tyre-row__content__text" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      "Klicka på köp nya däck för att se däck som passar ditt fordon."
                    ) +
                    "\n    "
                )
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }