import vehicleContactModal from "../apps/vehicle-contact-modal";
import pageHeader from "../components/page-header";
import pageHeaderI3Filter from "../components/page-header-i3-filter";
import pageHeaderI3Sort from "../components/page-header-i3-sort";
import { EventBus } from '../utils/event-bus';
import mountApp from "../utils/mount-app";

export default {
  init() {
    pageHeader.init();
    pageHeaderI3Filter.init();
    pageHeaderI3Sort.init();
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $(document).on('click', '#floating-watch', this.openSearchWatchModal.bind(this));
  },

  modifyDOM() {
    this.mountVehicleContactModal();
  },

  openSearchWatchModal() {
    EventBus.$emit('open-search-watch-modal');
  },

  mountVehicleContactModal() {
    mountApp({
      app: vehicleContactModal,
      selector: "[data-app=vehicle-contact-modal]",
    });
  },
};
