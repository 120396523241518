<template>
  <VcFormStack :label="option.heading" :required="option.required">
    <input type="number" class="form-control" min="1" v-model="mileage" :required="option.required" />
    <div v-if="showInfo" class="mileage-notice-text">
      {{ lang.MileageErrorText }}
    </div>
  </VcFormStack>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';
import { mapGetters } from "vuex";
import FieldTypes from "../../service-booking/constants/FieldTypes";
import moment from 'moment';
import { l10n } from "../../../utils/l10n";
export default {
  components: {
    VcFormStack,
  },
  props: {
    option: Object,
    default: () => { }
  },
  data() {
    return {
      mileage: 0,
      showInfo: false,
      lang: l10n.ServiceBooking,
    }
  },
  computed: {
    selectedValue() {
      const selected = this.option.values.find(v => v.selected);
      return selected && selected.value;
    },
    ...mapGetters({
      fieldByType: 'fields/byType',
    })
  },
  watch: {
    mileage() {
      if (this.mileage < 1) {
        this.showInfo = true;
      } else {
        const modelYear = this.fieldByType(FieldTypes.ModelYear).value;
        if ((this.mileage && modelYear) && this.mileage / moment().diff(moment(modelYear, 'YYYY'), 'months', false) > 250) {
          this.showInfo = true;
          //this.$emit('change', 0);
        } else {
          this.showInfo = false;
          this.$emit('change', this.mileage);
        }
      }

    },
  }
}
</script>
