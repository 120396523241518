<template>
  <div class="cart-item" :class="{'cart-item--loading': loading}">
    <div class="cart-item__product">
      <div v-if="line.imageUrl" class="cart-item__product-thumbnail">
        <img :src="line.imageUrl" alt="Produktbild" />
      </div>
      <div class="cart-item__product-main">
        <div class="cart-item__product-meta" v-if="!isUpsellProduct && line.productType && line.productCondition">
          <label v-if="line.productType">
            {{line.productType}}
          </label>
          <span v-if="line.productCondition" class="mark mark--sm mark--black mark--uppercase">
            {{condition}}
          </span>
        </div>
        <div class="cart-item__product-name">
          <a :href="permalink">{{line.name}}</a>
        </div>
      </div>
    </div>

    <table class="table table--stretch">
      <tbody>
        <CartItemLine v-for="line in lines"
          :line="line"
          :key="line.id"
          :quantityDisabled="line.linkedQuantity"
          :quantity="line.quantity"
          :quantityOptions="quantityOptions"
          @quantity="$emit('quantity', { line, quantity: $event })"
          @remove="$emit('remove', { line })">
          <div v-if="line.parentLineItem">
            <label class="cart-item__line-meta">
              {{line.productType}}
            </label>
            <div class="cart-item__line-name">
              {{line.name}}
            </div>
          </div>
          <div v-else
            class="cart-item__line-delivery-meta">
            <CartItemDeliveryStatus
              :deliveryStatus="line.deliveryStatus">
            </CartItemDeliveryStatus>
          </div>
        </CartItemLine>
      </tbody>
    </table>

    <div v-if="matchingRegNr"
      class="cart-item__feedback cart-item__feedback--success">
      <i class="fas fa-check"></i>
      Passar fordon med regnr {{currentRegNr}}
    </div>
  </div>
</template>

<script>
import CartItemLine from './CartItemLine.vue';
import CartItemDeliveryStatus from './CartItemDeliveryStatus.vue';
import { mapState } from 'vuex';
import RequestStates from '../../../constants/RequestStates';
import LineItemProductConditions from '../../../constants/LineItemProductConditions';
import LineItemCustomDataKeys from '../../../constants/LineItemCustomDataKeys';
import LineItemProductTypes from '../../../constants/LineItemProductTypes';
import EonProductTypeSlugs from "../../eon-filter-page/constants/EonProductTypeSlugs";

export default {
  components: {
    CartItemLine,
    CartItemDeliveryStatus,
  },
  props: {
    line: Object,
    lineChildren: {
      type: Array,
      default: () => [],
    },
    currentRegNr: String,
  },
  computed: {
    ...mapState({
      setQuantityState: state => state.cart.setQuantityState,
    }),

    quantityOptions() {
      const min = this.line.minQuantity;
      const max = this.line.maxQuantity;
      const len = max - min + 1;
      const range = [...Array(len).keys()].map(v => v + min);
      const options = range.map(q => ({
        name: `${q} st`,
        value: q,
      }));

      return options;
    },
    isUpsellProduct() {
      return parseInt(this.line.productTypeId, 10) === LineItemProductTypes.CartUpsell;
    },

    quantityState() {
      return this.setQuantityState[this.line.id] || {};
    },

    loading() {
      return this.quantityState.state === RequestStates.Request;
    },
    permalink() {
      const {
        encodedName,
        encodedId,
        typeId,
      } = this;
      const baseUrl = window.EonConfig.productBaseUrl;
      const productTypeSlug = EonProductTypeSlugs[parseInt(this.line.productTypeId, 10)];

      return `${baseUrl}/${productTypeSlug}/${encodedName}-${encodedId}`;
    },

    encodedName() {
      return this.line.name.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]+/g, '');
    },

    encodedId() {
      return this.line.productId.toString().replace(/\./g, '');
    },

    lines() {
      return [
        this.line,
        ...this.lineChildren,
      ];
    },

    condition() {
      if (this.line.productConditionId == LineItemProductConditions.New) {
        return 'Ny';
      } else {
        return 'Beg';
      }
    },

    matchingRegNr() {
      const customDataRegNr = this.line.customData && this.line.customData.find(cd => cd.name === LineItemCustomDataKeys.RegistrationNumber);
      const regNr = customDataRegNr && customDataRegNr.value;

      if (this.currentRegNr && regNr) {
        return this.currentRegNr === regNr;
      }
    },
  },
}
</script>
