import mountApp from "../utils/mount-app";
import Checkout from '../apps/tyre-order';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    this.mountCheckout();
  },

  mountCheckout() {
    mountApp({
      app: Checkout,
      selector: '[data-app=tyre-order]',
      extractProps: el => ({
        rawOrder: JSON.parse(el.getAttribute('data-raw-order')),
        paymentMethod: JSON.parse(el.getAttribute('data-payment-method'))
      }),
    });
  },
};
