var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-images",
      on: {
        click: function($event) {
          return _vm.$emit("onImageClick")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "product-images__item",
          class: {
            "product-images__item--overlay": _vm.overlay,
            "product-images__item--fullscreen": _vm.isQuickBuy
          }
        },
        [
          _c("img", {
            attrs: {
              src: _vm.image.src,
              alt: _vm.image.alt,
              title: _vm.image.alt
            }
          }),
          _vm._v(" "),
          _vm.overlayLabel
            ? _c("span", [
                _vm._v("\n      " + _vm._s(_vm.overlayLabel) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldShowSaveBtns
            ? _c("div", { staticClass: "product-images__item__actions" }, [
                _c(
                  "div",
                  {
                    staticClass: "product-images__item__actions__btn",
                    class: {
                      "product-images__item__actions__btn--selected":
                        _vm.isVehicleCompared
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.compareVehicle($event)
                      }
                    }
                  },
                  [_c("FontAwesomeIcon", { attrs: { icon: _vm.falBalance } })],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }