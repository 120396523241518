var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VcOptionBox",
        {
          attrs: {
            selected: _vm.selected,
            heading: _vm.heading,
            smallText: _vm.smallText,
            smallBlock: _vm.smallText && _vm.smallText.length > 15,
            checkbox: _vm.checkbox,
            image: _vm.image,
            attentionRibbon: _vm.ribbonText,
            largeImage: _vm.largeImage,
            summationImage: _vm.summationImage
          },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            },
            linkClick: _vm.onLinkClick
          }
        },
        [
          _vm.price && _vm.price.attentionText && !_vm.image
            ? _c(
                "div",
                {
                  staticClass: "price-tag__campaign",
                  attrs: { slot: "header-aside" },
                  slot: "header-aside"
                },
                [
                  _c("div", { staticClass: "price-tag__campaign__tag" }, [
                    _c("span", {
                      staticClass: "price-tag--discount",
                      domProps: { innerHTML: _vm._s(_vm.price.attentionText) }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("VcPriceTag", {
                        attrs: {
                          price: _vm.price,
                          discounted: _vm.discounted,
                          showIfZero: ""
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm.price
            ? _c(
                "div",
                { attrs: { slot: "header-aside" }, slot: "header-aside" },
                [
                  _c("VcPriceTag", {
                    attrs: {
                      price: _vm.price,
                      discounted: _vm.discounted,
                      "show-if-zero": _vm.showIfZero
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.expandText
            ? _c(
                "div",
                { attrs: { slot: "header-link" }, slot: "header-link" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.expandText))]),
                  _vm._v(" "),
                  _c("FontAwesomeIcon", {
                    attrs: {
                      icon: _vm.expanded ? _vm.faAngleUp : _vm.faAngleDown
                    }
                  })
                ],
                1
              )
            : _vm.modalContent
            ? _c(
                "div",
                { attrs: { slot: "header-link" }, slot: "header-link" },
                [_c("span", [_vm._v("Läs mer")])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayContent && !_vm.externalUrl
            ? _c(
                "div",
                {
                  staticClass: "form-row",
                  attrs: { slot: "content" },
                  slot: "content"
                },
                _vm._l(_vm.subOptions, function(subOption) {
                  return _c(_vm.SubOptionComponents[subOption.inputType], {
                    key: subOption.name,
                    tag: "component",
                    attrs: { option: subOption },
                    on: {
                      change: function($event) {
                        return _vm.$emit("subOptionChange", {
                          subOption: subOption,
                          value: $event
                        })
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.displayContent && _vm.externalUrl
            ? _c(
                "div",
                {
                  staticClass: "form-row",
                  attrs: { slot: "content" },
                  slot: "content"
                },
                [
                  _c("h4", [_vm._v("Bokning sker på tillverkarens egna sida")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        För tillgång till det senaste serviceprogrammet bokas service hos\n        tillverkaren\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "option-box__actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--primary",
                        attrs: { href: _vm.externalUrl, target: "_blank" },
                        on: { click: _vm.externalBookingClick }
                      },
                      [_vm._v("Boka här")]
                    )
                  ])
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.modalOpen
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.modalOpen = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.modalContent) },
                slot: "content"
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }