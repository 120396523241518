var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-row__col" },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.Name } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-row__col form-row__col--md-50" },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.Email } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row__col form-row__col--md-50" },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.Phone } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }