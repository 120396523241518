var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.vehiclesLength > 0,
          expression: "vehiclesLength > 0"
        }
      ],
      staticClass: "compare-vehicles"
    },
    [
      _c("div", { staticClass: "compare-vehicles__toolbar-item" }, [
        _vm._v("\n    Jämför fordon\n    "),
        _c("div", { staticClass: "compare-vehicles__toolbar-item__counter" }, [
          _vm._v(_vm._s(_vm.vehiclesLength))
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "compare-vehicles__toolbar-item link link--underline",
          on: { click: _vm.onCompareVehiclesModalOpen }
        },
        [_vm._v("\n    Visa jämförelse\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "compare-vehicles__toolbar-item link link--underline",
          on: { click: _vm.clearCompare }
        },
        [_vm._v("\n    Rensa\n  ")]
      ),
      _vm._v(" "),
      _vm.activeCompareVehiclesModal
        ? _c("VcModal", { on: { close: _vm.onCompareVehiclesModalClose } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("Jämför fordon")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm.isMobile
                  ? _c("MobileComparison", {
                      attrs: { labels: _vm.labels, vehicles: _vm.vehicles },
                      on: { "delete-vehicle": _vm.deleteVehicle }
                    })
                  : _c("DesktopComparison", {
                      attrs: { labels: _vm.labels, vehicles: _vm.vehicles },
                      on: { "delete-vehicle": _vm.deleteVehicle }
                    })
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }