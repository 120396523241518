<template>
  <div>
    <VcOptionBox
      :selected="option.selected"
      :heading="option.heading"
      :smallText="option.headingMeta"
      :smallBlock="option.headingMeta && option.headingMeta.length > 15"
      :checkbox="multiselect"
      :image="option.image"
      :attentionRibbon="ribbonText"
      :largeImage="option.largeImage"
      @click="onClick"
      @linkClick="onLinkClick"
    >
      <div
        v-if="shouldShowPrice && option.price && option.price.attentionText && !option.image"
        slot="header-aside"
        class="price-tag__campaign"
      >
        <div class="price-tag__campaign__tag">
          <span class="price-tag--discount" v-html="option.price.attentionText"></span>
        </div>
        <div>
          <VcPriceTag :price="option.price" discounted showIfZero> </VcPriceTag>
        </div>
      </div>
      <div v-else-if="shouldShowPrice && option.price" slot="header-aside">
        <VcPriceTag :price="option.price" discounted :show-if-zero="showIfZero"> </VcPriceTag>
      </div>
      <div slot="header-link" v-if="option.subOptionsText">
        <span>{{ option.subOptionsText }}</span>
        <FontAwesomeIcon :icon="expanded ? faAngleUp : faAngleDown">
        </FontAwesomeIcon>
      </div>
      <div slot="header-link" v-else-if="option.modalContent">
        <span>Läs mer</span>
      </div>
      <div
        class="form-row"
        slot="content"
        v-if="displayContent"
      >
        <component
          v-for="subOption in option.subOptions"
          :key="subOption.name"
          :is="SubOptionComponents[subOption.inputType]"
          :option="subOption"
          :multiselect="subOption.inputType === 2"
          @change="$emit('subOptionChange', { alteredOption: option, alteredSubOption: subOption, value: $event })"
        >
        </component>
      </div>
    </VcOptionBox>

    <VcModal v-if="modalOpen" @close="modalOpen = false">
      <div slot="header">
        {{ option.heading }}
      </div>
      <div slot="content" v-html="option.modalContent"></div>
    </VcModal>
  </div>
</template>

<script>
import { VcOptionBox, VcModal, VcPriceTag } from "@holmgrensbil/vue-components";

import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import SubOptionComponents from './constants/SubOptionsComponents';
import InputTypes from "../../constants/InputTypes";
import FieldTypes from './constants/FieldTypes';
import { mapActions } from 'vuex';

export default {
  components: {
    VcOptionBox,
    VcModal,
    VcPriceTag,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    multiselect: Boolean,
  },
  data() {
    return {
      expanded: false,
      modalOpen: false,
      faAngleUp,
      faAngleDown,
      SubOptionComponents,
      InputTypes,
    }
  },
  mounted() {
    if (this.option.subOptions?.length > 0 && this.option.inputType === InputTypes.ServiceAgreementInput) {
      const subOptions = this.option.subOptions.map(option => {
        if (option.id !== 'signServiceAgreement') {
          return {
            ...option,
            price: {
              ...option.price,
              current: 0,
            }
          }
        } else {
          return option
        }
      })
      this.setField({
        type: FieldTypes.ServiceAgreement,
        options: subOptions,
      });
    }
  },
  computed: {
    displayContent() {
      const hasSubOptions = this.option.subOptions && this.option.subOptions.length;
      const isExpandable = !!this.option.subOptionsText;

      if (!hasSubOptions) {
        return false;
      }

      if (isExpandable) {
        return this.option.selected && this.expanded;
      }

      return this.option.selected;
    },
    ribbonText() {
      if(this.option?.largeImage) {
        return this.option?.price?.attentionText;
      }
      return this.option?.ribbonText;
    },
    showIfZero() {
      return this.option.price.showIfZero;
    },
    shouldShowPrice() {
      return this.option.inputType === InputTypes.ServiceAgreementInput ? !this.option.selected : true;
    }
  },
  methods: {
    ...mapActions({
      setField: "fields/set"
    }),
    onLinkClick() {
      if (!this.option.selected) {
        this.$emit("click");
      }

      if (this.option.subOptionsText) {
        this.expanded = !this.expanded;
      } else if (this.option.modalContent) {
        this.modalOpen = true;
      }
    },
    onClick() {
      this.$emit('optionChange', !this.option.selected);
    },
  },
}
</script>
