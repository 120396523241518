export default {
  Condition: "Condition",
  ProductType: "ProductType",
  BuildWheel: 'BuildWheel',
  Season: "Season",
  Diameter: "Diameter",
  TyreProperties: "TyreProperties",
  RimProperties: "RimProperties",
  Quality: "Quality",
  RimType: "RimType",
  RimOptions: "RimOptions",
  TyreOptions: "TyreOptions",
  RollingResistance: "RollingResistance",
  WetGrip: "WetGrip",
  Noise: "Noise",
  Speed: "Speed",
  Load: "Load",
  ApprovalMark: "ApprovalMark",
  TestScore: "TestScore",
  Brand: "Brand"
};
