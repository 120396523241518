var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trade-in-valuation" }, [
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        {
          staticClass: "trade-in-valuation__col trade-in-valuation__col--w-25"
        },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.RegNr } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "trade-in-valuation__col trade-in-valuation__col--w-25"
        },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.Mileage } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "trade-in-valuation__col trade-in-valuation__col--w-50"
        },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.Email } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "trade-in-valuation__col trade-in-valuation__col--w-50"
        },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.Phone } })],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "trade-in-valuation__condition" },
      [
        _c("SectionField", { attrs: { type: _vm.FieldTypes.VehicleCondition } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }