const state = {
  options: [],
  title: null,
  selected: null,
  preselected: [],
};

const getters = {
  availableServices(state) {
    if(state.preselected && state.preselected.length) {
      return state.options.filter(o => state.preselected.some(p => p.value === o.value));
    }
    return state.options;
  }
};

const actions = {
  load({ commit }, data) {
    commit('received', data);
  },

  select({ commit }, item) {
    commit('selected', item);
  },

  deselect({ commit, state }) {
    if (state.selected) {
      commit('deselected');
    }
  },

  preselected({ commit }, data) {
    commit('preselected', data);
  },
};

const mutations = {
  received(state, data) {
    state.options = data.options;
    state.title = data.title;
  },

  selected(state, item) {
    state.selected = item;
  },

  preselected(state, items) {
    state.preselected = items;
  },

  deselected(state) {
    state.selected = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
