var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "my-pages__form-section",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "a",
          {
            staticClass:
              "link link--underline link--muted-light my-pages__restore-link",
            on: { click: _vm.onRestoreClick }
          },
          [_vm._v("\n      " + _vm._s(_vm.lang.App.ForgotPassword) + "\n    ")]
        ),
        _vm._v(" "),
        _c("label", [
          _vm._v("\n      " + _vm._s(_vm.lang.App.LoginWithEmail) + "\n    ")
        ]),
        _vm._v(" "),
        _c("VcFormStack", { attrs: { label: "E-post", required: "" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            staticClass: "form-control",
            attrs: {
              placeholder: "Ange din e-post",
              required: "",
              type: "text"
            },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("VcFormStack", { attrs: { label: "Lösenord", required: "" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.password,
                expression: "password"
              }
            ],
            staticClass: "form-control",
            attrs: {
              placeholder: "Ange ditt lösenord",
              required: "",
              type: "password"
            },
            domProps: { value: _vm.password },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.password = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn--primary btn--block",
            class: { disabled: _vm.btnDisabled },
            attrs: { type: "submit" }
          },
          [_vm._v("\n      " + _vm._s(_vm.lang.App.Login) + "\n    ")]
        ),
        _vm._v(" "),
        _vm.confirmSuccess
          ? _c(
              "div",
              {
                staticClass:
                  "feedback-message feedback-message--success feedback-message--slim"
              },
              [
                _c("div", { staticClass: "feedback-message__text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lang.App.EmailConfirmed) +
                      "\n      "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.confirmResendSuccess
          ? _c(
              "div",
              {
                staticClass:
                  "feedback-message feedback-message--success feedback-message--slim"
              },
              [
                _c("div", { staticClass: "feedback-message__text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lang.App.ResendConfirmSuccess) +
                      "\n      "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loginError
          ? _c(
              "div",
              {
                staticClass:
                  "feedback-message feedback-message--danger feedback-message--slim"
              },
              [
                _c("div", { staticClass: "feedback-message__text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.errorMessageForDisplay) +
                      "\n      "
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayResendConfirm
          ? _c(
              "a",
              {
                staticClass: "btn u-margin-top btn--outline btn--block btn--xs",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.resendConfirm()
                  }
                }
              },
              [_vm._v("Skicka nytt verifieringsmail")]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "my-pages__form-section" }, [
      _c("label", [_vm._v("\n      Skapa konto\n    ")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn--white btn--block",
          on: { click: _vm.onRegisterClick }
        },
        [_vm._v("\n      " + _vm._s(_vm.lang.App.CreateAccount) + "\n    ")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "list-styled list-styled--checklist list-styled--sm" },
      [
        _c("li", [_vm._v("\n        Spara fordon som favoriter\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("\n        Starta bevakning på fordon\n      ")]),
        _vm._v(" "),
        _c("li", [_vm._v("\n        Se dina webbeställningar\n      ")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }