import Axios from 'axios';
import CartStoreKeys from '../constants/CartStoreKeys';

const ax = Axios.create();

ax.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
ax.defaults.headers.common['storeKey'] = CartStoreKeys.TyreStore;
ax.defaults.baseURL = '/api/cart';

export default {
  fetch() {
    return ax.get('');
  },

  fetchById(id) {
    return ax.get(`/${id}`);
  },

  empty() {
    return ax.delete(`/empty`);
  },

  addItem({ productId, quantity, childProducts, parentProductId, customData, isUpsellProduct }) {
    return ax.post('', { productId, quantity, childProducts, parentProductId, customData, isUpsellProduct });
  },

  updateItem({ id, quantity }) {
    return ax.put(`/${id}`, { quantity });
  },

  deleteItem({ id }) {
    return ax.delete(`/${id}`);
  },

  addPromotion({ code }) {
    return ax.post(`/promotion`, { code });
  },

  removePromotion() {
    return ax.delete(`/promotion`);
  },

  fetchCheckout() {
    return ax.get(`/checkout`);
  },

  fetchPickup() {
    return ax.get(`/pickup`);
  },

  setPickup({ id, name }) {
    return ax.post(`/pickup`, { id, name });
  },

  fetchCheckoutAddress(ssn) {
    return ax.get(`/checkout/address/${ssn}`);
  },

  fetchCheckoutPayments() {
    return ax.get(`/checkout/payments`);
  },

  setCheckoutPayment(paymentId) {
    return ax.post(`/checkout/payments/${paymentId}`);
  },

  fetchUpsellProducts() {
    return ax.get(`/upsell`);
  },

  fetchAdditionalService(additionalServiceProductId) {
    return ax.get(`/additionalservices/${additionalServiceProductId}`);
  },

  shareCart(emailAddress, destinationSiteId) {
    return ax.post('/send', {emailAddress, destinationSiteId});
  },
  setCommonHeader(key, value) {
    ax.defaults.headers.common[key] = value;
  }
}
