<template>
  <div>
    <div v-html="periodsMessage">
    </div>
    <SectionField :type="FieldTypes.Period" v-if="periodsItems.length">
    </SectionField>
    <SectionField :type="FieldTypes.PeriodAddons" class="wizard--service-agreement__addons">
    </SectionField>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';

export default {
  components: {
    SectionField,
  },
  data() {
    return {
      FieldTypes,
    };
  },
  computed: {
    ...mapState({
      periodsItems: state => state.periods.items,
      periodsMessage: state => state.periods.message,
    }),
  },
}
</script>
