var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcModal",
    {
      attrs: { small: "", fixed: "" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("\n    Tillagd i varukorg\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "eon-product-services-modal",
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _c("VcFeedbackMessage", { attrs: { success: "", slim: "" } }, [
            _c("i", { staticClass: "fas fa-check" }),
            _vm._v(
              "\n      " + _vm._s(_vm.rim.name) + " tillagd i varukorgen!\n    "
            )
          ]),
          _vm._v(" "),
          _c("VcFeedbackMessage", { attrs: { success: "", slim: "" } }, [
            _c("i", { staticClass: "fas fa-check" }),
            _vm._v(
              "\n      " +
                _vm._s(_vm.tyre.name) +
                " tillagd i varukorgen!\n    "
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.addons, function(addon) {
            return _c(
              "VcFeedbackMessage",
              { key: addon.id, attrs: { success: "", slim: "" } },
              [
                _c("i", { staticClass: "fas fa-check" }),
                _vm._v(
                  "\n      " +
                    _vm._s(addon.name) +
                    " tillagd i varukorgen!\n    "
                )
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "eon-product-services-modal__actions",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "a",
            {
              staticClass: "btn btn--white",
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("\n      Fortsätt handla\n    ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn--primary btn--block",
              attrs: { href: _vm.checkoutUrl }
            },
            [_vm._v("\n      Till kassan\n    ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }