<template>
  <GridItemProductSpot v-if="type === GridItemTypes.ProductSpot"
    :product="product"
    :selected="selected"
    :loading="loading"
    @click="$emit('select')"
    :savedVehicles="savedVehicles">
  </GridItemProductSpot>
  <GridItemQuickbuy v-else-if="type === GridItemTypes.Quickbuy"
    :product="product"
    :transitionOnOpen="transitionOnOpen"
    :savedVehicles="savedVehicles">
  </GridItemQuickbuy>
</template>

<script>
import GridItemTypes from './constants/GridItemTypes';
import GridItemProductSpot from './GridItemProductSpot.vue';
import GridItemQuickbuy from './GridItemQuickbuy.vue';
import I3DocProduct from '../../models/I3DocProduct';

export default {
  props: {
    type: Number,
    product: Object,
    selected: Boolean,
    loading: Boolean,
    transitionOnOpen: Boolean,
    savedVehicles: Array,
  },
  components: {
    GridItemProductSpot,
    GridItemQuickbuy,
  },
  data() {
    return {
      GridItemTypes,
    };
  },
}
</script>
