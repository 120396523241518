import SectionTypeComponents from '../../shared/wizard/constants/SectionTypeComponents';
import SectionTypes from './SectionTypes';
import SectionContentDetails from '../SectionContentDetails.vue';
import SectionContentPeriod from '../SectionContentPeriod.vue';

export default {
  ...SectionTypeComponents,
  [SectionTypes.Details]: SectionContentDetails,
  [SectionTypes.Period]: SectionContentPeriod,
}
