var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "product-meta__license-plate" }, [
        _c("div", { staticClass: "product-meta__license-plate__symbol" }),
        _vm._v(" "),
        _vm.product.displayReport
          ? _c("span", [
              _c(
                "a",
                {
                  staticClass: "link js-regnr-history",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openModal(_vm.ModalTypes.History)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.product.regNr))]
              )
            ])
          : _c("span", [_vm._v(_vm._s(_vm.product.regNr))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "product-meta__links" }, [
        _c("div", { staticClass: "product-meta__links__item" }, [
          _c("i", { staticClass: "fas fa-map-marker-alt" }),
          _vm._v(" " + _vm._s(_vm.product.locationName) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _vm.activeModal
        ? _c("VcModal", { on: { close: _vm.closeModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("\n      " + _vm._s(_vm.activeModal.header) + "\n    ")
            ]),
            _vm._v(" "),
            _c("div", {
              attrs: { slot: "content" },
              domProps: { innerHTML: _vm._s(_vm.activeModal.content) },
              slot: "content"
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }