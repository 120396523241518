<template>
  <a
    class="eon-product-title"
    @click="$emit('onClick')"
    :class="{ 'eon-product-title--lg': large }"
  >
    <div
      v-for="(line, index) in product.titleLines"
      :key="index"
      class="eon-product-title__line"
    >
      <h1 v-if="large" class="eon-product-title__text">
        {{ line.title }}
      </h1>
      <div v-else class="eon-product-title__text">
        {{ line.title }}
      </div>

      <div v-if="line.meta" class="eon-product-title__meta">
        <i v-if="line.metaIcon" :class="line.metaIcon"></i>
        {{ line.meta }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    product: Object,
    large: Boolean
  }
};
</script>
