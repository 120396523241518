import VCard from "vcard-creator";
import gtm from "../utils/gtm";
const FileSaver = require("file-saver");

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document)
      .on(
        "click",
        ".employee-page__social-media__btn",
        this.copyLinkToClipboard.bind(this)
      )
      .on(
        "click",
        ".js-employeepage-contactmodal",
        this.onContactLinkClick.bind(this)
      )
      .on(
        "click",
        ".js-employeepage-contactphone",
        this.onPhoneLinkClick.bind(this)
      )
      .on(
        "click",
        ".employee-page__action-bar__btn--save-contact",
        this.getVCard.bind(this)
      )
      .on(
        "click",
        ".employee-page__action-bar__btn--share",
        this.onShareLinkClick.bind(this)
      );
  },

  onShareLinkClick({ currentTarget }) {
    const $el = $(currentTarget);
    const departmentNr = $el.data("departmentnr");
    const label = $el.data("employeeid");

    gtm.shareModalClick({ departmentNr, label });
  },

  onContactLinkClick({ currentTarget }) {
    const $el = $(currentTarget);
    const departmentNr = $el.data("departmentnr");
    const label = $el.data("employeeid");

    gtm.contactModalClick({ departmentNr, label });
  },

  onPhoneLinkClick({ currentTarget }) {
    const $el = $(currentTarget);
    const departmentNr = $el.data("departmentnr");
    const phoneNr = $el.data("employeeid");

    gtm.phoneNumberClick({ departmentNr, phoneNr });
  },

  copyLinkToClipboard() {
    const copyText = document.getElementById(
      "employee-page__modal-input__share-url"
    );
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  },

  async getVCard(e) {
    const $el = $(e.currentTarget);
    const firstName = $el.data("first-name");
    const lastName = $el.data("last-name");
    const phone = $el.data("phone");
    const email = $el.data("email");
    const departmentNr = $el.data("departmentnr");
    const employeeId = $el.data("employeeid");
    const isCamping = $el.data("iscamping");

    gtm.saveContact({ departmentNr, employeeId });

    const vCard = new VCard();
    vCard
      .addName(lastName, firstName)
      .addEmail(email, "PREF;HOME")
      .addPhoneNumber(phone, "PREF;CELL")
      .addCompany(isCamping ? "Holmgrens Fritid" : "Holmgrens Bil");

    const blob = new Blob([vCard.toString()], {
      type: "text/plain;charset=utf-8"
    });
    FileSaver.saveAs(blob, `${firstName}_${lastName}.vcf`);
  }
};
