var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info-block" }, [
    _c("i", { staticClass: "info-block__icon fas fa-users" }),
    _vm._v(" "),
    _c("div", { staticClass: "info-block__header" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.department.staffCount) +
          " medarbetare hittades på anläggningen\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", [_vm._v("\n    " + _vm._s(_vm.department.address) + "\n  ")]),
    _vm._v(" "),
    _c("div", [
      _vm._v(
        "\n    " +
          _vm._s(_vm.department.postalCode) +
          " " +
          _vm._s(_vm.department.city) +
          "\n  "
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }