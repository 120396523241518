export default {
  OpenHours: 'open-hours',
  Staff: 'staff',
  Departments: 'departments',
  ServiceBooking: 'service-booking',
  BuyCar: 'buy-car',
  CustomerService: 'customer-service',
  PhoneNumber: 'phonenumber',
  SpareParts: 'spareparts',
  Tyres: 'tyres',
  DamageWorkshop: 'damageworkshop'
}
