import InputTypes from '../../../../constants/InputTypes';
import SubOptionsCheck from '../SubOptionsCheck.vue';
import SubOptionsSelect from '../SubOptionsSelect.vue';
import SubOptionsToggle from '../SubOptionsToggle.vue';
import SubOptionsText from '../SubOptionsText.vue';
import SubOptionsTextarea from '../SubOptionsTextarea.vue';
import SubOptionsNone from '../SubOptionsNone.vue';
import SubOptionsMileage from '../SubOptionsMileage.vue';

export default {
  [InputTypes.None]: SubOptionsNone,
  [InputTypes.Radio]: SubOptionsCheck,
  [InputTypes.Checkbox]: SubOptionsCheck,
  [InputTypes.Select]: SubOptionsSelect,
  [InputTypes.Toggle]: SubOptionsToggle,
  [InputTypes.Text]: SubOptionsText,
  [InputTypes.Textarea]: SubOptionsTextarea,
  [InputTypes.Mileage]: SubOptionsMileage,
};
