<template>
  <div class="search-watch">
    <i class="search-watch__icon fas fa-bell"> </i>
    <div class="search-watch__header">
      {{ header }}
    </div>
    <label>
      Starta en bevakning av:
    </label>
    <div class="search-watch__filters">
      <div v-for="tag in tags" class="search-watch__filter" :key="tag">
        {{ tag }}
      </div>
    </div>
    <div v-if="done">
      <VcFeedbackMessage v-if="isContactSubmit" success>
        <div slot="header">
          {{ feedbackContactHeader }}
        </div>
        {{ feedbackContactText }}
      </VcFeedbackMessage>
      <VcFeedbackMessage v-else success>
        <div slot="header">
          {{ feedbackHeader }}
        </div>
        {{ feedbackText }}
      </VcFeedbackMessage>
    </div>
    <VcFeedbackMessage v-else-if="error" danger>
      <div slot="header">
        Något gick fel!
      </div>
      Det gick ej att skapa din bevakning.
    </VcFeedbackMessage>
    <form
      v-if="!done"
      @submit.prevent="
        $emit(
          'saveSearches',
          email,
          selectedOption === 'contact',
          selectedDepartment && selectedDepartment.value,
          brandToWatch
        )
      "
    >
      <div class="form-group">
        <VcOptionBox
          :selected="selectedOption === 'watch'"
          :heading="'Jag vill starta en bevakning'"
          @click="onChangeOption('watch')"
        >
          <div v-if="selectedOption === 'watch'" slot="content">
            <div class="search-watch__option-text">
              {{ text }}
            </div>
          </div>
        </VcOptionBox>
        <VcOptionBox
          v-if="brandToWatch"
          :selected="selectedOption === 'contact'"
          :heading="'Jag vill bli kontaktad'"
          @click="onChangeOption('contact')"
        >
          <div v-if="selectedOption === 'contact'" slot="content">
            <div class="search-watch__option-text">
              Kanske finns det bilar att beställa eller bilar som är på väg hem som motsvarar din sökning, lämna din mail så kan en av våra säljare återkomma till dig
            </div>
            <VcFormStack
              v-if="!departmentNr"
              :label="'Välj anläggning'"
              :required="true"
            >
              <VcFormSelect
                :options="availableDepartments"
                :value="selectedDepartment"
                prompt="Välj anläggning"
                valueProp="value"
                labelProp="name"
                valueType="number"
                @change="onChangeDepartment"
              >
              </VcFormSelect>
            </VcFormStack>
          </div>
        </VcOptionBox>
        <div class="input-group" v-if="!isLoggedIn">
          <div class="form-stack form-stack--required">
            <label>
              E-post
            </label>
            <input
              v-model="email"
              type="email"
              autocomplete="email"
              class="form-control"
              placeholder="Ange din e-post"
              required
            />
          </div>
          <div class="input-group-append">
            <button
              class="btn btn--primary btn--sm"
              :class="{ disabled: loading || disabledBtn }"
              :disabled="loading || disabledBtn"
              type="submit"
            >
              {{ selectedOption === "contact" ? "Kontakta mig" : "Bevaka" }}
            </button>
          </div>
        </div>
        <div
          v-else
          class="btn btn--primary btn--block"
          :class="{ disabled: loading || disabledBtn }"
          @click="
            !disabledBtn &&
              $emit(
                'saveSearches',
                null,
                selectedOption === 'contact',
                selectedDepartment && selectedDepartment.value,
                brandToWatch
              )
          "
        >
          {{
            selectedOption === "contact"
              ? "Kontakta mig"
              : "Lägg till i mina bevakningar"
          }}
        </div>
      </div>
    </form>
    <div class="form-text" v-html="privacyPolicyText"></div>
  </div>
</template>

<script>
import {
  VcFeedbackMessage,
  VcOptionBox,
  VcFormSelect,
  VcFormStack
} from "@holmgrensbil/vue-components";
import Axios from "../../axios";
import { EventBus } from "../../utils/event-bus";

export default {
  components: {
    VcFeedbackMessage,
    VcOptionBox,
    VcFormSelect,
    VcFormStack
  },
  props: {
    header: String,
    text: String,
    feedbackHeader: String,
    feedbackText: String,
    feedbackContactHeader: String,
    feedbackContactText: String,
    privacyPolicyText: String,
    tags: Array,
    done: Boolean,
    loading: Boolean,
    searchSaved: Boolean,
    error: Boolean,
    brandToWatch: String,
    departmentNr: Number
  },
  data() {
    return {
      email: "",
      selectedDepartment: null,
      selectedOption: "watch",
      availableDepartments: [],
      isContactSubmit: false
    };
  },
  computed: {
    isLoggedIn() {
      return window.CurrentUser !== undefined && window.CurrentUser !== null;
    },
    disabledBtn() {
      if (this.selectedOption === "contact") {
        return !this.selectedDepartment;
      }
    }
  },
  watch: {
    brandToWatch() {
      if (this.brandToWatch) {
        this.getDepartments(this.brandToWatch);
      } else {
        this.selectedOption = "watch";
        this.selectedDepartment = null;
      }
    }
  },
  async mounted() {
    if (this.brandToWatch) {
      await this.getDepartments(this.brandToWatch);
    }

    if (this.departmentNr) {
      this.selectedDepartment =
        this.availableDepartments.find(
          department => department.value === this.departmentNr
        ) || null;
    }

    EventBus.$on("isContactSubmit", isContact => {
      this.isContactSubmit = isContact;
    });
  },
  methods: {
    onChangeOption(option) {
      if (option === "watch") {
        this.selectedDepartment = null;
      }

      this.selectedOption = option;
    },
    onChangeDepartment(value) {
      this.selectedDepartment = value;
    },
    async getDepartments(brand) {
      try {
        const { data } = await Axios.get(
          `/api/contact/GetDepartmentsByBrand?brand=${brand}`
        );
        this.availableDepartments = data || [];
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
