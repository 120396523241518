var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eon-single-product row" },
    [
      _vm.product.images.length && _vm.additionalServicesFetched
        ? _c(
            "div",
            { staticClass: "col-md-6 eon-single-product__images" },
            [
              _c("ImageSlider", { attrs: { images: _vm.product.images } }),
              _vm._v(" "),
              _vm.shouldShowSCTButton
                ? _c("div", { staticClass: "sound-comfort-button" }, [
                    _vm._m(0)
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-md-6 col-lg-5 eon-single-product__main",
          class: { hidden: !_vm.additionalServicesFetched }
        },
        [
          _c("div", { staticClass: "eon-single-product__meta" }, [
            _c(
              "span",
              {
                staticClass: "mark",
                class: {
                  "mark--black mark--sm mark--uppercase": _vm.product.isNew
                }
              },
              [_vm._v(_vm._s(_vm.product.shortConditionType))]
            ),
            _vm._v(
              "\n      " +
                _vm._s(_vm.product.productTypeDisplayName) +
                "\n      " +
                _vm._s(_vm.product.hasTpms ? "med TPMS" : "") +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("EonProductTitle", { attrs: { product: _vm.product, large: "" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "eon-single-product__buy" },
            [
              _vm.shouldShowRibbon
                ? _c(
                    "div",
                    { staticClass: "ribbon ribbon--attention ribbon--right" },
                    [_vm._v("\n        Förvaring utan kostnad\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldShowDiscountRibbon && _vm.product.discount <= 100
                ? _c(
                    "div",
                    { staticClass: "ribbon ribbon--attention ribbon--right" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.product.discount) +
                          "% rabatt\n      "
                      )
                    ]
                  )
                : _vm.shouldShowDiscountRibbon && _vm.product.discount > 100
                ? _c(
                    "div",
                    { staticClass: "ribbon ribbon--attention ribbon--right" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.product.discount * (_vm.showPriceFor4 ? 4 : 1)
                          ) +
                          "kr rabatt\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "eon-single-product__buy-price" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn--xs btn--white",
                    on: {
                      click: function($event) {
                        _vm.showPriceFor4 = !_vm.showPriceFor4
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          Visa pris för " +
                        _vm._s(_vm.showPriceFor4 ? "1" : "4") +
                        " st\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "span",
                    { staticClass: "eon-single-product__buy-price-total" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$formatPrice(
                              _vm.showPriceFor4
                                ? _vm.product.totalPriceWithVat(
                                    _vm.currentQuantity
                                  ) * 4
                                : _vm.product.totalPriceWithVat(
                                    _vm.currentQuantity
                                  )
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "eon-single-product__buy-price-quantity" },
                    [
                      _vm._v(
                        "\n            / " +
                          _vm._s(_vm.showPriceFor4 ? "4" : "") +
                          " st\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "eon-single-product__buy-price-meta" },
                    [
                      _vm.product.discounted(_vm.currentQuantity)
                        ? _c(
                            "span",
                            {
                              staticClass: "eon-product-spot__price--obsolete"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatPrice(
                                    _vm.product.totalOrdinaryPriceWithVat
                                  )
                                ) + " /\n              st"
                              ),
                              _c("br")
                            ]
                          )
                        : _vm._e(),
                      _vm._v(
                        "\n            Avbetalning från " +
                          _vm._s(_vm.$formatPrice(_vm.lowestPartPaymentPrice)) +
                          "/mån\n            per " +
                          _vm._s(_vm.showPriceFor4 ? "4" : "") +
                          " st\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link link--underline link--icon",
                          on: {
                            click: function($event) {
                              _vm.showMoreMeta = !_vm.showMoreMeta
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v("\n                Mer info\n              ")
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fal fa-angle-down",
                            class: {
                              "fa-angle-down": !_vm.showMoreMeta,
                              "fa-angle-up": _vm.showMoreMeta
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showMoreMeta
                        ? _c(
                            "div",
                            _vm._l(_vm.product.annuityFactors, function(
                              factor
                            ) {
                              return _c("span", { key: factor.duration }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(factor.paymentPlanName) +
                                    " -\n                " +
                                    _vm._s(
                                      _vm.$formatPrice(
                                        (
                                          _vm.product.totalPriceWithVat(
                                            _vm.showPriceFor4 ? 4 : 1
                                          ) *
                                          factor.factor *
                                          (_vm.showPriceFor4 ? 4 : 1)
                                        ).toFixed(0)
                                      )
                                    ) +
                                    "/mån "
                                ),
                                _c("br")
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.additionalServices, function(service) {
                return _c(
                  "VcOptionBox",
                  {
                    key: service.id,
                    attrs: {
                      selected: _vm.isServiceSelected(service),
                      heading: service.name,
                      checkbox: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.onServiceClick(service)
                      }
                    }
                  },
                  [
                    service.price
                      ? _c(
                          "div",
                          {
                            attrs: { slot: "header-aside" },
                            slot: "header-aside"
                          },
                          [
                            _c("VcPriceTag", {
                              attrs: { price: service.price, showIfZero: "" }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    service.modalContent
                      ? _c(
                          "div",
                          {
                            attrs: { slot: "header-meta" },
                            slot: "header-meta"
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "link link--underline",
                                on: {
                                  click: function($event) {
                                    return _vm.onReadMore($event, service)
                                  }
                                }
                              },
                              [_vm._v("Läs mer")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isServiceSelected(service) && service.subOptions.length
                      ? _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          _vm._l(service.subOptions, function(option) {
                            return _c(
                              "div",
                              { key: option.id },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "u-c-muted eon-single-product__sub-options-header"
                                  },
                                  [
                                    _vm._v(
                                      "\n              Uppgradera till " +
                                        _vm._s(option.name) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "VcOptionBox",
                                  {
                                    attrs: {
                                      selected: _vm.isSubOptionSelected(option),
                                      heading: "+ " + option.name,
                                      checkbox: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onSubOptionClick(
                                          service,
                                          option
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v("\n              >\n              "),
                                    option.modalContent
                                      ? _c(
                                          "div",
                                          {
                                            attrs: { slot: "header-meta" },
                                            slot: "header-meta"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "link link--underline",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onReadMore(
                                                      $event,
                                                      option
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("Läs mer")]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    option.price
                                      ? _c(
                                          "div",
                                          {
                                            attrs: { slot: "header-aside" },
                                            slot: "header-aside"
                                          },
                                          [
                                            _c("VcPriceTag", {
                                              attrs: {
                                                price: option.price,
                                                showIfZero: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              }),
              _vm._v(" "),
              !_vm.productAdded
                ? _c("EonProductBuyActions", {
                    staticClass: "eon-single-product__buy-actions",
                    attrs: {
                      selectedServices: _vm.allSelectedAdditionalServices,
                      product: _vm.product,
                      regNr: _vm.regNr
                    },
                    on: { quantity: _vm.onQuantity, added: _vm.onAdded }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.productAdded
                ? _c(
                    "VcFeedbackMessage",
                    { attrs: { success: "", slim: "" } },
                    [
                      _c("i", { staticClass: "fas fa-check" }),
                      _vm._v(
                        "\n        Produkten tillagd i varukorgen!\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "eon-single-product__buy-meta" }, [
                _c(
                  "div",
                  { staticClass: "eon-single-product__buy-meta-delivery" },
                  [
                    _c("DeliveryStatusCircle", {
                      attrs: { deliveryStatus: _vm.deliveryStatus }
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.deliveryStatusLabel) +
                          " hos en av\n            "
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "link link--underline",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onOpenSitesClick($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n              våra " +
                              _vm._s(_vm.numberOfSites) +
                              " däckcenter\n            "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "eon-single-product__buy-meta-delivery eon-single-product__buy-meta-delivery--custom",
                  domProps: { innerHTML: _vm._s(_vm.productUsps) }
                })
              ])
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.departmentsModalOpen
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.departmentsModalOpen = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Våra " + _vm._s(_vm.numberOfSites) + " däckcenter")
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.modalContent) },
                slot: "content"
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.readMoreModalOpen
        ? _c("VcModal", { on: { close: _vm.closeReadMore } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("\n      " + _vm._s(_vm.readMoreHeader) + "\n    ")
            ]),
            _vm._v(" "),
            _c("div", {
              attrs: { slot: "content" },
              domProps: { innerHTML: _vm._s(_vm.readMoreContent) },
              slot: "content"
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.servicesModalOpen
        ? _c("EonProductServicesModal", {
            attrs: {
              product: _vm.product,
              addedQuantity: _vm.currentQuantity,
              previouslyAddedServices: _vm.allSelectedAdditionalServiceObjects
            },
            on: {
              close: function($event) {
                _vm.servicesModalOpen = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "link",
        attrs: {
          "data-target": "/globalt-innehall/informationssidor/soundcomfort/",
          "data-modal-source": "ajax"
        }
      },
      [
        _c("img", {
          attrs: { src: "/Static/images/sound-comfort.png?width=160" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }