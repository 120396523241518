var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cart-item", class: { "cart-item--loading": _vm.loading } },
    [
      _c("div", { staticClass: "cart-item__product" }, [
        _vm.line.imageUrl
          ? _c("div", { staticClass: "cart-item__product-thumbnail" }, [
              _c("img", {
                attrs: { src: _vm.line.imageUrl, alt: "Produktbild" }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "cart-item__product-main" }, [
          !_vm.isUpsellProduct &&
          _vm.line.productType &&
          _vm.line.productCondition
            ? _c("div", { staticClass: "cart-item__product-meta" }, [
                _vm.line.productType
                  ? _c("label", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.line.productType) +
                          "\n        "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.line.productCondition
                  ? _c(
                      "span",
                      {
                        staticClass: "mark mark--sm mark--black mark--uppercase"
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.condition) + "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "cart-item__product-name" }, [
            _c("a", { attrs: { href: _vm.permalink } }, [
              _vm._v(_vm._s(_vm.line.name))
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "table table--stretch" }, [
        _c(
          "tbody",
          _vm._l(_vm.lines, function(line) {
            return _c(
              "CartItemLine",
              {
                key: line.id,
                attrs: {
                  line: line,
                  quantityDisabled: line.linkedQuantity,
                  quantity: line.quantity,
                  quantityOptions: _vm.quantityOptions
                },
                on: {
                  quantity: function($event) {
                    return _vm.$emit("quantity", {
                      line: line,
                      quantity: $event
                    })
                  },
                  remove: function($event) {
                    return _vm.$emit("remove", { line: line })
                  }
                }
              },
              [
                line.parentLineItem
                  ? _c("div", [
                      _c("label", { staticClass: "cart-item__line-meta" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(line.productType) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "cart-item__line-name" }, [
                        _vm._v(
                          "\n            " + _vm._s(line.name) + "\n          "
                        )
                      ])
                    ])
                  : _c(
                      "div",
                      { staticClass: "cart-item__line-delivery-meta" },
                      [
                        _c("CartItemDeliveryStatus", {
                          attrs: { deliveryStatus: line.deliveryStatus }
                        })
                      ],
                      1
                    )
              ]
            )
          }),
          1
        )
      ]),
      _vm._v(" "),
      _vm.matchingRegNr
        ? _c(
            "div",
            { staticClass: "cart-item__feedback cart-item__feedback--success" },
            [
              _c("i", { staticClass: "fas fa-check" }),
              _vm._v(
                "\n    Passar fordon med regnr " +
                  _vm._s(_vm.currentRegNr) +
                  "\n  "
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }