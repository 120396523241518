/*eslint no-undef: 0 */

import {scrollToEl} from '../utils/scrolling';

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('change', '.EPiServerForms .option-box input', this.onInputChange.bind(this));
    $(document).on('click', '.EPiServerForms .option-box', this.onOptionBoxClick.bind(this));
    if (typeof $$epiforms !== 'undefined') {
      $$epiforms(".EPiServerForms").on('formsSubmitted', this.onFormSubmitted);
    }
  },

  onFormSubmitted() {
    scrollToEl($$epiforms(this).get(0));
  },  

  onOptionBoxClick({ currentTarget, target }) {
    if(target.nodeName === "INPUT") {
      return;
    }
    const $target = $(currentTarget);
    const $input = $target.find('input');
    if($input.is(':checked') && $input.attr('type') !== 'radio') {
      $input.prop('checked', false).trigger('change');
    } else {
      $input.prop('checked', true).trigger('change');
    }
  },

  onInputChange({ currentTarget }) {
    const $target = $(currentTarget);
    const $optionBox = $target.closest('.option-box');
    if($target.attr('type') === 'radio') {
      $target.closest('.FormChoice').find('.option-box').removeClass('option-box--selected');
    }
    if($target.is(':checked')) {
      $optionBox.addClass('option-box--selected');
    } else {
      $optionBox.removeClass('option-box--selected');
    }
  }
}
