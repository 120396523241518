export const getPageYOffset = () => window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

export const scrollToEl = (el, blockTrigger = false) => {
  const windowHeight = document.documentElement.clientHeight;
  const elHeight = el.offsetHeight;
  const offset = el.getBoundingClientRect().top + getPageYOffset();
  const margin = (windowHeight - elHeight) / 2;
  const top = elHeight >= windowHeight ? offset : offset - margin;

  window.scrollTo({
    top,
    behavior: 'smooth',
  });

  if (!blockTrigger) {
    setTimeout(() => {
      $('body, html').trigger('scroll');
    }, 50);
  }
}
