<template>
  <div class="eon-result__empty">
    <h3 class="eon-result__empty__title">
      {{ `Ingen träff på ${getSizeString(tyreSize)}.${ filteredSizes.length > 0 ? ` Prova ändra till dessa däckdimensioner som passar din ${carName}:` : ''}`}}
    </h3>
    <div v-if="filteredSizes.length > 0" class="facet-filter-bar">
      <div v-for="(size, index) in filteredSizes"
        :key="index"
        class="filter-bar__tag filter-bar__tag--bordered"
        @click="onClick(size)">
        {{getSizeString(size)}}
      </div>
    </div>
    <div class="eon-result__empty__text">
      OBS! När man byter tum-storlek och endast köper lösa däck är det viktigt att man kontrollerar så att däckets tum stämmer överens med ens nuvarande fälgar. Annars kommer det inte gå att montera däcket på den gamla fälgen.
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      carName: String,
      tyreSize: Object,
      sizes: Array,
    },
    computed: {
      filteredSizes() {
        return this.sizes.filter(size => !(
          size.width.toString() === this.tyreSize.width.toString() &&
          size.aspectRatio.toString() === this.tyreSize.aspectRatio.toString() &&
          size.diameter.toString() === this.tyreSize.diameter.toString()
        ));
      },
    },
    methods: {
      getSizeString(size) {
        return `${size.diameter}" ${size.width}/${size.aspectRatio}R${size.diameter}`;
      },
      onClick(size) {
        this.$emit('click', {
          width: size.width,
          aspectRatio: size.aspectRatio,
          diameter: size.diameter,
        });
      },
    },
  }
  </script>
