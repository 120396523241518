import FilterConfigurations from "./FilterConfigurations";
import SectionTypes from "./SectionTypes";

export default {
  [FilterConfigurations.All]: [],
  [FilterConfigurations.SearchFor]: [
    SectionTypes.ProductType,
    SectionTypes.BuildWheel,
  ],
  [FilterConfigurations.Season]: [
    SectionTypes.Season,
  ],
  [FilterConfigurations.Filter]: [
    SectionTypes.Condition,
    SectionTypes.TyreProperties,
    SectionTypes.RimProperties,
  ],
  [FilterConfigurations.Dimension]: [
    SectionTypes.Diameter,
  ],
  [FilterConfigurations.MainOptions]: [
    SectionTypes.ProductType,
    SectionTypes.BuildWheel,
    SectionTypes.Season,
  ],
  [FilterConfigurations.SubOptions]: [
    SectionTypes.Condition,
    SectionTypes.Diameter,
    SectionTypes.RimProperties,
    SectionTypes.TyreProperties,
  ],
}
