var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vehicle-configurator__summation-list",
      class: { "vehicle-configurator__summation-list--sub-list": _vm.isSubList }
    },
    [
      _vm._l(_vm.rows, function(row) {
        return _c(
          "div",
          {
            key: row.key,
            staticClass: "vehicle-configurator__summation-list__row",
            class: {
              "vehicle-configurator__summation-list__row--cash-summation":
                _vm.selectedFinancing === _vm.FinanceOptions.CASH
            }
          },
          [
            row.nameLink
              ? _c(
                  "a",
                  {
                    staticClass:
                      "vehicle-configurator__summation-list__label vehicle-configurator__summation-list__label--link",
                    attrs: {
                      "data-target": row.nameLink,
                      "data-modal-source": "ajax"
                    }
                  },
                  [_vm._v("\n      " + _vm._s(row.name) + "\n    ")]
                )
              : _c(
                  "div",
                  {
                    staticClass: "vehicle-configurator__summation-list__label"
                  },
                  [_vm._v("\n      " + _vm._s(row.name) + "\n    ")]
                ),
            _vm._v(" "),
            row.value && row.discount
              ? _c(
                  "div",
                  {
                    staticClass: "vehicle-configurator__summation-list__value"
                  },
                  [
                    row.ordinaryValue
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "vehicle-configurator__summation-list__value--obsolete"
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  "" + row.ordinaryValue + (row.suffix || "")
                                ) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v("\n       \n      "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "vehicle-configurator__summation-list__value--discount"
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s("" + row.value + (row.suffix || "")) +
                            "\n      "
                        )
                      ]
                    )
                  ]
                )
              : row.value
              ? _c(
                  "div",
                  {
                    staticClass: "vehicle-configurator__summation-list__value"
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s("" + row.value + (row.suffix || "")) +
                        "\n    "
                    )
                  ]
                )
              : _vm._e()
          ]
        )
      }),
      _vm._v(" "),
      _vm.collapsible
        ? _c(
            "div",
            {
              staticClass: "vehicle-configurator__summation-list__expand",
              on: { click: _vm.onToggleCollapse }
            },
            [
              _c("div", [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCollapsed,
                        expression: "isCollapsed"
                      }
                    ]
                  },
                  [_vm._v("Visa alla detaljer")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isCollapsed,
                        expression: "!isCollapsed"
                      }
                    ]
                  },
                  [_vm._v("Dölj alla detaljer")]
                )
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "i",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isCollapsed,
                        expression: "isCollapsed"
                      }
                    ]
                  },
                  [_c("i", { staticClass: "fal fa-angle-down" })]
                ),
                _vm._v(" "),
                _c(
                  "i",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isCollapsed,
                        expression: "!isCollapsed"
                      }
                    ]
                  },
                  [_c("i", { staticClass: "fal fa-angle-up" })]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.inSummation
        ? _c(
            "div",
            { staticClass: "vehicle-configurator__summation-list__totals" },
            [
              _vm.summary.totalSetCost
                ? _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-configurator__summation-list__row vehicle-configurator__summation-list__row--cash-total-row"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-configurator__summation-list__label"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s("Att betala vid leverans:") +
                              "\n      "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-configurator__summation-list__value"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$formatPriceNoSuffix(
                                  _vm.summary.totalSetCost
                                ) + " kr"
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.summary.totalMonthlyCost
                ? _c(
                    "div",
                    {
                      staticClass:
                        "vehicle-configurator__summation-list__row vehicle-configurator__summation-list__row--cash-total-row"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-configurator__summation-list__label"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s("Att betala per månad:") +
                              "\n      "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vehicle-configurator__summation-list__value"
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$formatPriceNoSuffix(
                                  _vm.summary.totalMonthlyCost
                                ) + " kr/mån"
                              ) +
                              "\n      "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }