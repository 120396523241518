import Vuex from 'vuex';
import cart from '../../shared/ecom/modules/cart';
import fields from './modules/fields';
import payment from './modules/payment';
import paymentInputs from './modules/payment-inputs';
import pickup from './modules/pickup';
import ssnLookup from './modules/ssn-lookup';

const strict = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
  strict,
  modules: {
    cart,
    fields,
    payment,
    paymentInputs,
    pickup,
    ssnLookup,
  },
});
