import Axios from 'axios';
import CartStoreKeys from '../constants/CartStoreKeys';

const ax = Axios.create();

ax.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
ax.defaults.headers.common['storeKey'] = CartStoreKeys.TyreStore;
ax.defaults.baseURL = '/api/order';

export default {
  submit(data) {
    return ax.post('', data);
  },
  setCommonHeader(key, value) {
    ax.defaults.headers.common[key] = value;
  }
}
