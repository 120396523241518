import SectionTypeComponents from '../../shared/wizard/constants/SectionTypeComponents';
import SectionTypes from './SectionTypes';
import SectionContenCampingtDetails from '../SectionContentCampingDetails.vue';
import SectionContentImages from '../SectionContentImages.vue';

export default {
  ...SectionTypeComponents,
  [SectionTypes.CampingDetails]: SectionContenCampingtDetails,
  [SectionTypes.Images]: SectionContentImages,
}
