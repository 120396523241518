<template>
  <div>
    <div class="swiper-container product-swiper-container" ref="swiperContainer">
      <div class="swiper-wrapper">
        <div class="swiper-slide" @click="onOpenImageGallery(index)" v-for="(image, index) in images" :key="image.original">
          <img :src="image.webshop_sidebar" />
        </div>
      </div>
      <div v-show="showPagination" class="swiper-pagination"></div>
      <div class="magnifying-glass">
        <i class="fal fa-search"></i>
      </div>
    </div>
    <div v-show="showThumbnails" class="swiper-container product-swiper-container" ref="swiperThumbsContainer">
      <div class="swiper-wrapper">
        <div class="swiper-slide product-swiper-slide" v-for="image in images" :key="image.webshop_thumb">
          <img :src="image.webshop_thumb" />
        </div>
      </div>
    </div>
    <ImageGallery
      v-if="imageGalleryOpen"
      :images="deconstructedImages"
      :imageIndex="imageIndex"
      @close="onCloseImageGallery"
    ></ImageGallery>
  </div>
</template>

<script>
import Swiper from '../../swiper';
import Breakpoints from '../../constants/Breakpoints';
import ImageGallery from '../shared/ImageGallery.vue';

export default {
  props: {
    images: Array,
  },
  components: {
    ImageGallery,
  },
  data() {
    return {
      swiper: null,
      swiperThumbs: null,
      imageGalleryOpen: false,
      imageIndex: 0,
    };
  },
  mounted() {

    if(window.innerWidth > Breakpoints.sm) {
      const height = document.querySelector('.eon-single-product__main').clientHeight;
      document.querySelector('.eon-single-product__images').style.height = `${height}px`;
    }


    this.swiperThumbs = new Swiper(this.$refs.swiperThumbsContainer, {
      spaceBetween: 12,
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });

    this.swiper = new Swiper(this.$refs.swiperContainer, {
      slidesPerView: 1,
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      thumbs: {
        swiper: this.swiperThumbs,
      },
    });
  },
  updated() {
    this.swiper.update();
  },
  computed: {
    showThumbnails() {
      return this.windowWidth >= Breakpoints.md;
    },

    showPagination() {
      return this.windowWidth < Breakpoints.md;
    },
    deconstructedImages() {
      return this.images.map(image => image.original)
    },
  },
  methods: {
    onOpenImageGallery(index) {
      this.imageIndex = index || 0;
      this.imageGalleryOpen = true;
    },
    onCloseImageGallery() {
      this.imageGalleryOpen = false;
    }
  }
}
</script>
