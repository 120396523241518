<template>
  <div>
    <div v-for="(group, idx) in field.options" :key="idx" style="margin-right: 1rem; display: inline-block;">
      <div v-if="group.times.length > 0" class="date-wizard__label-container">
        <span class="date-wizard__label-container__label">
          {{group.heading}}
        </span>
      </div>
      <div class="time-container">
        <div
          v-for="(cell, index) in group.times"
          :key="index"
          class="time-container__cell"
          :class="{ 'time-container--selected': selected(cell) }"
          @click="onSelectTime(cell)">
          <span class="time-container__cell-text">{{ cell.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldTypes from './constants/FieldTypes';
import { mapActions } from 'vuex';

export default {
  props: {
    field: Object,
    invalid: Boolean,
  },
  data() {
    return {
      selectedTime: null,
    };
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),
    onSelectTime(time) {
      this.selectedTime = time;
      this.$emit('change', `${ time.start }-${ time.end }`);
      this.setField({ type: FieldTypes.CustomerIsWaiting, value: time.customerIsWaiting });
    },
    selected(availableTime) {
      return this.selectedTime && availableTime.start === this.selectedTime.start;
    },
  },
}
</script>
