import SectionHandles from './SectionHandles';
import DepartmentLocation from '../DepartmentLocation.vue';
import DepartmentStaff from '../DepartmentStaff.vue';
import DepartmentHours from '../DepartmentHours.vue';

export default {
  [SectionHandles.OpenHours]: DepartmentHours,
  [SectionHandles.Staff]: DepartmentStaff,
  [SectionHandles.Departments]: DepartmentLocation,
  [SectionHandles.ServiceBooking]: DepartmentLocation,
  [SectionHandles.BuyCar]: DepartmentLocation,
  [SectionHandles.CustomerService]: DepartmentLocation,
  [SectionHandles.PhoneNumber]: DepartmentLocation,
  [SectionHandles.SpareParts]: DepartmentLocation,
  [SectionHandles.Tyres]: DepartmentLocation,
  [SectionHandles.DamageWorkshop]: DepartmentLocation,
}
