<template>
  <div class="info-block">
    <i class="info-block__icon fas fa-map-marker-alt">
    </i>

    <div class="info-block__header">
      {{department.name}}
    </div>

    <div>
      {{department.address}}
    </div>

    <div>
      {{department.postalCode}} {{department.city}}
    </div>

    <a v-if="department.directionsLink"
      class="link link--muted link--underline"
      :href="department.directionsLink"
      target="_blank">
      Vägbeskrivning
    </a>
  </div>
</template>

<script>
export default {
  props: {
    department: Object,
  },
}
</script>
