import isDefined from '../../../../utils/is-defined';
import FieldTypes from '../../constants/FieldTypes';

const state = {
  items: [],
};

const getters = {
  byType: ({ items }) => type => {
    return items.find(item => item.type === type);
  },

  bySection: ({ items }) => section => {
    return items.filter(item => item.section === section);
  },

  valid(_, getters) {
    return !getters.invalid.length;
  },

  invalid({ items }) {
    return items.filter(i => {
      const errors = [];

      let item;

      if (i.type === FieldTypes.Phone) {
        item = {
          ...i,
          value: i.value ? i.value.replace(/\s/g, "") : i.value,
        }
      } else {
        item = i;
      }


      if (item.required && !isDefined(item.value)) {
        errors.push('required');
      }


      if (item.inputPattern && !item.inputPattern.test(item.value)) {
        errors.push('pattern');
      }

      return !!errors.length;
    });
  },

  export({ items }) {
    const data = {};

    items.forEach(item => {
      data[item.type] = item.value;
    });

    return data;
  },
};

const actions = {
  load({ commit }, items) {
    commit('received', items);
  },

  set({ commit }, item) {
    commit('set', item);
  },
};

const mutations = {
  received(state, items) {
    state.items = items;
  },

  set(state, { type, ...props }) {
    state.items = state.items.map(item => {
      return (item.type === type) ? {
        ...item,
        ...props,
      } : item;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
