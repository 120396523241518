<template>
  <FontAwesomeIcon :icon="faCircle" :class="`attention-circle ${circleClass} ${small ? 'attention-circle--sm': ''}`"></FontAwesomeIcon>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import LineItemDeliveryStatusCircleClasses from '../../constants/LineItemDeliveryStatusCircleClasses';
import LineItemDeliveryStatuses from '../../constants/LineItemDeliveryStatuses';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    deliveryStatus: Number,
    small: Boolean,
  },
  computed: {
    circleClass() {
      return LineItemDeliveryStatusCircleClasses[this.deliveryStatus];
    },
  },
  data() {
    return {
      faCircle,
    };
  },
}
</script>
