<template>
  <div v-if="component.documents.length > 0">
    <div v-if="listHeading" class="search-results__heading">
      {{ listHeading }}
    </div>
    <div v-if="component.id === I3FederatedSearchTypes.Cars">
      <div class="search-results__container search-results__container--column" >
        <div v-for="document in component.documents.slice(0, 2)"
          :key="document._id"
          class="search-results__item"
        >
          <v-component :is="I3FederatedSearchTypeComponents[component.id]"
            :document="document">
          </v-component>
        </div>
      </div>
      <div class="search-results__btn" v-if="component.documents.length > 2">
        <div class="btn btn--primary btn--block" @click="$emit('onClick')">
          {{ `Visa alla bilar (${component.numberOfHits})` }}
        </div>
      </div>
    </div>
    <div class="search-results__container" v-else>
      <div
        v-for="document in component.documents"
        class="search-results__item"
        :key="document._id"
      >
        <v-component :is="I3FederatedSearchTypeComponents[component.id]"
          :document="document"
          :resultType="component.id"
        >
        </v-component>
      </div>
    </div>
  </div>
</template>

<script>
import I3FederatedSearchComponents from '../../constants/I3FederatedSearchComponents';
import I3FederatedSearchTypeComponents from './constants/I3FederatedSearchTypeComponents';
import I3FederatedSearchTypes from "../../constants/I3FederatedSearchTypes";

export default {
  props: {
    component: Object,
  },
  data() {
    return {
      I3FederatedSearchTypeComponents,
      I3FederatedSearchTypes,
    };
  },
  computed: {
    listHeading() {
      switch (this.component.id) {
        case I3FederatedSearchTypes.Cars: return 'Fordon';
        case I3FederatedSearchTypes.Episerver: return 'Sidor';
        case I3FederatedSearchTypes.Employees: return 'Personal';
        //case I3FederatedSearchTypes.Departments: return 'Anläggningar';
        default: return '';
      }
    }
  },
}
</script>
