<template>
  <div class="properties__item">
    <img class="properties__item__image" v-if="line.image" :src="line.image">
    <div class="properties__item__text-container">
      <div class="properties__item__label">
        {{line.name}}
      </div>
      <div class="properties__item__sub-label">
        {{line.message}}
      </div>
      <div class="product-usps" v-for="usp in line.usps" :key="usp.name">
        <div class="product-tag" :class="usp.value">{{usp.name}}</div>
      </div>
    </div>
    <div class="properties__value">
      <VcPriceTag :price="line.price" :show-if-zero="true">
      </VcPriceTag>
    </div>
  </div>
</template>

<script>
import { VcPriceTag } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcPriceTag,
  },
  props: {
    line: Object,
  },
}
</script>
