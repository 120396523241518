var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.wrapperComponent, { tag: "v-component" }, [
    _c(
      "div",
      { staticClass: "vehicle-list__full-item-wrapper" },
      [
        _c(
          "div",
          {
            ref: "container",
            staticClass: "vehicle-list__full-item",
            on: {
              click: function($event) {
                return _vm.$emit("click")
              }
            }
          },
          [
            _c("div", { staticClass: "quickbuy" }, [
              _c(
                "div",
                { staticClass: "quickbuy__aside" },
                [
                  (_vm.productImages && _vm.productImages.length === 1) ||
                  !_vm.product.available
                    ? _c("ProductImage", {
                        attrs: {
                          image: _vm.productImages[0],
                          overlay:
                            !_vm.product.available || _vm.product.noImage,
                          overlayLabel: _vm.product.availableLabel,
                          isQuickBuy: true
                        },
                        on: { onImageClick: _vm.onOpenImageGallery }
                      })
                    : _vm.productImages.length > 1
                    ? _c("ProductImageSlider", {
                        attrs: {
                          images: _vm.productImages,
                          product: _vm.product,
                          isQuickBuy: true
                        },
                        on: { onImageClick: _vm.onOpenImageGallery }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "quickbuy__main" }, [
                _c(
                  "div",
                  { staticClass: "quickbuy__main-content" },
                  [
                    _c(
                      "div",
                      { staticClass: "product-meta" },
                      [
                        _c("ProductMetaLinks", {
                          attrs: { product: _vm.product }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "product-meta-actions" }, [
                          _c(
                            "div",
                            {
                              staticClass: "bookmark-top",
                              class: {
                                ttjs: !_vm.isLoggedIn || _vm.productSaved,
                                "save-vehicle__user-not-logged-in": !_vm.isLoggedIn
                              },
                              attrs: {
                                "data-ttjs-title": !_vm.isLoggedIn
                                  ? "Logga in för att kunna spara fordon"
                                  : "Tillagd i dina sparade fordon. Under Mina sidor kan du se och ta bort sparade fordon"
                              },
                              on: { click: _vm.onSaveVehicle }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "bookmark-top__icon",
                                  class: {
                                    "bookmark-bottom__icon--selected":
                                      _vm.productSaved
                                  }
                                },
                                [
                                  _c("FontAwesomeIcon", {
                                    attrs: {
                                      icon: _vm.productSaved
                                        ? _vm.fasHeart
                                        : _vm.falHeart
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "link bookmark-top__link",
                                  class: {
                                    "bookmark-bottom__link--selected":
                                      _vm.productSaved
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.productSaved ? "Sparad" : "Spara"
                                    )
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "bookmark-top u-d-internal-user" },
                            [
                              _c(
                                "div",
                                { staticClass: "bookmark-top__icon" },
                                [
                                  _c("FontAwesomeIcon", {
                                    attrs: { icon: _vm.falPrint }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "link bookmark-top__link",
                                  attrs: {
                                    href: _vm.product.permalink + "/print"
                                  }
                                },
                                [_vm._v("Skriv ut")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bookmark-top" }, [
                            _c(
                              "div",
                              { staticClass: "bookmark-top__icon" },
                              [
                                _c("FontAwesomeIcon", {
                                  attrs: { icon: _vm.falShareSquare }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "link bookmark-top__link js-share-this-url",
                                attrs: {
                                  "data-currenturl": _vm.product.permalink,
                                  "data-toggle": "modal",
                                  "data-target": "[data-share-page-modal]"
                                }
                              },
                              [_vm._v("Dela")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "bookmark-top",
                              on: { click: _vm.compareVehicle }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "bookmark-top__icon" },
                                [
                                  _c("FontAwesomeIcon", {
                                    attrs: { icon: _vm.falBalance }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "link bookmark-top__link" },
                                [
                                  _vm._v(
                                    "\n                    Jämför\n                  "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "product-description" }, [
                      _c("h3", { staticClass: "product-description__header" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.product.brandModelTitle) +
                            "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-description__text" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.product.shortModelText) +
                            "\n              "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("ProductDetailModals", {
                      attrs: { product: _vm.product }
                    }),
                    _vm._v(" "),
                    _vm.product.available
                      ? _c(
                          "div",
                          {
                            staticClass: "swiper-container",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _vm.product.dicount ||
                            (_vm.product.usps &&
                              ((_vm.product.usps.campaignUsps &&
                                _vm.product.usps.campaignUsps.length > 0) ||
                                (_vm.product.usps.usps &&
                                  _vm.product.usps.usps.length > 0)))
                              ? _c(
                                  "div",
                                  { staticClass: "swiper-wrapper" },
                                  [
                                    _vm.product.discount &&
                                    _vm.priceMode === "regular"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "product-tag red swiper-slide"
                                          },
                                          [
                                            _c("FontAwesomeIcon", {
                                              attrs: { icon: _vm.fasTag }
                                            }),
                                            _vm._v(
                                              "\n                  Prissänkt " +
                                                _vm._s(
                                                  _vm.$formatPrice(
                                                    _vm.product.discount
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.product.discount &&
                                    _vm.priceMode === "noTax"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "product-tag red swiper-slide"
                                          },
                                          [
                                            _c("FontAwesomeIcon", {
                                              attrs: { icon: _vm.fasTag }
                                            }),
                                            _vm._v(
                                              "\n                  Prissänkt " +
                                                _vm._s(
                                                  _vm.$formatPrice(
                                                    _vm.product.discountNoVat
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.product.usps.campaignUsps,
                                      function(usp) {
                                        return _c(
                                          "div",
                                          {
                                            key: usp.name,
                                            staticClass:
                                              "product-tag swiper-slide",
                                            class: usp.colour
                                          },
                                          [
                                            usp.modalUrl
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass: "link",
                                                    attrs: {
                                                      "data-target":
                                                        usp.modalUrl,
                                                      "data-modal-source":
                                                        "ajax"
                                                    }
                                                  },
                                                  [
                                                    usp.faIcon
                                                      ? _c("i", {
                                                          class: usp.faIcon
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(_vm._s(usp.name))
                                                  ]
                                                )
                                              : [
                                                  usp.faIcon
                                                    ? _c("i", {
                                                        class: usp.faIcon
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(_vm._s(usp.name))
                                                ]
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.product.usps.usps, function(
                                      usp
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: usp.name,
                                          staticClass:
                                            "product-tag swiper-slide",
                                          class: usp.colour
                                        },
                                        [
                                          usp.modalUrl
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "link",
                                                  attrs: {
                                                    "data-target": usp.modalUrl,
                                                    "data-modal-source": "ajax"
                                                  }
                                                },
                                                [
                                                  usp.faIcon
                                                    ? _c("i", {
                                                        class: usp.faIcon
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(_vm._s(usp.name))
                                                ]
                                              )
                                            : [
                                                usp.faIcon
                                                  ? _c("i", {
                                                      class: usp.faIcon
                                                    })
                                                  : _vm._e(),
                                                _vm._v(_vm._s(usp.name))
                                              ]
                                        ],
                                        2
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-facts product-facts--sm" },
                      [
                        _c("div", { staticClass: "product-facts__item" }, [
                          _c("div", { staticClass: "meta-tag black rounded" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.product.shortVehicleCondition) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(_vm.product.modelYear))])
                        ]),
                        _vm._v(" "),
                        _vm.product.vehicleType != "Husvagn"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-road" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.mileage) + " mil")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.taxAmount &&
                        _vm.product.vehicleType != "Husvagn"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-coins" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.taxAmount) + " kr/år")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.fuelName
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _vm.product.usesChargingStation
                                ? _c("i", {
                                    staticClass: "fal fa-charging-station"
                                  })
                                : _c("i", { staticClass: "fal fa-gas-pump" }),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(_vm.product.fuelName))])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.totalWeight &&
                        _vm.product.vehicleType == "Husvagn"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-weight-hanging" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.totalWeight) + " kg")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.batteryCapacity
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-battery-bolt" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.product.batteryCapacity) + " kWh"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.electricRange > 0
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-bolt" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.product.electricRange) + " km"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.loadCapacity &&
                        (_vm.product.vehicleType == "Transportbil" ||
                          _vm.product.vehicleType == "Husvagn")
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-truck-loading" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.loadCapacity) + " kg")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.loadSpaceWidth &&
                        _vm.product.vehicleType == "Transportbil"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("div", { staticClass: "bordered" }, [
                                _c("i", { staticClass: "fal fa-arrows-h" })
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.product.loadSpaceWidth) + " mm"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.bodyNameNoPrefix &&
                        _vm.product.vehicleType === "Husbil"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-rv" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.bodyNameNoPrefix))
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.bodyNameNoPrefix &&
                        _vm.product.vehicleType === "Husvagn"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-caravan" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.bodyNameNoPrefix))
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.floorPlan
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-bed-alt" }),
                              _vm._v(" "),
                              _c("div", [_vm._v(_vm._s(_vm.product.floorPlan))])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.trailerWeight &&
                        _vm.product.fuelName != "El"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-trailer" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.product.trailerWeight) + " kg"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.length &&
                        _vm.product.vehicleType == "Husvagn"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-arrows-h" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.length) + " mm")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.gearBoxName &&
                        _vm.product.vehicleType !== "Husvagn"
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-cogs" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.gearBoxName))
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.seats && Number(_vm.product.seats) > 0
                          ? _c("div", { staticClass: "product-facts__item" }, [
                              _c("i", { staticClass: "fal fa-users" }),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(_vm._s(_vm.product.seats) + " säten")
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "quickbuy__main-aside" },
                  [
                    _c("div", { staticClass: "quickbuy__pricing" }, [
                      _vm.product.available
                        ? _c("div", { staticClass: "product-spot__price" }, [
                            _c(
                              "div",
                              { staticClass: "product-spot__price-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "product-spot__price-item__name"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Pris\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "product-spot__price-item__meta"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.priceMode != "noTax"
                                            ? "Inkl. moms"
                                            : "Exkl. moms"
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "price-tag",
                                    class: {
                                      "price-tag--discount":
                                        _vm.product.discount
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$formatPrice(
                                            _vm.priceMode != "noTax"
                                              ? _vm.product.currentPrice
                                              : _vm.product.currentPriceNoVat
                                          )
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.displayOnlyCash
                              ? _c(
                                  "div",
                                  { staticClass: "product-spot__price-item" },
                                  [
                                    _vm.product.flexCode == "mgflex"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "product-spot__price-item__name"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    MG100\n                  "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "product-spot__price-item__name"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Finansiering\n                  "
                                            )
                                          ]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Inkl. moms\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price-tag",
                                        class: {
                                          "price-tag--discount":
                                            _vm.product.discount
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$formatPriceNoSuffix(
                                                _vm.product.monthlyCost
                                              )
                                            ) +
                                            " kr/mån\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.displayOnlyCash &&
                            _vm.product.privateLeasingPrice > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "product-spot__price-item",
                                    class: {
                                      "offset-4": _vm.product.taxCode === 1
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__name"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Privatleasing\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Inkl. moms\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "price-tag" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$formatPriceNoSuffix(
                                              _vm.product.privateLeasingPrice
                                            )
                                          ) +
                                          " kr/mån\n                  "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.displayOnlyCash &&
                            this.product.tcoMonthlyCost > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "product-spot__price-item",
                                    class: {
                                      "offset-3": !_vm.product
                                        .privateLeasingPrice
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__name"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Operationell leasing\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    TCO Exkl. moms\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "price-tag" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$formatPriceNoSuffix(
                                              _vm.product.tcoMonthlyCost
                                            )
                                          ) +
                                          " kr/mån\n                  "
                                      )
                                    ])
                                  ]
                                )
                              : !_vm.displayOnlyCash &&
                                this.product.blMonthlyCost > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "product-spot__price-item",
                                    class: {
                                      "offset-3": !_vm.product
                                        .privateLeasingPrice
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__name"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Business lease\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Exkl. moms\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "price-tag" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$formatPriceNoSuffix(
                                              _vm.product.blMonthlyCost
                                            )
                                          ) +
                                          " kr/mån\n                  "
                                      )
                                    ])
                                  ]
                                )
                              : !_vm.displayOnlyCash &&
                                this.product.taxCode === 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "product-spot__price-item",
                                    class: {
                                      "offset-3": !_vm.product
                                        .privateLeasingPrice
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__name"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Företagsleasing\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "product-spot__price-item__meta"
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Exkl. moms\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "price-tag",
                                        class: {
                                          "price-tag--discount":
                                            _vm.product.discount
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$formatPriceNoSuffix(
                                                _vm.product.leasing
                                              )
                                            ) +
                                            " kr/mån\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("GridItemQuickbuyActions", {
                      attrs: { product: _vm.product }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "quickbuy__bookmarks" }, [
                      _c(
                        "div",
                        {
                          staticClass: "bookmark-bottom",
                          class: {
                            ttjs: !_vm.isLoggedIn || _vm.productSaved,
                            "save-vehicle__user-not-logged-in": !_vm.isLoggedIn
                          },
                          attrs: {
                            "data-ttjs-title": !_vm.isLoggedIn
                              ? "Logga in för att kunna spara fordon"
                              : "Tillagd i dina sparade fordon. Under Mina sidor kan du se och ta bort sparade fordon"
                          },
                          on: { click: _vm.onSaveVehicle }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "bookmark-bottom__icon",
                              class: {
                                "bookmark-bottom__icon--selected":
                                  _vm.productSaved
                              }
                            },
                            [
                              _c("FontAwesomeIcon", {
                                attrs: {
                                  icon: _vm.productSaved
                                    ? _vm.fasHeart
                                    : _vm.falHeart
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "bookmark-bottom__link",
                              class: {
                                "bookmark-bottom__link--selected":
                                  _vm.productSaved
                              }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.productSaved ? "Sparad" : "Spara"
                                  ) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "bookmark-bottom" }, [
                        _c(
                          "div",
                          { staticClass: "bookmark-bottom__icon" },
                          [
                            _c("FontAwesomeIcon", {
                              attrs: { icon: _vm.falShareSquare }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "link bookmark-bottom__link js-share-this-url",
                            attrs: {
                              "data-currenturl": _vm.product.permalink,
                              "data-toggle": "modal",
                              "data-target": "[data-share-page-modal]"
                            }
                          },
                          [_vm._v("Dela")]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "bookmark-bottom",
                          on: { click: _vm.compareVehicle }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "bookmark-bottom__icon" },
                            [
                              _c("FontAwesomeIcon", {
                                attrs: {
                                  icon: _vm.isVehicleCompared
                                    ? _vm.fasBalance
                                    : _vm.falBalance
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "bookmark-bottom__link",
                              class: {
                                "bookmark-bottom__link--selected":
                                  _vm.isVehicleCompared
                              }
                            },
                            [
                              _vm._v(
                                "\n                  Jämför\n                "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.imageGalleryOpen
          ? _c("ImageGallery", {
              attrs: {
                images: _vm.product.images,
                imageIndex: _vm.imageIndex,
                product: _vm.product
              },
              on: { close: _vm.onCloseImageGallery }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }