import pageHeader from '../components/page-header';
import pageHeaderI3Filter from '../components/page-header-i3-filter';
import pageHeaderI3Sort from '../components/page-header-i3-sort';
import vehicleContactModal from "../apps/vehicle-contact-modal";
import mountApp from "../utils/mount-app";

export default {
  init() {
    pageHeader.init();
    pageHeaderI3Filter.init();
    pageHeaderI3Sort.init();
    this.modifyDOM();
  },

  modifyDOM() {
    this.mountVehicleContactModal();
  },

  mountVehicleContactModal() {
    mountApp({
      app: vehicleContactModal,
      selector: "[data-app=vehicle-contact-modal]",
    });
  },
};
