var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spare-parts-modal" }, [
    _c("div", { staticClass: "spare-parts-form" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-stack reg-nr" }, [
          _c("div", { staticClass: "reg-nr__field" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-stack reg-nr__field__container",
                class: { "form-stack--lg": true }
              },
              [
                _c("label", [
                  _vm._v("\n              Regnummer\n            ")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.regNr,
                      expression: "regNr"
                    }
                  ],
                  staticClass: "form-control reg-nr__field__input",
                  class: { "form-control--invalid": _vm.regNrInvalid },
                  attrs: {
                    id: "regNr-input",
                    type: "text",
                    placeholder: "ABC123",
                    required: "",
                    pattern: "^\\w{3}\\d{2}(\\w{1}|\\d{1})$",
                    autocomplete: "off"
                  },
                  domProps: { value: _vm.regNr },
                  on: {
                    keyup: function($event) {
                      return _vm.onChangeRegNr()
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.regNr = $event.target.value
                    }
                  }
                })
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _vm.validCarInfo
          ? _c("div", { staticClass: "car-info" }, [
              _c("table", { staticClass: "car-info__table" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticClass: "car-info__table__text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.carInfo.brand) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "car-info__table__text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.carInfo.model + " " + _vm.carInfo.version) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "car-info__table__text" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.carInfo.modelYear) +
                        "\n            "
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("label", [
        _vm._v("\n      Beskriv reservdelen Som är skadad eller defekt\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-row__col" }, [
          _c("div", { staticClass: "form-stack form-stack--required" }, [
            _c("label", [
              _vm._v("\n            Beskrivning av reservdelen\n          ")
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message,
                  expression: "message"
                }
              ],
              staticClass: "form-control",
              class: { "form-control--invalid": _vm.messageInvalid },
              staticStyle: {
                margin: "0px 6px 0px 0px",
                height: "118px",
                width: "100%"
              },
              attrs: { placeholder: "Beskriv här...", required: "required" },
              domProps: { value: _vm.message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.message = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "field-input-images" }, [
          _c("label", [
            _vm._v("\n          Ladda upp bilder på reservdelen\n        ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field-input-images__grid" },
            _vm._l(_vm.imageComponentArray, function(imageComponent) {
              return _c("ImageUpload", {
                key: imageComponent,
                attrs: { i: imageComponent, regNr: _vm.regNr },
                on: {
                  onChangeImage: _vm.changeImage,
                  onDeleteImage: _vm.deleteImage,
                  onError: _vm.showError
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.error
            ? _c(
                "div",
                { staticClass: "feedback-message feedback-message--danger" },
                [
                  _c("div", { staticClass: "feedback-message__text" }, [
                    _vm._v(
                      "\n            Du måste ange ett registrerings nummer för att kunna ladda upp\n            bilder\n          "
                    )
                  ])
                ]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "form-stack form-stack--required" }, [
        _c("label", [_vm._v("\n        NAMN\n      ")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.customerName,
              expression: "customerName"
            }
          ],
          staticClass: "form-control",
          class: { "form-control--invalid": _vm.customerNameInvalid },
          attrs: {
            autocomplete: "name",
            placeholder: "Vad heter du?",
            type: "text",
            required: ""
          },
          domProps: { value: _vm.customerName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.customerName = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-row" }, [
        _c("div", { staticClass: "form-row__col form-row__col--md-50" }, [
          _c("div", { staticClass: "form-stack form-stack--required" }, [
            _c("label", [_vm._v("\n            E-POST\n          ")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.customerEmail,
                  expression: "customerEmail"
                }
              ],
              staticClass: "form-control",
              class: { "form-control--invalid": _vm.customerEmailInvalid },
              attrs: {
                autocomplete: "email",
                placeholder: "Ange din e-postadress",
                type: "email",
                required: ""
              },
              domProps: { value: _vm.customerEmail },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.customerEmail = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row__col form-row__col--md-50" }, [
          _c("div", { staticClass: "form-stack form-stack--required" }, [
            _c("label", [_vm._v("\n            MOBILNUMMER\n          ")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.customerPhone,
                  expression: "customerPhone"
                }
              ],
              staticClass: "form-control",
              class: { "form-control--invalid": _vm.customerPhoneInvalid },
              attrs: {
                autocomplete: "tel",
                placeholder: "Ange ditt telefonnummer",
                type: "tel",
                required: ""
              },
              domProps: { value: _vm.customerPhone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.customerPhone = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn--primary btn--block",
          attrs: { type: "submit" },
          on: { click: _vm.onSubmit }
        },
        [_vm._v("\n      Skicka förfrågan\n    ")]
      ),
      _vm._v(" "),
      _c("p", {
        staticClass: "form-text",
        domProps: { innerHTML: _vm._s(_vm.lang.General.PrivacyPolicyText) }
      })
    ]),
    _vm._v(" "),
    _vm._m(4)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-step__header" }, [
      _c("div", { staticClass: "form-step__title" }, [
        _vm._v("\n        Information om reservdelen\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-step__sub-title" }, [
        _vm._v(
          "\n        Genom att att veta vilket fordon du har kan vi enklare hitta en\n        reservdel\n      "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", {
        staticClass:
          "regnr-input__symbol reg-nr__field__symbol reg-nr__field__symbol--rounded"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "car-info__table__header" }, [_vm._v("MÄRKE")]),
      _vm._v(" "),
      _c("td", { staticClass: "car-info__table__header" }, [
        _vm._v("MODELL OCH VERSION")
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "car-info__table__header" }, [
        _vm._v("ÅRSMODELL")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-step__header" }, [
      _c("div", { staticClass: "form-step__title" }, [
        _vm._v("\n        Dina uppgifter\n      ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-step__sub-title" }, [
        _vm._v("\n        Skriv in dina uppgifter här\n      ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-feedback u-d-none" }, [
      _c("div", { staticClass: "loader loader--success" }, [
        _c("div", { staticClass: "loader__shape" }, [
          _c("div", { staticClass: "loader__symbol" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "loader__header" }, [
          _vm._v("\n        Tack för din förfrågan\n      ")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "loader__text" }, [
          _vm._v(
            "\n        Vi kommer kontakta dig om din reservdel inom kort.\n      "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }