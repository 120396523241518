<template>
  <div class="cart-promo">
    <a class="link link--underline link--muted-light cart-promo__cta"
      @click="active = !active">
      Har du en rabattkod?
    </a>
    <div v-for="promo in promotionCodes"
      :key="promo.code"
      class="cart-promo__code">
      <span>
        Rabattkod {{promo.code}} aktiverad
      </span>
      <a class="link link--underline link--muted-light" @click="onRemove">
        Ta bort
      </a>
    </div>
    <form v-if="active"
      class="cart-promo__form"
      @submit.prevent="onApply">
      <div class="input-group">
        <input type="text" class="form-control form-control--sm" placeholder="Rabattkod" required>
        <div class="input-group-append">
          <button class="btn btn--primary btn--sm" type="submit">
            Aktivera
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    promotionCodes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      code: null,
      active: false,
    };
  },
  methods: {
    ...mapActions({
      applyPromotion: 'cart/applyPromotion',
      removePromotion: 'cart/removePromotion',
    }),

    onApply() {
      this.applyPromotion(this.code);
    },

    onRemove() {
      this.removePromotion();
    },
  },
}
</script>
