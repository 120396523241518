<template>
  <div>
    <label v-if="field.label">
      {{field.label}}
    </label>
    <div class="form-row">
      <div v-for="option in field.options"
        :key="option.value"
        class="form-row__col"
        :class="!field.fullRow && 'form-row__col--md-50'">
        <NestedOptionBox
          :selected="optionIsSelected(option)"
          :heading="option.heading"
          :smallText="option.headingMeta"
          :expandText="option.subOptionsText"
          :modalContent="option.modalContent"
          :price="option.price"
          :subOptions="option.subOptions"
          :image="option.imageUrl"
          :checkbox="multiselect"
          :largeImage="option.largeImage"
          :externalUrl="option.externalUrl"
          @click="onClick(option)"
          @subOptionChange="onSubOptionChange(option, $event)">
        </NestedOptionBox>
      </div>
    </div>
  </div>
</template>

<script>
import NestedOptionBox from '../option-box/NestedOptionBox.vue';

export default {
  components: {
    NestedOptionBox,
  },
  props: {
    field: Object,
    invalid: Boolean,
    multiselect: Boolean,
  },
  methods: {
    optionIsSelected(option) {
      return !!this.field.value.find(v => v.name === option.name);
    },

    onClick(option) {
      let value = [];

      if (this.optionIsSelected(option)) {
        value = this.field.value.filter(v => v.name !== option.name);
      } else {
        if (this.multiselect) {
          value = [...this.field.value];
        }

        value.push({
          name: option.name,
          value: [],
          id: option.id,
        });
      }

      this.$emit('change', value);
    },

    onSubOptionChange(option, { subOption, value }) {
      const fieldOption = this.field.value.find(v => v.name === option.name);
      const subOptionIndex = fieldOption.value.findIndex(v => v.name === subOption.name);
      const subOptionValue = fieldOption.value[subOptionIndex];
      let newSubOptionValue;
      let newFieldValue;

      if (subOptionValue) {
        newSubOptionValue = fieldOption.value.map((ov, index) => {
          if (index === subOptionIndex) {
            return {
              ...ov,
              value,
            };
          }

          return ov;
        });
      } else {
        newSubOptionValue = [
          ...fieldOption.value,
          {
            name: subOption.name,
            value,
          }
        ];
      }

      newFieldValue = this.field.value.map(fv => {
        if (fv.name === option.name) {
          return {
            ...fv,
            value: newSubOptionValue,
          };
        }

        return fv;
      });

      this.$emit('change', newFieldValue);
    },
  },
}
</script>
