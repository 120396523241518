var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "summation-list",
      class: { "summation-list--sub-list": _vm.isSubList }
    },
    [
      _vm._l(_vm.rows, function(row) {
        return _c(
          "div",
          { key: row.name, staticClass: "summation-list__row" },
          [
            row.nameLink
              ? _c(
                  "a",
                  {
                    staticClass: "text link link--primary",
                    attrs: {
                      "data-target": row.nameLink,
                      "data-modal-source": "ajax"
                    }
                  },
                  [_vm._v(_vm._s(row.name))]
                )
              : _c("div", { staticClass: "text" }, [
                  _vm._v("\n      " + _vm._s(row.name) + "\n    ")
                ]),
            _vm._v(" "),
            row.value && row.discount
              ? _c("div", { staticClass: "text text--value" }, [
                  row.ordinaryValue
                    ? _c("span", { staticClass: "text--obsolete" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              "" + row.ordinaryValue + (row.suffix || "")
                            ) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _vm._v("\n       \n      "),
                  _c("span", { staticClass: "text--discount" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s("" + row.value + (row.suffix || "")) +
                        "\n      "
                    )
                  ])
                ])
              : row.value
              ? _c("div", { staticClass: "text text--value" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s("" + row.value + (row.suffix || "")) +
                      "\n    "
                  )
                ])
              : _vm._e()
          ]
        )
      }),
      _vm._v(" "),
      _vm.collapsible
        ? _c(
            "div",
            {
              staticClass: "link link--primary link--icon",
              on: { click: _vm.onToggleCollapse }
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCollapsed,
                      expression: "isCollapsed"
                    }
                  ]
                },
                [_vm._v("Visa alla detaljer")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCollapsed,
                      expression: "!isCollapsed"
                    }
                  ]
                },
                [_vm._v("Dölj alla detaljer")]
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCollapsed,
                      expression: "isCollapsed"
                    }
                  ]
                },
                [_c("i", { staticClass: "fal fa-angle-down" })]
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCollapsed,
                      expression: "!isCollapsed"
                    }
                  ]
                },
                [_c("i", { staticClass: "fal fa-angle-up" })]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }