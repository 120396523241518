import Cookies from 'js-cookie';
import gtm from '../utils/gtm';
import LineItemCustomDataKeys from '../constants/LineItemCustomDataKeys';
import LineItemDeliveryStatusLabels from '../constants/LineItemDeliveryStatusLabels';
import LineItemDeliveryStatusCircleClasses from '../constants/LineItemDeliveryStatusCircleClasses';

const COOKIE_NAME_PREFIX = 'purchase_tracked_';
export default {
  init() {
    this.track();
    this.modifyDOM();
  },

  modifyDOM() {
    const deliveryStatus = parseInt($('.delivery-status-circle').data('delivery-status'), 10);
    const label = LineItemDeliveryStatusLabels[deliveryStatus];
    const circleClass = LineItemDeliveryStatusCircleClasses[deliveryStatus];
    const template = `<div><i class="fa fa-circle attention-circle ${circleClass}"></i> ${label}</div>`;
    $('.delivery-status-circle').append($(template));
  },

  track() {
    if(typeof window.orderData === 'undefined') {
      return;
    }
    if(Cookies.get(COOKIE_NAME_PREFIX + window.orderData.orderId)) {
      return;
    }
    Cookies.set(COOKIE_NAME_PREFIX + window.orderData.orderId, true, { expires: 365 });
    gtm.trackEcommercePurchase('purchase', this.buildOrderData());
  },

  buildOrderData() {
    return {
      currencyCode: 'SEK',
      purchase: {
        actionField: {
          id: window.orderData.orderId,
          revenue: window.orderData.totalPriceIncVat,
          tax: window.orderData.totalVat,
          affiliation: window.orderData.destinationSiteId,
        },
        products: window.orderData.combinedLineItems.map(i => {
          const brandCustomData = i.customData && i.customData.length ? i.customData.find(l => l.name === LineItemCustomDataKeys.Brand) : '';
          const variantCustomData = i.customData && i.customData.length ? i.customData.find(l => l.name === LineItemCustomDataKeys.Variant) : '';
          return {
            item_name: i.name,
            item_id: i.productId,
            price: Math.round(i.currentPrice * (1 + i.vatPercentage / 100)),
            quantity: i.quantity,
            item_brand: brandCustomData && brandCustomData.value,
            item_category2: i.productCondition,
            item_category: i.productType,
            item_variant: variantCustomData && variantCustomData.value,
            location_id: i.supplier,
          };
        }),
      }
    }
  }
}
