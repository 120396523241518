import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../../constants/FieldTypes';
import ServicesApi from '../../../../models/ServicesApi';

const state = {
  reportTypes: [],
  icon: '',
  htmlContent: '',
  fetchState: null,
};

const getters = {
  hasExpress: state => {
    return state.reportTypes.find(r => r.value === 'express');
  }
};

const actions = {
  async fetchAvailableReportTypes({ commit, dispatch, state }, { departmentNr, service }) {
    commit('fetchRequest');

    try {
      const data = {
        departmentNr,
        service,
      };
      const res = await ServicesApi.fetchDepartmentExpress(data);
      let reportTypes = [
        {
          heading: 'Boka tid för skadebesiktning',
          name: 'manual',
          value: 'manual'
        }
      ];
      if(res && res.data && res.data.hasExpress) {
        reportTypes = [
          {
            heading: 'Expressbesiktning',
            name: 'express',
            value: 'express',
            imageUrl: res.data.icon,
          },
          ...reportTypes
        ]
      }
      commit('fetchSuccess', { reportTypes, ...res.data});

      dispatch('fields/set', {
        type: FieldTypes.TypeOfReport,
        options: state.reportTypes,
        value: [],
      }, { root: true });
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, { content, reportTypes, icon }) {
    state.fetchState = RequestStates.Success;
    state.reportTypes = reportTypes;
    state.htmlContent = content;
    state.icon = icon;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
