var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "star-rating" },
    _vm._l(_vm.range, function(r) {
      return _c("FontAwesomeIcon", {
        key: r,
        staticClass: "star-rating__item",
        attrs: {
          icon: r <= _vm.field.value ? _vm.faStarSolid : _vm.faStarLight
        },
        on: {
          click: function($event) {
            return _vm.onClick(r)
          }
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }