import gtm from "../utils/gtm";

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').
      on('click', '.call-action', this.onClick.bind(this));
  },

  onClick({ currentTarget }) {
    const $el = $(currentTarget);

    if ($el.hasClass('call-action--toggled')) {
      return true;
    }

    this.displayNumber($el); 

    return false;
  },

  displayNumber($el) {
    $el.addClass('call-action--toggled');

    let departmentNr = $el.data('department-nr');
    let phoneNr = $el.find('.call-action__number').last().text().trim();
    let vehicleCondition = $el.data('vehicle-condition');
    let brandName = $el.data('brandname')
    let formType = $el.data('form-type')

    gtm.phoneNumberClick({
      departmentNr,
      phoneNr,
      vehicleCondition: vehicleCondition,
      brandName: brandName,
      formType,
    });
  }
};
