import FieldTypes from "../constants/FieldTypes";
import SectionTypes from "../constants/SectionTypes";
import InputTypes from "../../shared/wizard/constants/InputTypes";
import fields from "../../shared/wizard/data/fields";
import RegExPatterns from "../../../constants/RegExPatterns";
import Conditions from "../constants/Conditions";

export default [
  ...fields,
  {
    type: FieldTypes.SourceRegNr
  },
  {
    type: FieldTypes.VehicleId
  },
  {
    type: FieldTypes.PageName
  },
  // {
  //   section: SectionTypes.Valuation,
  //   label: "Mätarställning (mil)",
  //   shortLabel: "Mätarställning",
  //   placeholder: "Mätarställning",
  //   type: FieldTypes.Mileage,
  //   inputType: InputTypes.Number,
  //   required: true
  // },
  // {
  //   section: SectionTypes.Valuation,
  //   label: "E-post",
  //   placeholder: "Ange din e-postadress",
  //   type: FieldTypes.Email,
  //   inputType: InputTypes.Email,
  //   inputPattern: RegExPatterns.Email,
  //   autocomplete: "email",
  //   required: true
  // },
  // {
  //   section: SectionTypes.Valuation,
  //   label: "I vilket skick är bilen?",
  //   type: FieldTypes.VehicleCondition,
  //   inputType: InputTypes.OptionBoxes,
  //   required: true,
  //   fullRow: true,
  //   value: [
  //     {
  //       value: Conditions.VeryGood,
  //       name: Conditions.VeryGood,
  //       heading: "Mycket bra skick"
  //     }
  //   ],
  //   options: [
  //     {
  //       value: Conditions.VeryGood,
  //       name: Conditions.VeryGood,
  //       heading: "Mycket bra skick",
  //       modalContent:
  //         "<h2>Mycket bra skick</h2><p>Bilen &auml;r som ny och har inga skador eller tecken p&aring; slitage. Den &auml;r i ett skick som &auml;r b&auml;ttre &auml;n normalt och har tagits om hand lite extra. Allt p&aring; bilen funkar och det finns inga k&auml;nda fel. Service &auml;r gjord enligt servicebok och finns dokumenterat, samt att alla tillbeh&ouml;r som medf&ouml;ljde bilen finns.</p>"
  //     },
  //     {
  //       value: Conditions.Good,
  //       name: Conditions.Good,
  //       heading: "Bra skick",
  //       modalContent:
  //         "<h2>Bra skick</h2><p>Bilen har inga skador och &auml;r i ett skick som &auml;r normalt f&ouml;r vanlig anv&auml;ndning av bilen. Viss slitage kan f&ouml;rekomma efter vanligt anv&auml;ndande men inget extra. Det finns inga skador i lacken eller synliga repor. I &ouml;vrigt &auml;r bilen i bra skick har har inga funktionsfel eller andra k&auml;nda fel. All service i gjord enligt servicebok och finns dokumenterat.</p>"
  //     },
  //     {
  //       value: Conditions.Ok,
  //       name: Conditions.Ok,
  //       heading: "Ok skick",
  //       modalContent:
  //         "<h2>Helt ok skick</h2><p>Bilen ser bra ut men kan ha mindre repor eller skador i lacken. Det finns sp&aring;r av att bilen &auml;r begagnad med viss slitage i kl&auml;dsel och stolar. I d&ouml;rrtr&ouml;sklar och vid bakluckan finns det lite skrapm&auml;rken. I &ouml;vrigt &auml;r bilen ok och har inga funktionsfel eller k&auml;nda fel. All service &auml;r gjord enligt servicebok.</p>"
  //     }
  //   ]
  // },
  {
    section: SectionTypes.Lookup,
    label: "Mätarställning (mil)",
    shortLabel: "Mätarställning",
    placeholder: "Mätarställning",
    type: FieldTypes.Mileage,
    inputType: InputTypes.Number,
    required: true
  },
  {
    section: SectionTypes.Details,
    label: "Utrustningsnivå/paket",
    shortLabel: "Utrustningsnivå",
    placeholder: "Välj utrustning",
    type: FieldTypes.EquipmentLevel,
    inputType: InputTypes.Select,
    options: [],
    required: true
  },
  {
    section: SectionTypes.Details,
    label: "Beskriv utrustningsnivå/paket",
    shortLabel: "Beskriv utrustningsnivå",
    placeholder: "Placeholder",
    type: FieldTypes.EquipmentText,
    inputType: InputTypes.Textarea
  },
  {
    section: SectionTypes.Details,
    label: "Vinterhjul?",
    shortLabel: "Vinterhjul",
    type: FieldTypes.WinterTires,
    inputType: InputTypes.Boolean
  },
  {
    section: SectionTypes.Details,
    label: "Beskriv skick, typ, storlek och märke",
    shortLabel: "Beskriv skick",
    placeholder: "Ex. 17 tums lättmetall BMW Original, Pirelli P-Zero, ca 6mm",
    type: FieldTypes.WinterTiresText,
    inputType: InputTypes.Textarea
  },
  {
    section: SectionTypes.Details,
    label: "Har fordonet skador/slitage?",
    shortLabel: "Skador",
    type: FieldTypes.Damage,
    inputType: InputTypes.Boolean
  },
  {
    section: SectionTypes.Details,
    label: "Beskriv skador, report, slitage",
    shortLabel: "Beskriv skador",
    placeholder: "Ex. Parkeringsskada höger fram, repa vänster bakdörr etc",
    type: FieldTypes.DamageText,
    inputType: InputTypes.Textarea
  },
  {
    section: SectionTypes.Details,
    label: "Ladda upp bild på skadan",
    shortLabel: "Skadebilder",
    placeholder: "Välj bild",
    type: FieldTypes.DamageImages,
    inputType: InputTypes.Images,
    value: []
  },
  {
    section: SectionTypes.Details,
    label: "Fullständig servicebok?",
    shortLabel: "Servicebok",
    type: FieldTypes.ServiceBook,
    inputType: InputTypes.Boolean
  },
  {
    section: SectionTypes.Details,
    label: "Senaste servad datum och miltal",
    shortLabel: "Senast servad",
    placeholder: "Ex. 20194019, 3600 mil",
    type: FieldTypes.ServiceBookText,
    inputType: InputTypes.Textarea
  },
  {
    section: SectionTypes.Details,
    label: "Vilken anläggning ligger närmast dig?",
    shortLabel: "Anläggning",
    placeholder: "Välj anläggning",
    type: FieldTypes.City,
    inputType: InputTypes.SelectObject,
    options: [],
    required: true
  },
  {
    section: SectionTypes.Details,
    label: "Är fordonet tillgängligt omgående?",
    shortLabel: "Tillgänglig omgående",
    type: FieldTypes.AvailableNow,
    inputType: InputTypes.Boolean
  },
  {
    section: SectionTypes.Details,
    label: "Ytterligare uppgifter, tillval och extrautrustning",
    shortLabel: "Ytterligare info",
    placeholder: "Tex dragkrok, panoramaglastak, övriga upplysningar",
    type: FieldTypes.ExtraInfo,
    inputType: InputTypes.Textarea
  },
  {
    section: SectionTypes.Images,
    label: "Bild fram",
    type: FieldTypes.ImageFront,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild bak",
    type: FieldTypes.ImageBack,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild vänster",
    type: FieldTypes.ImageLeft,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild höger",
    type: FieldTypes.ImageRight,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild interiör",
    type: FieldTypes.ImageInside,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild servicebok",
    type: FieldTypes.ImageServicebook,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild nycklar",
    type: FieldTypes.ImageKeys,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild extra",
    type: FieldTypes.ImageExtra1,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild extra",
    type: FieldTypes.ImageExtra2,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Bild extra",
    type: FieldTypes.ImageExtra3,
    inputType: InputTypes.Image
  },
  // { 
  //   section: SectionTypes.Contact, 
  //   label: "Vilken anläggning ligger närmast dig?", 
  //   shortLabel: "Anläggning", 
  //   placeholder: "Välj anläggning", 
  //   type: FieldTypes.City, 
  //   inputType: InputTypes.SelectObject, 
  //   options: [], 
  //   required: true 
  // }, 
];
