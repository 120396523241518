import mountApp from "../utils/mount-app";
import ReviewsBlock from "../apps/reviews-block";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: ReviewsBlock,
      selector: '[data-app=reviews-block]',
      extractProps: el => ({
        locationId: el.getAttribute('data-location-id'),
        heading: el.getAttribute('data-heading'),
        address: el.getAttribute('data-address'),
        reviewsUrl: el.getAttribute('data-reviews-url'),
      }),
    });
  },
}
