import Axios from "../axios";
import gtm from "../utils/gtm";

const classes = {
  tabActive: "tab-button--active"
};

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $("body")
      .on("click", ".contact-forms .tab-button", this.onTabClick.bind(this))
      .on("submit", ".contact-forms__form", this.onFormSubmit.bind(this));
  },

  modifyDOM() {},

  onTabClick({ currentTarget }) {
    const $el = $(currentTarget);
    $el.siblings().removeClass(classes.tabActive);
    $el.addClass(classes.tabActive);
  },

  onFormSubmit({ currentTarget }) {
    const $el = $(currentTarget);
    const $submitBtn = $el.find('[type="submit"]');
    const $feedback = $el.find(".form-feedback");

    const data = {
      currentPageUrl: window.location.toString()
    };

    $el.serializeArray().forEach(param => {
      data[param.name] = param.value;
    });

    $submitBtn.addClass("disabled");

    Axios.post("/api/leads/ContactForm", data)
      .then(() => {
        $submitBtn.removeClass("disabled");
        $feedback.addClass("active");
      })
      .catch(() => {
        $submitBtn.removeClass("disabled");
      });

    gtm.contactFormSubmit({
      departmentNr: data.departmentNr,
      requestType: data.requestType,
      brandName: data.brandName
    });

    return false;
  }
};
