var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "u-align-center" },
        [
          _c("FontAwesomeIcon", {
            staticClass: "wizard--service-booking__loader",
            attrs: { icon: _vm.faSpinnerThird, spin: true }
          })
        ],
        1
      )
    : _c("div", [
        _vm.hasExpress
          ? _c("div", { staticClass: "section" }, [
              _c(
                "div",
                {
                  staticClass:
                    "section__content section__content--yellow-bg section__content--padding-sm"
                },
                [
                  _c("img", { attrs: { src: _vm.icon } }),
                  _vm._v(" "),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.htmlContent) }
                  })
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c("SectionField", {
                attrs: { type: _vm.FieldTypes.TypeOfReport }
              })
            ],
            1
          )
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }