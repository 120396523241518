import FieldTypes from '../../shared/wizard/constants/FieldTypes';

export default {
  ...FieldTypes,
  SourceRegNr: 'sourceRegistrationNumber',
  VehicleId: 'vehicleId',
  PageName: 'pageName',
  EquipmentText: 'information',
  Mileage: 'mileage',
  Damage: 'damage',
  DamageText: 'damagetext',
  DamageImages: 'damageimages',
  City: 'city',
  AvailableNow: 'availablenow',
  ExtraInfo: 'extrainfo',
  ImageFront: 'imageFront',
  ImageBack: 'imageBack',
  ImageLeft: 'imageLeft',
  ImageRight: 'imageRight',
  ImageInside: 'imageInside',
  ImageServicebook: 'imageservicebook',
  ImageKeys: 'imageKeys',
  ImageExtra1: 'imageExtra1',
  ImageExtra2: 'imageExtra2',
  ImageExtra3: 'imageExtra3',
  GasTest: 'gasTest',
  DampDamage: 'dampDamage',
  DampDamageText: 'dampDamageText',
  GasTestText: 'gasTestText',
}
