import FieldTypes from '../constants/FieldTypes';
// import TradeInTypes from '../constants/TradeInTypes';
import SectionTypes from '../constants/SectionTypes';
import InputTypes from '../../shared/wizard/constants/InputTypes';
import fields from '../../shared/wizard/data/fields';

export default [
  ...fields,
  {
    type: FieldTypes.SourceRegNr,
  },
  {
    type: FieldTypes.VehicleId,
  },
  {
    type: FieldTypes.PageName,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Utrustningsnivå/paket',
    shortLabel: 'Utrustningsnivå',
    placeholder: 'Välj utrustning',
    type: FieldTypes.EquipmentLevel,
    inputType: InputTypes.Select,
    options: [],
    required: true,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Beskriv utrustningsnivå/paket',
    shortLabel: 'Beskriv utrustningsnivå',
    placeholder: 'Placeholder',
    type: FieldTypes.EquipmentText,
    inputType: InputTypes.Textarea,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Mätarställning (mil)',
    shortLabel: 'Mätarställning',
    placeholder: 'Mätarställning',
    type: FieldTypes.Mileage,
    inputType: InputTypes.Number,
    required: true,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Har fordonet fuktskador?',
    shortLabel: 'Fuktskador',
    type: FieldTypes.DampDamage,
    inputType: InputTypes.Boolean,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Är fordonet gasoltestat och OK?',
    shortLabel: 'Gasoltest',
    type: FieldTypes.GasTest,
    inputType: InputTypes.Boolean,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Beskriv var och hur omfattande fuktskadorna är',
    shortLabel: 'Beskriv fuktskadorna',
    placeholder: 'Ex. i taket',
    type: FieldTypes.DampDamageText,
    inputType: InputTypes.Textarea,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Beskriv hur gasoltestet utfördes',
    shortLabel: 'Beskriv gasoltestet',
    placeholder: 'Ex. testet utfördes på verkstad x',
    type: FieldTypes.GasTestText,
    inputType: InputTypes.Textarea,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Har fordonet skador/slitage?',
    shortLabel: 'Skador',
    type: FieldTypes.Damage,
    inputType: InputTypes.Boolean,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Beskriv skador, report, slitage',
    shortLabel: 'Beskriv skador',
    placeholder: 'Ex. Parkeringsskada höger fram, repa vänster bakdörr etc',
    type: FieldTypes.DamageText,
    inputType: InputTypes.Textarea,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Ladda upp bild på skadan',
    shortLabel: 'Skadebilder',
    placeholder: 'Välj bild',
    type: FieldTypes.DamageImages,
    inputType: InputTypes.Images,
    value: [],
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Vilken anläggning ligger närmast dig?',
    shortLabel: 'Anläggning',
    placeholder: 'Välj anläggning',
    type: FieldTypes.City,
    inputType: InputTypes.SelectObject,
    options: [],
    required: true,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Är fordonet tillgängligt omgående?',
    shortLabel: 'Tillgänglig omgående',
    type: FieldTypes.AvailableNow,
    inputType: InputTypes.Boolean,
  },
  {
    section: SectionTypes.CampingDetails,
    label: 'Ytterligare uppgifter, tillval och extrautrustning',
    shortLabel: 'Ytterligare info',
    placeholder: 'Tex dragkrok, panoramaglastak, övriga upplysningar',
    type: FieldTypes.ExtraInfo,
    inputType: InputTypes.Textarea,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild fram',
    type: FieldTypes.ImageFront,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild bak',
    type: FieldTypes.ImageBack,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild vänster',
    type: FieldTypes.ImageLeft,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild höger',
    type: FieldTypes.ImageRight,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild interiör',
    type: FieldTypes.ImageInside,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild servicebok',
    type: FieldTypes.ImageServicebook,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild nycklar',
    type: FieldTypes.ImageKeys,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild extra',
    type: FieldTypes.ImageExtra1,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild extra',
    type: FieldTypes.ImageExtra2,
    inputType: InputTypes.Image,
  },
  {
    section: SectionTypes.Images,
    label: 'Bild extra',
    type: FieldTypes.ImageExtra3,
    inputType: InputTypes.Image,
  },
]
