var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FieldInputSelect",
    _vm._g(
      _vm._b(
        {
          attrs: { valueProp: "value", labelProp: "name", valueType: "number" }
        },
        "FieldInputSelect",
        _vm.$props,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }