var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isExpress
      ? _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c("SectionField", { attrs: { type: _vm.FieldTypes.DamageText } })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isExpress
      ? _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c("SectionField", {
                attrs: { type: _vm.FieldTypes.DamagePlacement }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-row__col" },
        [_c("SectionField", { attrs: { type: _vm.FieldTypes.DamageNumber } })],
        1
      )
    ]),
    _vm._v(" "),
    _vm.isExpress
      ? _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [_c("SectionField", { attrs: { type: _vm.FieldTypes.Mileage } })],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isExpress
      ? _c("small", { staticClass: "u-c-muted" }, [
          _vm._v(_vm._s(_vm.lang.App.ImagesHeadline))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isExpress
      ? _c("div", { staticClass: "field-input-images" }, [
          _c(
            "div",
            { staticClass: "field-input-images__grid" },
            _vm._l(_vm.imageFields, function(field) {
              return _c(
                "div",
                {
                  key: field.type,
                  staticClass: "field-input-images__grid-item"
                },
                [_c("SectionField", { attrs: { type: field.type } })],
                1
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }