<template>
  <div class="i3-filter__facet"
    :class="{'i3-filter__facet--selected': selected}">
    <div class="i3-filter__facet-name" @click.stop="$emit('select')">
      {{facet.displayName}}
    </div>
    <div class="i3-filter__facet-icon">
      <i v-if="iconRight" class="fal fa-angle-right"></i>
      <i v-else class="fal fa-angle-down"></i>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    facet: Object,
    selected: Boolean,
    iconRight: Boolean,
  },
}
</script>
