var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "filter-bar__tag filter-bar__tag--watch",
          class: { "filter-bar__tag--watch--active": _vm.filtersSaved },
          on: { click: _vm.onOpenWatchModal }
        },
        [
          _c("FontAwesomeIcon", {
            staticClass: "filter-bar__tag__icon",
            attrs: { icon: _vm.filtersSaved ? _vm.faBellOn : _vm.faBell }
          }),
          _vm._v(
            "\n    " + _vm._s(_vm.filtersSaved ? "Bevakad" : "Bevaka") + "\n  "
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "TheSearchWatchModal",
            {
              attrs: {
                "email-found": _vm.emailFound,
                email: _vm.email,
                privacyPolicyText: _vm.lang.General.PrivacyPolicyText,
                feedbackHeader: _vm.feedbackHeader,
                feedbackText: _vm.feedbackText,
                feedbackContactHeader: _vm.feedbackContactHeader,
                feedbackContactText: _vm.feedbackContactText,
                hasCustomContent: !_vm.email
              },
              on: {
                close: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c("SearchWatch", {
                attrs: {
                  slot: "customContent",
                  header: _vm.header,
                  text: _vm.text,
                  tags: _vm.displayTags,
                  feedbackHeader: _vm.feedbackHeader,
                  feedbackText: _vm.feedbackText,
                  feedbackContactHeader: _vm.feedbackContactHeader,
                  feedbackContactText: _vm.feedbackContactText,
                  privacyPolicyText: _vm.lang.General.PrivacyPolicyText,
                  done: _vm.done,
                  loading: _vm.loading,
                  error: _vm.error,
                  brandToWatch: _vm.brandToWatch,
                  departmentNr: _vm.departmentNr
                },
                on: { saveSearches: _vm.onSaveSearches },
                slot: "customContent"
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }