import SectionTypes from "../constants/SectionTypes";
import EonProductTypes from "../constants/EonProductTypes";
import EonQueryParams from "../../../constants/EonQueryParams";
import EonTyreTypes from "../constants/EonTyreTypes";
import InputTypes from "../constants/InputTypes";
import EonQualityTypes from "../constants/EonQualityTypes";
import EonGradeAG from "../constants/EonGradeAG";
import EonSpeedIndices from "../constants/EonSpeedIndices";
import EonSpeedIndexSpeeds from "../constants/EonSpeedIndexSpeeds";
import EonLoadIndices from "../constants/EonLoadIndices";
import EonLoadIndexLoads from "../constants/EonLoadIndexLoads";
import EonConditionTypes from "../constants/EonConditionTypes";
import EonApprovalMarks from "../constants/EonApprovalMarks";
import EonRimTypes from "../constants/EonRimTypes";
import FieldTypes from "../constants/FieldTypes";
import BuildWheelTypes from "../constants/BuildWheelTypes";

export default [
  {
    type: EonQueryParams.Width,
    label: "Bredd"
  },
  {
    type: EonQueryParams.AspectRatio,
    label: "Profil"
  },
  {
    section: SectionTypes.Diameter,
    type: EonQueryParams.Diameter,
    inputType: InputTypes.Radios,
    options: [],
    required: true
  },
  {
    section: SectionTypes.ProductType,
    type: EonQueryParams.ProductType,
    inputType: InputTypes.TabButtons,
    options: [],
    value: String(EonProductTypes.Tyre)
  },
  {
    section: SectionTypes.BuildWheel,
    type: FieldTypes.BuildWheel,
    inputType: InputTypes.TabButtons,
    options: [
      {
        name: "Bygg ditt hjul",
        value: String(BuildWheelTypes.Selected),
        tabButtonIcon: "fas fa-tire"
      }
    ],
    value: String(BuildWheelTypes.Unselected)
  },
  {
    section: SectionTypes.Season,
    type: EonQueryParams.TyreType,
    inputType: InputTypes.TabButtons,
    options: [
      {
        name: "Sommar",
        value: String(EonTyreTypes.Summer),
        tabButtonIcon: "fal fa-sun-bright"
      },
      {
        name: "Vinter friktion",
        value: String(EonTyreTypes.WinterFriction),
        tabButtonIcon: "fal fa-snowflake"
      },
      {
        name: "Vinter dubb",
        value: String(EonTyreTypes.WinterStudded),
        tabButtonIcon: "fal fa-snowflake"
      }
    ]
  },
  {
    section: SectionTypes.Condition,
    type: EonQueryParams.Condition,
    inputType: InputTypes.Checkboxes,
    options: [
      {
        name: "Nytt",
        value: EonConditionTypes.New
      },
      {
        name: "Begagnat",
        value: EonConditionTypes.Used
      }
    ],
    value: [EonConditionTypes.New, EonConditionTypes.Used]
  },
  {
    section: SectionTypes.Brand,
    type: EonQueryParams.BrandId,
    label: "",
    inputType: InputTypes.Checkboxes,
    options: [
      {
        name: "Bridgestone",
        value: "10"
      },
      {
        name: "Continental",
        value: "18"
      },
      {
        name: "Dunlop",
        value: "5"
      },
      {
        name: "Goodyear",
        value: "3045"
      },
      {
        name: "Hankook",
        value: "19"
      },
      {
        name: "Kumho",
        value: "34"
      },
      {
        name: "Nokian",
        value: "3859"
      },
      {
        name: "Pirelli",
        value: "46"
      },
      {
        name: "Sava",
        value: "223"
      }
    ]
  },
  {
    section: SectionTypes.Quality,
    type: EonQueryParams.Quality,
    label: "",
    inputType: InputTypes.Checkboxes,
    options: [
      {
        name: "Premium",
        value: EonQualityTypes.Premium
      },
      {
        name: "Mellanklass",
        value: EonQualityTypes.Medium
      },
      {
        name: "Budget",
        value: EonQualityTypes.Budget
      }
    ]
  },
  {
    section: SectionTypes.RimType,
    type: EonQueryParams.RimType,
    label: "",
    inputType: InputTypes.Radios,
    options: [
      { name: "Stål", value: String(EonRimTypes.Steel) },
      { name: "Aluminium", value: String(EonRimTypes.Alloy) }
    ]
  },
  {
    section: SectionTypes.RimOptions,
    type: EonQueryParams.WinterCertified,
    label: "Vinterfälg",
    inputType: InputTypes.CheckboxBool
  },
  {
    section: SectionTypes.RollingResistance,
    type: EonQueryParams.RollingResistance,
    label: "",
    inputType: InputTypes.Radios,
    options: Object.values(EonGradeAG).map(value => ({ name: value, value }))
  },
  {
    section: SectionTypes.WetGrip,
    type: EonQueryParams.WetGrip,
    label: "",
    inputType: InputTypes.Radios,
    options: Object.values(EonGradeAG).map(value => ({ name: value, value }))
  },
  {
    section: SectionTypes.Noise,
    type: EonQueryParams.Noise,
    label: "",
    inputType: InputTypes.Number,
    placeholder: "Decibel"
  },
  {
    section: SectionTypes.Speed,
    type: EonQueryParams.Speed,
    label: "",
    inputType: InputTypes.SelectObject,
    placeholder: "Alla",
    options: Object.entries(EonSpeedIndices).map(([key, value]) => ({
      name: `${key} (${EonSpeedIndexSpeeds[value]} km/h)`,
      value
    }))
  },
  {
    section: SectionTypes.Load,
    type: EonQueryParams.Load,
    label: "",
    inputType: InputTypes.SelectObject,
    placeholder: "Alla",
    options: EonLoadIndices.map(i => ({
      name: `${i} (${EonLoadIndexLoads[i]})`,
      value: i
    }))
  },
  {
    section: SectionTypes.ApprovalMark,
    type: EonQueryParams.ApprovalMark,
    label: "",
    inputType: InputTypes.SelectObject,
    placeholder: "Alla",
    options: EonApprovalMarks
  },
  {
    section: SectionTypes.TestScore,
    type: EonQueryParams.TestScore,
    label: "",
    inputType: InputTypes.Stars,
    placeholder: "Alla"
  },
  {
    section: SectionTypes.TyreOptions,
    type: EonQueryParams.Runflat,
    label: "Punkteringsfria",
    inputType: InputTypes.CheckboxBool
  },
  {
    section: SectionTypes.TyreOptions,
    type: EonQueryParams.Enforced,
    label: "XL",
    inputType: InputTypes.CheckboxBool
  }
];
