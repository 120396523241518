<template>
  <div
    class="choose-spec"
    :class="{ 'choose-spec--promo': promo }"
    :style="{ backgroundImage: promoBg ? 'url(' + promoBg + ')' : 'none' }"
  >
    <div class="choose-spec__content">
      <div class="choose-spec__meta" v-if="meta" v-html="meta"></div>
      <h1 class="choose-spec__header" v-if="header">
        {{ header }}
      </h1>
      <form @submit.prevent="onSubmit">
        <div class="regnr-input" :class="{ 'regnr-input--lg': largeRegnr }">
          <div class="input-group">
            <div class="input-group-prepend">
              <div class="regnr-input__symbol"></div>
            </div>
            <div class="form-stack" :class="{ 'form-stack--lg': largeRegnr }">
              <label>
                Regnummer
              </label>
              <input
                id="regNr-input"
                type="text"
                @keyup="onChange()"
                class="form-control"
                placeholder="ABC123"
                style="color: #18191a"
                required
                pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
                v-model="regNr"
              />
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn--primary regnr-input-sibling"
          :class="{
            'btn--lg': largeRegnr,
            'regnr-input-sibling--lg': largeRegnr
          }"
        >
          {{ buttonText }}
        </button>
      </form>
      <div class="choose-spec__options">
        <div class="choose-spec__options-header">
          Har du inget regnummer?
        </div>
        <div class="inline-bullets">
          <div
            class="inline-bullets__item"
            @click="$emit('chooseVehicleClick')"
          >
            <a class="link link--underline">Välj bilmodell</a>
          </div>
          <div
            class="inline-bullets__item"
            @click="$emit('chooseTyreDimensionsClick')"
          >
            <a class="link link--underline">Ange däckdimensioner</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcFeedbackMessage } from "@holmgrensbil/vue-components";
import Breakpoints from "../../constants/Breakpoints";

export default {
  components: {
    VcFeedbackMessage
  },
  props: {
    header: String,
    meta: String,
    buttonText: String,
    promo: Boolean,
    promoBg: String,
    carLookupError: Boolean,
    preselectedRegNr: String
  },
  data() {
    return {
      regNr: null,
      modalOpen: null
    };
  },

  computed: {
    largeRegnr() {
      return this.promo && this.windowWidth >= Breakpoints.md;
    }
  },
  methods: {
    onChange() {
      const regNr = this.regNr ? this.regNr.toUpperCase() : null;
      if (regNr) this.$emit("change", regNr);
    },
    onSubmit() {
      this.$emit("submit");
    }
  }
};
</script>
