/* eslint-disable no-useless-escape */

export default {
  RegistrationNumber: /^\w{3}\d{2}(\w{1}|\d{1})$/,
  Email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
  Ssn: /^(19|20)?[0-9]{6}[- ]?[0-9]{4}$/,
  ResursEmail: /^[A-Za-z0-9!#%&'*+/=?^_`~-]+(\.[A-Za-z0-9!#%&'*+/=?^_`~-]+)*@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$/,
  ResursPhone: /^(0|\+46|0046)[ |-]?(200|20|70|73|76|74|[1-9][0-9]{0,2})([ |-]?[0-9]){5,8}$/,
  ResursSsn: /^(16\d{2}|18\d{2}|19\d{2}|20\d{2}|\d{2})(\d{2})(\d{2})(\-|\+)?([\d]{4})$/,
  ResursCard: /^([1-9][0-9]{3}[ ]{0,1}[0-9]{4}[ ]{0,1}[0-9]{4}[ ]{0,1}[0-9]{4})$/,
  cellPhone: /(0|\+46)7[0-9]{1}[-|\s]?[0-9]{7}/,
  Numeric: /^\d+$/,
}

