export default {
  async set(key, value) {
    const stringifiedObject = JSON.stringify(value)

    try {
      await sessionStorage.setItem(key, stringifiedObject)
      return
    } catch (error) {
      console.log(error)
    }
  },
  async get(key) {
    try {
      const string = await sessionStorage.getItem(key)
      return JSON.parse(string)
    } catch (error) {
      console.log(error)
    }
  }
}