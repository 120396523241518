<template>
  <div>

    <div v-if="error">
      <VcFeedbackMessage danger>
        <div slot="header">
          {{ lang.App.SubmitErrorHeading ? lang.App.SubmitErrorHeading : 'Ett fel uppstod.'}}
        </div>
        {{ lang.App.SubmitErrorText ? lang.App.SubmitErrorText : 'Vänligen kontakta vår kundtjänst eller försök igen.'}}
      </VcFeedbackMessage>
    </div>
    <div v-if="done">
      <VcFeedbackMessage success>
        <div slot="header">
          {{alternateTranslationKeys ? getAlternateFeedbackMessage().header : lang.App.SubmitFeedbackHeader}}
        </div>
        {{alternateTranslationKeys ? getAlternateFeedbackMessage().text : lang.App.SubmitFeedbackText}}
      </VcFeedbackMessage>
    </div>
    <div v-else class="wizard__submit">
      <slot></slot>

      <div v-if="requireTerms" class="form-group form-check">
        <input class="form-check__input" id="agreedTerms" type="checkbox" v-model="agreedTerms" />
        <div class="form-check__label" @click="agreedTerms = !agreedTerms" v-html="lang.General.TermsText">
        </div>
      </div>

      <VcFeedbackMessage v-if="displayAgreementError" info>
        Du måste godkänna villkoren för att skicka bokningen/förfrågan.
      </VcFeedbackMessage>

      <div
        :class="{'disabled': btnDisabled}"
        class="btn btn--primary btn--lg btn--block"
        @click="onSubmit">
        {{btnText}}
      </div>

      <div class="form-text" v-html="lang.General && lang.General.PrivacyPolicyText">
      </div>
    </div>
  </div>
</template>

<script>
import { VcFeedbackMessage } from '@holmgrensbil/vue-components';
import { mapState, mapGetters, mapActions } from 'vuex';
import RequestStates from '../../../constants/RequestStates';

export default {
  components: {
    VcFeedbackMessage,
  },
  props: {
    lastSection: Object,
    requireTerms: Boolean,
    alternateTranslationKeys: Object,
    buttonText: String,
  },
  data() {
    return {
      agreedTerms: false,
      validate: false,
    };
  },
  computed: {
    ...mapState({
      lang: state => state.config.lang,
      formSubmitState: state => state.form.submitState,
      order: state => state.sections.order,
    }),

    ...mapGetters({
      sectionByType: 'sections/byType',
      fieldByType: 'fields/byType',
      fieldsValid: 'fields/valid',
      fieldsExport: 'fields/export',
    }),

    btnDisabled() {
      if (this.loading) {
        return true;
      }

      return this.lastSection.order !== this.order;
    },

    btnText() {
      return this.buttonText && this.buttonText.length > 0 ? this.buttonText : this.lang.App.SubmitButtonText
    },

    agreementInvalid() {
      return this.requireTerms && !this.agreedTerms;
    },

    displayAgreementError() {
      return this.validate && this.agreementInvalid;
    },

    done() {
      return this.formSubmitState === RequestStates.Success;
    },

    error() {
      return this.formSubmitState === RequestStates.Failure;
    },

    loading() {
      return this.formSubmitState === RequestStates.Request;
    },
  },
  methods: {
    ...mapActions({
      submitForm: 'form/submit',
      nextSection: 'sections/next',
      validateSection: 'sections/validate',
    }),

    async onSubmit() {
      this.validateSection({ section: this.lastSection });
      this.validate = true;
      if (this.fieldsValid && !this.agreementInvalid) {
        this.nextSection();
        const result = await this.submitForm({
          ...this.fieldsExport,
          pageUrl: window.location.toString(),
        });
        if(result) {
          this.$emit('completed', this.lastSection);
        }
      }
    },

    getAlternateFeedbackMessage() {
      return {
        header: this.lang.App[this.alternateTranslationKeys.header],
        text: this.lang.App[this.alternateTranslationKeys.text],
      }
    }
  },
}
</script>
