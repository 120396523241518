export default class I3DocBlogArticle {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc._id;
  }

  get heading() {
    return this._doc.Heading;
  }

  get intro() {
    return this._doc.IntroText || "";
  }

  get url() {
    return this._doc.Url;
  }

  get blogTags() {
    return this._doc.BlogTag || [];
  }

  get created() {
    return this._doc.Created;
  }

  get image() {
    return this._doc.MainImage;
  }

  get name() {
    return this._doc.Name;
  }
}
