import FieldTypes from '../../shared/wizard/constants/FieldTypes';

export default {
  ...FieldTypes,
  Services: 'services',
  ServiceTypes: 'serviceTypes',
  DepartmentNr: 'departmentNr',
  Addons: 'options',
  ServiceMessage: 'serviceMessage',
  RepairMessage: 'repairMessage',
  Mileage: 'mileage',
  ServiceAgreement: 'serviceAgreement',
  ServiceAgreementAddons: 'serviceAgreementAddons',
}
