var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "product-meta" }, [
        _c(
          "div",
          { staticClass: "product-meta__links" },
          _vm._l(_vm.modals, function(modal) {
            return _c(
              "div",
              { key: modal.name, staticClass: "product-meta__links__item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "link link--underline",
                    on: {
                      click: function($event) {
                        return _vm.onClick(modal)
                      }
                    }
                  },
                  [_vm._v("\n          " + _vm._s(modal.name) + "\n        ")]
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.activeModal
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.activeModal = null
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("\n      " + _vm._s(_vm.activeModal.name) + "\n    ")
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.activeModal.value) },
                slot: "content"
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }