<template>
  <div
    v-if="associatedProducts.length"
    class="section__content section__content--lg"
  >
    <div class="section__header section__header--sm">
      {{ title }}
      <a :href="link" class="header-meta link link--underline link--icon">
        <span>{{ linkTitle }}</span>
        <i class="fal fa-angle-right"></i>
      </a>
    </div>
    <div class="eon-product-slider">
      <EonProductSlider :productObjects="associatedProducts"></EonProductSlider>
      <portal-target name="eon-product-services-modal"></portal-target>
    </div>
  </div>
</template>

<script>
import EonProductSlider from '../shared/ecom/EonProductSlider.vue';
import EonApi from '../../models/EonApi';
import EonProduct from '../../models/EonProduct';
import TyreSpec from '../../models/TyreSpec';

export default {
  components: {
    EonProductSlider,
  },
  props: {
    productObject: Object,
    title: String,
    link: String,
    linkTitle: String,
  },
  data() {
    return {
      associatedProducts: [],
    };
  },
  async created() {
    const currentProd = this.product;
    if (!TyreSpec.isEmpty)
    {
      const searchObject = {typeId: currentProd.typeId, tyreType: currentProd.tyreTypeId == 9 || currentProd.tyreTypeId == 4 ? 3 : currentProd.tyreTypeId, limit: 4};
      let performSearch = false;
      if ((currentProd.typeId === 2 || currentProd.typeId === 28) && TyreSpec.regNr) {
        searchObject.licenseplate = TyreSpec.regNr;
        performSearch = true;
      } else if (currentProd.typeId === 1) {
        searchObject.condition = "new";
        searchObject.width = currentProd.tyreAttrs?.width;
        searchObject.aspectRatio = currentProd.tyreAttrs?.aspectRatio;
        searchObject.diameter = currentProd.tyreAttrs?.diameter;
        performSearch = true;
      }
      if (performSearch) {
        
        try {
          const res = await EonApi.getProducts(searchObject);
          
          if (res.data?.products) {
            let filteredProducts = res.data.products.filter(p => p.productId !== currentProd.id);

            return this.associatedProducts = filteredProducts.slice(0,3);
          }
        } catch (error) {
          console.log("unable to find associated products", error);
        }
      }
    }
  },
  computed: {
    product() {
      return new EonProduct(this.productObject);
    },
  },

}
</script>
