var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStack",
    { attrs: { label: _vm.title } },
    [
      _c("VcFormSelect", {
        attrs: {
          options: _vm.availableServices,
          value: _vm.selected,
          labelProp: "name",
          valueProp: "value",
          prompt: "Ingen tjänst vald"
        },
        on: { change: _vm.select }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }