var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "contact__selected col-lg-8" }, [
      _c("h4", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.hideSections ? "Ta kontakt" : _vm.section.name) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-4" },
          _vm._l(_vm.selections, function(selection) {
            return _c(selection.component, {
              key: selection.type,
              tag: "component"
            })
          }),
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-8" }, [
          _vm.selectedDepartment
            ? _c(
                "div",
                { staticClass: "contact__department" },
                [
                  _c(
                    "div",
                    { staticClass: "contact__department-content" },
                    [
                      _c(
                        _vm.SectionHandleComponents[_vm.section.sectionHandle],
                        {
                          tag: "component",
                          attrs: { department: _vm.selectedDepartment }
                        }
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("DepartmentActions", {
                    attrs: {
                      "hide-sections": _vm.hideSections,
                      section: _vm.section,
                      department: _vm.selectedDepartment,
                      regNr: _vm.regNr
                    }
                  })
                ],
                1
              )
            : _c("div", { staticClass: "contact__department-placeholder" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.section.placeholder) +
                    "\n        "
                )
              ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.section.questions && _vm.section.questions.length
      ? _c(
          "div",
          { staticClass: "contact__faq col-lg-4" },
          [
            _c("h4", [_vm._v("\n      Vanliga frågor och svar\n    ")]),
            _vm._v(" "),
            _c("SectionFaq", { attrs: { questions: _vm.section.questions } })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }