import mountApp from "../utils/mount-app";
import ReviewsWidget from "../apps/reviews-widget";
import ReviewsModal from "../apps/reviews-modal";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: ReviewsWidget,
      selector: '[data-app=reviews-widget]',
      extractProps: el => ({
        inBlock: el.getAttribute('data-in-block') == "true",
        locationId: el.getAttribute('data-location-id'),
        heading: el.getAttribute('data-heading'),
        address: el.getAttribute('data-address'),
        reviewsUrl: el.getAttribute('data-reviews-url'),
      }),
    });

    mountApp({
      app: ReviewsModal,
      selector: '[data-app=reviews-modal]',
      extractProps: el => ({
        locationId: el.getAttribute('data-location-id'),
        heading: el.getAttribute('data-heading'),
        address: el.getAttribute('data-address'),
        reviewsUrl: el.getAttribute('data-reviews-url'),
      }),
    });
  },
}
