import { secondsToMsInt } from "../utils/numbers";
import Breakpoints from "../constants/Breakpoints";
import events from "../mixins/events";
import OverlayEvents from '../constants/OverlayEvents';

const selectors = {
  content: '.overlay__content',
};

const classes = {
  active: 'overlay--active',
  backdropActive: 'backdrop--active',
  aside: 'overlay--aside',
  overlayReplaceTransition: 'overlay--replace-transition',
  backdropAside: 'backdrop--aside',
  contentForward: 'overlay__content--forward',
  contentBackward: 'overlay__content--backward',
};

let overlayCount = 0;

class Overlay {
  constructor({ aside } = {}) {
    this.eventPrefix = `overlay.${Overlay.getCount()}.`;
    this.$el = $('<div class="overlay"></div>');
    this.$backdrop = $('<div class="backdrop"></div>');
    this.aside = aside;
    this.initialized = false;

    if (this.aside) {
      this.$el.addClass(classes.aside);
      this.$backdrop.addClass(classes.backdropAside);
    }
  }

  static getCount() {
    return ++overlayCount;
  }

  init({ content } = {}) {
    this.$backdrop.click(() => {
      this.close();
      this.emit(OverlayEvents.BackdropClose, { overlay: this });
    });

    if (content) {
      this.setContent(content);
    }

    $('body').append(this.$backdrop);
    $('body').append(this.$el);
    this.initialized = true;
  }

  setContent(content) {
    const html = $('<div class="overlay__content"></div>');
    html.append(content);
    this.$el.html(html);
    this.onContentInserted();
  }

  clearContent() {
    this.$el.empty();
  }

  open({ topOffset } = {}) {
    this.emit(OverlayEvents.Open, { overlay: this });
    this.$el.addClass(classes.active);
    this.$backdrop.addClass(classes.backdropActive);

    if (topOffset) {
      this.$el.css({
        top: topOffset,
        minHeight: this.$el.outerHeight() - topOffset,
        maxHeight: `calc(100% - ${topOffset}px)`,
      });

      this.$backdrop.css({
        top: topOffset,
        maxHeight: `calc(100% - ${topOffset}px)`,
      });
    }

    this.updateOverlayOpen();
    this.emit(OverlayEvents.Opened, { overlay: this });
  }

  replaceContent({ content, transition, transitionDirection }) {
    if (transition) {
      const inDirClass = transitionDirection === 'forward' ? classes.contentForward : classes.contentBackward;
      const outDirClass = transitionDirection === 'forward' ? classes.contentBackward : classes.contentForward;
      const transitionDuration = secondsToMsInt($(selectors.content).css('transition-duration'));
      const $current = this.$el.find(selectors.content);
      const $next = $(`
        <div class="overlay__content ${inDirClass}">
          ${content}
        </div>`);

      this.$el.addClass(classes.overlayReplaceTransition);
      this.$el.append($next);

      setTimeout(() => {
        this.$el.css('min-height', $next.outerHeight());
        $current.addClass(outDirClass);
        $next.removeClass(inDirClass);

        setTimeout(() => {
          this.$el.removeClass(classes.overlayReplaceTransition);
          $current.remove();
        }, transitionDuration);
      });
    } else {
      this.$el.find(selectors.content).html(content);
    }

    this.onContentInserted();
  }

  close({ clear } = {}) {
    this.$el.removeClass(classes.active);
    this.$backdrop.removeClass(classes.backdropActive);
    this.updateOverlayOpen();

    setTimeout(() => {
      this.$el.css({
        top: '',
        minHeight: '',
      });
      this.$backdrop.css('top', '');

      if (clear) {
        this.clearContent();
      }
    }, this.getElTransitionDuration());
  }

  getElTransitionDuration() {
    return secondsToMsInt(this.$el.css('transition-duration').split(', ')[0]);
  }

  onContentInserted() {
    this.emit(OverlayEvents.ContentInserted, { overlay: this });
  }

  isOpen() {
    return this.$el.hasClass(classes.active);
  }

  updateOverlayOpen() {
    const openOverlays = $('body').data('open-overlays') || 0;
    const current = this.isOpen() ? openOverlays + 1 : openOverlays - 1;
    const scrollTop = $('body').data('overlay-scroll-top');

    if (openOverlays === 0) {
      $('body').data('overlay-scroll-top', $('body').scrollTop());
    }

    $('body').data('open-overlays', current);

    if (current) {
      if ($(window).width() >= Breakpoints.md) {
        $('body').addClass('overlay-open');
      } else {
        setTimeout(() => {
          $('body').addClass('overlay-open');
        }, this.getElTransitionDuration());
      }
    } else {
      $('body').removeClass('overlay-open');

      if (scrollTop) {
        window.scrollTo(0, scrollTop);
      }
    }
  }

  destroy() {
    this.$el.remove();
    this.$backdrop.remove();
    this.initialized = false;
  }
}

Object.assign(Overlay.prototype, events);

export default Overlay;
