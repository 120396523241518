import LineItemDeliveryStatuses from "../constants/LineItemDeliveryStatuses";

export default class AccessoryProduct {
  constructor(product) {
    this._product = product;
  }

  get id() {
    return this._product._id;
  }

  get encodedId() {
    return this.id.toString().replace(/\./g, "");
  }

  get name() {
    return this._product.name;
  }

  get encodedName() {
    return this.name.replace(/\s/g, "-").replace(/[^a-zA-Z0-9-]+/g, "");
  }

  get ordinaryPrice() {
    const { consumerPrice } = this._product;
    return parseInt(consumerPrice) / 100;
  }

  get discountedPrice() {
    const { campaignPrice } = this._product;
    return parseInt(campaignPrice) / 100;
  }

  get displayPrice() {
    return this.price / 10;
  }

  get brand() {
    return this._product.brand;
  }

  get titleLines() {
    const lines = [];

    lines.push({
      title: this._product.name,
      meta: this._product.category,
    });

    return lines;
  }

  get brandName() {
    return this._product.brand;
  }

  get stock() {
    return 5;
  }

  get minQuantity() {
    return 1;
  }

  get maxQuantity() {
    return this.stock;
  }

  get quantityOptions() {
    if (!this.maxQuantity) {
      return [];
    }

    const len = this.maxQuantity - this.minQuantity + 1;
    const range = [...Array(len).keys()].map(v => v + this.minQuantity);
    const options = range.map(q => ({
      name: `${q} st`,
      value: q
    }));
    return options;
  }

  get quantityDefaultOption() {
    if (!this.quantityOptions.length) {
      return null;
    }

    return this.quantityOptions[0];
  }

  getDeliveryStatus(quantity) {
    if (this.stock >= quantity) {
      return LineItemDeliveryStatuses.Supplier;
    } else {
      return LineItemDeliveryStatuses.Order;
    }
  }


  get images() {
    return this._product.images || [];
  }

  get imageThumbnailUrl() {
    const { image } = this._product;
    return image ? image.webshop_thumb : null;
  }

  get imageUrl() {
    const { image } = this._product;
    return image ? image.original : null;
  }
}
