import SectionTypeComponents from '../../shared/wizard/constants/SectionTypeComponents';
import SectionTypes from './SectionTypes';
import SectionContentServices from '../SectionContentServices.vue';
import SectionContentDepartment from '../SectionContentDepartment.vue';
import SectionContentDate from '../SectionContentDate.vue';
import SectionContentAddons from '../SectionContentAddons.vue';
import SectionContentPromotion from '../SectionContentPromotion.vue';
import SectionContentExternalUrl from '../SectionContentExternalUrl.vue';

export default {
  ...SectionTypeComponents,
  [SectionTypes.Services]: SectionContentServices,
  [SectionTypes.Department]: SectionContentDepartment,
  [SectionTypes.Date]: SectionContentDate,
  [SectionTypes.Addons]: SectionContentAddons,
  [SectionTypes.Promotion]: SectionContentPromotion,
  [SectionTypes.ExternalUrl]: SectionContentExternalUrl,
}
