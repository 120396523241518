<template>
  <VcFormStack
    :label="field.label"
    :required="field.required">
    <VcFormSelect
      :options="field.options"
      :value="field.value"
      :prompt="field.placeholder"
      :invalid="invalid"
      :valueProp="valueProp"
      :labelProp="labelProp"
      :valueType="valueType"
      @change="$emit('change', $event)">
    </VcFormSelect>
  </VcFormStack>
</template>

<script>
import { VcFormStack, VcFormSelect } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormStack,
    VcFormSelect,
  },
  props: {
    invalid: Boolean,
    field: Object,
    valueProp: String,
    labelProp: String,
    valueType: String,
  },
}
</script>
