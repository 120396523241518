import gtm from "../utils/gtm";
import Axios from '../axios'
import SaveVehicleManager from "../models/SaveVehicleManager";
import ttjs from '../components/ttjs';
import { EventBus } from '../utils/event-bus';
import Breakpoints from '../constants/Breakpoints';

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  async modifyDOM() {

    this.setTooltipLayout();

    SaveVehicleManager.onAlterVehicleList(() => {
      if (!this.isSaved) {
        this.unsetSavedIcon();
      }
    })
  },

  async setTooltipLayout() {
    if (!this.isLoggedIn()) {
      this.setLoggedOutTooltip();
    } else {
      this.removeLoggedOutTooltip();
      if (await this.vehicleIsSaved()) {
        this.isSaved = true;
        this.setSavedIcon();
      }
    }
  },

  addEvents() {
    $('body')
      .on('click', '.product-main__open-contact', this.onOpenContactClick.bind(this))
      .on('click', '.vehicle-page__save-vehicle', this.onSaveVehicleClick.bind(this))
      .on('click', '.compare-vehicle-btn', this.onCompareVehicleClick.bind(this));
    EventBus.$on('logged-in', this.setTooltipLayout.bind(this));
  },

  onOpenContactClick({ currentTarget }) {
    const $el = $(currentTarget);
    const departmentNr = $el.data('department-nr');
    const carStatus = $el.data('car-status');
    const label = $el.text().trim();

    gtm.vehicleContactClick({ departmentNr, carStatus, label });
  },

  isLoggedIn() {
    return window.CurrentUser !== undefined && window.CurrentUser !== null;
  },

  async onSaveVehicleClick() {
    const regNr = $('.product-meta__license-plate').text().trim();
    if (this.isLoggedIn()) {
      if (this.isSaved) {
        try {
          await Axios.delete(`/api/MyPages/vehicles/${ regNr }`);
          SaveVehicleManager.alterVehicleList();
          this.unsetSavedIcon();
          this.isSaved = false;
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          await Axios.post('/api/MyPages/vehicles', { registrationNumber: regNr });
          SaveVehicleManager.alterVehicleList();
          this.setSavedIcon();
          this.isSaved = true;
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      document.dispatchEvent(new CustomEvent("toggle-mypages"));
    }
  },

  async onCompareVehicleClick() {
    const regNr = $('.product-meta__license-plate').text().trim();
    EventBus.$emit('compareVehicle', regNr)
  },

  async vehicleIsSaved() {
    const regNr = $('.product-meta__license-plate').text().trim();
    const savedVehicles = await Axios.get('/api/MyPages/vehicles');
    return savedVehicles.data.includes(regNr);
  },

  setSavedIcon() {
    const bookmarkTag = $('.bookmark__icon');
    const bookmarkTitleTag = $('.bookmark__link');

    bookmarkTag.removeClass('fal fa-heart')
    bookmarkTag.addClass('fa fa-heart bookmark__icon--filled')
    if (window.innerwidth > Breakpoints.md) {
      bookmarkTitleTag.addClass('ttjs')
      bookmarkTitleTag.attr('data-ttjs-title', 'Tillagd i dina sparade fordon. Under Mina sidor kan du se och ta bort sparade fordon')
    }

    bookmarkTitleTag.text('Sparat fordon')
  },

  unsetSavedIcon() {
    const bookmarkTag = $('.bookmark__icon');
    const bookmarkTitleTag = $('.bookmark__link');

    const tooltip = $('.ttjs-tooltip')
    tooltip.remove();

    bookmarkTag.removeClass('fa fa-heart bookmark__icon--filled')
    bookmarkTag.addClass('fal fa-heart')
    bookmarkTitleTag.text('Spara fordon')

    bookmarkTitleTag.removeClass('ttjs')

    const tt = ttjs.tooltips.find(t => t.reference === bookmarkTitleTag[0])
    ttjs.remove(tt);

  },

  setLoggedOutTooltip() {
    const bookmarkTag = $('.bookmark__icon');
    const bookmarkTitleTag = $('.bookmark__link');

    bookmarkTag.addClass('bookmark__user-not-logged-in')
    if (window.innerwidth > Breakpoints.md) {
      bookmarkTitleTag.addClass('ttjs bookmark__user-not-logged-in')
      bookmarkTitleTag.attr('data-ttjs-title', 'Du måste vara inloggad för att kunna spara fordon')
    } else {
      bookmarkTitleTag.addClass('bookmark__user-not-logged-in')
    }
  },

  removeLoggedOutTooltip() {
    const bookmarkTag = $('.bookmark__icon');
    const bookmarkTitleTag = $('.bookmark__link');

    const tooltip = $('.ttjs-tooltip')
    tooltip.remove();

    bookmarkTag.removeClass('bookmark__user-not-logged-in')

    bookmarkTitleTag.removeClass('ttjs bookmark__user-not-logged-in')
    bookmarkTitleTag.removeAttr('data-ttjs-title');
    bookmarkTitleTag.removeClass('ttjs');
    const tt = ttjs.tooltips.find(t => t.reference === bookmarkTitleTag[0])
    ttjs.remove(tt);
  }
};
