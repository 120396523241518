import pageHeader from "../components/page-header";
import pageHeaderI3Filter from "../components/page-header-i3-filter";
import pageHeaderI3Sort from "../components/page-header-i3-sort";

export default {
  init() {
    pageHeader.init();
    pageHeaderI3Filter.init();
    pageHeaderI3Sort.init();
  },
};
