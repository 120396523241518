export default {
  Allseason: 1,
  Summer: 2,
  WinterFriction: 3,
  WinterNordic: 4,
  WinterStudded: 5,
  WinterStuddable: 6,
  Spare: 7,
  Racing: 8,
  EuropeanWinterFriction: 9,
}
