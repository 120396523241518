import TyreSpec from "../models/TyreSpec";
import TyreSpecToolbar from "../apps/tyre-spec-toolbar";
import EonFilterPage from "../apps/eon-filter-page";
import mountApp from "../utils/mount-app";
import EonChannelManager from "../models/EonChannelManager";

export default {
  init() {
    this.ref = $('[data-app=eon-filter-page]').data('eon-ref');
    this.channel = EonChannelManager.getByRef(this.ref);
    this.modifyDOM();
    this.addEvents();
  },

  addEvents() {
  },

  modifyDOM() {
    mountApp({
      app: TyreSpecToolbar,
      selector: '[data-app=tyre-spec-toolbar]',
      setEonChannel: true,
      props: {
        TyreSpec,
      },
    });

    mountApp({
      app: EonFilterPage,
      selector: '[data-app=eon-filter-page]',
      setEonChannel: true,
      props: {
        TyreSpec,
      },
    });
  },
};
