<template>
  <VcFormStep
    :completed="completed"
    :disabled="disabled"
    :feedbackDanger="displaySectionValidationError"
    :arrow="!displaySectionValidationError"
    :title="getTitle(section)"
    :subtitle="getSubtitle(section)"
    :summary="section.summary"
  >
    <SectionEditButton
      slot="header-aside"
      v-if="completed && !submitted && !disableEdit"
      @click="onEdit"
    >
    </SectionEditButton>
    <div v-if="active && skipButton" slot="preContent">
      <a class="btn btn--muted btn--block u-margin-bottom" @click="onSubmit"
        >Nej, tack. Jag vill inte ha några tillval</a
      >
    </div>
    <div slot="content">
      <div v-if="active || section.summary">
        <component
          :is="SectionTypeComponents[section.type]"
          :section="section"
          :sectionValid="valid"
          @submit="onSubmit"
        >
        </component>
      </div>
      <FieldChecklist v-else-if="completed" :fields="sectionFields">
      </FieldChecklist>
    </div>
    <div v-if="active && !hideFooter" slot="footer" class="form-step__actions">
      <label>{{ footerLabel }}</label>
      <a class="btn btn--primary" @click="onSubmit">Fortsätt</a>
    </div>
    <SectionValidationError
      v-if="displaySectionValidationError"
      slot="feedback"
      :fields="invalidSectionFields"
    >
    </SectionValidationError>
  </VcFormStep>
</template>

<script>
import { VcFormStep } from "@holmgrensbil/vue-components";
import { mapState, mapGetters, mapActions } from "vuex";
import SectionValidationError from "./SectionValidationError.vue";
import FieldChecklist from "./FieldChecklist.vue";
import SectionEditButton from "./SectionEditButton.vue";
import RequestStates from "../../../constants/RequestStates";

export default {
  components: {
    VcFormStep,
    SectionValidationError,
    SectionEditButton,
    FieldChecklist
  },
  props: {
    section: Object,
    footerLabel: String,
    hideFooter: Boolean,
    SectionTypeComponents: Object
  },
  computed: {
    ...mapState({
      lang: state => state.config.lang,
      order: state => state.sections.order,
      formSubmitState: state => state.form.submitState
    }),

    ...mapGetters({
      fieldsBySection: "fields/bySection",
      invalidFieldsBySection: "fields/invalidBySection"
    }),

    submitted() {
      return this.formSubmitState === RequestStates.Success;
    },

    active() {
      return this.section.order === this.order;
    },

    completed() {
      return this.section.order < this.order;
    },

    skipButton() {
      return this.section.skipButton;
    },

    disabled() {
      return this.section.order > this.order;
    },

    sectionFields() {
      return this.fieldsBySection(this.section.type);
    },

    invalidSectionFields() {
      return this.invalidFieldsBySection(this.section.type);
    },

    disableEdit() {
      return this.section?.disableEdit;
    },

    displaySectionValidationError() {
      return this.section.validate && !!this.invalidSectionFields.length;
    },

    valid() {
      return !this.invalidSectionFields.length;
    }
  },

  methods: {
    ...mapActions({
      validateSection: "sections/validate",
      goToSection: "sections/goTo"
    }),

    onEdit() {
      this.goToSection(this.section.order);
    },

    onSubmit() {
      this.validateSection({ section: this.section });

      if (this.valid) {
        this.$emit("done");
        return true;
      }

      return false;
    },

    getTitle(section) {
      const obj =
        this.lang &&
        this.lang.App &&
        this.lang.App.Sections &&
        this.lang.App.Sections[section.type];
      return obj && obj.Title;
    },

    getSubtitle(section) {
      const obj =
        this.lang &&
        this.lang.App &&
        this.lang.App.Sections &&
        this.lang.App.Sections[section.type];
      return obj && obj.Subtitle;
    }
  }
};
</script>
