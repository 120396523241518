import SpareParts from "../apps/spare-parts";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: SpareParts,
      selector: '[data-app=spare-parts]',
      props: {
        pageName: window.document.title,
      },
    });
  },
};
