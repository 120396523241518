<template>
  <div class="i3-filter__facets-view">
    <FacetItem v-for="facet in facets"
      :key="facet.id"
      :facet="facet"
      :iconRight="navigateToFacet"
      :selected="expandedFacets.includes(facet.id)"
      @select="$emit('selectFacet', facet)">
      <div class="i3-filter__facet-children" v-if="!navigateToFacet && expandedFacets.includes(facet.id)">
        <FacetItemContent
          :facet="facet"
          @selectFilter="$emit('selectFilter', $event)"
          @selectRangeFilter="$emit('selectRangeFilter', $event)">
        </FacetItemContent>
      </div>
    </FacetItem>
  </div>
</template>

<script>
import FacetItem from './FacetItem.vue';
import FacetItemContent from './FacetItemContent.vue';

export default {
  props: {
    facets: Array,
    expandedFacets: Array,
    navigateToFacet: Boolean,
  },
  components: {
    FacetItem,
    FacetItemContent,
  },
}
</script>
