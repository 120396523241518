import { render, staticRenderFns } from "./DivDummy.vue?vue&type=template&id=00c39f5f&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\agent\\_work\\1\\s\\src\\Holmgrens.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('00c39f5f')) {
      api.createRecord('00c39f5f', component.options)
    } else {
      api.reload('00c39f5f', component.options)
    }
    module.hot.accept("./DivDummy.vue?vue&type=template&id=00c39f5f&", function () {
      api.rerender('00c39f5f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/apps/vehicle-list/DivDummy.vue"
export default component.exports