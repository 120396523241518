<template>
  <div class="campaign-list">
    <div class="vehicle-list__header">
        <div class="vehicle-list__header__title">
          {{$formatNumber(hits)}} kampanjer
        </div>
      </div>
    <TheGrid
      :channel="channel"
      :documents="documents"
      :queriedAt="queriedAt"
      @onNext="$emit('onNext')">
    </TheGrid>
  </div>
</template>

<script>
import TheGrid from './TheGrid.vue';
import i3TotalCount from '../../components/i3-total-count';
export default {
  props: {
    channel: Object,
    queriedAt: Date,
  },
  components: {
    TheGrid,
  },
  data() {
    return {
      documents: [],
      hits: 0,
    };
  },
  watch: {
    queriedAt() {
      this.documents = this.channel.data.documents.documents;
      this.hits = this.channel.data.documents.numberOfHits;
      setTimeout(() => {
        i3TotalCount.init();
      }, 50);
    },
  },
}
</script>
