import EonProductConditionTypes from "../constants/EonProductConditionTypes";
import EonTyreTypes from "../apps/eon-filter-page/constants/EonTyreTypes";
import EonProductTypes from "../apps/eon-filter-page/constants/EonProductTypes";
import EonRimTypes from "../apps/eon-filter-page/constants/EonRimTypes";
import EonProductTypeSlugs from "../apps/eon-filter-page/constants/EonProductTypeSlugs";
import LineItemDeliveryStatuses from "../constants/LineItemDeliveryStatuses";
import EonLoadIndexLoads from "../apps/eon-filter-page/constants/EonLoadIndexLoads";
import EonSpeedIndexSpeeds from "../apps/eon-filter-page/constants/EonSpeedIndexSpeeds";

export default class EonProduct {
  constructor(product) {
    this._product = product;
  }

  get id() {
    return this._product.productId;
  }

  get encodedId() {
    return this.id.toString().replace(/\./g, "");
  }

  get name() {
    return this._product.name;
  }

  get encodedName() {
    return this.name.replace(/\s/g, "-").replace(/[^a-zA-Z0-9-]+/g, "");
  }

  get conditionType() {
    return this.isNew ? "Ny" : "Begagnad";
  }

  get isNew() {
    const { condition } = this._product;

    if (!condition) {
      return false;
    }

    return (
      condition.type.toLowerCase() ===
      EonProductConditionTypes.New.toLowerCase()
    );
  }

  get condition() {
    const { condition } = this._product;
    return condition;
  }

  get isRim() {
    return this.typeId === EonProductTypes.Rim;
  }

  get isTyre() {
    return this.typeId === EonProductTypes.Tyre;
  }

  get isCompleteWheel() {
    return this.typeId === EonProductTypes.CompleteWheel;
  }

  get typeId() {
    const { type } = this._product;
    return type.id;
  }

  get productTypeDisplayName() {
    const { type } = this._product;

    if (!type) {
      return "";
    }

    return type.name;
  }

  get isStudded() {
    return this.tyreAttrs.isStudded;
  }

  get isFriction() {
    const { tyreType } = this.tyreAttrs;

    return (
      tyreType &&
      [
        EonTyreTypes.EuropeanWinterFriction,
        EonTyreTypes.WinterFriction,
        EonTyreTypes.WinterNordic
      ].includes(tyreType.id)
    );
  }

  get price() {
    const { consumerPrice, vat } = this._product;
    return ((consumerPrice + vat) / 100).toFixed(0);
  }

  get displayPrice() {
    return this.price / 10;
  }

  get tyre() {
    return this._product.tyre;
  }

  get rim() {
    return this._product.rim;
  }

  get brand() {
    return this._product.brand;
  }

  get model() {
    return this._product.model;
  }

  totalPriceWithVat(quantity) {
    var price = Math.round(this._product.price / 100);
    if (this._product.discount > 0 && this._product.discountQuantity > 0 && this._product.discountQuantity != quantity)
    {
      price = Math.round(this._product.nonDiscountedPrice / 100);
    }
    const vatPercent = Number(this._product.vatPercent);

    const vatQuote = vatPercent / 100 + 1;
    const displayPrice = price * vatQuote;

    return displayPrice.toFixed(0);
  }

  discounted(quantity) {
    return this._product.discount > 0 && this._product.discountQuantity === quantity;
  }

  get totalOrdinaryPriceWithVat() {
    const price = Math.round(this._product.consumerPrice / 100);
    const vatPercent = Number(this._product.vatPercent);

    const vatQuote = vatPercent / 100 + 1;
    const displayPrice = price * vatQuote;

    return displayPrice.toFixed(0);
  }

  get tyreAttrs() {
    return this.isCompleteWheel ? this.tyre.attrs : this._product.attrs;
  }

  get rimAttrs() {
    return this.isCompleteWheel ? this.rim.attrs : this._product.attrs;
  }

  get isWinter() {
    const { tyreType } = this.tyreAttrs;

    return (
      tyreType &&
      [
        EonTyreTypes.EuropeanWinterFriction,
        EonTyreTypes.WinterFriction,
        EonTyreTypes.WinterNordic,
        EonTyreTypes.WinterStuddable,
        EonTyreTypes.WinterStudded
      ].includes(tyreType.id)
    );
  }

  get isAllround() {
    const { tyreType } = this.tyreAttrs;

    return tyreType && tyreType.id === EonTyreTypes.Allseason;
  }

  get isSpare() {
    const { tyreType } = this.tyreAttrs;

    return tyreType && tyreType.id === EonTyreTypes.Spare;
  }

  get isSummer() {
    const { tyreType } = this.tyreAttrs;

    return tyreType && tyreType.id === EonTyreTypes.Summer;
  }

  get isRacing() {
    const { tyreType } = this.tyreAttrs;

    return tyreType && tyreType.id === EonTyreTypes.Racing;
  }

  get tyreTypeId() {
    const { tyreType } = this.tyreAttrs;

    return tyreType ? tyreType.id : null;
  }

  get seasonDisplayName() {
    if (this.isWinter) {
      return "Vinterdäck";
    } else if (this.isSummer) {
      return "Sommardäck";
    } else if (this.isSpare) {
      return "Reservdäck";
    } else if (this.isRacing) {
      return "Racingdäck";
    } else if (this.isAllround) {
      return "Allround-däck";
    }

    return "";
  }

  get seasonIconClass() {
    return this.isWinter ? "fal fa-snowflake" : "fal fa-sun-bright";
  }

  get rimTypeDisplayName() {
    const { rimType } = this.rimAttrs;

    if (!rimType) {
      return "";
    }
    return rimType.id === EonRimTypes.Steel ? "Stålfälg" : "Aluminiumfälg";
  }

  get hasTpms() {
    return this._product.hasTpms;
  }

  get hasSCT() {
    return (
      this._product.description && this._product.description.includes("SCT")
    );
  }

  get titleLines() {
    const lines = [];

    if (this.isTyre) {
      lines.push({
        title: `${this.brand.name} ${this.model.name}`,
        meta: `${this.seasonDisplayName}${this.isStudded ? " • Dubb" : ""}${
          this.isFriction ? " • Friktion" : ""
        } • ${this.tyreAttrs.dimension ?? ""}`,
        metaIcon: this.seasonIconClass
      });
    } else if (this.isRim) {
      lines.push({
        title: `${this.brand.name} ${this.model.name}`,
        meta: `${this.rimAttrs?.diameter}" ${this.rimTypeDisplayName}`
      });
    } else if (this.isCompleteWheel) {
      lines.push({
        title: `${this.tyre.brand.name} ${this.tyre.model.name}`,
        meta: `${this.seasonDisplayName}${this.isStudded ? " • Dubb" : ""}${
          this.isFriction ? " • Friktion" : ""
        } • ${this.tyre.attrs.dimension}`,
        metaIcon: this.seasonIconClass
      });
      lines.push({
        title: `${this.rim?.brand?.name} ${this.rim.model.name}`,
        meta: `${this.rim?.attrs?.diameter}" ${this.rimTypeDisplayName}`
      });
    }

    return lines;
  }

  get brandName() {
    if (this.isTyre || this.isRim) {
      return this.brand && this.brand.name;
    } else {
      return `${this.tyre.brand && this.tyre.brand.name} • ${this.rim.brand &&
        this.rim.brand.name}`;
    }
  }

  get stock() {
    return this._product.stock;
  }

  get usps() {
    return [
      this.isTyre && this.tyreAttrs.isRunflat
        ? {
          icon: "fal fa-tire-flat",
          label: "RFT",
          text: "Punkteringsfria däck"
        }
        : false,
      this.tyreAttrs &&
      this.tyreAttrs.loadIndex &&
      this.tyreAttrs.loadIndex.length
        ? {
          icon: "fal fa-weight-hanging",
          label: `${
            EonLoadIndexLoads[parseInt(this.tyreAttrs.loadIndex, 10)]
          } kg`,
          text: "Maximal tyngd som däcken är avsedda för"
        }
        : false,
      this.tyreAttrs && this.tyreAttrs.noiseDecibel > 0
        ? {
          icon: "fal fa-volume",
          label: `${this.tyreAttrs.noiseDecibel} db`,
          text: "Däckets externa ljudnivå"
        }
        : false,
      this.tyreAttrs &&
      this.tyreAttrs.speedIndex &&
      this.tyreAttrs.speedIndex.length
        ? {
          icon: "fal fa-tachometer-alt-fast",
          label: `${EonSpeedIndexSpeeds[this.tyreAttrs.speedIndex]} km/h`,
          text: " Högsta godkända hastighetsklass"
        }
        : false,
      this.tyreAttrs &&
      this.tyreAttrs.rollingResistance &&
      this.tyreAttrs.rollingResistance.length
        ? {
          icon: "fal fa-road",
          label: this.tyreAttrs.rollingResistance,
          text: " Däckets påverkan på bränsleförbrukning"
        }
        : false,
      this.hasTpms
        ? {
          icon: "fal fa-tire-pressure-warning",
          label: "TPMS ingår",
          text:
              "TPMS eller Tire Pressure Monitoring System är ett tryckövervakningssystem för däck."
        }
        : false,
      this.hasSCT
        ? {
          icon: "fal fa-ear-muffs",
          label: "SCT",
          text:
              "SoundComfort Technology - reducerar luftvibrationer och dämpar det invändiga bullret."
        }
        : false
    ].filter(Boolean);
  }

  get shortConditionType() {
    return this.conditionType.substr(0, 3);
  }

  get minQuantity() {
    if (this.isCompleteWheel && !this.isNew) {
      return 4;
    }

    if (!this.isNew) {
      return 1;
    }

    return 1;
  }

  get maxQuantity() {
    if (this.isCompleteWheel && !this.isNew) {
      return 4;
    }

    if (!this.isNew) {
      return this.stock;
    }

    return 8;
  }

  get quantityOptions() {
    if (!this.maxQuantity) {
      return [];
    }

    const len = this.maxQuantity - this.minQuantity + 1;
    const range = [...Array(len).keys()].map(v => v + this.minQuantity);
    const options = range.map(q => ({
      name: `${q} st`,
      value: q
    }));
    return options;
  }

  get quantityDefaultOption() {
    if (!this.quantityOptions.length) {
      return null;
    }

    const found4 = this.quantityOptions.findIndex(o => o.value === 4);
    const index = found4 === -1 ? 0 : found4;

    return this.quantityOptions[index];
  }

  getDeliveryStatus(quantity) {
    if (this._product.stock >= quantity) {
      if (this.hasInternalSupplier) {
        return LineItemDeliveryStatuses.Store;
      } else {
        return LineItemDeliveryStatuses.Supplier;
      }
    } else {
      return LineItemDeliveryStatuses.Order;
    }
  }

  get hasInternalSupplier() {
    const { internalSupplierIds } = window.EonConfig;
    const supplierId = this._product.supplier && this._product.supplier.id;

    if (internalSupplierIds && supplierId) {
      return internalSupplierIds.includes(supplierId);
    }

    return false;
  }

  lowestPartPaymentPrice(quantity) {
    return (this.totalPriceWithVat(quantity) * this.lowestAnnuityFactor).toFixed(0);
  }

  get lowestAnnuityFactor() {
    const factors = this.annuityFactors.map(i => i.factor);
    return Math.min(...factors);
  }

  get annuityFactors() {
    const { annuityFactors } = window.EonConfig;

    return annuityFactors;
  }

  get images() {
    return this._product.images || [];
  }

  get imageThumbnailUrl() {
    const { image } = this._product;
    return image ? image.webshop_thumb : null;
  }

  get imageUrl() {
    const { image } = this._product;
    return image ? image.original : null;
  }

  get permalink() {
    const { encodedName, encodedId, typeId } = this;
    const baseUrl = window.EonConfig.productBaseUrl;
    const productTypeSlug = EonProductTypeSlugs[typeId];

    return `${baseUrl}/${productTypeSlug}/${encodedName}-${encodedId}`;
  }

  get discount() {
    return this._product.discount;
  }

  get gtmVariantName() {
    return this.isRim ? this.rimTypeDisplayName : this.seasonDisplayName;
  }

  get supplierId() {
    return this._product.supplier.id;
  }

  get supplierName() {
    return this._product.supplier.name;
  }

  get tireData() {
    return {
      item_name: this.name,
      item_id: this.id,
      price: this.price,
      item_brand: this.brandName,
      item_category: this.productTypeDisplayName,
      item_variant: this.gtmVariantName,
      item_category2: this.conditionType,
      location_id: `${this.supplierId} ${this.supplierName}`
    };
  }
}
