var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", { attrs: { href: _vm.product.permalink } }, [
    _c("div", { staticClass: "mini-product-spot" }, [
      _c(
        "div",
        {
          staticClass:
            "mini-product-spot__thumbnail mini-product-spot__thumbnail--rounded"
        },
        [
          _c("img", {
            attrs: { src: _vm.productImages[0], alt: "Produktbild" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "mini-product-spot__content mini-product-spot__content--mini"
        },
        [
          _c("div", { staticClass: "mini-product-spot__header" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.product.brandModelTitle) + "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mini-product-spot__sub-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.product.shortModelText) + "\n      "
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "price-tag",
                class: { "price-tag--discount": _vm.product.discount }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$formatPrice(_vm.product.currentPrice)) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _vm.product.discount
              ? _c("div", { staticClass: "price-tag price-tag--obsolete" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$formatPrice(_vm.product.ordinaryPrice)) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }