var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-pages__section" },
    [
      _c("div", { staticClass: "my-pages__section__title-container" }, [
        _c("span", { staticClass: "my-pages__section__title" }, [
          _vm._v(
            _vm._s(_vm.sectionData ? _vm.sectionData.length : 0) +
              " BEVAKNINGAR"
          )
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.sectionData, function(subscription) {
        return _c(
          "div",
          { key: subscription.id, staticClass: "section-item" },
          [
            _c(
              "div",
              {
                staticClass: "section-item__tag-container",
                on: {
                  click: function($event) {
                    return _vm.$emit(
                      "clickSubscription",
                      subscription.searchString
                    )
                  }
                }
              },
              _vm._l(subscription.tags, function(tag, index) {
                return _c(
                  "div",
                  {
                    key: tag,
                    staticClass: "section-item__tag",
                    class: { "section-item__tag--first-item": index === 0 }
                  },
                  [
                    _c("span", { staticClass: "section-item__tag__text" }, [
                      _vm._v(_vm._s(tag))
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "section-item__subscription-delete",
                on: {
                  click: function($event) {
                    return _vm.$emit("deleteSubscription", subscription.id)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-trash" })]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }