var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "service-booking-selector--container" }, [
      _c(
        "div",
        {
          staticClass: "form-step",
          class: {
            "form-step--completed": _vm.validCarInfo && _vm.selectedDepartment
          }
        },
        [
          _c("div", { staticClass: "service-booking-selector--content" }, [
            _c(
              "div",
              {
                staticClass: "service-booking-selector",
                class: {
                  "service-booking-selector--readonly": _vm.readOnlyMode
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "service-booking-selector__columns",
                    class: {
                      "service-booking-selector__columns--readonly":
                        _vm.readOnlyMode,
                      "service-booking-selector__columns--hidden": !_vm.showSelectService
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "service-booking-selector__column service-booking-selector__column--service",
                        class: {
                          "service-booking-selector__column--readonly":
                            _vm.readOnlyMode,
                          "service-booking-selector__column--notify":
                            _vm.selectServiceNotifier,
                          "service-booking-selector__column--hidden": !_vm.showSelectService
                        }
                      },
                      [
                        !_vm.readOnlyMode
                          ? _c(
                              "VcFormStack",
                              { attrs: { label: "Tjänst" } },
                              [
                                _c("VcFormSelect", {
                                  attrs: {
                                    options: _vm.availableServices,
                                    value: _vm.selectedService,
                                    prompt: "Välj verkstadstjänst",
                                    labelProp: "name",
                                    valueProp: "value",
                                    valueType: "string",
                                    tabindex: 100
                                  },
                                  on: { change: _vm.onServiceChange }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("FontAwesomeIcon", {
                                  attrs: { icon: _vm.faCheckLight }
                                }),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.selectedService.name) +
                                    "\n                "
                                )
                              ],
                              1
                            )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "service-booking-selector__columns__main-actions"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "service-booking-selector__column service-booking-selector__column--regnr",
                            class: {
                              "service-booking-selector__column--readonly":
                                _vm.readOnlyMode,
                              "service-booking-selector__column--notify":
                                _vm.selectRegnrNotifier
                            }
                          },
                          [
                            !_vm.readOnlyMode
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "regnr-input",
                                    on: {
                                      click: function($event) {
                                        return _vm.onRegNrClick()
                                      }
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "input-group" }, [
                                      _vm._m(0),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "form-stack" }, [
                                        _c("label", [
                                          _vm._v(
                                            "\n                          Regnummer\n                        "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "regNr-input",
                                            type: "text",
                                            placeholder: "ABC123",
                                            required: "",
                                            disabled: !_vm.serviceCompleted,
                                            pattern:
                                              "^\\w{3}\\d{2}(\\w{1}|\\d{1})$",
                                            tabindex: 101
                                          },
                                          domProps: {
                                            value: _vm.selectedRegNr
                                          },
                                          on: {
                                            input: function(evt) {
                                              return (_vm.selectedRegNr =
                                                evt.target.value)
                                            },
                                            keyup: _vm.onRegNrChange
                                          }
                                        })
                                      ])
                                    ])
                                  ]
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("FontAwesomeIcon", {
                                      attrs: { icon: _vm.faCheckLight }
                                    }),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.selectedRegNr) +
                                        "\n                  "
                                    )
                                  ],
                                  1
                                )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "service-booking-selector__column service-booking-selector__column--department",
                            class: {
                              "service-booking-selector__column--readonly":
                                _vm.readOnlyMode,
                              "service-booking-selector__column--invalid":
                                _vm.noAvailableDepartments
                            },
                            on: {
                              click: function($event) {
                                return _vm.onDepartmentClick()
                              }
                            }
                          },
                          [
                            !_vm.readOnlyMode
                              ? _c(
                                  "VcFormStack",
                                  { attrs: { label: "Anläggning" } },
                                  [
                                    _c("VcFormSelect", {
                                      attrs: {
                                        options: _vm.availableDepartments,
                                        value: _vm.selectedDepartment,
                                        disabled: _vm.departmentSelectDisabled,
                                        prompt: _vm.selectDepartmentPrompt,
                                        invalid: _vm.noAvailableDepartments,
                                        labelProp: "name",
                                        valueProp: "value",
                                        valueType: "string",
                                        tabindex: 102
                                      },
                                      on: { change: _vm.onDepartmentChange }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "regnr-input__tooltip regnr-input__tooltip--error",
                                        class: {
                                          "regnr-input__tooltip--visible":
                                            (_vm.selectedDepartment &&
                                              _vm.selectedDepartment.message) ||
                                            _vm.showFetchedDepartmentError
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              (_vm.selectedDepartment &&
                                                _vm.selectedDepartment
                                                  .message) ||
                                                _vm.lang.Lookup.NoDepartment
                                            ) +
                                            "\n                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("FontAwesomeIcon", {
                                      attrs: { icon: _vm.faCheckLight }
                                    }),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.selectedDepartment.name) +
                                        "\n                  "
                                    )
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "service-booking-selector__column service-booking-selector__column--submit",
                        class: {
                          "service-booking-selector__column--readonly":
                            _vm.readOnlyMode
                        }
                      },
                      [
                        !_vm.readOnlyMode && !_vm.fromSpecificServicePage
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn--primary btn--block",
                                attrs: { disabled: _vm.btnDisabled },
                                on: {
                                  click: function($event) {
                                    return _vm.moveOn()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  Gå vidare med bokning\n                "
                                )
                              ]
                            )
                          : !_vm.readOnlyMode
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn--primary btn--block",
                                attrs: { disabled: _vm.btnDisabled },
                                on: {
                                  click: function($event) {
                                    return _vm.moveOnInternal()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  Fortsätt\n                "
                                )
                              ]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "btn btn--outline btn--xs",
                                on: {
                                  click: function($event) {
                                    _vm.readOnlyMode = !_vm.readOnlyMode
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                  Ändra\n                "
                                )
                              ]
                            )
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            (_vm.selectedDepartment && _vm.selectedDepartment.message) ||
            _vm.showFetchedDepartmentError
              ? _c(
                  "div",
                  { staticClass: "feedback feedback--error feedback--mobile" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          (_vm.selectedDepartment &&
                            _vm.selectedDepartment.message) ||
                            _vm.lang.Lookup.NoDepartment
                        ) +
                        "\n        "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.validCarInfo
              ? _c("div", { staticClass: "feedback feedback--success" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.carInfoDisplayName) +
                      "\n        "
                  )
                ])
              : _c("div", { staticClass: "feedback feedback--standard" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.startMessage) + "\n        "
                  )
                ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }