import qs from 'query-string';
import mountApp from "../utils/mount-app";
import Checkout from '../apps/checkout';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    this.mountCheckout();
  },

  mountCheckout() {
    mountApp({
      app: Checkout,
      selector: '[data-app=checkout]',
      props: {
        paymentError: qs.parse(window.location.search).paymentError === 'true',
      },
    });
  },
};
