<template>
  <div class="swiper-container" ref="swiperContainer">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="product in products" :key="product.id">
        <ProductSpot
          :product="product"
          card
          :is-private-store="true"
        >
        </ProductSpot>
      </div>
    </div>
  </div>
</template>

<script>
import I3DocProduct from "../../models/I3DocProduct";
import Breakpoints from "../../constants/Breakpoints";
import ProductSpot from "../vehicle-list/ProductSpot.vue";
import Swiper from "../../swiper";

export default {
  props: {
    documents: Array,
    default: () => [],
    slimList: Boolean
  },
  components: {
    ProductSpot
  },
  data() {
    return {
      swiper: null
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiperContainer, {
      slidesPerView: this.slimList ? 3 : 4,
      spaceBetween: 16,
      breakpoints: {
        [Breakpoints.xl - 2]: {
          slidesPerView: this.slimList ? 3 : 4,
          spaceBetween: 16
        },
        [Breakpoints.md - 2]: {
          slidesPerView: 3,
          spaceBetween: 16
        },
        [Breakpoints.sm - 2]: {
          slidesPerView: "auto",
          spaceBetween: 16
        }
      }
    });
  },
  updated() {
    this.swiper.update();
  },
  computed: {
    products() {
      return this.documents.map(doc => new I3DocProduct(doc));
    }
  }
};
</script>
