var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-list__item", on: { click: _vm.onClick } },
    [
      _c("ProductSpot", {
        attrs: {
          product: _vm.product,
          selected: _vm.selected,
          loading: _vm.loading,
          savedVehicles: _vm.savedVehicles,
          interactive: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }