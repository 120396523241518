<template>
  <input type="number"
    class="form-control"
    :value="field.value"
    :placeholder="field.placeholder"
    @change="$emit('change', $event.target.value)">
</template>

<script>
export default {
  props: {
    field: Object,
  },
}
</script>
