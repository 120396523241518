<template>
  <div
    class="configurator-select"
  >
    <div class="configurator-select__button">
      <div v-if="heading" class="configurator-select__heading">{{ heading }}</div>
      <div class="configurator-select__container">
        <div class="configurator-select__input configurator-select__input--date">
          <div class="datepicker">
            <input
              class="form-control js-datepicker"
              name="requestDate"
              data-allowsaturday="true"
              :placeholder="placeholder"
              required
              :value="defaultValue"
              @change="onChange"
            />
            <font-awesome-icon class="datepicker__icon configurator-select__input__icon" :icon="faCalendar"></font-awesome-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';
import ConfiguratorSimpleDropdownItem from './ConfiguratorSimpleDropdownItem.vue';
import ConfiguratorDepartmentDropdownItem from './ConfiguratorDepartmentDropdownItem.vue';
import { faCalendar, faSort } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    VcFormStack,
    ConfiguratorSimpleDropdownItem,
    ConfiguratorDepartmentDropdownItem,
    FontAwesomeIcon,
},
  props: {
    options: Array,
    heading: String,
    defaultValue: String,
    isDepartmentSelect: Boolean,
    defaultPlaceholder: String,
  },
  data() {
    return {
      selectedValue: null,
      faSort,
      faCalendar,
    }
  },
  computed: {
    placeholder() {
      return this.defaultPlaceholder || 'Välj';
    }
  },
  methods: {
    onChange(e) {
      const value = e.target.value;
      this.selectedValue = value;
      this.$emit('change', value);
    },
  }
}
</script>
