var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blog-list" },
    [
      _c("div", { staticClass: "blog-list__header" }, [
        _c("div", { staticClass: "blog-list__header__title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$formatNumber(_vm.hits)) + " artiklar\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "blog-list__header__sorting",
            on: { click: _vm.setSortingDirection }
          },
          [
            _vm._v("\n      Senaste\n      "),
            _c("FontAwesomeIcon", {
              staticClass: "blog-list__header__icon",
              attrs: {
                icon: _vm.sortByLatest ? _vm.faChevronDown : _vm.faChevronUp
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "campaign-list__items" },
        _vm._l(_vm.articles, function(article) {
          return _c("ArticleSpot", {
            key: article.id,
            attrs: { article: article }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("VcIntersectObserver", {
        attrs: { options: { rootMargin: "200px" } },
        on: {
          intersect: function($event) {
            return _vm.$emit("onNext")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }