import { render, staticRenderFns } from "./StarList.vue?vue&type=template&id=c6b2fbbe&"
import script from "./StarList.vue?vue&type=script&lang=js&"
export * from "./StarList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\agent\\_work\\1\\s\\src\\Holmgrens.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c6b2fbbe')) {
      api.createRecord('c6b2fbbe', component.options)
    } else {
      api.reload('c6b2fbbe', component.options)
    }
    module.hot.accept("./StarList.vue?vue&type=template&id=c6b2fbbe&", function () {
      api.rerender('c6b2fbbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/apps/reviews-widget/StarList.vue"
export default component.exports