<template>
  <div class="configurator-section tyre-selector">
    <div class="configurator-section__title-container">
      <h4 class="configurator-section__title">
        <i class="fal fa-tire configurator-section__icon"></i>
        {{ tyreData.heading }}
      </h4>
      <a
        v-if="tyreData.informationModalUrl"
        class="link link--muted-light link--underline"
        href="#"
        :data-target="tyreData.informationModalUrl"
        data-modal-source="ajax"
      >
        {{ lang.SiteGeneral.ReadMore }}
      </a>
    </div>
    <VcOptionBox
      :selected="!showTyreOptions"
      :heading="tyreData.denyOption"
      @click="onHideTyreOptions"
    ></VcOptionBox>
    <VcOptionBox
      :selected="showTyreOptions"
      :heading="tyreData.acceptOption"
      @click="onShowTyreOptions"
    ></VcOptionBox>
    <div
      v-if="showTyreOptions && hasTyreOptions"
      class="tyre-selector__up-sell"
    >
      <ProductUpsell
        :options="tyreOptions"
        :heading="'Vinterdäckserbjudande'"
        @optionChange="onOptionChange"
        :selectedFinance="selectedFinancingOption"
        :lang="lang"
      />
    </div>
  </div>
</template>

<script>
import ProductUpsell from "./VehicleConfiguratorUpsell.vue";
import { VcOptionBox, VcModal, VcPriceTag } from "@holmgrensbil/vue-components";
import store from "./store";
import Axios from "../../axios";

export default {
  components: {
    ProductUpsell,
    VcOptionBox
  },
  props: {
    tyreData: Object,
    selectedFinancingOption: String,
    lang: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showTyreOptions: false,
      hasTyreOptions: false,
      selectedTyre: null
    };
  },
  computed: {
    storeState() {
      return store.state;
    },
    tyreOptions() {
      const tyres = this.storeState.tyres;
      return tyres;
    }
  },
  methods: {
    onOptionChange(tyre) {
      store.alterTyreOptions(tyre);
    },
    onShowTyreOptions() {
      this.showTyreOptions = true;
      store.setNeedTyres(true);
    },
    async fetchTyreOptions() {
      try {
        const calculateFinanceOptions = this.storeState.calculateFinanceOptions;
        const financingTemplateId = this.storeState.financingConfig
          .financingTemplateId;

        const { data } = await Axios.post(
          `/api/vehicleConfigurator/${this.storeState.regNr}/tyres`,
          {
            ...calculateFinanceOptions,
            financingTemplateId
          }
        );
        store.setTyresAction(data);
        this.hasTyreOptions = data && data.length > 0;
      } catch (e) {
        console.error(e);
      }
    },
    onHideTyreOptions() {
      this.showTyreOptions = false;
      store.setNeedTyres(false);
      //store.setTyresAction([]);
    }
  },
  mounted() {
    this.fetchTyreOptions();
  }
};
</script>
