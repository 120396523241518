<template>
  <div>
    <a class="btn btn--primary" @click="onExternalClick" target="_blank" :href="externalUrl">
      Gå till bokning
    </a>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import gtm from '../../utils/gtm';
import FieldTypes from './constants/FieldTypes';
export default {
  computed: {
    ...mapState({
      externalUrl: state => state.isExternal.value && state.isExternal.url,
    }),
    ...mapGetters({
      fieldByType: 'fields/byType'
    })
  },
  methods: {
    onExternalClick() {
      const departmentNr = this.fieldByType(FieldTypes.Department).value.value;
      gtm.serviceBookingExternalClick({ departmentNr });
    }
  }
}
</script>
