var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "build-tyre__section",
      class: {
        "build-tyre__section--selected": _vm.selected,
        "build-tyre__section--addon": _vm.isAddon
      }
    },
    [
      _c("div", { staticClass: "build-tyre__section__header" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "build-tyre__content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }