var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "swiperContainer", staticClass: "swiper-container" },
    [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.products, function(product) {
          return _c(
            "div",
            { key: product.id, staticClass: "swiper-slide" },
            [
              _c("ProductSpot", {
                attrs: { product: product, card: "", "is-private-store": true }
              })
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }