<template>
  <VcModal @close="$emit('modalClose')">
    <div slot="header">
      {{modalHeader}}
    </div>
    <div slot="content" class="choose-dimensions">
      <SelectManual v-if="manualSelect"
        :tyreSize="tyreSize"
        @change="onChange">
      </SelectManual>
      <SelectRecommended v-else
        :sizes="TyreSpec.recommendedSizes"
        :tyreSize="tyreSize"
        :carName="TyreSpec.carName"
        @change="onChange">
      </SelectRecommended>
      <div class="btn btn--primary btn--block"
        :class="{'disabled': buttonDisabled}"
        @click="onSubmit">
        {{buttonText}}
      </div>
      <p class="form-text">
        När man byter tum-storlek och endast köper lösa däck är det viktigt att man kontrollerar så att däckets tum stämmer överens med ens nuvarande fälgar. Annars kommer det inte gå att montera däcket på den gamla fälgen.
      </p>
    </div>
  </VcModal>
</template>

<script>
import { VcModal } from '@holmgrensbil/vue-components';
import SelectManual from './SelectManual.vue';
import SelectRecommended from './SelectRecommended.vue';
import ChannelQuery from '../../models/ChannelQuery';
import EonQueryParams from '../../constants/EonQueryParams';
import { EventBus } from '../../utils/event-bus';

export default {
  components: {
    SelectManual,
    SelectRecommended,
    VcModal,
  },
  props: {
    TyreSpec: Object,
    modalHeader: String,
    buttonText: String,
    buttonHref: String,
    forceManual: Boolean,
    channel: Object,
  },
  data() {
    return {
      tyreSize: null,
    };
  },
  computed: {
    buttonDisabled() {
      return !(this.tyreSize.width && this.tyreSize.aspectRatio && this.tyreSize.diameter);
    },
    manualSelect() {
      return this.TyreSpec.recommendedSizes.length === 0 || this.forceManual;
    },
  },
  created() {
    this.tyreSize = { ...this.TyreSpec.tyreSize };
  },
  methods: {
    onChange(tyreSize) {
      this.tyreSize = tyreSize;
    },

    onSubmit() {
      if(this.manualSelect) {
        this.TyreSpec.clear();
      }
      this.TyreSpec.set({
        tyreSize: this.tyreSize,
      });
      if(this.channel) {
        const query = new ChannelQuery(this.channel.lastQuery);
        const params = [
          { name: EonQueryParams.Width, value: this.TyreSpec.tyreSize.width },
          { name: EonQueryParams.AspectRatio, value: this.TyreSpec.tyreSize.aspectRatio },
          { name: EonQueryParams.Diameter, value: this.TyreSpec.tyreSize.diameter },
        ];
        params.forEach(d => {
            query.setParam(d.name, d.value);
        });
        EventBus.$emit('set-filter', params)
        this.channel.execute(query);
        this.$emit('modalClose');
      } else {
        window.location.href = this.buttonHref;
      }

    },
  },
}
</script>
