<template>
  <div class="campaign-list__item campaign-list__item--large">
    <a :href="campaign.permalink">
      <div class="campaign-list__item__image">
        <img
          v-bind:src="backgroundImage"
          v-bind:alt="campaign.meta"
          loading="lazy"
        />
        <div v-if="campaign.sticker" class="sticker sticker--lg">
          <span class="sticker__text" v-html="campaign.sticker"></span>
        </div>
        <div class="product-spot__tags">
          <div
            class="product-tag"
            :class="[usp.Color]"
            v-for="usp in campaign.usps"
            :key="usp.Name"
          >
            <i v-if="usp.Icon" :class="[usp.Icon]"></i>
            {{ usp.Name }}
          </div>
          <div class="product-tag black" v-if="campaign.rollupCountSearchUrl">
            <i class="fas fa-circle" style="color: #ff4401;"></i>
            <span
              :data-i3-total-count-url="campaign.rollupCountSearchUrl"
            ></span>
            kvar i lager
          </div>
        </div>
      </div>
      <div class="campaign-list__item__content">
        <div class="campaign-list__item__logo">
          <img v-bind:src="campaign.brandImage" loading="lazy" />
        </div>
        <div class="campaign-list__item__meta">
          {{ campaign.meta }}
        </div>
        <h2 class="h3">{{ campaign.title }}</h2>
        <div v-html="campaign.text"></div>
        <button
          v-if="campaign.btnText && campaign.btnText.length"
          class="btn btn--secondary btn--block"
        >
          {{ campaign.btnText }}
        </button>
      </div>
    </a>
  </div>
</template>

<script>
import I3DocCampaign from "../../models/I3DocCampaign";
import ImageResizerPresets from "../../constants/ImageResizerPresets";

export default {
  props: {
    document: Object
  },
  created() {
    this.campaign = new I3DocCampaign(this.document);
  },
  computed: {
    backgroundImage() {
      return `${this.campaign.image}?${ImageResizerPresets.GridItemPromoImage}`;
    }
  }
};
</script>
