var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-stack",
      class: { "form-stack--required": _vm.field.required }
    },
    [
      _c("label", [_vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        class: { "form-control--invalid": _vm.invalid },
        attrs: {
          type: _vm.field.inputType,
          placeholder: _vm.field.placeholder,
          autocomplete: _vm.field.autocomplete,
          required: _vm.field.required,
          readonly: _vm.field.readonly
        },
        domProps: { value: _vm.field.value },
        on: {
          blur: function($event) {
            return _vm.$emit("blur")
          },
          keyup: function($event) {
            return _vm.onFieldChange(_vm.field.type, $event.target.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }