import SectionTypes from "../constants/SectionTypes";

export default [
  {
    order: 1,
    type: SectionTypes.Lookup,
    inValuation: true,
  },
  {
    order: 2,
    type: SectionTypes.Details
  },
  {
    order: 3,
    type: SectionTypes.Images
  },
  {
    order: 4,
    type: SectionTypes.Contact
  }
];
