var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.facet
    ? _c("div", { staticClass: "i3-filter-toggle toggle-switch-button" }, [
        _c("span", [_vm._v("\n    " + _vm._s(_vm.facet.displayName) + "\n  ")]),
        _vm._v(" "),
        _c("div", { staticClass: "toggle-switch" }, [
          _c("input", {
            attrs: { type: "checkbox", id: _vm.inputId },
            domProps: {
              value: _vm.onFilter.displayName,
              checked:
                _vm.onFilter.displayName === _vm.selectedFilter.displayName
            },
            on: { change: _vm.onFilterToggle }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: _vm.inputId } })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }