<template>
  <div class="configurator-section accessories-selector">
<!-- OPTION BOXES WITH FITTINGS AND CHARGE BOX IF THEIR DISPLAY IS TRUE -->
    <div v-if="displayChargeBox" class="accessories-selector__input-row">
      <div class="accessories-selector__heading">
        Laddbox
        <a
          v-if="chargeBoxData.informationModalUrl"
          class="accessories-selector__link link link--underline"
          href="#"
          :data-target="chargeBoxData.informationModalUrl"
          data-modal-source="ajax"
        >{{ lang.SiteGeneral.ReadMore }}</a>
      </div>
      <div>
        <ConfiguratorCheckbox
          text="Ja, jag vill ha prisförslag på laddbox"
          :defaultChecked="needChargeBox"
          @change="setChargeBox"
        />
      </div>
    </div>
    <div v-if="displayFittings" class="accessories-selector__input-row">
      <div v-if="fetchedFittings">
        <ConfiguratorSubSelect
          :options="fittingsList"
          heading="Inredning"
          @change="onChangeFittings"
          :defaultValue="fittingsList[0].value"
        />
        <div v-if="selectedFitting.modalContent" class="accessories-selector__link-container">
          <a
            class="accessories-selector__link link link--underline"
            @click="(e) => onOpenModal(e, selectedFitting)"
          >{{ `Läs mer om inredning: ${selectedFitting.heading}` }}</a>
        </div>
      </div>
    </div>
    <VcModal v-if="modalOpen" @close="onCloseModal">
      <div slot="header">
        {{ modalHeader }}
      </div>
      <div slot="content" v-html="modalContent"></div>
    </VcModal>
  </div>
</template>
<script>
import store from '../store';
import ConfiguratorCheckbox from '../configurator-components/ConfiguratorCheckbox.vue';
import ConfiguratorSubSelect from '../configurator-components/ConfiguratorSubSelect.vue';
import Axios from 'axios';
import { VcModal } from "@holmgrensbil/vue-components";

export default {
  props: {
    lang: Object,
  },
  components: {
    ConfiguratorCheckbox,
    ConfiguratorSubSelect,
    VcModal,
  },
  data() {
    return {
      fetchedFittings: null,
      modalOpen: false,
      modalHeader: '',
      modalContent: '',
    };
  },
  mounted() {
    if (this.displayFittings) {
      this.fetchFittings();
    }
  },
  computed: {
    storeState() {
      return store.state;
    },
    displayFittings() {
      return this.storeState.fittings?.display;
    },
    displayChargeBox() {
      return this.storeState.chargeBox?.display;
    },
    chargeBoxData() {
      return this.storeState.chargeBox;
    },
    needChargeBox() {
      return this.storeState.needChargeBox;
    },
    fittingsList() {
      if (this.fetchedFittings) {
        return this.fetchedFittings.map(fitting => {
          return {
            name: fitting.heading,
            value: fitting.id,
          }
        })
      } else {
        return [];
      }
    },
    selectedFitting() {
      return this.fetchedFittings ? this.fetchedFittings.find(fitting => fitting.selected) : null;
    }
  },
  methods: {
    async fetchFittings() {
      const financingTemplateId = store.state.financingTemplateId;

      const { data } = await Axios.post(`/api/vehicleConfigurator/${this.storeState.regNr}/fittings`, {
        financingTemplateId,
      })

      this.fetchedFittings = data;

      store.setFittingOptions(data);
    },
    setChargeBox(checked) {
      store.setNeedChargeBox(checked);
    },
    onChangeFittings(selectedFitting) {
      const fetchedFittins = this.fetchedFittings.map(fitting => {
        return {
          ...fitting,
          selected: fitting.id === selectedFitting.value ? true : false,
        }
      })
      this.fetchedFittings = fetchedFittins;
      store.setFittingOptions(fetchedFittins);
    },
    onOpenModal(e, fitting) {
      e.preventDefault();
      this.modalOpen = true;
      this.modalHeader = fitting.heading;
      this.modalContent = fitting.modalContent;
    },
    onCloseModal() {
      this.modalOpen = false;
      this.modalHeader = '';
      this.modalContent = '';
    }
  }
}
</script>
