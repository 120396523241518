<template>
  <div class="field-input-images__grid-item">
    <div v-if="imageUrl"
      class="file-thumbnail"
      :style="{'background-image': `url(${imageUrl}?${ImageResizerPresets.FileThumbnail}`}">
      <div class="file-thumbnail__delete"
        @click="onDeleteImage">
        <i class="fal fa-times"></i>
      </div>
    </div>
    <VcInputFile
      v-else
      :label="'Välj bild'"
      @change="onFileChange">
      <div slot="icon">
        <font-awesome-icon
          :icon="uploading ? faSpinnerThird : faCameraAlt"
          :spin="uploading ? true : false">
        </font-awesome-icon>
      </div>
    </VcInputFile>
  </div>
</template>

<script>
import { VcInputFile } from '@holmgrensbil/vue-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird, faCameraAlt } from "@fortawesome/pro-light-svg-icons";
import { uploadImage, deleteImage } from '../../utils/image-service';
import ImageResizerPresets from '../../constants/ImageResizerPresets';

export default {
  components: {
    VcInputFile,
    FontAwesomeIcon,
  },
  props: {
    regNr: String,
    i: Number,
  },
  data() {
    return {
      uploading: false,
      faSpinnerThird,
      faCameraAlt,
      imageUrl: '',
      deleteKey: null,
      ImageResizerPresets,
    }
  },
  mounted() {

  },
  methods: {
    onFileChange(file) {
      if (!this.regNr) {
        this.$emit('onError', null)
        return;
      }
      const folder = this.regNr;

      this.uploading = true;
      uploadImage(file, folder).then(res => {
        const {
          imageUrl,
          deleteKey,
        } = res.data;

        this.deleteKey = deleteKey;
        this.uploading = false;
        this.imageUrl = imageUrl
        this.$emit('onChangeImage', { [this.i]: imageUrl });
      });
    },
    onDeleteImage() {
      deleteImage(this.deleteKey);
      this.imageUrl = '';
      this.$emit('onDeleteImage', this.i);
    },
  }
}
</script>