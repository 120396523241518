import Axios from "../../../../axios";

const state = {};

const getters = {};

const actions = {
  load({ commit, dispatch }, config) {
    commit('received', config);
    dispatch('brands/load', config.brands, { root: true });
    dispatch('departments/load', config.departments, { root: true });
    dispatch('sections/load', config.sections, { root: true });
    dispatch('services/load', config.services, { root: true });
  },

  async fetch({ dispatch }) {
    const res = await Axios.get('/api/Contact/GetContactData');
    dispatch('load', res.data);
  },
};

const mutations = {
  received(state, config) {
    Object.assign(state, config);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
