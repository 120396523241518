var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.field.label
        ? _c("label", [_vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.field.options, function(option) {
        return _c(
          "div",
          {
            key: option.value,
            staticClass: "form-check form-check--sm form-check--inline",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.onChange(option)
              }
            }
          },
          [
            _c("input", {
              staticClass: "form-check__input",
              attrs: { type: "checkbox" },
              domProps: { checked: _vm.isSelected(option) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "form-check__label" }, [
              _vm._v("\n      " + _vm._s(option.name) + "\n    ")
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }