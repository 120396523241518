var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cart-upsell-item",
      class: { "cart-item--loading": _vm.loading }
    },
    [
      _c("div", { staticClass: "cart-upsell-item__main" }, [
        _vm.product.imageUrl
          ? _c("img", { attrs: { src: _vm.product.imageUrl } })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "cart-upsell-item__product-name" }, [
          _vm._v("\n      " + _vm._s(_vm.product.name) + "\n      "),
          _vm.product.description
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass:
                      "link link--underline link--muted-light link--icon",
                    on: {
                      click: function($event) {
                        _vm.modalOpen = !_vm.modalOpen
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "u-fw-regular" }, [
                      _vm._v("Mer info")
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cart-upsell-item__actions" }, [
        _c(
          "div",
          { staticClass: "cart-upsell-item__price" },
          [
            _c("VcPriceTag", {
              attrs: { price: _vm.product.price, showIfZero: "" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cart-upsell-item__quantity" },
          [
            _c("VcFormSelect", {
              attrs: {
                options: _vm.quantityOptions,
                value: _vm.quantityValue,
                labelProp: "name",
                valueProp: "value",
                valueType: "number",
                small: ""
              },
              on: { change: _vm.onQuantityChange }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cart-upsell-item__action" }, [
          _c(
            "a",
            {
              staticClass: "btn btn--white btn--icon btn--sm btn--block",
              on: { click: _vm.onAddClick }
            },
            [
              _vm._v("\n        Lägg till\n        "),
              _c("FontAwesomeIcon", { attrs: { icon: _vm.faPlus } })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm.modalOpen
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.modalOpen = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(_vm._s(_vm.product.name))
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.product.modalContent) },
                slot: "content"
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }