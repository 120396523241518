var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "site-header__search" },
    [
      _c(
        "div",
        {
          staticClass: "site-header__search__input",
          class: { "site-header__search__input--open": _vm.searchOpen }
        },
        [
          _c("VcSearchBar", {
            attrs: {
              placeholder: "Sök fordon, anläggningar och mer...",
              noInputBorders: "",
              disableCancel: _vm.disableSearchCancel
            },
            on: {
              input: _vm.onSearchInput,
              focus: _vm.onFocus,
              submit: function($event) {
                return _vm.onClickAllResults(true)
              }
            },
            model: {
              value: _vm.searchString,
              callback: function($$v) {
                _vm.searchString = $$v
              },
              expression: "searchString"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "search-field" } }, [
        _vm.searchOpen
          ? _c("div", { staticClass: "search-content" }, [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "search-content__loading" },
                    [
                      _c("FontAwesomeIcon", {
                        staticClass: "search-content__loading__spinner",
                        attrs: { icon: _vm.faSpinnerThird, spin: true }
                      })
                    ],
                    1
                  )
                : _vm.showSearchResultContent && !_vm.searchHits
                ? _c(
                    "div",
                    { staticClass: "search-content__results__popular" },
                    [
                      _c(
                        "div",
                        { staticClass: "search-content__results__heading" },
                        [_vm._v("\n          Populära sökningar\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "search-content__results__popular__list"
                        },
                        _vm._l(_vm.popularSearches, function(search) {
                          return _c(
                            "div",
                            {
                              key: search.query,
                              staticClass:
                                "search-content__results__popular__item",
                              on: {
                                click: function($event) {
                                  return _vm.onPopularSearchSelect(search)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(search.name) +
                                  "\n          "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      !_vm.searchHits && _vm.hasMadeSearches
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "search-content__results__popular__empty"
                            },
                            [
                              _vm._v(
                                "\n          Hittade inget resultat\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : !_vm.searchHits
                ? _c("div", { staticClass: "search-content__empty" }, [
                    _vm._v("\n        Hittade inget resultat\n      ")
                  ])
                : !_vm.loading &&
                  ((_vm.carResults.documents &&
                    _vm.carResults.documents.length > 0) ||
                    (_vm.pageResults.documents &&
                      _vm.pageResults.documents.length > 0) ||
                    // (departmentResults.documents &&
                    //   departmentResults.documents.length > 0) ||
                    (_vm.employeeResults.documents &&
                      _vm.employeeResults.documents.length > 0))
                ? _c("div", [
                    _c("div", { staticClass: "search-content__results" }, [
                      _vm.carResults.documents.length > 0
                        ? _c(
                            "div",
                            { staticClass: "search-content__results__column" },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "search-content__results__heading"
                                  },
                                  [
                                    _vm._v(
                                      "\n                Fordon\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "search-content__results__list"
                                  },
                                  _vm._l(
                                    _vm.carResults.documents.slice(0, 4),
                                    function(document) {
                                      return _c(
                                        "div",
                                        {
                                          key: document.id,
                                          staticClass:
                                            "search-content__results__column__product-spot"
                                        },
                                        [
                                          _c("ResultItemCar", {
                                            attrs: { document: document }
                                          })
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ]),
                              _vm._v(" "),
                              _vm.carResults.documents.length > 4
                                ? _c(
                                    "div",
                                    { staticClass: "search-content__btn" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "btn btn--primary btn--block",
                                          on: {
                                            click: function($event) {
                                              return _vm.onClickAllResults()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                "Visa alla bilar (" +
                                                  _vm.carResults.numberOfHits +
                                                  ")"
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "search-content__results__column search-content__results__column--scrollable"
                        },
                        [
                          _vm.facetPageResults.documents.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "search-content__results__section"
                                },
                                _vm._l(_vm.facetPageResults.documents, function(
                                  document
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: document.id,
                                      staticClass:
                                        "search-content__results__column__search-spot"
                                    },
                                    [
                                      _c("ResultItem", {
                                        attrs: {
                                          document: document,
                                          resultType:
                                            _vm.I3FederatedSearchTypes
                                              .FacetPages
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pageResults.documents.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "search-content__results__section"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "search-content__results__heading"
                                    },
                                    [
                                      _vm._v(
                                        "\n                Sidor\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.pageResults.documents, function(
                                    document
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: document.id,
                                        staticClass:
                                          "search-content__results__column__search-spot"
                                      },
                                      [
                                        _c("ResultItem", {
                                          attrs: {
                                            document: document,
                                            resultType:
                                              _vm.I3FederatedSearchTypes
                                                .Episerver
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.employeeResults.documents.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "search-content__results__section"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "search-content__results__heading search-content__results__heading--in-list"
                                    },
                                    [
                                      _vm._v(
                                        "\n                Personal\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.employeeResults.documents,
                                    function(document) {
                                      return _c(
                                        "div",
                                        {
                                          key: document.id,
                                          staticClass:
                                            "search-content__results__column__search-spot"
                                        },
                                        [
                                          _c("ResultItem", {
                                            attrs: {
                                              document: document,
                                              resultType:
                                                _vm.I3FederatedSearchTypes
                                                  .Employees
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }