var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit($event)
        }
      }
    },
    [
      _c("label", [_vm._v("\n    Logga in med BankID\n  ")]),
      _vm._v(" "),
      _c("VcFormStack", { attrs: { label: "Personnummer", required: "" } }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.ssn,
              expression: "ssn"
            }
          ],
          staticClass: "form-control",
          attrs: { placeholder: "ÅÅÅÅMMDD-XXXX", required: "", type: "text" },
          domProps: { value: _vm.ssn },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.ssn = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn--primary btn--block",
          attrs: { type: "submit" }
        },
        [_vm._v("\n    Logga in med BankID\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }