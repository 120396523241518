var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-group" },
    [
      _c(
        "VcFormStack",
        { attrs: { label: _vm.field.label, required: _vm.field.required } },
        [
          _c("input", {
            staticClass: "form-control",
            class: { "form-control--invalid": _vm.invalid },
            attrs: {
              placeholder: _vm.field.placeholder,
              autocomplete: _vm.field.autocomplete,
              required: _vm.field.required,
              type: _vm.type,
              min: _vm.min
            },
            domProps: { value: _vm.field.value },
            on: {
              change: function($event) {
                return _vm.$emit("change", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.field.tooltip
            ? _c("i", {
                staticClass: "form-tooltip ttjs far fa-question-circle",
                attrs: { "data-ttjs-title": _vm.field.tooltip }
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--primary btn--sm",
            on: {
              click: function($event) {
                return _vm.$emit("clickchange", _vm.field.value)
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.field.appendButtonText))]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }