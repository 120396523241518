<template>
  <div>
    <UpsellProduct v-for="upsellProduct in upsellProducts"
      :key="upsellProduct.id"
      @added="onAdded"
      :product="upsellProduct">
    </UpsellProduct>
  </div>
</template>

<script>

import UpsellProduct from './UpsellProduct.vue';

export default {
  components: {
    UpsellProduct,
  },

  props: {
    upsellProducts: Array,
  },

  methods: {
    onAdded(product) {
      this.$emit('added', product);
    }
  }
};
</script>
