<template>
  <div class="campaign-configurator__config-group" v-if="visibleCarConfigurationGroupOptions.length">
    <label>
      {{group.heading}}
    </label>

    <div class="form-row">
      <div v-for="option in visibleCarConfigurationGroupOptions"
        class="form-row__col"
        :key="option.parameterName">
        <ConfigGroupOption
          :option="option"
          :multiselect="multiselect"
          @optionChange="onOptionChange(option, $event)"
          @subOptionChange="onSubOptionChange(option, $event)">
        </ConfigGroupOption>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigGroupOption from '../shared/configurator/ConfigGroupOption.vue';
import store from './store';
import InputTypes from '../../constants/InputTypes';

export default {
  components: {
    ConfigGroupOption,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    multiselect() {
      return this.group.inputType === InputTypes.Checkbox;
    },
    visibleCarConfigurationGroupOptions() {
      return this.group.carConfigurationGroupOptions.filter(i => !i.hidden);
    },
  },
  methods: {
    onOptionChange(option) {
      store.setGroupOptionSelected(this.group, option, this.multiselect);
      this.$emit('change');
    },

    onSubOptionChange(option, { subOption, value }) {
      store.setGroupSubOptionSelected(this.group, option, subOption, value);
      this.$emit('change');
    },
  },
}
</script>
