var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-select" }, [
    _c("div", { staticClass: "configurator-select__button" }, [
      _vm.heading
        ? _c("div", { staticClass: "configurator-select__heading" }, [
            _vm._v(_vm._s(_vm.heading))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "configurator-select__container" }, [
        _c(
          "div",
          {
            staticClass:
              "configurator-select__input configurator-select__input--date"
          },
          [
            _c(
              "div",
              { staticClass: "datepicker" },
              [
                _c("input", {
                  staticClass: "form-control js-datepicker",
                  attrs: {
                    name: "requestDate",
                    "data-allowsaturday": "true",
                    placeholder: _vm.placeholder,
                    required: ""
                  },
                  domProps: { value: _vm.defaultValue },
                  on: { change: _vm.onChange }
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  staticClass:
                    "datepicker__icon configurator-select__input__icon",
                  attrs: { icon: _vm.faCalendar }
                })
              ],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }