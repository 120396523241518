<template>
  <VcFormStack :label="title">
    <VcFormSelect
      :options="options"
      :value="selected"
      labelProp="name"
      valueProp="value"
      prompt="Inget märke valt"
      @change="select">
    </VcFormSelect>
  </VcFormStack>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { VcFormSelect, VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormSelect,
    VcFormStack,
  },
  computed: {
    ...mapState({
      options: state => state.brands.options,
      title: state => state.brands.title,
      selected: state => state.brands.selected,
    }),
  },
  methods: {
    ...mapActions({
      select: 'brands/select',
    }),
  }
}
</script>
