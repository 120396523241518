var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.option.name,
      staticClass: "configurator-select__department-item",
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.option)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "configurator-select__department-item__name-container",
          class: {
            "configurator-select__department-item__name-container--selected":
              _vm.selected
          }
        },
        [
          _vm.selected
            ? _c("font-awesome-icon", {
                staticClass: "configurator-select__department-item__check",
                attrs: { icon: _vm.faCheck }
              })
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.option.name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "configurator-select__availability-container" },
        [
          _c("div", {
            staticClass: "delivery-indicator",
            class: {
              "delivery-indicator--available": _vm.available,
              "delivery-indicator--unavailable": !_vm.available
            }
          }),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.available ? "Omgående" : "3-7 dagar"))])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }