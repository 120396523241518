<template>
  <div>
    <SearchWatch
      :header="header"
      :text="text"
      :tags="tags"
      :feedbackHeader="feedbackHeader"
      :feedbackText="feedbackText"
      :feedbackContactHeader="feedbackContactHeader"
      :feedbackContactText="feedbackContactText"
      :privacyPolicyText="lang.General.PrivacyPolicyText"
      :done="done"
      :loading="loading"
      :error="error"
      :brandToWatch="brandToWatch"
      @saveSearches="onSaveSearches"
    >
    </SearchWatch>
    <TheSearchWatchModal
      v-if="showModal"
      :email-found="emailFound"
      :email="email"
      :feedbackHeader="feedbackHeader"
      :feedbackText="feedbackText"
      :feedbackContactHeader="feedbackContactHeader"
      :feedbackContactText="feedbackContactText"
      :privacyPolicyText="lang.General.PrivacyPolicyText"
      :isContactSubmitProp="isContactSubmit"
      @close="close()"
    >
    </TheSearchWatchModal>
  </div>
</template>

<script>
import SearchWatch from "../shared/SearchWatch.vue";
import Axios from "../../axios";
import RequestStates from "../../constants/RequestStates";
import TheSearchWatchModal from "./TheSearchWatchModal.vue";
import gtm from "../../utils/gtm";
import { EventBus } from "../../utils/event-bus";
import Cookies from "js-cookie";

export default {
  components: {
    SearchWatch,
    TheSearchWatchModal
  },
  props: {
    lang: Object,
    queryFilters: Array,
    lastQuery: Object,
    brandToWatch: String,
    channel: Object
  },
  data() {
    return {
      submitState: null,
      showModal: false,
      emailFound: false,
      email: "",
      isContactSubmit: false
    };
  },
  mounted() {
    EventBus.$on(
      "isContactSubmit",
      isContact => (this.isContactSubmit = isContact)
    );
  },
  watch: {
    queryFilters() {
      this.submitState = null;
    }
  },
  computed: {
    isLoggedIn() {
      return window.CurrentUser !== undefined && window.CurrentUser !== null;
    },
    tags() {
      const parentTags = this.queryFilters.map(qf => qf.label);
      const childTags = [];
      this.queryFilters.map(
        qf => qf.children && qf.children.map(qfc => childTags.push(qfc.label))
      );
      return [...parentTags, ...childTags];
    },
    header() {
      if (this.isLoggedIn) {
        return this.lang.App.HeaderLoggedIn;
      } else if (this.channel?.data?.documents?.numberOfHits === 0) {
        return this.lang.App.NoHitsSearchWatchHeading;
      } else {
        return this.lang.App.Header;
      }
    },
    text() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || "";
        return this.lang.App.TextLoggedIn.replace("{{email}}", email);
      } else {
        return this.lang.App.Text;
      }
    },
    feedbackHeader() {
      if (this.isLoggedIn) {
        return this.lang.App.FeedbackHeaderLoggedIn;
      } else {
        return this.lang.App.FeedbackHeader;
      }
    },
    feedbackText() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || "";
        return this.lang.App.FeedbackTextLoggedIn.replace("{{email}}", email);
      } else {
        return this.lang.App.FeedbackText;
      }
    },
    feedbackContactHeader() {
      if (this.isLoggedIn) {
        return this.lang.App.FeedbackContactHeaderLoggedIn;
      } else {
        return this.lang.App.FeedbackContactHeader;
      }
    },
    feedbackContactText() {
      if (this.isLoggedIn) {
        const email = window.CurrentUser.email || "";
        return this.lang.App.FeedbackContactTextLoggedIn.replace(
          "{{email}}",
          email
        );
      } else {
        return this.lang.App.FeedbackContactText;
      }
    },
    done() {
      return this.submitState === RequestStates.Success;
    },

    loading() {
      return this.submitState === RequestStates.Request;
    },
    error() {
      return this.submitState === RequestStates.Failure;
    }
  },
  methods: {
    close() {
      this.emailFound = false;
      this.email = "";
      this.showModal = false;
    },
    onSubmit(email) {
      var site = Cookies.get("site");
      const data = {
        email,
        searchString: this.lastQuery.toString(),
        isCamping: site === "camping"
      };

      this.submitState = RequestStates.Request;

      Axios.post("/api/Vehicle/RegisterSearchCoverage", data).then(res => {
        if (res.data && res.data.isSuccess) {
          this.submitState = RequestStates.Success;
        }
      });
    },
    async onSaveSearches(email, isContact, departmentNr, brandName) {
      var site = Cookies.get("site");
      const searchString = this.channel.isUrlBased
        ? `${this.channel.defaultQuery.toString()}&${this.lastQuery.toString()}`
        : this.lastQuery.toString();

      try {
        this.submitState = RequestStates.Request;
        const res = await Axios.post("/api/MyPages/searches", {
          email,
          searchString,
          contact: isContact,
          departmentNr,
          isCamping: site === "camping"
        });
        this.submitState = RequestStates.Success;
        if (res?.data?.emailFound) {
          this.emailFound = true;
          this.email = email;
          this.showModal = true;
        } else if (res?.data?.emailFound === false) {
          this.emailFound = false;
          this.email = email;
          this.showModal = true;
        }
        gtm.submitSearchWatch();
        if (isContact) {
          EventBus.$emit("isContactSubmit", true);
          gtm.submitSearchWatchContact({ departmentNr, brandName });
        }
      } catch (error) {
        this.submitState = RequestStates.Failure;
      }
    }
  }
};
</script>
