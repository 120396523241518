import Swiper from "../swiper";

const selectors = {
  el: ".gallery-slideshow",
  main: ".gallery-slideshow__main",
  mainSwiper: ".gallery-slideshow__main .swiper-container",
  thumbs: ".gallery-slideshow__thumbs",
  thumbsSwiper: ".gallery-slideshow__thumbs .swiper-container",
  productImages:
    ".product-images .swiper-container .swiper-wrapper .swiper-slide",
  targetIndex: ".swiper-slide-active"
};

const classes = {
  active: "gallery-slideshow--active"
};

export default {
  init() {
    this.$el = $(selectors.el);
    if (this.$el.length) {
      this.addEvents();
    }
  },

  addEvents() {
    $("body")
      .on("click", ".gallery-grid__item", this.onGridItemClick.bind(this))
      .on("click", ".gallery-slideshow", this.onSlideshowClick.bind(this))
      .on(
        "click",
        ".product-images__fullscreen",
        this.onGridItemClick.bind(this)
      )
      .on(
        "click",
        ".product-images__item--fullscreen",
        this.onGridItemClick.bind(this)
      )
      .on(
        "click",
        ".hero__gallery__modal-btn",
        this.onConfiguratorImageClick.bind(this)
      );
  },

  onGridItemClick() {
    const itemIndex = $(selectors.productImages).index(
      $(selectors.targetIndex)
    );

    this.$el.addClass(classes.active);
    this.initSwiper(itemIndex);
    return false;
  },
  onConfiguratorImageClick() {
    this.$el.addClass(classes.active);
    this.initSwiper(0);
    return false;
  },

  onSlideshowClick({ target }) {
    const $target = $(target);

    if (
      $target.hasClass("swiper-container") ||
      $target.parents(".swiper-container").length ||
      $target.hasClass("gallery-slideshow__nav") ||
      $target.parents(".gallery-slideshow__nav").length
    ) {
      return false;
    }

    this.$el.removeClass(classes.active);
    this.destroySwiper();

    return false;
  },

  initSwiper(startIndex) {
    if (this.swiper) {
      return;
    }

    this.swiper = new Swiper(selectors.mainSwiper, {
      spaceBetween: 12,
      navigation: {
        nextEl: ".gallery-slideshow__nav--next",
        prevEl: ".gallery-slideshow__nav--prev",
        disabledClass: "gallery-slideshow__nav--disabled"
      },
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true
      },
    });

    if (startIndex) {
      this.swiper.slideTo(startIndex, 0, false);
    }
  },

  destroySwiper() {
    this.swiper.destroy();
    if (this.swiperThumbs) {
      this.swiperThumbs.destroy();
    }
    this.swiper = null;
  }
};
