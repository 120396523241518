import Vuex from 'vuex';
import config from '../../shared/wizard/modules/config';
import fields from '../../shared/wizard/modules/fields';
import form from '../../shared/wizard/modules/form';
import times from '../../shared/wizard/modules/times';
import dates from '../../shared/wizard/modules/dates';
import sections from '../../shared/wizard/modules/sections';
import reportTypes from './modules/reporttypes';
import service from './modules/service';
import addons from './modules/addons';

const strict = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
  strict,
  modules: {
    config,
    fields,
    form,
    sections,
    reportTypes,
    times,
    dates,
    service,
    addons,
  },
});
