var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "u-align-center" },
        [
          _c("FontAwesomeIcon", {
            staticClass: "wizard--service-booking__loader",
            attrs: { icon: _vm.faSpinnerThird, spin: true }
          })
        ],
        1
      )
    : _c("SectionField", {
        attrs: { type: _vm.FieldTypes.TyreServices },
        on: { change: _vm.onChange }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }