export default {
  FreeText: 'q',
  Sort: 'sort',
  LoadPrevious: 'load_previous',
  Offset: 'offset',
  Preference: 'preference',
  Hits: 'hits',
  TaxCode: 'taxcode',
  BrandId: 'brandId',
  ModelId: 'modelId',
  EngineId: 'engineId',
  RegNr: 'regNr',
  Department: 'department',
};
