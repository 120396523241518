var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-section tyre-selector" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "configurator-section__loader" },
          [
            _c("FontAwesomeIcon", {
              attrs: { icon: _vm.faSpinnerThird, spin: true }
            })
          ],
          1
        )
      : !_vm.hasTyreOptions
      ? _c(
          "div",
          [
            _c("ConfiguratorCheckbox", {
              attrs: {
                text: "Jag vill ha erbjudande på vinterdäck",
                defaultChecked: _vm.needTyres
              },
              on: { change: _vm.setNeedsTyre }
            })
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "form-row" },
          _vm._l(_vm.tyreOptions, function(tyre) {
            return _c(
              "div",
              {
                key: "tyre-product-" + tyre.id,
                staticClass: "tyre-selector__item"
              },
              [
                _c("ConfiguratorCheckbox", {
                  ref: tyre.id,
                  refInFor: true,
                  attrs: {
                    imageSource:
                      tyre.image || "/Static/images/hb_tire-icon.png",
                    defaultChecked: tyre.selected,
                    imageTags: _vm.getImageTags(tyre)
                  },
                  on: {
                    change: function(e) {
                      return _vm.onOptionChange(e, tyre)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "tyre-selector__item__heading" }, [
                  _vm._v(_vm._s(tyre.heading))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tyre-selector__item__price-container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tyre-selector__item__price",
                        class: {
                          "tyre-selector__item__price--discount":
                            _vm.getPrice(tyre.financing).current <
                            _vm.getPrice(tyre.financing).ordinary
                        }
                      },
                      [
                        _vm._v(
                          "\n          +" +
                            _vm._s(
                              _vm.$formatPriceNoSuffix(
                                _vm.getPrice(tyre.financing).current.toFixed() +
                                  _vm.getPrice(tyre.financing).suffix
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.getPrice(tyre.financing).current <
                    _vm.getPrice(tyre.financing).ordinary
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "tyre-selector__item__price tyre-selector__item__price--obsolete"
                          },
                          [
                            _vm._v(
                              "\n          +" +
                                _vm._s(
                                  _vm.$formatPriceNoSuffix(
                                    _vm
                                      .getPrice(tyre.financing)
                                      .ordinary.toFixed() +
                                      _vm.getPrice(tyre.financing).suffix
                                  )
                                ) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "link link--underline link--muted",
                    attrs: { href: tyre.productUrl, target: "_blank" }
                  },
                  [_vm._v("Läs mer")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--outline btn--sm btn--block",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.addClick(tyre)
                      }
                    }
                  },
                  [_vm._v("Lägg till")]
                )
              ],
              1
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }