<template>
  <Modal
    v-if="reviewsModalOpen"
    :lang="lang"
    :locationId="locationId"
    :heading="heading"
    :address="address"
    :reviewsUrl="reviewsUrl"
    @close="reviewsModalOpen = false"
  />
</template>

<script>
import Modal from './Modal.vue';
import { EventBus } from "../../utils/event-bus";

export default {
  props: {
    lang: Object,
    locationId: String,
    heading: String,
    address: String,
    reviewsUrl: String,
  },
  components: {
    Modal,
  },
  data() {
    return {
      reviewsModalOpen: false,
    }
  },
  async created() {
    EventBus.$on('open-reviews-modal', () => this.reviewsModalOpen = true);
  },
};
</script>
