import gtm from "../utils/gtm";

const selectors = {
  list: '.employee-list',
  item: '.employee-list__item',
};

const classes = {
  listExpanded: 'employee-list--expanded',
};

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').
      on('click', '.employee-list .link--icon', this.onExpandClick.bind(this)).
      on('click', '.employee-list__contact-link', this.onContactLinkClick.bind(this)).
      on('click', '.employee-list__phone-link', this.onPhoneLinkClick.bind(this));
  },

  onExpandClick({ currentTarget }) {
    $(currentTarget).closest(selectors.list).toggleClass(classes.listExpanded);
    return false;
  },

  onContactLinkClick({ currentTarget }) {
    const $el = $(currentTarget);
    const $item = $el.closest(selectors.item);
    const departmentNr = $item.data('department-nr');
    const label = $el.text().trim();

    gtm.contactModalClick({ departmentNr, label });
  },

  onPhoneLinkClick({ currentTarget }) {
    const $el = $(currentTarget);
    const $item = $el.closest(selectors.item);
    const departmentNr = $item.data('department-nr');
    const phoneNr = $el.text().trim();

    gtm.phoneNumberClick({ departmentNr, phoneNr });
  },
}
