import Vuex from 'vuex';
import config from '../../shared/wizard/modules/config';
import departments from './modules/departments';
import fields from '../../shared/wizard/modules/fields';
import form from '../../shared/wizard/modules/form';
import lookup from '../../shared/wizard/modules/lookup';
import sections from '../../shared/wizard/modules/sections';


const strict = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
  strict,
  modules: {
    config,
    departments,
    fields,
    form,
    lookup,
    sections,
  },
});
