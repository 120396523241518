import Axios from "../axios";

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $("body").on("click", ".js-getclaim", this.onClick.bind(this));
  },

  onClick({ currentTarget }) {
    const $currentTarget = $(currentTarget);
    const claimId = $("#claimid").val();
    const registrationNumber = $("#registrationnumber").val();
    const rentalPeriodStart = $("#rentalstart").val();
    var data = {
      claimId,
      registrationNumber,
      rentalPeriodStart
    };
    this.getClaim($currentTarget, data);
  },

  async getClaim($currentTarget, data) {
    try {
      const response = await Axios.post("/api/vehicle/claim", data);

      $("#claimResult")
        .removeClass("u-d-none")
        .show();

      $("#relatedPartyInClaim").text(response.data.relatedPartyInClaim);
      $("#rentalCarUsedDays").text(response.data.rentalCarUsedDays);

      $("#compCodes li").remove();
      $(response.data.rentalCarCompCodes).each(function(index, item) {
        $("#compCodes").append(
          $(document.createElement("li")).text(
            item.rentalCarCompCode + ": " + item.rentalCarCompCodeDescription
          )
        );
      });

      return response.status === 200;
    } catch (e) {
      return false;
    }
  }
};
