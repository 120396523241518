export default class I3DocCampaign {
  constructor(doc) {
    this._doc = doc;
  }

  get id() {
    return this._doc._id;
  }

  get heading() {
    return this._doc.Heading;
  }

  get title() {
    return this._doc.Title;
  }

  get intro() {
    return this._doc.IntroText;
  }

  get introTooltip() {
    return this._doc.IntroTextTooltip;
  }

  get text() {
    return this._doc.Text;
  }

  get sticker() {
    return this._doc.StickerText;
  }

  get ribbon() {
    return this._doc.RibbonText;
  }

  get meta() {
    return this._doc.Meta;
  }

  get ordinaryPrice() {
    return parseFloat(this._doc.OrdinaryPrice);
  }

  get campaignPrice() {
    return parseFloat(this._doc.CampaignPrice);
  }

  get priceObject() {
    const { ordinaryPrice, campaignPrice } = this;

    return {
      prefix: "",
      suffix: " kr",
      current: campaignPrice,
      ordinary: ordinaryPrice
    };
  }

  get image() {
    return this._doc.Image;
  }

  get brandImage() {
    return this._doc.BrandImage;
  }

  get isLarge() {
    return this._doc.Large === "true";
  }

  get btnText() {
    return this._doc.ButtonText;
  }

  get btnClass() {
    const btnClass =
      this._doc.ButtonType && this._doc.ButtonType.length
        ? this._doc.ButtonType
        : "btn--primary";
    return `btn ${btnClass}`;
  }

  get permalink() {
    return this._doc.Url;
  }

  get isRollupCampaign() {
    return (
      Array.isArray(this._doc.ContentType) &&
      this._doc.ContentType.some(c => c === "CampaignRollupPage")
    );
  }

  get rollupCountSearchUrl() {
    if (!this.isRollupCampaign) {
      return null;
    }
    const { SearchChannel, RegNrs } = this._doc;
    if (!Array.isArray(RegNrs) || RegNrs.length === 0) {
      return null;
    }
    const joinedRegNrs = Array.isArray(RegNrs) ? RegNrs.join(",") : "";
    return `${SearchChannel}?q=${joinedRegNrs}&webpublish=1`;
  }

  get usps() {
    const usps = [];

    if (!this._doc.CampaignUsps) {
      return usps;
    }
    const campaignUsps = Array.isArray(this._doc.CampaignUsps)
      ? this._doc.CampaignUsps
      : [this._doc.CampaignUsps];
    return [...usps, ...campaignUsps].filter(c => c.Name && c.Name.length);
  }
}
