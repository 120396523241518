var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass:
        "list-styled list-styled--sm list-styled--inline list-styled--checklist list-styled--checklist--success"
    },
    _vm._l(_vm.definedFields, function(field, index) {
      return _c("li", { key: index }, [
        _vm._v("\n    " + _vm._s(_vm.getString(field)) + "\n  ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }