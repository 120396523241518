var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasVehicle
    ? _c("div", { staticClass: "eon-result__header" }, [
        _c("strong", [
          _vm._v("\n    " + _vm._s(_vm.fCount) + " matchningar\n  ")
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n    Vi hittade " +
              _vm._s(_vm.fCount) +
              " produkter som matchar ditt fordon med " +
              _vm._s(_vm.vehicle) +
              "\n  "
          )
        ])
      ])
    : _c("div", { staticClass: "eon-result__header" }, [
        _c(
          "div",
          {
            staticClass:
              "feedback-message feedback-message--center feedback-message--slim feedback-message--info"
          },
          [
            _vm._v(
              "\n    Välj fordon/dimension ovan för att visa produkter som passar ditt fordon.\n  "
            )
          ]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }