export default [
  { value: "AO", name: "AO (Audi)" },
  { value: "N0", name: "N0 (Porsche)" },
  { value: "N1", name: "N1 (Porsche)" },
  { value: "N2", name: "N2 (Porsche)" },
  { value: "N3", name: "N3 (Porsche)" },
  { value: "N4", name: "N4 (Porsche)" },
  { value: "N5", name: "N5 (Porsche)" },
  { value: "N6", name: "N6 (Porsche)" },
  { value: "MO", name: "MO (Mercedes)" },
  { value: "MO", name: "MO (Mercedes SL65 AMG)" },
  { value: "*", name: "* (BMW)" },
  { value: "A", name: "A (Renault Espace IV 18\")" },
  { value: "AR", name: "AR (Alfa Romeo)" },
  { value: "ARR", name: "ARR (Alfa Romeo Racing)" },
  { value: "AZ", name: "AZ (Alfa Romeo / Fiat / Subaru / Toyota)" },
  { value: "B", name: "B (Bentley)" },
  { value: "B1", name: "B1 (Bentley)" },
  { value: "CZ", name: "CZ (Subaru)" },
  { value: "DZ", name: "DZ (Honda / Mazda / Toyota)" },
  { value: "EZ", name: "EZ (Audi / Mazda 6)" },
  { value: "G", name: "G (VW Golf)" },
  { value: "GZ", name: "GZ (Subaru)" },
  { value: "J", name: "J (Jaguar)" },
  { value: "JRS", name: "JRS (Jaguar)" },
  { value: "JZ", name: "JZ (Seat / Subaru)" },
  { value: "K1", name: "K1 (Ferrari)" },
  { value: "K2", name: "K2 (Ferrari)" },
  { value: "K3", name: "K3 (Ferrari)" },
  { value: "KZ", name: "KZ (Nissan / Toyota)" },
  { value: "LZ", name: "LZ (Lancia)" },
  { value: "MZ", name: "MZ (Mitsubishi / Toyota)" },
  { value: "PE", name: "PE (Peugeot)" },
  { value: "PZ", name: "PZ (Ford / Mazda / Mercedes / VW)" },
  { value: "QZ", name: "QZ (Mini)" },
  { value: "RZ", name: "RZ ()" },
  { value: "SZ", name: "SZ (Mazda MPV)" },
  { value: "TZ", name: "TZ (Mazda 6)" },
  { value: "UZ", name: "UZ (BMW 3 / Skoda)" },
  { value: "AM4", name: "AM4 (Aston Martin)" },
  { value: "AM6", name: "AM6 (Aston Martin)" },
  { value: "AM8", name: "AM8 (Aston Martin)" },
  { value: "AM9", name: "AM9 (Aston Martin)" },
  { value: "AML", name: "AML (Aston Martin)" },
  { value: "AMR", name: "AMR (Aston Martin)" },
  { value: "AMS", name: "AMS (Aston Martin)" },
  { value: "AMX", name: "AMX (Aston Martin)" },
  { value: "C1", name: "C1 (Chrysler Viper)" },
  { value: "FO", name: "FO (Ford)" },
  { value: "HO", name: "HO (Honda)" },
  { value: "LRO", name: "LRO (Land Rover)" },
  { value: "MC1", name: "MC1 (Mc Laren)" },
  { value: "MGT", name: "MGT (Maserati)" },
  { value: "MOEXTENDED", name: "MOEXTENDED (Mercedes Extended)" },
  { value: "R01", name: "R01 (Audi Quattro, RS4, RS6, R8)" },
  { value: "R02", name: "R02 (Audi Quattro, RS4, RS6, R8)" },
  { value: "TO", name: "TO (Toyota)" },
  { value: "VO", name: "VO (Volkswagen)" },
  { value: "VW", name: "VW (Volkswagen)" },
  { value: "*RSC", name: "*RSC (BMW Racing)" },
  { value: "A6A", name: "A6A (Aston Martin)" },
  { value: "A7A", name: "A7A (Aston Martin)" },
  { value: "AMP", name: "AMP (Aston Martin)" },
  { value: "AMV", name: "AMV (Aston Martin)" },
  { value: "AOE", name: "AOE (Audi Runflat)" },
  { value: "AO1", name: "AO1 (Audi)" },
  { value: "BC", name: "BC (Bentley Comfort)" },
  { value: "BL", name: "BL (Bentley Low Rolling Resistance)" },
  { value: "ME2", name: "ME2 (Dallara)" },
  { value: "HN", name: "HN (Hyundai)" },
  { value: "L", name: "L (Lamborghini)" },
  { value: "L1", name: "L1 (Lamborghini)" },
  { value: "LR", name: "LR (Land Rover)" },
  { value: "LS", name: "LS (Lotus)" },
  { value: "MC", name: "MC (Mc Laren)" },
  { value: "MO-S", name: "MO-S (Mercedes PNCS)" },
  { value: "MO-V", name: "MO-V (Mercedes Light Truck / Pickup)" },
  { value: "HP", name: "HP (Pagani)" },
  { value: "NA0", name: "NA0 (Porsche)" },
  { value: "Volvo", name: "Volvo (Volvo)" },
  { value: "ALP", name: "ALP (Alpina)" },
  { value: "F", name: "F (Ferrari)" },
  { value: "F01", name: "F01 (Ferrari)" },
  { value: "F02", name: "F02 (Ferrari)" },
  { value: "F03", name: "F03 (Ferrari)" }
]
