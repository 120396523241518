var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DeliveryStatusCircle", {
        attrs: { deliveryStatus: _vm.deliveryStatus, small: "" }
      }),
      _vm._v("\n  " + _vm._s(_vm.label) + "\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }