var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", [_vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-row__col form-row__col--md-50" },
        [
          _c("VcOptionBox", {
            attrs: { selected: _vm.field.value === true, heading: "Ja" },
            on: {
              click: function($event) {
                return _vm.$emit("change", true)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row__col form-row__col--md-50" },
        [
          _c("VcOptionBox", {
            attrs: { selected: _vm.field.value === false, heading: "Nej" },
            on: {
              click: function($event) {
                return _vm.$emit("change", false)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }