<template>
  <div class="accessories-product-spot">
    <div class="accessories-product-spot__image">
      <img :src="product.imageUrl" />
    </div>
    <div class="accessories-product-spot__desc">
      <div class="accessories-product-spot__info">
        <div class="accessories-product-spot__meta">
          {{product.brand}}
        </div>
        <EonProductTitle :product="product">
        </EonProductTitle>
      </div>
    </div>

    <div class="accessories-product-spot__buy">
      <div class="accessories-product-spot__price">
        <div class="accessories-product-spot__price__section">
          <div class="price-tag" :class="{'price-tag--discount': product.discountedPrice}">
            {{ product.discountedPrice ? $formatPrice(product.discountedPrice) : $formatPrice(product.ordinaryPrice)}}
          </div>
          <div class="price-tag price-tag--obsolete" v-if="product.discountedPrice">
            {{$formatPrice(product.ordinaryPrice)}}
          </div>
          <div class="accessories-product-spot__price__meta">
            <DeliveryStatusCircle :deliveryStatus="deliveryStatus" small></DeliveryStatusCircle> {{deliveryStatusLabel}}
          </div>
        </div>
      </div>

      <GridItemBuyActions
        :product="product"
        :regNr="regNr"
        @quantity="onQuantity"
        @added="onAdded">
      </GridItemBuyActions>
    </div>
  </div>
</template>

<script>
import GridItemBuyActions from './GridItemBuyActions.vue';
import LineItemDeliveryStatusLabels from '../../constants/LineItemDeliveryStatusLabels';
import DeliveryStatusCircle from './DeliveryStatusCircle.vue';
import LineItemDeliveryStatuses from '../../constants/LineItemDeliveryStatuses';
import EonProductTitle from '../shared/ecom/EonProductTitle.vue';
import gtm from '../../utils/gtm';
import GtmEvents from '../../constants/GtmEvents';

export default {
  props: {
    product: Object,
    regNr: String,
  },
  components: {
    GridItemBuyActions,
    DeliveryStatusCircle,
    EonProductTitle,
  },
  data() {
    return {
      servicesModalOpen: false,
      addedQuantity: null,
      currentQuantity: null,
    };
  },
  computed: {
    deliveryStatus() {
      if (this.currentQuantity) {
        return this.product.getDeliveryStatus(this.currentQuantity);
      }

      return LineItemDeliveryStatuses.None;
    },

    deliveryStatusLabel() {
      return LineItemDeliveryStatusLabels[this.deliveryStatus];
    }
  },
  methods: {
    onQuantity(input) {
      this.currentQuantity = input.value;
    },

    onAdded(data) {
      this.addedQuantity = data.quantity;
      this.servicesModalOpen = true;
      const gtmData = {
        currencyCode: 'SEK',
        add: {
          products: [{...{quantity: this.addedQuantity}, ...this.product.tireData}]
        }
      }
      gtm.ecommerceEvent(GtmEvents.AddToCart, gtmData);
    },
  },
}
</script>
