<template>
  <div class="product-detail-modals-container">
    <div class="product-meta__links">
      <div class="product-meta__links__item" @click.prevent="openModal(ModalTypes.Equipment)">
        <a class="link">Utrustning</a>
      </div>
      <div class="product-meta__links__item" @click.prevent="openModal(ModalTypes.Facts)">
        <a class="link">Specifikation</a>
      </div>

      <VcModal v-if="activeModal" @close="closeModal">
        <div slot="header">
          {{ activeModal.header }}
        </div>
        <div slot="content" v-html="activeModal.content"></div>
      </VcModal>
    </div>
    <div class="quickbuy__pricing__option quickbuy__pricing__option--attention" v-if="product.reserved">
                  Reserverad t.o.m {{ $formatYYYYMMDD(product.bookedTo) }}
                </div>
  </div>
</template>

<script>
import Axios from "../../axios";
import { VcModal } from "@holmgrensbil/vue-components";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faSquareArrowUpRight as falSquareArrowUpRight,
} from "@fortawesome/pro-light-svg-icons";

const ModalTypes = {
  Facts: 1,
  Equipment: 2
};

const modals = [
  {
    type: ModalTypes.Facts,
    fetchUrl: regNr => `/fordon/${regNr}/GetVehicleFacts`,
    header: "Specifikation"
  },
  {
    type: ModalTypes.Equipment,
    fetchUrl: regNr => `/fordon/${regNr}/GetVehicleEquipment`,
    header: "Utrustning"
  }
];

export default {
  props: {
    product: Object
  },
  components: {
    VcModal,
    FontAwesomeIcon
  },
  data() {
    return {
      activeModal: null,
      ModalTypes,
      modals,
      falSquareArrowUpRight
    };
  },
  methods: {
    openModal(type) {
      const modal = this.modals.find(m => m.type === type);

      Axios.get(modal.fetchUrl(this.product.regNr)).then(res => {
        this.activeModal = {
          header: modal.header,
          content: res.data
        };
      });
    },

    closeModal() {
      this.activeModal = null;
    }
  }
};
</script>
