var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configurator-section additional-service-selector" },
    [
      _c("div", { staticClass: "configurator-section__title-container" }, [
        _c("h4", { staticClass: "configurator-section__title" }, [
          _c("i", {
            staticClass: "fal fa-file-certificate configurator-section__icon"
          }),
          _vm._v(
            "\n      " + _vm._s(_vm.serviceAgreementData.heading) + "\n    "
          )
        ]),
        _vm._v(" "),
        _vm.serviceAgreementData.informationModalUrl
          ? _c(
              "a",
              {
                staticClass: "link link--muted-light link--underline",
                attrs: {
                  href: "#",
                  "data-target": _vm.serviceAgreementData.informationModalUrl,
                  "data-modal-source": "ajax"
                }
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.lang.SiteGeneral.ReadMore) + "\n    "
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      !_vm.fetchedAgreement
        ? _c(
            "div",
            [
              _c("VcOptionBox", {
                attrs: {
                  selected: !_vm.wantsAgreement,
                  heading: _vm.serviceAgreementData.denyOption
                },
                on: {
                  click: function($event) {
                    return _vm.onWantsAgreement(false)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "ConfiguratorOptionBox",
                {
                  attrs: {
                    selected: _vm.wantsAgreement,
                    heading: _vm.serviceAgreementData.acceptOption,
                    hasSubOption: true,
                    yellowBg: _vm.wantsAgreement,
                    lang: _vm.lang
                  },
                  on: {
                    click: function($event) {
                      return _vm.onWantsAgreement(true)
                    }
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("ConfiguratorSubSelect", {
                          attrs: {
                            selectedValue: _vm.months,
                            option: _vm.monthsOptions
                          },
                          on: { change: _vm.setMonths }
                        }),
                        _vm._v(" "),
                        _c("ConfiguratorSubSelect", {
                          attrs: {
                            selectedValue: _vm.mileage,
                            option: _vm.mileageOptions
                          },
                          on: { change: _vm.setMileage }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn--primary additional-service-selector__submit-btn",
                            on: { click: _vm.getServiceAgreement }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.lang.Summation.ServiceAgreementAction
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _c("div", { staticClass: "configurator-section__completed" }, [
            _c("div", { staticClass: "details-bar" }, [
              _c("div", { staticClass: "details-bar__item-list" }, [
                _c("div", { staticClass: "details-bar__item" }, [
                  _c("div", { staticClass: "details-bar__title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.lang.Summation.ServiceAgreementPeriodHeading
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "details-bar__value details-bar__value" },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.months +
                              " " +
                              _vm.lang.Summation.ServiceAgreementPeriodMeta
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details-bar__item" }, [
                  _c("div", { staticClass: "details-bar__title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.lang.Summation.ServiceAgreementDistanceHeading
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "details-bar__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.mileage +
                            " " +
                            _vm.lang.Summation.ServiceAgreementDistanceMeta
                        ) +
                        "\n          "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "details-bar__action",
                  on: { click: _vm.removeAgreement }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lang.Summation.RemoveSelection) +
                      "\n      "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "main-info" }, [
              _c("div", { staticClass: "main-info__heading" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Summation.ServiceAgreementOfferHeading) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "main-info__data" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$formatPriceNoSuffix(
                        _vm.fetchedAgreement.monthlyCost
                      ) + " kr/mån"
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "main-info__meta" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Summation.ServiceAgreementOfferText) +
                    "\n      "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "configurator-section__completed__sub-action" },
              [
                _c("div", { staticClass: "main-info__heading" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lang.Summation.ServiceAgreementAddonsHeading) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.fetchedAgreement.additionalProducts, function(
                  additionalProduct
                ) {
                  return _c(
                    "VcOptionBox",
                    {
                      key: additionalProduct.id,
                      attrs: {
                        selected: _vm.addedAdditionalProducts.includes(
                          additionalProduct.id
                        ),
                        heading: additionalProduct.name,
                        checkbox: true
                      },
                      on: {
                        click: function($event) {
                          return _vm.changeAdditionalProduct(
                            additionalProduct.id
                          )
                        }
                      }
                    },
                    [
                      additionalProduct.description
                        ? _c(
                            "div",
                            {
                              attrs: { slot: "header-meta" },
                              slot: "header-meta"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "link link--underline link--muted",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.onLinkClick(
                                        additionalProduct.name,
                                        additionalProduct.description
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.lang.SiteGeneral.Readmore) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      additionalProduct.monthlyCost
                        ? _c(
                            "div",
                            {
                              attrs: { slot: "header-aside" },
                              slot: "header-aside"
                            },
                            [
                              _c("VcPriceTag", {
                                attrs: {
                                  price: {
                                    current: additionalProduct.monthlyCost,
                                    suffix: " kr/mån"
                                  },
                                  "show-if-zero": true
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                })
              ],
              2
            )
          ]),
      _vm._v(" "),
      _vm.modalOpen
        ? _c("VcModal", { on: { close: _vm.closeModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("\n      " + _vm._s(_vm.modalHeading) + "\n    ")
            ]),
            _vm._v(" "),
            _c("div", {
              attrs: { slot: "content" },
              domProps: { innerHTML: _vm._s(_vm.modalContent) },
              slot: "content"
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }