var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-watch" },
    [
      _c("i", { staticClass: "search-watch__icon fas fa-bell" }),
      _vm._v(" "),
      _c("div", { staticClass: "search-watch__header" }, [
        _vm._v("\n    " + _vm._s(_vm.header) + "\n  ")
      ]),
      _vm._v(" "),
      _c("label", [_vm._v("\n    Starta en bevakning av:\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-watch__filters" },
        _vm._l(_vm.tags, function(tag) {
          return _c("div", { key: tag, staticClass: "search-watch__filter" }, [
            _vm._v("\n      " + _vm._s(tag) + "\n    ")
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.done
        ? _c(
            "div",
            [
              _vm.isContactSubmit
                ? _c("VcFeedbackMessage", { attrs: { success: "" } }, [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.feedbackContactHeader) +
                          "\n      "
                      )
                    ]),
                    _vm._v(
                      "\n      " + _vm._s(_vm.feedbackContactText) + "\n    "
                    )
                  ])
                : _c("VcFeedbackMessage", { attrs: { success: "" } }, [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.feedbackHeader) + "\n      "
                      )
                    ]),
                    _vm._v("\n      " + _vm._s(_vm.feedbackText) + "\n    ")
                  ])
            ],
            1
          )
        : _vm.error
        ? _c("VcFeedbackMessage", { attrs: { danger: "" } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("\n      Något gick fel!\n    ")
            ]),
            _vm._v("\n    Det gick ej att skapa din bevakning.\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.done
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.$emit(
                    "saveSearches",
                    _vm.email,
                    _vm.selectedOption === "contact",
                    _vm.selectedDepartment && _vm.selectedDepartment.value,
                    _vm.brandToWatch
                  )
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "VcOptionBox",
                    {
                      attrs: {
                        selected: _vm.selectedOption === "watch",
                        heading: "Jag vill starta en bevakning"
                      },
                      on: {
                        click: function($event) {
                          return _vm.onChangeOption("watch")
                        }
                      }
                    },
                    [
                      _vm.selectedOption === "watch"
                        ? _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c(
                                "div",
                                { staticClass: "search-watch__option-text" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.text) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.brandToWatch
                    ? _c(
                        "VcOptionBox",
                        {
                          attrs: {
                            selected: _vm.selectedOption === "contact",
                            heading: "Jag vill bli kontaktad"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onChangeOption("contact")
                            }
                          }
                        },
                        [
                          _vm.selectedOption === "contact"
                            ? _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "search-watch__option-text"
                                    },
                                    [
                                      _vm._v(
                                        "\n            Kanske finns det bilar att beställa eller bilar som är på väg hem som motsvarar din sökning, lämna din mail så kan en av våra säljare återkomma till dig\n          "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.departmentNr
                                    ? _c(
                                        "VcFormStack",
                                        {
                                          attrs: {
                                            label: "Välj anläggning",
                                            required: true
                                          }
                                        },
                                        [
                                          _c("VcFormSelect", {
                                            attrs: {
                                              options: _vm.availableDepartments,
                                              value: _vm.selectedDepartment,
                                              prompt: "Välj anläggning",
                                              valueProp: "value",
                                              labelProp: "name",
                                              valueType: "number"
                                            },
                                            on: {
                                              change: _vm.onChangeDepartment
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isLoggedIn
                    ? _c("div", { staticClass: "input-group" }, [
                        _c(
                          "div",
                          { staticClass: "form-stack form-stack--required" },
                          [
                            _c("label", [
                              _vm._v("\n            E-post\n          ")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email,
                                  expression: "email"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "email",
                                autocomplete: "email",
                                placeholder: "Ange din e-post",
                                required: ""
                              },
                              domProps: { value: _vm.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.email = $event.target.value
                                }
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn--primary btn--sm",
                              class: {
                                disabled: _vm.loading || _vm.disabledBtn
                              },
                              attrs: {
                                disabled: _vm.loading || _vm.disabledBtn,
                                type: "submit"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.selectedOption === "contact"
                                      ? "Kontakta mig"
                                      : "Bevaka"
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ])
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "btn btn--primary btn--block",
                          class: { disabled: _vm.loading || _vm.disabledBtn },
                          on: {
                            click: function($event) {
                              !_vm.disabledBtn &&
                                _vm.$emit(
                                  "saveSearches",
                                  null,
                                  _vm.selectedOption === "contact",
                                  _vm.selectedDepartment &&
                                    _vm.selectedDepartment.value,
                                  _vm.brandToWatch
                                )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.selectedOption === "contact"
                                  ? "Kontakta mig"
                                  : "Lägg till i mina bevakningar"
                              ) +
                              "\n      "
                          )
                        ]
                      )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "form-text",
        domProps: { innerHTML: _vm._s(_vm.privacyPolicyText) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }