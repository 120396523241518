<template>
  <tr>
    <td>
      <slot></slot>
    </td>
    <td>
      <VcFormSelect
        :options="quantityOptions"
        :value="quantityValue"
        :disabled="quantityDisabled"
        labelProp="name"
        valueProp="value"
        valueType="number"
        small
        @change="$emit('quantity', $event.value)">
      </VcFormSelect>
    </td>
    <td class="cart-item__line-price">
      <VcPriceTag :vatPercentage="line.vatPercentage" :price="line.totalPrice" showIfZero hideOrdinaryPriceIfDiscount></VcPriceTag>
      <VcPriceTag :vatPercentage="line.vatPercentage" :price="line.price" hideOrdinaryPriceIfDiscount></VcPriceTag>
    </td>
    <td v-if="line.isDeletable"
      class="cart-item__line-remove"
      @click="$emit('remove')">
      <i class="fas fa-trash"></i>
    </td>
  </tr>
</template>

<script>
import { VcFormSelect, VcPriceTag } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormSelect,
    VcPriceTag,
  },
  props: {
    line: Object,
    quantity: Number,
    quantityOptions: Array,
    quantityDisabled: Boolean,
  },
  computed: {
    quantityValue() {
      return this.quantityOptions.find(qo => qo.value === this.quantity);
    },
  },
}
</script>
