<template>
  <div class="eon-result__header" v-if="hasVehicle">
    <strong>
      {{fCount}} matchningar
    </strong>
    <span>
      Vi hittade {{fCount}} produkter som matchar ditt fordon med {{vehicle}}
    </span>
  </div>
  <div class="eon-result__header" v-else>
    <div class="feedback-message feedback-message--center feedback-message--slim feedback-message--info">
      Välj fordon/dimension ovan för att visa produkter som passar ditt fordon.
    </div>
  </div>
</template>

<script>
import TyreSpec from '../../models/TyreSpec';

export default {
  props: {
    count: Number,
    vehicle: String,
  },
  computed: {
    fCount() {
      return this.$formatNumber(this.count);
    },
    hasVehicle() {
      return !TyreSpec.isEmpty;
    },
  },
}
</script>
