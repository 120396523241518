var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "choose-spec",
      class: { "choose-spec--promo": _vm.promo },
      style: {
        backgroundImage: _vm.promoBg ? "url(" + _vm.promoBg + ")" : "none"
      }
    },
    [
      _c("div", { staticClass: "choose-spec__content" }, [
        _vm.meta
          ? _c("div", {
              staticClass: "choose-spec__meta",
              domProps: { innerHTML: _vm._s(_vm.meta) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.header
          ? _c("h1", { staticClass: "choose-spec__header" }, [
              _vm._v("\n      " + _vm._s(_vm.header) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.onSubmit($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "regnr-input",
                class: { "regnr-input--lg": _vm.largeRegnr }
              },
              [
                _c("div", { staticClass: "input-group" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "form-stack",
                      class: { "form-stack--lg": _vm.largeRegnr }
                    },
                    [
                      _c("label", [
                        _vm._v("\n              Regnummer\n            ")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regNr,
                            expression: "regNr"
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { color: "#18191a" },
                        attrs: {
                          id: "regNr-input",
                          type: "text",
                          placeholder: "ABC123",
                          required: "",
                          pattern: "^\\w{3}\\d{2}(\\w{1}|\\d{1})$"
                        },
                        domProps: { value: _vm.regNr },
                        on: {
                          keyup: function($event) {
                            return _vm.onChange()
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.regNr = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn--primary regnr-input-sibling",
                class: {
                  "btn--lg": _vm.largeRegnr,
                  "regnr-input-sibling--lg": _vm.largeRegnr
                },
                attrs: { type: "submit" }
              },
              [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n      ")]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "choose-spec__options" }, [
          _c("div", { staticClass: "choose-spec__options-header" }, [
            _vm._v("\n        Har du inget regnummer?\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "inline-bullets" }, [
            _c(
              "div",
              {
                staticClass: "inline-bullets__item",
                on: {
                  click: function($event) {
                    return _vm.$emit("chooseVehicleClick")
                  }
                }
              },
              [
                _c("a", { staticClass: "link link--underline" }, [
                  _vm._v("Välj bilmodell")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "inline-bullets__item",
                on: {
                  click: function($event) {
                    return _vm.$emit("chooseTyreDimensionsClick")
                  }
                }
              },
              [
                _c("a", { staticClass: "link link--underline" }, [
                  _vm._v("Ange däckdimensioner")
                ])
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }