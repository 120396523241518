export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    this.initSticky();
  },

  initSticky() {
    const $sticky = $('.sticky');
    const $parent = $('.vehicle-page__side-bar');

    if (!!$sticky.offset()) { // eslint-disable-line

      const stickyTop = $sticky.offset().top;
      const stickOffset = stickyTop;

      $(window).scroll(() => {
        const windowTop = $(window).scrollTop();
        const parentWidth = $parent.innerWidth() - 24;
        const $stickyStopper = $('.sticky-stopper');
        const generalSidebarHeight = $sticky.innerHeight();
        const stickyStopperPosition = $stickyStopper.offset().top;
        const stopPoint = stickyStopperPosition - generalSidebarHeight - stickOffset - 56;
        const diff = stopPoint;

        if ((stopPoint + 120) < windowTop) {
          $sticky.css({ position: 'absolute', top: diff, width: 'calc(100% - 24px)' });
        } else if ((windowTop + 110) > stickyTop) {
          $sticky.css({ position: 'fixed', top: 150, width: parentWidth });
        } else {
          $sticky.css({position: 'absolute', top: 'initial', width: 'calc(100% - 24px)'});
        }
      });
    }
  }
}







