<template>
  <div class="row opening-hours-print-app u-margin-bottom-lg u-margin-top-lg">
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-6">
          <label> VÄLJ ÖPPETTIDER</label>
          <VcOptionBox
            @click="type = 'regular'"
            :selected="type === 'regular'"
            heading="Ordinarie öppettider"
          >
          </VcOptionBox>
          <VcOptionBox
            class="u-margin-top"
            @click="type = 'custom'"
            :selected="type === 'custom'"
            heading="Period, from 7 dagar fram"
          >
          </VcOptionBox>
          <VcFormStack v-if="type === 'custom'" label="Datum" required>
            <VcDatePicker
              :value="date"
              :minDate="minDate"
              placeholder="Välj en dag"
              @change="onDateChange($event.target.value)"
            >
              <i class="fal fa-calendar-alt" slot="icon"></i>
            </VcDatePicker>
          </VcFormStack>

          <VcOptionBox
            v-if="type === 'custom'"
            class="u-margin-top-lg"
            checkbox
            heading="Dölj datum"
            :selected="noDate"
            @click="onHideDate"
          ></VcOptionBox>

          <label class="u-margin-top-lg"> VÄLJ TEMA</label>
          <VcFormStack label="Tema">
            <VcFormSelect
              prompt="Välj tema"
              :options="themes"
              :value="selectedTheme"
              valueProp="name"
              labelProp="name"
              required
              @change="onThemeChange"
            >
            </VcFormSelect>
          </VcFormStack>
        </div>
        <div class="col-md-6">
          <label> Skriv meddelande</label>
          <VcFormStack label="Brödtext">
            <textarea
              placeholder="Skriv meddelande här..."
              v-model="message"
              class="form-control"
            ></textarea>
          </VcFormStack>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <label> Förhandsgranska och skriv ut</label>
      <div class="opening-hours-print-app__preview__container">
        <div
          v-if="selectedTheme"
          class="opening-hours-print-app__preview__image-container"
        >
          <div
            class="opening-hours-print-app__preview__header"
            :class="{
              'opening-hours-print-app__preview__header--white':
                selectedTheme && selectedTheme.colorClass === 'white',
              'opening-hours-print-app__preview__header--yellow':
                selectedTheme && selectedTheme.colorClass === 'yellow',
              'opening-hours-print-app__preview__header--red':
                selectedTheme && selectedTheme.colorClass === 'red',
              'opening-hours-print-app__preview__header--green':
                selectedTheme && selectedTheme.colorClass === 'green',
              'opening-hours-print-app__preview__header--blue':
                selectedTheme && selectedTheme.colorClass === 'blue',
              'opening-hours-print-app__preview__header--white-text':
                selectedTheme && selectedTheme.whiteText
            }"
            :style="
              `background-image: url(${selectedTheme && selectedTheme.image})`
            "
          >
            <i class="fas fa-clock opening-hours-print-app__preview__icon"></i>
            <div class="opening-hours-print-app__preview__title">
              Öppettider
            </div>
            <div class="opening-hours-print-app__preview__sub-title">
              Personbilar - Österängsvägen 8
            </div>
          </div>
          <img src="/Static/images/hb_printscreen@2x.png" />
        </div>
        <button
          class="btn btn--primary"
          :class="{
            disabled: !selectedTheme
          }"
          :disabled="!selectedTheme"
          @click="goToPrint"
        >
          <i
            class="fal fa-print opening-hours-print-app__preview__btn-icon"
          ></i>
          Generera och skriv ut
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VcOptionBox,
  VcFormStack,
  VcFormSelect,
  VcDatePicker
} from "@holmgrensbil/vue-components";
import axios from "../../axios";
export default {
  components: {
    VcOptionBox,
    VcFormStack,
    VcFormSelect,
    VcDatePicker
  },
  props: {
    lang: {
      type: Object
    },
    departmentNr: {
      type: String
    }
  },
  mounted() {
    this.onGetOpeningHoursThemes();
  },
  data() {
    return {
      type: "regular",
      message: "",
      selectedTheme: null,
      themes: [],
      date: "",
      noDate: false,
    };
  },

  computed: {
    printUrl() {
      const baseUrl = window.location.href.replace(
        "/print",
        "/printopeninghours"
      );
      const selectedTheme = this.selectedTheme
        ? this.selectedTheme.name
        : "standard";
      return (
        baseUrl +
        `?startDate=${this.date}&text=${this.message}&theme=${selectedTheme}${this.noDate ? '&noDate=true' : ''}`
      );
    },
    minDate() {
      var d = new Date();
      d.setDate(d.getDate() - 7);
      return d;
    }
  },

  methods: {
    async onThemeChange(theme) {
      this.selectedTheme = theme;
    },
    onDateChange(value) {
      this.date = value;
    },
    goToPrint() {
      window.location.href = this.printUrl;
    },
    async onGetOpeningHoursThemes() {
      try {
        const res = await axios.get("/api/contact/GetOpeningHoursThemes");
        this.themes = res?.data || [];
      } catch (e) {
        console.log(e);
      }
    },
    onHideDate() {
      this.noDate = !this.noDate;
    }
  }
};
</script>
