import SectionTypes from './SectionTypes';
import SectionContentLookup from '../SectionContentLookup.vue';
import SectionContentContact from '../SectionContentContact.vue';
import SectionContentSummary from '../SectionContentSummary.vue';

export default {
  [SectionTypes.Lookup]: SectionContentLookup,
  [SectionTypes.Contact]: SectionContentContact,
  [SectionTypes.Summary]: SectionContentSummary,
}
