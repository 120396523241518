<template>
  <div class="vehicle-slider">
    <TheSlider :documents="documents" :slimList="channel.slimList"></TheSlider>
  </div>
</template>

<script>
import TheSlider from './TheSlider.vue';

export default {
  props: {
    channel: Object,
    queriedAt: Date,
  },
  components: {
    TheSlider,
  },
  data() {
    return {
      documents: [],
    };
  },
  watch: {
    queriedAt() {
      this.documents = this.channel.data.documents.documents || [];
    },
  },
}
</script>
