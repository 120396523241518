<template>
  <VcModal @close="$emit('modalClose')">
    <div slot="header">
      {{modalHeader}}
    </div>
    <div slot="content" class="choose-vehicle">
      <VcFormStack label="Bilmärke">
        <VcFormSelect
          prompt="Välj bilmärke"
          :options="manufacturerOptions"
          :value="manufacturer"
          valueProp="id"
          valueType="number"
          labelProp="name"
          @change="selectManufacturer">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Bilmodell">
        <VcFormSelect
          ref="modelSelector"
          prompt="Välj bilmodell"
          :options="modelOptions"
          :value="model"
          valueProp="id"
          valueType="number"
          labelProp="name"
          @change="selectModel">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Bilmotor" v-if="displaySelectVariant">
        <VcFormSelect
          prompt="Välj bilmotor"
          :options="variantOptions"
          :value="variant"
          valueProp="id"
          valueType="number"
          labelProp="name"
          @change="selectVariant">
        </VcFormSelect>
      </VcFormStack>
      <div class="btn btn--primary btn--block" :class="{'disabled': !buttonEnabled}" @click="onSubmit">
        {{buttonText}}
      </div>
    </div>
  </VcModal>

</template>

<script>
import { VcFormSelect, VcFormStack, VcModal } from '@holmgrensbil/vue-components';
import ServicesApi from '../../models/ServicesApi';

export default {
  components: {
    VcFormSelect,
    VcFormStack,
    VcModal,
  },
  props: {
    buttonText: String,
    modalHeader: String,
  },
  data() {
    return {
      manufacturer: null,
      manufacturerOptions: [],

      model: null,
      modelOptions: [],

      variant: null,
      variantOptions: [],

      displayDirectly: false,
    };
  },
  created() {
    this.onCreated();
  },
  computed: {
    buttonEnabled() {
      return (this.manufacturer && this.model && this.variant) || this.displayDirectly;
    },

    displaySelectVariant() {
      if (!this.displayDirectly && this.variantOptions.length === 1) {
        if (this.variantOptions[0].name === "") {
          return false;
        }
      }

      return !!this.variantOptions.length;
    },
  },

  methods: {
    async onCreated() {
      const res = await ServicesApi.fetchBrands();
      this.manufacturerOptions = res.data.filter(manufacturer => manufacturer.partsBrand);
    },

    async selectManufacturer(manufacturer) {
      this.manufacturer = manufacturer;
      const res = await ServicesApi.fetchModels(this.manufacturer.id);
      this.modelOptions = res.data;

      this.model = null;

      this.displayDirectly = false;

      this.variant = null;
      this.variantOptions = [];

      this.$refs.modelSelector.$el.children[0].focus()
    },

    async selectModel(model) {
      this.model = model;
      this.variant = null;

      const res = await ServicesApi.fetchEngines(this.model.id);
      this.variantOptions = res.data;

      if (this.variantOptions.length === 1) {
        this.selectVariant(this.variantOptions[0]);
      }
    },

    async selectVariant(variant) {
      this.variant = variant;
    },

    async onSubmit() {
      this.$emit('setCarManually', {
        brandId: this.manufacturer.id,
        brand: this.manufacturer.name,
        modelId: this.model.id,
        model: this.model.name,
        engineId: this.variant.id,
        engine: this.variant.name,
        generation: this.variant.generation,
      })
    },
  },
}
</script>
