<template>
  <div class="i3-search-watch">
    <TheSearchWatch v-if="active"
      :lastQuery="lastQuery"
      :queryFilters="queryFilters"
      :brandToWatch="brandToWatch"
      :channel="channel"
      :lang="lang">
    </TheSearchWatch>
  </div>
</template>

<script>
import ChannelEvents from '../../constants/ChannelEvents';
import TheSearchWatch from './TheSearchWatch.vue';

export default {
  components: {
    TheSearchWatch,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
    lang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      queryFilters: [],
      lastQuery: null,
      hasNextPage: null,
      brandToWatch: null,
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  computed: {
    active() {
      return !!this.queryFilters.length && !this.hasNextPage;
    },
  },
  methods: {
    onQuerySuccess() {
      const brandModelFacets = this.channel.data.facets
        .find(facet => facet.id === 'brandmodelfacet')?.filters?.filter(filter => filter.selected || filter.children.filter(child => child.selected).length === 1) || [];
      this.queryFilters = this.channel.getQueryFilters();

      this.brandToWatch = brandModelFacets.length === 1 && !window.location.href.includes("fritid")
        ? brandModelFacets[0]?.displayName
        : null;
      this.lastQuery = this.channel.lastQuery;
      this.hasNextPage = this.channel.hasNextPage;
    },
  },
}
</script>
