export const replaceForDisplay = (value) => {
  const replaceValues = window.REPLACE_VEHICLE_PROPNAME_FOR_DISPLAY;
  if (replaceValues != undefined) {
    if (replaceValues.length) {
      replaceValues.forEach(a => {
        if (a.key.toLowerCase() === value.toLowerCase())
          value = a.value;
      });
    }
  }
  return value;
}
