<template>
  <div>
    <div
      v-if="
        carResults.documents.length > 0 ||
        pageResults.documents.length > 0 ||
        // departmentResults.documents.length > 0 ||
        employeeResults.documents.length > 0
      "
      class="search-results">
      <ResultList :component="carResults" @onClick="onClickAllResults"></ResultList>
      <ResultList :component="pageResults"></ResultList>
      <!-- <ResultList :component="departmentResults"></ResultList> -->
      <ResultList :component="employeeResults"></ResultList>
    </div>
    <div class="i3-site-search__no-results" v-else>
      Hittade inget sökresultat.
    </div>
  </div>
</template>

<script>
import ResultList from './ResultList.vue';
import I3DocProduct from "../../models/I3DocProduct";

export default {
  components: {
    ResultList,
  },
  props: {
    components: Array,
    searchString: String,
  },
  computed: {
    showSearchResultContent() {
      return !!this.searchString || this.popularSearches.length > 0;
    },
    carResults() {
      return (
        this.components.find(component => component.id === "documentsCars") || {}
      );
    },
    pageResults() {
      return (
        this.components.find(component => component.id === "documentsEpiserver") || {}
      );
    },
    // departmentResults() {
    //   return (
    //     this.components.find(component => component.id === "documentsDepartments") || {}
    //   );
    // },
    employeeResults() {
      return (
        this.components.find(component => component.id === "documentsEmployees") || {}
      );
    },
  },
  methods: {
    onClickAllResults() {
      if (this.searchString) {
        if (this.carResults.documents.length === 1) {
          const product = new I3DocProduct(this.carResults.documents[0]);
          window.location.href = product.permalink;
        } else {
          const { origin, MainVehicleSearchPage } = window;
          window.location.href = `${origin}${MainVehicleSearchPage}?q=${this.searchString.replaceAll('-', ' ')}*`;
        }
      }
    },
  }
}
</script>
