<template>
  <div
    class="wizard wizard--service-top wizard--service-booking"
    v-if="departmentSelected"
  >
    <SectionList
      :sections="sections"
      :SectionTypeComponents="SectionTypeComponents"
      @completed="onSectionCompleted"
    >
    </SectionList>
    <FormSubmit :lastSection="lastSection" @completed="onSectionCompleted">
    </FormSubmit>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SectionTypes from "./constants/SectionTypes";
import FieldTypes from "./constants/FieldTypes";
import SectionList from "../shared/wizard/SectionList.vue";
import FormSubmit from "../shared/wizard/FormSubmit.vue";
import SectionTypeComponents from "./constants/SectionTypeComponents";
import gtm from "../../utils/gtm";
import ServiceBookingManager from "../../models/ServiceBookingManager";
import isDefined from "../../utils/is-defined";

export default {
  components: {
    SectionList,
    FormSubmit
  },
  data() {
    return {
      SectionTypeComponents,
      regNr: null,
      departmentNr: null,
      availableDays: [],
      service: null,
      carInfo: null
    };
  },
  created() {
    if (ServiceBookingManager.carInfo) {
      this.carInfo = ServiceBookingManager.carInfo;
    }

    ServiceBookingManager.onCarInfoSet(e => {
      if (e.detail.carInfo) {
        this.carInfo = e.detail.carInfo;
      }
    });

    if (ServiceBookingManager.service) {
      this.service = ServiceBookingManager.service;
      this.setService({ service: ServiceBookingManager.service });
    }

    ServiceBookingManager.onServiceSet(e => {
      if (e.detail.service) {
        this.setEndpoint(`/api/services/${e.detail.service.value}`);
        this.service = e.detail.service;
        this.setService({ service: e.detail.service });
      }
    });

    ServiceBookingManager.onRegNrSet(e => {
      const regNr = e.detail.regNr;

      if (this.regNr && this.regNr !== regNr) {
        this.resetState();
        this.departmentNr = null;
      }

      this.setField({ type: FieldTypes.RegNr, value: regNr });
      this.regNr = regNr;
      this.setRegNr(this.regNr);

      const brandName = this.carInfo ? this.carInfo.brand : null;

      let section = { type: "Vehicle", order: 0 };
      gtm.serviceBookingV2SectionCompleted({
        section,
        brandName
      });
    });

    ServiceBookingManager.onDepartmentSet(e => {
      if (e.detail.department) {
        const departmentNr = e.detail.department.value;
        this.departmentNr = departmentNr;
        const departmentName = e.detail.department.name;
        this.setField({ type: FieldTypes.DepartmentNr, value: departmentNr });
        this.setField({
          type: FieldTypes.DepartmentName,
          value: departmentName
        });
        this.fetchServiceTypes({
          departmentNr: departmentNr,
          regNr: this.regNr,
          service: this.service.value
        });
        const brandName = this.carInfo ? this.carInfo.brand : null;
        let section = { type: "Department", order: 0 };
        gtm.serviceBookingV2SectionCompleted({
        section,
        departmentNr,
        brandName
      });
      } else {
        this.departmentNr = null;
      }
    });
  },
  computed: {
    ...mapState({
      sections: state => state.sections.items
    }),

    ...mapGetters({
      fieldByType: "fields/byType",
      sectionByType: "sections/byType"
    }),

    lastSection() {
      return this.sectionByType(SectionTypes.Summary);
    },
    departmentSelected() {
      return isDefined(this.departmentNr);
    },
    serviceSelected() {
      return (
        this.fieldByType(FieldTypes.ServiceTypes).value[0].id === "service"
      );
    }
  },
  methods: {
    ...mapActions({
      setField: "fields/set",
      fetchAddons: "addons/fetch",
      fetchAvailableDays: "dates/fetchDates",
      fetchServiceTypes: "serviceTypes/fetchTypes",
      filterSections: "sections/filterSections",
      setEndpoint: "form/load",
      setService: "service/setService",
      setRegNr: "fields/setRegNr",
      resetState: "config/reset"
    }),

    onSectionCompleted(section) {
      const departmentNr = this.departmentNr;
      const servicesTypes =
        this.fieldByType(FieldTypes.ServiceTypes).value || [];
      const services = servicesTypes.map(service => service.id);
      const serviceType = services.join(",");
      const selectedAddons = [];

      const campaignCode =
        this.fieldByType(FieldTypes.CampaignCode).value || "";
      if (campaignCode) {
        selectedAddons.push(campaignCode);
      }

      const addonTypes =
        this.fieldByType(FieldTypes.Addons).options.forEach(option =>
          option.options.forEach(subOption => {
            if (subOption.selected) {
              selectedAddons.push(subOption.id);
            }
          })
        ) || [];
      const addonType = selectedAddons.join(",");
      // const serviceTypes = [];
      // this.fieldByType(FieldTypes.ServiceTypes).value.forEach(value => {
      //   serviceTypes.push(value.id)
      // });
      if (section.type === SectionTypes.Services) {
        this.fetchAddons({
          departmentNr: this.departmentNr,
          service: this.service.value,
          regNr: this.regNr,
          mileage: this.fieldByType(FieldTypes.Mileage).value,
          serviceType: services
        });
        this.setField({
          type: FieldTypes.ServiceMessage,
          hiddenInSummary: !this.serviceSelected
        });
      }

      if (section.type === SectionTypes.Addons) {
        this.fetchAvailableDays({
          departmentNr: this.departmentNr,
          addons: selectedAddons,
          regNr: this.regNr,
          service: this.service.value,
          serviceType: services
        });
        this.setField({
          type: FieldTypes.Addons,
          value: selectedAddons
        });
      }

      if (section === this.lastSection) {
      }

      const brandName = this.carInfo ? this.carInfo.brand : null;
      gtm.serviceBookingV2SectionCompleted({
        section,
        departmentNr,
        serviceType,
        addonType,
        brandName
      });
    }
  }
};
</script>
