import { isSameDay } from "../utils/dates";
import I3FuelTypes from "../constants/I3FuelTypes";

export default class I3DocProduct {
  constructor(doc, usps) {
    this._doc = doc;
    this.usps = usps || {};
  }

  get id() {
    return this._doc._id;
  }

  get title() {
    return `${this._doc.vehiclecondition} ${this._doc.vehicletype == "Personbil" ? "Bil" : this._doc.vehicletype} ${this._doc.locationname} ${this._doc.brandname} ${this._doc.modelname} ${this._doc.modelyear}`;
  }

  get brandModelTitle() {
    const { brandWithRules, model, modelname, brandname, brandmodelsuffix } = this._doc;
    
    if (brandWithRules == undefined) {
      if (brandname === 'MINI')
        return `${`MINI Cooper`} ${model ?? modelname} ${brandmodelsuffix == undefined ? "" : brandmodelsuffix.join(" ")}`;
      else
        return `${brandname} ${model ?? modelname} ${brandmodelsuffix == undefined ? "" : brandmodelsuffix.join(" ")}`;
      // 
    }


    return `${brandWithRules} ${model ?? modelname} ${brandmodelsuffix == undefined ? "" : brandmodelsuffix.join(" ")}`;
  }

  get shortModelText() {
    return this._doc.modeltext_nobrand;
  }

  get description() {
    return this._doc.description;
  }

  get modelYear() {
    return this._doc.modelyear;
  }

  get fuelName() {
    return this._doc.fuelname;
  }

  get environmentbonus() {
    return this._doc.environmentbonus;
  }

  get floorPlan() {
    return this._doc.floorplan;
  }

  get totalWeight() {
    return this._doc.totalweight;
  }

  get loadSpaceWidth() {
    return this._doc.loadspacewidth;
  }

  get gearBoxName() {
    return this._doc.gearboxname;
  }
  get horsepower() {
    return this._doc.horsepower;
  }
  get locationName() {
    return this._doc.locationname;
  }
  get seats() {
    return this._doc.seats;
  }

  get isNew() {
    return this._doc.vehiclecondition === "Ny";
  }

  get brandName() {
    return this._doc.brandname;
  }

  get driveShaftName() {
    return this._doc.driveshaftname;
  }

  get privateLeasingPrice() {
    return parseFloat(this._doc.privateleasing);
  }

  get leasing() {
    return parseFloat(this._doc.leasing);
  }

  get leasingOrdinary() {
    return parseFloat(this._doc.leasingordinary);
  }

  get ordinaryPrice() {
    return parseFloat(this._doc.ordinaryprice);
  }

  get currentPrice() {
    return parseFloat(this._doc.currentprice);
  }

  get monthlyCost() {
    return parseFloat(this._doc.monthlycost);
  }

  get monthlyCostOrdinary() {
    return parseFloat(this._doc.monthlycostordinary);
  }

  get ordinaryPriceNoVat() {
    return parseFloat(this._doc.ordinarypricenovat);
  }

  get currentPriceNoVat() {
    return parseFloat(this._doc.currentpricenovat);
  }

  get electricRange() {
    const wltp = parseInt(this._doc.electricrange_wltp ?? 0, 10);
    const nedc = parseInt(this._doc.electricrange_nedc ?? 0, 10);
    return wltp > 0 ? wltp : nedc;
  }

  get taxAmount() {
    const vehicleTax = parseInt(this._doc.vehicletax ?? 0, 10);
    const malusTax = parseInt(this._doc.malustax ?? 0, 10);
    const vehicleTaxNoMalus = parseInt(this._doc.vehicletax_nomalus ?? 0, 10);
    const registrationDate = this._doc.registrationdate
      ? new Date(this._doc.registrationdate.replace(" ", "T"))
      : null;
    const malusDate = new Date();
    malusDate.setFullYear(malusDate.getFullYear() - 3);

    if (registrationDate) {
      if (registrationDate < malusDate) {
        return vehicleTaxNoMalus > 0 ? vehicleTaxNoMalus : vehicleTax;
      } else {
        return malusTax > 0 ? malusTax : vehicleTax;
      }
    } else {
      return malusTax > 0
        ? malusTax
        : vehicleTaxNoMalus > 0
        ? vehicleTaxNoMalus
        : vehicleTax;
    }
  }
  
  get taxCode() {
    return parseInt(this._doc.taxcode ?? 0, 10);
  }

  get tcoMonthlyCost() {
    return this._doc.tcomonthlycost ?? 0;
  }

  get blMonthlyCost() {
    return this._doc.blmonthlycost ?? 0;
  }

  get discount() {
    return parseFloat(this._doc.discount);
  }

  get discountNoVat() {
    return parseFloat(this._doc.discountnovat);
  }

  get sold() {
    const { webpublish } = this._doc;
    return webpublish === "0";
  }

  get bookedFrom() {
    const { bookedfrom } = this._doc;
    return bookedfrom ? new Date(bookedfrom.replace(" ", "T")) : null;
  }

  get bookedTo() {
    const { bookedto } = this._doc;
    return bookedto ? new Date(bookedto.replace(" ", "T")) : null;
  }

  get flexCode() {
    return this._doc.flexloancode;
  }

  get reserved() {
    const { bookedFrom, bookedTo } = this;
    const now = new Date();

    if (!bookedFrom || !bookedTo) {
      return false;
    }

    return (now > bookedFrom && now < bookedTo) || isSameDay(now, bookedTo);
  }

  get available() {
    return !this.sold;
  }

  get noImage() {
    return this._doc.noimage === "true";
  }

  get availableLabel() {
    if (this.sold) {
      return "Såld";
    }

    // if (this.reserved) {
    //   return "Reserverad";
    // }

    // if (this.noImage) {
    //   return "Bild kommer snart";
    // }

    return "";
  }

  get mileage() {
    const { mileage } = this._doc;
    return mileage ? parseInt(mileage, 10) : 0;
  }

  get vehicleCondition() {
    return this._doc.brandmodelsuffix && this._doc.brandmodelsuffix.indexOf("Demo") > -1
      ? "Demo"
      :  this._doc.vehiclecondition || "";
  }

  get shortVehicleCondition() {
    return this._doc.brandmodelsuffix && this._doc.brandmodelsuffix.indexOf("Demo") > -1
      ? "Demo"
      : this.vehicleCondition.substr(0, 3);
  }

  get vehicleType() {
    return this._doc.vehicletype;
  }

  get isPurchasable() {
    return this._doc.ispurchasable == "true";
  }

  get regNr() {
    const { registrationnumber } = this._doc;
    return registrationnumber;
  }

  get trailerWeight() {
    return (
      this._doc.trailerweightbrake12 ??
      this._doc.trailerweightbrake8 ??
      this._doc.trailerweightmax ??
      this._doc.trailerweightb
    );
  }

  get loadCapacity() {
    const { totalweight, curbweight } = this._doc;
    return (
      this._doc.loadcapacity ??
      (parseInt(totalweight, 10) > 0 && parseInt(curbweight, 10) > 0
        ? parseInt(totalweight, 10) - parseInt(curbweight, 10)
        : "")
    );
  }

  get batteryCapacity() {
    return this._doc.batterycapacity;
  }

  get blackWeek() {
    return this._doc.campaigns && this._doc.campaigns.indexOf("Black Week") > -1;
  }

  get newYearBoost() {
    return this._doc.campaigns && this._doc.campaigns.indexOf("NyårsBOOST") > -1;
  }

  get trissIncluded() {
    return this._doc.campaigns && this._doc.campaigns.indexOf("100 Triss ingår") > -1;
  }

  get outlet() {
    return this._doc.campaigns && this._doc.campaigns.indexOf("Biloutlet") > -1;
  }

  get begWeeks() {
    return this._doc.campaigns && this._doc.campaigns.indexOf("Begagnatveckor") > -1;
  }

  get bodyName() {
    return this._doc.bodyname;
  }

  get bodyNameNoPrefix() {
    return this._doc.bodyname_noprefix;
  }

  get colorName() {
    return this._doc.factorycolour;
  }

  get colorNameShort() {
    const color = this._doc.factorycolour;
    if (color && color.length > 10) {
      return color.substr(0, 10) + "...";
    }
    return color;
  }

  get usesChargingStation() {
    const { fuelid } = this._doc;
    return fuelid === I3FuelTypes.Electric;
  }

  get length() {
    return this._doc.length;
  }

  get views() {
    return parseInt(this._doc.views, 10); // or usersViewed?
  }

  get isPopular() {
    return this.views > 100; // TODO
  }

  get mainImage() {
    return this._doc.mainImage;
  }

  get fallbackImage() {
    return this._doc.fallbackimage;
  }

  get images() {
    const images = this._doc.images_url || [];

    if (!images.length && this.mainImage) {
      return [this.mainImage];
    }

    return images;
  }

  get departmentNr() {
    return this._doc.departmentnr;
  }

  get carStatus() {
    return this.available
      ? "available"
      : this.sold
      ? "sold"
      : this.reserved
      ? "reserved"
      : "";
  }

  get displayReport() {
    const departmentNr = this.departmentNr.toString();
    if (departmentNr.startsWith("2")) return false;
    if (this.vehicleCondition == "Begagnad") return true;
    return false;
  }

  get inStock() {
    if (this.campaignTag && this.campaignTag.length > 0) return false;
    if (this._doc.instock === "false") return false;
    if (this.vehicleCondition == "Begagnad") return true;
    if (this.vehicleCondition == "Ny" && Boolean(this._doc.vinnumber))
      return true;
    if (this.vehicleCondition == "Demo") {
      if (this.fuelName != "El" && this.fuelName != "Hybrid el/bensin")
        return true;
      if (this.fuelName == "El" || this.fuelName == "Hybrid el/bensin") {
        const registrationDate = this._doc.registrationdate
          ? new Date(this._doc.registrationdate.replace(" ", "T"))
          : null;
        const todayDate = new Date();
        if (
          registrationDate &&
          registrationDate < todayDate.setMonth(todayDate.getMonth() - 3)
        )
          return true;
      }
    }
    return false;
  }

  get campaignTag() {
    return this._doc.campaigntag || "";
  }

  get isPremium() {
    return this._doc.ispremium == "true";
  }

  get campaignTagPrivate() {
    return this._doc.campaigntagprivate || "";
  }

  get campaignTagCompany() {
    return this._doc.campaigntagcompany || "";
  }

  get permalink() {
    var { modelname } = this._doc;
    var { modelYear, fuelName, brandName, regNr, vehicleType } = this;
    const vehiclePage = window.I3Config.vehiclePage;
    modelname = modelname
      ? modelname
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/ +(?= )/g, "")
          .replace(/[\W_]+/g, "-")
          .trim()
          .toLowerCase()
      : "";
    brandName = brandName
      ? brandName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/ +(?= )/g, "")
          .replace(/[\W_]+/g, "-")
          .trim()
          .toLowerCase()
      : "";

    let fuelOrVehicleType = fuelName;
    if (vehicleType == "Husvagn" || vehicleType == "Husbil")
      fuelOrVehicleType = vehicleType;

    fuelOrVehicleType = fuelOrVehicleType
      ? fuelOrVehicleType
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/ +(?= )/g, "")
          .replace(/[\W_]+/g, "-")
          .trim()
          .toLowerCase()
      : "";
      if (fuelOrVehicleType == "el")
      {
        fuelOrVehicleType = "elbil"
      }
    return `${window.location.origin}${vehiclePage}/${brandName}-${modelname}-${modelYear}-${fuelOrVehicleType}-${regNr}`;
  }
}
