var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "department-main-action__container" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "department-main-action__loader" },
          [
            _c("FontAwesomeIcon", {
              attrs: { icon: _vm.faSpinnerThird, spin: true }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("div", { staticClass: "department-main-action__title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.lang.Contact.DepartmentPageHeading) +
                  "\n      "
              ),
              _vm.selectedDepartment && _vm.selectedDepartment.printUrl
                ? _c(
                    "div",
                    { staticClass: "contact-print u-d-internal-user" },
                    [
                      _c("i", { staticClass: "fal fa-print" }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "link link--primary",
                          attrs: { href: _vm.selectedDepartment.printUrl }
                        },
                        [_vm._v("Skriv ut")]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "department-main-action__select" },
              [
                _c("ConfiguratorSubSelect", {
                  attrs: {
                    options: _vm.departmentOptions,
                    defaultPlaceholder: _vm.selectedDepartment.name
                  },
                  on: { change: _vm.onChangeDepartment }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.selectedDepartment
              ? _c(
                  "div",
                  { staticClass: "department-main-action__button-container" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--primary btn--block call-action",
                        attrs: { href: "tel:" + _vm.selectedDepartment.phoneNr }
                      },
                      [
                        _c("i", { staticClass: "far fa-phone" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "call-action__number call-action__number--toggler"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.selectedDepartment.phoneNr.slice(0, 3)
                                ) +
                                " - "
                            ),
                            _c("span", [_vm._v("XX XX XX")])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "call-action__number" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.selectedDepartment.phoneNr) +
                              "\n        "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn--muted btn--block department-actions__contact",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.openModal($event)
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fal fa-envelope" }),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.lang.Contact.ContactUs) +
                            "\n      "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.selectedDepartment.vehicleListHeading
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn--muted btn--block",
                            attrs: {
                              target: "_blank",
                              href: _vm.selectedDepartment.vehicleListUrl
                            }
                          },
                          [
                            _c("i", {
                              class: _vm.selectedDepartment.vehicleListIcon
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.selectedDepartment.vehicleListHeading
                                ) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedDepartment.serviceHeading
                      ? _c(
                          "a",
                          {
                            staticClass: "btn btn--muted btn--block",
                            attrs: { href: _vm.selectedDepartment.serviceUrl }
                          },
                          [
                            _c("i", {
                              class: _vm.selectedDepartment.serviceIcon
                            }),
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.selectedDepartment.serviceHeading) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedDepartment
              ? _c("div", { staticClass: "department-info-row__section" }, [
                  _c("i", { staticClass: "far fa-map-marker-alt" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "department-info-row__line" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.selectedDepartment.address +
                            ", " +
                            _vm.selectedDepartment.postalCode +
                            ", " +
                            _vm.selectedDepartment.city
                        ) +
                        "\n        "
                    ),
                    _c("span", [
                      _c("br"),
                      _c(
                        "a",
                        {
                          staticClass: "link",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onDirectionsClick($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.lang.Contact.Directions))]
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedDepartment && _vm.selectedDepartment.informationText
              ? _c(
                  "div",
                  { staticClass: "department-info-row__section info" },
                  [
                    _c("i", { staticClass: "fa-light fa-circle-info" }),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.selectedDepartment.informationText
                        )
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedDepartment &&
            _vm.selectedDepartment.openHours.length > 0
              ? _c("div", { staticClass: "department-info-row__section" }, [
                  _c("i", { staticClass: "far fa-clock" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "department-info-row__opening-hours" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "department-info-row__opening-hours__list"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "department-info-row__opening-hours__item",
                              class: {
                                "department-info-row__opening-hours__item--open":
                                  _vm.selectedDepartment.openHours[0].value ===
                                  "isOpen",
                                "department-info-row__opening-hours__item--closed":
                                  _vm.selectedDepartment.openHours[0].value ===
                                  "isClosed"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.selectedDepartment.openHours[0].name
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "department-info-row__opening-hours__item"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.selectedDepartment.openHours[1].name +
                                      " " +
                                      _vm.selectedDepartment.openHours[1].value
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "department-info-row__opening-hours__item"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.selectedDepartment.openHours[2].name +
                                      " " +
                                      _vm.selectedDepartment.openHours[2].value
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "department-info-row__opening-hours__item link",
                              on: { click: _vm.toggleOpeningHoursModal }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.lang.Contact.OpeningHoursCta) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedDepartment && _vm.selectedDepartment.brands.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "department-info-row__section department-info-row__section--no-bottom-padding"
                  },
                  [
                    _c("i", { staticClass: "far fa-badge-check" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "department-info-row__brands" }, [
                      _c(
                        "div",
                        { staticClass: "department-info-row__brands__title" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.selectedDepartment.serviceName) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "department-info-row__brands__list" },
                        _vm._l(_vm.selectedDepartment.brands, function(brand) {
                          return _c(
                            "div",
                            {
                              key: brand.value,
                              staticClass: "department-info-row__brands__item"
                            },
                            [
                              _c("img", {
                                attrs: { src: brand.value, alt: brand.name }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.openingHoursModalOpen
              ? _c("VcModal", { on: { close: _vm.toggleOpeningHoursModal } }, [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(_vm._s("Öppettider"))
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "department-main-action__title" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.selectedDepartment.companyPrefix +
                                " " +
                                _vm.selectedDepartment.city
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "department-main-action__select" },
                      [
                        _c("ConfiguratorSubSelect", {
                          attrs: {
                            options: _vm.departmentOptions,
                            defaultValue: _vm.selectedDepartment.value
                          },
                          on: { change: _vm.onChangeDepartment }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "department-main-action__modal-opening-hours"
                      },
                      [
                        _c("i", { staticClass: "far fa-clock" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "department-info-row__opening-hours__list"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "department-info-row__opening-hours__item",
                                class: {
                                  "department-info-row__opening-hours__item--open":
                                    _vm.selectedDepartment.openHours[0]
                                      .value === "isOpen",
                                  "department-info-row__opening-hours__item--closed":
                                    _vm.selectedDepartment.openHours[0]
                                      .value === "isClosed"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.selectedDepartment.openHours[0].name
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.selectedDepartment.openHours.slice(
                                1,
                                _vm.selectedDepartment.openHours.length
                              ),
                              function(openHour) {
                                return _c(
                                  "div",
                                  {
                                    key: openHour.name,
                                    staticClass:
                                      "department-info-row__opening-hours__item"
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(openHour.name) +
                                          "\n              "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(openHour.value) +
                                          "\n              "
                                      )
                                    ])
                                  ]
                                )
                              }
                            )
                          ],
                          2
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }