<template>
  <div class="properties__item">
    <div class="properties__label">
      {{line.name}}
    </div>
    <div class="properties__value">
      <VcPriceTag :price="line.price" :show-if-zero="!line.hidePrice">
      </VcPriceTag>
      <div class="u-c-attention" v-if="line.message" v-html="line.message">
      </div>
    </div>
  </div>
</template>

<script>
import { VcPriceTag } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcPriceTag,
  },
  props: {
    line: Object,
  },
}
</script>
