var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "regnr-input" }, [
    _c(
      "div",
      { staticClass: "input-group" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("VcFormStack", { attrs: { label: _vm.field.label } }, [
          _c("input", {
            staticClass: "form-control",
            class: { "form-control--invalid": _vm.invalid },
            attrs: { placeholder: _vm.field.placeholder, type: "text" },
            domProps: { value: _vm.field.value },
            on: {
              change: function($event) {
                return _vm.$emit("change", $event.target.value)
              }
            }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }