var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OverlayScrollable",
    [
      _c("OverlayHeader", {
        attrs: {
          slot: "header",
          text: "Varukorg",
          meta: "(" + _vm.cartLineParents.length + " varor)"
        },
        on: { close: _vm.onClose },
        slot: "header"
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "site-cart",
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.cartIsEmpty
            ? _c(
                "div",
                {
                  staticClass:
                    "feedback-message feedback-message--info feedback-message--slim feedback-message--center"
                },
                [
                  _c("div", { staticClass: "feedback-message__text" }, [
                    _vm._v(
                      "\n        Du har inte lagt till några produkter i varukorgen.\n      "
                    )
                  ])
                ]
              )
            : _c("Cart", { attrs: { isAccessoryCart: _vm.isAccessoryCart } })
        ],
        1
      ),
      _vm._v(" "),
      _vm.cartIsEmpty
        ? _c("OverlayFooter", {
            attrs: { slot: "footer", text: "Fortsätt handla" },
            on: { click: _vm.onClose },
            slot: "footer"
          })
        : _c(
            "OverlayFooter",
            {
              attrs: { slot: "footer", text: "Gå till kassan", yellowBg: "" },
              on: { click: _vm.onGoToCheckout },
              slot: "footer"
            },
            [_c("CartSummary")],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }