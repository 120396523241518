var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "contact-forms__form form-feedback-host" }, [
    _vm.cities.length
      ? _c("div", [
          _c("div", { staticClass: "form-stack" }, [
            _c("label", [_vm._v(" Välj anläggning ")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedLocation,
                      expression: "selectedLocation"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedLocation = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      function($event) {
                        return _vm.onLocationChange()
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Välj anläggning")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cities, function(location) {
                    return _c(
                      "option",
                      {
                        key: location.value,
                        domProps: { value: location.value }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(location.name) +
                            "\n          "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.department
      ? _c("div", { staticClass: "info-block" }, [
          _c("i", { staticClass: "info-block__icon fas fa-map-marker-alt" }),
          _vm._v(" "),
          _c("div", { staticClass: "info-block__header" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.department.departmentName) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "u-c-muted" }, [
            _vm._v("\n      " + _vm._s(_vm.department.departmentAddress) + " "),
            _c("br"),
            _vm._v(
              "\n      " +
                _vm._s(_vm.department.departmentZip) +
                " " +
                _vm._s(_vm.department.departmentCity) +
                "\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.department
      ? _c(
          "a",
          {
            staticClass: "btn btn--primary btn--block call-action",
            attrs: {
              href: "tel:" + _vm.department.departmentPhone,
              "data-form-type": "quickForm",
              "data-department-nr":
                _vm.department && _vm.department.departmentNr
            }
          },
          [
            _c("i", { staticClass: "fal fa-phone" }),
            _vm._v(" "),
            _c("div", {
              staticClass: "call-action__number call-action__number--toggler",
              domProps: { innerHTML: _vm._s(_vm.maskedNumber) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "call-action__number" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.department.departmentPhone) + "\n    "
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }