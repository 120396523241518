<template>
  <div class="image-gallery">
    <div class="image-gallery__container">
      <div @click="$emit('close')" class="circle-action">
        <i class="fal fa-times"></i>
      </div>
      <div class="image-gallery__main">
        <div class="swiper-container" ref="swiperContainer">
          <div class="swiper-wrapper">
            <div v-for="image in images" :key="image" class="swiper-slide">
              <img class="image-gallery__main-image" :src="image" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="image-gallery__nav image-gallery__nav--next">
          <i class="fal fa-angle-right"></i>
        </div>
        <div class="image-gallery__nav image-gallery__nav--prev">
          <i class="fal fa-angle-left"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "../../swiper";

export default {
  props: {
    images: {
      type: Array,
      default: () => []
    },
    imageIndex: Number
  },
  data() {
    return {
      swiper: null,
      swiperThumbs: null
    };
  },
  mounted() {

    this.swiper = new Swiper(this.$refs.swiperContainer, {
      spaceBetween: 12,
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true
      },
      navigation: {
        nextEl: ".image-gallery__nav--next",
        prevEl: ".image-gallery__nav--prev",
        disabledClass: "image-gallery__nav--disabled"
      },
    });

    if (this.imageIndex) {
      this.swiper.slideTo(this.imageIndex, 0, false);
    }
  },
  methods: {
    jumpToIndex(index) {
      this.swiper.slideTo(index, 0, false);
    }
  }
};
</script>
