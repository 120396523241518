<template>
  <div class="eon-filter__section"
    :class="{'eon-filter__section--child': section.parent,
             'eon-filter__section--collapsible': section.collapsible,
             'eon-filter__section--collapsed': section.collapsible && collapsed,
             'eon-filter__section--injected-filter': isInjected}">

    <div v-if="section.collapsible"
      class="eon-filter__section--collapsible__header"
      @click="collapsed = !collapsed">
      {{section.header}}

      <div class="badge" v-if="getSectionValueCount(section)">
        {{getSectionValueCount(section)}}
      </div>

      <i v-show="collapsed">
        <i class="fal fa-angle-down"></i>
      </i>
      <i v-show="!collapsed">
        <i class="fal fa-angle-up"></i>
      </i>
    </div>
    <div v-else-if="section.header && (!hideHeader || displayClearButton)" class="eon-filter__section-header">
      <div>
        <span v-show="!hideHeader && !isInjected">
          {{section.header}}
        </span>
      </div>

      <a v-if="displayClearButton"
        class="btn btn--white btn--xs"
        @click="onClear">
        Rensa filter
      </a>
    </div>

    <div v-if="!section.collapsible || !collapsed">
      <filter-section v-for="section in childSections"
        :key="section.type"
        :section="section"
        @fieldChange="$emit('fieldChange', $event)">
      </filter-section>

      <div class="eon-filter__fields">
        <SectionField v-for="field in fields"
          :key="field.type"
          :type="field.type"
          @change="$emit('fieldChange', $event)">
        </SectionField>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SectionField from './SectionField.vue';

export default {
  name: 'filter-section',
  components: {
    SectionField,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
    hideHeader: Boolean,
    isInjected: Boolean,
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    ...mapGetters({
      fieldsBySection: 'fields/bySection',
      childrenByParent: 'sections/childrenByParent',
    }),

    childSections() {
      return this.childrenByParent(this.section.type);
    },

    childSectionFields() {
      return this.childSections.map(cs => this.fieldsBySection(cs.type)).flat();
    },

    fields() {
      return this.fieldsBySection(this.section.type);
    },

    joinedFields() {
      return [
        ...this.fields,
        ...this.childSectionFields,
      ];
    },

    hasActiveFilter() {
      return this.joinedFields.find(field => !!field.value);
    },

    displayClearButton() {
      return this.section.clearButton && this.hasActiveFilter;
    },
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),

    getSectionValueCount(section) {
      let totalCount = 0;
      this.fieldsBySection(section.type).forEach(f => {
        if(Array.isArray(f.value)) {
          totalCount += f.value.length;
        } else if(f.value) {
          totalCount += 1;
        }
      });
      return totalCount;
    },

    onClear() {
      this.joinedFields.filter(f => f.value).forEach(field => {
        this.setField({
          type: field.type,
          value: field.defaultValue,
        });
      });

      this.$emit('fieldsClear');
    },
  },
}
</script>
