import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../constants/FieldTypes';
import ServicesApi from '../../../../models/ServicesApi';

const state = {
  items: [],
  fetchState: null,
};

const getters = {};

const getDaysInMonth = (month, year) => {
  const date = new Date(Date.UTC(year, month, 1));
  const endDate = new Date(Date.UTC(year + 1, month, 1))

  const days = [];

  while (date < endDate) {
    days.push(new Date(date).toISOString().slice(0, 10));
    date.setDate(date.getDate() + 1);
  }

  return days;
}

const actions = {
  async fetchDates({ commit, dispatch }, props) {
    commit('fetchDateRequest');
    const { addons } = props;
    const date = new Date();
    const data = {
      ...props,
      addons: addons && addons.join(','),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
    try {

      const res = await ServicesApi.fetchAvailableDates(data);

      const handledData = res.data;
      const days = getDaysInMonth(data.month, data.year);

      const blockedDays = days.filter(item => !handledData.includes(item));

      commit('fetchDateSuccess', handledData);
      dispatch('fields/set', {
        type: FieldTypes.Date,
        options: blockedDays,
        value: null,
      }, { root: true });
    } catch (error) {
      commit('fetchDateFailure', { error });
      const days = getDaysInMonth(data.month, data.year);
      dispatch('fields/set', {
        type: FieldTypes.Date,
        options: days,
        value: null,
      }, { root: true });
    }
  },
};

const mutations = {
  fetchDateRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchDateSuccess(state, items) {
    state.fetchState = RequestStates.Success;
    state.items = items;
  },

  fetchDateFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
