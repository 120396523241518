import SectionTypeComponents from '../../shared/wizard/constants/SectionTypeComponents';
import SectionTypes from './SectionTypes';
import SectionContentDetails from '../SectionContentDetails.vue';
import SectionContentImages from '../SectionContentImages.vue';
import SectionContentValuation from '../SectionContentValuation.vue';

export default {
  ...SectionTypeComponents,
  [SectionTypes.Details]: SectionContentDetails,
  [SectionTypes.Images]: SectionContentImages,
  [SectionTypes.Valuation]: SectionContentValuation,
}
