import gtm from "../utils/gtm";

export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $("body")
      .on(
        "click",
        ".department-actions__contact",
        this.onContactClick.bind(this)
      )
      .on(
        "click",
        ".department-actions__testdrive",
        this.onTestdriveClick.bind(this)
      )
      .on(
        "click",
        ".js-department-actions__review",
        this.onReviewClick.bind(this)
      );
  },

  onContactClick({ currentTarget }) {
    this.trackContactModalClick($(currentTarget));
  },

  onReviewClick({ currentTarget }) {
    var $el = $(currentTarget);
    var departmentNr = $el.data("department-nr");
    gtm.reviewClick({ departmentNr });
  },

  onTestdriveClick({ currentTarget }) {
    this.trackContactModalClick($(currentTarget));
  },

  trackContactModalClick($el) {
    const departmentNr = $el
      .closest(".department-actions")
      .data("department-nr");
    const label = $el.text().trim();

    gtm.contactModalClick({ departmentNr, label });
  }
};
