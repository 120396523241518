var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "VcOptionBox",
            {
              class: {
                "selected-option": _vm.yellowBg
              },
              attrs: {
                selected: _vm.selected,
                heading: _vm.heading,
                smallText: _vm.smallText,
                smallBlock: _vm.smallText && _vm.smallText.length > 25,
                attentionRibbon: _vm.ribbonText
              },
              on: {
                click: function($event) {
                  return _vm.$emit("click")
                },
                linkClick: _vm.onLinkClick
              }
            },
            [
              _vm.price && !_vm.hidePrice
                ? _c(
                    "div",
                    { attrs: { slot: "header-aside" }, slot: "header-aside" },
                    [
                      _c("VcPriceTag", {
                        attrs: {
                          price: _vm.price,
                          "show-if-zero": _vm.showIfZero
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.modalContent
                ? _c(
                    "div",
                    { attrs: { slot: "header-link" }, slot: "header-link" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.lang.SiteGeneral.ReadMore))
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasSubOption && _vm.selected
                ? _vm._t("content", null, { slot: "content" })
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.modalOpen
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.modalOpen = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")
              ]),
              _vm._v(" "),
              _c("div", {
                attrs: { slot: "content" },
                domProps: { innerHTML: _vm._s(_vm.modalContent) },
                slot: "content"
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }