<template>
  <FieldInput
    :field="field"
    :invalid="invalid && validate"
    @change="onFieldChange(field, $event)"
    @clickchange="onFieldClickChange(field, $event)"
    >
  </FieldInput>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FieldInput from './FieldInput.vue';

export default {
  components: {
    FieldInput,
  },
  props: {
    type: String,
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
      invalidFields: 'fields/invalid',
      sectionByType: 'sections/byType',
    }),

    field() {
      return this.fieldByType(this.type);
    },

    validate() {
      return this.sectionByType(this.field.section).validate;
    },

    invalid() {
      return !!this.invalidFields.find(field => field.type === this.type);
    },
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),

    onFieldChange({ type }, value) {
      this.setField({ type, value });
      this.$emit('change', { type, value })
    },
    onFieldClickChange({ type }, value) {
      this.setField({ type, value });
      this.$emit('clickchange', { type, value })
    },
  },
}
</script>
