var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accessories-product-spot" }, [
    _c("div", { staticClass: "accessories-product-spot__image" }, [
      _c("img", { attrs: { src: _vm.product.imageUrl } })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "accessories-product-spot__desc" }, [
      _c(
        "div",
        { staticClass: "accessories-product-spot__info" },
        [
          _c("div", { staticClass: "accessories-product-spot__meta" }, [
            _vm._v("\n        " + _vm._s(_vm.product.brand) + "\n      ")
          ]),
          _vm._v(" "),
          _c("EonProductTitle", { attrs: { product: _vm.product } })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "accessories-product-spot__buy" },
      [
        _c("div", { staticClass: "accessories-product-spot__price" }, [
          _c(
            "div",
            { staticClass: "accessories-product-spot__price__section" },
            [
              _c(
                "div",
                {
                  staticClass: "price-tag",
                  class: { "price-tag--discount": _vm.product.discountedPrice }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.product.discountedPrice
                          ? _vm.$formatPrice(_vm.product.discountedPrice)
                          : _vm.$formatPrice(_vm.product.ordinaryPrice)
                      ) +
                      "\n        "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.product.discountedPrice
                ? _c("div", { staticClass: "price-tag price-tag--obsolete" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$formatPrice(_vm.product.ordinaryPrice)) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "accessories-product-spot__price__meta" },
                [
                  _c("DeliveryStatusCircle", {
                    attrs: { deliveryStatus: _vm.deliveryStatus, small: "" }
                  }),
                  _vm._v(" " + _vm._s(_vm.deliveryStatusLabel) + "\n        ")
                ],
                1
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("GridItemBuyActions", {
          attrs: { product: _vm.product, regNr: _vm.regNr },
          on: { quantity: _vm.onQuantity, added: _vm.onAdded }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }