<template>
  <div class="my-pages__section">
    <div class="my-pages__section__title-container">
      <span class="my-pages__section__title">{{sectionData ? sectionData.length : 0}} BEVAKNINGAR</span>
    </div>
    <div class="section-item" v-for="subscription in sectionData" :key="subscription.id">
      <div class="section-item__tag-container" @click="$emit('clickSubscription', subscription.searchString)">
        <div
          class="section-item__tag"
          v-for="(tag, index) in subscription.tags"
          :key="tag"
          :class="{'section-item__tag--first-item': index === 0}">
          <span class="section-item__tag__text">{{ tag }}</span>
        </div>
      </div>
      <div class="section-item__subscription-delete" @click="$emit('deleteSubscription', subscription.id)">
        <i class="fas fa-trash"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sectionData: Array,
  },
}
</script>
