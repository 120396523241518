<template>
  <div class="info-block info-block--hours info-block--hours--expanded">
    <i class="info-block__icon fas fa-clock">
    </i>

    <div class="info-block__header">
      Öppettider
    </div>

    <div>
      <div v-for="(hours) in department.openHours"
        :key="hours.name"
        class="info-block--hours__row"
        :class="{'info-block--hours__row--active': hours.name.includes('Idag')}">
        <div>
          {{hours.name}}
        </div>
        <div>
          {{hours.value}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    department: Object,
  },
}
</script>
