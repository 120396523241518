import InputTypes from './InputTypes';
import FieldInputTextarea from '../FieldInputTextarea.vue';
import FieldInputBoolean from '../FieldInputBoolean.vue';
import FieldInputSelect from '../FieldInputSelect.vue';
import FieldInputSelectObject from '../FieldInputSelectObject.vue';
import FieldInputSelectObjectNumber from '../FieldInputSelectObjectNumber.vue';
import FieldInputNumber from '../FieldInputNumber.vue';
import FieldInputText from '../FieldInputText.vue';
import FieldInputTextAppendButton from '../FieldInputTextAppendButton.vue';
import FieldInputPhone from '../FieldInputPhone.vue';
import FieldInputEmail from '../FieldInputEmail.vue';
import FieldInputImage from '../FieldInputImage.vue';
import FieldInputImages from '../FieldInputImages.vue';
import FieldInputRegNr from '../FieldInputRegNr.vue';
import FieldInputOptionBoxes from '../FieldInputOptionBoxes.vue';
import FieldInputOptionBoxesMulti from '../FieldInputOptionBoxesMulti.vue';
import FieldInputDate from '../FieldInputDate.vue';
import FieldInputTime from '../FieldInputTime.vue';

export default {
  [InputTypes.Textarea]: FieldInputTextarea,
  [InputTypes.Boolean]: FieldInputBoolean,
  [InputTypes.Select]: FieldInputSelect,
  [InputTypes.SelectObject]: FieldInputSelectObject,
  [InputTypes.SelectObjectNumber]: FieldInputSelectObjectNumber,
  [InputTypes.Number]: FieldInputNumber,
  [InputTypes.Text]: FieldInputText,
  [InputTypes.TextAppendButton]: FieldInputTextAppendButton,
  [InputTypes.Phone]: FieldInputPhone,
  [InputTypes.Email]: FieldInputEmail,
  [InputTypes.Image]: FieldInputImage,
  [InputTypes.Images]: FieldInputImages,
  [InputTypes.RegNr]: FieldInputRegNr,
  [InputTypes.OptionBoxes]: FieldInputOptionBoxes,
  [InputTypes.OptionBoxesMulti]: FieldInputOptionBoxesMulti,
  [InputTypes.Date]: FieldInputDate,
  [InputTypes.Time]: FieldInputTime,
}
