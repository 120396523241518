import Contact from "../apps/contact";
import mountApp from "../utils/mount-app";
import qs from 'query-string';
import Breakpoints from '../constants/Breakpoints';

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    const sectionHandle = qs.parse(window.location.search).sectionHandle;

    mountApp({
      app: Contact,
      selector: '.site-content [data-app=contact]',
      props: {
        sectionHandle,
      },
    });

    if($(window).width() > Breakpoints.md) {
      let maxHeight = 0;
      $('.card').each(function() {
        const height = $(this).height();
        maxHeight = height > maxHeight ? height : maxHeight;
      });
      $('.card').css('height', maxHeight);
    }


  },
};
