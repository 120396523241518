import FieldTypes from "../constants/FieldTypes";
import InputTypes from "../../shared/wizard/constants/InputTypes";
import SectionTypes from "../constants/SectionTypes";
import RegExPatterns from "../../../constants/RegExPatterns";

export default [
  {
    type: FieldTypes.CustomerIsWaiting,
    value: false
  },
  {
    label: "Verkstad",
    type: FieldTypes.DepartmentNr
  },
  {
    label: "Regnummer",
    type: FieldTypes.RegNr,
    inputPattern: RegExPatterns.RegistrationNumber
  },
  {
    section: SectionTypes.Vehicle,
    type: FieldTypes.VehicleInfoRegNr,
    label: "Registreringsnummer",
    hidden: true
  },
  {
    section: SectionTypes.Vehicle,
    type: FieldTypes.VehicleName,
    label: "Fordonsnamn",
    hidden: true
  },
  {
    section: SectionTypes.Department,
    type: FieldTypes.DepartmentName,
    label: "Anläggning",
    hidden: true
  },
  {
    section: SectionTypes.RimType,
    label: "Kommentarer",
    shortLabel: "Kommentarer",
    placeholder: "Kommentarer",
    type: FieldTypes.Message,
    inputType: InputTypes.Textarea,
    required: false
  },
  {
    section: SectionTypes.RimType,
    label: "Typ av fälg",
    shortLabel: "Typ av fälg",
    type: FieldTypes.RimType,
    inputType: InputTypes.OptionBoxes,
    required: true
  },
  {
    section: SectionTypes.RimSize,
    label: "Ladda upp bilder på fälgarna",
    shortLabel: "Bilder",
    placeholder: "Välj bild",
    type: FieldTypes.Images,
    inputType: InputTypes.Images,
    value: [],
    required: false
  },
  {
    section: SectionTypes.RimSize,
    label: "Tumstorlek på fälgarna",
    shortLabel: "Tumstorlek",
    type: FieldTypes.RimSize,
    inputType: InputTypes.SelectObject,
    value: {
      name: "17 tum",
      value: "17"
    },
    required: true,
    options: [
      {
        name: "17 tum",
        value: "17"
      },
      {
        name: "18 tum",
        value: "18"
      },
      {
        name: "19 tum",
        value: "19"
      },
      {
        name: "20 tum",
        value: "20"
      },
      {
        name: "21 tum",
        value: "21"
      },
      {
        name: "22 tum",
        value: "22"
      },
      {
        name: "23 tum",
        value: "23"
      },
      {
        name: "24 tum",
        value: "24"
      },
    ]
  },
  {
    section: SectionTypes.RimSize,
    label: "Antal fälgar",
    shortLabel: "Antal fälgar",
    type: FieldTypes.RimAmount,
    inputType: InputTypes.OptionBoxes,
    value: [{
      id: "1",
      name: "1",
      value: "1",
      selected: true,
      heading: "1 st",
      checkbox: false
    },],
    required: true,
    options: [
      {
        id: "1",
        name: "1",
        value: "1",
        selected: true,
        heading: "1 st",
        checkbox: false
      },
      {
        id: "2",
        name: "2",
        value: "2",
        selected: false,
        heading: "2 st",
        checkbox: false
      },
      {
        id: "3",
        name: "3",
        value: "3",
        selected: false,
        heading: "3 st",
        checkbox: false
      }
      ,
      {
        id: "4",
        name: "4",
        value: "4",
        selected: false,
        heading: "4 st",
        checkbox: false
      }
    ]
  },
  {
    section: SectionTypes.RimSize,
    type: FieldTypes.TyreHotel,
    label: "Gäller det hjul som finns på däckhotell på angiven ort",
    inputType: InputTypes.OptionBoxes,
    required: true,
    value: [{
      id: "nej",
      name: "nej",
      value: "nej",
      selected: true,
      heading: "Nej",
      checkbox: false
    },],
    options: [
      {
        id: "nej",
        name: "nej",
        value: "nej",
        selected: true,
        heading: "Nej",
        checkbox: false
      },
      {
        id: "ja",
        name: "ja",
        value: "ja",
        selected: false,
        heading: "Ja",
        checkbox: false
      },
    ]
  },
  {
    section: SectionTypes.Contact,
    label: "Namn",
    placeholder: "Vad heter du?",
    type: FieldTypes.Name,
    inputType: InputTypes.Text,
    autocomplete: "name",
    required: true
  },
  {
    section: SectionTypes.Contact,
    label: "E-post",
    placeholder: "Ange din e-postadress",
    type: FieldTypes.Email,
    inputType: InputTypes.Email,
    inputPattern: RegExPatterns.Email,
    autocomplete: "email",
    required: true
  },
  {
    section: SectionTypes.Contact,
    label: "Mobilnummer",
    placeholder: "070-123 45 67",
    type: FieldTypes.Phone,
    inputType: InputTypes.Phone,
    inputPattern: RegExPatterns.Phone,
    autocomplete: "tel",
    required: true
  }
];
