import InputTypes from './InputTypes';
import FieldInputTabButtons from '../filter-widget/FieldInputTabButtons.vue';
import FieldInputCheckboxes from '../filter-widget/FieldInputCheckboxes.vue';
import FieldInputNumber from '../filter-widget/FieldInputNumber.vue';
import FieldInputSelectObject from '../filter-widget/FieldInputSelectObject.vue';
import FieldInputRadios from '../filter-widget/FieldInputRadios.vue';
import FieldInputStars from '../filter-widget/FieldInputStars.vue';
import FieldInputCheckboxBool from '../filter-widget/FieldInputCheckboxBool.vue';

export default {
  [InputTypes.TabButtons]: FieldInputTabButtons,
  [InputTypes.Checkboxes]: FieldInputCheckboxes,
  [InputTypes.Number]: FieldInputNumber,
  [InputTypes.SelectObject]: FieldInputSelectObject,
  [InputTypes.Radios]: FieldInputRadios,
  [InputTypes.Stars]: FieldInputStars,
  [InputTypes.CheckboxBool]: FieldInputCheckboxBool,
}
