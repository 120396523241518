<template>
  <VcModal @close="$emit('modalClose')">
    <div slot="header">
      {{modalHeader}}
    </div>
    <div slot="content" class="choose-dimensions">
      <SelectManual v-if="manualSelect"
        :tyreSize="tyreSize"
        @change="onChange">
      </SelectManual>
      <SelectRecommended v-else
        :sizes="TyreSpec.recommendedSizes"
        :tyreSize="tyreSize"
        :carName="TyreSpec.carName"
        @change="onChange">
      </SelectRecommended>
      <VcFormStack label="Produkt">
        <VcFormSelect
          :options="[{ value: 'tyre', label: lang.Tyre.TyreLinkBtn }, { value: 'wheel', label: lang.Tyre.WheelLinkBtn }, { value: 'rim', label: lang.Tyre.RimLinkBtn }]"
          :value="productType"
          labelProp="label"
          valueProp="value"
          valueType="string"
          required
          @change="productType = $event">
        </VcFormSelect>
      </VcFormStack>
      <VcFormStack label="Säsong">
        <VcFormSelect
          :options="[{ value: EonTyreTypes.Summer, label: 'Sommar' }, { value: EonTyreTypes.WinterStudded, label: 'Vinter dubb' }, { value: EonTyreTypes.WinterFriction, label: 'Vinter friktion' }]"
          :value="tyreType"
          labelProp="label"
          valueProp="value"
          valueType="number"
          required
          @change="tyreType = $event">
        </VcFormSelect>
      </VcFormStack>
      <div
        class="btn btn--primary btn--block choose-spec__multi__btn"
        :class="{'disabled': buttonDisabled}"
        @click="onSubmit()"
      >
        {{ "Se produkter" }}
      </div>
      <p class="form-text">
        När man byter tum-storlek och endast köper lösa däck är det viktigt att man kontrollerar så att däckets tum stämmer överens med ens nuvarande fälgar. Annars kommer det inte gå att montera däcket på den gamla fälgen.
      </p>
    </div>
  </VcModal>
</template>

<script>
import { VcModal, VcFormStack, VcFormSelect } from '@holmgrensbil/vue-components';
import SelectManual from './SelectManual.vue';
import SelectRecommended from './SelectRecommended.vue';
import EonTyreTypes from "../eon-filter-page/constants/EonTyreTypes";

export default {
  components: {
    SelectManual,
    SelectRecommended,
    VcModal,
    VcFormStack,
    VcFormSelect,
  },
  props: {
    TyreSpec: Object,
    modalHeader: String,
    tyreButtonHref: String,
    rimButtonHref: String,
    wheelButtonHref: String,
    forceManual: Boolean,
    lang: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tyreSize: null,
      productType: { value: 'tyre', label: this.lang.Tyre.TyreLinkBtn },
      tyreType: { value: EonTyreTypes.Summer, label: "Sommar" },

      EonTyreTypes
    };
  },
  computed: {
    buttonDisabled() {
      return !(this.tyreSize.width && this.tyreSize.aspectRatio && this.tyreSize.diameter);
    },
    manualSelect() {
      return this.TyreSpec.recommendedSizes.length === 0 || this.forceManual;
    },
  },
  created() {
    this.tyreSize = { ...this.TyreSpec.tyreSize };

    this.tyreType = window.EonConfig.isWinterSeason
      ? { value: EonTyreTypes.WinterFriction, label: 'Vinter friktion' }
      : { value: EonTyreTypes.Summer, label: 'Sommar' };
  },
  methods: {
    onChange(tyreSize) {
      this.tyreSize = tyreSize;
    },

    onSubmit() {
      if (this.buttonDisabled) {
        return;
      } else {
        if(this.manualSelect) {
          this.TyreSpec.clear();
        }
        this.TyreSpec.set({
          tyreSize: this.tyreSize,
        });
  
        let href = '';
  
        switch (this.productType.value) {
          case 'tyre': {
            href = this.tyreButtonHref;
            break;
          }
          case 'rim': {
            href = this.rimButtonHref;
            break;
          }
          case 'wheel': {
            href = this.wheelButtonHref;
            break;
          }
          default: break;
        }
  
        window.location.href = href + `&tyreType=${this.tyreType.value}`;
      }
    },
  },
}
</script>
