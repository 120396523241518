<template>
    <div class="field-input-images">
      <div class="field-input-images__grid">
        <div v-for="field in sectionFields"
          :key="field.type"
          class="field-input-images__grid-item">
          <SectionField :type="field.type">
          </SectionField>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import SectionField from '../shared/wizard/SectionField.vue';
  
  export default {
    components: {
      SectionField,
    },
    props: {
      section: Object,
    },
    computed: {
      ...mapGetters({
        fieldsBySection: 'fields/bySection',
      }),
  
      sectionFields() {
        return this.fieldsBySection(this.section.type);
      },
    },
  }
  </script>