var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tooltip-container",
      attrs: { slot: "content" },
      slot: "content"
    },
    [
      _c(
        "div",
        {
          staticClass: "tooltip-content tooltip-content--arrow",
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _c("p", { staticClass: "tooltip-title" }, [
            _vm._v("\n      Välkommen till Holmgrens Däckshop!\n    ")
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "tooltip-content-text" }, [
            _vm._v(
              "\n      Däckshoppen är nu filtrerad med kompletta hjul,\n      enbart däck, enbart fälg och begagnade hjul som\n      passar ditt fordon.\n    "
            )
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "tooltip-product" }, [
            _vm._v(
              "\n      Nu visas produkter med däckdimension: " +
                _vm._s(_vm.TyreSpec.tyreSize.diameter) +
                '" ' +
                _vm._s(_vm.TyreSpec.tyreSize.width) +
                "/" +
                _vm._s(_vm.TyreSpec.tyreSize.aspectRatio) +
                "R" +
                _vm._s(_vm.TyreSpec.tyreSize.diameter) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn btn--white btn--block",
              class: { disabled: _vm.buttonDisabled },
              on: {
                click: function($event) {
                  return _vm.$emit("openTyreSelector")
                }
              }
            },
            [_vm._v("\n      Ändra däckdimensioner\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn btn--primary btn--block",
              class: { disabled: _vm.buttonDisabled },
              on: {
                click: function($event) {
                  return _vm.$emit("markAsSeen")
                }
              }
            },
            [_vm._v("\n      Okej, stäng denna ruta!\n    ")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }