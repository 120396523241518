var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.pickupFromShareUrl
      ? _c(
          "div",
          [
            _c(
              "VcFormStack",
              { attrs: { label: "Anläggning", required: "" } },
              [
                _c("VcFormSelect", {
                  attrs: {
                    prompt: "Välj anläggning",
                    options: _vm.pickupItems,
                    value: _vm.pickupSelected,
                    valueProp: "departmentNr",
                    valueType: "number",
                    labelProp: "name",
                    required: ""
                  },
                  on: { change: _vm.onChange }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.selectedDepartment && !_vm.departmentLoading
              ? _c(
                  "div",
                  { staticClass: "row checkout__selected-department" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-sm-6 order-last order-sm-first checkout__selected-department-delivery"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "delivery-status-circle" },
                          [
                            _c("DeliveryStatusCircle", {
                              attrs: {
                                deliveryStatus: _vm.deliveryStatus.status
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _c("h4", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.deliveryStatus.title) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.deliveryStatus.description) +
                                "\n          "
                            )
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "div",
                        { staticClass: "checkout__selected-department-map" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "checkout__selected-department-map-image",
                              style: {
                                "background-image":
                                  "url(" +
                                  _vm.selectedDepartment.googleMapsImageUrl +
                                  ")"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--white btn--sm",
                                  attrs: {
                                    href: _vm.selectedDepartment.directionsUrl,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.lang.Checkout.Department.OpenMap
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.cartHasServices
                            ? _c(
                                "VcFeedbackMessage",
                                { attrs: { success: "", slim: "" } },
                                [
                                  _c("i", { staticClass: "fas fa-check" }),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.lang.Checkout.Department.Eligible
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    !_vm.isAccessoryCheckout
                      ? _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "form-check form-check--sm form-check--inline checkout__selected-department-tyre-hotel-order"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.isTyreHotelOrder,
                                    expression: "isTyreHotelOrder"
                                  }
                                ],
                                staticClass: "form-check__input",
                                attrs: {
                                  id: "tyreHotelOrder",
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.isTyreHotelOrder)
                                    ? _vm._i(_vm.isTyreHotelOrder, null) > -1
                                    : _vm.isTyreHotelOrder
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.isTyreHotelOrder,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.isTyreHotelOrder = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.isTyreHotelOrder = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.isTyreHotelOrder = $$c
                                      }
                                    },
                                    _vm.onTyreHotelOrderChange
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check__label",
                                  attrs: { for: "tyreHotelOrder" }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.lang.Checkout.TyreHotel.Check
                                      ) +
                                      "\n            "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "#",
                                        "data-target":
                                          _vm.checkoutTyreHotelModal,
                                        "data-modal-source": "ajax"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-question-circle"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ],
          1
        )
      : _c("div", [
          _vm.selectedDepartment && !_vm.departmentLoading
            ? _c("div", { staticClass: "row checkout__selected-department" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 order-last order-sm-first checkout__selected-department-delivery"
                  },
                  [
                    _c("div", [
                      _c("h4", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.selectedDepartment.name) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.lang.Checkout.TyreHotel.Info) +
                            "\n          "
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "checkout__selected-department-map" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "checkout__selected-department-map-image",
                          style: {
                            "background-image":
                              "url(" +
                              _vm.selectedDepartment.googleMapsImageUrl +
                              ")"
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn--white btn--sm",
                              attrs: {
                                href: _vm.selectedDepartment.directionsUrl,
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.lang.Checkout.Department.OpenMap) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }