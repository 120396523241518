var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-main-action__section" }, [
    _c("div", { staticClass: "product-main-action__row" }, [
      _c(
        "div",
        {
          staticClass: "product-main-action__row__action-container",
          on: {
            click: function($event) {
              return _vm.$emit("onOpenRow", _vm.rowKey)
            }
          }
        },
        [
          _c("div", { staticClass: "product-main-action__row__title" }, [
            _vm._v("\n        " + _vm._s(_vm.rowTitle) + "\n      ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-main-action__row__value-container" },
            [
              _vm.isRowOpen
                ? _c(
                    "div",
                    {
                      staticClass:
                        "product-main-action__row__value product-main-action__row__value--static"
                    },
                    [_vm._v("\n          Dölj\n        ")]
                  )
                : _c("div", [_vm._t("row-value")], 2),
              _vm._v(" "),
              _c("font-awesome-icon", {
                staticClass: "product-main-action__row__arrow",
                class: {
                  "product-main-action__row__arrow--open": _vm.isRowOpen
                },
                attrs: { icon: _vm.faChevronDown }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.isRowOpen ? _c("div", [_vm._t("row-content")], 2) : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }