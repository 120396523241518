<template>
  <SectionField :type="FieldTypes.Department">
  </SectionField>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';

export default {
  components: {
    SectionField,
  },
  data() {
    return {
      FieldTypes,
    };
  },
}
</script>
