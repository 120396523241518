var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "service-agreement-block" },
    [
      _c("CarInfo"),
      _vm._v(" "),
      _vm.store.lookupResult ? _c("AdditionalCarInfo") : _vm._e(),
      _vm._v(" "),
      _vm.store.hasInstances ? _c("Instances") : _vm._e(),
      _vm._v(" "),
      _vm.store.hasAdditionalProducts ? _c("Addons") : _vm._e(),
      _vm._v(" "),
      _vm.store.hasInstances ? _c("Summary") : _vm._e(),
      _vm._v(" "),
      _vm.store.shouldShowCustomerInfo ? _c("Customer") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }