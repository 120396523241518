var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout__select-vehicle" },
    [
      _c("div", { staticClass: "checkout__select-vehicle__description" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.lang.Checkout.VehicleInformation.Description) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "regnr-input" }, [
        _c("div", { staticClass: "input-group" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "form-stack" }, [
            _c("label", [_vm._v("\n          Regnummer\n        ")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.regNr,
                  expression: "regNr"
                }
              ],
              staticClass: "form-control",
              staticStyle: { color: "#18191a" },
              attrs: {
                id: "regNr-input",
                type: "text",
                placeholder: "ABC123",
                required: !_vm.hasMultipleRegNumbers,
                pattern: "^\\w{3}\\d{2}(\\w{1}|\\d{1})$"
              },
              domProps: { value: _vm.regNr },
              on: {
                keyup: function($event) {
                  return _vm.onChange()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.regNr = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("VcOptionBox", {
        attrs: {
          selected: _vm.hasMultipleRegNumbers,
          heading: _vm.lang.Checkout.VehicleInformation.OptionHeading
        },
        on: { click: _vm.onSetMultipleRegNumbers }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }