<template>
  <ul class="list-unstyled properties">
    <li class="properties__item properties__item--header">
      <div class="properties__label">
        {{total.heading}}

        <div class="properties__meta">
          {{total.subHeading}}
        </div>
      </div>
      <div class="properties__value">
        <VcPriceTag :price="total.price" total></VcPriceTag>
      </div>
    </li>
  </ul>
</template>

<script>
import { VcPriceTag } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcPriceTag,
  },
  props: {
    total: Object,
  },
}
</script>
