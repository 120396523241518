<template>
  <div>
    <div v-if="fields.length > 0">
      {{text}}
    </div>
    <div v-if="customText">
      {{ customText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fields: Array,
    customText: {
      type: String,
      required: false,
      default: '',
    }
  },
  computed: {
    text() {
      const fieldNames = this.fields.map(field => field.shortLabel || field.label);
      let invalidStr = this.fields.length > 1 ? 'Obligatoriska fält' : 'Obligatoriskt fält';

      return `${invalidStr}: ${fieldNames.join(', ')}`;
    },
  },
}
</script>
