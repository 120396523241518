<template>
  <div v-if="field.value"
    class="file-thumbnail"
    :style="{'background-image': `url(${field.value}?${ImageResizerPresets.FileThumbnail}`}">
    <div class="file-thumbnail__delete"
      @click="onDeleteImage(field)">
      <i class="fal fa-times"></i>
    </div>
  </div>
  <VcInputFile v-else
    :label="label"
    @change="onFileChange">
    <div slot="icon">
      <font-awesome-icon
        :icon="uploading ? faSpinnerThird : faCameraAlt"
        :spin="uploading ? true : false">
      </font-awesome-icon>
    </div>
  </VcInputFile>
</template>

<script>
import { VcInputFile } from '@holmgrensbil/vue-components';
import { mapGetters } from 'vuex';
import ImageResizerPresets from '../../../constants/ImageResizerPresets';
import FieldTypes from './constants/FieldTypes';
import { uploadImage, deleteImage } from '../../../utils/image-service';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird, faCameraAlt } from "@fortawesome/pro-light-svg-icons";

export default {
  components: {
    VcInputFile,
    FontAwesomeIcon,
  },
  props: {
    field: Object,
  },
  data() {
    return {
      deleteKey: null,
      ImageResizerPresets,
      uploading: false,
      faSpinnerThird,
      faCameraAlt,
    };
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
    }),
    label() {
      return this.uploading ? 'Laddar upp...' : (this.field.required ? `${this.field.label}*` : `${this.field.label}`);
    },
  },
  methods: {
    onFileChange(file) {
      const folder = this.fieldByType(FieldTypes.RegNr).value;

      this.uploading = true;
      uploadImage(file, folder).then(res => {
        const {
          imageUrl,
          deleteKey,
        } = res.data;

        this.deleteKey = deleteKey;
        this.uploading = false;
        this.$emit('change', imageUrl);
      });
    },

    onDeleteImage(field) {
      deleteImage(this.deleteKey);
      this.$emit('change', null);
    },
  },
}
</script>
