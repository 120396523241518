import FieldTypes from '../constants/FieldTypes';
import InputTypes from '../../shared/wizard/constants/InputTypes';
import fields from '../../shared/wizard/data/fields';
import SectionTypes from '../constants/SectionTypes';

export default [
  ...fields,
  {
    section: SectionTypes.Details,
    label: 'Mätarställning (mil)',
    shortLabel: 'Mätarställning',
    placeholder: 'Mätarställning',
    type: FieldTypes.Mileage,
    inputType: InputTypes.Number,
    required: true,
  },
  {
    section: SectionTypes.Period,
    label: 'Avtalsperiod',
    shortLabel: 'Period',
    type: FieldTypes.Period,
    inputType: InputTypes.OptionBoxes,
    options: [],
    required: true,
  },
  {
    section: SectionTypes.Period,
    label: 'Tillval',
    type: FieldTypes.PeriodAddons,
    inputType: InputTypes.OptionBoxesMulti,
    options: [
      {
        heading: "Serviceabonnemang Plus",
        headingMeta: "Däckhotell, hjulskifte 2 ggr/år, och tvätt av hjulen",
        name: "servicePlus",
        selected: false,
        subOptionsText: "",
        subOptions: [],
        price: {
          ordinary: 99,
          current: 99,
          prefix: "",
          suffix: " kr/mån",
          attention: false
        }
      },
    ],
    value: [],
    required: false,
  },
]
