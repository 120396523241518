<template>
  <div class="reviews-widget__star-list">
    <div
      v-for="(star, index) in getStarArray(rating)"
      :key="listId + index"
      class="reviews-widget__star-container"
    >
      <img src="/Static/images/pale-star.svg" class="reviews-widget__star">
      <img
        src="/Static/images/gold-star.svg"
        class="reviews-widget__star reviews-widget__star--active"
        :style="`width: ${width * star}px`"
      >
    </div>
  </div>
</template>

<script>
import ratingConverter from './utils/ratingConverter';
export default {
  props: {
    rating: Number | String,
    listId: String,
    width: Number
  },
  methods: {
    getStarArray(ratingInput) {
      let rating = ratingInput;

      if (typeof ratingInput === "string") {
        rating = ratingConverter(ratingInput);
      }

      let turns = 4;
      const arr = [];
      while (turns >= 0) {
        if (rating >= 1) {
          arr.push(1);
        } else if (rating <= 0) {
          arr.push(0);
        } else {
          arr.push(rating);
        }
        rating = rating - 1;
        turns = turns - 1;
      }

      return arr.length > 5 ? arr.slice(0, arr.length - 1) : arr;
    },
  }
}
</script>
