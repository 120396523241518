var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [
      _vm._v(
        "\n    Rekommenderade storlekar till " + _vm._s(_vm.carName) + "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.sizes, function(size, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "tab-button tab-button--horizontal tab-button--inline",
            class: { "tab-button--active": _vm.isSelected(size) },
            on: {
              click: function($event) {
                return _vm.onSelect(size)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.getSizeString(size)) + "\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }