<template>
  <div
    class="wizard wizard--service-top wizard--damage-report"
    v-if="departmentSelected"
  >
    <SectionList
      :sections="sections"
      :SectionTypeComponents="SectionTypeComponents"
      @completed="onSectionCompleted"
    >
    </SectionList>
    <FormSubmit
      :lastSection="lastSection"
      @completed="onSectionCompleted"
      :alternateTranslationKeys="
        isExpress ? alternateTranslationKeys : undefined
      "
    >
    </FormSubmit>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import SectionTypes from "./constants/SectionTypes";
import FieldTypes from "./constants/FieldTypes";
import FormSubmit from "../shared/wizard/FormSubmit.vue";
import SectionList from "../shared/wizard/SectionList.vue";
import SectionTypeComponents from "./constants/SectionTypeComponents";
import ServiceBookingManager from "../../models/ServiceBookingManager";
import gtm from "../../utils/gtm";
import isDefined from "../../utils/is-defined";
import AlternateTranslationKeys from "./constants/AlternateTranslationKeys";
import ServicesApi from "../../models/ServicesApi";

export default {
  components: {
    SectionList,
    FormSubmit
  },
  props: {
    pageName: String
  },
  data() {
    return {
      SectionTypeComponents,
      regNr: null,
      departmentNr: null,
      availableDays: [],
      service: null,
      alternateTranslationKeys: AlternateTranslationKeys,
      carInfo: null
    };
  },
  created() {
    if (ServiceBookingManager.carInfo) {
      this.carInfo = ServiceBookingManager.carInfo;
    }

    ServiceBookingManager.onCarInfoSet(e => {
      if (e.detail.carInfo) {
        this.carInfo = e.detail.carInfo;
      }
    });

    this.setField({ type: FieldTypes.PageName, value: this.pageName });

    if (ServiceBookingManager.service) {
      this.service = ServiceBookingManager.service;
      this.setService({ service: ServiceBookingManager.service });
    }
    ServiceBookingManager.onServiceSet(e => {
      if (e.detail.service) {
        this.setEndpoint(`/api/services/${e.detail.service.value}`);
        this.service = e.detail.service;
        this.setService({ service: e.detail.service });
      }
    });

    ServiceBookingManager.onRegNrSet(async e => {
      const regNr = e.detail.regNr;

      if (this.regNr && this.regNr !== regNr) {
        this.resetState();
        this.departmentNr = null;
      }

      this.setField({ type: FieldTypes.RegNr, value: regNr });

      this.regNr = regNr;
      this.setRegNr(regNr);
      let section = { type: "Vehicle", order: 0 };
      gtm.damageInspectionSectionCompleted({ section });
    });

    ServiceBookingManager.onDepartmentSet(e => {
      if (e.detail.department) {
        const departmentNr = e.detail.department.value;
        const departmentName = e.detail.department.name;

        this.departmentNr = departmentNr;
        this.fetchReportTypes({
          departmentNr: departmentNr,
          service: this.service.value
        });
        this.setField({ type: FieldTypes.DepartmentNr, value: departmentNr });
        this.setField({
          type: FieldTypes.DepartmentName,
          value: departmentName
        });
        let section = { type: "Department", order: 0 };
        gtm.damageInspectionSectionCompleted({ section });
      } else {
        this.departmentNr = null;
      }
    });
  },
  computed: {
    ...mapState({
      sections: state => state.sections.items
    }),

    ...mapGetters({
      sectionByType: "sections/byType",
      fieldByType: "fields/byType"
    }),

    lastSection() {
      return this.sectionByType(SectionTypes.Summary);
    },
    isExpress() {
      const value = this.fieldByType(FieldTypes.TypeOfReport).value;
      return value ? Boolean(value.find(i => i.name === "express")) : false;
    },
    departmentSelected() {
      return isDefined(this.departmentNr);
    }
  },
  methods: {
    ...mapActions({
      setField: "fields/set",
      fetchAddons: "addons/fetch",
      fetchReportTypes: "reportTypes/fetchAvailableReportTypes",
      fetchAvailableDays: "dates/fetchDates",
      filterSections: "sections/filterSections",
      setEndpoint: "form/load",
      setService: "service/setService",
      setRegNr: "fields/setRegNr",
      resetState: "config/reset"
    }),

    onSectionCompleted(section) {
      if (section.type === SectionTypes.TypeOfReport) {
        this.onTypeOfReportSectionComplete();
      }
      if (section.type === SectionTypes.Addons) {
        this.onAddonsCompleted();
      }
      const departmentNr = this.departmentNr;
      const serviceType = this.isExpress ? "express" : "time";
      const brandName = this.carInfo ? this.carInfo.brand : null;
      gtm.damageInspectionSectionCompleted({
        section,
        departmentNr,
        serviceType,
        brandName
      });
    },
    onTypeOfReportSectionComplete() {
      this.setField({ type: FieldTypes.IsExpress, value: this.isExpress });
      [
        FieldTypes.DamagePlacement,
        FieldTypes.DamageText,
        FieldTypes.Mileage,
        FieldTypes.ImageBackRight,
        FieldTypes.ImageFrontRight,
        FieldTypes.ImageFrontLeft,
        FieldTypes.ImageBackLeft,
        FieldTypes.ImageOverview,
        FieldTypes.ImageZoom,
        FieldTypes.ImageMeter
      ].forEach(fieldType =>
        this.setField({ type: fieldType, required: this.isExpress })
      );

      this.setField({ type: FieldTypes.IsExpress, value: this.isExpress });
      [FieldTypes.Date, FieldTypes.Time].forEach(fieldType =>
        this.setField({ type: fieldType, required: !this.isExpress })
      );

      if (!this.isExpress) {
        this.fetchAvailableDays({
          departmentNr: this.departmentNr,
          service: this.service.value,
          regNr: this.regNr
        });
      }

      this.fetchAddons({
        departmentNr: this.departmentNr,
        service: this.service.value,
        regNr: this.regNr,
        serviceType: this.service.value,
      });
    },
    onAddonsCompleted() {
      const selectedAddons = [];
      this.fieldByType(FieldTypes.Addons).options.forEach(option =>
        option.options.forEach(subOption => {
          if (subOption.selected) {
            selectedAddons.push(subOption.id);
          }
        })
      )
      this.setField({
        type: FieldTypes.Addons,
        value: selectedAddons
      });
    }
  },
  watch: {
    isExpress() {
      this.filterSections(s =>
        this.isExpress ? s.type !== SectionTypes.Date && !s.hidden : !s.hidden
      );
    }
  }
};
</script>
