import FieldTypes from '../constants/FieldTypes';
import ServicesApi from '../../../../models/ServicesApi';
import RequestStates from '../../../../constants/RequestStates';
const state = {
  items: [],
  fetchState: null,
};

const getters = {};

const actions = {
  async fetchTimes({ commit, dispatch }, props) {
    commit('fetchTimesRequest');
    const { addons } = props;
    try {
      const data = {
        ...props,
        addons: addons && addons.join(','),
      };

      const res = await ServicesApi.fetchAvailableTimes(data);

      commit('fetchTimesSuccess', res.data);
      dispatch('fields/set', {
        type: FieldTypes.Time,
        options: res.data,
        value: null,
      }, { root: true });
    } catch (error) {
      commit('fetchTimesFailure', { error });
    }
  },
};

const mutations = {
  fetchTimesRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchTimesSuccess(state, items) {
    state.fetchState = RequestStates.Success;
    state.items = items;
  },

  fetchTimesFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
