export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $('body').
      on('click', '[data-toggler]', this.onClick.bind(this));
  },

  onClick({ currentTarget }) {
    const $currentTarget = $(currentTarget);
    const $target = $($currentTarget.data('toggle-target'));
    $target.toggle();
    if($currentTarget.data('toggle-self')) {
      $currentTarget.toggle();
    }
    return false;
  },
}
