<template>
  <div>
    <div class="product-meta">
      <div class="product-meta__links">
        <div v-for="modal in modals"
          :key="modal.name"
          class="product-meta__links__item">
          <a class="link link--underline" @click="onClick(modal)">
            {{modal.name}}
          </a>
        </div>
      </div>
    </div>

    <VcModal v-if="activeModal"
      @close="activeModal = null">
      <div slot="header">
        {{activeModal.name}}
      </div>
      <div slot="content" v-html="activeModal.value">
      </div>
    </VcModal>
  </div>
</template>

<script>
import { VcModal } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcModal,
  },
  props: {
    modals: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeModal: null,
    };
  },
  methods: {
    onClick(modal) {
      this.activeModal = modal;
    },
  },
}
</script>
