<template>
  <RangeFilterItem v-if="isRange"
    :facet="facet"
    @select="$emit('selectRangeFilter', $event)">
  </RangeFilterItem>
  <div v-else-if="isColor">
    <ColorFilterItem v-for="filter in facet.filters"
      :key="filter.query"
      :filter="filter"
      @select="$emit('selectFilter', $event)">
    </ColorFilterItem>
  </div>
  <div v-else>
    <FilterItem v-for="filter in facet.filters"
      :key="filter.query"
      :filter="filter"
      @select="$emit('selectFilter', $event)">
    </FilterItem>
  </div>
</template>

<script>
import FilterItem from './FilterItem.vue';
import ColorFilterItem from './ColorFilterItem.vue';
import RangeFilterItem from './RangeFilterItem.vue';

export default {
  props: {
    facet: Object,
  },
  components: {
    FilterItem,
    RangeFilterItem,
    ColorFilterItem,
  },
  computed: {
    isRange() {
      return this.facet.selectionType === 'RANGE';
    },

    isColor() {
      return this.facet.id === 'basecolourname';
    },
  }
}
</script>
