var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.fields.length > 0
      ? _c("div", [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")])
      : _vm._e(),
    _vm._v(" "),
    _vm.customText
      ? _c("div", [_vm._v("\n    " + _vm._s(_vm.customText) + "\n  ")])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }