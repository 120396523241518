var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i3-search-watch" },
    [
      _vm.active
        ? _c("TheSearchWatch", {
            attrs: {
              lastQuery: _vm.lastQuery,
              queryFilters: _vm.queryFilters,
              brandToWatch: _vm.brandToWatch,
              channel: _vm.channel,
              lang: _vm.lang
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }