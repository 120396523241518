var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.option.heading
        ? _c("label", { staticClass: "option-box__content-header" }, [
            _vm._v("\n    " + _vm._s(_vm.option.heading) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.option.text
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.option.text) } })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.option.values, function(value) {
        return _c("VcOptionBox", {
          key: value.name,
          attrs: {
            selected: value.selected,
            heading: value.name,
            smallText: value.subtitle,
            smallBlock: "",
            checkbox: _vm.isCheckbox
          },
          on: {
            click: function($event) {
              return _vm.onClick(value)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }