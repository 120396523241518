import Tooltip from 'tooltip.js';
import observeAddedEl from '../utils/observe-added-el';

export default {
  init() {
    this.tooltips = [];
    this.modifyDOM();
  },

  modifyDOM() {
    $('.ttjs').each((i, el) => {
      this.initTooltip(el);
    });
    observeAddedEl('.ttjs', el => {
      this.initTooltip(el);
    });
  },

  initTooltip(el) {
    const $el = $(el);
    const title = $el.data('ttjs-title');

    const instance = new Tooltip(el, {
      title,
      html: true,
      trigger: 'hover',
      placement: $el.data('ttjs-placement') || 'top',
      closeOnClickOutside: true,
      arrowSelector: '.ttjs-tooltip-arrow',
      innerSelector: '.ttjs-tooltip-inner',
      template: `
        <div class="ttjs-tooltip" role="tooltip">
          <div class="ttjs-tooltip-arrow"></div>
          <div class="ttjs-tooltip-inner"></div>
        </div>`
    });

    this.tooltips.push(instance);
  },

  remove(tt) {
    this.tooltips = this.tooltips.filter(t => t !== tt);
  }
}
