var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcModal",
    {
      attrs: { small: "" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.lang.Contact.ReviewsModalHeadline) + "\n  "
        )
      ]),
      _vm._v(" "),
      _vm.reviewData
        ? _c(
            "div",
            {
              staticClass: "reviews-widget__modal",
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c("div", { staticClass: "reviews-widget__modal__title" }, [
                _vm._v(_vm._s(_vm.heading))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "reviews-widget__modal__address" }, [
                _vm._v(_vm._s(_vm.address))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "reviews-widget__modal__star-row" },
                [
                  _c(
                    "div",
                    { staticClass: "reviews-widget__modal__average-rating" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.reviewData.averageRating.toFixed(1)) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("StarList", {
                    attrs: {
                      "list-id": "modal-average-rating-star",
                      rating: _vm.reviewData.averageRating,
                      width: 32
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "reviews-widget__total-reviews" }, [
                    _vm._v("\n        " + _vm._s(_vm.ctaText) + "\n      ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _vm.reviewsUrl
                ? _c(
                    "div",
                    { staticClass: "reviews-widget__modal__review-button" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn--primary btn--sm",
                          attrs: { href: _vm.reviewsUrl, target: "_blank" }
                        },
                        [_vm._v("Ge ditt omdöme")]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "reviews-widget__sort" }, [
                _c("div", { staticClass: "reviews-widget__sort__label" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lang.Contact.ReviewsSortLabel) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "reviews-widget__sort__row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "reviews-widget__sort__tag",
                      class: {
                        "reviews-widget__sort__tag--active":
                          _vm.activeSorting === _vm.sortKeys.Latest
                      },
                      on: {
                        click: function($event) {
                          return _vm.changeSortingOrder(_vm.sortKeys.Latest)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.lang.Contact.ReviewsSortLatest) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "reviews-widget__sort__tag",
                      class: {
                        "reviews-widget__sort__tag--active":
                          _vm.activeSorting === _vm.sortKeys.Highest
                      },
                      on: {
                        click: function($event) {
                          return _vm.changeSortingOrder(_vm.sortKeys.Highest)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.lang.Contact.ReviewsSortHighest) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "reviews-widget__sort__tag",
                      class: {
                        "reviews-widget__sort__tag--active":
                          _vm.activeSorting === _vm.sortKeys.Lowest
                      },
                      on: {
                        click: function($event) {
                          return _vm.changeSortingOrder(_vm.sortKeys.Lowest)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.lang.Contact.ReviewsSortLowest) +
                          "\n        "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "reviews-widget__list" },
                [
                  _vm._l(_vm.reviewList, function(review) {
                    return _c("Review", {
                      key: review.reviewId,
                      attrs: { review: review }
                    })
                  }),
                  _vm._v(" "),
                  _vm.reviewData.nextPageToken
                    ? _c(
                        "div",
                        {
                          staticClass: "reviews-widget__load-more",
                          on: { click: _vm.nextPage }
                        },
                        [_vm._v("Fler recensioner")]
                      )
                    : _vm._e()
                ],
                2
              )
            ]
          )
        : _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "reviews-widget__modal__loader" },
                  [
                    _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.faSpinnerThird, spin: true }
                    })
                  ],
                  1
                )
              : _c("div", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.lang.Contact.ReviewsError) +
                      "\n    "
                  )
                ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }