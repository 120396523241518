<template>
  <div>
    <component v-for="section in displaySections"
      :is="getSectionComponent(section)"
      :key="section.type"
      :ref="getSectionRefName(section)"
      :section="section"
      :SectionTypeComponents="SectionTypeComponents"
      :hideFooter="section.order === sectionsMaxOrder || section.hideFooter"
      :footerLabel="`Steg ${section.order} av ${sectionsMaxOrder}`"
      @done="onSectionDone(section)">
    </component>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SectionTypes from './constants/SectionTypes';
import SectionContainer from './SectionContainer.vue';
import SectionContainerLookup from './SectionContainerLookup.vue';
import SectionContainerValuation from './SectionContainerValuation.vue';
import SectionContainerLookupValuation from './SectionContainerLookupValuation.vue';

export default {
  props: {
    sections: Array,
    SectionTypeComponents: Object,
  },
  computed: {
    ...mapState({
      order: state => state.sections.order,
    }),

    ...mapGetters({
      sectionsMaxOrder: 'sections/maxOrder',
      sectionsCurrentItems: 'sections/currentItems',
    }),

    displaySections() {
      return this.sections.filter(section => {
        if (section.dormant) {
          return false;
        }

        if (section.hidden) {
          return false;
        }

        if (section.lazy) {
          return section.order <= this.order;
        }

        return section;
      })
    },
  },
  methods: {
    ...mapActions({
      nextSection: 'sections/next',
    }),

    getSectionRefName({ order }) {
      return `section${order}`;
    },

    getSectionEl(section) {
      const ref = this.$refs[this.getSectionRefName(section)];
      return ref && ref.length && ref[0].$el;
    },

    onSectionDone(section) {
      this.nextSection();
      this.$emit('completed', section);

      const next = this.sectionsCurrentItems;

      if (next.length) {
        setTimeout(() => {
          const nextEl = this.getSectionEl(next[0]);

          if (nextEl) {
            nextEl.scrollIntoView({
              block: 'nearest',
              behavior: 'smooth',
            });
          }
        });
      }
    },

    getSectionComponent(section) {
      let container = SectionContainer;

      switch(section.type) {
        case SectionTypes.Lookup: {
          if (section.inValuation) {
            container = SectionContainerLookupValuation;
          } else {
            container = SectionContainerLookup;
          }
          break;
        }
        case SectionTypes.Valuation: {
          container = SectionContainerValuation;
          break;
        }
      }

      return container;
    },
  },
}
</script>
