<template>
  <div class="properties">
    <div class="properties__item properties__item--header">
      <div class="properties__label">
        {{ lang.Summation.SummationVehicleHeading }}
      </div>
    </div>
    <div class="vehicle-line">
      <div class="properties__item">
        <img
          class="properties__item__image"
          v-if="vehicle.image"
          :src="vehicle.image"
        />
        <div class="properties__item__text-container">
          <div class="properties__item__label">
            {{ vehicle.heading }}
          </div>
          <div class="properties__item__sub-label">
            {{ vehicle.subHeading }}
          </div>
          <div class="vehicle-configurator__summation__meta-data">
            <span class="meta-tag black rounded">{{ vehicle.condition }}</span>
            <span v-if="vehicle.fuelName && vehicle.gearBoxName">
              {{
                `${vehicle.modelYear} | ${vehicle.fuelName} | ${vehicle.gearboxName} | ${vehicle.mileage} mil`
              }}
            </span>
            <span v-else>{{
              `${vehicle.modelYear} | ${vehicle.mileage} mil`
            }}</span>
          </div>
        </div>
        <div class="properties__value vehicle-configurator__summation__price">
          <div
            v-if="price.current !== price.ordinary && !!price.ordinary"
            class="price-tags"
          >
            <div class="price-tag price-tag--discount">
              {{ `${$formatPriceNoSuffix(price.current)} ${price.suffix}` }}
            </div>
            <div class="price-tag price-tag--obsolete">
              {{ `${$formatPriceNoSuffix(price.ordinary)} ${price.suffix}` }}
            </div>
          </div>
          <div v-else-if="attention" class="price-tag price-tag--discount">
            {{ `${$formatPriceNoSuffix(price.current)} ${price.suffix}` }}
          </div>
          <div v-else class="price-tag">
            {{ `${$formatPriceNoSuffix(price.current)} ${price.suffix}` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcPriceTag } from "@holmgrensbil/vue-components";

export default {
  components: {
    VcPriceTag
  },
  props: {
    vehicle: Object,
    price: Object,
    lang: Object,
    attention: Boolean
  }
};
</script>
