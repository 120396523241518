var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field-input-images__grid-item" },
    [
      _vm.imageUrl
        ? _c(
            "div",
            {
              staticClass: "file-thumbnail",
              style: {
                "background-image":
                  "url(" +
                  _vm.imageUrl +
                  "?" +
                  _vm.ImageResizerPresets.FileThumbnail
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "file-thumbnail__delete",
                  on: { click: _vm.onDeleteImage }
                },
                [_c("i", { staticClass: "fal fa-times" })]
              )
            ]
          )
        : _c(
            "VcInputFile",
            { attrs: { label: "Välj bild" }, on: { change: _vm.onFileChange } },
            [
              _c(
                "div",
                { attrs: { slot: "icon" }, slot: "icon" },
                [
                  _c("font-awesome-icon", {
                    attrs: {
                      icon: _vm.uploading
                        ? _vm.faSpinnerThird
                        : _vm.faCameraAlt,
                      spin: _vm.uploading ? true : false
                    }
                  })
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }