import Vuex from 'vuex';
import brands from './modules/brands';
import config from './modules/config';
import departments from './modules/departments';
import sections from './modules/sections';
import services from './modules/services';
import regNr from './modules/regNr';

const strict = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
  strict,
  modules: {
    brands,
    config,
    departments,
    sections,
    services,
    regNr,
  },
});
