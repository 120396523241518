<template>
  <div>
    <DeliveryStatusCircle :deliveryStatus="deliveryStatus" small></DeliveryStatusCircle>
    {{label}}
  </div>
</template>

<script>
import LineItemDeliveryStatusLabels from '../../../constants/LineItemDeliveryStatusLabels';
import DeliveryStatusCircle from './DeliveryStatusCircle.vue';

export default {
  components: {
    DeliveryStatusCircle,
  },
  props: {
    deliveryStatus: Number,
  },
  computed: {
    label() {
      return LineItemDeliveryStatusLabels[this.deliveryStatus];
    },
  },
}
</script>
