var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i3-filter__facet-view" },
    [
      _c("FacetItemContent", {
        attrs: { facet: _vm.facet },
        on: {
          selectFilter: function($event) {
            return _vm.$emit("selectFilter", $event)
          },
          selectRangeFilter: function($event) {
            return _vm.$emit("selectRangeFilter", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }