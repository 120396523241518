var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row table-row" }, [
    _vm.vehicle._doc.trimText
      ? _c("div", { staticClass: "table-column col-3" }, [
          _c("span", [_vm._v("Fordon: ")]),
          _vm._v(
            _vm._s(_vm.vehicle._doc.brandName) +
              " " +
              _vm._s(_vm.vehicle._doc.trimText)
          )
        ])
      : _c("div", { staticClass: "table-column col-3" }, [
          _c("span", [_vm._v("Fordon: ")]),
          _vm._v(
            _vm._s(_vm.vehicle._doc.brandName) +
              " " +
              _vm._s(_vm.vehicle._doc.modelName) +
              " " +
              _vm._s(_vm.vehicle._doc.engineName)
          )
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-column col-2" }, [
      _c("span", [_vm._v("Årsmodell: ")]),
      _vm._v(_vm._s(_vm.vehicle._doc.modelYear))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-column col-1" }, [
      _c("span", [_vm._v("Färg: ")]),
      _vm._v(_vm._s(_vm.vehicle.color))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-column col-2" }, [
      _c("span", [_vm._v("Registrerad: ")]),
      _vm._v(_vm._s(_vm.vehicle.registrationDate))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-column col-2" }, [
      _c("span", [_vm._v("Tillgänglig: ")]),
      _vm._v(_vm._s(_vm.vehicle._doc.arrivalDate))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-column col-2" }, [
      _c(
        "button",
        { staticClass: "btn btn--primary btn--sm", on: { click: _vm.onClick } },
        [_vm._v("Prisförslag")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }