<template>
  <div
    class="reviews-widget__review"
  >
    <div class="reviews-widget__review__image">
      <img :src="review.reviewer.profilePhotoUrl" >
    </div>
    <div class="reviews-widget__review__content">
      <div class="reviews-widget__review__name">
        {{ review.reviewer.displayName }}
      </div>
      <div class="reviews-widget__review__rating-container">
        <StarList :list-id="review.reviewId" :rating="review.starRating" :width="16" />
        <div class="reviews-widget__review__date">
          {{ moment(review.updateTime).fromNow() }}
        </div>
      </div>
      <div class="reviews-widget__review__comment" v-if="review.comment">
        {{ comment }}
        <span v-if="!showFullComment && review.comment.length > 100">... <span class="reviews-widget__review__read-more" @click="showFullComment = true">Läs mer</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import StarList from "../reviews-widget/StarList.vue";
export default {
  props: {
    review: Object,
  },
  components: {
    StarList,
  },
  data() {
    return {
      showFullComment: false,
      moment,
    }
  },
  computed: {
    comment() {
      return this.showFullComment ? this.review.comment : this.review.comment.substring(0, 100);
    }
  },
  methods: {

  }
}
</script>
