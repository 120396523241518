<template>
  <a class="campaign-list__item" :href="article.url">
    <div>
      <div class="campaign-list__item__image">
        <img :src="image.src" :alt="image.alt" />
      </div>
      <div class="campaign-list__item__content">
        <div>
          <a :href="article.url">
            <h3 class="campaign-list__item__title">
              {{ article.heading }}
            </h3>
          </a>
          <div class="blog-list__item-intro">
            {{ introText }}
          </div>
        </div>
        <div class="blog-list__category-listing">
          <div
            class="blog-category-item"
            v-for="categoryItem in article.blogTags"
            :key="categoryItem + '-categoryItemBlogListing'"
          >
            {{ categoryItem }}
          </div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import ImageResizerPresets from "../../constants/ImageResizerPresets";
export default {
  props: {
    article: Object
  },
  computed: {
    image() {
      return {
        src: `${this.article.image}?${ImageResizerPresets.BlogSpot}`,
        alt: this.article.name
      };
    },
    introText() {
      return this.article.intro.length > 200
        ? this.article.intro.slice(0, 200) + "..."
        : this.article.intro;
    }
  }
};
</script>
