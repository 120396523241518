<template>
  <div>
    <label>
      {{field.label}}
    </label>
    <div class="form-row">
      <div class="form-row__col form-row__col--md-50">
        <VcOptionBox
          :selected="field.value === true"
          heading="Ja"
          @click="$emit('change', true)">
        </VcOptionBox>
      </div>
      <div class="form-row__col form-row__col--md-50">
        <VcOptionBox
          :selected="field.value === false"
          heading="Nej"
          @click="$emit('change', false)">
        </VcOptionBox>
      </div>
    </div>
  </div>
</template>

<script>
import { VcOptionBox } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcOptionBox,
  },
  props: {
    field: Object,
  },
}
</script>
