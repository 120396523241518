import SectionTypes from '../constants/SectionTypes';

export default [
  {
    order: 1,
    type: SectionTypes.Lookup,
  },
  {
    order: 2,
    type: SectionTypes.Details,
  },
  {
    order: 3,
    type: SectionTypes.Period,
  },
  {
    order: 4,
    type: SectionTypes.Contact,
  },
  {
    order: 4,
    type: SectionTypes.Summary,
    lazy: true,
    summary: true,
  },
];
