import FieldTypes from '../constants/FieldTypes';
import InputTypes from '../constants/InputTypes';
import SectionTypes from '../constants/SectionTypes';
import RegExPatterns from '../../../../constants/RegExPatterns';

export default [
  {
    type: FieldTypes.Newsletter,
    label: 'Jag vill få nyhetsbrev från Holmgrens Bil',
  },
  {
    section: SectionTypes.Lookup,
    label: 'Regnummer',
    placeholder: 'ABC123',
    type: FieldTypes.RegNr,
    inputType: InputTypes.RegNr,
    inputPattern: RegExPatterns.RegistrationNumber,
  },
  {
    section: SectionTypes.Lookup,
    type: FieldTypes.RegDate,
  },
  {
    section: SectionTypes.Lookup,
    label: 'Märke',
    placeholder: 'Välj märke',
    type: FieldTypes.Brand,
    inputType: InputTypes.SelectObject,
    options: [],
  },
  {
    section: SectionTypes.Lookup,
    label: 'Modell',
    type: FieldTypes.Model,
  },
  {
    section: SectionTypes.Lookup,
    label: 'Version',
    type: FieldTypes.Version,
  },
  {
    section: SectionTypes.Lookup,
    label: 'Årsmodell',
    type: FieldTypes.ModelYear,
  },
  {
    section: SectionTypes.Contact,
    label: 'Namn',
    placeholder: 'Vad heter du?',
    type: FieldTypes.Name,
    inputType: InputTypes.Text,
    autocomplete: 'name',
    required: true,
  },
  {
    section: SectionTypes.Contact,
    label: 'E-post',
    placeholder: 'Ange din e-postadress',
    type: FieldTypes.Email,
    inputType: InputTypes.Email,
    inputPattern: RegExPatterns.Email,
    autocomplete: 'email',
    required: true,
  },
  {
    section: SectionTypes.Contact,
    label: 'Mobilnummer',
    placeholder: '070-123 45 67',
    type: FieldTypes.Phone,
    inputType: InputTypes.Phone,
    inputPattern: RegExPatterns.Phone,
    autocomplete: 'tel',
    required: true,
  }
]
