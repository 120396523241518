<script>
// https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
export default {
  functional: true,
  render(createElement, context) {
    const data = {
      props: {
        name: 'expand',
      },
      on: {
        afterEnter(element) {
          element.style.height = 'auto';
        },
        appear(element) {
          const width = getComputedStyle(element).width;

          element.style.width = width;
          element.style.position = 'absolute';
          element.style.visibility = 'hidden';
          element.style.height = 'auto';

          const height = getComputedStyle(element).height;

          element.style.width = '';
          element.style.position = '';
          element.style.visibility = '';
          element.style.height = 0;

          // Force repaint to make sure the
          // animation is triggered correctly.
          getComputedStyle(element).height;

          setTimeout(() => {
            element.style.height = height;
          });
        },
        leave(element) {
          const height = getComputedStyle(element).height;
          element.style.height = height;
          // Force repaint to make sure the
          // animation is triggered correctly.
          getComputedStyle(element).height;

          setTimeout(() => {
            element.style.height = 0;
          });
        },
      }
    };

    return createElement('transition', data, context.children);
   },
 };
 </script>

<style scoped>
/* * {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
} */
</style>
