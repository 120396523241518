export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    if($('.site-content > div:first-child').hasClass('promo--transparent-top')) {
      $('body').addClass('transparent-top');
    }
    if($('.site-content > div:first-child').hasClass('hero-image--transparent-top')) {
      $('body').addClass('transparent-top');
    }
    if($('.site-content > .container--section-breakout:first > .row0 > .sectionblock > .section > div > .row0 > .block > .promo--transparent-top ').length) {
      $('body').addClass('transparent-top');
    }
  },
}
