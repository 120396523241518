var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "properties" }, [
    _c("div", { staticClass: "properties__item properties__item--header" }, [
      _c("div", { staticClass: "properties__label" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.lang.Summation.SummationVehicleHeading) +
            "\n    "
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "vehicle-line" }, [
      _c("div", { staticClass: "properties__item" }, [
        _vm.vehicle.image
          ? _c("img", {
              staticClass: "properties__item__image",
              attrs: { src: _vm.vehicle.image }
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "properties__item__text-container" }, [
          _c("div", { staticClass: "properties__item__label" }, [
            _vm._v("\n          " + _vm._s(_vm.vehicle.heading) + "\n        ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "properties__item__sub-label" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.vehicle.subHeading) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "vehicle-configurator__summation__meta-data" },
            [
              _c("span", { staticClass: "meta-tag black rounded" }, [
                _vm._v(_vm._s(_vm.vehicle.condition))
              ]),
              _vm._v(" "),
              _vm.vehicle.fuelName && _vm.vehicle.gearBoxName
                ? _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.vehicle.modelYear +
                            " | " +
                            _vm.vehicle.fuelName +
                            " | " +
                            _vm.vehicle.gearboxName +
                            " | " +
                            _vm.vehicle.mileage +
                            " mil"
                        ) +
                        "\n          "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.vehicle.modelYear +
                          " | " +
                          _vm.vehicle.mileage +
                          " mil"
                      )
                    )
                  ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "properties__value vehicle-configurator__summation__price"
          },
          [
            _vm.price.current !== _vm.price.ordinary && !!_vm.price.ordinary
              ? _c("div", { staticClass: "price-tags" }, [
                  _c("div", { staticClass: "price-tag price-tag--discount" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$formatPriceNoSuffix(_vm.price.current) +
                            " " +
                            _vm.price.suffix
                        ) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "price-tag price-tag--obsolete" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$formatPriceNoSuffix(_vm.price.ordinary) +
                            " " +
                            _vm.price.suffix
                        ) +
                        "\n          "
                    )
                  ])
                ])
              : _vm.attention
              ? _c("div", { staticClass: "price-tag price-tag--discount" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$formatPriceNoSuffix(_vm.price.current) +
                          " " +
                          _vm.price.suffix
                      ) +
                      "\n        "
                  )
                ])
              : _c("div", { staticClass: "price-tag" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$formatPriceNoSuffix(_vm.price.current) +
                          " " +
                          _vm.price.suffix
                      ) +
                      "\n        "
                  )
                ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }