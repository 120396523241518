<template>
  <div>
    <div v-for="option in field.options"
      class="tab-button tab-button--horizontal"
      :class="{'tab-button--active': field.value === option.value && shouldShowSelected}"
      :key="option.value"
      @click="$emit('change', option.value)">
      <i :class="option.tabButtonIcon"></i>
      <span>
        {{option.name }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EonQueryParams from '../../../constants/EonQueryParams';
import FieldTypes from '../constants/FieldTypes';
import BuildWheelTypes from '../constants/BuildWheelTypes';

export default {
  props: {
    field: Object,
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
    }),
    shouldShowSelected() {
      return this.field.type === EonQueryParams.ProductType ?  this.fieldByType(FieldTypes.BuildWheel).value !== String(BuildWheelTypes.Selected) : true;
    },
  },
}
</script>
