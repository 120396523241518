const state = {};

const getters = {};

const actions = {
  load({ commit, dispatch }, config) {
    commit('received', config);
    dispatch('sections/load', config.sections, { root: true });
    dispatch('fields/load', config.fields, { root: true });
    dispatch('form/load', config.endpoint, { root: true });
  },

  reset({dispatch, state}) {
    dispatch('load', state.loadState);
    dispatch('sections/goTo', 1, {root: true});
  }
};

const mutations = {
  received(state, config) {
    config.loadState = Object.assign({}, config);
    Object.assign(state, config);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
