var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStep",
    {
      attrs: {
        completed: _vm.completed,
        disabled: _vm.disabled,
        feedbackDanger: _vm.displaySectionValidationError,
        arrow: !_vm.displaySectionValidationError,
        title: _vm.getTitle(_vm.section),
        subtitle: _vm.getSubtitle(_vm.section),
        summary: _vm.section.summary
      }
    },
    [
      _vm.completed && !_vm.submitted && !_vm.disableEdit
        ? _c("SectionEditButton", {
            attrs: { slot: "header-aside" },
            on: { click: _vm.onEdit },
            slot: "header-aside"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.active && _vm.skipButton
        ? _c("div", { attrs: { slot: "preContent" }, slot: "preContent" }, [
            _c(
              "a",
              {
                staticClass: "btn btn--muted btn--block u-margin-bottom",
                on: { click: _vm.onSubmit }
              },
              [_vm._v("Nej, tack. Jag vill inte ha några tillval")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _vm.active || _vm.section.summary
            ? _c(
                "div",
                [
                  _c(_vm.SectionTypeComponents[_vm.section.type], {
                    tag: "component",
                    attrs: { section: _vm.section, sectionValid: _vm.valid },
                    on: { submit: _vm.onSubmit }
                  })
                ],
                1
              )
            : _vm.completed
            ? _c("FieldChecklist", { attrs: { fields: _vm.sectionFields } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.active && !_vm.hideFooter
        ? _c(
            "div",
            {
              staticClass: "form-step__actions",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("label", [_vm._v(_vm._s(_vm.footerLabel))]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn--primary",
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("Fortsätt")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displaySectionValidationError
        ? _c("SectionValidationError", {
            attrs: { slot: "feedback", fields: _vm.invalidSectionFields },
            slot: "feedback"
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }