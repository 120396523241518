<template>
  <VcFormSelect
    :options="field.options"
    :prompt="field.placeholder"
    :value="valueObject"
    valueProp="value"
    labelProp="name"
    @change="$emit('change', $event ? $event.value : null)">
  </VcFormSelect>
</template>

<script>
import { VcFormSelect } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormSelect,
  },
  props: {
    field: Object,
  },
  computed: {
    valueObject() {
      return this.field.options.find(o => o.value === this.field.value);
    },
  },
}
</script>
