var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.displaySections, function(section) {
      return _c(
        "div",
        { key: section.type, staticClass: "properties" },
        [
          _c(
            "div",
            { staticClass: "properties__item properties__item--header" },
            [
              _c("div", { staticClass: "properties__label" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.getTitle(section)) + "\n      "
                )
              ])
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.getSectionSummationLines(section.type), function(
            line,
            index
          ) {
            return _c("div", { key: index, staticClass: "properties__item" }, [
              _c("div", {
                staticClass: "properties__label",
                domProps: { innerHTML: _vm._s(line.label) }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "properties__value" },
                [
                  line.price
                    ? _c("VcPriceTag", {
                        attrs: {
                          price: line.price,
                          showIfZero: line.price.showIfZero
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }