var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-bar" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "filter-bar__tags" }, [
        !_vm.isBeginning
          ? _c("div", {
              staticClass:
                "filter-bar__tags__shadow filter-bar__tags__shadow--left"
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.isEnd
          ? _c("div", {
              staticClass:
                "filter-bar__tags__shadow filter-bar__tags__shadow--right"
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { ref: "swiperContainer", staticClass: "swiper-container" }, [
          _c(
            "div",
            { staticClass: "swiper-wrapper" },
            [
              _vm.queryFilters.length > 0 && !_vm.hideWatchButton
                ? _c(
                    "div",
                    {
                      staticClass:
                        "swiper-slide filter-bar__tag filter-bar__tag--watch",
                      class: {
                        "filter-bar__tag--watch--active": _vm.filtersSaved,
                        pulsing: _vm.highlightBtn
                      },
                      on: { click: _vm.onOpenWatchModal }
                    },
                    [
                      _c("FontAwesomeIcon", {
                        staticClass: "filter-bar__tag__icon",
                        attrs: {
                          icon: _vm.filtersSaved ? _vm.faBellOn : _vm.faBell
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.filtersSaved ? "Bevakad" : "Bevaka") +
                          "\n        "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.queryFilters, function(qf) {
                return _c(
                  "div",
                  {
                    key: qf.key + _vm.mobileTag,
                    staticClass: "swiper-slide filter-bar__tag",
                    class: {
                      "filter-bar__tag--group":
                        qf.children && qf.children.length > 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.onClearQueryFilter(
                          qf,
                          qf.children && qf.children.length > 0
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.doReplaceForDisplay(qf.label)) +
                        "\n          "
                    ),
                    (qf.type === _vm.I3QueryFilterTypes.FreeText &&
                      !qf.locked) ||
                    qf.type === _vm.I3QueryFilterTypes.RangeFacet ||
                    (qf.children && qf.children.length === 0)
                      ? _c("div", [
                          _c("i", {
                            staticClass: "far fa-times filter-bar__tag__icon"
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(qf.children, function(qfc) {
                      return _c(
                        "div",
                        {
                          key: qfc.key + _vm.mobileTag,
                          staticClass: "filter-bar__tag filter-bar__tag--child",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onClearQueryFilter(qfc, false)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(qfc.label) +
                              "\n            "
                          ),
                          _vm._m(1, true)
                        ]
                      )
                    })
                  ],
                  2
                )
              })
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _vm.showModal
        ? _c(
            "TheSearchWatchModal",
            {
              attrs: {
                "email-found": _vm.emailFound,
                email: _vm.email,
                privacyPolicyText: _vm.lang.General.PrivacyPolicyText,
                feedbackHeader: _vm.feedbackHeader,
                feedbackText: _vm.feedbackText,
                feedbackContactHeader: _vm.feedbackContactHeader,
                feedbackContactText: _vm.feedbackContactText,
                hasCustomContent: !_vm.email
              },
              on: {
                close: function($event) {
                  return _vm.close()
                }
              }
            },
            [
              _c("SearchWatch", {
                attrs: {
                  slot: "customContent",
                  header: _vm.header,
                  text: _vm.text,
                  tags: _vm.tags,
                  feedbackHeader: _vm.feedbackHeader,
                  feedbackText: _vm.feedbackText,
                  feedbackContactHeader: _vm.feedbackContactHeader,
                  feedbackContactText: _vm.feedbackContactText,
                  privacyPolicyText: _vm.lang.General.PrivacyPolicyText,
                  done: _vm.done,
                  loading: _vm.loading,
                  error: _vm.error,
                  brandToWatch: _vm.brandToWatch
                },
                on: { saveSearches: _vm.onSaveSearches },
                slot: "customContent"
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "filter-bar__btn" }, [
      _c("i", { staticClass: "fal fa-sliders-v filter-bar__btn__icon" }),
      _vm._v("\n    Filtrera\n  ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "far fa-times filter-bar__tag__icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }