var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eon-result" },
    [
      _vm.products.length &&
      _vm.selectedProductType !== _vm.EonProductTypes.TyreBuild
        ? _c("TheHeader", {
            attrs: {
              count: _vm.channel.data.count,
              vehicle: _vm.TyreSpec.shortDisplayName
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.topQuerying || _vm.isLoading
        ? _c(
            "div",
            { staticClass: "eon-result__querying-content" },
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.faSpinnerThird, spin: true }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.products.length
        ? _c("TheGrid", {
            attrs: {
              products: _vm.products,
              selectedProductType: _vm.selectedProductType,
              regNr: _vm.TyreSpec.regNr,
              querying: _vm.topQuerying || _vm.bottomQuerying,
              isBuildWheel: _vm.isBuildWheel,
              selectedProduct: _vm.selectedProduct
            },
            on: { onNext: _vm.onNext }
          })
        : _vm.queriedAt
        ? _c("NoResults", {
            attrs: {
              sizes: _vm.TyreSpec.recommendedSizes,
              tyreSize: _vm.tyreSize,
              carName: _vm.TyreSpec.carName
            },
            on: { click: _vm.onGoToRecommendedSize }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.bottomQuerying
        ? _c(
            "div",
            { staticClass: "eon-result__querying-content-end" },
            [
              _c("FontAwesomeIcon", {
                attrs: { icon: _vm.faSpinnerThird, spin: true }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }