var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact", class: { "contact--slim": _vm.hideSections } },
    [
      !_vm.hideSections
        ? _c("TheTabs", {
            attrs: { sections: _vm.sections, selected: _vm.selectedSection },
            on: { select: _vm.onSelectSection }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedSection
        ? _c("TheSection", {
            ref: "section",
            attrs: {
              "hide-sections": _vm.hideSections,
              section: _vm.selectedSection
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }