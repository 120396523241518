var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overlay-header" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showBack
          ? _c(
              "div",
              {
                staticClass: "overlay-header__back",
                on: {
                  click: function($event) {
                    return _vm.$emit("back")
                  }
                }
              },
              [_c("i", { staticClass: "fal fa-arrow-left" })]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "overlay-header__text" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n\n    "),
        _vm.meta
          ? _c("span", { staticClass: "overlay-header__meta" }, [
              _vm._v("\n      " + _vm._s(_vm.meta) + "\n    ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "circle-action",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_c("i", { staticClass: "fal fa-times" })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }