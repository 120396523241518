<template>
  <div>
    <div class="form-row">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.Name">
        </SectionField>
      </div>
    </div>

    <div class="form-row">
      <div class="form-row__col form-row__col--md-50">
        <SectionField :type="FieldTypes.Email">
        </SectionField>
      </div>
      <div class="form-row__col form-row__col--md-50">
        <SectionField :type="FieldTypes.Phone">
        </SectionField>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from './SectionField.vue';

export default {
  components: {
    SectionField,
  },
  data() {
    return {
      FieldTypes,
    };
  },
}
</script>
