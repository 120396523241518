<template>
  <div>
    <table class="comparison-mobile-table">
      <tr class="comparison-mobile-table__row comparison-mobile-table__row--header">
        <th
          class="comparison-mobile-table__cell comparison-mobile-table__cell--header"
        ></th>
        <th
          class="comparison-mobile-table__cell comparison-mobile-table__cell--header"
        >
        </th>
        <th
          v-for="label in filteredLabels"
          :key="label.key"
          class="comparison-mobile-table__cell comparison-mobile-table__cell--header"
        >
          <div class="comparison-mobile-table__header-content">
            {{ label.label }}
          </div>
        </th>
      </tr>
      <tr
        v-for="row in rows"
        :key="`${row.title}-${uuidv4()}`"
        class="comparison-mobile-table__row"
      >
        <td class="comparison-mobile-table__cell comparison-mobile-table__cell--label">
          <div class="comparison-mobile-table__cell__content-wrapper">
            <a class="comparison-mobile-table__cell__content" :href="row.carUrl">
              <div class="comparison-mobile-table__cell__content__brand">
                {{ row.title }}
              </div>
              <div class="comparison-mobile-table__cell__content__text">
                {{ row.subTitle }}
              </div>
            </a>
          </div>
        </td>
        <td class="comparison-mobile-table__cell">
          <div class="comparison-mobile-table__cell__content">
            <div class="delete-btn" @click="$emit('delete-vehicle', row.regNr)">
              <i class="fas fa-trash"></i>
            </div>
          </div>
        </td>
        <td
          v-for="cell in row.vehicleCells"
          :key="`${cell.primaryValue}-${uuidv4()}`"
          class="comparison-mobile-table__cell"
        >
          <div
            class="comparison-mobile-table__cell__content"
            :class="{
              'comparison-mobile-table__cell__content__single-line': cell.hasUnit,
              'comparison-mobile-table__cell__content__discount': cell.secondaryValue,
            }"
          >
            {{ cell.primaryValue }}
            <span v-if="cell.secondaryValue" class="comparison-mobile-table__cell__secondary-value">{{ cell.secondaryValue }}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    labels: {
      required: true,
      type: Array,
    },
    vehicles: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      uuidv4,
    };
  },
  computed: {
    filteredLabels() {
      const fieldsWithLabel = this.labels.filter(label => label.label)
      return fieldsWithLabel;
    },
    rows() {
      const row = this.vehicles.map(vehicle => {
        const vehicleValues = this.filteredLabels.map(label => {
          let primaryValue = vehicle[label.key];
          let secondaryValue = null;
          if (label.key === 'currentprice') {
            const hasDiscount = vehicle.ordinaryprice > vehicle.currentprice;
            secondaryValue = hasDiscount ? this.$formatPrice(vehicle.ordinaryprice) : null;
            primaryValue = this.$formatPrice(vehicle.currentprice);
          } else if (label.key === 'registrationdate' && vehicle[label.key]) {
            primaryValue = vehicle[label.key].slice(0, 10);
          } else if (label.key === 'usps' && vehicle[label.key] && Array.isArray(vehicle[label.key])) {
            primaryValue = vehicle[label.key].join(', ');
          } else {
            primaryValue = label.unit && primaryValue ? `${primaryValue} ${label.unit}` : primaryValue;
          }
          return {
            primaryValue,
            secondaryValue,
            hasUnit: !!label.unit,
          }
        })
        return {
          title: `${vehicle.brandname} ${vehicle.modelname}`,
          subTitle: vehicle.modeltext_nobrand,
          carUrl: vehicle.carUrl,
          vehicleCells: vehicleValues,
          regNr: vehicle.registrationnumber,
        }
      })

      return row;
    }
  },
}
</script>
