var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contactModalOpen
    ? _c(
        "VcModal",
        { attrs: { small: "" }, on: { close: _vm.onCloseContactModal } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("\n    Personligt prisförslag\n  ")
          ]),
          _vm._v(" "),
          !_vm.formSent
            ? _c(
                "div",
                {
                  staticClass: "vehile-list-simple-contact-form",
                  attrs: { slot: "content" },
                  slot: "content"
                },
                [
                  _c("div", { staticClass: "contact-forms" }, [
                    _c(
                      "form",
                      { staticClass: "contact-forms__form form-feedback-host" },
                      [
                        _c("h5", [
                          _c("strong", [
                            _vm._v(
                              "\n            Kontakta oss för att få prisförslag\n          "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _c("small", [
                            _vm._v(
                              "\n            Fyll i dina uppgifter så återkommer vi med ett personligt erbjudande.\n          "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                Namn\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.inputName,
                                      expression: "inputName"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    autocomplete: "name",
                                    placeholder: "Ditt namn",
                                    required: ""
                                  },
                                  domProps: { value: _vm.inputName },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.inputName = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                E-post\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.inputEmail,
                                      expression: "inputEmail"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    autocomplete: "email",
                                    placeholder: "Din e-postadress",
                                    required: ""
                                  },
                                  domProps: { value: _vm.inputEmail },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.inputEmail = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                Mobilnummer\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.inputPhone,
                                      expression: "inputPhone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "tel",
                                    placeholder: "Ditt telefonnummer",
                                    required: "",
                                    autocomplete: "tel"
                                  },
                                  domProps: { value: _vm.inputPhone },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.inputPhone = $event.target.value
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-stack form-stack--required"
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                Närmaste anläggning\n              "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-select" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.selectedLocation,
                                          expression: "selectedLocation"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.selectedLocation = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          "\n                    Välj ort\n                  "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.locations, function(location) {
                                        return _c(
                                          "option",
                                          {
                                            key: location,
                                            domProps: { value: location }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(location) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-check form-check--sm form-check--inline"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.acceptedTerms,
                                      expression: "acceptedTerms"
                                    }
                                  ],
                                  staticClass: "form-check__input",
                                  attrs: {
                                    id: "acceptedTerms",
                                    type: "checkbox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.acceptedTerms)
                                      ? _vm._i(_vm.acceptedTerms, null) > -1
                                      : _vm.acceptedTerms
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.acceptedTerms,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.acceptedTerms = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.acceptedTerms = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.acceptedTerms = $$c
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check__label",
                                    attrs: { for: "acceptedTerms" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Jag godkänner att Holmgrens Bil behandlar mina uppgifter som en del i hanteringen av detta ärende.\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-row__col" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn--primary btn--block",
                                attrs: {
                                  disabled: !_vm.acceptedTerms || _vm.formSent,
                                  type: "submit"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.sendForm($event)
                                  }
                                }
                              },
                              [_vm._v("Skicka förfrågan")]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-text" }, [
                          _c("p", [
                            _vm._v(
                              "Alla personuppgifter som skickas in till Holmgrens Bil kommer att behandlas enligt bestämmelserna i EU:s\n            dataskyddsförordningen (GDPR). "
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: { "text-decoration": "underline" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      title:
                                        "Läs om hur vi behandlar din personuppgifter",
                                      href: "/om-oss/personuppgiftspolicy"
                                    }
                                  },
                                  [_vm._v("Här")]
                                )
                              ]
                            ),
                            _vm._v(
                              " kan du läsa mer om hur vi\n            behandlar dina personuppgifter."
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              )
            : _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "vehile-list-simple-contact-form__loader"
                      },
                      [
                        _c("FontAwesomeIcon", {
                          attrs: { icon: _vm.faSpinnerThird, spin: true }
                        })
                      ],
                      1
                    )
                  : _c("div", [
                      _c("div", { staticClass: "form-feedback" }, [
                        !_vm.error
                          ? _c(
                              "div",
                              { staticClass: "loader loader--success" },
                              [
                                _c("div", { staticClass: "loader__shape" }, [
                                  _c("div", { staticClass: "loader__symbol" })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "loader__header" }, [
                                  _vm._v(
                                    "\n            Tack för ditt intresse!\n          "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "loader__text" }, [
                                  _vm._v(
                                    "\n            Vi hör av oss inom kort.\n          "
                                  )
                                ])
                              ]
                            )
                          : _c("div", { staticClass: "loader loader--error" }, [
                              _c("div", { staticClass: "loader__shape" }, [
                                _c("div", { staticClass: "loader__symbol" })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "loader__header" }, [
                                _vm._v(
                                  "\n            Något gick fel\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "loader__text" }, [
                                _vm._v(
                                  "\n            Vänligen försök igen eller kontakta oss\n          "
                                )
                              ])
                            ])
                      ])
                    ])
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }