import mountApp from '../utils/mount-app';
import I3FilterToggle from '../apps/i3-filter-toggle';

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    mountApp({
      app: I3FilterToggle,
      selector: '[data-app=i3-filter-toggle]',
      setI3Channel: true,
      props: {
        facetId: 'webpublish',
      },
    });
  },
};
