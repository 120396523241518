var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "product-main-action__configurator" },
      [
        _c(
          "ConfiguratorSection",
          {
            attrs: {
              rowTitle: _vm.financingSectionName,
              isRowOpen: _vm.rowOpen === _vm.rowKeys.price,
              rowKey: _vm.rowKeys.price
            },
            on: { onOpenRow: _vm.openRow }
          },
          [
            _c(
              "div",
              {
                staticClass: "product-main-action__row__value",
                attrs: { slot: "row-value" },
                slot: "row-value"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "price-tag",
                    class: {
                      "price-tag--discount":
                        _vm.priceDisplay.current < _vm.priceDisplay.ordinary
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$formatPriceNoSuffix(_vm.priceDisplay.current) +
                            _vm.priceDisplay.suffix
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.priceDisplay.current < _vm.priceDisplay.ordinary
                  ? _c(
                      "div",
                      { staticClass: "price-tag price-tag--obsolete" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$formatPriceNoSuffix(
                                _vm.priceDisplay.ordinary
                              ) + _vm.priceDisplay.suffix
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "product-main-action__row__content",
                attrs: { slot: "row-content" },
                slot: "row-content"
              },
              [
                _c("FinanceSection", {
                  attrs: {
                    selected: _vm.selectedFinancingOption,
                    financeOptions: _vm.financeOptions,
                    lang: _vm.lang
                  },
                  on: { "change-finance-option": _vm.onChangeFinanceOption }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _vm.displayDepartments
          ? _c(
              "ConfiguratorSection",
              {
                attrs: {
                  rowTitle: "Leverans",
                  isRowOpen: _vm.rowOpen === _vm.rowKeys.department,
                  rowKey: _vm.rowKeys.department
                },
                on: { onOpenRow: _vm.openRow }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__value",
                    attrs: { slot: "row-value" },
                    slot: "row-value"
                  },
                  [
                    _vm.selectedDepartmentOption
                      ? _c("div", {
                          staticClass: "delivery-indicator",
                          class: {
                            "delivery-indicator--available":
                              _vm.selectedDepartmentOption.value ===
                              this.departments[0].value,
                            "delivery-indicator--unavailable":
                              _vm.selectedDepartmentOption.value !==
                              this.departments[0].value
                          }
                        })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.selectedDepartmentOption
                            ? _vm.selectedDepartmentOption.name
                            : "Välj anläggning"
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__content",
                    attrs: { slot: "row-content" },
                    slot: "row-content"
                  },
                  [
                    _c("ConfiguratorSubSelect", {
                      attrs: {
                        options: _vm.departments,
                        defaultValue: _vm.selectedDepartment,
                        isDepartmentSelect: ""
                      },
                      on: { change: _vm.onChangeDepartment }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayTradeIn
          ? _c(
              "ConfiguratorSection",
              {
                attrs: {
                  rowTitle: "Inbytesbil",
                  isRowOpen: _vm.rowOpen === _vm.rowKeys.tradeIn,
                  rowKey: _vm.rowKeys.tradeIn
                },
                on: { onOpenRow: _vm.openRow }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__value",
                    class: {
                      "product-main-action__row__value--static": !_vm.appraisedVehicle
                    },
                    attrs: { slot: "row-value" },
                    slot: "row-value"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.appraisedVehicle
                            ? _vm.appraisedVehicle.toUpperCase()
                            : "Lägg till"
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__content",
                    attrs: { slot: "row-content" },
                    slot: "row-content"
                  },
                  [
                    _c("TradeInSection", {
                      attrs: {
                        lang: _vm.lang,
                        valuationSubmitted: _vm.valuationSubmitted,
                        currentVehicleRegNr: _vm.regNr
                      },
                      on: {
                        onValuationSubmitted: _vm.onValuationSubmitted,
                        onVehicleAppraised: _vm.onVehicleAppraised
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayServiceAgreement
          ? _c(
              "ConfiguratorSection",
              {
                attrs: {
                  rowTitle: "Serviceavtal",
                  isRowOpen: _vm.rowOpen === _vm.rowKeys.serviceAgreement,
                  rowKey: _vm.rowKeys.serviceAgreement
                },
                on: { onOpenRow: _vm.openRow }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__value",
                    class: {
                      "product-main-action__row__value--static": !_vm.storeState
                        .serviceAgreementSummation
                    },
                    attrs: { slot: "row-value" },
                    slot: "row-value"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.storeState.serviceAgreementSummation
                            ? "+" + _vm.serviceAgreementTotal + " kr/mån"
                            : "Lägg till"
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__content",
                    attrs: { slot: "row-content" },
                    slot: "row-content"
                  },
                  [
                    _c("ServiceAgreementSection", {
                      attrs: {
                        lang: _vm.lang,
                        serviceAgreementData: _vm.serviceAgreementData
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayTyres
          ? _c(
              "ConfiguratorSection",
              {
                attrs: {
                  rowTitle: "Vinterhjul",
                  isRowOpen: _vm.rowOpen === _vm.rowKeys.tyres,
                  rowKey: _vm.rowKeys.tyres
                },
                on: { onOpenRow: _vm.openRow }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__value",
                    class: {
                      "product-main-action__row__value--static":
                        !this.selectedTyres.length > 0
                    },
                    attrs: { slot: "row-value" },
                    slot: "row-value"
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.tyreValueString) + "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__content",
                    attrs: { slot: "row-content" },
                    slot: "row-content"
                  },
                  [
                    _c("TyreSection", {
                      attrs: {
                        lang: _vm.lang,
                        selectedFinancingOption: _vm.selectedFinancingOption,
                        displayTax: _vm.displayTax
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayFittings || _vm.displayChargeBox
          ? _c(
              "ConfiguratorSection",
              {
                attrs: {
                  rowTitle: "Tillbehör",
                  isRowOpen: _vm.rowOpen === _vm.rowKeys.accessories,
                  rowKey: _vm.rowKeys.accessories
                },
                on: { onOpenRow: _vm.openRow }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__value",
                    class: { "product-main-action__row__value--static": true },
                    attrs: { slot: "row-value" },
                    slot: "row-value"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.addonsLength > 0
                            ? _vm.addonsLength + " aktivt val"
                            : "Lägg till"
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product-main-action__row__content",
                    attrs: { slot: "row-content" },
                    slot: "row-content"
                  },
                  [_c("AccessoriesSection", { attrs: { lang: _vm.lang } })],
                  1
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-main-action__summary" },
      [
        _c("SummationList", {
          attrs: {
            lang: _vm.lang,
            selectedFinancing: _vm.selectedFinancingOption,
            displayTax: _vm.displayTax,
            inSummation: true
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }