export default {
  checkout: {
    string: 'Öppnar/Visar kassan',
    order: 1,
  },
  department: {
    string: 'Väljer anläggning',
    order: 2,
  },
  customerDetails: {
    string: 'Lämnar kunduppgifter',
    order: 3,
  },
  payment: {
    string: 'Faktura, Kort, Swish',
    order: 4,
  },
  summary: {
    string: 'Visar sammanställning',
    order: 5,
  }
}