var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.orders.length
    ? _c(
        "div",
        { staticClass: "my-pages__section" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.orders, function(order) {
            return _c(
              "a",
              {
                key: order.id,
                staticClass: "section-item",
                attrs: { _target: "_blank", href: order.url }
              },
              [
                _c("div", { staticClass: "section-item__col" }, [
                  _c("div", {
                    staticClass: "section-item__status-icon",
                    class: {
                      "section-item__status-icon--red": order.status === "red",
                      "section-item__status-icon--green":
                        order.status === "green",
                      "section-item__status-icon--yellow":
                        order.status === "yellow"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "my-pages__section__title" }, [
                    _vm._v(_vm._s(order.id))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "section-item__col section-item__col--middle-item"
                  },
                  [
                    _c("span", { staticClass: "my-pages__section__title" }, [
                      _vm._v(_vm._s(order.date))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "section-item__col section-item__col--last-item"
                  },
                  [
                    _c("span", { staticClass: "my-pages__section__title" }, [
                      _vm._v(_vm._s(_vm.$formatPrice(order.cost)))
                    ])
                  ]
                )
              ]
            )
          })
        ],
        2
      )
    : _c("div", { staticClass: "my-pages__section my-pages__section--empty" }, [
        _c("p", [_vm._v("Inga ordrar hittades")])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "my-pages__section__title-container my-pages__section__title-container--header"
      },
      [
        _c("div", { staticClass: "section-item__col" }, [
          _c("span", { staticClass: "my-pages__section__title" }, [
            _vm._v("ORDERNR")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "section-item__col section-item__col--middle-item" },
          [
            _c("span", { staticClass: "my-pages__section__title" }, [
              _vm._v("DATUM")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "section-item__col section-item__col--last-item" },
          [
            _c("span", { staticClass: "my-pages__section__title" }, [
              _vm._v("SUMMA")
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }