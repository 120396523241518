import ACCleaning from "../apps/service-control";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: ACCleaning,
      selector: '[data-app=service-control]',
      props: {
        pageName: window.document.title,
      },
    });
  },
};