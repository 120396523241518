<template>
  <div class="my-pages__logged-in">
    <div class="my-pages__facets-view">
      <div
        class="my-pages__facet"
        v-for="item in lines"
        :key="item.id"
        :current-user="currentUser"
        :class="{'my-pages__facet--selected': selectedFacets.includes(item.id)}"
      >
        <div class="my-pages__facet-pane" @click.stop="select(item.id)">
          <div class="my-pages__facet-name">{{item.title}}</div>
          <div
            class="my-pages__facet-counter"
            v-if="
              item.section === MY_VEHICLES ||
              item.section === SAVED_VEHICLES ||
              item.section === SUBSCRIPTIONS ||
              item.section === TYRE_ORDERS ||
              item.section === MY_TYRES
            "
          >
            <span class="text">{{ getSectionData(item.section).length }}</span>
          </div>
          <div class="my-pages__facet-icon">
            <i class="fal fa-angle-down"></i>
          </div>
        </div>
        <div v-if="selectedFacets.includes(item.id)">
          <component
            :is="getCurrentSection(item.section)"
            :current-user="currentUser"
            :lang="lang"
            @signOut="$emit('signOut')"
            :sectionData="getSectionData(item.section)"
            :tyreError="tyreError"
            @deleteVehicle="onDeleteVehicle"
            @deleteMyVehicle="onDeleteMyVehicle"
            @deleteSubscription="onDeleteSubscription"
            @clickVehicle="onClickVehicle"
            @clickSubscription="onClickSubscription"
            @onChangeLocation="onChangeLocation"
          ></component>
        </div>
      </div>
    </div>
    <div class="my-pages__logout" @click="$emit('signOut')">
      <span>
        Logga ut
        <i class="fal fa-sign-out-alt"></i>
      </span>
    </div>
  </div>
</template>

<script>
import sectionComponents from "./constants/sectionComponents";
import sections from "./constants/sections";
import excemptStrings from "./constants/excemptStrings";
import SavedVehicles from "./sections/SavedVehicles.vue";
import Subscriptions from "./sections/Subscriptions.vue";
import TyreOrders from "./sections/TyreOrders.vue";
import Settings from "./sections/Settings.vue";
import mock from "./mock";
import I3DocProduct from "../../models/I3DocProduct";
import Axios from '../../axios';
import qs from 'query-string';
import I3Channel from '../../models/I3Channel';
import SaveVehicleManager from '../../models/SaveVehicleManager';
import EonOrderStates from '../../constants/EonOrderStates';
import { EventBus } from '../../utils/event-bus';

export default {
  components: {
    SavedVehicles,
    Subscriptions,
    TyreOrders,
    Settings
  },
  props: {
    currentUser: Object,
    lang: Object
  },
  data() {
    return {
      SAVED_VEHICLES: sections.SavedVehicles,
      SUBSCRIPTIONS: sections.Subscriptions,
      TYRE_ORDERS: sections.TyreOrders,
      MY_VEHICLES: sections.MyVehicles,
      MY_TYRES: sections.MyTyres,
      SETTINGS: sections.Settings,
      selectedFacets: [],
      lines: [
        {
          id: 1,
          title: "Sparade fordon",
          section: sections.SavedVehicles
        },
        {
          id: 2,
          title: "Bevakningar",
          section: sections.Subscriptions
        },
        {
          id: 3,
          title: "Däckordrar",
          section: sections.TyreOrders
        },
        {
          id: 4,
          title: "Mina fordon",
          section: sections.MyVehicles
        },
        {
          id: 5,
          title: "Mina däck",
          section: sections.MyTyres
        },
        {
          id: 6,
          title: "Kontoinställningar",
          section: sections.Settings
        }
      ],
      subscriptions: [],
      orders: [],
      savedVehicles: [],
      myVehicles: [],
      myTyres: [],
      selectedLocation: this.currentUser.location,
      tyreError: false,
    };
  },
  created() {
    SaveVehicleManager.onAlterVehicleList(async (e) => {
      const savedVehicles = await Axios.get('/api/MyPages/vehicles');
      const documents = await this.getDocuments(savedVehicles.data);

      this.savedVehicles = documents;
    });
    EventBus.$on('my-vehicle-added', async () => {
      this.myVehicles = await this.getMyVehicles();
    });
    EventBus.$on('trade-in-completed', async () => {
      this.myVehicles = await this.getMyVehicles();
    });
    EventBus.$on('requestTradeIn', async () => {
      this.myVehicles = await this.getMyVehicles();
    });
  },
  methods: {
    select(id) {
      if (this.selectedFacets.includes(id)) {
        this.selectedFacets = this.selectedFacets.filter(item => item !== id);
      } else {
        this.selectedFacets = [...this.selectedFacets, id];
      }
    },
    getCurrentSection(section) {
      return sectionComponents[section];
    },
    getSectionData(section) {
      let data;
      switch (section) {
        case sections.SavedVehicles:
          data = this.savedVehicles.map(document => new I3DocProduct(document));
          break;
        case sections.Subscriptions:
          data = this.subscriptions;
          break;
        case sections.TyreOrders:
          data = this.orders;
          break;
        case sections.MyVehicles:
          data = this.myVehicles;
          break;
        case sections.MyTyres:
          data = this.myTyres;
          break;
      }

      return data;
    },
    async getTyres(vehicles) {
      try {
        this.myTyres = [];
        this.tyreError = false;
        if (vehicles.length) {
          this.tyreError = true;
        }
        vehicles.forEach(async(vehicleData) => {
          try {
            const { data } = await Axios.post('/api/MyPages/tyrehotel', {
              location: this.selectedLocation,
              registrationNumber: vehicleData.registrationNumber,
            })
            this.tyreError = false;
            this.myTyres = [...this.myTyres, { tyreData: data, vehicleData }];
          } catch (e) {

          }
        });
      } catch(e) {

      }
    },
    async onDeleteVehicle(registrationNumber) {
      const response = await Axios.delete(`/api/MyPages/vehicles/${registrationNumber}`);
      const documents = await this.getDocuments(response.data)
      this.savedVehicles = documents;
      SaveVehicleManager.alterVehicleList();
    },
    async onDeleteMyVehicle(id) {
      const response = await Axios.delete(`/api/MyPages/myvehicles/${id}`);
      this.myVehicles = await this.getMyVehicles();
    },
    async onDeleteSubscription(id) {
      const response = await Axios.delete(`/api/MyPages/searches/${id}`);
      const subscriptions = await this.getSubscriptions(response.data)
      this.subscriptions = subscriptions;
    },
    onClickVehicle(permalink) {
      window.location.href = permalink;
    },
    onClickSubscription(searchString) {
      const mainSearchPage = window.MainVehicleSearchPage;
      window.location.href = `${ window.MainVehicleSearchPage }?${ searchString }`
    },
    async getDocuments(array) {
      const uniqueArray = [...new Set(array)];
      const searchChannel = window.MyPagesVehiclesSearchChannel || "https://haapi.holmgrensbil.se/rest/apps/web/searchers/mypagesvehicles";
      const response = await Axios.get(`${searchChannel}?q=${ uniqueArray.join(',') }`)
      return response.data.documents.documents;
    },
    async getOrders() {
      try {
        const response = await Axios.get('/api/MyPages/tyreorders');
        return response.data;
      } catch(e) {
        return [];
      }

    },
    async getMyVehicles() {
      try {
        const response = await Axios.get('/api/MyPages/myvehicles');
        await this.getTyres(response.data);
        return response.data;
      } catch(e) {
        return [];
      }

    },
    getSubscriptions(savedSearches) {
      const subscriptions = savedSearches.map(sub => {

        const queries = qs.parse(sub.searchString)
        const tags = this.getTags(queries)

        return {
          tags,
          id: sub.id,
          searchString: sub.searchString,
        }
      })

      return subscriptions;
    },
    getTags(queries) {
      const tags = []

      Object.entries(queries).forEach((entry, index) => {
        if (excemptStrings.includes(entry[0]) || (entry[0] === 'q' && entry[1] === "*")) {
        } else if (entry[0] === 'price_min') {
          const price = `Pris: ${ entry[1] } - ${ Object.entries(queries)[index - 1][1] }`
          tags.push(price)
        } else if (entry[0] === 'mileage_min') {
          const price = `Mil: ${ entry[1] } - ${ Object.entries(queries)[index - 1][1] }`
          tags.push(price)
        } else if (typeof entry[1] !== 'string') {
          entry[1].forEach(e => tags.push(e))
        } else {
          if (entry[1].split('§').length > 1) {
            const subTags = entry[1].split('§');
            subTags.forEach(tag => tags.push(tag))
          } else {
            tags.push(entry[1])
          }
        }
      })

      return tags;
    },
    onChangeLocation(location) {
      this.selectedLocation = location;
      this.getTyres(this.myVehicles);
    }
  },
  async mounted() {
    const savedVehicles = await Axios.get('/api/MyPages/vehicles');
    const savedSearches = await Axios.get('/api/MyPages/searches');

    const subscriptions = this.getSubscriptions(savedSearches.data);
    const documents = await this.getDocuments(savedVehicles.data);
    const orders = await this.getOrders();

    this.myVehicles = await this.getMyVehicles();
    this.savedVehicles = documents;
    this.subscriptions = subscriptions;
    this.orders = orders.filter(o => [EonOrderStates.Confirmed, EonOrderStates.Completed].includes(o.orderState));
  }
};
</script>
