<template>
  <div
    class="form-row__col"
    :class="{
      'service-agreement-option': this.option.id === 'signServiceAgreement' && this.option.selected,
    }"
  >
    <VcOptionBox
      :selected="option.selected"
      :heading="option.heading"
      :smallText="option.headingMeta"
      :smallBlock="option.headingMeta && option.headingMeta.length > 15"
      :checkbox="multiselect"
      :image="option.image"
      :attentionRibbon="ribbonText"
      :largeImage="option.largeImage"
      @click="onClick"
      @linkClick="onLinkClick"
    >
      <div
        v-if="price && price.attentionText && !option.image"
        slot="header-aside"
        class="price-tag__campaign"
      >
        <div class="price-tag__campaign__tag">
          <span class="price-tag--discount" v-html="price.attentionText"></span>
        </div>
        <div>
          <VcPriceTag :price="price" showIfZero> </VcPriceTag>
        </div>
      </div>
      <div v-else-if="price" slot="header-aside">
        <VcPriceTag :price="price" :show-if-zero="showIfZero"> </VcPriceTag>
      </div>
      <div slot="header-link" v-if="option.subOptionsText">
        <span>{{ option.subOptionsText }}</span>
        <FontAwesomeIcon :icon="expanded ? faAngleUp : faAngleDown">
        </FontAwesomeIcon>
      </div>
      <div slot="header-link" v-else-if="option.modalContent">
        <span>Läs mer</span>
      </div>
      <div
        slot="content"
        v-if="displayContent"
      >
        <div class="form-row">
          <ConfiguratorSubSelect
            :selectedValue="months"
            @change="setMonths"
            :option="monthsOptions"
          />
          <ConfiguratorSubSelect
            :selectedValue="mileage"
            @change="setMileage"
            :option="mileageOptions"
          />
          <button
            @click="getServiceAgreement"
            class="btn btn--primary wizard--service-booking__select-service-submit"
          >
            Beräkna
          </button>
        </div>
        <div class="form-row service-addons" v-if="fetchedAgreement">
          <div class="main-info">
            <div class="main-info__heading">
              {{ lang.Summation.ServiceAgreementOfferHeading }}
            </div>
            <div v-if="fetchedAgreement.price" class="main-info__data">
              {{ `${$formatPriceNoSuffix(fetchedAgreement.price.current)} kr/mån` }}
            </div>
            <div class="main-info__meta">
              {{ lang.Summation.ServiceAgreementOfferText }}
            </div>
          </div>
          <div class="configurator-section__completed__sub-action">
            <div class="main-info__heading">
              {{ lang.Summation.ServiceAgreementAddonsHeading }}
            </div>
            <VcOptionBox
              v-for="additionalProduct in fetchedAgreement.options"
              :key="additionalProduct.id"
              :selected="addedAdditionalProducts.includes(additionalProduct.id)"
              :heading="additionalProduct.heading"
              :checkbox="true"
              @click="changeAdditionalProduct(additionalProduct.id, additionalProduct)"
            >
              <div slot="header-meta" v-if="true">
                <a
                  class="link link--underline link--muted"
                  @click.prevent.stop="
                    onLinkClick(
                      additionalProduct.heading,
                      additionalProduct.description
                    )
                  "
                >
                  {{ lang.SiteGeneral.ReadMore }}
                </a>
              </div>

              <div v-if="additionalProduct.price" slot="header-aside">
                <VcPriceTag
                  :price="additionalProduct.price"
                  :show-if-zero="true"
                >
                </VcPriceTag>
              </div>
            </VcOptionBox>
          </div>
        </div>
      </div>
    </VcOptionBox>

    <VcModal v-if="modalOpen" @close="modalOpen = false">
      <div slot="header">
        {{ option.heading }}
      </div>
      <div slot="content" v-html="option.modalContent"></div>
    </VcModal>
  </div>
</template>

<script>
import { VcOptionBox, VcModal, VcPriceTag } from "@holmgrensbil/vue-components";
import ConfiguratorSubSelect from '../vehicle-configurator/ConfiguratorSubSelect.vue';
import Axios from "../../axios";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { mapActions, mapGetters, mapState } from 'vuex';
import FieldTypes from './constants/FieldTypes';

export default {
  components: {
    VcOptionBox,
    VcModal,
    VcPriceTag,
    ConfiguratorSubSelect,
  },
  props: {
    option: {
      type: Object,
      required: true,
    },
    multiselect: Boolean,
  },
  data() {
    return {
      expanded: false,
      modalOpen: false,
      faAngleUp,
      faAngleDown,
      mileage: null,
      months: null,
      currentMileage: null,
      fetchedAgreement: null,
      addedAdditionalProducts: [],
      modifiedOption: {},
    }
  },
  created() {
    if (this.option.id === 'signServiceAgreement') {


      const selectedAgreement = this.fieldByType(FieldTypes.ServiceAgreement).value ? this.fieldByType(FieldTypes.ServiceAgreement).value[0] : null;
      const selectedAgreementAddons = this.fieldByType(FieldTypes.ServiceAgreementAddons).value || [];

      this.mileage = this.option?.mileage.find(mileage => mileage.value === (selectedAgreement?.selectedMileage || 1500))?.value || null;
      this.months = this.option?.months.find(month => month.value === (selectedAgreement?.selectedMonths || 24))?.value || null;
      this.currentMileage = this.option?.currentMileage;

      if (selectedAgreement && this.option.selected) {
        this.getServiceAgreement();
        this.fetchedAgreement = selectedAgreement;
        this.addedAdditionalProducts = selectedAgreementAddons;
      }
    } else if (this.option.selected) {
      this.setField({
        type: FieldTypes.ServiceAgreement,
        value: [this.option],
      });
    }
    },
  computed: {
    displayContent() {
      return this.option.selected && this.option.id === 'signServiceAgreement';
    },
    ribbonText() {
      if(this.option?.largeImage) {
        return this.option?.price?.attentionText;
      }
      return this.option?.ribbonText;
    },
    showIfZero() {
      return this.option?.price?.showIfZero;
    },
    monthsOptions() {
      return {
        heading: "Serviceavtalslängd",
        required: true,
        values: this.option?.months || [],
      };
    },
    price() {
      return this.option.id === 'signServiceAgreement' && this.fetchedAgreement ? this.fetchedAgreement.price : this.option.price;
    },
    mileageOptions() {
      return {
        heading: "Körsträcka per år",
        required: true,
        values: this.option?.mileage || [],
      };
    },
    ...mapState({
      lang: state => state.config.lang,
    }),
    ...mapGetters({
      fieldByType: 'fields/byType',
    }),
  },
  methods: {
  ...mapActions({
      setField: "fields/set"
    }),
    onLinkClick() {
      if (!this.option.selected) {
        this.$emit("click");
      }

      if (this.option.subOptionsText) {
        this.expanded = !this.expanded;
      } else if (this.option.modalContent) {
        this.modalOpen = true;
      }
    },
    async onClick() {
      if (this.option.id !== 'signServiceAgreement') {
        const optionObj = {
          ...this.option,
          selected: true,
        }
        await this.setField({
          type: FieldTypes.ServiceAgreement,
          value: [optionObj],
        });

       await this.setField({
          type: FieldTypes.ServiceAgreementAddons,
          value: [],
        });
      } else if (this.option.id === 'signServiceAgreement') {
        await this.getServiceAgreement();
      }
      this.$emit('change', !this.option.selected);
    },
    setMileage(mileage) {
      this.mileage = Number(mileage);
    },
    setMonths(months) {
      this.months = Number(months);
    },
    async getServiceAgreement() {
      const regNr = this.fieldByType(FieldTypes.RegNr).value;
      try {
        const { data } = await Axios.get(
          `/api/vehicleConfigurator/${regNr}/serviceAgreement?mileage=${this.mileage}&months=${this.months}&currentMileage=${this.currentMileage}&useNearest=true`
        );

        this.addedAdditionalProducts = data.additionalProducts
          .map(product => {
            if (product.selected) {
              return product;
            }
          })
          .filter(product => product);

        const option = {
          heading: `Serviceavtal ${this.months} mån ${this.mileage} mil/år`,
          inputType: 1,
          id: 'signServiceAgreement',
          name: "signServiceAgreement",
          price: {
            priceText: `${data.monthlyCost} kr/mån`,
            current: data.monthlyCost,
            suffix: " kr/mån",
            showIfZero: true,
          },
          rowIndex: 0,
          selected: true,
          sortOrder: 0,
          mileage: this.mileage,
          months: this.months,
          selectedMileage: this.mileage,
          selectedMonths: this.months,
          options: data.additionalProducts.map((product) => {
            return {
              id: product.id,
              heading: product.name + ' (Serviceavtal)',
              price: {
                current: product.monthlyCost,
                suffix: ' kr/mån',
              },
              description: product.description,
              selected: product.selected,
              name: 'signServiceAgreement',
            }
          })
        };

        this.fetchedAgreement = option;

        const serviceAgreementField = this.fieldByType(FieldTypes.ServiceAgreement);
        const alteredSubOptions = serviceAgreementField.options.map(subOption => {
          if (subOption.id === 'signServiceAgreement') {
            return option;
          } else {
            return subOption;
          }
        });

        await this.setField({
          type: FieldTypes.ServiceAgreement,
          value: [option],
          options: alteredSubOptions,
        });

        await this.setField({
          type: FieldTypes.ServiceAgreementAddons,
          options: [option],
        });

      } catch (e) {
        console.error(e);
      }
    },
    changeAdditionalProduct(id, test) {
      if (this.addedAdditionalProducts.includes(id)) {
        this.addedAdditionalProducts = this.addedAdditionalProducts.filter(
          product => product !== id
        );
      } else {
        this.addedAdditionalProducts = [...this.addedAdditionalProducts, id];
      }

      this.setField({
        type: FieldTypes.ServiceAgreementAddons,
        value: this.addedAdditionalProducts,
      });
    }
  },
}
</script>
