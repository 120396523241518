var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-checkbox" }, [
    _c(
      "label",
      {
        ref: "checkboxInput",
        staticClass: "configurator-checkbox__container",
        class: {
          "configurator-checkbox__container--checked": _vm.checked
        },
        style: { height: _vm.imageSource ? _vm.containerHeight : "42px" }
      },
      [
        _c("div", { staticClass: "configurator-checkbox__title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.imageSource ? "" : _vm.text) + "\n      "
          ),
          _vm.subText ? _c("span", [_vm._v(_vm._s(_vm.subText))]) : _vm._e()
        ]),
        _vm._v(" "),
        _vm.imageSource
          ? _c(
              "div",
              {
                staticClass: "configurator-checkbox__image-container",
                style: { backgroundImage: "url(" + _vm.imageSource + ")" }
              },
              [
                _vm.imageTags && _vm.imageTags.length
                  ? _c(
                      "div",
                      { staticClass: "configurator-checkbox__tag-container" },
                      _vm._l(_vm.imageTags, function(tag) {
                        var _obj, _obj$1
                        return _c(
                          "div",
                          {
                            staticClass: "product-tag",
                            class:
                              ((_obj = {}), (_obj[tag.tagTheme] = true), _obj)
                          },
                          [
                            _c("i", {
                              class:
                                ((_obj$1 = {}),
                                (_obj$1["far fa-" + tag.icon] = tag.icon),
                                _obj$1)
                            }),
                            _vm._v(
                              "\n          " + _vm._s(tag.title) + "\n        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          staticClass: "configurator-checkbox__input",
          attrs: { type: "checkbox" },
          domProps: { checked: _vm.checked },
          on: { change: _vm.onCheck }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "configurator-checkbox__checkmark",
          style: { top: _vm.imageSource ? "12px" : "auto" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }