<template>
  <div>
    <div class="form-check form-check--sm"
      @click.stop="$emit('change', !field.value ? true : null)">
      <input
        :checked="field.value"
        class="form-check__input"
        type="checkbox"
      />
      <div class="form-check__label">
        {{field.label}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
  },
}
</script>
