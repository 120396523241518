<template>
  <FieldInputText
    v-bind="$props"
    v-on="$listeners"
    type="tel">
  </FieldInputText>
</template>

<script>
import FieldInputText from './FieldInputText.vue';

export default {
  components: {
    FieldInputText,
  },
  props: {
    invalid: Boolean,
    field: Object,
  },
}
</script>
