var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact__department-actions" },
    [
      _vm._l(_vm.actions, function(action) {
        return _c(
          "a",
          {
            key: "" + action.url + action.text,
            staticClass: "btn btn--sm",
            class: action.class,
            attrs: {
              href: action.url,
              target: _vm.isUrlExternal(action.url) ? "_blank" : ""
            },
            on: {
              click: function($event) {
                return _vm.onActionClick(action)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(action.text) + "\n  ")]
        )
      }),
      _vm._v(" "),
      _vm.hasSparePartsModal && _vm.modalActive
        ? _c(
            "VcModal",
            {
              on: {
                close: function($event) {
                  _vm.modalActive = false
                }
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("\n      Skicka en förfrågan på reservdel\n    ")
              ]),
              _vm._v(" "),
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c("div", { ref: "sparePartsAppHolder" }, [
                  _c("div", { ref: "sparePartsApp" })
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }