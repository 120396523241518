<template>
  <div slot="content" class="tooltip-container">
    <div slot="content" class="tooltip-content tooltip-content--arrow">
      <p class="tooltip-title">
        Välkommen till Holmgrens Däckshop!
      </p>
      <p class="tooltip-content-text">
        Däckshoppen är nu filtrerad med kompletta hjul,
        enbart däck, enbart fälg och begagnade hjul som
        passar ditt fordon.
      </p>
      <p class="tooltip-product">
        Nu visas produkter med däckdimension: {{TyreSpec.tyreSize.diameter}}" {{TyreSpec.tyreSize.width}}/{{TyreSpec.tyreSize.aspectRatio}}R{{TyreSpec.tyreSize.diameter}}
      </p>
      <div class="btn btn--white btn--block"
        :class="{'disabled': buttonDisabled}"
        @click="$emit('openTyreSelector')">
        Ändra däckdimensioner
      </div>
      <div class="btn btn--primary btn--block"
        :class="{'disabled': buttonDisabled}"
        @click="$emit('markAsSeen')">
        Okej, stäng denna ruta!
      </div>
    </div>
  </div>
</template>

<script>
import { VcModal } from '@holmgrensbil/vue-components';

export default {
  components: {
    // SelectManual,
    // SelectRecommended,
    VcModal,
  },
  props: {
    TyreSpec: Object,
  },
  data() {
    return {
      // tyreSize: null,
    };
  },
  computed: {
    buttonDisabled() {
      return false;
    },
    manualSelect() {
      return this.TyreSpec.recommendedSizes.length === 0 || this.forceManual;
    },
  },
  // created() {
  //   this.tyreSize = { ...this.TyreSpec.tyreSize };
  // },
  methods: {
    onChange(tyreSize) {
      // console.log("onChange", tyreSize);
      // this.tyreSize = tyreSize;
    },

    onSubmit() {
      // if(this.manualSelect) {
      //   this.TyreSpec.clear();
      // }
      // this.TyreSpec.set({
      //   tyreSize: this.tyreSize,
      // });
      // window.location.href = this.buttonHref;
    },
  },
}
</script>
