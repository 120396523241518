<template>
  <div class="my-pages__section">
    <div class="my-pages__section__title-container">
      <span class="my-pages__section__title">KONTO</span>
      <a @click.prevent="onDeleteAccount" :class="{'delete-button': !deleting}">{{deleting ? 'Raderar konto...' : 'Radera konto'}}</a>
      <div v-if="deleteError" class="feedback-message feedback-message--danger feedback-message--slim">
        <div class="feedback-message__text">
          {{lang.App.DeleteError}}
        </div>
      </div>
    </div>
    <div>
      <span>{{currentUser.email}}</span>
    </div>

    <div class="my-pages__section__title-container">
      <span class="my-pages__section__title">ORT</span>
    </div>
    <div v-if="locations.length">
      <div class="form-stack">
        <label>
         Din ort
        </label>
        <div class="form-select">
          <select class="form-control" v-model="selectedLocation" @change="onLocationChange()">
            <option value="">
              Välj ort
            </option>
            <option v-for="location in locations" :value="location.value" :key="location.value">
              {{location.name}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="my-pages__section__title-container">
      <span class="my-pages__section__title">ÄNDRA LÖSENORD</span>
    </div>
    <div class="form-stack form-stack--required">
      <label>
        Nytt lösenord
      </label>
      <input class="form-control" v-model="newPassword" placeholder="Ange nytt lösenord" type="password" required />
    </div>
    <div class="form-stack form-stack--required">
      <label>
        Upprepa nytt lösenord
      </label>
      <input class="form-control" v-model="confirmNewPassword" placeholder="Upprepa nytt lösenord" type="password" required />
    </div>
    <div class="form-stack form-stack--required">
      <label>
        Nuvarande lösenord
      </label>
      <input class="form-control" v-model="currentPassword" placeholder="Ange ditt nuvarande lösenord" type="password" required />
    </div>
    <button :class="{'disabled': btnDisabled}" class="btn btn--primary btn--block"
      @click="onSubmitPassword">
      Ändra lösenord
    </button>
    <div v-if="updateSuccess" class="feedback-message feedback-message--success feedback-message--slim">
      <div class="feedback-message__text">
        {{successMessage}}
      </div>
    </div>
    <div v-if="updateError" class="feedback-message feedback-message--danger feedback-message--slim">
      <div class="feedback-message__text">
        {{errorMessage}}
      </div>
    </div>
  </div>
</template>

<script>

import Axios from '../../../axios';
const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
import { VcFormStack, VcFormSelect } from '@holmgrensbil/vue-components';
import { EventBus } from '../../../utils/event-bus';

export default {
  components: {
    VcFormStack,
    VcFormSelect,
  },
  data() {
    return {
      newPassword: null,
      currentPassword: null,
      confirmNewPassword: null,
      updateError: false,
      updateSuccess: false,
      errorMessage: this.lang.App.PasswordChangeError,
      successMessage: this.lang.App.PasswordChangeSuccess,
      btnDisabled: false,
      deleteError: false,
      deleting: false,
      locations: [],
      selectedLocation: this.currentUser.location
    };
  },
  props: {
    currentUser: Object,
    lang: Object,
  },
  async created() {
    try {
      const res = await Axios.get('/api/contact/GetLocations');
      this.locations = res.data;
    } catch(e) {

    }

  },
  methods: {
    async onDeleteAccount() {
      if(!confirm(this.lang.App.ConfirmDelete)) {
        return;
      }
      this.deleting = true;
      try {
        const result = await Axios.delete('/api/login/deleteuser');
        if(result.status === 200) {
          this.$emit('signOut');
        } else {
          this.deleteError = true;
        }
      } catch(e) {
        this.deleteError = true;
      } finally {
        this.deleting = false;
      }
    },

    async onLocationChange() {
      try {
        const res = await Axios.post('/api/mypages/location', {
          myLocation: this.selectedLocation
        });
        EventBus.$emit('updated-user', {
          ...this.currentUser,
            location: this.selectedLocation
        });

        this.$emit('onChangeLocation', this.selectedLocation)
      } catch(e) {

      }
    },

    async onSubmitPassword() {
      this.updateError = false;
      this.updateSuccess = false;
      if(!pattern.test(this.newPassword)) {
        this.updateError = true;
        this.errorMessage = this.lang.App.PasswordPatternText;
        return;
      }
      if(this.newPassword !== this.confirmNewPassword) {
        this.updateError = true;
        this.errorMessage = this.lang.App.PasswordMatchText;
        return;
      }
      this.btnDisabled = true;
      try {
        const {
          newPassword,
          currentPassword,
        } = this;
        const result = await Axios.post('/api/login/updatepassword', { newPassword, currentPassword });
        if(result.status === 200) {
          this.updateSuccess = true;
        } else {
          this.updateError = true;
        }

      } catch(e) {
        this.updateError = true;
      } finally {
        this.btnDisabled = false;
      }
    }
  }
}
</script>
