<template>
  <div ref="text" class="tyre-text">
    {{text}}
  </div>
</template>

<script>
import CircleType from 'circletype';

export default {
  props: {
    text: String,
  },
  data() {
    return {
      circleType: null,
    };
  },
  mounted() {
    this.circleType = new CircleType(this.$refs.text).radius(170);
  },
  destroyed() {
    this.circleType.destroy();
  },
}
</script>
