import SectionHandles from "../../constants/SectionHandles";

const state = {
  items: [],
  selected: null,
};

const getters = {};

const actions = {
  load({ commit }, items) {
    commit('received', items);
  },

  select({ commit, state, dispatch }, item) {
    if (state.selected) {

      if (state.selected.firstSelection !== 'services' && state.selected.secondSelection !== 'services') {
        dispatch('services/deselect', null, { root: true });
      }
      if (state.selected.firstSelection !== 'brands' && state.selected.secondSelection !== 'brands') {
        dispatch('brands/deselect', null, { root: true });
      }

      if (state.selected.firstSelection !== item.firstSelection || state.selected.secondSelection !== item.secondSelection) {
        dispatch('brands/deselect', null, { root: true });
        dispatch('services/deselect', null, { root: true });
        dispatch('departments/deselect', null, { root: true });
        dispatch('regNr/deselect', null, { root: true });
        dispatch('departments/clearDepartmentsByRegNr', item.sectionHandle, { root: true });
      }
    }

    if (item.sectionHandle === SectionHandles.Tyres) {
      dispatch('services/select', { name: 'Däck', value: 'Däck' }, { root: true })
    }
    if (item.sectionHandle === SectionHandles.ServiceBooking || item.sectionHandle === SectionHandles.DamageWorkshop) {
      dispatch('departments/deselect', null, { root: true });
      dispatch('departments/clearDepartmentsByRegNr', item.sectionHandle, { root: true });
    }

    commit('selected', item);
  },
};

const mutations = {
  received(state, items) {
    state.items = items;
  },

  selected(state, item) {
    state.selected = item;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
