var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "panel",
      staticClass: "i3-filter__panel",
      class: { "i3-filter__panel--minimized": !_vm.showControls },
      style: { "max-height": _vm.maxHeightStyle }
    },
    [
      _vm._t("search"),
      _vm._v(" "),
      _c("div", { staticClass: "i3-filter__panel-controls" }, [
        _vm.queryFilters.length
          ? _c(
              "div",
              _vm._l(_vm.queryFilters, function(qf) {
                return _c(
                  "span",
                  { key: qf.key },
                  [
                    qf.children && qf.children.length
                      ? _c(
                          "TagGroup",
                          { key: qf.key, attrs: { label: qf.label } },
                          _vm._l(qf.children, function(child) {
                            return _c("TagItem", {
                              key: child.key,
                              attrs: { label: child.label, isChild: "" },
                              on: {
                                remove: function($event) {
                                  return _vm.onRemove(child)
                                }
                              }
                            })
                          }),
                          1
                        )
                      : _c("TagItem", {
                          key: qf.key,
                          attrs: { label: qf.label, locked: qf.locked },
                          on: {
                            remove: function($event) {
                              return _vm.onRemove(qf)
                            }
                          }
                        })
                  ],
                  1
                )
              }),
              0
            )
          : _c("div", { staticClass: "i3-filter__panel-meta" }, [
              _vm._v("\n      Inga filter valda\n    ")
            ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "i3-filter__panel-clear",
            class: {
              "i3-filter__panel-clear--disabled": !_vm.queryFilters.length
            },
            on: {
              click: function($event) {
                return _vm.$emit("clearAll")
              }
            }
          },
          [_c("i", { staticClass: "fas fa-trash" })]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }