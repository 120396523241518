var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FieldInput", {
    attrs: { field: _vm.field, invalid: _vm.invalid && _vm.validate },
    on: {
      change: function($event) {
        return _vm.onFieldChange(_vm.field, $event)
      },
      clickchange: function($event) {
        return _vm.onFieldClickChange(_vm.field, $event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }