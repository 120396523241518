<template>
  <div @change="onChange" class="configurator-app__summation-footer">
    <div v-if="done">
      <VcFeedbackMessage success>
        <div slot="header">
          {{ lang.Summation.FeedbackHeader }}
        </div>
        {{ lang.Summation.FeedbackText }}
      </VcFeedbackMessage>
    </div>
    <div v-else>
      <div
        class="configurator-app__contact-section"
        v-if="departmentOptions.length"
      >
        <div class="section__header section__header--sm">
          {{ lang.Summation.ContactDepartmentHeader }}
        </div>
        <VcFormStack label="Anläggning" required>
          <VcFormSelect
            :options="departmentOptions"
            :value="selectedDepartment"
            labelProp="name"
            valueProp="value"
            valueType="number"
            prompt="Ingen anläggning vald"
            required
            @change="onDepartmentChange"
          >
          </VcFormSelect>
        </VcFormStack>
      </div>
      <div
        class="configurator-app__contact-section"
        v-if="requestType !== ContactRequestTypes.Purchase"
      >
        <VcOptionBox
          v-for="option in requestTypeOptions"
          :key="option.name"
          :selected="option.value === requestType"
          :heading="option.name"
          :class="{
            'configurator-app__contact-section__selected-option':
              option.value === requestType
          }"
          @click="onRequestTypeChange(option.value)"
        >
          <div slot="content" v-if="option.value === requestType">
            <VcFormStack
              v-if="requestType === ContactRequestTypes.AskQuestion"
              required
              label="Vad har du för fråga?"
            >
              <textarea v-model="message" class="form-control"></textarea>
            </VcFormStack>
            <VcFormStack
              v-if="
                requestType === ContactRequestTypes.BookMeeting ||
                  requestType === ContactRequestTypes.TestDrive
              "
              label="Datum"
              required
            >
              <VcDatePicker
                :value="date"
                placeholder="Välj en dag"
                @change="onDateChange($event.target.value)"
              >
                <i class="fal fa-calendar-alt" slot="icon"></i>
              </VcDatePicker>
            </VcFormStack>
            <div class="form-stack form-stack--required">
              <label>
                Namn
              </label>
              <input
                class="form-control"
                v-model="name"
                autocomplete="name"
                placeholder="Vad heter du?"
                type="text"
                required
              />
            </div>
            <div class="form-row">
              <div class="form-row__col form-row__col--md-50">
                <div class="form-stack form-stack--required">
                  <label>
                    Telefonnummer
                  </label>
                  <input
                    class="form-control"
                    v-model="phone"
                    autocomplete="tel"
                    placeholder="Ange ditt telefonnummer"
                    type="tel"
                    required
                  />
                </div>
              </div>
              <div class="form-row__col form-row__col--md-50">
                <div class="form-stack form-stack--required">
                  <label>
                    E-post
                  </label>
                  <input
                    class="form-control"
                    v-model="email"
                    autocomplete="email"
                    placeholder="Ange din e-postadress"
                    type="email"
                    required
                  />
                </div>
              </div>
            </div>
            <div
              v-if="isTransport"
              class="configurator-app__contact-section__regnr"
            >
              <div class="configurator-app__contact-section__regnr__title">
                Har du ett fordon som du vill byta in?
              </div>
              <p class="configurator-app__contact-section__regnr__text">
                Vi återkommer med en värdering på ditt fordon.
              </p>
              <div class="regnr-input">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="regnr-input__symbol"></div>
                  </div>
                  <div class="form-stack">
                    <label>
                      Regnummer
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ABC123"
                      pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
                      v-model="regNr"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              :class="{ disabled: loading }"
              type="submit"
              class="btn btn--primary btn--block"
            >
              {{ lang.Summation.SubmitButtonText }}
            </button>
            <div
              class="form-text"
              v-html="lang.General.PrivacyPolicyText"
            ></div>
          </div>
        </VcOptionBox>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VcOptionBox,
  VcFormStack,
  VcFormSelect,
  VcDatePicker,
  VcFeedbackMessage
} from "@holmgrensbil/vue-components";
import ContactRequestTypes from "../../../constants/ContactRequestTypes";
import RequestStates from "../../../constants/RequestStates";
import VueScrollTo from "vue-scrollto";
import Vue from "vue";

export default {
  components: {
    VcOptionBox,
    VcFormStack,
    VcFormSelect,
    VcDatePicker,
    VcFeedbackMessage
  },
  props: {
    department: Object,
    departmentOptions: {
      type: Array,
      default: () => []
    },
    lang: Object,
    isPurchasable: Boolean,
    isTransport: Boolean,
    submitState: Number
  },
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      message: null,
      date: null,
      regNr: null,
      selectedDepartment: null,
      requestType: ContactRequestTypes.Callup,
      requestTypeOptions: [
        {
          name: this.lang.Summation.ContactRequestCallup,
          value: ContactRequestTypes.Callup
        },
        {
          name: this.lang.Summation.ContactRequestAskQuestion,
          value: ContactRequestTypes.AskQuestion
        },
        {
          name: this.lang.Summation.ContactRequestPriceSuggestion,
          value: ContactRequestTypes.PriceSuggestion
        },
        {
          name: this.lang.Summation.ContactRequestTestDrive,
          value: ContactRequestTypes.TestDrive
        },
        {
          name: this.lang.Summation.ContactRequestBookMeeting,
          value: ContactRequestTypes.BookMeeting
        }
      ].filter(Boolean),
      ContactRequestTypes
    };
  },
  created() {
    this.selectedDepartment = this.department;
    this.onChange();
  },
  computed: {
    done() {
      return this.submitState === RequestStates.Success;
    },
    loading() {
      return this.submitState === RequestStates.Request;
    }
  },
  methods: {
    onChange() {
      this.$emit("change", {
        customerName: this.name,
        customerEmail: this.email,
        customerPhone: this.phone,
        customerMessage: this.message,
        departmentNr: this.selectedDepartment
          ? this.selectedDepartment.value
          : this.department
          ? this.department.value
          : null,
        requestDate: this.date,
        requestType: this.requestType,
        regNr: this.regNr
      });
    },

    onRequestTypeChange(value) {
      this.requestType = parseInt(value, 10);
      Vue.nextTick(function() {
        VueScrollTo.scrollTo(
          ".configurator-app__contact-section__selected-option"
        );
      });
    },

    onDepartmentChange(department) {
      this.selectedDepartment = department;
    },

    onDateChange(value) {
      this.date = value;
    }
  }
};
</script>
