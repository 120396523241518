var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "campaign-list__item",
      attrs: { href: _vm.campaign.permalink }
    },
    [
      _c("div", [
        _c("div", { staticClass: "campaign-list__item__image" }, [
          _c("img", {
            attrs: {
              src: _vm.backgroundImage,
              alt: _vm.campaign.meta,
              loading: "lazy"
            }
          }),
          _vm._v(" "),
          _vm.campaign.sticker
            ? _c("div", { staticClass: "sticker sticker--sm" }, [
                _c("span", {
                  staticClass: "sticker__text sticker__text--sm",
                  domProps: { innerHTML: _vm._s(_vm.campaign.sticker) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-spot__tags" },
            [
              _vm._l(_vm.campaign.usps, function(usp) {
                return _c(
                  "div",
                  {
                    key: usp.Name,
                    staticClass: "product-tag",
                    class: [usp.Color]
                  },
                  [
                    usp.Icon ? _c("i", { class: [usp.Icon] }) : _vm._e(),
                    _vm._v("\n          " + _vm._s(usp.Name) + "\n        ")
                  ]
                )
              }),
              _vm._v(" "),
              _vm.campaign.rollupCountSearchUrl
                ? _c(
                    "div",
                    { staticClass: "product-tag product-tag--xs black" },
                    [
                      _c("i", {
                        staticClass: "fas fa-circle",
                        staticStyle: { color: "#ff4401" }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        attrs: {
                          "data-i3-total-count-url":
                            _vm.campaign.rollupCountSearchUrl
                        }
                      }),
                      _vm._v("\n          kvar i lager\n        ")
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "campaign-list__item__content" }, [
          _c("div", { staticClass: "campaign-list__item__logo" }, [
            _c("img", {
              attrs: { src: _vm.campaign.brandImage, loading: "lazy" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "campaign-list__item__meta" }, [
            _vm._v("\n        " + _vm._s(_vm.campaign.meta) + "\n      ")
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "h3" }, [_vm._v(_vm._s(_vm.campaign.title))]),
          _vm._v(" "),
          _c("div", {
            staticClass: "campaign-list__item__text",
            domProps: { innerHTML: _vm._s(_vm.campaignText) }
          }),
          _vm._v(" "),
          _vm.campaign.btnText && _vm.campaign.btnText.length
            ? _c("button", { staticClass: "btn btn--secondary btn--block" }, [
                _vm._v("\n        " + _vm._s(_vm.campaign.btnText) + "\n      ")
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }