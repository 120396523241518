var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedFinancingOption && _vm.vehicleData
    ? _c(
        "div",
        { staticClass: "product-main-action__container vehicle-configurator" },
        [
          _c(
            "div",
            { staticClass: "product-main-action__main" },
            [
              _c("div", { staticClass: "product-main-action__pricing-meta" }, [
                _c("div", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.priceMetaString) + "\n      "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "product-main-action__pricing" }, [
                _c("div", [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "price-tag product-main-action__pricing__current-price",
                        class: {
                          "price-tag--discount":
                            _vm.primaryPrice.current < _vm.primaryPrice.ordinary
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$formatPriceNoSuffix(
                                _vm.primaryPrice.current
                              ) + _vm.primaryPrice.suffix
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.primaryPrice.current < _vm.primaryPrice.ordinary
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "price-tag price-tag--obsolete product-main-action__pricing__obsolete-price"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$formatPriceNoSuffix(
                                    _vm.primaryPrice.ordinary
                                  ) + _vm.primaryPrice.suffix
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("Configurator", {
                attrs: {
                  lang: _vm.lang,
                  regNr: _vm.regNr,
                  priceDisplay: _vm.primaryPrice,
                  displayTax: _vm.shouldDisplayTax,
                  position: _vm.position,
                  selectedFinancingOption: _vm.selectedFinancingOption
                },
                on: { onChangeFinanceOption: _vm.onChangeFinanceOption }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "product-main-action__actions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary btn--block",
                    on: { click: _vm.onOpenContactModal }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "vehicle-configurator__btn-icon",
                      attrs: { icon: _vm.faEnvelope }
                    }),
                    _vm._v("\n        " + _vm._s("Ta kontakt") + "\n      ")
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--muted btn--block",
                    attrs: {
                      href: _vm.showNumber
                        ? "tel:" + _vm.departmentPhoneNumber
                        : "#"
                    },
                    on: { click: _vm.onPhoneClick }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "vehicle-configurator__btn-icon",
                      attrs: { icon: _vm.faPhone }
                    }),
                    _vm._v(" "),
                    !_vm.showNumber
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s("Visa telefonnummer") +
                              "\n        "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.departmentPhoneNumber) +
                              "\n        "
                          )
                        ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      )
    : _c("div", { staticClass: "skeleton-loader" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }