import Axios from "../../axios";
import gtm from "../../utils/gtm";
import ContactRequestTypes from "../../constants/ContactRequestTypes";
import ContactResponseTypes from "../../constants/ContactResponseTypes";
import { EventBus } from "../../utils/event-bus";
import FinanceOptions from '../../constants/FinanceOptions';
import { monthlyCost, getEffectiveRate, monthlyCostTyre } from "../../utils/priceCalculations";
import CalculationKeys from "./constants/calculation-keys";

export default {
  state: {
    config: null,
    calculation: null,
    vehicle: null,
    financingConfig: null,
    financeOptions: [],
    financingSummary: null,
    tradeIn: null,
    contact: null,
    regNr: null,
    tyreData: null,
    tyres: [],
    needTyres: false,
    selectedFinancingOption: null,
    valuation: null,
    serviceAgreement: null,
    fittings: null,
    calculateFinanceOptions: null,
    serviceAgreementSummation: null,
    fittingOptions: [],
    displayTax: false,
    chargeBox: null,
    needChargeBox: false,
    financingTemplateId: null,
    hasFetchedVehicle: false,
    departments: [],
    selectedDepartment: null,
  },

  clearStore() {
    this.state.hasFetchedVehicle = false;
    this.state.config = null;
    this.state.calculation = null;
    this.state.vehicle = null;
    this.state.financingConfig = null;
    this.state.financeOptions = [];
    this.state.financingSummary = null;
    this.state.tradeIn = null;
    this.state.contact = null;
    this.state.regNr = null;
    this.state.tyreData = null;
    this.state.tyres = [];
    this.state.needTyres = false;
    this.state.selectedFinancingOption = null;
    this.state.valuation = null;
    this.state.serviceAgreement = null;
    this.state.fittings = null;
    this.state.calculateFinanceOptions = null;
    this.state.serviceAgreementSummation = null;
    this.state.fittingOptions = [];
    this.state.displayTax = false;
    this.state.chargeBox = null;
    this.state.needChargeBox = false;
    this.state.financingTemplateId = null;
    this.state.hasFetchedVehicle = false;
    this.state.departments = [];
    this.state.selectedDepartment = null;
  },

  async fetchConfigAction(regNr) {
    if (regNr !== this.state.regNr || !this.state.config) {
      this.setRegNrAction(regNr);
      const { data } = await Axios.get(
        `/api/vehicleConfigurator/${regNr}`
      );
      this.setConfigAction(data);
    }
    return this.state.config;
  },

  setConfigAction(data) {
    this.state.config = data?.configurator;
    this.state.contact = data?.contact;
    this.state.vehicle = data?.vehicle;
    this.state.financingTemplateId = data?.financingTemplateId;
    this.state.financeOptions = data?.financeOptions;

    this.state.tradeIn = data?.tradeIn;
    this.state.serviceAgreement = data?.serviceAgreement;
    this.state.fittings = data?.fittings;
    this.state.tyreData = data?.tyres;
    this.state.calculation = data?.calculation;
    this.state.chargeBox = data?.chargeBox;
    this.state.selectedFinancingOption = data?.financeOptions.some((item) => item.selected === true) ? data?.financeOptions.find((item) => item.selected === true) : data?.financeOptions[0];
    EventBus.$emit("set-vehicle-data", {
      contact: data?.contact,
      vehicle: data?.vehicle,
      selectedFinancingOption: this.state.selectedFinancingOption,
      regNr: this.state.regNr,
      isTransport: false,
      isPurchasable: data?.contact.displayPurchase
    });
    this.state.hasFetchedVehicle = true;
  },

  setDepartments(departments) {
    this.state.departments = departments;
    this.state.selectedDepartment = departments[0].value;
  },

  setSelectedDepartment(department) {
    this.state.selectedDepartment = department;
  },

  setTyresAction(tyres) {
    this.state.tyres = tyres;
  },

  setNeedTyres(need) {
    this.state.needTyres = need;
  },

  setNeedChargeBox(need) {
    this.state.needChargeBox = need;
  },

  setRegNrAction(regNr) {
    this.state.regNr = regNr;
  },

  alterTax(shouldDisplayTax) {
    this.state.displayTax = shouldDisplayTax;
  },

  trackSubmitAction(contactData) {
    
  },

  setSelectedFinancingOption(financeOption) {
    this.state.selectedFinancingOption = financeOption;
    EventBus.$emit("set-vehicle-data", {
      contact: this.state?.contact,
      vehicle: this.state?.vehicle,
      selectedFinancingOption: this.state.selectedFinancingOption,
      regNr: this.state.regNr,
      isTransport: false,
      isPurchasable: this.state?.contact.displayPurchase
    });
  },

  setValuation(valuation) {
    this.state.valuation = valuation;
  },

  setServiceAgreement(serviceAgreement) {
    this.state.serviceAgreementSummation = serviceAgreement;
  },

  clearServiceAgreement() {
    this.state.serviceAgreementSummation = null;
  },

  setFittingOptions(fitting) {
    this.state.fittingOptions = fitting;
  },

  getFinanceSummary() {
    const selectedFinancing = this.state.selectedFinancingOption;

    const serviceAgreementSummation = this.state.serviceAgreementSummation
    const serviceAgreementCost = serviceAgreementSummation
      ? serviceAgreementSummation.monthlyCost + serviceAgreementSummation.additionalProducts.filter(product => product.selected).reduce((acc, curr) => curr.monthlyCost + acc, 0)
      : 0;

    const selectedTyres = this.state.tyres.filter(tyre => tyre.selected).map(tyre => {
      const currentPrice = this.state.displayTax ? tyre.financing.currentPrice : tyre.financing.currentPriceNoVat;
      return {
        ...tyre,
        financing: {
          ...tyre.financing,
          calculatedPrice: selectedFinancing.calculationType === CalculationKeys.Monthly ? monthlyCostTyre(selectedFinancing.financing, tyre.financing, currentPrice) : currentPrice
        }
      }
    }) || [];
    const tyreCost = selectedTyres.reduce((acc, curr) => curr.financing.calculatedPrice + acc, 0);

    const tyresTotalResidualValue = selectedTyres.reduce((acc, curr) => (curr.financing.currentPrice * (curr.financing.residualPercentage / 100)) + acc, 0);
    const additionalMonthlyCost = (selectedFinancing.calculationType === CalculationKeys.Monthly ? tyreCost : 0);
    const additionalResidualValue = tyresTotalResidualValue;
    const additionalTotalCost = (selectedFinancing.calculationType === CalculationKeys.Monthly ? 0 : tyreCost);

    const obj = {
      ...selectedFinancing.financing,
      currentPrice: selectedFinancing.calculationType === CalculationKeys.Monthly ? monthlyCost(selectedFinancing.financing).toFixed() : selectedFinancing.financing.currentPrice,
      downPayment: selectedFinancing.financing.selectedDownPayment / 100 * selectedFinancing.financing.currentPrice,
      effectiveRate: [CalculationKeys.Set, CalculationKeys.SetMonthly].includes(selectedFinancing.calculationType) ? 0 : getEffectiveRate(selectedFinancing.financing, additionalMonthlyCost, additionalResidualValue, additionalTotalCost).toFixed(2),
      residualValue: selectedFinancing.financing.residualValue > 0
        ? selectedFinancing.financing.residualValue
        : selectedFinancing.financing.currentPrice * (selectedFinancing.financing.periods?.find(period => period.selected).residualPrecentage || 0) / 100,
      selectedTyres,
    };

    if (selectedFinancing.calculationType === CalculationKeys.Set) {
      obj.totalSetCost = selectedFinancing.financing.currentPrice + selectedFinancing.financing.registrationFee + tyreCost;
      obj.totalMonthlyCost = serviceAgreementCost;
    } else if (selectedFinancing.calculationType === CalculationKeys.Monthly) {
      obj.totalSetCost = selectedFinancing.financing.registrationFee + selectedFinancing.financing.arrangementFee + (selectedFinancing.financing.selectedDownPayment / 100 * selectedFinancing.financing.currentPrice);
      obj.totalMonthlyCost = (monthlyCost(selectedFinancing.financing) + selectedFinancing.financing.administrationFee + serviceAgreementCost + tyreCost).toFixed();
    } else if (selectedFinancing.calculationType === CalculationKeys.SetMonthly) {
      obj.totalMonthlyCost = selectedFinancing.financing.currentPrice;
      obj.totalSetCost = selectedFinancing.financing.privateLeasingDownPayment;
    }

    return obj;
  },

  alterTyreOptions(tyre, checked) {
    const alteredTyreOptions = this.state.tyres.map(tyreOption => {
      if (tyreOption.id === tyre.id) {
        return {
          ...tyreOption,
          selected: checked,
        };
      } else {
        return tyreOption;
      }
    });
    this.state.tyres = alteredTyreOptions;
  },

  submitAction(contactData) {
    const selectedTyres = this.state.tyres.filter(tyre => tyre.selected);
    const selectedFittings = this.state.fittingOptions.filter(fitting => fitting.selected);
    const body = {
      currentPageUrl: window.location.toString(),
      registrationNumber: this.regNr,
      financingTemplateId: this.state.financingTemplateId,
      isNoVat: !this.state.displayTax,
      selectedFinancingOption: this.state.selectedFinancingOption,
      serviceAgreement: this.state.serviceAgreementSummation,
      selectedTyres: selectedTyres,
      tradeIn: this.state.valuation,
      fittings: selectedFittings,
      chargeBox: this.state.needChargeBox,
      financingSummary: this.getFinanceSummary(),
      selectedDepartment: this.state.selectedDepartment,
      contactData,
      needsTyresSuggestion: selectedTyres && selectedTyres.length == 0 && this.state.needTyres,
    };

    gtm.configuratorSubmit({
      responseType:
        parseInt(contactData.requestType, 10) === ContactRequestTypes.Callup
          ? ContactResponseTypes.Phone
          : ContactResponseTypes.Email,
      requestType: contactData.requestType,
      departmentNr: contactData.departmentNr,
      requestTyre: selectedTyres && selectedTyres.length > 0 || this.state.needTyres ? true: false,
      requestTradeIn: this.state.valuation ? true : false,
      requestServiceAgreement: this.state.serviceAgreementSummation ? true : false,
      requestAddons: this.state.needChargeBox ? true : false,
      financingType: this.state.selectedFinancingOption.name

    });

    if (
      this.state.valuation &&
      this.state.valuation.registrationNumber &&
      this.state.valuation.registrationNumber.length > 0
    ) {
      gtm.configuratorQuickTradeIn();
    }

    return Axios.post(
      `/api/vehicleConfigurator/${this.state.regNr}/contact`,
      body
    );
  }
};
