<template>
  <div>
    <div ref="grid" class="vehicle-list__items">
      <GridItem
        v-for="gridItem in gridItems"
        :key="`${gridItem.type}${gridItem.document._id}`"
        :product="gridItem.product"
        :type="gridItem.type"
        :selected="selectedItems.includes(gridItem.document._id)"
        :loading="loadingItems.includes(gridItem.document._id)"
        :transitionOnOpen="transitionOnOpen"
        @select="onItemClick(gridItem)"
        :savedVehicles="savedVehicles"
        :isPrivateStore="isPrivateStore"
      >
      </GridItem>
    </div>
    <VcIntersectObserver
      v-if="gridItems.length"
      :options="{ rootMargin: '200px' }"
      @intersect="onIntersect"
    ></VcIntersectObserver>
  </div>
</template>

<script>
import Vue from "vue";
import { VcIntersectObserver } from "@holmgrensbil/vue-components";
import GridItemTypes from "./constants/GridItemTypes";
import GridItem from "./GridItem.vue";
import I3DocProduct from "../../models/I3DocProduct";
import Axios from "../../axios";
import { getPageYOffset } from "../../utils/scrolling";
import gtm from "../../utils/gtm";
import SaveVehicleManager from "../../models/SaveVehicleManager";
import Cookies from "js-cookie";

export default {
  props: {
    gridState: Object,
    channel: Object,
    documents: {
      type: Array,
      default: () => []
    },
    queriedAt: {
      type: Date
    },
    isPrivateStore: Boolean
  },
  components: {
    GridItem,
    VcIntersectObserver
  },
  data() {
    return {
      selectedItems: [],
      loadingItems: [],
      keepSelectedItemsOnNextQuery: false,
      productUsps: {},
      transitionOnOpen: true,
      savedVehicles: [],
    };
  },
  created() {
    SaveVehicleManager.onAlterVehicleList(async e => {
      const savedVehicles = await Axios.get("/api/MyPages/vehicles");
      this.savedVehicles = savedVehicles.data;
    });
  },
  async mounted() {
    if (this.gridState) {
      this.$refs.grid.style = `min-height: ${this.gridState.height}px`;

      // TODO: this code needs to run after query succeeded, could be done nicer.
      setTimeout(() => {
        this.transitionOnOpen = false;
        this.selectedItems = this.gridState.selectedItems;

        Vue.nextTick(() => {
          this.transitionOnOpen = true;
        });
      }, 1000);
    }
    const savedVehicles = await Axios.get("/api/MyPages/vehicles");
    this.savedVehicles = savedVehicles.data;

  },
  watch: {
    queriedAt() {
      if (this.keepSelectedItemsOnNextQuery) {
        this.keepSelectedItemsOnNextQuery = false;
        return;
      }

      this.selectedItems = [];
      this.$refs.grid.style = `min-height: auto`;
    }
  },
  methods: {
    onIntersect() {
      this.keepSelectedItemsOnNextQuery = true;
      this.$emit("onNext");
    },
    async onItemClick(item) {
      this.loadingItems.push(item.document._id);
      await this.fetchProductUsps(item.product);
      this.selectItem(item.document);
      this.loadingItems = this.loadingItems.filter(
        id => id !== item.document._id
      );

      gtm.vehicleQuickbuyClick({
        departmentNr: item.product.departmentNr,
        vehicleCondition: item.product.vehicleCondition,
        brandName: item.product.brandName,
        vehicleType: item.product.vehicleType,
        carStatus: item.product.carStatus
      });
    },

    async fetchProductUsps(product) {
      if (!this.productUsps[product.id]) {
        try {
          const res = await Axios.get(
            `/fordon/${product.regNr}/GetSellingPoints`
          );
          this.productUsps[product.id] = res.data;
        } catch (e) {
          this.productUsps[product.id] = [];
        }
      }

      return this.productUsps[product.id];
    },

    selectItem({ _id, _clickurl }) {
      if (this.selectedItems.includes(_id)) {
        this.selectedItems = this.selectedItems.filter(v => v !== _id);
      } else {
        const index = this.documents.findIndex(d => d._id === _id);

        this.selectedItems = [
          ...this.ensureOneSelectedPerRow(this.selectedItems, index),
          _id
        ];

        this.channel.reportClick({ _clickurl });
      }

      this.setGridState();
    },

    setGridState() {
      setTimeout(() => {
        const gridState = {
          height: this.$refs.grid.clientHeight,
          selectedItems: this.selectedItems,
          pageYOffset: getPageYOffset()
        };
        this.$emit("stateChange", gridState);
      }, 220);
    },

    ensureOneSelectedPerRow(selected, index) {
      const gridWidth = this.$refs.grid.clientWidth;
      const itemWidth = this.$refs.grid.children[0].clientWidth;
      const itemWidthPercent = Math.round((itemWidth / gridWidth) * 100);
      const itemsPerRow = Math.round(100 / itemWidthPercent);
      const toLeft = index % itemsPerRow;
      const toRight = itemsPerRow - toLeft - 1;
      const arrRange = Array(toRight + toLeft + 1)
        .fill(index - toLeft)
        .map((x, y) => x + y);
      const arrRangeIds = arrRange
        .filter(index => this.documents[index])
        .map(index => this.documents[index]._id);

      return selected.filter(id => {
        return !arrRangeIds.includes(id);
      });
    },

    insertQuickbuy(items, selectedIndex) {
      const document = this.documents[selectedIndex];
      const quickbuy = {
        document,
        product: new I3DocProduct(document, this.productUsps[document._id]),
        type: GridItemTypes.Quickbuy
      };
      const selectedPosition = selectedIndex + 1;
      const gridWidth = this.$refs.grid.clientWidth;
      const itemWidth = this.$refs.grid.children[0].clientWidth;
      const itemWidthPercent = Math.round((itemWidth / gridWidth) * 100);
      const productSpotItems = items.filter(
        i => i.type === GridItemTypes.ProductSpot
      );

      const insertTarget = productSpotItems.find((item, index) => {
        if (selectedIndex > index) {
          return false;
        }

        const position = index + 1;

        if (
          (position * itemWidthPercent) % 100 === 0 ||
          (itemWidthPercent === 33 && (position * itemWidthPercent) % 99 === 0)
        ) {
          return true;
        }

        if (index + 1 === items.length) {
          return true;
        }
      });

      const insertTargetPosition = insertTarget
        ? items.findIndex(
            item => item.document._id === insertTarget.document._id
          ) + 1
        : items.length;

      return [
        ...items.slice(0, insertTargetPosition),
        quickbuy,
        ...items.slice(insertTargetPosition)
      ];
    }
  },
  computed: {
    gridItems() {
      let items = this.documents.map(document => ({
        document,
        product: new I3DocProduct(document, this.productUsps[document._id]),
        type: GridItemTypes.ProductSpot
      }));

      this.selectedItems.forEach(v => {
        const index = this.documents.findIndex(d => d._id === v);

        if (index !== -1) {
          items = this.insertQuickbuy(items, index);
        }
      });

      return items;
    }
  }
};
</script>
