var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "eon-filter-page" }, [
    _vm.mobile
      ? _c(
          "div",
          { staticClass: "eon-filter-page__header" },
          [
            _vm._l(_vm.filterConfigButtons, function(button) {
              return _c(
                "div",
                { key: button.label, staticClass: "page-header__controls" },
                [
                  _c(
                    "div",
                    { staticClass: "page-header__control" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-stack",
                          on: {
                            click: function($event) {
                              return _vm.onConfigClick(button.config)
                            }
                          }
                        },
                        [
                          _c("label", [
                            _vm._v(
                              "\n            " +
                                _vm._s(button.label) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-control" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(button.text ? button.text : " ") +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("FontAwesomeIcon", {
                            attrs: {
                              icon:
                                _vm.currentFilterConfig === button.config
                                  ? _vm.faAngleUp
                                  : _vm.faAngleDown
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.currentFilterConfig === button.config
                        ? _c("FilterWidget", {
                            attrs: {
                              channel: _vm.channel,
                              mainSpecSelector:
                                button.config ===
                                _vm.FilterConfigurations.SearchFor,
                              filterConfiguration: button.config
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "page-header__controls" }, [
              _c(
                "div",
                {
                  staticClass:
                    "page-header__control page-header__control--filter"
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--white btn--block btn--icon",
                      on: {
                        click: function($event) {
                          return _vm.onConfigClick(
                            _vm.FilterConfigurations.Filter
                          )
                        }
                      }
                    },
                    [
                      _vm._v("\n          Filtrera\n          "),
                      _c("FilterBadge", { attrs: { channel: _vm.channel } }),
                      _vm._v(" "),
                      _c("i", { staticClass: "fal fa-sliders-h" })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "page-header__control page-header__control--sort"
                },
                [
                  _c("SortWidget", {
                    attrs: { channel: _vm.channel },
                    on: { clicked: _vm.sortClicked }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.currentFilterConfig === _vm.FilterConfigurations.Filter
              ? _c("FilterWidget", {
                  attrs: {
                    channel: _vm.channel,
                    filterConfiguration: _vm.FilterConfigurations.Filter
                  }
                })
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "section section--gray section--padding-sm" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          [
            _c(
              "div",
              [
                !_vm.mobile &&
                _vm.currentFilterConfig === _vm.FilterConfigurations.All
                  ? _c("FilterWidget", {
                      attrs: {
                        channel: _vm.channel,
                        filterConfiguration:
                          _vm.FilterConfigurations.MainOptions,
                        isColumn: "",
                        mainSpecSelector: true
                      },
                      on: { onFieldChange: _vm.onFieldChange }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isTyreBuild
                  ? _c("div", { staticClass: "build-tyre" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm.isTyreBuild
                        ? _c(
                            "div",
                            { staticClass: "build-tyre__container" },
                            [
                              _c("TyreBuildSelectSection", {
                                attrs: {
                                  selected:
                                    _vm.tyreBuildStep ===
                                    _vm.TyreBuildSteps.Rim,
                                  product: _vm.selectedRim,
                                  step: _vm.TyreBuildSteps.Rim,
                                  title: "1. Välj fälg",
                                  label: "Fälg",
                                  image: "../../../Static/images/hb_rim@2x.png",
                                  quantity: _vm.productQuantity,
                                  mobile: _vm.mobile
                                },
                                on: {
                                  onSelect: _vm.selectTyreStep,
                                  alterBuildWheelQuantity:
                                    _vm.alterBuildWheelQuantity
                                }
                              }),
                              _vm._v(" "),
                              _c("TyreBuildSelectSection", {
                                attrs: {
                                  selected:
                                    _vm.tyreBuildStep ===
                                    _vm.TyreBuildSteps.Tyre,
                                  product: _vm.selectedTyre,
                                  step: _vm.TyreBuildSteps.Tyre,
                                  title: "2. Välj däck",
                                  label: "Däck",
                                  image:
                                    "../../../Static/images/hb_tire@2x.png",
                                  quantity: _vm.productQuantity,
                                  mobile: _vm.mobile
                                },
                                on: {
                                  onSelect: _vm.selectTyreStep,
                                  alterBuildWheelQuantity:
                                    _vm.alterBuildWheelQuantity
                                }
                              }),
                              _vm._v(" "),
                              _c("TyreBuildFinishSection", {
                                attrs: {
                                  title: "3. Välj tillval",
                                  isAddon: "",
                                  addons: _vm.addons,
                                  totalPrice: _vm.totalPrice,
                                  loading: _vm.addToCartLoading
                                },
                                on: {
                                  onAddonsSelected: _vm.onAddonsSelected,
                                  onAddToCart: _vm.addToCart
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.mobile &&
                ((_vm.isTyreBuild && _vm.tyreBuildStep) || !_vm.isTyreBuild)
                  ? _c(
                      "div",
                      { staticClass: "row eon-filter-page__desktop-results" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-4" },
                          [
                            _vm.currentFilterConfig ===
                            _vm.FilterConfigurations.All
                              ? _c("FilterWidget", {
                                  attrs: {
                                    channel: _vm.channel,
                                    filterConfiguration:
                                      _vm.FilterConfigurations.SubOptions
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-8" },
                          [
                            _c("EonResult", {
                              attrs: {
                                channel: _vm.channel,
                                TyreSpec: _vm.TyreSpec,
                                selectedProductType: _vm.selectedProductType,
                                isBuildWheel: _vm.isTyreBuild,
                                selectedProduct: _vm.selectedProduct,
                                isLoading: _vm.isLoadingResults
                              },
                              on: { onQuerySuccess: _vm.onQuerySuccess }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.mobile && !_vm.isTyreBuild
                  ? _c(
                      "div",
                      [
                        _c("EonResult", {
                          attrs: {
                            channel: _vm.channel,
                            TyreSpec: _vm.TyreSpec,
                            selectedProductType: _vm.selectedProductType
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.mobileModalOpen
              ? _c(
                  "VcModal",
                  {
                    on: {
                      close: function($event) {
                        _vm.mobileModalOpen = false
                      }
                    }
                  },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.mobileModalHeader) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { slot: "content" }, slot: "content" },
                      [
                        _c("EonResult", {
                          attrs: {
                            channel: _vm.channel,
                            TyreSpec: _vm.TyreSpec,
                            selectedProductType: _vm.selectedProductType,
                            isBuildWheel: true,
                            selectedProduct: _vm.selectedProduct,
                            isLoading: _vm.isLoadingResults
                          },
                          on: { onQuerySuccess: _vm.onQuerySuccess }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.onAddedPurchaseModal && _vm.selectedTyre && _vm.selectedRim
              ? _c("TyreBuildAddedToCart", {
                  attrs: {
                    rim: _vm.selectedRim,
                    tyre: _vm.selectedTyre,
                    addons: _vm.filteredAddons
                  },
                  on: {
                    close: function($event) {
                      _vm.onAddedPurchaseModal = false
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "build-tyre__heading" }, [
      _c("div", { staticClass: "build-tyre__title" }, [
        _vm._v("Bygg ditt hjul")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "build-tyre__text" }, [
        _vm._v(
          "\n                Vi erbjuder dig att sätta ihop ditt egna hjulpaket. Vi har tagit fram ett förslag som skulle passa bra på din bil. Vi bjuder på monteringen!\n              "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }