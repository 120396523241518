import FieldTypes from "../constants/FieldTypes";
import SectionTypes from "../constants/SectionTypes";
import InputTypes from "../../shared/wizard/constants/InputTypes";
import RegExPatterns from "../../../constants/RegExPatterns";

export default [
  {
    type: FieldTypes.RegNr
  },
  {
    type: FieldTypes.DepartmentNr
  },
  {
    type: FieldTypes.PageName
  },
  {
    type: FieldTypes.IsExpress,
    value: false
  },
  {
    type: FieldTypes.CustomerIsWaiting,
    value: false
  },
  {
    section: SectionTypes.TypeOfReport,
    label: "Välj besiktningstyp",
    type: FieldTypes.TypeOfReport,
    inputType: InputTypes.OptionBoxes,
    options: [],
    required: true
  },
  {
    section: SectionTypes.Vehicle,
    type: FieldTypes.VehicleInfoRegNr,
    label: "Registreringsnummer",
    hidden: true
  },
  {
    section: SectionTypes.Vehicle,
    type: FieldTypes.VehicleName,
    label: "Fordonsnamn",
    hidden: true
  },
  // {
  //   section: SectionTypes.Addons,
  //   label: "Välj tillval",
  //   type: FieldTypes.Addons,
  //   inputType: InputTypes.OptionBoxesMulti,
  //   options: [],
  //   required: false,
  //   nestedOption: true
  // },
  {
    section: SectionTypes.Department,
    type: FieldTypes.DepartmentName,
    label: "Anläggning",
    hidden: true
  },
  {
    section: SectionTypes.Images,
    label: "Skadans placering",
    placeholder: "Vart på fordonet uppstod skadan?",
    type: FieldTypes.DamagePlacement,
    inputType: InputTypes.Text,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Beskriv skadan och skadeförlopp",
    placeholder: "Beskriv här...",
    type: FieldTypes.DamageText,
    inputType: InputTypes.Textarea,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Skadenummer expressbokning eller försäkringsbolag",
    placeholder: "Skadenummer vid expressbokning eller försäkringsbolag",
    type: FieldTypes.DamageNumber,
    inputType: InputTypes.Text,
    required: true,
    tooltip:
      "Ett skadenummer får du när du anmält din skada till ditt försäkringsbolag. Dom flesta försäkringsbolag kan ta emot en anmälan direkt på respektive hemsida. Vid expressbokning ange alltid skadenummer"
  },
  {
    section: SectionTypes.Images,
    label: "Mätarställning (km)",
    placeholder: "Ange nuvarande mätarställning",
    type: FieldTypes.Mileage,
    inputType: InputTypes.Number
  },
  {
    section: SectionTypes.Images,
    label: "Översikt av skadan",
    type: FieldTypes.ImageOverview,
    inputType: InputTypes.Image,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Inzoomad bild av skadan",
    type: FieldTypes.ImageZoom,
    inputType: InputTypes.Image,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Vänster fram",
    type: FieldTypes.ImageFrontLeft,
    inputType: InputTypes.Image,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Höger fram",
    type: FieldTypes.ImageFrontRight,
    inputType: InputTypes.Image,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Vänster bak",
    type: FieldTypes.ImageBackLeft,
    inputType: InputTypes.Image,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Höger bak",
    type: FieldTypes.ImageBackRight,
    inputType: InputTypes.Image,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Bild på mätarställningen",
    type: FieldTypes.ImageMeter,
    inputType: InputTypes.Image,
    required: true
  },
  {
    section: SectionTypes.Images,
    label: "Extra",
    type: FieldTypes.ImageExtra1,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Extra",
    type: FieldTypes.ImageExtra2,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Images,
    label: "Extra",
    type: FieldTypes.ImageExtra3,
    inputType: InputTypes.Image
  },
  {
    section: SectionTypes.Contact,
    label: "Namn",
    placeholder: "Vad heter du?",
    type: FieldTypes.Name,
    inputType: InputTypes.Text,
    autocomplete: "name",
    required: true
  },
  {
    section: SectionTypes.Contact,
    label: "E-post",
    placeholder: "Ange din e-postadress",
    type: FieldTypes.Email,
    inputType: InputTypes.Email,
    inputPattern: RegExPatterns.Email,
    autocomplete: "email",
    required: true
  },
  {
    section: SectionTypes.Contact,
    label: "Mobilnummer",
    placeholder: "070-123 45 67",
    type: FieldTypes.Phone,
    inputType: InputTypes.Phone,
    inputPattern: RegExPatterns.Phone,
    autocomplete: "tel",
    required: true
  },
  {
    section: SectionTypes.Date,
    label: "Datum",
    shortLabel: "Datum",
    placeholder: "Välj datum",
    type: FieldTypes.Date,
    inputType: InputTypes.Date,
    required: true,
    options: []
  },
  {
    section: SectionTypes.Date,
    label: "Tid",
    shortLabel: "Tid",
    placeholder: "Välj tid",
    type: FieldTypes.Time,
    inputType: InputTypes.Time,
    required: true,
    options: []
  }
];
