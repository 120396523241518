import RequestStates from '../../../../constants/RequestStates';
import ServicesApi from '../../../../models/ServicesApi';

const state = {
  validCode: false,
  code: null,
  fetchState: null,
};

const getters = {};

const actions = {
  async validateCode({ commit }, { service, departmentNr, regNr, code, }) {
    commit('fetchRequest');

    try {
      const data = {
        departmentNr,
        service,
        regNr,
        code,
      };

      const res = await ServicesApi.validateCode(data);
      commit('fetchSuccess', res.data);
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, code) {
    state.fetchState = RequestStates.Success;
    state.validCode = true;
    state.code = code;
  },

  fetchFailure(state) {
    state.validCode = false;
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
