<template>
  <div class="tyre-order" v-if="order">
    <div class="cart-item" v-for="item in order.items" :key="item.id">
      <div class="tyre-order__order-item">
        <div class="tyre-order__order-item__main-data">
          <div class="cart-item__product">
            <div v-if="item.imageUrl" class="cart-item__product-thumbnail">
              <img :src="item.imageUrl" alt="Produktbild" />
            </div>
            <div class="cart-item__product-main">
              <div class="cart-item__product-meta">
                <label>
                  {{item.type}}
                </label>
                <span class="mark mark--sm mark--black mark--uppercase"
                  {{item.condition}}
                </span>
              </div>
              <div class="cart-item__product-name">
                <a href=#>{{item.title}}</a>
                <a href=#>{{item.description}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="tyre-order__stats-container">
          <div class="tyre-order__stats-container__cell tyre-order__stats-container__cell__divider">
          </div>
          <div class="tyre-order__stats-container__cell tyre-order__stats-container__cell--right-aligned">
            <span class="tyre-order__stats-container__quantity-text">{{ item.quantity }} st</span>
          </div>
          <div class="cart-item__line-price tyre-order__stats-container__cell">
            <VcPriceTag :price="item.totalPrice" showIfZero hideOrdinaryPriceIfDiscount></VcPriceTag>
            <VcPriceTag :price="item.price" hideOrdinaryPriceIfDiscount></VcPriceTag>
          </div>
        </div>
      </div>
      <div class="tyre-order__order-item" v-for="line in item.additionalItems" :key="line.id">
        <div class="tyre-order__stats-container__cell">
          <label class="cart-item__line-meta">
            {{line.type}}
          </label>
          <div class="cart-item__line-name">
            {{line.title}}
          </div>
        </div>
        <div class="tyre-order__stats-container__cell">
          <span class="tyre-order__stats-container__quantity-text">{{ line.quantity }} st</span>
        </div>
        <div class="tyre-order__stats-container__cell cart-item__line-price">
          <VcPriceTag :price="line.totalPrice" showIfZero hideOrdinaryPriceIfDiscount></VcPriceTag>
          <VcPriceTag :price="line.price" hideOrdinaryPriceIfDiscount></VcPriceTag>
        </div>
      </div>
    </div>
    <div class="tyre-order__spec">
      <div class="tyre-order__spec__row">
        <span class="tyre-order__spec__row__title">DATUM</span>
        <span class="tyre-order__spec__row__data">{{ order.dateOfPurchase }}</span>
      </div>
      <div class="tyre-order__spec__row">
        <span class="tyre-order__spec__row__title">BETALSÄTT</span>
        <span class="tyre-order__spec__row__data">{{ order.typeOfPurchase }}</span>
      </div>
      <div class="tyre-order__spec__row" v-if="order.recycleFee > 0">
        <span class="tyre-order__spec__row__title">DÄCKÅTERVINNINGSAVGIFT</span>
        <span class="tyre-order__spec__row__data">{{ $formatPrice(order.recycleFee) }}</span>
      </div>
      <div class="tyre-order__spec__row" v-if="order.totalDiscount > 0">
        <span class="tyre-order__spec__row__title">RABATTER PÅ DETTA KÖP</span>
        <span class="tyre-order__spec__row__data tyre-order__spec__row__data--discount">-{{ $formatPrice(order.totalDiscount) }}</span>
      </div>
      <div class="tyre-order__spec__row">
        <div class="tyre-order__spec__row__sub-container">
          <span class="tyre-order__spec__row__title">TOTALKOSTNAD</span>
          <span class="tyre-order__spec__row__sub-info">(inkl. moms)</span>
        </div>
        <div class="tyre-order__spec__row__sub-container">
          <VcPriceTag :price="order.totalPrice" showIfZero hideOrdinaryPriceIfDiscount></VcPriceTag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcPriceTag } from '@holmgrensbil/vue-components';
import { formatYYYYMMDD } from '../../utils/dates';
import LineItemProductTypes from '../../constants/LineItemProductTypes';

import LineItemProductConditions from '../../constants/LineItemProductConditions';
export default {
  components: {
    VcPriceTag
  },
  props: {
    rawOrder: Object,
    paymentMethod: Object,
  },
  computed: {
    order() {
      return {
        items: this.mapOrderLines(),
        dateOfPurchase: formatYYYYMMDD(this.rawOrder.createdAt),
        typeOfPurchase: this.paymentMethod.name,
        recycleFee: this.rawOrder.additionalFees
          .filter(f => f.productTypeId == LineItemProductTypes.RecyclingFee)
          .reduce((c, f) => c + f.currentPrice, 0),
        totalDiscount: this.rawOrder.totalPromotion,
        totalPrice: {
          current: this.rawOrder.totalPrice,
          ordinary: this.rawOrder.totalPrice,
          suffix: " kr",
        },
      };
    },
  },
  methods: {
    mapOrderLines() {
      const topOrderLines = this.rawOrder.lineItems.filter(l => !l.parentLineItemId);
      const subOrderLines = this.rawOrder.lineItems.filter(l => l.parentLineItemId !== null);
      return topOrderLines.map(l =>  {
        const orderLine = this.mapOrderLine(l);
        orderLine.additionalItems = subOrderLines
          .filter(l => l.parentLineItemId === orderLine.id)
          .map(sl => this.mapOrderLine(sl));
        return orderLine;
      });
    },
    mapOrderLine(orderLine) {
      const {
        id,
        description,
        name,
        imageUrl,
        quantity,
        productType,
        productCondition,
        currentPrice,
        ordinaryPrice,
      } = orderLine;
      return {
        id,
        title: name,
        description,
        imageUrl,
        quantity,
        type: productType,
        condition: productCondition === LineItemProductConditions.New ? 'Ny' : 'Begagnad',
        totalPrice: {
          current: currentPrice * quantity,
          ordinary: ordinaryPrice * quantity,
          suffix: ' kr',
        },
        price: {
          current: currentPrice,
          ordinary: ordinaryPrice,
          suffix: ' kr/st',
        }
      }
    }
  },
}
</script>
