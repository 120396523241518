var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "row opening-hours-print-app u-margin-bottom-lg u-margin-top-lg"
    },
    [
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", [_vm._v(" VÄLJ ÖPPETTIDER")]),
              _vm._v(" "),
              _c("VcOptionBox", {
                attrs: {
                  selected: _vm.type === "regular",
                  heading: "Ordinarie öppettider"
                },
                on: {
                  click: function($event) {
                    _vm.type = "regular"
                  }
                }
              }),
              _vm._v(" "),
              _c("VcOptionBox", {
                staticClass: "u-margin-top",
                attrs: {
                  selected: _vm.type === "custom",
                  heading: "Period, from 7 dagar fram"
                },
                on: {
                  click: function($event) {
                    _vm.type = "custom"
                  }
                }
              }),
              _vm._v(" "),
              _vm.type === "custom"
                ? _c(
                    "VcFormStack",
                    { attrs: { label: "Datum", required: "" } },
                    [
                      _c(
                        "VcDatePicker",
                        {
                          attrs: {
                            value: _vm.date,
                            minDate: _vm.minDate,
                            placeholder: "Välj en dag"
                          },
                          on: {
                            change: function($event) {
                              return _vm.onDateChange($event.target.value)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fal fa-calendar-alt",
                            attrs: { slot: "icon" },
                            slot: "icon"
                          })
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type === "custom"
                ? _c("VcOptionBox", {
                    staticClass: "u-margin-top-lg",
                    attrs: {
                      checkbox: "",
                      heading: "Dölj datum",
                      selected: _vm.noDate
                    },
                    on: { click: _vm.onHideDate }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("label", { staticClass: "u-margin-top-lg" }, [
                _vm._v(" VÄLJ TEMA")
              ]),
              _vm._v(" "),
              _c(
                "VcFormStack",
                { attrs: { label: "Tema" } },
                [
                  _c("VcFormSelect", {
                    attrs: {
                      prompt: "Välj tema",
                      options: _vm.themes,
                      value: _vm.selectedTheme,
                      valueProp: "name",
                      labelProp: "name",
                      required: ""
                    },
                    on: { change: _vm.onThemeChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", [_vm._v(" Skriv meddelande")]),
              _vm._v(" "),
              _c("VcFormStack", { attrs: { label: "Brödtext" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Skriv meddelande här..." },
                  domProps: { value: _vm.message },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.message = $event.target.value
                    }
                  }
                })
              ])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c("label", [_vm._v(" Förhandsgranska och skriv ut")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "opening-hours-print-app__preview__container" },
          [
            _vm.selectedTheme
              ? _c(
                  "div",
                  {
                    staticClass:
                      "opening-hours-print-app__preview__image-container"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "opening-hours-print-app__preview__header",
                        class: {
                          "opening-hours-print-app__preview__header--white":
                            _vm.selectedTheme &&
                            _vm.selectedTheme.colorClass === "white",
                          "opening-hours-print-app__preview__header--yellow":
                            _vm.selectedTheme &&
                            _vm.selectedTheme.colorClass === "yellow",
                          "opening-hours-print-app__preview__header--red":
                            _vm.selectedTheme &&
                            _vm.selectedTheme.colorClass === "red",
                          "opening-hours-print-app__preview__header--green":
                            _vm.selectedTheme &&
                            _vm.selectedTheme.colorClass === "green",
                          "opening-hours-print-app__preview__header--blue":
                            _vm.selectedTheme &&
                            _vm.selectedTheme.colorClass === "blue",
                          "opening-hours-print-app__preview__header--white-text":
                            _vm.selectedTheme && _vm.selectedTheme.whiteText
                        },
                        style:
                          "background-image: url(" +
                          (_vm.selectedTheme && _vm.selectedTheme.image) +
                          ")"
                      },
                      [
                        _c("i", {
                          staticClass:
                            "fas fa-clock opening-hours-print-app__preview__icon"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "opening-hours-print-app__preview__title"
                          },
                          [_vm._v("\n            Öppettider\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "opening-hours-print-app__preview__sub-title"
                          },
                          [
                            _vm._v(
                              "\n            Personbilar - Österängsvägen 8\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      attrs: { src: "/Static/images/hb_printscreen@2x.png" }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn--primary",
                class: {
                  disabled: !_vm.selectedTheme
                },
                attrs: { disabled: !_vm.selectedTheme },
                on: { click: _vm.goToPrint }
              },
              [
                _c("i", {
                  staticClass:
                    "fal fa-print opening-hours-print-app__preview__btn-icon"
                }),
                _vm._v("\n        Generera och skriv ut\n      ")
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }