<template>
  <div class="my-pages__section">
    <div class="my-pages__section__title-container">
      <span class="my-pages__section__title">{{ `${ sectionData.length } FORDON` }}</span>
    </div>
    <div class="saved-vehicles" v-for="product in sectionData" :key="product.id">
      <div v-if="product.available" class="swiper-container" ref="swiperContainer">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="image in product.images" :key="image">
            <div class="product-images__item product-images__item--vertical">
              <img :src="image" alt="" />
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="product-images__nav product-images__nav--prev">
          <i class="fal fa-angle-left"></i>
        </div>
        <div class="product-images__nav product-images__nav--next">
          <i class="fal fa-angle-right"></i>
        </div>
      </div>
      <div v-else>
        <div class="product-images__item product-images__item--vertical product-images__item--overlay">
          <img :src="product.images[0]" alt="" />
          <span>
            {{ product.availableLabel }}
          </span>
        </div>
      </div>
      <div class="product-info" @click="$emit('clickVehicle', product.permalink)">
        <span class="product-info__title">{{ product.title }}</span>
        <div class="product-info__description-container">
          <span class="product-info__description-container__text">{{ `${ product.shortVehicleCondition } ${ product.modelYear } • ` }}</span>
          <span class="product-info__description-container__text">{{ `${ product.mileage } mil • ` }}</span>
          <span class="product-info__description-container__text">{{ `${ product.fuelName } • ` }}</span>
          <span class="product-info__description-container__text">{{ product.gearBoxName }}</span>
        </div>
        <div>
          <span
            class="product-info__price"
            :class="{ 'product-info__price--discount': product.currentPrice < product.ordinaryPrice }">
            {{ $formatPrice(product.currentPrice) }}
          </span>
          <span
            v-if="product.currentPrice < product.ordinaryPrice"
            class="product-info__price product-info__price--obsolete">
            {{ $formatPrice(product.ordinaryPrice) }}
          </span>
        </div>
      </div>
      <div class="product-info__card-actions">
        <div @click="$emit('deleteVehicle', product.regNr)" class="product-info__card-actions__btn">
          <i class="fas fa-trash blue"></i>
        </div>
        <div @click="compareVehicle(product.regNr)" class="product-info__card-actions__btn">
          <i class="fal fa-balance-scale blue"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from '../../../swiper';
import { VcPriceTag } from '@holmgrensbil/vue-components';
import { EventBus } from '../../../utils/event-bus';

export default {
  components: {
    VcPriceTag,
  },
  props: {
    sectionData: Array,
  },
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiperContainer, {
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      navigation: {
        nextEl: '.product-images__nav--next',
        prevEl: '.product-images__nav--prev',
        disabledClass: 'product-images__nav--disabled',
      },
    });
  },
  methods: {
    onPressVehicle(permaLink) {
      this.$emit('pressVehicle', permaLink)
    },
    compareVehicle(regNr) {
      EventBus.$emit('compareVehicle', regNr)
    }
  },
}
</script>
