import "core-js/modules/es.object.assign";
import Vue from "vue";
import Vuex from "vuex";
import VueWindowSize from "vue-window-size";
import PortalVue from "portal-vue";
import bugsnagVue from "@bugsnag/plugin-vue";
import { formatYYYYMMDD } from "./utils/dates";
import { formatNumber } from "./utils/numbers";
import bugsnagClient from "./bugsnag";
import { monthlyCost } from "./utils/priceCalculations";

bugsnagClient.use(bugsnagVue, Vue);
Vue.use(Vuex);
Vue.use(VueWindowSize);
Vue.use(PortalVue);

Vue.mixin({
  methods: {
    $validateEmail(email) {
      if (!email || email === ''){
        return false;
      }
      const res = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      return res.test(String(email).toLowerCase());
    },
    $formatPrice(cost) {
      let price = cost;

      if (!cost && cost !== 0) {
        price = 0;
      }

      if (typeof price === "string") {
        price = cost.replace(/\.00/g, "");
      }

      return `${this.$formatNumber(price)} kr`;
    },
    $formatPriceMonthly(cost) {
      let price = cost;

      if (!cost && cost !== 0) {
        price = 0;
      }

      if (typeof price === "string") {
        price = cost.replace(/\.00/g, "");
      }

      return `${this.$formatNumber(price)} kr/mån`;
    },
    $formatPriceNoSuffix(cost) {
      let price = cost;

      if (!cost && cost !== 0) {
        price = 0;
      }

      if (typeof price === "string") {
        price = cost.replace(/\.00/g, "");
      }
      return `${this.$formatNumber(price)}`;
    },

    $formatNumber(number = "") {
      return formatNumber(number);
    },

    $formatYYYYMMDD(date) {
      return formatYYYYMMDD(date);
    },
  }
});
