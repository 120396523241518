import Axios from "axios";
import EonQueryParams from "../constants/EonQueryParams";

const config = window.EonConfig || {};
const baseUrl = config.proxyUrl || "https://p645.eontyre.com/api/";
const defaultParams = config.proxyUrl
  ? {}
  : {
      [EonQueryParams.ApiKey]: "db2cd038-d256-4a8f-822b-b34e03b7",
      [EonQueryParams.Database]: "2"
    };

const _get = (url, params = {}) =>
  Axios.get(`${baseUrl}${url}`, {
    params: {
      ...defaultParams,
      ...params
    }
  });

export default {
  async getCar(regNr) {
    const res = await _get(`webshop/cars/${regNr}`);
    return res.data;
  },

  async getCarByVariant(variant) {
    const params = { isVehicleId: true, type: "variant" };
    const res = await _get(`webshop/cars/${variant}`, params);
    return res.data;
  },

  async getCarByModel(model) {
    const params = { isVehicleId: true, type: "model" };
    const res = await _get(`webshop/cars/${model}`, params);
    return res.data;
  },

  async getManufacturers() {
    const res = await _get(`vehicle/manufacturer`);
    return res.data;
  },

  async getModels(manufacturer) {
    const res = await _get(`vehicle/model`, { manufacturer });
    return res.data;
  },

  async getVariants(model) {
    const res = await _get(`vehicle/variant`, { model });
    return res.data;
  },

  async getProducts(filters) {
    const params = {
      [EonQueryParams.MinQuantityInStock]: 0,
      ...filters
    };
    const res = await _get(`webshop/products`, params);

    // Eon sometimes incorrectly returns empty array instead of object
    if (Array.isArray(res.data.data) && res.data.data.length === 0) {
      res.data.data = { products: [] };
    }

    return res.data;
  },
  async getProductImages(id) {
    const res = await _get(
      `webshop/products?query=%23${id}&webshopId=64&skipGroupIdentical=1&apiKey=64a1904851e947cfbd635fd145986eef`
    );

    // Eon sometimes incorrectly returns empty array instead of object
    if (Array.isArray(res.data.data) && res.data.data.length === 0) {
      res.data.data = { products: [] };
    }

    return res.data;
  }
};
