var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-row date-wizard" }, [
    _c(
      "div",
      { staticClass: "form-row__col" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.datesLoading
          ? _c(
              "div",
              { staticClass: "u-align-center" },
              [
                _c("FontAwesomeIcon", {
                  staticClass: "wizard--service-booking__loader",
                  attrs: { icon: _vm.faSpinnerThird, spin: true }
                })
              ],
              1
            )
          : _vm.dates.length === 0
          ? _c("VcFeedbackMessage", { attrs: { info: "", center: "" } }, [
              _c("strong", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.App.NoTimesMessage) +
                    "\n      "
                )
              ])
            ])
          : _c("SectionField", {
              attrs: { type: _vm.FieldTypes.Date },
              on: { change: _vm.onDateChange }
            })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-row__col form-row__col--md-50" },
      [
        _vm.timesLoading
          ? _c(
              "div",
              { staticClass: "u-align-center" },
              [
                _c("FontAwesomeIcon", {
                  staticClass: "wizard--service-booking__loader",
                  attrs: { icon: _vm.faSpinnerThird, spin: true }
                })
              ],
              1
            )
          : _c("SectionField", {
              attrs: { type: _vm.FieldTypes.Time },
              on: { change: _vm.onTimeChange }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "date-wizard__label-container" }, [
      _c("span", { staticClass: "date-wizard__label-container__label" }, [
        _vm._v("\n        Välj datum\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }