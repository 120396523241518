import Swiper from '../swiper';

export default {
  init() {
    if (!$('.image-slider').length) {
      return;
    }

    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    this.initSwiper();
  },

  initSwiper() {
    this.swiper = new Swiper('.image-slider', {
      autoHeight: true,
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
      },
      navigation: {
        nextEl: '.image-slider__nav--next',
        prevEl: '.image-slider__nav--prev',
      },
      loop: true
    });
  },
}
