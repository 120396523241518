import Breakpoints from "../constants/Breakpoints";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    if ($(window).width() < Breakpoints.md) {
      this.applySlider();
    }
  },

  applySlider() {
    $('.site-usps').addClass('site-usps--slider');

    const $items = $('.site-usps__item');
    const count = $items.length;

    $items.first().addClass('site-usps__item--active');

    setInterval(() => {
      const $active = $('.site-usps__item--active');
      let $next;

      if ($active.index() + 1 === count) {
        $next = $items.first();
      } else {
        $next = $active.next();
      }

      $active.removeClass('site-usps__item--active').addClass('site-usps__item--out');
      $next.removeClass('site-usps__item--out').addClass('site-usps__item--active');

      setTimeout(() => {
        $active.removeClass('site-usps__item--out');
      }, 220); 
    }, 4000);
  },
}
