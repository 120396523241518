var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-section trade-in-selector" }, [
    _vm.fetchedValuation
      ? _c("div", [
          _c("div", { staticClass: "configurator-section__completed" }, [
            _c("div", { staticClass: "details-bar" }, [
              _c("div", { staticClass: "details-bar__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.fetchedValuation.brandName +
                        " " +
                        _vm.fetchedValuation.modelName +
                        " " +
                        _vm.fetchedValuation.version
                    ) +
                    "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details-bar__item-list" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.fetchedValuation.registrationNumber +
                        " • " +
                        _vm.fetchedValuation.modelYear +
                        " • " +
                        _vm.fetchedValuation.mileage
                    ) +
                    "\n        "
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--muted btn--block",
              on: { click: _vm.removeValuation }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "vehicle-configurator__btn-icon",
                attrs: { icon: _vm.faTrashCan }
              }),
              _vm._v(
                "\n      " + _vm._s(_vm.lang.Summation.RemoveTradeIn) + "\n    "
              )
            ],
            1
          )
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _c("ConfiguratorTextInput", {
                attrs: {
                  inputKey: "regnr-input",
                  placeholder: "Regnummer",
                  useRegNrIcon: ""
                },
                on: {
                  onChange: function(value) {
                    return (_vm.regNr = value)
                  }
                }
              }),
              _vm._v(" "),
              _c("ConfiguratorTextInput", {
                attrs: { inputKey: "mileage-input", placeholder: "Miltal" },
                on: {
                  onChange: function(value) {
                    return (_vm.mileage = value)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "button",
              {
                staticClass: "btn btn--muted btn--block",
                attrs: { disabled: !_vm.regNr || !_vm.mileage || _vm.loading },
                on: { click: _vm.onAppraiseVehicle }
              },
              [
                _vm.loading
                  ? _c("font-awesome-icon", {
                      staticClass: "vehicle-configurator__btn-icon",
                      attrs: { icon: _vm.faSpinnerThird, spin: true }
                    })
                  : _vm._e(),
                _vm._v("\n        Lägg till inbytesbil\n      ")
              ],
              1
            ),
            _vm._v(" "),
            _vm.appraiseError
              ? _c(
                  "div",
                  { staticClass: "vehicle-configurator__button-error" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.appraiseError) + "\n      "
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }