var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "eon-filter__section",
      class: {
        "eon-filter__section--child": _vm.section.parent,
        "eon-filter__section--collapsible": _vm.section.collapsible,
        "eon-filter__section--collapsed":
          _vm.section.collapsible && _vm.collapsed,
        "eon-filter__section--injected-filter": _vm.isInjected
      }
    },
    [
      _vm.section.collapsible
        ? _c(
            "div",
            {
              staticClass: "eon-filter__section--collapsible__header",
              on: {
                click: function($event) {
                  _vm.collapsed = !_vm.collapsed
                }
              }
            },
            [
              _vm._v("\n    " + _vm._s(_vm.section.header) + "\n\n    "),
              _vm.getSectionValueCount(_vm.section)
                ? _c("div", { staticClass: "badge" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.getSectionValueCount(_vm.section)) +
                        "\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.collapsed,
                      expression: "collapsed"
                    }
                  ]
                },
                [_c("i", { staticClass: "fal fa-angle-down" })]
              ),
              _vm._v(" "),
              _c(
                "i",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.collapsed,
                      expression: "!collapsed"
                    }
                  ]
                },
                [_c("i", { staticClass: "fal fa-angle-up" })]
              )
            ]
          )
        : _vm.section.header && (!_vm.hideHeader || _vm.displayClearButton)
        ? _c("div", { staticClass: "eon-filter__section-header" }, [
            _c("div", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hideHeader && !_vm.isInjected,
                      expression: "!hideHeader && !isInjected"
                    }
                  ]
                },
                [_vm._v("\n        " + _vm._s(_vm.section.header) + "\n      ")]
              )
            ]),
            _vm._v(" "),
            _vm.displayClearButton
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn--white btn--xs",
                    on: { click: _vm.onClear }
                  },
                  [_vm._v("\n      Rensa filter\n    ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.section.collapsible || !_vm.collapsed
        ? _c(
            "div",
            [
              _vm._l(_vm.childSections, function(section) {
                return _c("filter-section", {
                  key: section.type,
                  attrs: { section: section },
                  on: {
                    fieldChange: function($event) {
                      return _vm.$emit("fieldChange", $event)
                    }
                  }
                })
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "eon-filter__fields" },
                _vm._l(_vm.fields, function(field) {
                  return _c("SectionField", {
                    key: field.type,
                    attrs: { type: field.type },
                    on: {
                      change: function($event) {
                        return _vm.$emit("fieldChange", $event)
                      }
                    }
                  })
                }),
                1
              )
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }