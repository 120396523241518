import Axios from "../axios";
import qs from "query-string";
import events from "../mixins/events";
import ChannelEvents from "../constants/ChannelEvents";
import ChannelQuery from "./ChannelQuery";
import I3QueryFilterTypes from "../constants/I3QueryFilterTypes";
import I3QueryParams from "../constants/I3QueryParams";
import { v4 as uuidv4 } from 'uuid';
import Cookies from "js-cookie";

class I3Channel {
  constructor(
    {
      ref,
      url,
      queryString,
      hits,
      persist,
      lazy,
      morelikethis,
      seenbyothers,
      popularSearches,
      hideTaxCodeToggle,
      sendStatistics,
      isUrlBased,
      slimlist
    },
    preference
  ) {
    this.eventPrefix = `I3Channel.${ref}.`;
    this.ref = ref;
    this.url = url;
    this.data = { documents: {}, components: {}, facets: [] };
    this.hits = hits;
    this.persist = persist;
    this.executedQueries = [];
    this.morelikethis = morelikethis;
    this.seenbyothers = seenbyothers;
    this.preference = preference;
    this.popularSearches = popularSearches;
    this.initialRangeFacetValues = {};
    this.additionalParams = [];
    this.hideTaxCodeToggle = hideTaxCodeToggle;
    this.sendStatistics = sendStatistics;
    this.isUrlBased = isUrlBased;
    this.queryString = queryString;
    this.slimList = slimlist;

    if (queryString) {
      this.defaultQuery = new ChannelQuery(queryString);
    }

    if (!lazy) {
      this.init();
    }
  }

  init() {
    let initialQuery;

    if (this.persist) {
      this.on(ChannelEvents.QuerySuccess, this.setQueryString.bind(this));
      initialQuery = new ChannelQuery(this.getQueryString());

      // If persisted query included offset (user scrolled past page 1)
      // then include all previous docs in query
      if (initialQuery.getParam(I3QueryParams.Offset)) {
        initialQuery.setParam(I3QueryParams.LoadPrevious, 1);
      }
    }

    this.execute(initialQuery);
  }

  async execute(query, { appendDocuments } = {}) {
    if (typeof query === "undefined") {
      query = new ChannelQuery();
    } else if (typeof query === "string") {
      query = new ChannelQuery(query);
    }

    if (this.defaultQuery && !this.isUrlBased) {
      query = new ChannelQuery(
        Object.assign({}, this.defaultQuery.params, query.params)
      );
    }

    if (this.additionalParams.length > 0) {
      this.additionalParams.forEach(param =>
        query.setParam(param.key, param.value)
      );
    }

    const sortOption = Cookies.get(`sortOrder_${this.ref}`);
    if (sortOption)
    {
      query.setParam(I3QueryParams.Sort, sortOption);
    }

    if (this.hits) {
      query.setParam(I3QueryParams.Hits, this.hits);
    }

    query.setParam(I3QueryParams.Preference, this.preference);

    this.emit(ChannelEvents.QueryRequest, { channel: this });

    try {
      const res = await Axios.get(`${this.url}?${query}`);
      const data = this.modifyResponseData(res.data, query);

      if (appendDocuments) {
        data.documents.documents = [
          ...this.data.documents.documents,
          ...data.documents.documents
        ];
      }

      this.data = data;
      this.executedQueries.push(query);
      this.emit(ChannelEvents.QuerySuccess, { channel: this });
    } catch (e) {
      this.emit(ChannelEvents.QueryFailure, { channel: this });
    }
  }

  modifyResponseData(data, query) {
    if (data.facets) {
      data.facets = this.injectRangeFacetValues(data.facets, query);
    }

    if (this.morelikethis) {
      data.documents.documents = data.documents.documents[0].morelikethis;
    }

    if (this.seenbyothers) {
      data.documents.documents = data.documents.documents[0].seenbyothers;
    }

    return data;
  }

  injectRangeFacetValues(facets, query) {
    return facets.map(facet => {
      if (facet.selectionType === "RANGE") {
        const minQuery = query.getParam(facet.minQuery);
        const maxQuery = query.getParam(facet.maxQuery);
        const initialMin = query.getParam(`initial_${facet.minQuery}`);
        const initialMax = query.getParam(`initial_${facet.maxQuery}`);

        return {
          ...facet,
          initialMin:
            typeof initialMin !== "undefined"
              ? parseInt(initialMin, 10)
              : facet.min,
          initialMax:
            typeof initialMax !== "undefined"
              ? parseInt(initialMax, 10)
              : facet.max,
          minSelected: minQuery ? parseInt(minQuery, 10) : undefined,
          maxSelected: maxQuery ? parseInt(maxQuery, 10) : undefined
        };
      }

      return facet;
    });
  }

  get lastQuery() {
    return this.executedQueries[this.executedQueries.length - 1];
  }

  getSelectedFilters() {
    return this.data.facets
      .filter(facet => facet.filters)
      .map(facet => facet.filters)
      .flat()
      .filter(
        filter =>
          filter.selected ||
          (filter.children && filter.children.find(c => c.selected))
      );
  }

  getSelectedRangeFacets() {
    return this.data.facets
      .filter(facet => facet.selectionType === "RANGE")
      .filter(facet => facet.minSelected || facet.maxSelected);
  }

  getFreeTextValue() {
    return this.hasTouchedParam(I3QueryParams.FreeText)
      ? this.lastQuery.getParam(I3QueryParams.FreeText)
      : "";
  }

  getDefaultFreeTextValue() {
    return this.defaultQuery && this.defaultQuery.params[I3QueryParams.FreeText] !== "*"
      ? this.defaultQuery.getParam(I3QueryParams.FreeText)
      : "";
  }

  getQueryFilters() {
    const items = [];
    const freeTextValue = this.getFreeTextValue();
    const defaultFreeTextValue = this.getDefaultFreeTextValue();
    const mapFilterToQueryFilter = filter => ({
      filter,
      label: filter.displayName,
      key: uuidv4(),
      type: I3QueryFilterTypes.Filter
    });
    const filters = this.getSelectedFilters().map(filter => ({
      ...mapFilterToQueryFilter(filter),
      children: filter.children
        .filter(child => child.selected)
        .map(mapFilterToQueryFilter)
    }));
    const rangeFacets = this.getSelectedRangeFacets().map(facet => ({
      facet,
      label: this.getRangeFacetLabel(facet),
      key: facet.id,
      type: I3QueryFilterTypes.RangeFacet
    }));

    if (freeTextValue) {
      items.push({
        label: `"${freeTextValue}"`,
        key: freeTextValue,
        type: I3QueryFilterTypes.FreeText,
        locked: this.sameAsDefault(freeTextValue)
      });
    }

    if (defaultFreeTextValue) {
      items.push({
        label: `"${defaultFreeTextValue}"`,
        key: defaultFreeTextValue,
        type: I3QueryFilterTypes.FreeText,
        locked: this.sameAsDefault(defaultFreeTextValue)
      });
    }

    return items.concat(filters, rangeFacets);
  }

  getRangeFacetLabel(facet) {
    let minMax;

    if (facet.minSelected && facet.maxSelected) {
      minMax = `${facet.minSelected} - ${facet.maxSelected}`;
    } else if (facet.maxSelected) {
      minMax = `< ${facet.maxSelected}`;
    } else if (facet.minSelected) {
      minMax = `> ${facet.minSelected}`;
    }

    return `${facet.displayName}: ${minMax} ${facet.unit}`;
  }

  sameAsDefault(freeTextValue) {
    return freeTextValue === this.defaultQuery.params[I3QueryParams.FreeText];
  }

  hasTouchedParam(key) {
    if (!this.lastQuery) {
      return false;
    }

    if (!this.defaultQuery) {
      return this.lastQuery.params[key];
    }

    if (key === I3QueryParams.FreeText && this.lastQuery.params[key] != "*") {
      return true;
    }

    return this.lastQuery.params[key] !== this.defaultQuery.params[key];
  }

  get hasNextPage() {
    return (
      !!this.data.documents.pagination &&
      !!this.data.documents.pagination.nextPage
    );
  }

  queryNextPage() {
    if (!this.hasNextPage) {
      return;
    }

    return this.execute(this.data.documents.pagination.nextPage.query, {
      appendDocuments: true
    });
  }

  getQueryString() {
    return qs.parse(window.location.search);
  }

  setQueryString() {
    const { lastQuery, defaultQuery } = this;
    const compareQuery = defaultQuery ? defaultQuery : new ChannelQuery();
    const query = new ChannelQuery(lastQuery);
    const blacklist = [I3QueryParams.Preference];
    blacklist.forEach(param => query.unsetParam(param));

    const search =
      query.toString() === compareQuery.toString() ? "" : query.toString();
    if (search && search.length > 0)
    {
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${search}`
      );
    }
  }

  setAdditionalParams(newParam) {
    if (newParam.value !== null && newParam.value !== undefined) {
      if (!this.additionalParams.includes(newParam)) {
        this.additionalParams = [...this.additionalParams, newParam];
      }
    } else {
      const filteredParams = [...this.additionalParams].filter(
        param => param.key !== newParam.key
      );
      this.additionalParams = filteredParams;
    }
  }

  reportClick({ _clickurl }) {
    if (_clickurl) {
      Axios.get(`${this.url}${_clickurl}`);
    }
  }
}

Object.assign(I3Channel.prototype, events);

export default I3Channel;
