import Vue from "vue";
import App from "./App.vue";
import createStore from "./store";
import fields from "./data/fields";
import sections from "./data/sections";
import { l10n } from "../../utils/l10n";

export default props => {
  const store = createStore();
  const lang = {
    General: l10n.General,
    App: l10n.ServiceBooking
  };

  return Vue.extend({
    store,
    created() {
      const config = {
        lang,
        fields,
        sections,
        endpoint: "/api/services/ACRengöring",
        noBrands: true
      };
      store.dispatch("config/load", config);
    },
    render: h => h(App, { props })
  });
};
