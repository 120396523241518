var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.field.options, function(option) {
      return _c(
        "div",
        {
          key: option.value,
          staticClass: "tab-button tab-button--horizontal",
          class: {
            "tab-button--active":
              _vm.field.value === option.value && _vm.shouldShowSelected
          },
          on: {
            click: function($event) {
              return _vm.$emit("change", option.value)
            }
          }
        },
        [
          _c("i", { class: option.tabButtonIcon }),
          _vm._v(" "),
          _c("span", [_vm._v("\n      " + _vm._s(option.name) + "\n    ")])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }