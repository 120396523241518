<template>
  <div>
    <form class="my-pages__form-section" @submit.prevent="onSubmit">
      <a class="link link--underline link--muted-light my-pages__restore-link"
        @click="onRestoreClick">
        {{lang.App.ForgotPassword}}
      </a>
      <label>
        {{lang.App.LoginWithEmail}}
      </label>
      <VcFormStack label="E-post" required>
        <input
          v-model="email"
          class="form-control"
          placeholder="Ange din e-post"
          required
          type="text" />
      </VcFormStack>
      <VcFormStack label="Lösenord" required>
        <input
          v-model="password"
          class="form-control"
          placeholder="Ange ditt lösenord"
          required
          type="password" />
      </VcFormStack>
      <button :class="{'disabled': btnDisabled}" type="submit" class="btn btn--primary btn--block">
        {{lang.App.Login}}
      </button>
      <div v-if="confirmSuccess" class="feedback-message feedback-message--success feedback-message--slim">
        <div class="feedback-message__text">
          {{lang.App.EmailConfirmed}}
        </div>
      </div>
      <div v-if="confirmResendSuccess" class="feedback-message feedback-message--success feedback-message--slim">
        <div class="feedback-message__text">
          {{lang.App.ResendConfirmSuccess}}
        </div>
      </div>
      <div v-if="loginError" class="feedback-message feedback-message--danger feedback-message--slim">
        <div class="feedback-message__text">
          {{errorMessageForDisplay}}
        </div>
      </div>
      <a v-if="displayResendConfirm" href="#" @click.prevent="resendConfirm()" class="btn u-margin-top btn--outline btn--block btn--xs">Skicka nytt verifieringsmail</a>

    </form>
    <!-- <div class="my-pages__form-section">
      <label>
        Logga in med BankID
      </label>
      <a class="btn btn--white btn--block"
        @click="onBankidClick">
        Logga in med BankID
      </a>
      <p class="form-text" v-html="policyText">
      </p>
    </div> -->

    <div class="my-pages__form-section">
      <label>
        Skapa konto
      </label>
      <ul class="list-styled list-styled--checklist list-styled--sm">
        <li>
          Spara fordon som favoriter
        </li>
        <li>
          Starta bevakning på fordon
        </li>
        <li>
          Se dina webbeställningar
        </li>
      </ul>
      <a class="btn btn--white btn--block"
        @click="onRegisterClick">
        {{lang.App.CreateAccount}}
      </a>
    </div>
  </div>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';
import Views from './constants/Views';
import SignInStatuses from './constants/SignInStatuses';
import Axios from '../../axios';
import qs from 'query-string';

export default {
  components: {
    VcFormStack,
  },
  props: {
    lang: Object,
  },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      policyText: 'Genom att logga in godkänner jag Holmgrens villkor och bekräftar att jag läst dataskyddspolicyn.',
      loginError: false,
      loginErrorText: this.lang.LoginFailed,
      loginCustomErrorText: '',
      confirmSuccess: false,
      confirmResendSuccess: false,
      btnDisabled: false,
      displayResendConfirm: false,
    };
  },
  computed: {
    errorMessageForDisplay() {
      return this.loginCustomErrorText.length ? this.loginCustomErrorText : this.loginErrorText;
    }
  },

  async beforeMount() {
    const queryString = qs.parse(window.location.search);
    if(queryString.cToken && queryString.email) {
      await this.confirmEmail(queryString.cToken, queryString.email);
    }
  },

  methods: {
    onRestoreClick() {
      this.$emit('view', Views.Restore);
    },

    onBankidClick() {
      this.$emit('view', Views.Bankid);
    },

    onRegisterClick() {
      this.$emit('view', Views.Register);
    },

    async confirmEmail(token, email) {
      const result = await Axios.post('/api/login/confirmemail', {
        email,
        code: token
      });
      if(result && result.status === 200) {
        this.confirmSuccess = true;
      } else {
        this.loginError = true;
        this.loginCustomErrorText = this.lang.ConfirmFailed;
      }
      window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
    },

    async resendConfirm() {
      const {
        email,
      } = this;
      const result = await Axios.post('/api/login/resendconfirm', {
        email,
      });
      if(result && result.status === 200) {
        this.displayResendConfirm = false;
        this.confirmResendSuccess = true;
        this.loginError = false;
      } else {
        this.loginError = true;
        this.loginCustomErrorText = this.lang.ConfirmFailed;
      }
    },

    async onSubmit() {
      this.loginError = false;
      this.loginCustomErrorText = '';
      this.displayResendConfirm = false;
      const {
        email,
        password,
        rememberMe,
      } = this;

      this.btnDisabled = true;
      try {
        const result = await Axios.post('/api/login/login', {
          email,
          password,
          rememberMe,
        });

        if(result && result.data && result.data.signInStatus === SignInStatuses.Success) {
          const { origin, MyPagesPage } = window;
          window.location.href = `${origin}/mina-sidor`;
        } else {
          if(result.data.message) {
            this.loginCustomErrorText = result.data.message;
          }
          if(result.data.signInStatus === SignInStatuses.RequiresVerification) {
            this.displayResendConfirm = true;
          }
          this.loginError = true;
        }
      } catch(e) {
        console.error(e);
        this.loginError = true;
      } finally {
        this.btnDisabled = false;
      }

    },
  },
}
</script>
