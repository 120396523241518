import SectionTypes from "../constants/SectionTypes";

export default [
  {
    order: -1,
    type: SectionTypes.Department,
    hidden: true
  },
  {
    order: 0,
    type: SectionTypes.Vehicle,
    hidden: true
  },
  {
    order: 1,
    type: SectionTypes.TypeOfReport
  },
  // {
  //   order: 2,
  //   type: SectionTypes.Addons,
  // },
  {
    order: 2,
    type: SectionTypes.Date
  },
  {
    order: 3,
    type: SectionTypes.Images
  },
  {
    order: 4,
    type: SectionTypes.Contact
  },
  {
    order: 5,
    type: SectionTypes.Summary,
    lazy: true,
    summary: true
  }
];
