import I3FederatedSearchTypes from './I3FederatedSearchTypes';
import I3DocPage from '../models/I3DocPage';
import I3DocProduct from '../models/I3DocProduct';
import I3DocEmployees from '../models/I3DocEmployees';
import I3DocDepartments from '../models/I3DocDepartments';
import I3DocFacetPage from '../models/I3DocFacetPage';

export default {
  [I3FederatedSearchTypes.Cars]: I3DocProduct,
  [I3FederatedSearchTypes.Episerver]: I3DocPage,
  [I3FederatedSearchTypes.Employees]: I3DocEmployees,
  [I3FederatedSearchTypes.Departments]: I3DocDepartments,
  [I3FederatedSearchTypes.FacetPages]: I3DocFacetPage,
}
