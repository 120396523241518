<template>
  <form class="contact-forms__form form-feedback-host">
    <div v-if="cities.length">
      <div class="form-stack">
        <label> Välj anläggning </label>
        <div class="form-select">
          <select
            class="form-control"
            v-model="selectedLocation"
            @change="onLocationChange()"
          >
            <option value="">Välj anläggning</option>
            <option
              v-for="location in cities"
              :value="location.value"
              :key="location.value"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="department" class="info-block">
      <i class="info-block__icon fas fa-map-marker-alt"> </i>

      <div class="info-block__header">
        {{ department.departmentName }}
      </div>

      <div class="u-c-muted">
        {{ department.departmentAddress }} <br />
        {{ department.departmentZip }} {{ department.departmentCity }}
      </div>
    </div>
    <a
      v-if="department"
      :href="`tel:${department.departmentPhone}`"
      class="btn btn--primary btn--block call-action"
      data-form-type="quickForm"
      :data-department-nr="department && department.departmentNr"
    >
      <i class="fal fa-phone"></i>
      <div
        class="call-action__number call-action__number--toggler"
        v-html="maskedNumber"
      ></div>
      <div class="call-action__number">
        {{ department.departmentPhone }}
      </div>
    </a>
  </form>
</template>

<script>
export default {
  props: {
    cities: {
      type: Array,
    },
    department: {
      type: Object,
    },
    submittedSelectedLocation: {
      type: String,
    }
  },

  data() {
    return {
      selectedLocation: "",
    };
  },
  mounted() {
    this.selectedLocation = this.submittedSelectedLocation;
  },
  computed: {
    maskedNumber() {
      return (
        this.department?.departmentPhone?.substr(0, 3) +
        "- <span>XX XX XX</span>"
      );
    }
  },
  methods: {
    onLocationChange() {
      this.$emit('onLocationChange', this.selectedLocation);
    },
  }
};
</script>
