var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i3-filter__range-filter" },
    [
      _c("div", { staticClass: "i3-filter__range-filter-inputs" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.minValue,
              expression: "minValue",
              modifiers: { number: true }
            }
          ],
          staticClass: "form-control",
          attrs: { type: "number", min: _vm.facet.initialMin },
          domProps: { value: _vm.minValue },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.minValue = _vm._n($event.target.value)
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        }),
        _vm._v(" "),
        _c("div", [_vm._v("\n      -\n    ")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.maxValue,
              expression: "maxValue",
              modifiers: { number: true }
            }
          ],
          staticClass: "form-control",
          attrs: { type: "number", max: _vm.initialMax },
          domProps: { value: _vm.maxValue },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.maxValue = _vm._n($event.target.value)
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("VueSlider", {
        staticClass: "i3-filter__range-filter-slider",
        attrs: {
          value: _vm.sliderValue,
          min: _vm.min,
          max: _vm.max,
          "enable-cross": false,
          duration: 0,
          marks: _vm.marks,
          interval: _vm.interval,
          dotSize: 32,
          tooltip: "none"
        },
        on: { change: _vm.onSliderChange },
        scopedSlots: _vm._u([
          {
            key: "dot",
            fn: function(ref) {
              var value = ref.value
              var focus = ref.focus
              return [
                _c(
                  "div",
                  {
                    class: [
                      "vue-slider-dot-handle",
                      { "vue-slider-dot-handle-focus": focus }
                    ]
                  },
                  [
                    _c("i", { staticClass: "fal fa-angle-left" }),
                    _vm._v(" "),
                    _c("i", { staticClass: "fal fa-angle-right" })
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }