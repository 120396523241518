export default {
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  H: 'H',
  V: 'V',
  W: 'W',
  Y: 'Y',
  VR: 'VR',
  ZR: 'ZR',
}
