var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reviews-widget__review" }, [
    _c("div", { staticClass: "reviews-widget__review__image" }, [
      _c("img", { attrs: { src: _vm.review.reviewer.profilePhotoUrl } })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "reviews-widget__review__content" }, [
      _c("div", { staticClass: "reviews-widget__review__name" }, [
        _vm._v("\n      " + _vm._s(_vm.review.reviewer.displayName) + "\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "reviews-widget__review__rating-container" },
        [
          _c("StarList", {
            attrs: {
              "list-id": _vm.review.reviewId,
              rating: _vm.review.starRating,
              width: 16
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "reviews-widget__review__date" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.moment(_vm.review.updateTime).fromNow()) +
                "\n      "
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.review.comment
        ? _c("div", { staticClass: "reviews-widget__review__comment" }, [
            _vm._v("\n      " + _vm._s(_vm.comment) + "\n      "),
            !_vm.showFullComment && _vm.review.comment.length > 100
              ? _c("span", [
                  _vm._v("... "),
                  _c(
                    "span",
                    {
                      staticClass: "reviews-widget__review__read-more",
                      on: {
                        click: function($event) {
                          _vm.showFullComment = true
                        }
                      }
                    },
                    [_vm._v("Läs mer")]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }