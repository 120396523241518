<template>
  <div class="configurator-section tyre-selector">
    <div v-if="loading" class="configurator-section__loader">
      <FontAwesomeIcon
        :icon="faSpinnerThird"
        :spin="true"
      />
    </div>
    <div v-else-if="!hasTyreOptions">
      <ConfiguratorCheckbox
        text="Jag vill ha erbjudande på vinterdäck"
        :defaultChecked="needTyres"
        @change="setNeedsTyre"
      />
    </div>
    <div v-else class="form-row">
      <div v-for="tyre in tyreOptions" :key="'tyre-product-' + tyre.id" class="tyre-selector__item">
        <ConfiguratorCheckbox
          :ref="tyre.id"
          :imageSource="tyre.image || '/Static/images/hb_tire-icon.png'"
          :defaultChecked="tyre.selected"
          :imageTags="getImageTags(tyre)"
          @change="(e) => onOptionChange(e, tyre)"
        />
        <div class="tyre-selector__item__heading">{{ tyre.heading }}</div>
        <div class="tyre-selector__item__price-container">
          <div
            class="tyre-selector__item__price"
            :class="{
              'tyre-selector__item__price--discount': getPrice(tyre.financing).current < getPrice(tyre.financing).ordinary,
            }"
          >
            +{{ $formatPriceNoSuffix(getPrice(tyre.financing).current.toFixed() + getPrice(tyre.financing).suffix) }}
          </div>
          <div v-if="getPrice(tyre.financing).current < getPrice(tyre.financing).ordinary" class="tyre-selector__item__price tyre-selector__item__price--obsolete">
            +{{ $formatPriceNoSuffix(getPrice(tyre.financing).ordinary.toFixed() + getPrice(tyre.financing).suffix) }}
          </div>
        </div>
        <a :href="tyre.productUrl" class="link link--underline link--muted" target="_blank">Läs mer</a>
        <a class="btn btn--outline btn--sm btn--block" @click.prevent="addClick(tyre)">Lägg till</a>
      </div>
    </div>
  </div>
</template>
<script>
import Axios from 'axios';
import ConfiguratorCheckbox from '../configurator-components/ConfiguratorCheckbox.vue';
import store from '../store';
import FinanceOptions from '../../../constants/FinanceOptions';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { monthlyCost, monthlyCostTyre, pmt } from "../../../utils/priceCalculations";
import CalculationKeys from "../constants/calculation-keys";

export default {
  props: {
    lang: Object,
    selectedFinancingOption: Object,
    displayTax: Boolean,
  },
  components: {
    ConfiguratorCheckbox,
    FontAwesomeIcon
},
  data() {
    return {
      FinanceOptions,
      loading: false,
      hasTyreOptions: false,
      faSpinnerThird,
    };
  },
  mounted() {
    if (!this.storeState.tyres || this.storeState.tyres.length === 0) {
      this.fetchTyreOptions();
    } else {
      this.hasTyreOptions = true;
    }
  },
  computed: {
    storeState() {
      return store.state;
    },
    tyreOptions() {
      const tyres = this.storeState.tyres;
      return tyres;
    },
    needTyres() {
      const tyres = this.storeState.needTyres;
      return tyres;
    },
  },
  methods: {
    addClick(tyre) {
      this.$refs[tyre.id][0].toggleCheckbox();
      //store.alterTyreOptions(tyre, true);
    },
    onOptionChange(checked, tyre) {
      store.alterTyreOptions(tyre, checked);
    },
    getPrice(financing) {
      const suffix = this.selectedFinancingOption.calculationType === CalculationKeys.Monthly ? ' kr/mån' : ' kr';
      const currentPrice = this.displayTax ? financing.ordinaryPrice : financing.ordinaryPriceNoVat;
      const ordinaryPrice = this.displayTax ? financing.currentPrice : financing.currentPriceNoVat;
      return {

        ordinary: this.selectedFinancingOption.calculationType === CalculationKeys.Monthly
          ? monthlyCostTyre(this.selectedFinancingOption.financing, financing, ordinaryPrice)
          : ordinaryPrice,
        current: this.selectedFinancingOption.calculationType === CalculationKeys.Monthly
          ? monthlyCostTyre(this.selectedFinancingOption.financing, financing, currentPrice)
          : currentPrice,
        suffix,
      }
    },
    async fetchTyreOptions() {
      try {
        this.loading = true;
        const financingTemplateId = this.storeState.financingTemplateId;

        const { data } = await Axios.post(
          `/api/vehicleConfigurator/${this.storeState.regNr}/tyres`,
          {
            financingTemplateId
          }
        );
        store.setTyresAction(data);
        this.hasTyreOptions = data && data.length > 0;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    getImageTags(tyre) {
      const list = [];
      const metaTag = tyre.headingMeta ? { title: tyre.headingMeta, icon: '', tagTheme: 'red' } : null;
      const conditionTag = tyre.condition && tyre.condition === 'USED' ? { title: this.lang.ConfiguratorSummation.TyresUsedTag, icon: 'recycle', tagTheme: 'black' } : null;

      if (metaTag) list.push(metaTag);
      if (conditionTag) list.push(conditionTag);

      return list;
    },
    setNeedsTyre(checked) {
      store.setNeedTyres(checked);
    }
  },
}
</script>
