var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-list" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "vehicle-list__header" }, [
            _c("div", { staticClass: "vehicle-list__header__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$formatNumber(_vm.hits)) +
                  " fordon\n      "
              )
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("TheGrid", {
            attrs: {
              gridState: _vm.gridState,
              channel: _vm.channel,
              documents: _vm.documents,
              queriedAt: _vm.queriedAt
            },
            on: {
              onNext: function($event) {
                return _vm.$emit("onNext")
              },
              stateChange: _vm.onGridStateChange
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("CompareVehicles")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "share-page-filterbar__btn " }, [
      _c("i", { staticClass: "fal fa-share-square" }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "link link--primary js-share-current-url",
          attrs: {
            "data-toggle": "modal",
            "data-target": "[data-share-page-modal]"
          }
        },
        [_vm._v("Dela")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }