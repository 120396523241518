import Axios from '../axios';
import gtm from '../utils/gtm';

export default {
  init() {
    this.getReportDone = false;
    this.requestInfoDone = false;
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
    $('body').
      on('submit', '.vehicle-history-forms__get-report', this.onSubmitGetReport.bind(this)).
      on('submit', '.vehicle-history-forms__request-info', this.onSubmitReportInfo.bind(this)).
      on('click', '.vehicle-history-forms [data-report-url]', this.onClickShowReport.bind(this));
  },

  modifyDOM() {
    this.update();
  },

  onSubmitGetReport({ currentTarget }) {
    const $el = $(currentTarget);
    const $submitBtn = $el.find('[type="submit"]');
    const data = {
      email: $('.vehicle-history-forms [name="email"]').val(),
      registrationNumber: $('.vehicle-history-forms').data('registration-nr'),
      carUrl: window.location.href,
    };

    $submitBtn.addClass('disabled');

    Axios.post('/api/vehicle/RequestReport', data).then(res => {
      $('.vehicle-history-forms [data-report-car-name]').text(res.data.vehicle);
      $('.vehicle-history-forms [data-report-url]').prop('href', res.data.reportUrl);
      $submitBtn.removeClass('disabled');
      this.getReportDone = true;
      this.update();
      gtm.vehicleHistoryGetReportSubmit(this.getGtmData());
    });

    return false;
  },

  onSubmitReportInfo({ currentTarget }) {
    const $el = $(currentTarget);
    const $submitBtn = $el.find('[type="submit"]');
    const data = {
      email: $('.vehicle-history-forms [name="email"]').val(),
      phone: $('.vehicle-history-forms [name="phone"]').val(),
      registrationNumber: $('.vehicle-history-forms').data('registration-nr'),
      carUrl: window.location.href,
    };

    $submitBtn.addClass('disabled');

    Axios.post('/api/vehicle/RequestReportContact', data).then(() => {
      $submitBtn.removeClass('disabled');
      this.requestInfoDone = true;
      this.update();
      gtm.vehicleHistoryContactSubmit(this.getGtmData());
    });

    return false;
  },

  async onClickShowReport(e) {
    e.preventDefault();

    await gtm.vehicleHistoryShowReportClick(this.getGtmData());

    const $el = $(e.currentTarget);
    window.open($el.prop('href'), '_blank');
  },

  getGtmData() {
    return {
      registrationNumber: $('.vehicle-history-forms').data('registration-nr'),
      vehicleCondition: $('.vehicle-history-forms').data('vehicle-condition'),
      brandName: $('.vehicle-history-forms').data('brand-name'),
      departmentNr: $('.vehicle-history-forms').data('department-nr'),
    };
  },

  update() {
    const $getReport = $('.vehicle-history-forms__get-report');
    const $requestInfo = $('.vehicle-history-forms__request-info');
    const $requestInfoForm = $requestInfo.find('form');

    if (this.requestInfoDone) {
      $requestInfoForm.find('.form-feedback').addClass('active');
    } else if (this.getReportDone) {
      $getReport.hide();
      $requestInfo.show();
    } else {
      $getReport.show();
      $requestInfo.hide();
    }
  },
}
