<template>
  <div class="checkout__select-vehicle">
    <div class="checkout__select-vehicle__description">
      {{ lang.Checkout.VehicleInformation.Description }}
    </div>
    <div class="regnr-input">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="regnr-input__symbol"></div>
        </div>
        <div class="form-stack">
          <label>
            Regnummer
          </label>
          <input
            id="regNr-input"
            type="text"
            @keyup="onChange()"
            class="form-control"
            placeholder="ABC123"
            :required="!hasMultipleRegNumbers"
            pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
            style="color: #18191a"
            v-model="regNr"
          />
        </div>
      </div>
    </div>
    <VcOptionBox
      :selected="hasMultipleRegNumbers"
      :heading="lang.Checkout.VehicleInformation.OptionHeading"
      @click="onSetMultipleRegNumbers"
    ></VcOptionBox>
  </div>
</template>
<script>
import { VcOptionBox } from "@holmgrensbil/vue-components";
export default {
  components: { VcOptionBox },
  props: {
    lang: Object
  },
  data() {
    return {
      regNr: "",
      hasMultipleRegNumbers: false
    };
  },
  methods: {
    onChange() {
      this.$emit("setRegNumber", this.regNr);
    },
    onSetMultipleRegNumbers() {
      const newCheckboxValue = !this.hasMultipleRegNumbers;
      this.hasMultipleRegNumbers = newCheckboxValue;
      this.$emit("setMultipleRegNumbers", newCheckboxValue);
    }
  }
};
</script>
