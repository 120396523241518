import Overlay from "../models/Overlay";
import I3SiteSearch from "../apps/i3-site-search";
import SiteCart from "../apps/site-cart";
import MyPages from "../apps/my-pages";
import Contact from "../apps/contact";
import mountApp from "../utils/mount-app";
import OverlayEvents from "../constants/OverlayEvents";
import qs from 'query-string';
import Breakpoints from "../constants/Breakpoints";


export default {
  init({ navOverlayOnClose }) {
    this.navOverlay = new Overlay();
    this.navOverlay.on(OverlayEvents.BackdropClose, navOverlayOnClose);
    this.navOverlay.on(OverlayEvents.ContentInserted, this.maybeMountContact);
    this.mobileNavOverlay = new Overlay({ aside: true });
    this.searchOverlay = new Overlay({ aside: true });
    this.loginOverlay = new Overlay({ aside: true });
    this.cartOverlay = new Overlay({ aside: true });
    this.currentIndex = null;
    this.addEvents();
    const queryString = qs.parse(window.location.search);
    if(queryString.rToken || queryString.cToken || (queryString.myPagesOpen && queryString.myPagesOpen == 1)) {
      this.onClickLogin();
    }
  },

  addEvents() {
    $('body').
      on('click', '.site-header__action-item--nav', this.onClickMobileNav.bind(this)).
      on('click', '.site-header__action-item--search', this.onClickSearch.bind(this)).
      on('click', '.site-header__action-item--login', this.onClickLogin.bind(this)).
      on('click', '.save-vehicle__user-not-logged-in', this.onClickLogin.bind(this)).
      on('click', '.save-search__user-not-logged-in', this.onClickLogin.bind(this)).
      on('click', '.bookmark__user-not-logged-in', this.onClickLogin.bind(this)).
      on('click', '.site-header__action-item--cart', this.onClickCart.bind(this)).
      on('click', '[data-overlay]', this.onToggleNavOverlay.bind(this));

    document.addEventListener("toggle-mypages", () => {
      this.mountMyPages();
    });
  },

  onClickMobileNav() {
    if (!this.mobileNavOverlay.initialized) {
      this.mobileNavOverlay.init({ content: $('.mobile-nav-tree-holder .nav-tree') });
    }

    setTimeout(() => {
      this.toggleMobileNav();
    }, 1);

    return false;
  },

  toggleMobileNav() {
    if (this.mobileNavOverlay.isOpen()) {
      this.mobileNavOverlay.close();
    } else {
      this.mobileNavOverlay.open({
        topOffset: this.getTopOffset(),
        fullHeight: true,
      });
    }
  },

  maybeMountContact({ detail }) {
    const selector = '.overlay__content [data-app=contact]';

    if (detail.overlay.$el.find(selector).length) {
      mountApp({
        app: Contact,
        selector,
      });
    }
  },

  onClickSearch() {
    if ($(window).width() <= Breakpoints.md) {
      this.mountSearch();
    }
    return false;
  },

  onClickLogin() {
    if (window.CurrentUser !== null && window.CurrentUser.email.length) {
      const { origin, MyPagesPage } = window;
      window.location.href = `${origin}/mina-sidor`;
    } else {
      this.mountMyPages();
      return false;
    }
  },

  onClickCart() {
    this.mountCart();
    return false;
  },

  onToggleNavOverlay(e) {
    const $el = $(e.currentTarget);
    const content = $($el.data('overlay')).html();

    if (!this.navOverlay.initialized) {
      this.navOverlay.init();
    }

    setTimeout(() => {
      this.toggleNavOverlay(content, $el.index());
    }, 1);

    return false;
  },

  mountMyPages() {
    if (!this.loginOverlay.initialized) {
      const selector = '[data-app=my-pages]';
      const $el = $(selector);

      this.loginOverlay.init({ content: $el });

      mountApp({
        app: MyPages,
        selector,
        props: {
          onClose: () => {
            this.loginOverlay.close();
          },
          loggedInUser: window.CurrentUser ? window.CurrentUser : null,
        },
      });
    }

    setTimeout(() => {
      this.loginOverlay.open();
    }, 1);
  },

  mountCart() {
    if (!this.cartOverlay.initialized) {
      const selector = '[data-app=site-cart]';
      const $el = $(selector);

      this.cartOverlay.init({ content: $el });

      const mountedApps = mountApp({
        app: SiteCart,
        selector,
        props: {
          onClose: () => {
            this.cartOverlay.close();
          },
        },
      });

      this.cartOverlay.on(OverlayEvents.Open, () => {
        mountedApps.forEach(ma => ma.$children[0].$data.cartOverlayOpen = new Date());
      });
    }

    setTimeout(() => {
      this.cartOverlay.open();
    }, 1);
  },

  mountSearch() {
    if (!this.searchOverlay.initialized) {
      const selector = '[data-app=site-search]';
      const $el = $(selector);

      this.searchOverlay.init({ content: $el });

      mountApp({
        app: I3SiteSearch,
        selector,
        setI3Channel: true,
        props: {
          onClose: () => {
            this.searchOverlay.close();
          },
        },
      });
    }

    setTimeout(() => {
      this.searchOverlay.open();
    }, 1);
  },

  toggleNavOverlay(content, index) {
    if (this.navOverlay.isOpen()) {
      if (index === this.currentIndex) {
        this.navOverlay.close();
      } else {
        this.navOverlay.replaceContent({
          content,
          transition: true,
          transitionDirection: index > this.currentIndex ? 'forward' : 'backward',
        });
      }
    } else {
      const topOffset = this.getTopOffset();
      this.navOverlay.setContent(content);
      this.navOverlay.open({ topOffset });
    }

    this.currentIndex = index;
  },

  getTopOffset() {
    let headerHeight = 0;

    if ($('body').hasClass('scrolled')) {
      headerHeight = $('.site-header__main').outerHeight() + $('.site-header__sub-bar').outerHeight() || 0
    } else {
      headerHeight = $('.site-header').outerHeight() || 0;
    }
    const alertsHeight = $('body').hasClass('scrolled') || !window.hasSiteAlerts ? 0 : ($('.site-alerts').outerHeight() || 0);
    return headerHeight + alertsHeight;
  },
}
