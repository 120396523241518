export default (options) => {
  return options.filter(o => o.selected).map(o => ({
    id: o.id,
    name: o.name,
    value: o.subOptions ? o.subOptions.filter(so => so.selected).map(so => ({
      name: so.name,
      value: [],
    })) : [],
  }));
};
