<template>
  <div v-if="option.text" v-html="option.text">
    </div>
</template>

<script>
export default {
  props: {
    option: Object,
    default: () => {}
  },
}
</script>
