import Vuex from 'vuex';
import cart from '../../shared/ecom/modules/cart';

const strict = process.env.NODE_ENV !== 'production';

export default () => new Vuex.Store({
  strict,
  modules: {
    cart,
  },
});
