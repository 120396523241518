var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcFormStack",
    { attrs: { label: _vm.option.heading, required: _vm.option.required } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.mileage,
            expression: "mileage"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "number", min: "1", required: _vm.option.required },
        domProps: { value: _vm.mileage },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.mileage = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _vm.showInfo
        ? _c("div", { staticClass: "mileage-notice-text" }, [
            _vm._v("\n    " + _vm._s(_vm.lang.MileageErrorText) + "\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }