var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configurator-section tyre-selector" },
    [
      _c("div", { staticClass: "configurator-section__title-container" }, [
        _c("h4", { staticClass: "configurator-section__title" }, [
          _c("i", { staticClass: "fal fa-tire configurator-section__icon" }),
          _vm._v("\n      " + _vm._s(_vm.tyreData.heading) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.tyreData.informationModalUrl
          ? _c(
              "a",
              {
                staticClass: "link link--muted-light link--underline",
                attrs: {
                  href: "#",
                  "data-target": _vm.tyreData.informationModalUrl,
                  "data-modal-source": "ajax"
                }
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.lang.SiteGeneral.ReadMore) + "\n    "
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("VcOptionBox", {
        attrs: {
          selected: !_vm.showTyreOptions,
          heading: _vm.tyreData.denyOption
        },
        on: { click: _vm.onHideTyreOptions }
      }),
      _vm._v(" "),
      _c("VcOptionBox", {
        attrs: {
          selected: _vm.showTyreOptions,
          heading: _vm.tyreData.acceptOption
        },
        on: { click: _vm.onShowTyreOptions }
      }),
      _vm._v(" "),
      _vm.showTyreOptions && _vm.hasTyreOptions
        ? _c(
            "div",
            { staticClass: "tyre-selector__up-sell" },
            [
              _c("ProductUpsell", {
                attrs: {
                  options: _vm.tyreOptions,
                  heading: "Vinterdäckserbjudande",
                  selectedFinance: _vm.selectedFinancingOption,
                  lang: _vm.lang
                },
                on: { optionChange: _vm.onOptionChange }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }