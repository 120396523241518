<template>
  <div class="contact" :class="{'contact--slim' : hideSections}">
    <TheTabs
      v-if="!hideSections"
      :sections="sections"
      :selected="selectedSection"
      @select="onSelectSection">
    </TheTabs>
    <TheSection v-if="selectedSection"
      ref="section"
      :hide-sections="hideSections"
      :section="selectedSection">
    </TheSection>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import TheTabs from './TheTabs.vue';
import TheSection from './TheSection.vue';
import { scrollToEl } from '../../utils/scrolling';
import Breakpoints from '../../constants/Breakpoints';

export default {
  components: {
    TheTabs,
    TheSection,
  },
  props: {
    sectionHandle: String,
    preselectedBrand: String,
    preselectedServices: Array,
    hideSections: Boolean,
  },
  created() {
    this.onCreated();
  },
  computed: {
    ...mapState({
      sections: state => state.sections.items,
      selectedSection: state => state.sections.selected,
    }),

    shouldScrollToSection() {
      return this.windowWidth < Breakpoints.md;
    },
  },
  methods: {
    ...mapActions({
      fetchConfig: 'config/fetch',
      selectSection: 'sections/select',
      setPreselectedServices: 'services/preselected',
      setPreselectedBrand: 'departments/preselectedBrand',
    }),

    async onCreated() {
      await this.fetchConfig();

      if (this.sectionHandle) {
        const section = this.sections.find(s => s.sectionHandle === this.sectionHandle);

        if (section) {
          this.onSelectSection(section);
        }
      }
      if(this.preselectedServices && Array.isArray(this.preselectedServices)) {
        this.setPreselectedServices(this.preselectedServices);
      }

      if(this.preselectedBrand && this.preselectedBrand.length) {
        this.setPreselectedBrand(this.preselectedBrand);
      }
    },

    getSectionIconClass(section) {
      return `fa-${section.icon}`;
    },

    onSelectSection(section) {
      if(section.sectionHandle === 'link' && section.linkUrl && section.linkUrl.length) {
        window.location.assign(section.linkUrl);
        return;
      }
      this.selectSection(section);

      if (this.shouldScrollToSection) {
        Vue.nextTick(() => {
          scrollToEl(this.$refs.section.$el);
        });
      }
    },
  },
}
</script>
