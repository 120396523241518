<template>
    <div
        class="configurator-select__item"
        :key="option.name"
        @click="$emit('click', option)">
        {{option.name}}
    </div>
</template>

<script>
export default {
    props: {
        option: Object,
    },
}
</script>