import Pikaday from "pikaday";
import { formatYYYYMMDD } from "../utils/dates";
import observeAddedEl from "../utils/observe-added-el";
import moment from 'moment';

export default {
  init() {
    this.pickers = [];
    this.modifyDOM();
  },

  modifyDOM() {
    $(".js-datepicker").each((i, el) => {
      var pastDates = $(el).attr("data-past-dates") === "true";
      var allowSaturday = $(el).attr("data-allowsaturday") === "true";
      var allowSunday = $(el).attr("data-allowsunday") === "true";
      var startDate = $(el).attr("data-startdate");
      var endDate = $(el).attr("data-enddate");
      var blockedDates = $(el)?.attr("data-blocked-dates")?.split(',').map(d => moment(d)?.format('yyyy-MM-DD')) || [];
      if (allowSaturday)
        this.initDatepickerSaturdays(el, pastDates, startDate, endDate,  blockedDates);
      else this.initDatepicker(el, pastDates, startDate, endDate, blockedDates, !allowSunday);
    });
    observeAddedEl(".js-datepicker", el => {
      var pastDates = $(el).attr("data-past-dates") === "true";
      var startDate = $(el).attr("data-startdate");
      var endDate = $(el).attr("data-enddate");
      var allowSaturday = $(el).attr("data-allowsaturday") === "true";
      var allowSunday = $(el).attr("data-allowsunday") === "true";
      var blockedDates = $(el)?.attr("data-blocked-dates")?.split(',').map(d => moment(d)?.format('yyyy-MM-DD')) || [];
      if (allowSaturday)
        this.initDatepickerSaturdays(el, pastDates, startDate, endDate, blockedDates);
      else this.initDatepicker(el, pastDates, startDate, endDate, blockedDates, !allowSunday);
    });
  },

  initDatepicker(el, pastDates, startDate, endDate, blockedDates, disableWeekends) {
    el.setAttribute("readonly", "readonly");

    let picker = new Pikaday({
      field: el,
      firstDay: 1,
      toString: date => {
        return this.formatYYYYMMDD(date);
      },
      i18n: {
        previousMonth: "Föregående månad",
        nextMonth: "Nästa månad",
        months: [
          "Januari",
          "Februari",
          "Mars",
          "April",
          "Maj",
          "Juni",
          "Juli",
          "Augusti",
          "September",
          "Oktober",
          "November",
          "December"
        ],
        weekdays: [
          "Söndag",
          "Måndag",
          "Tisdag",
          "Onsdag",
          "Torsdag",
          "Fredag",
          "Lördag"
        ],
        weekdaysShort: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"]
      },
      minDate: pastDates ? null : startDate ? new Date(startDate) : new Date(),
      maxDate: endDate ? new Date(endDate) : null,
      defaultDate: startDate ? new Date(startDate) : null,
      disableWeekends: disableWeekends,
      theme: "pika-holmgrens",
      disableDayFn: (date) => {
        return blockedDates.includes(moment(date).format("yyyy-MM-DD"));
      },
    });

    this.pickers.push(picker);
  },

  initDatepickerSaturdays(el, pastDates, startDate, endDate, blockedDates) {
    el.setAttribute("readonly", "readonly");
    const picker = new Pikaday({
      field: el,
      firstDay: 1,
      toString: date => {
        return this.formatYYYYMMDD(date);
      },
      i18n: {
        previousMonth: "Föregående månad",
        nextMonth: "Nästa månad",
        months: [
          "Januari",
          "Februari",
          "Mars",
          "April",
          "Maj",
          "Juni",
          "Juli",
          "Augusti",
          "September",
          "Oktober",
          "November",
          "December"
        ],
        weekdays: [
          "Söndag",
          "Måndag",
          "Tisdag",
          "Onsdag",
          "Torsdag",
          "Fredag",
          "Lördag"
        ],
        weekdaysShort: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"]
      },
      theme: "pika-holmgrens",
      minDate: pastDates ? null : startDate ? new Date(startDate) : new Date(),
      maxDate: endDate ? new Date(endDate) : null,
      defaultDate: startDate ? new Date(startDate) : null,
      disableDayFn: function(date) {
        if (date.getDay() === 0) {
          return date;
        } else if (blockedDates.includes(moment(date).format("yyyy-MM-DD"))) {
          return true;
        }
      }
    });

    this.pickers.push(picker);
  },

  formatYYYYMMDD: date => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
};
