var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-margin-top-lg" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("h4", { staticClass: "u-fw-medium" }, [_vm._v("Dina uppgifter")]),
        _vm._v(" "),
        _vm.diplayDepartmentSelector
          ? _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-row__col" },
                [
                  _c(
                    "VcFormStack",
                    {
                      attrs: {
                        label: "Välj din närmaste aläggning",
                        required: _vm.diplayDepartmentSelector
                      }
                    },
                    [
                      _c("VcFormSelect", {
                        attrs: {
                          prompt: "Välj din anläggning",
                          options: _vm.store.departments,
                          value: _vm.store.selectedDepartment,
                          valueProp: "value",
                          labelProp: "label",
                          valueType: "string",
                          required: _vm.diplayDepartmentSelector
                        },
                        on: { change: _vm.onDepartmentChange }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col form-row__col--md-50" },
            [
              _c(
                "VcFormStack",
                { attrs: { label: "Förnamn", required: true } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.store.customer.firstName,
                        expression: "store.customer.firstName"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Förnamn",
                      required: true,
                      type: "text"
                    },
                    domProps: { value: _vm.store.customer.firstName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.store.customer,
                          "firstName",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row__col form-row__col--md-50" },
            [
              _c(
                "VcFormStack",
                { attrs: { label: "Efternamn", required: true } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.store.customer.lastName,
                        expression: "store.customer.lastName"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Efternamn",
                      required: true,
                      type: "text"
                    },
                    domProps: { value: _vm.store.customer.lastName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.store.customer,
                          "lastName",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col form-row__col--md-50" },
            [
              _c(
                "VcFormStack",
                { attrs: { label: "Telefonnummer", required: true } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.store.customer.phone,
                        expression: "store.customer.phone"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Telefonnummer",
                      required: true,
                      type: "text"
                    },
                    domProps: { value: _vm.store.customer.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.store.customer,
                          "phone",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row__col form-row__col--md-50" },
            [
              _c(
                "VcFormStack",
                { attrs: { label: "E-post", required: true } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.store.customer.email,
                        expression: "store.customer.email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "E-post",
                      required: true,
                      type: "email"
                    },
                    domProps: { value: _vm.store.customer.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.store.customer,
                          "email",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        !_vm.store.submitted
          ? _c(
              "button",
              {
                staticClass:
                  "u-margin-top u-margin-bottom btn btn--primary btn--block",
                attrs: { disabled: !_vm.store.readyForSubmit },
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              },
              [
                _vm._v("\n        Skicka\n        "),
                _vm.submitting
                  ? _c("FontAwesomeIcon", {
                      attrs: { icon: _vm.faSpinnerThird, spin: true }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.store.submitted
          ? _c(
              "div",
              {
                staticClass:
                  "u-margin-top u-margin-bottom feedback-message feedback-message--slim feedback-message--success"
              },
              [
                _vm._v(
                  "\n        Din ansökan om serviceavtal har nu skickats in och vi kommer återkomma\n        till dig med ytterligare detaljer.\n      "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.store.submitError
          ? _c(
              "div",
              {
                staticClass:
                  "u-margin-top u-margin-bottom feedback-message feedback-message--slim feedback-message--danger"
              },
              [
                _vm._v(
                  "\n        Ett fel har tyvärr uppstått. Kontakta kundtjänst för att få hjälp med\n        ditt serviceavtal.\n      "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("small", {
          staticClass: "u-c-muted u-margin-top",
          domProps: {
            innerHTML: _vm._s(_vm.store.lang.General.PrivacyPolicyText)
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }