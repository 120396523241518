<template>
  <div class="eon-single-product row">
    <div class="col-md-6 eon-single-product__images" v-if="product.images.length && additionalServicesFetched">
      <ImageSlider :images="product.images"></ImageSlider>
      <div v-if="shouldShowSCTButton" class="sound-comfort-button">
        <a class="link" data-target="/globalt-innehall/informationssidor/soundcomfort/" data-modal-source="ajax">
          <img src="/Static/images/sound-comfort.png?width=160" />
        </a>
      </div>
    </div>
    <div class="col-md-6 col-lg-5 eon-single-product__main" :class="{ hidden: !additionalServicesFetched }">
      <div class="eon-single-product__meta">
        <span :class="{ 'mark--black mark--sm mark--uppercase': product.isNew }" class="mark">{{
      product.shortConditionType }}</span>
        {{ product.productTypeDisplayName }}
        {{ product.hasTpms ? "med TPMS" : "" }}
      </div>

      <EonProductTitle :product="product" large> </EonProductTitle>

      <div class="eon-single-product__buy">
        <div v-if="shouldShowRibbon" class="ribbon ribbon--attention ribbon--right">
          Förvaring utan kostnad
        </div>

        <div v-if="shouldShowDiscountRibbon && product.discount <= 100" class="ribbon ribbon--attention ribbon--right">
          {{ product.discount }}% rabatt
        </div>
        <div v-else-if="shouldShowDiscountRibbon && product.discount > 100"
          class="ribbon ribbon--attention ribbon--right">
          {{ product.discount * (showPriceFor4 ? 4 : 1) }}kr rabatt
        </div>

        <div class="eon-single-product__buy-price">
          <a class="btn btn--xs btn--white" @click="showPriceFor4 = !showPriceFor4">
            Visa pris för {{ showPriceFor4 ? "1" : "4" }} st
          </a>

          <div>
            <span class="eon-single-product__buy-price-total">
              {{
      $formatPrice(
        showPriceFor4
          ? product.totalPriceWithVat(currentQuantity) * 4
          : product.totalPriceWithVat(currentQuantity)
      )
    }}
            </span>
            <span class="eon-single-product__buy-price-quantity">
              / {{ showPriceFor4 ? "4" : "" }} st
            </span>
            <div class="eon-single-product__buy-price-meta">
              <span class="eon-product-spot__price--obsolete"
                v-if="product.discounted(currentQuantity)">{{ $formatPrice(product.totalOrdinaryPriceWithVat) }} /
                st<br></span>
              Avbetalning från {{ $formatPrice(lowestPartPaymentPrice) }}/mån
              per {{ showPriceFor4 ? "4" : "" }} st
              <a class="link link--underline link--icon" @click="showMoreMeta = !showMoreMeta">
                <span>
                  Mer info
                </span>
                <i class="fal fa-angle-down" :class="{
      'fa-angle-down': !showMoreMeta,
      'fa-angle-up': showMoreMeta
    }"></i>
              </a>
              <div v-if="showMoreMeta">
                <span v-for="factor in product.annuityFactors" :key="factor.duration">
                  {{ factor.paymentPlanName }} -
                  {{
      $formatPrice(
        (
          product.totalPriceWithVat(showPriceFor4 ? 4 : 1) *
          factor.factor *
          (showPriceFor4 ? 4 : 1)
        ).toFixed(0)
      )
    }}/mån <br />
                </span>
              </div>
            </div>
          </div>
        </div>

        <VcOptionBox v-for="service in additionalServices" :key="service.id" :selected="isServiceSelected(service)"
          :heading="service.name" checkbox @click="onServiceClick(service)">
          <div slot="header-aside" v-if="service.price">
            <VcPriceTag :price="service.price" showIfZero> </VcPriceTag>
          </div>

          <div slot="header-meta" v-if="service.modalContent">
            <a class="link link--underline" @click="onReadMore($event, service)">Läs mer</a>
          </div>
          <div slot="content" v-if="isServiceSelected(service) && service.subOptions.length">
            <div v-for="option in service.subOptions" :key="option.id">
              <p class="u-c-muted eon-single-product__sub-options-header">
                Uppgradera till {{ option.name }}
              </p>
              <VcOptionBox :selected="isSubOptionSelected(option)" :heading="'+ ' + option.name" checkbox
                @click="onSubOptionClick(service, option)">
                >
                <div slot="header-meta" v-if="option.modalContent">
                  <a class="link link--underline" @click="onReadMore($event, option)">Läs mer</a>
                </div>
                <div slot="header-aside" v-if="option.price">
                  <VcPriceTag :price="option.price" showIfZero> </VcPriceTag>
                </div>
              </VcOptionBox>
            </div>
          </div>
        </VcOptionBox>

        <EonProductBuyActions v-if="!productAdded" :selectedServices="allSelectedAdditionalServices" :product="product"
          :regNr="regNr" class="eon-single-product__buy-actions" @quantity="onQuantity" @added="onAdded">
        </EonProductBuyActions>

        <VcFeedbackMessage success slim v-if="productAdded">
          <i class="fas fa-check"></i>
          Produkten tillagd i varukorgen!
        </VcFeedbackMessage>

        <div class="eon-single-product__buy-meta">
          <div class="eon-single-product__buy-meta-delivery">
            <DeliveryStatusCircle :deliveryStatus="deliveryStatus"></DeliveryStatusCircle>
            <div>
              {{ deliveryStatusLabel }} hos en av
              <a class="link link--underline" href="#" @click.prevent="onOpenSitesClick">
                våra {{ numberOfSites }} däckcenter
              </a>
            </div>
          </div>
          <div class="eon-single-product__buy-meta-delivery eon-single-product__buy-meta-delivery--custom"
            v-html="productUsps"></div>
        </div>
      </div>
    </div>
    <VcModal v-if="departmentsModalOpen" @close="departmentsModalOpen = false">
      <div slot="header">Våra {{ numberOfSites }} däckcenter</div>
      <div slot="content" v-html="modalContent"></div>
    </VcModal>
    <VcModal v-if="readMoreModalOpen" @close="closeReadMore">
      <div slot="header">
        {{ readMoreHeader }}
      </div>
      <div slot="content" v-html="readMoreContent"></div>
    </VcModal>
    <EonProductServicesModal v-if="servicesModalOpen" :product="product" :addedQuantity="currentQuantity"
      :previouslyAddedServices="allSelectedAdditionalServiceObjects" @close="servicesModalOpen = false">
    </EonProductServicesModal>
  </div>
</template>

<script>
import {
  VcFeedbackMessage,
  VcOptionBox,
  VcPriceTag
} from "@holmgrensbil/vue-components";
import EonProduct from "../../models/EonProduct";
import EonProductTitle from "../shared/ecom/EonProductTitle.vue";
import EonProductBuyActions from "../shared/ecom/EonProductBuyActions.vue";
import DeliveryStatusCircle from "../shared/ecom/DeliveryStatusCircle.vue";
import ImageSlider from "./ImageSlider.vue";
import ProductsApi from "../../models/ProductsApi";
import LineItemDeliveryStatusLabels from "../../constants/LineItemDeliveryStatusLabels";
import LineItemDeliveryStatuses from "../../constants/LineItemDeliveryStatuses";
import gtm from "../../utils/gtm";
import { VcModal } from "@holmgrensbil/vue-components";
import Axios from "../../axios";
import NestedOptionBox from "../shared/option-box/NestedOptionBox.vue";
import EonProductServicesModal from "../shared/ecom/EonProductServicesModal.vue";
import GtmEvents from "../../constants/GtmEvents";
import TyreSpec from '../../models/TyreSpec';

export default {
  components: {
    DeliveryStatusCircle,
    VcFeedbackMessage,
    VcOptionBox,
    VcPriceTag,
    EonProductBuyActions,
    EonProductTitle,
    ImageSlider,
    VcModal,
    NestedOptionBox,
    EonProductServicesModal
  },
  props: {
    productObject: Object,
    productUsps: String
  },
  data() {
    return {
      additionalServices: [],
      selectedServices: [],
      selectedSubOptions: [],
      productAdded: false,
      showPriceFor4: false,
      currentQuantity: null,
      showMoreMeta: false,
      departmentsModalOpen: false,
      modalContent: null,
      fetching: false,
      readMoreModalOpen: false,
      readMoreHeader: "",
      readMoreContent: null,
      servicesModalOpen: false,
      additionalServicesFetched: false,
      regNr: "",
    };
  },

  watch: {
    showPriceFor4(val) {
      const suffix = val ? " kr / 4 st" : " kr / st";
      this.additionalServices.forEach(s => {
        s.price.ordinary = val ? s.price.ordinary * 4 : s.price.ordinary / 4;
        s.price.current = val ? s.price.current * 4 : s.price.current / 4;
        s.price.suffix = suffix;
        s.subOptions.forEach(so => {
          so.price.ordinary = val
            ? so.price.ordinary * 4
            : so.price.ordinary / 4;
          so.price.current = val ? so.price.current * 4 : so.price.current / 4;
          so.price.suffix = suffix;
        });
      });
    }
  },
  computed: {
    shouldShowSCTButton() {
      return this.productObject.description.includes("SCT");
    },
    numberOfSites() {
      return window.EonConfig.internalSupplierIds.length;
    },
    product() {
      return new EonProduct(this.productObject);
    },

    lowestPartPaymentPrice() {
      return (
        this.product.lowestPartPaymentPrice(this.showPriceFor4 ? 4 : 1) * (this.showPriceFor4 ? 4 : 1)
      ).toFixed(0);
    },

    deliveryStatus() {
      if (this.currentQuantity) {
        return this.product.getDeliveryStatus(this.currentQuantity);
      }

      return LineItemDeliveryStatuses.None;
    },

    deliveryStatusLabel() {
      return LineItemDeliveryStatusLabels[this.deliveryStatus];
    },
    shouldShowRibbon() {
      return !this.product.discounted && this.currentQuantity >= 4 && this.product.isCompleteWheel;
    },
    shouldShowDiscountRibbon() {
      return this.product.discounted(this.currentQuantity);
    },
    allSelectedAdditionalServices() {
      return [...this.selectedServices, ...this.selectedSubOptions];
    },
    allSelectedAdditionalServiceObjects() {
      let services = [];
      const selectedServices = this.allSelectedAdditionalServices;
      this.additionalServices.forEach(s => {
        if (selectedServices.includes(s.id)) {
          services.push(s);
        }
        s.subOptions.forEach(so => {
          if (selectedServices.includes(so.id)) {
            services.push(so);
          }
        });
      });
      return services;
    }
  },
  created() {
    const tireData = this.product.tireData;
    const data = {
      detail: {
        products: [tireData]
      }
    };
    gtm.tireCardLoad(GtmEvents.Detail, data);
    if (!TyreSpec.isEmpty) {
      this.regNr = TyreSpec.regNr
    }
  },
  methods: {
    async fetchAdditionalServices(quantity) {
      try {
        const params = {
          productId: this.product.encodedId,
          quantity
        };
        const res = await ProductsApi.fetchAdditionalServices(params);

        const additionalServices = res.data;

        this.selectedServices = [];
        const updatedAdditionalServices = additionalServices.reduce((result, service) => {

            result.push({ ...service, suffix: '' });
          return result;
        }, []);
        // additionalServices.forEach(s => {
        //   s.price.suffix += " / st";
        //   s.subOptions.forEach(so => {
        //     so.price.suffix += " / st";
        //   });
        // });
        this.additionalServices = updatedAdditionalServices;
        this.additionalServicesFetched = true;
      } catch (e) {
        this.additionalServicesFetched = true;
        console.log("failed", e);
      }
    },

    async onOpenSitesClick() {
      try {
        const modal = await this.fetchSitesModal();
        this.modalContent = modal.data;
        this.departmentsModalOpen = true;
        this.fetching = false;
      } catch (e) {
        this.fetching = false;
      }
    },

    fetchSitesModal() {
      return Axios.get(
        `${this.product.permalink}/TyreCentersPartial?deliveryStatus=${this.deliveryStatus}`
      );
    },

    onQuantity({ value }) {
      this.currentQuantity = value;
      this.fetchAdditionalServices(value);
    },

    isServiceSelected(service) {
      return this.selectedServices.includes(service.id);
    },

    isSubOptionSelected(option) {
      return this.selectedSubOptions.includes(option.id);
    },

    onServiceClick(service) {
      if (this.selectedServices.includes(service.id)) {
        this.selectedServices = this.selectedServices.filter(
          s => s !== service.id
        );
      } else {
        this.selectedServices.push(service.id);
      }
    },
    onSubOptionClick(service, option) {
      if (this.selectedSubOptions.includes(option.id)) {
        this.selectedSubOptions = this.selectedSubOptions.filter(
          s => s !== option.id
        );
      } else {
        this.selectedSubOptions.push(option.id);
      }
    },

    onAdded() {
      this.productAdded = true;
      this.servicesModalOpen = true;
      const selectedServicesData = this.allSelectedAdditionalServiceObjects.map(
        s => {
          return {
            name: s.name,
            id: s.id,
            price: s.price.current,
            category: "Tilläggstjänst"
          };
        }
      );
      const gtmData = {
        currencyCode: "SEK",
        value: this.addedQuantity * this.product.tireData.price,
        add: {
          products: [
            ...selectedServicesData,
            ...[
              {
                ...{ quantity: this.currentQuantity },
                ...this.product.tireData
              }
            ]
          ]
        }
      };
      gtm.ecommerceEvent(GtmEvents.AddToCart, gtmData);
    },
    onReadMore(e, origin) {
      e.stopPropagation();
      this.readMoreModalOpen = true;
      this.readMoreHeader = origin.name;
      this.readMoreContent = origin.modalContent;
    },
    closeReadMore() {
      this.readMoreModalOpen = false;
      this.readMoreContent = null;
    }
  }
};
</script>
