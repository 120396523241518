export default {
  Booking: 'booking',
  Carfax: 'carfax',
  Contact: 'contact',
  Engagement: 'engagement',
  ServiceAgreement: 'serviceAgrement',
  TradeIn: 'tradeIn',
  ProductClick: 'productClick',
  RemoveFromCart: 'removeFromCart',
  AddToCart: 'addToCart',
  Checkout: 'checkout',
  Detail: 'detail',
  Impressions: 'impressions',
  Search: 'search',
}
