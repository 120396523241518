var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-configurator" },
    [
      _vm.contactModalOpen
        ? _c("VcModal", { on: { close: _vm.onCloseContactModal } }, [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _vm._v("\n      " + _vm._s("Ta kontakt") + "\n    ")
            ]),
            _vm._v(" "),
            _vm.loadingData
              ? _c(
                  "div",
                  {
                    staticClass:
                      "vehicle-configurator__modal vehicle-configurator__modal--loading",
                    attrs: { slot: "content" },
                    slot: "content"
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "vehicle-configurator__modal__loader",
                      attrs: { icon: _vm.faSpinnerThird, spin: true }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "vehicle-configurator__modal",
                    attrs: { slot: "content" },
                    slot: "content"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vehicle-configurator__modal__container" },
                      [
                        _vm.vehicleData && _vm.selectedFinancingOption
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-configurator__modal__column"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-configurator__modal__vehicle-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-configurator__modal__vehicle-image"
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: _vm.vehicleData.image,
                                            alt: ""
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-configurator__modal__vehicle-info"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-configurator__modal__vehicle-title"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.vehicleData.brandName +
                                                  " " +
                                                  _vm.vehicleData.modelName
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-configurator__modal__vehicle-sub-title"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.vehicleData.modelText)
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-configurator__modal__vehicle-data-container"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__modal__vehicle-data-item"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.vehicleData.condition +
                                                      " " +
                                                      _vm.vehicleData
                                                        .modelYear +
                                                      " • "
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__modal__vehicle-data-item"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.vehicleData.mileage +
                                                      " mil • "
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__modal__vehicle-data-item"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.vehicleData.fuelName +
                                                      " • "
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__modal__vehicle-data-item"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.vehicleData.gearboxName
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("Configurator", {
                                  attrs: {
                                    priceMetaString: _vm.priceMetaString,
                                    lang: _vm.lang,
                                    regNr: _vm.regNr,
                                    departments: _vm.departments,
                                    priceDisplay: _vm.priceDisplay,
                                    displayTax: _vm.displayTax,
                                    selectedFinancingOption:
                                      _vm.selectedFinancingOption
                                  },
                                  on: {
                                    onChangeFinanceOption:
                                      _vm.onChangeFinanceOption
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "vehicle-configurator__modal__column"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vehicle-configurator__contact-form"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vehicle-configurator__tab-bar tab-bar"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-configurator__tab-bar__item tab-bar__item",
                                        class: {
                                          "tab-bar__item--active":
                                            _vm.activeContactTab ===
                                            "contactForm"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.setContactTab(
                                              "contactForm"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Ta kontakt\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-configurator__tab-bar__item tab-bar__item",
                                        class: {
                                          "tab-bar__item--active":
                                            _vm.activeContactTab === "location"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.setContactTab("location")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Var finns bilen?\n              "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.activeContactTab === "contactForm"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-configurator__tab-container"
                                      },
                                      [
                                        _vm.done
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__feedback vehicle-configurator__feedback--success"
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: _vm.faCircleCheck
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__feedback__title"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.lang.Summation
                                                              .FeedbackHeader
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__feedback__text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.lang.Summation
                                                              .FeedbackText
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-configurator__contact-form__input-section"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__text-input-headline"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s("E-post") +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "ConfiguratorTextInput",
                                                        {
                                                          attrs: {
                                                            inputKey: "email",
                                                            placeholder:
                                                              "E-postadress",
                                                            type: "email"
                                                          },
                                                          on: {
                                                            onChange: function(
                                                              input
                                                            ) {
                                                              return (_vm.contactEmail = input)
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-configurator__contact-form__input-section"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__text-input-headline"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            "Telefonnummer"
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "ConfiguratorTextInput",
                                                        {
                                                          attrs: {
                                                            inputKey:
                                                              "phonenumber",
                                                            placeholder:
                                                              "Telefonnummer",
                                                            type: "tel"
                                                          },
                                                          on: {
                                                            onChange: function(
                                                              input
                                                            ) {
                                                              return (_vm.contactPhoneNumber = input)
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vehicle-configurator__contact-form__input-section"
                                                },
                                                [
                                                  _c("ConfiguratorSubSelect", {
                                                    attrs: {
                                                      options:
                                                        _vm.contactOptions,
                                                      heading:
                                                        "Vad ser du som nästa steg?",
                                                      defaultValue:
                                                        _vm.selectedContactOption
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.onChangeContactOption
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.selectedContactOption ===
                                              _vm.ContactRequestTypes
                                                .AskQuestion
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__contact-form__input-section"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "vehicle-configurator__text-input-headline"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                "Meddelande"
                                                              ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "ConfiguratorTextInput",
                                                            {
                                                              attrs: {
                                                                isLongText: "",
                                                                inputKey:
                                                                  "textarea",
                                                                placeholder:
                                                                  "Skriv ditt meddelande..."
                                                              },
                                                              on: {
                                                                onChange: function(
                                                                  input
                                                                ) {
                                                                  return (_vm.contactText = input)
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.selectedContactOption ===
                                                _vm.ContactRequestTypes
                                                  .BookMeeting ||
                                              _vm.selectedContactOption ===
                                                _vm.ContactRequestTypes
                                                  .TestDrive
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__contact-form__input-section"
                                                    },
                                                    [
                                                      _c(
                                                        "ConfiguratorDateSelect",
                                                        {
                                                          attrs: {
                                                            heading:
                                                              "Önskat datum?",
                                                            defaultPlaceholder:
                                                              "Välj önskat datum"
                                                          },
                                                          on: {
                                                            change: function(
                                                              date
                                                            ) {
                                                              return (_vm.contactDate = date)
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.selectedContactOption ===
                                                _vm.ContactRequestTypes
                                                  .BookMeeting ||
                                              _vm.selectedContactOption ===
                                                _vm.ContactRequestTypes
                                                  .TestDrive
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__contact-form__input-section"
                                                    },
                                                    [
                                                      _c(
                                                        "ConfiguratorSubSelect",
                                                        {
                                                          attrs: {
                                                            options:
                                                              _vm.contactTimeOptions,
                                                            heading:
                                                              "Önskad tid",
                                                            defaultValue:
                                                              _vm.contactTime
                                                          },
                                                          on: {
                                                            change: function(
                                                              time
                                                            ) {
                                                              return (_vm.contactTime =
                                                                time.value)
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn--primary btn--block",
                                                  attrs: {
                                                    disabled:
                                                      _vm.loading ||
                                                      !_vm.contactFormValid
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.submitContactRequest
                                                  }
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "vehicle-configurator__btn-icon",
                                                    attrs: {
                                                      icon: _vm.loading
                                                        ? _vm.faSpinnerThird
                                                        : _vm.faEnvelope,
                                                      spin: _vm.loading
                                                    }
                                                  }),
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        "Skicka meddelande"
                                                      ) +
                                                      "\n                "
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "form-text" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "\n                    Alla personuppgifter som skickas in till Holmgrens Bil kommer att\n                    behandlas enligt bestämmelserna i EU:s dataskyddsförordningen\n                    (GDPR). "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "text-decoration":
                                                            "underline"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              title:
                                                                "Läs om hur vi behandlar din personuppgifter",
                                                              href:
                                                                "/om-oss/personuppgiftspolicy"
                                                            }
                                                          },
                                                          [_vm._v("Här")]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " kan du läsa mer om hur vi behandlar dina personuppgifter.\n                  "
                                                    )
                                                  ])
                                                ]
                                              )
                                            ])
                                      ]
                                    )
                                  : _vm.activeContactTab === "location"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vehicle-configurator__tab-container"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-configurator__department-container"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__department"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vehicle-configurator__department__title"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.contact
                                                            .departmentName
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vehicle-configurator__department__adress"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.contact
                                                            .departmentAddress +
                                                            ", " +
                                                            _vm.contact
                                                              .departmentPostCode +
                                                            ", " +
                                                            _vm.contact
                                                              .departmentCity
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "link link--underline vehicle-configurator__link",
                                                    attrs: {
                                                      href:
                                                        _vm.contact
                                                          .directionsUrl,
                                                      target: "_blank"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.onDirectionsClick
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Vägbeskrivning\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__department-map",
                                                style:
                                                  "background-image: url(" +
                                                  _vm.contact
                                                    .googleMapsImageUrl +
                                                  ")"
                                              },
                                              [
                                                _c("a", {
                                                  attrs: {
                                                    href:
                                                      _vm.contact.directionsUrl,
                                                    target: "_blank"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "btn btn--primary btn--block",
                                            attrs: {
                                              href: _vm.showNumber
                                                ? "tel:" +
                                                  _vm.departmentPhoneNumber
                                                : "#"
                                            },
                                            on: { click: _vm.onPhoneClick }
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass:
                                                "vehicle-configurator__btn-icon",
                                              attrs: { icon: _vm.faPhone }
                                            }),
                                            _vm._v(" "),
                                            !_vm.showNumber
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        "Visa telefonnummer"
                                                      ) +
                                                      "\n                "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.departmentPhoneNumber
                                                      ) +
                                                      "\n                "
                                                  )
                                                ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vehicle-configurator__department-links"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vehicle-configurator__department-links__item"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "opening-hours"
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "vehicle-configurator__department-links__icon vehicle-configurator__btn-icon opening-hours__clock",
                                                      attrs: {
                                                        icon: _vm.faClock
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "opening-hours__container"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.displayOpeningHours = !_vm.displayOpeningHours
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm.contact
                                                              .openingHours
                                                              .isOpenNow
                                                              ? _c("span", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "opening-hours__indicator"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Öppet"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " •\n                        "
                                                                  )
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "link link--underline vehicle-configurator__link"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.getNextOpeningStatus(
                                                                          _vm
                                                                            .contact
                                                                            .openingHours
                                                                            .dates[0],
                                                                          _vm
                                                                            .contact
                                                                            .openingHours
                                                                            .dates[1]
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                staticClass:
                                                                  "vehicle-configurator__department-links__chevron",
                                                                attrs: {
                                                                  icon: _vm.displayOpeningHours
                                                                    ? _vm.faChevronUp
                                                                    : _vm.faChevronDown
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.displayOpeningHours
                                                          ? _c(
                                                              "div",
                                                              _vm._l(
                                                                _vm.contact
                                                                  .openingHours
                                                                  .dates,
                                                                function(date) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        date.day +
                                                                        "-" +
                                                                        date.month +
                                                                        "-opening-hour-date",
                                                                      staticClass:
                                                                        "opening-hours__row"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                date.description +
                                                                                  " " +
                                                                                  date.day +
                                                                                  "/" +
                                                                                  date.month
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                date.isClosed
                                                                                  ? "Stängt"
                                                                                  : date.openTime +
                                                                                      " - " +
                                                                                      date.closeTime
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      _vm.contact.departmentUrl,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__department-links__item"
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "vehicle-configurator__department-links__icon vehicle-configurator__btn-icon",
                                                        attrs: {
                                                          icon: _vm.faHome
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "vehicle-configurator__department-links__link"
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "link link--underline vehicle-configurator__link"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Gå till avdelningen"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.contact.reviewUrl,
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "vehicle-configurator__department-links__item"
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "vehicle-configurator__department-links__icon vehicle-configurator__btn-icon",
                                                        attrs: {
                                                          icon: _vm.faStar
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "vehicle-configurator__department-links__link"
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "link link--underline vehicle-configurator__link"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.lang
                                                                    .Contact
                                                                    .GiveReview
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }