<template>
  <div class="service-agreement-block">
    <CarInfo />
    <AdditionalCarInfo v-if="store.lookupResult" />
    <Instances v-if="store.hasInstances" />
    <Addons v-if="store.hasAdditionalProducts" />
    <Summary v-if="store.hasInstances" />
    <Customer v-if="store.shouldShowCustomerInfo" />
  </div>
</template>

<script>
import CarInfo from './CarInfo.vue';
import Customer from './Customer.vue';
import AdditionalCarInfo from './AdditionalCarInfo.vue';
import Instances from './Instances.vue';
import Addons from './Addons.vue';
import Summary from './Summary.vue';
import store from './store';

export default {
  components: {
    CarInfo,
    Customer,
    AdditionalCarInfo,
    Instances,
    Addons,
    Summary
  },
  data() {
    return {
      store: store.state
    }
  },

  methods: {

  }
}
</script>
