import { EventBus } from '../utils/event-bus';
export default {
  init() {
    this.addEvents();
  },

  addEvents() {
    $(document).on('click', '.js-scroll-to', this.onScrollToClick);
    $(document).on('click', '.js-scroll-to-with-header', this.onScrollToClickWithHeaderOffset);
    $(document).on('click', '.scroll-to-top', this.onScrollToTop)
  },

  onScrollToClick(e) {
    e.preventDefault();
    const $this = $(this);
    if($this.data('scroll-target') && $($this.data('scroll-target')).length) {
      const $scrollTarget =$($this.data('scroll-target'));
      $([document.documentElement, document.body]).animate({
        scrollTop: $($scrollTarget).offset().top,
      });
    }
  },

  onScrollToClickWithHeaderOffset(e) {
    e.preventDefault();
    const $this = $(this);
    const sectionType = $this.data('section');
    if (sectionType === 'purchase' || sectionType === 'contact') {
      EventBus.$emit('set-section-type', sectionType)
    }
    if($this.data('scroll-target') && $($this.data('scroll-target')).length) {
      const $scrollTarget =$($this.data('scroll-target'));
      $([document.documentElement, document.body]).animate({
        scrollTop: $($scrollTarget).offset().top - 106,
      });
    }
  },

  onScrollToTop(e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: 0
    }, 400);
  }
}
