<template>
  <div class="configurator-section trade-in-selector">
    <div class="configurator-section__title-container">
      <h4 class="configurator-section__title">
        <i class="fal fa-cars configurator-section__icon"></i>
        {{ tradeInData.heading }}
      </h4>
      <a
        v-if="tradeInData.informationModalUrl"
        class="link link--muted-light link--underline"
        href="#"
        :data-target="tradeInData.informationModalUrl"
        data-modal-source="ajax"
      >
        {{ lang.SiteGeneral.ReadMore }}
      </a>
    </div>
    <div v-if="!fetchedValuation">
      <VcOptionBox
        :selected="!hasTradeInVehicle"
        :heading="tradeInData.denyOption"
        @click="onHasTradeInVehicle(false)"
      ></VcOptionBox>
      <ConfiguratorOptionBox
        :selected="hasTradeInVehicle"
        :heading="tradeInData.acceptOption"
        :hasSubOption="true"
        @click="onHasTradeInVehicle(true)"
        :yellowBg="hasTradeInVehicle"
        :lang="lang"
      >
        <div slot="content">
          <div class="trade-in-selector__input">

            <div class="form-row">
              <div class="regnr-input">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="regnr-input__symbol"></div>
                  </div>
                  <div class="form-stack">
                    <label>
                      {{ lang.Summation.SummationVehicleRegistration }}
                    </label>
                    <input
                      id="regNr-input"
                      type="text"
                      class="form-control"
                      placeholder="ABC123"
                      required
                      pattern="^\w{3}\d{2}(\w{1}|\d{1})$"
                      v-model="regNr"
                    />
                  </div>
                </div>
              </div>
              <div class="trade-in-selector__small-input">
                <div class="form-stack">
                  <label>
                    {{ lang.Summation.SummationVehicleMileage }}
                  </label>
                  <input
                    id="mileage"
                    type="text"
                    class="form-control"
                    placeholder="Ange miltal"
                    required
                    v-model="mileage"
                  />
                </div>
              </div>
            </div>
            <button @click="appraiseVehicle" class="btn btn--primary">
              {{ lang.Summation.TradeInAction }}
            </button>
          </div>
        </div>
      </ConfiguratorOptionBox>
    </div>
    <div v-else class="configurator-section__completed">
      <div class="details-bar">
        <div class="details-bar__item-list">
          <div class="details-bar__item">
            <div class="details-bar__title">
              {{ lang.Summation.SummationVehicleRegistration }}
            </div>
            <div class="details-bar__value details-bar__value--uppercase">
              {{ regNr }}
            </div>
          </div>
          <div class="details-bar__item">
            <div class="details-bar__title">
              {{ lang.Summation.SummationVehicleBrand }}
            </div>
            <div class="details-bar__value">
              {{ fetchedValuation.brandName }}
            </div>
          </div>
          <div class="details-bar__item">
            <div class="details-bar__title">
              {{ lang.Summation.SummationVehicleModel }}
            </div>
            <div class="details-bar__value">
              {{ `${fetchedValuation.modelName} ${fetchedValuation.version}` }}
            </div>
          </div>
          <div class="details-bar__item">
            <div class="details-bar__title">
              {{ lang.Summation.SummationVehicleYear }}
            </div>
            <div class="details-bar__value">
              {{ fetchedValuation.modelYear }}
            </div>
          </div>
          <div class="details-bar__item">
            <div class="details-bar__title">
              {{ lang.Summation.SummationVehicleMileage }}
            </div>
            <div class="details-bar__value">
              {{ fetchedValuation.mileage }}
            </div>
          </div>
        </div>
        <div class="details-bar__action" @click="removeValuation">
          {{ lang.Summation.RemoveSelection }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VcOptionBox } from "@holmgrensbil/vue-components";
import ConfiguratorOptionBox from "./ConfiguratorOptionBox.vue";
import Axios from "../../axios";
import store from "./store";
import gtm from "../../utils/gtm";

export default {
  components: {
    ConfiguratorOptionBox,
    VcOptionBox
  },
  props: {
    tradeInData: Object,
    lang: Object,
    currentVehicleRegNr: String,
    department: Number,
  },
  data() {
    return {
      hasTradeInVehicle: false,
      regNr: "",
      mileage: "",
      email: "",
      modalContent: "",
      fetchedValuation: null,
      requestSent: false,
    };
  },
  async created() {
    try {
      const { data } = await Axios.get(this.tradeInData.informationModalUrl);
      this.modalContent = data;
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    onHasTradeInVehicle(hasTradeInVehicle) {
      this.hasTradeInVehicle = hasTradeInVehicle;
    },
    removeValuation() {
      this.fetchedValuation = null;
      store.setValuation(null);
    },
    async appraiseVehicle() {
      if (this.mileage === "" || this.regNr === "")
        return false;
      try {
        const { data } = await Axios.get(
          `/api/vehicleConfigurator/${this.currentVehicleRegNr}/tradeIn?mileage=${this.mileage}&tradeInRegistrationNumber=${this.regNr}&email=${this.email}`
        );
        this.fetchedValuation = data;
        store.setValuation(data);
        gtm.configuratorQuickTradeInActivate();
      } catch (e) {
        console.error(e);
      }
    },
    async submitTradeIn() {
      if (this.email) {
        try {
          const data = await Axios.post('/api/vehicle/SaveTradeinRequest', {
            registrationNumber: this.regNr,
            customerEmail: this.email,
            mileage: this.mileage,
          });
          this.requestSent = true;

          gtm.valuationFormSubmit({
            brandName: this.fetchedValuation.brandName,
            vehicleType: window.vehicleData.vehicleType,
            departmentNr: this.department,
            vehicleCondition: 'Begagnad',
          })
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }
};
</script>
