<template>
  <div>
    <SectionField :type="FieldTypes.Message">
    </SectionField>
    <SectionField :type="FieldTypes.Images">
    </SectionField>
  </div>
</template>

<script>
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';

export default {
  components: {
    SectionField
  },
  data() {
    return {
      FieldTypes,
    };
  },
};
</script>
