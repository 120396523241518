var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "choose-spec" },
    [
      _c("div", { staticClass: "choose-spec__content" }, [
        !_vm.vehicleSelected
          ? _c("div", [
              _c("div", [
                _c("div", { staticClass: "regnr-input" }, [
                  _c("div", { staticClass: "input-group" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-stack" }, [
                      _c("label", [
                        _vm._v(
                          "\n                        Regnummer\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regNr,
                            expression: "regNr"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "regNr-input",
                          type: "text",
                          placeholder: "ABC123"
                        },
                        domProps: { value: _vm.regNr },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.regNr = $event.target.value
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary regnr-input-sibling",
                    attrs: { disabled: !_vm.vehicleData },
                    on: {
                      click: function() {
                        return _vm.onFindProducts()
                      }
                    }
                  },
                  [_vm._v("\n            Hitta produkter\n        ")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "choose-spec__options" }, [
                _c("div", { staticClass: "choose-spec__options-header" }, [
                  _vm._v("\n          Har du inget regnummer?\n        ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "inline-bullets" }, [
                  _c(
                    "div",
                    {
                      staticClass: "inline-bullets__item",
                      on: {
                        click: function($event) {
                          _vm.manualSelectModalOpen = true
                        }
                      }
                    },
                    [
                      _c("a", { staticClass: "link link--underline" }, [
                        _vm._v("Välj bilmodell")
                      ])
                    ]
                  )
                ])
              ])
            ])
          : _c("div", [
              _c("div", { staticClass: "choose-spec__options-header" }, [
                _vm._v(
                  "\n        Valt fordon: " +
                    _vm._s(_vm.vehicleData) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "inline-bullets" }, [
                _c(
                  "div",
                  {
                    staticClass: "inline-bullets__item",
                    on: { click: _vm.clearVehicle }
                  },
                  [
                    _c("a", { staticClass: "link link--underline" }, [
                      _vm._v("Rensa fordon")
                    ])
                  ]
                )
              ])
            ])
      ]),
      _vm._v(" "),
      _vm.manualSelectModalOpen
        ? _c("ManualVehicleSelect", {
            attrs: {
              modalHeader: "Välj bilmodell",
              buttonText: "Se produkter"
            },
            on: {
              setCarManually: _vm.setCarManually,
              modalClose: function($event) {
                _vm.manualSelectModalOpen = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }