<template>
  <div class="product-color"
    :class="{ 'product-color--selected': filter.selected }"
    @click.stop="$emit('select', filter)">
    <div v-if="colorClass" class="product-color__gradient" :class="[`product-color__gradient--${colorClass}`]">
    </div>
    <div v-else-if="filter.displayName === 'Flerfärgad'" class="product-color__gradient product-color__gradient--multi-colored">
    </div>
    <i v-else class="fas fa-question product-color__icon"></i>
    <div class="product-color__name">
      {{filter.displayName}}
    </div>
  </div>
</template>

<script>
const colorMap = {
  'Vit': 'white',
  'Grå/Silver': 'silver',
  'Brun/Beige': 'brown',
  'Svart': 'black',
  'Röd/Orange': 'red',
  'Gul': 'yellow',
  'Grön': 'green',
  'Blå/Lila': 'blue',
};

export default {
  props: {
    filter: Object,
  },
  computed: {
    colorClass() {
      return colorMap[this.filter.displayName];
    },
  },
}
</script>
