<template>
  <div class="i3-sort form-select">
    <select class="vehicle-listing-filter__sort__select" v-model="selectedQuery" @change="onChange">
      <option value="">Sortera</option>
      <option v-for="opt in sortOptions" :key="opt.query" :value="opt.query">
        {{opt.displayName}}
      </option>
    </select>
  </div>
</template>

<script>
import ChannelEvents from '../../constants/ChannelEvents';
import I3QueryParams from '../../constants/I3QueryParams';
import ChannelQuery from '../../models/ChannelQuery';
import Cookies from 'js-cookie';

export default {
  props: {
    channel: Object,
  },
  data() {
    return {
      sortOptions: [],
      selectedQuery: '',
    };
  },
  created() {
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  methods: {
    onQuerySuccess() {
      this.sortOptions = this.channel.data.documents.sortOptions || [];
      this.setSelectedQuery();
    },

    onChange() {
      let query;

      if (this.selectedQuery === '') {
        query = new ChannelQuery(this.channel.lastQuery);
        query.unsetParam(I3QueryParams.Sort);
      } else {
        query = new ChannelQuery(this.selectedQuery);
      }
      
      Cookies.set(`sortOrder_${this.channel.ref}`, query.getParam(I3QueryParams.Sort));

      this.channel.execute(query);
      this.channel.emit(ChannelEvents.SendSearchSignal);
    },

    setSelectedQuery() {
      const selected = this.sortOptions.find(o => o.selected);
      if (selected) {
        this.selectedQuery = selected.query;
      }
    },
  },
}
</script>
