<template>
  <div
    class="summation-list"
    :class="{ 'summation-list--sub-list': isSubList }"
  >
    <div v-for="row in rows" :key="row.name" class="summation-list__row">
      <a
        v-if="row.nameLink"
        class="text link link--primary"
        :data-target="row.nameLink"
        data-modal-source="ajax"
        >{{ row.name }}</a
      >
      <div v-else class="text">
        {{ row.name }}
      </div>

      <div v-if="row.value && row.discount" class="text text--value">
        <span v-if="row.ordinaryValue" class="text--obsolete">
          {{ `${row.ordinaryValue}${row.suffix || ""}` }}
        </span>
        &nbsp;
        <span class="text--discount">
          {{ `${row.value}${row.suffix || ""}` }}
        </span>
      </div>
      <div v-else-if="row.value" class="text text--value">
        {{ `${row.value}${row.suffix || ""}` }}
      </div>
    </div>
    <div
      v-if="collapsible"
      class="link link--primary link--icon"
      @click="onToggleCollapse"
    >
      <span v-show="isCollapsed">Visa alla detaljer</span>
      <span v-show="!isCollapsed">Dölj alla detaljer</span>
      <i v-show="isCollapsed">
        <i class="fal fa-angle-down"></i>
      </i>
      <i v-show="!isCollapsed">
        <i class="fal fa-angle-up"></i>
      </i>
    </div>
  </div>
</template>

<script>
import FinanceOptions from '../../constants/FinanceOptions';
export default {
  props: {
    list: Array,
    financing: Object,
    selectedFinancing: String,
    isSubList: Boolean,
    lang: Object
  },
  data() {
    return {
      isCollapsed: true
    };
  },
  computed: {
    collapsible() {
      const list = this.financing ? this.financingList : this.list;
      return list.length > 4;
    },
    rows() {
      const list = this.financing ? this.financingList : this.list;
      return this.isCollapsed ? list.slice(0, 4) : list;
    },
    financingList() {
      let monthlyCostName = "";
      let downPaymentName = "";

      switch (this.selectedFinancing) {
        case FinanceOptions.INSTALLMENT: {
          monthlyCostName = this.lang.Summation.SummationListInstallment;
          downPaymentName = this.lang.Summation.SummationListDownPayment;
          break;
        }
        case FinanceOptions.LEASING:
        case FinanceOptions.OPLEASING:
        case FinanceOptions.BLLEASING: {
          monthlyCostName = this.lang.Summation.SummationListLeasing;
          downPaymentName = this.lang.Summation.SummationListLeasingDownPayment;
          break;
        }
        case FinanceOptions.PRIVATELEASING: {
          monthlyCostName = this.lang.Summation.SummationListPrivateLeasing;
          downPaymentName = this.lang.Summation.SummationListCash;
          break;
        }
      }
      const list = [
        {
          name: monthlyCostName,
          value: this.$formatPriceNoSuffix(this.financing["monthlyCost"]),
          suffix: " kr/månad"
        },

        {
          name: this.lang.Summation.SummationListCash,
          value: this.$formatPriceNoSuffix(this.financing["currentPrice"]),
          suffix: " kr"
        },

        {
          name: this.lang.Summation.SummationListPeriod,
          value: this.$formatPriceNoSuffix(this.financing["period"]),
          suffix: " månader"
        },

        {
          name: downPaymentName,
          value: this.$formatPriceNoSuffix(this.financing["downpayment"]),
          suffix: " kr"
        },

        {
          name: this.lang.Summation.SummationListInterest,
          value: this.$formatPriceNoSuffix(this.financing["rate"]),
          suffix: "%",
          discount: this.financing["ordinaryRate"] > 0,
          ordinaryValue: this.financing["ordinaryRate"]
        },
        {
          name: this.lang.Summation.SummationListEffectiveRate,
          value: this.$formatPriceNoSuffix(this.financing["effectiveRate"]),
          suffix: "%"
        },
        {
          name: this.lang.Summation.SummationListArrangementFee,
          value: this.$formatPriceNoSuffix(this.financing["arrangementFee"]),
          suffix: " kr"
        },
        {
          name: this.lang.Summation.SummationListRegistrationFee,
          value: this.$formatPriceNoSuffix(this.financing["registrationFee"]),
          suffix: " kr"
        },
        {
          name: this.lang.Summation.SummationListAdministrationFee,
          value: this.$formatPriceNoSuffix(this.financing["administrationFee"]),
          suffix: " kr/mån"
        },
        {
          name: this.lang.Summation.SummationListResidualValue,
          value: this.$formatPriceNoSuffix(this.financing["residualValue"]),
          suffix: " kr"
        },

        {
          name: this.lang.Summation.SummationListMileage,
          value: this.$formatPriceNoSuffix(this.financing["mileage"]),
          suffix: " mil/år"
        }
      ].filter(item => item.value);

      return list;
    }
  },
  methods: {
    onToggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
</script>

<style></style>
