<template>
  <div
    class="configurator-select__department-item"
    :key="option.name"
    @click="$emit('click', option)">
    <div
      class="configurator-select__department-item__name-container"
      :class="{
        'configurator-select__department-item__name-container--selected': selected
      }"
    >
      <font-awesome-icon v-if="selected" class="configurator-select__department-item__check" :icon="faCheck"></font-awesome-icon>
      {{ option.name }}
    </div>
    <div class="configurator-select__availability-container">
      <div
        class="delivery-indicator"
        :class="{
          'delivery-indicator--available': available,
          'delivery-indicator--unavailable': !available,
        }"
      ></div>
      <div>{{ available ? 'Omgående' : '3-7 dagar' }}</div>
    </div>
  </div>
</template>

<script>
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  props: {
    option: Object,
    selected: Boolean,
    available: Boolean,
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faCheck,
    }
  },
}
</script>