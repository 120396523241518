<template>
  <div class="configurator-section finance-selector">
    <div>
      <div v-for="option in financeOptions" :key="option.id" class="form-row">
        <div class="form-row__col">
          <ConfiguratorOptionBox :selected="selected.id === option.id" :calculationType="option.calculationType"
            :heading="option.name" :smallText="option.nameSuffix" :financing="option.financing" :lang="lang"
            :modalContent="option.modalContent" :ribbonText="option.ribbonText"
            @click="$emit('change-finance-option', option)"></ConfiguratorOptionBox>
          <div v-if="selected.id === option.id">
            <div class="form-row configurator-section__input-row"
              v-if="option.calculationType === CalculationKeys.Monthly">
              <ConfiguratorSubSelect :options="downPaymentOptions.values" :heading="downPaymentOptions.heading"
                @change="(val) => onChangeDownPayment(val, option)"
                :defaultValue="parseInt(option.financing.selectedDownPayment)" />
              <ConfiguratorSubSelect v-if="option.financing.periods" :options="periodOptions(option.financing.periods)"
                :heading="'Avtalsperiod'" @change="(val) => onChangePeriod(val, option)"
                :defaultValue="option.financing.periods.find(p => p.selected).months || null" />
            </div>
            <div v-if="option.calculationType !== CalculationKeys.Set" class="configurator-section__summary">
              <SummationList :lang="lang" :selectedFinancing="selected" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfiguratorOptionBox from "../configurator-components/ConfiguratorOptionBox.vue";
import SubOptionsToggle from "../../shared/option-box/SubOptionsToggle.vue";
import SubOptionsSelect from "../../shared/option-box/SubOptionsSelect.vue";
import FinanceOptions from '../../../constants/FinanceOptions';
import ConfiguratorSubSelect from "../configurator-components/ConfiguratorSubSelect.vue";
import SummationList from "../configurator-components/SummationList.vue";
import CalculationKeys from "../constants/calculation-keys";

export default {
  components: {
    ConfiguratorOptionBox,
    SubOptionsToggle,
    SubOptionsSelect,
    SummationList,
    ConfiguratorSubSelect
  },
  props: {
    financeOptions: Array,
    selected: Object,
    displayTax: Boolean,
    lang: Object
  },
  data() {
    return {
      FinanceOptions,
      selectedDownPayment: 0,
      financingSettings: {
        installment: {
          period: {},
          downPayment: 0
        },
        leasing: {
          period: {},
          downPayment: 0
        }
      },
      CalculationKeys,
    };
  },
  computed: {
    downPaymentOptions() {
      const list = [];
      if (
        this.selected &&
        this.selected.id !== FinanceOptions.CASH &&
        this.selected.id !== FinanceOptions.PRIVATELEASING &&
        this.selected.id !== FinanceOptions.OPLEASING &&
        this.selected.id !== FinanceOptions.BLLEASING
      ) {
        const selectedPeriod = this.selected.financing.periods.find(p => p.selected);
        const minimumDownPayment = this.selected.financing.minDownPayment;
        const downPaymentStep = this.selected.financing.downPaymentStep ?? 10;
        const maximumDownPayment =
          selectedPeriod.residualPrecentage === 0
            ? 80
            : 100 - selectedPeriod.residualPrecentage || 0;
        if (minimumDownPayment === 0 && maximumDownPayment === 0) {
          list.push(0);
        } else {
          let downPayment;
          for (
            downPayment = minimumDownPayment;
            downPayment <= maximumDownPayment - 10;
            downPayment = downPayment + downPaymentStep
          ) {
            list.push(downPayment);
          }
        }
      }

      return {
        heading: this.selected.id === FinanceOptions.INSTALLMENT ? 'Kontantinsats' : 'Förhöjd första hyra',
        required: true,
        values: list.map((v, i) => ({
          name: `${v}%`,
          value: v,
          selected: i === 0
        }))
      };
    }
  },
  methods: {
    onChangePeriod(val, option) {
      option.financing.periods.map(period => period.months === val.value ? period.selected = true : period.selected = false);
    },
    onChangeDownPayment(val, option) {
      option.financing.selectedDownPayment = Number(val.value);
    },
    periodOptions(optionList) {
      return optionList.map(period => {
        return {
          name: period.name,
          value: period.months,
          selected: period.selected
        };
      });
    }
  }
};
</script>
