<template>
  <div>
    <label>
      {{ lang.Checkout.CustomerDetails.Ssn.Title }}
    </label>

    <div class="input-group">
      <SectionField v-for="field in ssnLookupFields"
        :key="field.type"
        :field="field"
        :invalid="displayFieldInvalid(field)"
        @blur="onFieldBlur(field)"
        @change="onChange">
      </SectionField>

      <div class="input-group-append">
        <button class="btn btn--primary btn--sm"
          :class="{'disabled': ssnLookupLoading}"
          @click="onAutofillClick">
          {{ lang.Checkout.CustomerDetails.Ssn.Label }}
        </button>
      </div>
    </div>

    <a href="#" class="checkout__why-ssn link u-c-muted" :data-target="checkoutGovernmentIdModal" data-modal-source="ajax">Varför behöver vi personnummer <i class="far fa-question-circle"></i></a>

    <VcFeedbackMessage v-if="displaySsnInvalid" danger slim>
      {{ lang.Checkout.CustomerDetails.Ssn.Error }}
    </VcFeedbackMessage>

    <label>
      {{ lang.Checkout.CustomerDetails.Content.Title }}
    </label>

    <div class="form-row">
      <SectionField v-for="field in contactDetailsFields"
        :key="field.type"
        :field="field"
        :invalid="displayFieldInvalid(field)"
        class="form-row__col form-row__col--md-50"
        @blur="onFieldBlur(field)"
        @change="onChange">
      </SectionField>
    </div>
  </div>
</template>

<script>
import { VcFeedbackMessage } from '@holmgrensbil/vue-components';
import CartApi from '../../models/CartApi';
import { mapState, mapActions, mapGetters } from 'vuex';
import RequestStates from '../../constants/RequestStates';
import SectionTypes from './constants/SectionTypes';
import SectionField from './SectionField.vue';
import FieldTypes from './constants/FieldTypes';
import SessionStorageUtil from '../../utils/session-storage';

export default {
  components: {
    VcFeedbackMessage,
    SectionField,
  },
  props: {
    lang: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      displaySsnInvalid: false,
      validateFields: [],
    };
  },
  watch: {
    ssnLookupState(newVal) {
      if(newVal === RequestStates.Success) {
        this.makeReadOnly();
      }
    }
  },
  computed: {
    ...mapState({
      ssnLookupState: state => state.ssnLookup.fetchState,
    }),

    ...mapGetters({
      fieldByType: 'fields/byType',
      fieldsBySection: 'fields/bySection',
      invalidFields: 'fields/invalid',
    }),

    contactDetailsFields() {
      return this.fieldsBySection(SectionTypes.ContactDetails);
    },

    readOnlyFields() {
      return [
        this.fieldByType(FieldTypes.FirstName),
        this.fieldByType(FieldTypes.LastName),
        this.fieldByType(FieldTypes.Address),
        this.fieldByType(FieldTypes.Zip),
        this.fieldByType(FieldTypes.City),
        this.fieldByType(FieldTypes.CareOf),
      ];
    },

    ssnLookupFields() {
      return this.fieldsBySection(SectionTypes.SsnLookup);
    },

    ssnLookupLoading() {
      return this.ssnLookupState === RequestStates.Request;
    },

    ssnField() {
      return this.fieldByType(FieldTypes.Ssn);
    },

    invalidSsnField() {
      return this.invalidFields.find(f => f.type === FieldTypes.Ssn);
    },

    checkoutGovernmentIdModal() {
      return window.EonConfig.checkoutGovernmentIdModal;
    }
  },
  methods: {
    ...mapActions({
      ssnLookupAutofill: 'ssnLookup/autofill',
    }),

    makeReadOnly() {
      this.readOnlyFields.forEach(field => field.readonly = true);
    },

    displayFieldInvalid(field) {
      return this.validateFields.includes(field.type) && this.isInvalidField(field);
    },

    isInvalidField(field) {
      return !!this.invalidFields.find(f => f.type === field.type);
    },

    onFieldBlur(field) {
      if (this.validateFields.includes(field.type)) {
        return;
      }

      this.validateFields.push(field.type);
    },

    onAutofillClick() {
      this.displaySsnInvalid = false;

      if (this.invalidSsnField) {
        this.displaySsnInvalid = true;
        return;
      }

      this.ssnLookupAutofill(this.ssnField.value);
    },
    async onChange(e) {
      const fields = await SessionStorageUtil.get('checkout-customerDetails') || {};

      await SessionStorageUtil.set('checkout-customerDetails', {
        ...fields,
        [e.type]: {
          type: e.type,
          value: e.value,
        },
      });

      if (e.type === FieldTypes.Ssn) {
        this.$emit('ssnChange', e.value)
      }
    },
  }
}
</script>
