export default {
  ApiKey: "apiKey",
  Database: "customVehicleDatabase",
  Version: "version",
  Page: "page",
  Limit: "limit",
  SortBy: "sortBy",
  VehicleId: "vehicleId",
  LicensePlate: "licenseplate",
  Query: "query",
  SearchMode: "searchMode",
  Width: "width",
  AspectRatio: "aspectRatio",
  Diameter: "diameter",
  ProductType: "typeId",
  TyreType: "tyreType",
  RimType: "rimType",
  Quality: "quality",
  WetGrip: "wetGrip",
  RollingResistance: "rollingResistance",
  Noise: "noiseEmissionDecibel",
  Speed: "speedIndex",
  Load: "loadIndex",
  Condition: "condition",
  ApprovalMark: "carApprovalMark",
  TestScore: "minimumTestScore",
  FixedBore: "isFixedCentreBore",
  WinterCertified: "isWinterCertified",
  Runflat: "isRunflat",
  Enforced: "isEnforced",
  MinQuantityInStock: "minQuantityInStock",
  BrandId: "brandId"
};
