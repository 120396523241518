import Contact from "../apps/contact";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: Contact,
      selector: '.site-content [data-app=contact]',
    });
  },
};
