var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-section trade-in-selector" }, [
    _c("div", { staticClass: "configurator-section__title-container" }, [
      _c("h4", { staticClass: "configurator-section__title" }, [
        _c("i", { staticClass: "fal fa-cars configurator-section__icon" }),
        _vm._v("\n      " + _vm._s(_vm.tradeInData.heading) + "\n    ")
      ]),
      _vm._v(" "),
      _vm.tradeInData.informationModalUrl
        ? _c(
            "a",
            {
              staticClass: "link link--muted-light link--underline",
              attrs: {
                href: "#",
                "data-target": _vm.tradeInData.informationModalUrl,
                "data-modal-source": "ajax"
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.lang.SiteGeneral.ReadMore) + "\n    "
              )
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    !_vm.fetchedValuation
      ? _c(
          "div",
          [
            _c("VcOptionBox", {
              attrs: {
                selected: !_vm.hasTradeInVehicle,
                heading: _vm.tradeInData.denyOption
              },
              on: {
                click: function($event) {
                  return _vm.onHasTradeInVehicle(false)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "ConfiguratorOptionBox",
              {
                attrs: {
                  selected: _vm.hasTradeInVehicle,
                  heading: _vm.tradeInData.acceptOption,
                  hasSubOption: true,
                  yellowBg: _vm.hasTradeInVehicle,
                  lang: _vm.lang
                },
                on: {
                  click: function($event) {
                    return _vm.onHasTradeInVehicle(true)
                  }
                }
              },
              [
                _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                  _c("div", { staticClass: "trade-in-selector__input" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "regnr-input" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c("div", { staticClass: "regnr-input__symbol" })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-stack" }, [
                            _c("label", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.lang.Summation
                                      .SummationVehicleRegistration
                                  ) +
                                  "\n                  "
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regNr,
                                  expression: "regNr"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "regNr-input",
                                type: "text",
                                placeholder: "ABC123",
                                required: "",
                                pattern: "^\\w{3}\\d{2}(\\w{1}|\\d{1})$"
                              },
                              domProps: { value: _vm.regNr },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.regNr = $event.target.value
                                }
                              }
                            })
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "trade-in-selector__small-input" },
                        [
                          _c("div", { staticClass: "form-stack" }, [
                            _c("label", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.lang.Summation.SummationVehicleMileage
                                  ) +
                                  "\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.mileage,
                                  expression: "mileage"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "mileage",
                                type: "text",
                                placeholder: "Ange miltal",
                                required: ""
                              },
                              domProps: { value: _vm.mileage },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.mileage = $event.target.value
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn--primary",
                        on: { click: _vm.appraiseVehicle }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.lang.Summation.TradeInAction) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ])
              ]
            )
          ],
          1
        )
      : _c("div", { staticClass: "configurator-section__completed" }, [
          _c("div", { staticClass: "details-bar" }, [
            _c("div", { staticClass: "details-bar__item-list" }, [
              _c("div", { staticClass: "details-bar__item" }, [
                _c("div", { staticClass: "details-bar__title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.lang.Summation.SummationVehicleRegistration) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "details-bar__value details-bar__value--uppercase"
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.regNr) + "\n          "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details-bar__item" }, [
                _c("div", { staticClass: "details-bar__title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.lang.Summation.SummationVehicleBrand) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details-bar__value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.fetchedValuation.brandName) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details-bar__item" }, [
                _c("div", { staticClass: "details-bar__title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.lang.Summation.SummationVehicleModel) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details-bar__value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.fetchedValuation.modelName +
                          " " +
                          _vm.fetchedValuation.version
                      ) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details-bar__item" }, [
                _c("div", { staticClass: "details-bar__title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.lang.Summation.SummationVehicleYear) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details-bar__value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.fetchedValuation.modelYear) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "details-bar__item" }, [
                _c("div", { staticClass: "details-bar__title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.lang.Summation.SummationVehicleMileage) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details-bar__value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.fetchedValuation.mileage) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "details-bar__action",
                on: { click: _vm.removeValuation }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Summation.RemoveSelection) +
                    "\n      "
                )
              ]
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }