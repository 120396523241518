import EonSpeedIndices from "./EonSpeedIndices";

export default {
  [EonSpeedIndices.Q]: '160',
  [EonSpeedIndices.R]: '170',
  [EonSpeedIndices.S]: '180',
  [EonSpeedIndices.T]: '190',
  [EonSpeedIndices.U]: '200',
  [EonSpeedIndices.H]: '210',
  [EonSpeedIndices.V]: '240',
  [EonSpeedIndices.W]: '270',
  [EonSpeedIndices.Y]: '300',
  [EonSpeedIndices.VR]: '> 210',
  [EonSpeedIndices.ZR]: '> 240',
}
