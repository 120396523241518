<template>
  <form @submit.prevent="onSubmit">
    <label>
      Logga in med BankID
    </label>
    <VcFormStack label="Personnummer" required>
      <input
        v-model="ssn"
        class="form-control"
        placeholder="ÅÅÅÅMMDD-XXXX"
        required
        type="text" />
    </VcFormStack>
    <button type="submit" class="btn btn--primary btn--block">
      Logga in med BankID
    </button>
  </form>
</template>

<script>
import { VcFormStack } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFormStack,
  },
  data() {
    return {
      ssn: '',
    };
  },
  methods: {
    onSubmit() {
    },
  },
}
</script>
