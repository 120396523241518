var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcModal",
    {
      on: {
        close: function($event) {
          return _vm.$emit("modalClose")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("\n    " + _vm._s(_vm.modalHeader) + "\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "choose-dimensions",
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.manualSelect
            ? _c("SelectManual", {
                attrs: { tyreSize: _vm.tyreSize },
                on: { change: _vm.onChange }
              })
            : _c("SelectRecommended", {
                attrs: {
                  sizes: _vm.TyreSpec.recommendedSizes,
                  tyreSize: _vm.tyreSize,
                  carName: _vm.TyreSpec.carName
                },
                on: { change: _vm.onChange }
              }),
          _vm._v(" "),
          _c(
            "VcFormStack",
            { attrs: { label: "Produkt" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: [
                    { value: "tyre", label: _vm.lang.Tyre.TyreLinkBtn },
                    { value: "wheel", label: _vm.lang.Tyre.WheelLinkBtn },
                    { value: "rim", label: _vm.lang.Tyre.RimLinkBtn }
                  ],
                  value: _vm.productType,
                  labelProp: "label",
                  valueProp: "value",
                  valueType: "string",
                  required: ""
                },
                on: {
                  change: function($event) {
                    _vm.productType = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VcFormStack",
            { attrs: { label: "Säsong" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: [
                    { value: _vm.EonTyreTypes.Summer, label: "Sommar" },
                    {
                      value: _vm.EonTyreTypes.WinterStudded,
                      label: "Vinter dubb"
                    },
                    {
                      value: _vm.EonTyreTypes.WinterFriction,
                      label: "Vinter friktion"
                    }
                  ],
                  value: _vm.tyreType,
                  labelProp: "label",
                  valueProp: "value",
                  valueType: "number",
                  required: ""
                },
                on: {
                  change: function($event) {
                    _vm.tyreType = $event
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "btn btn--primary btn--block choose-spec__multi__btn",
              class: { disabled: _vm.buttonDisabled },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [_vm._v("\n      " + _vm._s("Se produkter") + "\n    ")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "form-text" }, [
            _vm._v(
              "\n      När man byter tum-storlek och endast köper lösa däck är det viktigt att man kontrollerar så att däckets tum stämmer överens med ens nuvarande fälgar. Annars kommer det inte gå att montera däcket på den gamla fälgen.\n    "
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }