<template>
  <div class="configurator-section additional-service-selector">
    <div class="configurator-section__title-container">
      <h4 class="configurator-section__title">
        <i class="fal fa-file-certificate configurator-section__icon"></i>
        {{ serviceAgreementData.heading }}
      </h4>
      <a
        v-if="serviceAgreementData.informationModalUrl"
        class="link link--muted-light link--underline"
        href="#"
        :data-target="serviceAgreementData.informationModalUrl"
        data-modal-source="ajax"
      >
        {{ lang.SiteGeneral.ReadMore }}
      </a>
    </div>
    <div v-if="!fetchedAgreement">
      <VcOptionBox
        :selected="!wantsAgreement"
        :heading="serviceAgreementData.denyOption"
        @click="onWantsAgreement(false)"
      ></VcOptionBox>
      <ConfiguratorOptionBox
        :selected="wantsAgreement"
        :heading="serviceAgreementData.acceptOption"
        :hasSubOption="true"
        @click="onWantsAgreement(true)"
        :yellowBg="wantsAgreement"
        :lang="lang"
      >
        <div slot="content">
          <div class="form-row">
            <ConfiguratorSubSelect
              :selectedValue="months"
              @change="setMonths"
              :option="monthsOptions"
            />
            <ConfiguratorSubSelect
              :selectedValue="mileage"
              @change="setMileage"
              :option="mileageOptions"
            />
            <button
              @click="getServiceAgreement"
              class="btn btn--primary additional-service-selector__submit-btn"
            >
              {{ lang.Summation.ServiceAgreementAction }}
            </button>
          </div>
        </div>
      </ConfiguratorOptionBox>
    </div>
    <div v-else class="configurator-section__completed">
      <div class="details-bar">
        <div class="details-bar__item-list">
          <div class="details-bar__item">
            <div class="details-bar__title">
              {{ lang.Summation.ServiceAgreementPeriodHeading }}
            </div>
            <div class="details-bar__value details-bar__value">
              {{ `${months} ${lang.Summation.ServiceAgreementPeriodMeta}` }}
            </div>
          </div>
          <div class="details-bar__item">
            <div class="details-bar__title">
              {{ lang.Summation.ServiceAgreementDistanceHeading }}
            </div>
            <div class="details-bar__value">
              {{ `${mileage} ${lang.Summation.ServiceAgreementDistanceMeta}` }}
            </div>
          </div>
        </div>
        <div class="details-bar__action" @click="removeAgreement">
          {{ lang.Summation.RemoveSelection }}
        </div>
      </div>
      <div class="main-info">
        <div class="main-info__heading">
          {{ lang.Summation.ServiceAgreementOfferHeading }}
        </div>
        <div class="main-info__data">
          {{ `${$formatPriceNoSuffix(fetchedAgreement.monthlyCost)} kr/mån` }}
        </div>
        <div class="main-info__meta">
          {{ lang.Summation.ServiceAgreementOfferText }}
        </div>
      </div>
      <div class="configurator-section__completed__sub-action">
        <div class="main-info__heading">
          {{ lang.Summation.ServiceAgreementAddonsHeading }}
        </div>
        <VcOptionBox
          v-for="additionalProduct in fetchedAgreement.additionalProducts"
          :key="additionalProduct.id"
          :selected="addedAdditionalProducts.includes(additionalProduct.id)"
          :heading="additionalProduct.name"
          :checkbox="true"
          @click="changeAdditionalProduct(additionalProduct.id)"
        >
          <div slot="header-meta" v-if="additionalProduct.description">
            <a
              class="link link--underline link--muted"
              @click.prevent.stop="
                onLinkClick(
                  additionalProduct.name,
                  additionalProduct.description
                )
              "
            >
              {{ lang.SiteGeneral.Readmore }}
            </a>
          </div>

          <div v-if="additionalProduct.monthlyCost" slot="header-aside">
            <VcPriceTag
              :price="{
                current: additionalProduct.monthlyCost,
                suffix: ' kr/mån'
              }"
              :show-if-zero="true"
            >
            </VcPriceTag>
          </div>
        </VcOptionBox>
      </div>
    </div>
    <VcModal v-if="modalOpen" @close="closeModal">
      <div slot="header">
        {{ modalHeading }}
      </div>
      <div slot="content" v-html="modalContent"></div>
    </VcModal>
  </div>
</template>

<script>
import { VcOptionBox, VcModal, VcPriceTag } from "@holmgrensbil/vue-components";
import ConfiguratorOptionBox from "./ConfiguratorOptionBox.vue";
import ConfiguratorSubSelect from "./ConfiguratorSubSelect.vue";
import Axios from "../../axios";
import store from "./store";

export default {
  components: {
    ConfiguratorOptionBox,
    VcOptionBox,
    VcModal,
    VcPriceTag,
    ConfiguratorSubSelect
  },
  props: {
    serviceAgreementData: Object,
    lang: Object
  },
  data() {
    return {
      wantsAgreement: false,
      mileage: null,
      months: null,
      fetchedAgreement: null,
      addedAdditionalProducts: [],
      modalHeading: "",
      modalContent: "",
      modalOpen: false
    };
  },
  computed: {
    monthsOptions() {
      return {
        heading: "Serviceavtalslängd",
        required: true,
        values: this.serviceAgreementData.months
      };
    },
    mileageOptions() {
      return {
        heading: "Körsträcka per år",
        required: true,
        values: this.serviceAgreementData.mileage
      };
    }
  },
  async created() {
    this.mileage = this.serviceAgreementData.mileage[0].value;
    this.months = this.serviceAgreementData.months[0].value;
  },
  methods: {
    onWantsAgreement(wantsAgreement) {
      this.wantsAgreement = wantsAgreement;
    },
    setMileage(mileage) {
      this.mileage = Number(mileage);
    },
    setMonths(months) {
      this.months = Number(months);
    },
    removeAgreement() {
      this.fetchedAgreement = null;
      store.setServiceAgreement(null);
    },
    changeAdditionalProduct(id) {
      if (this.addedAdditionalProducts.includes(id)) {
        this.addedAdditionalProducts = this.addedAdditionalProducts.filter(
          product => product !== id
        );
      } else {
        this.addedAdditionalProducts = [...this.addedAdditionalProducts, id];
      }

      store.setServiceAgreement({
        ...this.fetchedAgreement,
        additionalProducts: this.fetchedAgreement.additionalProducts.map(
          product => {
            return {
              ...product,
              selected: this.addedAdditionalProducts.includes(product.id)
            };
          }
        ),
        mileage: this.mileage
      });
    },
    async getServiceAgreement() {
      const regNr = store.state.regNr;
      try {
        const { data } = await Axios.get(
          `/api/vehicleConfigurator/${regNr}/serviceAgreement?mileage=${this.mileage}&months=${this.months}`
        );
        this.fetchedAgreement = data;
        this.addedAdditionalProducts = data.additionalProducts
          .map(product => {
            if (product.selected) {
              return product;
            }
          })
          .filter(product => product);

        store.setServiceAgreement({
          ...data,
          mileage: this.mileage
        });
      } catch (e) {
        console.error(e);
      }
    },
    onLinkClick(modalHeading, modalContent) {
      this.modalOpen = true;
      this.modalHeading = modalHeading;
      this.modalContent = modalContent;
    },
    closeModal() {
      this.modalOpen = false;
      this.modalHeading = "";
      this.modalContent = "";
    }
  }
};
</script>
