import OpeningHoursPrint from "../apps/opening-hours-print";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {

    mountApp({
      app: OpeningHoursPrint,
      selector: '[data-app=opening-hours]',
      extractProps: el => ({
        departmentNr: el.getAttribute("data-department-nr"),
      })
    });

  },
};
