var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.popularSearches.length
    ? _c("div", { staticClass: "i3-filter__search-view" }, [
        _c("label", [_vm._v("\n    Populära sökningar\n  ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-list" },
          _vm._l(_vm.popularSearches, function(search) {
            return _c(
              "div",
              {
                key: search.query,
                staticClass: "btn btn--white btn--sm",
                on: {
                  click: function($event) {
                    return _vm.$emit("select", search)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(search.name) + "\n    ")]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }