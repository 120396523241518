import TradeIn from "../apps/trade-in";
import TradeInCamping from "../apps/trade-in-camping";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.modifyDOM();
  },

  modifyDOM() {
    mountApp({
      app: TradeIn,
      selector: '[data-app=trade-in]',
      props: {
        pageName: window.document.title,
      },
    });
    mountApp({
      app: TradeInCamping,
      selector: '[data-app=trade-in-camping]',
      props: {
        pageName: window.document.title,
      },
    });
  },
};
