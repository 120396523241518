<template>
  <div v-if="loading" class="u-align-center">
    <FontAwesomeIcon
      :icon="faSpinnerThird"
      :spin="true"
      class="wizard--service-booking__loader"
    >
    </FontAwesomeIcon>
  </div>
  <div v-else>
    <div v-if="field.options.length">
      <div v-for="group in field.options" :key="group.name">
        <label>
          {{ group.name }}
        </label>

        <div class="form-row">
          <div class="group-row" v-for="(row, index) in getRowOptions(groupedOptions(group.name), group.name)" :key="`row-${index}`">
            <div
              v-for="option in row"
              class="form-row__col"
              :class="
                option.largeImage &&
                  'form-row__col--md-50 form-row__col--large-image'
              "
              :key="option.id"
            >
              <ServiceOptionBox
                :option="option"
                :multiselect="option.inputType === 2"
                @optionChange="onOptionChange(option, $event)"
                @subOptionChange="onSubOptionChange"
              ></ServiceOptionBox>
            </div>
          </div>
          <div
            v-if="!expandRowIsExcluded(group.name) && groupedOptions(group.name).length > 4"
            class="link link--primary link--icon wizard--service-booking__show-more-link"
            @click="onOpenExpandedRow(group.name)">
            {{ expandedRowOpen === group.name ? 'Se färre tillval' : 'Se fler tillval' }}
            <FontAwesomeIcon :icon="expandedRowOpen === group.name ? faAngleUp : faAngleDown"></FontAwesomeIcon>
          </div>
        </div>
      </div>
    </div>
    <label v-if="serviceSelected">
      Övrigt
    </label>
    <div class="form-row" v-if="serviceSelected">
      <div class="form-row__col">
        <SectionField :type="FieldTypes.ServiceMessage"> </SectionField>
      </div>
    </div>
  </div>
</template>

<script>
import { VcOptionBox, VcModal, VcPriceTag } from "@holmgrensbil/vue-components";

import { mapGetters, mapActions, mapState } from "vuex";
import FieldTypes from "./constants/FieldTypes";
import ServiceOptionBox from "./ServiceOptionBox.vue";
import SectionField from "../shared/wizard/SectionField.vue";
import ConfigGroupOption from "../shared/configurator/ConfigGroupOption.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faSpinnerThird, faAngleUp, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import groupBy from "lodash/groupBy";
import json from "../../utils/json";

export default {
  components: {
    SectionField,
    ConfigGroupOption,
    FontAwesomeIcon,
    VcOptionBox,
    ServiceOptionBox,
  },
  data() {
    return {
      FieldTypes,
      faSpinnerThird,
      faAngleUp,
      faAngleDown,
      expandedRowOpen: '',
    };
  },
  computed: {
    ...mapGetters({
      fieldByType: "fields/byType",
      invalidFields: "fields/invalid",
      sectionByType: "sections/byType"
    }),
    ...mapState({
      addonsRequestState: state => state.addons.fetchState
    }),

    field() {
      return this.fieldByType(FieldTypes.Addons);
    },
    loading() {
      return this.addonsRequestState === 1;
    },
    serviceSelected() {
      return (
        this.fieldByType(FieldTypes.ServiceTypes).value[0].id === "service"
      );
    }
  },
  methods: {
    ...mapActions({
      setField: "fields/set"
    }),
    groupedOptions(groupName) {
      var group = json(this.field.options, "name", groupName)[0];
      if (group) {
        return Object.values(groupBy(group.options, 'rowIndex'));
      } else {
        return this.field.options;
      }
    },
    onOptionChange(option, event) {
      const alteredOptions = this.field.options.map(item => {
        if (!item.options.find(subItem => subItem.id === option.id)) {
          return item;
        }
        const subOptions = item.options.map(subItem => {
          if (subItem.id === option.id) {
            return {
              ...subItem,
              selected: subItem.inputType === 1 && !event ? true : event
            };
          } else {
            return {
              ...subItem,
              selected: subItem.inputType !== 2 ? false : subItem.selected
            };
          }
        });
        return {
          ...item,
          options: subOptions
        };
      });
      this.setField({
        type: FieldTypes.Addons,
        options: alteredOptions
      });
    },
    onSubOptionChange({ alteredOption, alteredSubOption, value }) {
      const alteredSubOptions = alteredOption.subOptions.map(subOption => {
        if (alteredSubOption.id === subOption.id) {
          return {
            ...subOption,
            selected: alteredSubOption.inputType === 1 && !value ? true : value
          };
        } else {
          return {
            ...subOption,
            selected: alteredSubOption.inputType === 1 ? false : !!subOption.selected
          };
        }
      });

      const alteredOptions = this.field.options.map(item => {
        if (!item.options.find(subItem => subItem.id === alteredOption.id)) {
          return item;
        }
        const subOptions = item.options.map(subItem => {
          if (subItem.id === alteredOption.id) {
            let price = {
              ...subItem.price,
            };

            if (alteredSubOption.id === 'hasServiceAgreement' || alteredSubOption.id === 'signServiceAgreement') {
              price.current = 0;
            }

            return {
              ...subItem,
              price,
              subOptions: alteredSubOptions,
            };
          } else {
            return subItem;
          }
        });
        return {
          ...item,
          options: subOptions
        };
      });

      this.setField({
        type: FieldTypes.Addons,
        options: alteredOptions
      });
    },
    expandRowIsExcluded(name) {
      return name === 'Kampanj' || name === 'Hyrbil';
    },
    onOpenExpandedRow(name) {
      this.expandedRowOpen = this.expandedRowOpen === name ? '' : name;
    },
    getRowOptions(row, groupName) {
      return (this.expandRowIsExcluded(groupName) || (row <= 4 || this.expandedRowOpen === groupName) ? row : row.slice(0, 4))
    }
  }
};
</script>
