var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-pages__section" },
    [
      _c("div", { staticClass: "my-pages__section__title-container" }, [
        _c("span", { staticClass: "my-pages__section__title" }, [
          _vm._v(_vm._s(_vm.sectionData.length + " FORDON"))
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.sectionData, function(product) {
        return _c("div", { key: product.id, staticClass: "saved-vehicles" }, [
          product.available
            ? _c(
                "div",
                {
                  ref: "swiperContainer",
                  refInFor: true,
                  staticClass: "swiper-container"
                },
                [
                  _c(
                    "div",
                    { staticClass: "swiper-wrapper" },
                    _vm._l(product.images, function(image) {
                      return _c(
                        "div",
                        { key: image, staticClass: "swiper-slide" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "product-images__item product-images__item--vertical"
                            },
                            [_c("img", { attrs: { src: image, alt: "" } })]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "swiper-pagination" }),
                  _vm._v(" "),
                  _vm._m(0, true),
                  _vm._v(" "),
                  _vm._m(1, true)
                ]
              )
            : _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "product-images__item product-images__item--vertical product-images__item--overlay"
                  },
                  [
                    _c("img", { attrs: { src: product.images[0], alt: "" } }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(product.availableLabel) +
                          "\n        "
                      )
                    ])
                  ]
                )
              ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "product-info",
              on: {
                click: function($event) {
                  return _vm.$emit("clickVehicle", product.permalink)
                }
              }
            },
            [
              _c("span", { staticClass: "product-info__title" }, [
                _vm._v(_vm._s(product.title))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-info__description-container" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "product-info__description-container__text"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          product.shortVehicleCondition +
                            " " +
                            product.modelYear +
                            " • "
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "product-info__description-container__text"
                    },
                    [_vm._v(_vm._s(product.mileage + " mil • "))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "product-info__description-container__text"
                    },
                    [_vm._v(_vm._s(product.fuelName + " • "))]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "product-info__description-container__text"
                    },
                    [_vm._v(_vm._s(product.gearBoxName))]
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "product-info__price",
                    class: {
                      "product-info__price--discount":
                        product.currentPrice < product.ordinaryPrice
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$formatPrice(product.currentPrice)) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                product.currentPrice < product.ordinaryPrice
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "product-info__price product-info__price--obsolete"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$formatPrice(product.ordinaryPrice)) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "product-info__card-actions" }, [
            _c(
              "div",
              {
                staticClass: "product-info__card-actions__btn",
                on: {
                  click: function($event) {
                    return _vm.$emit("deleteVehicle", product.regNr)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-trash blue" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "product-info__card-actions__btn",
                on: {
                  click: function($event) {
                    return _vm.compareVehicle(product.regNr)
                  }
                }
              },
              [_c("i", { staticClass: "fal fa-balance-scale blue" })]
            )
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "product-images__nav product-images__nav--prev" },
      [_c("i", { staticClass: "fal fa-angle-left" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "product-images__nav product-images__nav--next" },
      [_c("i", { staticClass: "fal fa-angle-right" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }