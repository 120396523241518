var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configurator-section finance-selector" }, [
    _c("h4", { staticClass: "configurator-section__title" }, [
      _c("i", { staticClass: "fal fa-money-check configurator-section__icon" }),
      _vm._v("\n    " + _vm._s(_vm.lang.Summation.FinancingHeading) + "\n  ")
    ]),
    _vm._v(" "),
    _vm.financingSummary[_vm.FinanceOptions.CASH] &&
    _vm.financingConfig[_vm.FinanceOptions.CASH].display
      ? _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c(
                "ConfiguratorOptionBox",
                {
                  attrs: {
                    selected: _vm.selected === _vm.FinanceOptions.CASH,
                    heading: _vm.lang.Summation.FinancingOptionCash,
                    smallText: _vm.cashSmallText,
                    price: _vm.cashPrice,
                    hasSubOption: _vm.financingSummary.cash.vatToggle,
                    lang: _vm.lang,
                    modalContent: _vm.financingSummary.cash.modalContent,
                    ribbonText: _vm.financingSummary.cash.ribbonText
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "change-finance-option",
                        _vm.FinanceOptions.CASH
                      )
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "content" }, slot: "content" },
                    [
                      _c("SubOptionsToggle", {
                        attrs: { option: _vm.cashSubOptions },
                        on: {
                          change: function($event) {
                            return _vm.onChangeTaxSetting($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.financingSummary[_vm.FinanceOptions.INSTALLMENT] &&
    _vm.financingConfig[_vm.FinanceOptions.INSTALLMENT].display
      ? _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c(
                "ConfiguratorOptionBox",
                {
                  attrs: {
                    selected: _vm.selected === _vm.FinanceOptions.INSTALLMENT,
                    heading: _vm.lang.Summation.FinancingOptionInstallment,
                    smallText: "(" + _vm.lang.Summation.IncludingTax + ")",
                    price: {
                      current: _vm.financingSummary.installment.monthlyCost,
                      ordinary:
                        _vm.financingSummary.installment.ordinaryMonthlyCost,
                      suffix: " kr/månad"
                    },
                    hasSubOption: true,
                    lang: _vm.lang,
                    modalContent: _vm.financingSummary.installment.modalContent,
                    ribbonText: _vm.financingSummary.installment.ribbonText
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "change-finance-option",
                        _vm.FinanceOptions.INSTALLMENT
                      )
                    }
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("ConfiguratorSubSelect", {
                          attrs: {
                            option: _vm.downPaymentOptions,
                            selectedValue:
                              _vm.financingSettings[
                                _vm.FinanceOptions.INSTALLMENT
                              ].downPayment
                          },
                          on: { change: _vm.onChangeDownPayment }
                        }),
                        _vm._v(" "),
                        _c("ConfiguratorSubSelect", {
                          attrs: {
                            option: _vm.periodOptions,
                            selectedValue:
                              _vm.financingSettings[
                                _vm.FinanceOptions.INSTALLMENT
                              ].period.months
                          },
                          on: { change: _vm.onChangePeriod }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "finance-selector__information-field" },
                      [
                        _c("SummationList", {
                          attrs: {
                            lang: _vm.lang,
                            financing:
                              _vm.financingSummary[
                                _vm.FinanceOptions.INSTALLMENT
                              ],
                            selectedFinancing: _vm.selected
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.financingSummary[_vm.FinanceOptions.LEASING] &&
    _vm.financingConfig[_vm.FinanceOptions.LEASING].display
      ? _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c(
                "ConfiguratorOptionBox",
                {
                  attrs: {
                    selected: _vm.selected === _vm.FinanceOptions.LEASING,
                    heading: _vm.lang.Summation.FinancingOptionLeasing,
                    smallText: "(" + _vm.lang.Summation.ExcludingTax + ")",
                    price: {
                      current: _vm.financingSummary.leasing.monthlyCost,
                      ordinary:
                        _vm.financingSummary.leasing.ordinaryMonthlyCost,
                      suffix: " kr/månad"
                    },
                    hasSubOption: true,
                    lang: _vm.lang,
                    modalContent: _vm.financingSummary.leasing.modalContent,
                    ribbonText: _vm.financingSummary.leasing.ribbonText
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "change-finance-option",
                        _vm.FinanceOptions.LEASING
                      )
                    }
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("ConfiguratorSubSelect", {
                          attrs: {
                            option: _vm.downPaymentOptions,
                            selectedValue:
                              _vm.financingSettings[_vm.FinanceOptions.LEASING]
                                .downPayment
                          },
                          on: { change: _vm.onChangeDownPayment }
                        }),
                        _vm._v(" "),
                        _c("ConfiguratorSubSelect", {
                          attrs: {
                            option: _vm.periodOptions,
                            selectedValue:
                              _vm.financingSettings[_vm.FinanceOptions.LEASING]
                                .period.months
                          },
                          on: { change: _vm.onChangePeriod }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "finance-selector__information-field" },
                      [
                        _c("SummationList", {
                          attrs: {
                            lang: _vm.lang,
                            financing:
                              _vm.financingSummary[_vm.FinanceOptions.LEASING],
                            selectedFinancing: _vm.selected
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.financingSummary[_vm.FinanceOptions.PRIVATELEASING] &&
    _vm.financingConfig[_vm.FinanceOptions.PRIVATELEASING].display
      ? _c("div", { staticClass: "form-row" }, [
          _c(
            "div",
            { staticClass: "form-row__col" },
            [
              _c(
                "ConfiguratorOptionBox",
                {
                  attrs: {
                    selected:
                      _vm.selected === _vm.FinanceOptions.PRIVATELEASING,
                    heading: _vm.lang.Summation.FinancingOptionPrivateLeasing,
                    smallText: "(" + _vm.lang.Summation.IncludingTax + ")",
                    price: {
                      current: _vm.financingSummary.privateLeasing.monthlyCost,
                      ordinary:
                        _vm.financingSummary.privateLeasing.ordinaryMonthlyCost,
                      suffix: " kr/månad",
                      attention: true
                    },
                    hasSubOption: true,
                    lang: _vm.lang,
                    modalContent:
                      _vm.financingSummary.privateLeasing.modalContent,
                    ribbonText: _vm.financingSummary.privateLeasing.ribbonText
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        "change-finance-option",
                        _vm.FinanceOptions.PRIVATELEASING
                      )
                    }
                  }
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _c(
                      "div",
                      { staticClass: "finance-selector__information-field" },
                      [
                        _c("SummationList", {
                          attrs: {
                            lang: _vm.lang,
                            financing:
                              _vm.financingSummary[
                                _vm.FinanceOptions.PRIVATELEASING
                              ],
                            selectedFinancing: _vm.selected
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }