import Vuex from "vuex";
import config from "../../shared/wizard/modules/config";
import fields from "../../shared/wizard/modules/fields";
import form from "../../shared/wizard/modules/form";
import times from "../../shared/wizard/modules/times";
import dates from "../../shared/wizard/modules/dates";
import sections from "../../shared/wizard/modules/sections";
import addons from "./modules/addons";
import serviceTypes from "./modules/serviceTypes";
import service from "./modules/service";

const sharedModules = {
  config,
  fields,
  form,
  sections,
  times,
  dates
};

const strict = process.env.NODE_ENV !== "production";

export default () =>
  new Vuex.Store({
    strict,
    modules: {
      ...sharedModules,
      addons,
      serviceTypes,
      service
    }
  });
