import Breakpoints from "../constants/Breakpoints";
import Swiper from "../swiper";

const selectors = {
  mainSwiper: ".similar-blogs__item-container .swiper-container",
};

export default {
  pyronInit: false,
  init() {
    this.initSwiper();
  },

  initSwiper() {
    if (this.swiper) {
      return;
    }

    this.swiper = new Swiper(selectors.mainSwiper, {
      spaceBetween: 24,
      slidesPerView: 3,
      noSwiping: false,
      preloadImages: false,
      lazy: true,

      breakpoints: {
        [Breakpoints.md - 2]: {
          slidesPerView: 'auto',
          spaceBetween: 24,
          noSwiping: false,
        },
      },
    });
  },
};
