<template>
  <div class="eon-filter-page">
    <div v-if="mobile" class="eon-filter-page__header">
      <div v-for="button in filterConfigButtons"
        :key="button.label"
        class="page-header__controls">
        <div class="page-header__control">
          <div class="form-stack" @click="onConfigClick(button.config)">
            <label>
              {{button.label}}
            </label>
            <div class="form-control">
              {{button.text ? button.text : '&nbsp;'}}
            </div>
            <FontAwesomeIcon :icon="currentFilterConfig === button.config ? faAngleUp : faAngleDown">
            </FontAwesomeIcon>
          </div>
          <FilterWidget v-if="currentFilterConfig === button.config"
            :channel="channel"
            :mainSpecSelector="button.config === FilterConfigurations.SearchFor"
            :filterConfiguration="button.config">
          </FilterWidget>
        </div>
      </div>

      <div class="page-header__controls">
        <div class="page-header__control page-header__control--filter">
          <a class="btn btn--white btn--block btn--icon" @click="onConfigClick(FilterConfigurations.Filter)">
            Filtrera
            <FilterBadge :channel="channel">
            </FilterBadge>
            <i class="fal fa-sliders-h"></i>
          </a>
        </div>

        <div class="page-header__control page-header__control--sort">
          <SortWidget :channel="channel" @clicked="sortClicked">
          </SortWidget>
        </div>
      </div>

      <FilterWidget v-if="currentFilterConfig === FilterConfigurations.Filter"
        :channel="channel"
        :filterConfiguration="FilterConfigurations.Filter">
      </FilterWidget>
    </div>

    <div class="section section--gray section--padding-sm">
      <div class="container">
        <div>
          <div>
            <FilterWidget v-if="!mobile && currentFilterConfig === FilterConfigurations.All"
              :channel="channel"
              :filterConfiguration="FilterConfigurations.MainOptions"
              isColumn
              :mainSpecSelector="true"
              @onFieldChange="onFieldChange"
            >
            </FilterWidget>
            <div v-if="isTyreBuild" class="build-tyre">
              <div class="build-tyre__heading">
                <div class="build-tyre__title">Bygg ditt hjul</div>
                <div class="build-tyre__text">
                  Vi erbjuder dig att sätta ihop ditt egna hjulpaket. Vi har tagit fram ett förslag som skulle passa bra på din bil. Vi bjuder på monteringen!
                </div>
              </div>
              <div v-if="isTyreBuild" class="build-tyre__container">
                <TyreBuildSelectSection
                  :selected="tyreBuildStep === TyreBuildSteps.Rim"
                  :product="selectedRim"
                  :step="TyreBuildSteps.Rim"
                  :title="'1. Välj fälg'"
                  :label="'Fälg'"
                  :image="'../../../Static/images/hb_rim@2x.png'"
                  :quantity="productQuantity"
                  :mobile="mobile"
                  @onSelect="selectTyreStep"
                  @alterBuildWheelQuantity="alterBuildWheelQuantity"
                />
                <TyreBuildSelectSection
                  :selected="tyreBuildStep === TyreBuildSteps.Tyre"
                  :product="selectedTyre"
                  :step="TyreBuildSteps.Tyre"
                  :title="'2. Välj däck'"
                  :label="'Däck'"
                  :image="'../../../Static/images/hb_tire@2x.png'"
                  :quantity="productQuantity"
                  :mobile="mobile"
                  @onSelect="selectTyreStep"
                  @alterBuildWheelQuantity="alterBuildWheelQuantity"
                />
                <TyreBuildFinishSection
                  :title="'3. Välj tillval'"
                  isAddon
                  :addons="addons"
                  :totalPrice="totalPrice"
                  :loading="addToCartLoading"
                  @onAddonsSelected="onAddonsSelected"
                  @onAddToCart="addToCart"
                />
              </div>
            </div>
            <div v-if="!mobile && ((isTyreBuild && tyreBuildStep) || !isTyreBuild)" class="row eon-filter-page__desktop-results">
              <div class="col-md-4">
                <FilterWidget v-if="currentFilterConfig === FilterConfigurations.All"
                  :channel="channel"
                  :filterConfiguration="FilterConfigurations.SubOptions">
                </FilterWidget>
              </div>
              <div class="col-md-8">
                <EonResult
                  :channel="channel"
                  :TyreSpec="TyreSpec"
                  :selectedProductType="selectedProductType"
                  :isBuildWheel="isTyreBuild"
                  :selectedProduct="selectedProduct"
                  :isLoading="isLoadingResults"
                  @onQuerySuccess="onQuerySuccess">
                </EonResult>
              </div>
            </div>
            <div v-if="mobile && !isTyreBuild">
              <EonResult :channel="channel" :TyreSpec="TyreSpec" :selectedProductType="selectedProductType">
              </EonResult>
            </div>
          </div>
          <VcModal v-if="mobileModalOpen"
            @close="mobileModalOpen = false">
            <div slot="header">
              {{ mobileModalHeader }}
            </div>
            <div slot="content">
              <EonResult
                :channel="channel"
                :TyreSpec="TyreSpec"
                :selectedProductType="selectedProductType"
                :isBuildWheel="true"
                :selectedProduct="selectedProduct"
                :isLoading="isLoadingResults"
                @onQuerySuccess="onQuerySuccess">
              </EonResult>
            </div>
          </VcModal>
          <TyreBuildAddedToCart
            v-if="onAddedPurchaseModal && selectedTyre && selectedRim"
            :rim="selectedRim"
            :tyre="selectedTyre"
            :addons="filteredAddons"
            @close="onAddedPurchaseModal = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import SortWidget from './sort-widget/TheWidget.vue';
import FilterWidget from './filter-widget/TheWidget.vue';
import EonResult from '../eon-result/App.vue';
import FilterBadge from './FilterBadge.vue';
import TyreBuildSelectSection from './TyreBuildSelectSection.vue';
import TyreBuildFinishSection from './TyreBuildFinishSection.vue';
import TyreBuildAddedToCart from './TyreBuildAddedToCart.vue';
import FilterConfigurations from './constants/FilterConfigurations';
import Breakpoints from '../../constants/Breakpoints';
import EonProductTypes from './constants/EonProductTypes';
import Axios from '../../axios';
import EonQueryParams from '../../constants/EonQueryParams';
import { mapGetters, mapActions } from 'vuex';
import ChannelEvents from '../../constants/ChannelEvents';
import TyreSizes from '../../constants/TyreSizes';
import TyreBuildSteps from './constants/TyreBuildSteps';
import FieldTypes from './constants/FieldTypes';
import { EventBus } from '../../utils/event-bus';
import ChannelQuery from '../../models/ChannelQuery';
import LineItemCustomDataKeys from '../../constants/LineItemCustomDataKeys';
import siteHeaderCartBadge from '../../components/site-header-cart-badge';
import CartApi from '../../models/CartApi';
import gtm from '../../utils/gtm';
import GtmEvents from '../../constants/GtmEvents';
import TyreSpec from '../../models/TyreSpec';
import { VcModal } from '@holmgrensbil/vue-components';

export default {
  components: {
    SortWidget,
    FilterWidget,
    EonResult,
    FontAwesomeIcon,
    FilterBadge,
    TyreBuildSelectSection,
    TyreBuildFinishSection,
    TyreBuildAddedToCart,
    VcModal,
  },
  props: {
    channel: {
      type: Object,
      required: true,
    },
    TyreSpec: Object,
    lang: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.channel.one(ChannelEvents.QuerySuccess, this.oneQuerySuccess);
    this.setField({
      type: EonQueryParams.ProductType,
      options: [
        {
          name: this.lang.Tyre.TyreLinkBtn,
          value: String(EonProductTypes.Tyre),
          tabButtonIcon: "far fa-circle"
        },
        {
          name: this.lang.Tyre.RimLinkBtn,
          value: String(EonProductTypes.Rim),
          tabButtonIcon: "fal fa-tire"
        },
        {
          name: this.lang.Tyre.WheelLinkBtn,
          value: String(EonProductTypes.CompleteWheel),
          tabButtonIcon: "fas fa-tire"
        },
      ]
    })
    EventBus.$on('set-filter', (params) => {
      params.forEach((param) => {
        if (param.name === EonQueryParams.Diameter) {
          this.fetchAddons(param.value)
        }
        this.setField({
          type: param.name,
          value: param.value,
        });
      });
    })
  },
  mounted() {
    this.fetchAddons(TyreSpec?.tyreSize?.diameter)
    this.setupFieldDefaults();
    EventBus.$on('selectEonProduct', (product) => this.selectProductForBuild(product))
    if (!this.mobile) {
      this.currentFilterConfig = FilterConfigurations.All;
    }
  },
  computed: {
    ...mapGetters({
      fieldByType: 'fields/byType',
      fieldsExport: 'fields/export',
    }),

    selectedProductType() {
      return this.fieldByType(EonQueryParams.ProductType).value;
    },

    selectedProduct() {
      if (this.tyreBuildStep === TyreBuildSteps.Rim) {
        return this.selectedRim
        } else if (this.tyreBuildStep === TyreBuildSteps.Tyre) {
        return this.selectedTyre;
      } else {
        return null;
      }
    },

    mobile() {
      return this.windowWidth < Breakpoints.md;
    },

    isCompleteWheel() {
      return this.selectedProductType === String(EonProductTypes.CompleteWheel);
    },

    isTyre() {
      return this.selectedProductType === String(EonProductTypes.Tyre);
    },

    isTyreBuild() {
      return this.fieldByType(FieldTypes.BuildWheel).value === "1";
    },

    searchForName() {
      const field = this.isTyreBuild ? this.fieldByType(FieldTypes.BuildWheel) : this.fieldByType(EonQueryParams.ProductType);
      const value = field ? field.value : null;
      const words = [];
      if (value) {
        words.push(field.options.find(o => String(o.value) === value).name);

        if (this.isCompleteWheel) {
          const conditionField = this.fieldByType(EonQueryParams.Condition);
          const conditionValue = conditionField.value ? conditionField.value : null;

          if (conditionValue && conditionValue.length) {
            const conditionValues = conditionField.options.filter(o => conditionValue.includes(o.value));
            words.push(...conditionValues.map(v => v.name));
          }
        }
      }

      return words.join(' - ');
    },

    seasonName() {
      const field = this.fieldByType(EonQueryParams.TyreType);
      const value = field ? field.value : null;

      if (value) {
        return field.options.find(o => String(o.value) === value).name;
      }
    },

    dimensionName() {
      const field = this.fieldByType(EonQueryParams.Diameter);
      const value = field.value ? `${field.value}"` : 'Alla';

      return value;
    },

    filterConfigButtons() {
      if (!this.mobile) {
        return [];
      }

      const buttons = [{
        config: FilterConfigurations.SearchFor,
        label: 'Sök efter',
        text: this.searchForName,
      }];

      if (this.isCompleteWheel || this.isTyre) {
        buttons.push({
          config: FilterConfigurations.Season,
          label: 'Säsong',
          text: this.seasonName || 'Välj säsong',
        });
      }

      return buttons;
    },
    totalPrice() {
      const addonsPrice = this.addons.filter(addon => this.addonsSelected.includes(addon.id)).reduce((acc, curr) => (acc + curr.price.current), 0);
      const tyrePrice = this.selectedTyre ? parseInt(this.selectedTyre.totalPriceWithVat) : 0;
      const rimPrice = this.selectedRim ? parseInt(this.selectedRim.totalPriceWithVat) : 0;

      return (addonsPrice + tyrePrice + rimPrice) * this.productQuantity.value;
    },
    mobileModalHeader() {
      return this.tyreBuildStep === TyreBuildSteps.Tyre ? 'Däck' : 'Fälg';
    },
    filteredAddons() {
      return this.addons.filter(addon => this.addonsSelected.includes(addon.id));
    }
  },
  data() {
    return {
      faAngleDown,
      faAngleUp,
      FilterConfigurations,
      currentFilterConfig: null,
      tyreBuildStep: null,
      TyreBuildSteps,
      selectedRim: null,
      selectedTyre: null,
      productQuantity: { name: '4 st', value: 4 },
      addons: [],
      addonsSelected: [],
      mobileModalOpen: false,
      isLoadingResults: false,
      onAddedPurchaseModal: false,
      addToCartLoading: false,
    };
  },
  methods: {
    ...mapActions({
      setField: 'fields/set',
    }),

    sortClicked() {
      if(this.mobile && this.currentFilterConfig === FilterConfigurations.Filter) {
        this.currentFilterConfig = null;
      }
    },

    oneQuerySuccess() {
      const { params } = this.channel.lastQuery;
      Object.entries(params).forEach(([type, value]) => {
        this.setField({
          type,
          value,
        });
      });
    },

    onQuerySuccess() {
      this.isLoadingResults = false;
    },

    onConfigClick(config) {
      if (this.currentFilterConfig === config) {
        this.currentFilterConfig = null;
      } else {
        this.currentFilterConfig = config;
      }
    },

    setupFieldDefaults() {
      const { recommendedSizes, tyreSize } = this.TyreSpec;
      const value = tyreSize.diameter;
      const options = [];

      if (recommendedSizes.length) {
        const diameters = this.TyreSpec.recommendedSizes.map(size => size.diameter);
        const uniqueDiameters = [...new Set(diameters)];
        options.push(...uniqueDiameters);
      } else {
        options.push(...TyreSizes.Diameters);
      }

      this.setField({
        type: EonQueryParams.Diameter,
        options: options.map(diam => ({
          name: `${diam}"`,
          value: String(diam),
        })),
        value,
      });

      this.setField({
        type: EonQueryParams.FixedBore,
        options: [],
        value: true
      });
    },
    selectTyreStep(step) {
      this.tyreBuildStep = step;
      this.isLoadingResults = true;
      if (this.mobile) {
        this.mobileModalOpen = true;
      }
      this.setField({ type: 'typeId', value: step.toString() });
      this.executeQuery();
    },
    executeQuery() {
      const query = new ChannelQuery(this.fieldsExport);
      this.channel.execute(query);
    },
    onFieldChange() {
      if (this.fieldByType(FieldTypes.BuildWheel)) {
        this.tyreBuildStep = null;
      }
    },
    selectProductForBuild(product) {
      if (product.typeId === EonProductTypes.Rim) {
        this.selectedRim = product;
      } else if (product.typeId === EonProductTypes.Tyre) {
        this.selectedTyre = product;
      }
      this.mobileModalOpen = false;
    },
    alterBuildWheelQuantity(value) {
      this.productQuantity = value;
    },
    async fetchAddons(diameter) {
      try {
        const { data } = await Axios.get(`/api/products/buildtyreadditionalservices?inch=${diameter}`);
        this.addons = data;
      } catch (e) {

      }
    },
    onAddonsSelected(addons) {
      this.addonsSelected = addons;
    },
    async addToCart() {
      this.addToCartLoading = true;
      try {
        await this.addTyre();
        await this.addRim();
        this.onAddedPurchaseModal = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.addToCartLoading = false;
      }
    },
    async addTyre() {
      const customData = [];
      const regNr = TyreSpec.regNr;
      if (regNr) {
        customData.push({
          name: LineItemCustomDataKeys.RegistrationNumber,
          value: regNr,
        });
      }
      try {
        const res = await CartApi.addItem({
          productId: this.selectedTyre.id,
          quantity: this.productQuantity.value,
          childProducts: this.addonsSelected.map(addon => ({
            "productId": addon,
            "linkedQuantity": true,
          })),
          customData: [
            ...customData,
            {
              "name": "brand",
              "value": this.selectedTyre.brandName,
            },
            {
              "name": "variant",
              "value": this.selectedTyre.gtmVariantName,
            }
          ],
        })
        gtm.ecommerceEvent(GtmEvents.AddToCart, {
          currencyCode: 'SEK',
          value: this.addedQuantity.value * this.product.tireData.price,
          add: {
            products: [{...{quantity: this.productQuantity.value}, ...this.selectedTyre.tireData}]
          }
        });
        siteHeaderCartBadge.setCount(res.data.lineItems.filter(li => !li.parentLineItem).length);
      } catch (e) {
        console.log(e)
        throw e;
      }
    },
    async addRim() {
      const customData = [];
      const regNr = TyreSpec.regNr;
      if (regNr) {
        customData.push({
          name: LineItemCustomDataKeys.RegistrationNumber,
          value: regNr,
        });
      }
      try {
        const res = await CartApi.addItem({
          productId: this.selectedRim.id,
          quantity: this.productQuantity.value,
          customData: [
            ...customData,
          ],
        })
        gtm.ecommerceEvent(GtmEvents.AddToCart, {
          currencyCode: 'SEK',
          value: this.addedQuantity.value * this.product.tireData.price,
          add: {
            products: [{...{quantity: this.productQuantity.value}, ...this.selectedRim.tireData}]
          }
        });
        siteHeaderCartBadge.setCount(res.data.lineItems.filter(li => !li.parentLineItem).length);
      } catch (e) {
        console.log(e)
        throw e;
      }
    }
  },
}
</script>
