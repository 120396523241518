var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "configurator-section service-agreement-selector" },
    [
      _vm.fetchedAgreement
        ? _c("div", [
            _c("div", { staticClass: "configurator-section__completed" }, [
              _c("div", { staticClass: "details-bar" }, [
                _c("div", { staticClass: "details-bar__title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.lang.Summation.ServiceAgreementPeriodHeading) +
                      "\n          "
                  ),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$formatPriceMonthly(
                          _vm.fetchedAgreement.monthlyCost
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details-bar__item-list" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        "Avtalet gäller i " +
                          _vm.months +
                          " månader på en uppskattad körsträcka om " +
                          _vm.mileage +
                          " mil/år. Avtalet täcker alla ordinarie servicekostnader enligt ditt fordons serviceschema."
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.fetchedAgreement.additionalProducts
                ? _c(
                    "div",
                    {
                      staticClass: "configurator-section__completed__sub-action"
                    },
                    [
                      _c("div", { staticClass: "main-info__heading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.lang.Summation.ServiceAgreementAddonsHeading
                            ) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.fetchedAgreement.additionalProducts, function(
                        product
                      ) {
                        return _c(
                          "div",
                          {
                            staticClass:
                              "service-agreement-selector__product-row"
                          },
                          [
                            _c("ConfiguratorCheckbox", {
                              key: "service-agreement-product-" + product.id,
                              attrs: {
                                text: product.name,
                                subText: _vm.$formatPriceMonthly(
                                  product.monthlyCost
                                ),
                                defaultChecked: _vm.addedAdditionalProducts.includes(
                                  product.id
                                )
                              },
                              on: {
                                change: function(checked) {
                                  return _vm.changeAdditionalProduct(
                                    product.id,
                                    checked
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn--muted btn--block",
                on: { click: _vm.removeAgreement }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "vehicle-configurator__btn-icon",
                  attrs: { icon: _vm.faTrashCan }
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.lang.Summation.RemoveServiceAgreement) +
                    "\n    "
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "service-agreement-selector__input-step" }, [
            _c(
              "div",
              { staticClass: "form-row configurator-section__input-row" },
              [
                _c("ConfiguratorSubSelect", {
                  attrs: {
                    options: _vm.monthsOptions.values,
                    defaultPlaceholder: _vm.monthsOptions.heading
                  },
                  on: { change: _vm.onChangeMonths }
                }),
                _vm._v(" "),
                _c("ConfiguratorSubSelect", {
                  attrs: {
                    options: _vm.mileageOptions.values,
                    defaultPlaceholder: _vm.mileageOptions.heading
                  },
                  on: { change: _vm.onChangeMileage }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn--muted btn--block",
                  class: {
                    disabled: !_vm.mileage || !_vm.months || _vm.loading
                  },
                  attrs: {
                    disable: !_vm.mileage || !_vm.months || _vm.loading
                  },
                  on: { click: _vm.fetchServiceAgreement }
                },
                [
                  _vm.loading
                    ? _c("font-awesome-icon", {
                        staticClass: "vehicle-configurator__btn-icon",
                        attrs: { icon: _vm.faSpinnerThird, spin: true }
                      })
                    : _vm._e(),
                  _vm._v("\n        Hämta erbjudande\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _vm.serviceAgreementError
                ? _c(
                    "div",
                    { staticClass: "vehicle-configurator__button-error" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.serviceAgreementError) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }