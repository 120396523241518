import Swiper from '../swiper';
import Breakpoints from '../constants/Breakpoints';

export default {
  init() {
    if (!$('.tall-card-slider').length) {
      return;
    }

    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    this.initSwiper();
  },

  initSwiper() {
    this.swiper = new Swiper('.tall-card-slider', {
      autoHeight: true,
      autoplay: {
        delay: 1,
        disableOnInteraction: true,
      },
      freeMode: true,
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 32,
      speed: 8000,
      breakpoints: {
        [Breakpoints.md - 2]: {
          slidesPerView: 'auto',
          spaceBetween: 8,
        },
      },
    });
  },
}
