import qs from 'query-string';
import withoutEmptyProps from '../utils/without-empty-props';

export default class ChannelQuery {
  constructor(params) {
    if (typeof params === 'string') {
      // is e.g. 'q=*'
      this.params = qs.parse(params);
    } else if (ChannelQuery.prototype.isPrototypeOf(params)) {
      // is ChannelQuery instance
      this.params = params.params;
    } else {
      // is object or undefined
      this.params = params;
    }
  }

  get queryString() {
    return qs.stringify(withoutEmptyProps(this.params));
  }

  toString() {
    return this.queryString;
  }

  getParam(key) {
    return this.params && this.params[key];
  }

  setParam(key, value) {
    this.params = Object.assign({}, this.params, {
      [key]: value,
    });
  }

  get shouldSendSearchSignal() {
    if(this.getParam('offset') > 0) {
      return false;
    }
    const defaultParams = ['hits', 'load_previous', 'offset', 'preference'];
    return Object.keys(this.params).filter(key => {
      return !defaultParams.includes(key) && !(key === 'q' && this.getParam(key) === '*');
    }).length;
  }

  unsetParam(key) {
    const params = Object.assign({}, this.params);
    delete params[key];
    this.params = params;
  }
}
