<template>
  <FieldInputSelect
    v-bind="$props"
    v-on="$listeners"
    valueProp="value"
    labelProp="name">
  </FieldInputSelect>
</template>

<script>
import FieldInputSelect from './FieldInputSelect.vue';

export default {
  components: {
    FieldInputSelect,
  },
  props: {
    invalid: Boolean,
    field: Object,
  },
}
</script>
