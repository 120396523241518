import VehicleListSort from "../apps/i3-sort";
import mountApp from "../utils/mount-app";

export default {
  init() {
    this.addEvents();
    this.modifyDOM();
  },

  addEvents() {
  },

  modifyDOM() {
    const vehicleListSortSelector = '[data-app=i3-sort]';
    const $vehicleListSort = $(vehicleListSortSelector);

    $('.page-header__control--sort').append($vehicleListSort);

    mountApp({
      app: VehicleListSort,
      selector: vehicleListSortSelector,
      setI3Channel: true,
    });
  },
}
