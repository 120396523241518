import FieldTypes from "../../shared/wizard/constants/FieldTypes";

export default {
  ...FieldTypes,
  DepartmentNr: "departmentNr",
  Images: "images",
  Message: "message",
  RimSize: "rimSize",
  RimAmount: "rimAmount",
  RimType: "rimType",
  TyreHotel: "tyreHotel",
};
