var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.lang.Checkout.CustomerDetails.Ssn.Title) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group" },
        [
          _vm._l(_vm.ssnLookupFields, function(field) {
            return _c("SectionField", {
              key: field.type,
              attrs: { field: field, invalid: _vm.displayFieldInvalid(field) },
              on: {
                blur: function($event) {
                  return _vm.onFieldBlur(field)
                },
                change: _vm.onChange
              }
            })
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c(
              "button",
              {
                staticClass: "btn btn--primary btn--sm",
                class: { disabled: _vm.ssnLookupLoading },
                on: { click: _vm.onAutofillClick }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lang.Checkout.CustomerDetails.Ssn.Label) +
                    "\n      "
                )
              ]
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "checkout__why-ssn link u-c-muted",
          attrs: {
            href: "#",
            "data-target": _vm.checkoutGovernmentIdModal,
            "data-modal-source": "ajax"
          }
        },
        [
          _vm._v("Varför behöver vi personnummer "),
          _c("i", { staticClass: "far fa-question-circle" })
        ]
      ),
      _vm._v(" "),
      _vm.displaySsnInvalid
        ? _c("VcFeedbackMessage", { attrs: { danger: "", slim: "" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.lang.Checkout.CustomerDetails.Ssn.Error) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("label", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.lang.Checkout.CustomerDetails.Content.Title) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-row" },
        _vm._l(_vm.contactDetailsFields, function(field) {
          return _c("SectionField", {
            key: field.type,
            staticClass: "form-row__col form-row__col--md-50",
            attrs: { field: field, invalid: _vm.displayFieldInvalid(field) },
            on: {
              blur: function($event) {
                return _vm.onFieldBlur(field)
              },
              change: _vm.onChange
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }