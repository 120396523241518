<template>
  <NestedOptionBox
    :selected="option.selected"
    :heading="option.heading"
    :smallText="option.headingMeta"
    :expandText="option.subOptionsText"
    :modalContent="option.modalContent"
    :ribbonText="ribbonText"
    :price="option.price"
    :checkbox="multiselect"
    :subOptions="option.subOptions"
    :image="option.image"
    :largeImage="option.largeImage"
    :summationImage="option.summationImage"
    @click="onClick"
    @subOptionChange="onSubOptionChange"
  >
  </NestedOptionBox>
</template>

<script>
import NestedOptionBox from "../option-box/NestedOptionBox.vue";

export default {
  components: {
    NestedOptionBox
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    multiselect: Boolean
  },

  computed: {
    ribbonText() {
      if (this.option?.largeImage) {
        return this.option?.price?.attentionText;
      }
      return this.option?.ribbonText;
    }
  },
  methods: {
    onClick() {
      this.$emit("optionChange", !this.option.selected);
    },

    onSubOptionChange(e) {
      this.$emit("subOptionChange", e);
    }
  }
};
</script>
