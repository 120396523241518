var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isAdded
    ? _c(
        "div",
        { staticClass: "service-spot" },
        [
          _c("div", { staticClass: "service-spot__main" }, [
            _c("img", { attrs: { src: _vm.service.imageUrl } }),
            _vm._v(" "),
            _c("div", [
              _c("strong", [
                _vm._v("\n        " + _vm._s(_vm.service.name) + "\n      ")
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("VcPriceTag", {
                  attrs: { price: _vm.service.price, showIfZero: "" }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "service-spot__desc" }, [
            _c("span", {
              domProps: { innerHTML: _vm._s(_vm.service.description) }
            }),
            _vm._v(" "),
            _vm.descExpanded
              ? _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.service.modalContent) }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.service.modalContent
              ? _c("div", [
                  _c(
                    "a",
                    {
                      staticClass:
                        "link link--underline link--muted-light link--icon",
                      on: {
                        click: function($event) {
                          _vm.descExpanded = !_vm.descExpanded
                        }
                      }
                    },
                    [
                      _c("span", [_vm._v("Läs mer")]),
                      _vm._v(" "),
                      _c("FontAwesomeIcon", {
                        attrs: {
                          icon: _vm.descExpanded
                            ? _vm.faAngleUp
                            : _vm.faAngleDown
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.isAdded
            ? _c(
                "VcFeedbackMessage",
                { attrs: { success: "", slim: "" } },
                [
                  _c("FontAwesomeIcon", { attrs: { icon: _vm.faCheck } }),
                  _vm._v(
                    "\n    " +
                      _vm._s(_vm.service.name) +
                      " ligger nu i din varukorg!\n  "
                  )
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "btn btn--white btn--icon btn--sm btn--block",
                  on: {
                    click: function($event) {
                      return _vm.$emit("add")
                    }
                  }
                },
                [
                  _vm._v("\n    Lägg till\n    "),
                  _c("FontAwesomeIcon", { attrs: { icon: _vm.faPlus } })
                ],
                1
              ),
          _vm._v(" "),
          _vm.service.productMeta
            ? _c("div", {
                staticClass: "service-spot__meta",
                domProps: { innerHTML: _vm._s(_vm.service.productMeta) }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }