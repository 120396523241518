var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "i3-sort form-select" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedQuery,
            expression: "selectedQuery"
          }
        ],
        staticClass: "vehicle-listing-filter__sort__select",
        on: {
          change: [
            function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedQuery = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.onChange
          ]
        }
      },
      [
        _c("option", { attrs: { value: "" } }, [_vm._v("Sortera")]),
        _vm._v(" "),
        _vm._l(_vm.sortOptions, function(opt) {
          return _c(
            "option",
            { key: opt.query, domProps: { value: opt.query } },
            [_vm._v("\n      " + _vm._s(opt.displayName) + "\n    ")]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }