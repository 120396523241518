<template>
  <div class="vehicle-list__item" @click="onClick">
    <ProductSpot
      :product="product"
      :selected="selected"
      :loading="loading"
      :savedVehicles="savedVehicles"
      interactive>
    </ProductSpot>
  </div>
</template>

<script>
import ProductSpot from './ProductSpot.vue';

export default {
  components: {
    ProductSpot,
  },
  props: {
    product: Object,
    selected: Boolean,
    loading: Boolean,
    savedVehicles: Array,
  },
  methods: {
    onClick() {
      if (this.loading) {
        return;
      }

      this.$emit('click');
    },
  },
}
</script>
