<template>
  <div class="configurator-section trade-in-selector">
    <div v-if="fetchedValuation" >
      <div class="configurator-section__completed">
        <div class="details-bar">
          <div class="details-bar__title">
            {{ `${fetchedValuation.brandName} ${fetchedValuation.modelName} ${fetchedValuation.version}` }}
          </div>
          <div class="details-bar__item-list">
            {{ `${fetchedValuation.registrationNumber} • ${fetchedValuation.modelYear} • ${fetchedValuation.mileage}` }}
          </div>
        </div>
      </div>
      <button class="btn btn--muted btn--block" @click="removeValuation">
        <font-awesome-icon :icon="faTrashCan" class="vehicle-configurator__btn-icon"></font-awesome-icon>
        {{ lang.Summation.RemoveTradeIn }}
      </button>
    </div>
    <div v-else>
      <div class="form-row">
        <ConfiguratorTextInput
          inputKey="regnr-input"
          placeholder="Regnummer"
          useRegNrIcon
          @onChange="(value) => regNr = value"
        />
        <ConfiguratorTextInput
          inputKey="mileage-input"
          placeholder="Miltal"
          @onChange="(value) => mileage = value"
        />
      </div>
      <div class="form-row">
        <button class="btn btn--muted btn--block" :disabled="(!regNr || !mileage) || loading" @click="onAppraiseVehicle">
          <font-awesome-icon v-if="loading" :icon="faSpinnerThird" :spin="true" class="vehicle-configurator__btn-icon"></font-awesome-icon>
          Lägg till inbytesbil
        </button>
        <div v-if="appraiseError" class="vehicle-configurator__button-error">
          {{ appraiseError }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import gtm from '../../../utils/gtm';
import ConfiguratorTextInput from '../configurator-components/ConfiguratorTextInput.vue';
import store from '../store';
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';

export default {
  props: {
    lang: Object,
    valuationSubmitted: Boolean,
    currentVehicleRegNr: String,
  },
  components: {
    ConfiguratorTextInput,
    FontAwesomeIcon,
  },
  data() {
    return {
      fetchedValuation: null,
      regNr: '',
      mileage: '',
      email: '',
      requestSent: false,
      loading: false,
      appraiseError: '',
      faTrashCan,
      faSpinnerThird,
    }
  },
  mounted() {
    const valuation = store.state.valuation;
    if (valuation) {
      this.fetchedValuation = valuation;
    }
  },
  computed: {
    vehicleData() {
      return store.state.vehicle;
    },
  },
  methods: {
    async onAppraiseVehicle(e) {
      this.appraiseError = '';
      e.preventDefault();
      if (this.mileage === "" || this.regNr === "")
        return false;
      try {
        this.loading = true;
        const { data } = await Axios.get(
          `/api/vehicleConfigurator/${this.currentVehicleRegNr}/tradeIn?mileage=${this.mileage}&tradeInRegistrationNumber=${this.regNr}&email=${this.email}`
        );

        if (moment().year(data.modelYear).isBefore(moment().subtract(8, 'year')) || this.mileage > 15000) {
          this.appraiseError = "Du kan inte värdera ett fordon som gått mer än 15000 mil eller som är äldre än 8 år";
          return;
        }

        this.fetchedValuation = data;
        store.setValuation(data);
        this.$emit('onVehicleAppraised', this.regNr);
        gtm.configuratorQuickTradeInActivate();
      } catch (e) {
        console.log(e)

        if (e.response) {
          this.appraiseError = e.response.data.message;
        } else {
          this.appraiseError = "Något gick fel, var god försök igen";
        }
      } finally {
        this.loading = false;
      }
    },
    removeValuation() {
      this.regNr = '',
      this.mileage = '',
      this.fetchedValuation = null;
      store.setValuation(null);
      this.$emit('onValuationSubmitted', false);
      this.$emit('onVehicleAppraised', '');
    },
    async submitTradeIn() {
      if (this.email) {
        try {
          this.loading = true;
          await Axios.post('/api/vehicle/SaveTradeinRequest', {
            registrationNumber: this.regNr,
            customerEmail: this.email,
            mileage: this.mileage,
            city: {
              name: store.state.contact.departmentCity,
              value: store.state.contact.departmentEmail,
            },
            brand: this.fetchedValuation.brandName,
            model: this.fetchedValuation.modelName,
            version: this.fetchedValuation.version,
            modelYear: this.fetchedValuation.modelYear,
            pageUrl: window.location.toString(),
          });
          this.requestSent = true;
          this.$emit('onValuationSubmitted', true);
          gtm.valuationFormSubmit({
            brandName: this.fetchedValuation.brandName,
            vehicleType: this.vehicleData.vehicleType,
            departmentNr: this.department,
            vehicleCondition: 'Begagnad',
          })
        } catch (e) {
          console.warn(e);
        } finally {
          this.loading = false;
        }
      }
    },
  },
}
</script>
