import bugsnag from '@bugsnag/js';

const apiKey = 'a2062513ec74214dd4d30f98d1284930';
const bugsnagClient = bugsnag({
  apiKey,
  autoCaptureSessions: false,
  releaseStage: process.env.NODE_ENV,
});

export default bugsnagClient;
