var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SectionField", { attrs: { type: _vm.FieldTypes.Message } }),
      _vm._v(" "),
      _c("SectionField", { attrs: { type: _vm.FieldTypes.Images } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }