<template>
  <div>
    <table class="comparison-desktop-table">
      <tr class="comparison-desktop-table__row comparison-desktop-table__row--header">
        <td
          class="comparison-desktop-table__cell comparison-desktop-table__cell--header comparison-desktop-table__cell--label"
        ></td>
        <td
          v-for="vehicle in vehicles"
          :key="vehicle._id"
          class="comparison-desktop-table__cell comparison-desktop-table__cell--header"
        >
          <div class="comparison-desktop-table__header-image">
            <a :href="vehicle.carUrl">
              <img :src="`${vehicle.mainImage}?${ImageResizerPresets.ProductSpot}&404=${vehicle.fallbackimage}`" />
            </a>
            <div class="delete-btn" @click="$emit('delete-vehicle', vehicle.registrationnumber)">
              <i class="fas fa-trash"></i>
            </div>
          </div>
          <div class="comparison-desktop-table__header-content">
            <a class="comparison-desktop-table__header-content__title" :href="vehicle.carUrl">{{`${vehicle.brandname} ${vehicle.modelname}`}}</a>
            <div class="comparison-desktop-table__header-content__sub-title">{{vehicle.modeltext_nobrand}}</div>
          </div>
        </td>
      </tr>
      <tr
        v-for="(row, index) in rows"
        :key="`${row.label}-${uuidv4()}`"
        class="comparison-desktop-table__row"
        :class="{
          'white-bg': index % 2 === 1,
        }"
      >
        <td
          class="comparison-desktop-table__cell comparison-desktop-table__cell--label"
          :class="{
            'white-bg': index % 2 === 1,
          }"
        >
          {{ row.label }}
        </td>
        <td
          v-for="cell in row.vehicleCells"
          :key="`${cell}-${uuidv4()}`"
          class="comparison-desktop-table__cell"
        >
          <div>
            {{ cell.primaryValue }}
            <span v-if="cell.secondaryValue" class="comparison-desktop-table__cell__secondary-value">{{ cell.secondaryValue }}</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import ImageResizerPresets from "../../constants/ImageResizerPresets";
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    labels: {
      required: true,
      type: Array,
    },
    vehicles: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      ImageResizerPresets,
      uuidv4,
    };
  },
  computed: {
    rows() {
      const fieldsWithLabel = this.labels.filter(label => label.label);
      const row = fieldsWithLabel.map(label => {
        const vehicleValues = this.vehicles.map(vehicle => {
          let primaryValue = vehicle[label.key];
          let secondaryValue = null;
          if (label.key === 'currentprice') {
            const hasDiscount = vehicle.ordinaryprice > vehicle.currentprice;
            secondaryValue = hasDiscount ? this.$formatPrice(vehicle.ordinaryprice) : null;
            primaryValue = this.$formatPrice(vehicle.currentprice);
          } else if (label.key === 'registrationdate' && vehicle[label.key]) {
            primaryValue = vehicle[label.key].slice(0, 10);
          } else if (label.key === 'inspectionapproved' && vehicle[label.key]) {
            primaryValue = vehicle[label.key].slice(0, 10);
          } else if (label.key === 'usps' && vehicle[label.key] && Array.isArray(vehicle[label.key])) {
            primaryValue = vehicle[label.key].join(', ');
          } else {
            primaryValue = label.unit && primaryValue ? `${primaryValue} ${label.unit}` : primaryValue;
          }
          return {
            primaryValue,
            secondaryValue,
            hasUnit: !!label.unit,
          }
        })
        return {
          label: label.label,
          vehicleCells: vehicleValues,
        }
      })

      return row;
    },
    columnLength() {
      return this.vehicles.length + 1;
    },
  },
}
</script>
