var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VcModal",
    {
      attrs: { modalBodyClass: "search-watch__modal" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("\n    Bevakning\n  ")
      ]),
      _vm._v(" "),
      !_vm.hasCustomContent
        ? _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _vm.isContactSubmit || _vm.isContactSubmitProp
                ? _c("VcFeedbackMessage", { attrs: { success: "" } }, [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.feedbackContactHeader) +
                          "\n      "
                      )
                    ]),
                    _vm._v(
                      "\n      " + _vm._s(_vm.feedbackContactText) + "\n    "
                    )
                  ])
                : _c("VcFeedbackMessage", { attrs: { success: "" } }, [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.feedbackHeader) + "\n      "
                      )
                    ]),
                    _vm._v("\n      " + _vm._s(_vm.feedbackText) + "\n    ")
                  ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "search-watch__modal__form form-feedback-host",
                  staticStyle: { "margin-top": "15px" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _c("h5", [
                    _vm.emailFound
                      ? _c("strong", [
                          _vm._v(
                            "\n          OBS! " +
                              _vm._s(_vm.email) +
                              " är kopplad till ett konto\n        "
                          )
                        ])
                      : _c("strong", [
                          _vm._v(
                            "\n         Vill du även skapa ett konto?\n        "
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm.emailFound
                      ? _c("small", [
                          _vm._v(
                            "\n          Logga in för att hantera dina bevakningar. Har du glömt ditt lösenord? "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.onForgotPasswordClick($event)
                                }
                              }
                            },
                            [_vm._v(" Klicka här")]
                          )
                        ])
                      : _c("small", [
                          _c("i", { staticClass: "fas fa-check" }),
                          _vm._v(" Favoritmarkera bilar\n          "),
                          _c("i", { staticClass: "fas fa-check" }),
                          _vm._v(" Spara och hantera bevakningar\n          "),
                          _c("i", { staticClass: "fas fa-check" }),
                          _vm._v(" Se dina ordrar\n        ")
                        ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-row" }, [
                    _c(
                      "div",
                      { staticClass: "form-row__col form-row__col--md-50" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-stack form-stack--required" },
                          [
                            _c("label", [
                              _vm._v("\n              E-post\n            ")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.inputEmail,
                                  expression: "inputEmail"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "email",
                                autocomplete: "email",
                                placeholder: "E-post",
                                required: ""
                              },
                              domProps: { value: _vm.inputEmail },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.inputEmail = $event.target.value
                                }
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-row__col form-row__col--md-50" },
                      [
                        _c(
                          "div",
                          { staticClass: "form-stack form-stack--required" },
                          [
                            _c("label", [
                              _vm._v("\n              Lösenord\n            ")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "password",
                                placeholder: _vm.emailPlaceHolder,
                                required: ""
                              },
                              domProps: { value: _vm.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.password = $event.target.value
                                }
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn--primary btn--block",
                      attrs: { disabled: _vm.btnDisabled, type: "submit" }
                    },
                    [_vm._v("\n          " + _vm._s(_vm.btnText) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _vm.errorMessage.length
                    ? _c(
                        "VcFeedbackMessage",
                        { attrs: { danger: "", slim: "" } },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.registerSuccess
                    ? _c(
                        "VcFeedbackMessage",
                        { attrs: { success: "", slim: "" } },
                        [
                          _vm._v(
                            "\n        Registreringen lyckades. Ett verifieringsmail har nu skickats till er " +
                              _vm._s(this.inputEmail) +
                              " där det finns intruktioner om hur ni verifierar er e-post.\n      "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "form-text",
                    domProps: { innerHTML: _vm._s(_vm.privacyPolicyText) }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._t("customContent", null, { slot: "content" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }