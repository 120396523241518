<template>
  <div>
    <label v-if="option.heading" class="option-box__content-header">
      {{option.heading}}
    </label>

    <div v-if="option.text" v-html="option.text">
    </div>

    <VcOptionBox v-for="value in option.values"
      :key="value.name"
      :selected="value.selected"
      :heading="value.name"
      :smallText="value.subtitle"
      smallBlock
      :checkbox="isCheckbox"
      @click="onClick(value)">
    </VcOptionBox>
  </div>
</template>

<script>
import { VcOptionBox } from '@holmgrensbil/vue-components';
import InputTypes from '../../../constants/InputTypes';

export default {
  components: {
    VcOptionBox,
  },
  props: {
    option: Object,
    default: () => {}
  },
  computed: {
    isCheckbox() {
      return this.option.inputType === InputTypes.Checkbox;
    },
  },
  methods: {
    onClick({ value }) {
      const values = this.option.values.map(v => {
        if (v.value === value) {
          return {
            ...v,
            selected: !v.selected,
          };
        }

        return v;
      });
      const selected = values.filter(v => v.selected);

      this.$emit('change', selected.map(s => s.value));
    },
  },
}
</script>
