<template>
  <OverlayScrollable class="i3-site-search">
    <OverlayHeader slot="header" text="Sök" @close="onClose"> </OverlayHeader>

    <div slot="content">
      <VcSearchBar
        placeholder="Sök fordon, anläggningar och mer..."
        noInputBorders
        :disableCancel="disableSearchCancel"
        v-model="searchString"
        @input="onSearchInput"
      >
      </VcSearchBar>
      <TheResults
        v-if="(searchString || selectedPopularSearch) && !loading"
        :components="components"
        :searchString="searchString"
      >
      </TheResults>
      <TheSuggestions
        v-else-if="!searchString"
        :popularSearches="popularSearches"
        @select="onPopularSearchSelect"
      >
      </TheSuggestions>
    </div>
  </OverlayScrollable>
</template>

<script>
import { VcSearchBar } from "@holmgrensbil/vue-components";
import debounce from "debounce";
import ChannelEvents from "../../constants/ChannelEvents";
import I3QueryParams from "../../constants/I3QueryParams";
import ChannelQuery from "../../models/ChannelQuery";
import TheResults from "./TheResults.vue";
import TheSuggestions from "./TheSuggestions.vue";
import Breakpoints from "../../constants/Breakpoints";
import OverlayHeader from "../shared/OverlayHeader.vue";
import OverlayScrollable from "../shared/OverlayScrollable.vue";

export default {
  components: {
    OverlayHeader,
    OverlayScrollable,
    VcSearchBar,
    TheResults,
    TheSuggestions
  },
  props: {
    channel: Object,
    onClose: Function
  },
  created() {
    this.searchDebounced = debounce(this.search.bind(this), 1000);
    this.popularSearches = this.channel.popularSearches;
    this.channel.on(ChannelEvents.QuerySuccess, this.onQuerySuccess);
    this.onQuerySuccess();
  },
  destroyed() {
    this.channel.off(ChannelEvents.QuerySuccess, this.onQuerySuccess);
  },
  data() {
    return {
      components: [],
      searchString: null,
      loading: false,
      popularSearches: [],
      selectedPopularSearch: false
    };
  },
  computed: {
    disableSearchCancel() {
      return this.windowWidth >= Breakpoints.md;
    }
  },
  methods: {
    onQuerySuccess() {
      this.components = this.channel.data.components.components || [];
      this.loading = false;
    },

    onPopularSearchSelect(popularSearch) {
      const { origin, MainVehicleSearchPage } = window;
      window.location.href = `${origin}${MainVehicleSearchPage}${popularSearch.query}`;
    },

    onSearchInput(value) {
      this.loading = true;
      this.selectedPopularSearch = false;
      this.searchDebounced(value);
    },

    search(str) {
      const query = new ChannelQuery(this.channel.defaultQuery);

      if (str) {
        query.setParam(I3QueryParams.FreeText, `${str.replaceAll('-', ' ')}*`);
        this.channel.execute(query);
      }
    }
  }
};
</script>
