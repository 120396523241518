var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    Fyll i dina däckdimensioner\n  ")]),
      _vm._v(" "),
      _c("VisualTyre", { key: _vm.tyreText, attrs: { text: _vm.tyreText } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "choose-dimensions__form" },
        [
          _c(
            "VcFormStack",
            { attrs: { label: "Bredd" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: _vm.widthOptions,
                  value: _vm.tyreSize.width,
                  prompt: "Välj bredd"
                },
                on: { change: _vm.selectWidth }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VcFormStack",
            { attrs: { label: "Profil" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: _vm.aspectRatioOptions,
                  value: _vm.tyreSize.aspectRatio,
                  prompt: "Välj profil"
                },
                on: { change: _vm.selectAspectRatio }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VcFormStack",
            { attrs: { label: "Dimension" } },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: _vm.diameterOptions,
                  value: _vm.tyreSize.diameter,
                  prompt: "Välj dimension"
                },
                on: { change: _vm.selectDiameter }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }