<template>
  <div
    class="filter-bar__tag"
    :class="{
      'filter-bar__tag--child': isChild,
    }"
    @click="remove"
  >
    {{label}}
    <span v-if="!locked" @click="$emit('remove')">
      <i class="fal fa-times filter-bar__tag__icon"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    locked: Boolean,
    isChild: Boolean,
  },
  methods: {
    remove() {
      if (!this.locked) {
        this.$emit('remove')
      }
    }
  }
}
</script>
