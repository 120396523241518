<template>
  <div class="form-row">
    <div class="form-row__col form-row__col--md-50">
      <SectionField :type="FieldTypes.Date">
      </SectionField>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';

export default {
  components: {
    SectionField,
  },
  data() {
    return {
      FieldTypes,
    };
  },
}
</script>
