<template>
  <div class="form-row date-wizard">
    <div class="form-row__col">
      <div class="date-wizard__label-container">
        <span class="date-wizard__label-container__label">
          Välj datum
        </span>
      </div>
      <div v-if="datesLoading" class="u-align-center">
        <FontAwesomeIcon
          :icon="faSpinnerThird"
          :spin="true"
          class="wizard--service-booking__loader">
        </FontAwesomeIcon>
      </div>
      <VcFeedbackMessage v-else-if="dates.length === 0" info center>
        <strong>
          {{lang.App.NoTimesMessage}}
        </strong>
      </VcFeedbackMessage>
      <SectionField v-else :type="FieldTypes.Date" @change="onDateChange">
      </SectionField>
    </div>
    <div class="form-row__col form-row__col--md-50">
      <div v-if="timesLoading" class="u-align-center">
        <FontAwesomeIcon
          :icon="faSpinnerThird"
          :spin="true"
          class="wizard--service-booking__loader">
        </FontAwesomeIcon>
      </div>
      <SectionField v-else :type="FieldTypes.Time" @change="onTimeChange">
      </SectionField>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import FieldTypes from './constants/FieldTypes';
import SectionField from '../shared/wizard/SectionField.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import { VcFeedbackMessage } from '@holmgrensbil/vue-components';

export default {
  components: {
    SectionField,
    FontAwesomeIcon,
    VcFeedbackMessage,
  },
  data() {
    return {
      FieldTypes,
      selectedTime: null,
      faSpinnerThird,
    };
  },
  methods: {
    ...mapActions({
      fetchAvailableTimes: 'times/fetchTimes',
      setField: 'fields/set',
    }),
    async onDateChange(input) {
      const selectedTyreService = this.fieldByType(FieldTypes.TyreServices).options.find(option => option.name === this.fieldByType(FieldTypes.TyreServices).value[0].name);

      await this.fetchAvailableTimes({
        departmentNr: this.fieldByType(FieldTypes.DepartmentNr).value,
        date: input.value,
        regNr: this.fieldByType(FieldTypes.RegNr).value,
        service: this.service.value,
        serviceType: selectedTyreService.id,
      });
    },
    async onTimeChange(input) {
      this.setField({ type: input.type, value: input.value.split('-')[0] });
    },
  },
  computed: {
    ...mapState({
      lang: state => state.config.lang,
      times: state => state.times.items,
      dates: state => state.dates.items,
      dateFetchState: state => state.dates.fetchState,
      timesFetchState: state => state.times.fetchState,
      service: state => state.service.service,
    }),
    ...mapGetters({
      fieldByType: 'fields/byType',
    }),
    datesLoading() {
      return this.dateFetchState === 1;
    },
    timesLoading() {
      return this.timesFetchState === 1;
    },
  },
}
</script>
