<template>
  <form class="contact-forms__form form-feedback-host">
    <div v-if="cities.length">
      <div class="form-stack">
        <label> Välj anläggning </label>
        <div class="form-select">
          <select
            class="form-control"
            v-model="selectedLocation"
            @change="onLocationChange()"
          >
            <option value="">Välj anläggning</option>
            <option
              v-for="location in cities"
              :value="location.value"
              :key="location.value"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="department" class="info-block">
      <i class="info-block__icon fas fa-map-marker-alt"> </i>

      <div class="info-block__header">
        {{ department.departmentName }}
      </div>

      <div class="u-c-muted">
        {{ department.departmentAddress }} <br />
        {{ department.departmentZip }} {{ department.departmentCity }}
      </div>
    </div>
    <div v-if="department">
      <div class="form-row">
        <div class="form-row__col form-row__col--md-50">
          <div class="form-stack form-stack--required">
            <label> Namn </label>
            <input
              class="form-control"
              type="text"
              autocomplete="name"
              name="customerName"
              placeholder="Ditt för- &amp; efternamn"
              required=""
              v-model="name"
            />
          </div>
        </div>
        <div class="form-row__col form-row__col--md-50">
          <div class="form-stack form-stack--required">
            <label> E-post </label>
            <input
              class="form-control"
              type="email"
              autocomplete="email"
              name="customerEmail"
              placeholder="Din e-postadress"
              required=""
              v-model="email"
            />
          </div>
        </div>
      </div>

      <div class="form-stack form-stack--required">
        <label> Meddelande </label>
        <textarea
          class="form-control"
          name="customerMessage"
          placeholder="Skriv ditt meddelande..."
          required=""
          v-model="message"
        ></textarea>
      </div>
    </div>

    <button
      v-if="department && !messageSuccess"
      type="button"
      :class="{ disabled: !maySend }"
      class="btn btn--primary btn--block"
      @click="sendContactForm"
    >
      Skicka meddelande
    </button>

    <div class="form-text" v-if="department">
      <p>
        Alla personuppgifter som skickas in till Holmgrens Bil kommer att
        behandlas enligt bestämmelserna i EU:s dataskyddsförordningen
        (GDPR).&nbsp;<span style="text-decoration: underline"
          ><a
            title="Läs om hur vi behandlar din personuppgifter"
            href="/om-oss/personuppgiftspolicy"
            >Här</a
          ></span
        >&nbsp;kan du läsa mer om hur vi behandlar dina personuppgifter.
      </p>
    </div>

    <div class="form-feedback" :class="{ active: messageSuccess }">
      <div class="loader loader--success">
        <div class="loader__shape">
          <div class="loader__symbol"></div>
        </div>
        <div class="loader__header">Tack för ditt intresse!</div>
        <div class="loader__text">Vi hör av oss inom kort.</div>
      </div>
    </div>
  </form>
</template>

<script>
import Axios from "../../axios";
import GtmActions from '../../constants/GtmActions';
import GtmEvents from '../../constants/GtmEvents';
import gtm from "../../utils/gtm";
export default {
  props: {
    cities: {
      type: Array,
    },
    department: {
      type: Object,
    },
    service: {
      type: String,
    },
    submittedSelectedLocation: {
      type: String,
    }
  },

  data() {
    return {
      selectedLocation: "",
      name: "",
      message: "",
      email: "",
      messageSuccess: false,
      messageError: false,
      loading: false,
    };
  },
  mounted() {
    this.selectedLocation = this.submittedSelectedLocation;
  },
  computed: {
    maySend() {
      return (
        this.department &&
        this.email.length &&
        this.name.length &&
        this.message.length &&
        !this.loading
      );
    },
  },
  methods: {
    onLocationChange() {
      this.$emit('onLocationChange', this.selectedLocation);
    },
    async sendContactForm() {
      try {
        this.loading = true;
        await Axios.post("/api/leads/ContactForm", {
          currentPageUrl: window.location.href,
          requestType: 4,
          departmentNr: this.department.departmentNr,
          departmentName: this.department.departmentName,
          service: this.service,
          customerName: this.name,
          customerEmail: this.email,
          customerMessage: this.message
        });
        gtm.contactFormSubmit({
          action: GtmActions.SubmitEmail,
          event: GtmEvents.Contact,
          departmentNr: this.department.departmentNr,
          formType: 'quickForm',
        })
        this.messageSuccess = true;
      } catch (e) {
        this.messageError = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
