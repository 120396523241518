var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.departmentSelected
    ? _c(
        "div",
        { staticClass: "wizard wizard--service-top wizard--glass-repair" },
        [
          _c("SectionList", {
            attrs: {
              sections: _vm.sections,
              SectionTypeComponents: _vm.SectionTypeComponents
            },
            on: { completed: _vm.onSectionCompleted }
          }),
          _vm._v(" "),
          _c("FormSubmit", {
            attrs: {
              lastSection: _vm.lastSection,
              buttonText: "Skicka förfrågan"
            },
            on: { completed: _vm.onSectionCompleted }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }