<template>
  <div class="bottom-action-bar">
    <div v-if="vehicleData" class="bottom-action-bar__data">
      <div class="bottom-action-bar__vehicle">
        <div class="bottom-action-bar__image">
          <img :src="vehicleData.image" />
        </div>
        <div class="bottom-action-bar__vehicle__info">
          <div class="bottom-action-bar__vehicle__title">
            {{ `${vehicleData.brandName} ${vehicleData.modelName}` }}
          </div>
          <div class="bottom-action-bar__vehicle__subtitle">
            {{ vehicleData.modelText }}
          </div>
        </div>
      </div>
      <div v-if="priceDisplay" class="bottom-action-bar__price">
        <div class="bottom-action-bar__price__container">
          <div
            class="price-tag"
            :class="{
              'price-tag--discount': priceDisplay.current < priceDisplay.ordinary,
            }"
          >
            {{ $formatPriceNoSuffix(priceDisplay.current.toFixed()) + priceDisplay.suffix }}
          </div>
          <div v-if="priceDisplay.current < priceDisplay.ordinary" class="price-tag--obsolete">
            {{ $formatPriceNoSuffix(priceDisplay.ordinary.toFixed()) + priceDisplay.suffix }}
          </div>
        </div>
        <div class="bottom-action-bar__price__pricing-meta">
          {{ priceMetaString }}
        </div>
      </div>
    </div>
    <div v-if="contact" class="bottom-action-bar__actions">
      <a href="#" class="btn btn--primary" @click="onOpenContactModal">
          <font-awesome-icon :icon="faEnvelope" class="vehicle-configurator__btn-icon"></font-awesome-icon>
          {{ lang.Contact.ContactUs }}
      </a>
      <a
        :href="showNumber ? `tel:${departmentPhoneNumber}` : '#'"
        class="btn btn--muted btn--block"
        @click="onPhoneClick"
      >
        <font-awesome-icon :icon="faPhone" class="vehicle-configurator__btn-icon"></font-awesome-icon>
        <span v-if="!showNumber">
          {{ 'Visa telefonnummer' }}
        </span>
        <span v-else>
          {{ departmentPhoneNumber }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { EventBus } from '../../utils/event-bus';
import gtm from '../../utils/gtm';
import { getPriceMetaString } from '../../utils/strings';
import CalculationKeys from "../main-action/constants/calculation-keys";
import { monthlyCost } from "../../utils/priceCalculations";

export default {
  props: {
    lang: Object,
  },
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      showNumber: false,
      contact: null,
      vehicleData: null,
      regNr: '',
      selectedFinancingOption: '',
      displayTax: true,
      faPhone,
      faEnvelope,
    }
  },
  computed: {
    departmentPhoneNumber() {
      return this.contact ? this.contact.departmentPhone : "";
    },
    priceMetaString() {
      return getPriceMetaString(this.displayTax, this.lang, this.selectedFinancingOption);
    },
    priceDisplay() {
      if (this.selectedFinancingOption) {
        if ([CalculationKeys.Set, CalculationKeys.SetMonthly].includes(this.selectedFinancingOption.calculationType)) {
          return {
            current: this.selectedFinancingOption.financing.currentPrice,
            ordinary: this.selectedFinancingOption.financing.ordinaryPrice,
            suffix: this.selectedFinancingOption.calculationType === CalculationKeys.Set ? " kr" : " kr/månad",
          };
        } else {
          return {
            current: monthlyCost(this.selectedFinancingOption.financing),
            ordinary: monthlyCost(this.selectedFinancingOption.financing, this.selectedFinancingOption.financing.ordinaryPrice),
            suffix: " kr/månad",
          };
        }
      }
    },
  },
  created() {
    EventBus.$on("alter-tax", this.alterDisplayTax.bind(this));
    EventBus.$on('set-vehicle-data', this.onFetchData.bind(this));
  },
  methods: {
    alterDisplayTax(shouldDisplayTax) {
      this.displayTax = shouldDisplayTax;
    },
    onFetchData({
      selectedFinancingOption,
      regNr,
      contact,
      vehicle
    }) {
      this.contact = contact;
      this.vehicleData = vehicle;
      this.regNr = regNr;
      this.selectedFinancingOption = selectedFinancingOption;
    },
    onPhoneClick(e) {
      if (!this.showNumber) {
        e.preventDefault();
      }

      const departmentNr = this.contact.departmentNr;
      const phoneNr = this.contact.departmentPhone;
      const vehicleCondition = this.vehicleData.condition;
      const brandName = this.vehicleData.brandName;

      gtm.phoneNumberClick({
        departmentNr,
        phoneNr,
        vehicleCondition: vehicleCondition,
        brandName: brandName
      });

      this.showNumber = true;
    },
    onOpenContactModal(e) {
      e.preventDefault();
      const productUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
      EventBus.$emit('open-vehicle-contact-modal', { regNr: this.regNr, productUrl });
    }
  }
}
</script>
