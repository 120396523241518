var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "choose-spec choose-spec--multi",
      class: { "choose-spec--promo": _vm.promo },
      style: {
        backgroundImage: _vm.promoBg ? "url(" + _vm.promoBg + ")" : "none"
      }
    },
    [
      _c("div", { staticClass: "choose-spec__content" }, [
        _c("div", [
          _vm.meta
            ? _c("div", {
                staticClass: "promo__meta",
                domProps: { innerHTML: _vm._s(_vm.meta) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.header
            ? _c("h1", { staticClass: "promo__header" }, [
                _vm._v("\n        " + _vm._s(_vm.header) + "\n      ")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-step",
            class: {
              "form-step--completed": _vm.vehicle,
              "form-step--danger": _vm.regNrError
            }
          },
          [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onSubmit($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "form-row choose-spec__row" }, [
                  _c("div", { staticClass: "regnr-input" }, [
                    _c("div", { staticClass: "input-group" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-stack" }, [
                        _c("label", [
                          _vm._v(
                            "\n                  Regnummer\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regNr,
                              expression: "regNr"
                            }
                          ],
                          staticClass: "form-control",
                          staticStyle: { color: "#18191a" },
                          attrs: {
                            id: "regNr-input",
                            type: "text",
                            placeholder: "ABC123",
                            required: "",
                            pattern: "^\\w{3}\\d{2}(\\w{1}|\\d{1})$"
                          },
                          domProps: { value: _vm.regNr },
                          on: {
                            keyup: function($event) {
                              return _vm.onChange()
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.regNr = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.isMobile
                    ? _c(
                        "div",
                        {
                          staticClass: "choose-spec__options",
                          class: {
                            "choose-spec__options--standard":
                              !_vm.vehicle && !_vm.regNrError,
                            "choose-spec__options--success": _vm.vehicle,
                            "choose-spec__options--error": _vm.regNrError
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "choose-spec__options-header" },
                            [
                              _vm.vehicle
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.vehicleString))
                                  ])
                                : _vm.regNrError
                                ? _c("span", [_vm._v(_vm._s(_vm.regNrError))])
                                : _c("span", [
                                    _vm._v("Har du inget regnummer?")
                                  ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "inline-bullets" }, [
                            !_vm.vehicle
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "inline-bullets__item",
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("chooseVehicleClick")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "a",
                                      { staticClass: "link link--underline" },
                                      [_vm._v("Välj bilmodell")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "inline-bullets__item",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "chooseTyreDimensionsClick"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "a",
                                  { staticClass: "link link--underline" },
                                  [_vm._v("Ange däckdimensioner")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.vehicle
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "inline-bullets__item",
                                    on: { click: _vm.clearVehicle }
                                  },
                                  [
                                    _c(
                                      "a",
                                      { staticClass: "link link--underline" },
                                      [_vm._v("Byt fordon")]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "choose-spec__action-container" },
                    [
                      _c(
                        "VcFormStack",
                        { attrs: { label: "Produkt" } },
                        [
                          _c("VcFormSelect", {
                            attrs: {
                              options: [
                                {
                                  value: "tyre",
                                  label: _vm.lang.Tyre.TyreLinkBtn
                                },
                                {
                                  value: "wheel",
                                  label: _vm.lang.Tyre.WheelLinkBtn
                                },
                                {
                                  value: "rim",
                                  label: _vm.lang.Tyre.RimLinkBtn
                                }
                              ],
                              value: _vm.productType,
                              labelProp: "label",
                              valueProp: "value",
                              valueType: "string",
                              required: ""
                            },
                            on: {
                              change: function($event) {
                                _vm.productType = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "VcFormStack",
                        { attrs: { label: "Säsong" } },
                        [
                          _c("VcFormSelect", {
                            attrs: {
                              options: [
                                {
                                  value: _vm.EonTyreTypes.Summer,
                                  label: "Sommar"
                                },
                                {
                                  value: _vm.EonTyreTypes.WinterStudded,
                                  label: "Vinter dubb"
                                },
                                {
                                  value: _vm.EonTyreTypes.WinterFriction,
                                  label: "Vinter friktion"
                                }
                              ],
                              value: _vm.tyreType,
                              labelProp: "label",
                              valueProp: "value",
                              valueType: "number",
                              required: ""
                            },
                            on: {
                              change: function($event) {
                                _vm.tyreType = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn--primary",
                          class: {
                            disabled: !_vm.vehicle
                          },
                          on: { click: _vm.onSubmit }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s("Se produkter") +
                              "\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            !_vm.isMobile
              ? _c(
                  "div",
                  {
                    staticClass: "choose-spec__options",
                    class: {
                      "choose-spec__options--standard":
                        !_vm.vehicle && !_vm.regNrError,
                      "choose-spec__options--success": _vm.vehicle,
                      "choose-spec__options--error": _vm.regNrError
                    }
                  },
                  [
                    _c("div", { staticClass: "choose-spec__options-header" }, [
                      _vm.vehicle
                        ? _c("span", [_vm._v(_vm._s(_vm.vehicleString))])
                        : _vm.regNrError
                        ? _c("span", [_vm._v(_vm._s(_vm.regNrError))])
                        : _c("span", [_vm._v("Har du inget regnummer?")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "inline-bullets" }, [
                      !_vm.vehicle
                        ? _c(
                            "div",
                            {
                              staticClass: "inline-bullets__item",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("chooseVehicleClick")
                                }
                              }
                            },
                            [
                              _c("a", { staticClass: "link link--underline" }, [
                                _vm._v("Välj bilmodell")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "inline-bullets__item",
                          on: {
                            click: function($event) {
                              return _vm.$emit("chooseTyreDimensionsClick")
                            }
                          }
                        },
                        [
                          _c("a", { staticClass: "link link--underline" }, [
                            _vm._v("Ange däckdimensioner")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.vehicle
                        ? _c(
                            "div",
                            {
                              staticClass: "inline-bullets__item",
                              on: { click: _vm.clearVehicle }
                            },
                            [
                              _c("a", { staticClass: "link link--underline" }, [
                                _vm._v("Byt fordon")
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              : _vm._e()
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }