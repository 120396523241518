<template>
  <div>
    <VcOptionBox
      :selected="selected"
      :heading="heading"
      :smallText="smallText"
      :smallBlock="smallText && smallText.length > 15"
      :checkbox="checkbox"
      :image="image"
      :attentionRibbon="ribbonText"
      :largeImage="largeImage"
      :summationImage="summationImage"
      @click="$emit('click')"
      @linkClick="onLinkClick"
    >
      <div
        v-if="price && price.attentionText && !image"
        slot="header-aside"
        class="price-tag__campaign"
      >
        <div class="price-tag__campaign__tag">
          <span class="price-tag--discount" v-html="price.attentionText"></span>
        </div>
        <div>
          <VcPriceTag :price="price" :discounted="discounted" showIfZero>
          </VcPriceTag>
        </div>
      </div>
      <div v-else-if="price" slot="header-aside">
        <VcPriceTag
          :price="price"
          :discounted="discounted"
          :show-if-zero="showIfZero"
        >
        </VcPriceTag>
      </div>
      <div slot="header-link" v-if="expandText">
        <span>{{ expandText }}</span>
        <FontAwesomeIcon :icon="expanded ? faAngleUp : faAngleDown">
        </FontAwesomeIcon>
      </div>
      <div slot="header-link" v-else-if="modalContent">
        <span>Läs mer</span>
      </div>
      <div
        class="form-row"
        slot="content"
        v-if="displayContent && !externalUrl"
      >
        <component
          v-for="subOption in subOptions"
          :key="subOption.name"
          :is="SubOptionComponents[subOption.inputType]"
          :option="subOption"
          @change="$emit('subOptionChange', { subOption, value: $event })"
        >
        </component>
      </div>
      <div class="form-row" slot="content" v-if="displayContent && externalUrl">
        <h4>Bokning sker på tillverkarens egna sida</h4>
        <p>
          För tillgång till det senaste serviceprogrammet bokas service hos
          tillverkaren
        </p>
        <div class="option-box__actions">
          <a
            :href="externalUrl"
            @click="externalBookingClick"
            class="btn btn--primary"
            target="_blank"
            >Boka här</a
          >
        </div>
      </div>
    </VcOptionBox>

    <VcModal v-if="modalOpen" @close="modalOpen = false">
      <div slot="header">
        {{ heading }}
      </div>
      <div slot="content" v-html="modalContent"></div>
    </VcModal>
  </div>
</template>

<script>
import { VcOptionBox, VcModal, VcPriceTag } from "@holmgrensbil/vue-components";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SubOptionComponents from "./constants/SubOptionComponents";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import gtm from "../../../utils/gtm";
import { mapGetters } from 'vuex';
import FieldTypes from '../../service-booking/constants/FieldTypes';
import ServiceBookingManager from '../../../models/ServiceBookingManager';

export default {
  components: {
    VcOptionBox,
    VcModal,
    VcPriceTag,
    FontAwesomeIcon
  },
  data() {
    return {
      SubOptionComponents,
      expanded: false,
      modalOpen: false,
      faAngleUp,
      faAngleDown
    };
  },
  props: {
    selected: {
      type: Boolean
    },
    ribbonText: {
      type: String
    },
    heading: {
      type: String
    },
    smallText: {
      type: String
    },
    expandText: {
      type: String
    },
    modalContent: {
      type: String
    },
    price: {
      type: Object
    },
    checkbox: {
      type: Boolean
    },
    subOptions: {
      type: Array,
      default: () => []
    },
    image: {
      type: String
    },
    largeImage: {
      type: Boolean
    },
    summationImage: {
      type: String
    },
    externalUrl: {
      type: String
    }
  },
  computed: {
    displayContent() {
      const hasSubOptions = this.subOptions && this.subOptions.length;
      const isExpandable = !!this.expandText;
      const hasExternalUrl = !!this.externalUrl;

      if (hasExternalUrl) {
        return this.selected;
      }

      if (!hasSubOptions) {
        return false;
      }

      if (isExpandable) {
        return this.selected && this.expanded;
      }

      return this.selected;
    },
    showIfZero() {
      return this.price.showIfZero;
    },
    discounted() {
      return this.price.attentionText && this.price.attentionText.length > 0;
    },
    ...mapGetters({
      fieldByType: 'fields/byType',
    })
  },
  methods: {
    onLinkClick() {
      if (!this.selected) {
        this.$emit("click");
      }

      if (this.expandText) {
        this.expanded = !this.expanded;
      } else if (this.modalContent) {
        this.modalOpen = true;
      }
    },
    externalBookingClick() {
      let brand;
      if (ServiceBookingManager.carInfo) {
        brand = ServiceBookingManager.carInfo.brand;
      }
      const department = this.fieldByType(FieldTypes.DepartmentNr).value;
      gtm.serviceBookingExternalClick({brand, department});
    }
  }
};
</script>
