var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSelectableOptions()
    ? _c(
        "div",
        { staticClass: "product-upsell" },
        [
          _c(
            "div",
            {
              ref: "swiperContainer",
              staticClass: "swiper-container product-upsell__container"
            },
            [
              _c(
                "div",
                { staticClass: "swiper-wrapper" },
                _vm._l(
                  _vm.optionsWithPrice.filter(function(opt) {
                    return !opt.hidden
                  }),
                  function(option) {
                    return _c(
                      "div",
                      {
                        key: option.id,
                        staticClass: "product-upsell__item swiper-slide"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "product-upsell__content-header" },
                          [
                            option.headingMeta
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ribbon ribbon--attention ribbon--right"
                                  },
                                  [_vm._v(_vm._s(option.headingMeta))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "product-upsell__content-header__text"
                                  },
                                  [_vm._v(_vm._s(option.heading))]
                                ),
                                _vm._v(" "),
                                _c("VcPriceTag", {
                                  attrs: { price: option.price }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            option.image
                              ? _c("img", {
                                  staticClass:
                                    "product-upsell__content-header__image product-upsell__content-header__image--interactive",
                                  attrs: { src: option.image },
                                  on: {
                                    click: function($event) {
                                      return _vm.onOpenImageGallery(option.id)
                                    }
                                  }
                                })
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "product-upsell__content",
                          domProps: { innerHTML: _vm._s(option.modalContent) }
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            class: {
                              "btn btn--primary btn--icon btn--sm btn--block":
                                option.selected,
                              "btn btn--white btn--icon btn--sm btn--block": !option.selected
                            },
                            on: {
                              click: function($event) {
                                return _vm.onAddClick(option)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(option.selected ? "Vald" : "Lägg till") +
                                "\n          "
                            ),
                            _c("FontAwesomeIcon", {
                              attrs: {
                                icon: option.selected ? _vm.faCheck : _vm.faPlus
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        option.productUrl
                          ? _c(
                              "div",
                              { staticClass: "product-upsell__link-btn" },
                              [
                                _c("i", {
                                  staticClass: "far fa-shopping-cart link--icon"
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "link link--underline link--icon",
                                    attrs: { href: option.productUrl }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.lang.VehiclePage
                                            .TyreBuyActionButton
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  }
                ),
                0
              )
            ]
          ),
          _vm._v(" "),
          _vm.imageGalleryOpen
            ? _c("ImageGallery", {
                attrs: { images: _vm.selectedImages },
                on: { close: _vm.onCloseImageGallery }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }