import Axios from '../../axios';
import { formatYYYYMMDD } from '../../utils/dates';
import gtm from "../../utils/gtm";

import { l10n } from '../../utils/l10n';

export default {
  state: {
    lang: {
      General: l10n.General,
      App: l10n.ServiceAgreement,
    },
    regNr: '',
    lookupResult: null,
    agreementResult: null,
    lookupInvalid: false,
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    additionalInfo: {
      mileage: {value: 1500, label: "1500 mil"},
      months: {value: 36, label: "36 månader"},
      currentMileage: null
    },
    selectedDepartment: null,
    departments: [
      {
        label: "Jönköping", value: "bilsalj.jonkoping.ov@holmgrensbil.se",
      },
      {
        label: "Kalmar", value: "bilsalj.kalmar.dv@holmgrensbil.se",
      },
      {
        label: "Karlskrona", value: "bilsalj.karlskrona.vg@holmgrensbil.se",
      },
      {
        label: "Linköping", value: "bilsalj.linkoping.gg@holmgrensbil.se",
      },
      {
        label: "Norrköping", value: "bilsalj.norrkoping.cv@holmgrensbil.se",
      },
      {
        label: "Nyköping", value: "bilsalj.nykoping.kv@holmgrensbil.se",
      },
      {
        label: "Nässjö", value: "bilsalj.nassjo.sv@holmgrensbil.se",
      },
      {
        label: "Skövde", value: "bilsalj.skovde.pv@holmgrensbil.se",
      },
      {
        label: "Vetlanda", value: "bilsalj.vetlanda.bg@holmgrensbil.se",
      },
      {
        label: "Vimmerby", value: "bilsalj.vimmerby.bg@holmgrensbil.se",
      },
      {
        label: "Värnamo", value: "varnamo.bilforsaljningbredastensvagen@holmgrensbil.se",
      },
      {
        label: "Växjö", value: "bilsalj.vaxjo.sv@holmgrensbil.se",
      },
      {
        label: "Örebro", value: "bilsalj.orebro.kg@holmgrensbil.se",
      }
    ],
    submitError: false,
    submitted: false,
    get noPackages() {
      return this.lookupResult?.hasPackage === false;
    },
    get hasPackages() {
      return this.lookupResult?.hasPackage === true;
    },
    get canFetchAgreement() {
      return this.additionalInfo.mileage?.value &&
      this.additionalInfo.months?.value &&
      this.additionalInfo.currentMileage?.length;
    },
    get hasInstances() {
      return this.instances?.length;
    },
    get instances() {
      return this.agreementResult?.instances;
    },
    get hasAdditionalProducts() {
      return this.additionalProducts?.length;
    },
    get hasSelectedAdditionalProducts() {
      return this.additionalProducts?.filter(a => a.selected).length;
    },
    get additionalProducts() {
      return this.agreementResult?.additionalProducts;
    },
    get shouldShowCustomerInfo() {
      return (this.lookupResult && this.noPackages) || (this.lookupResult && this.hasInstances);
    },
    get numberOfSelectedInstances() {
      return this.instances?.filter(i => i.selected).length;
    },
    get additionalProductsDisplay() {
      return this.additionalProducts?.filter(a => a.selected).map(a => a.name).join(', ');
    },
    get validCustomer() {
      return this.customer.firstName.length && this.customer.lastName.length;
    },
    get validEmail() {
      return this.customer.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    },
    get validPhone() {
      return this.customer.phone.length > 5;
    },
    get validDepartment() {
      if (this.hasPackages)
        return true;
      if (this.lookupResult?.brand == 'BMW' || this.lookupResult?.brand == 'MINI')
        return this.selectedDepartment;
      return true;
    },
    get readyForSubmit() {
      return this.canFetchAgreement &&
        this.validCustomer &&
        this.validEmail &&
        this.validDepartment &&
        this.validPhone;
    }
  },

  async lookupRegNr() {
    this.state.lookupInvalid = false;
    if (!this.state.regNr.match(/^\w{3}\d{2}(\w{1}|\d{1})$/g)) {
      this.state.lookupResult = null;
      return;
    }
    try {
      const res = await Axios.get(`/api/vehicleConfigurator/${this.state.regNr}/serviceAgreement/vehicle`);
      this.state.lookupResult = res.data;

    } catch(e) {
      this.state.lookupInvalid = true;
      this.state.lookupResult = null;
    }
  },

  async fetchAgreement() {
    try {
      const res = await Axios.get(`/api/vehicleConfigurator/${this.state.regNr}/serviceAgreement?mileage=${this.state.additionalInfo.mileage?.value}&months=${this.state.additionalInfo.months?.value}&currentMileage=${this.state.additionalInfo.currentMileage}`);

      this.state.agreementResult = this._transformAgreementResponse(res);

    } catch(e) {
      console.log(e);
      this.state.agreementResult = null;
    }
  },

  async calculate() {
    try {
      const res = await Axios.post(`/api/vehicleConfigurator/${this.state.regNr}/serviceAgreement/calculate`, this.state.agreementResult);
      this.state.agreementResult = this._transformAgreementResponse(res);

    } catch(e) {
      console.log(e);
      this.state.agreementResult = null;
    }
  },

  async submit() {
    this.state.submitError = false;
    try {
      const res = await Axios.post(`/api/vehicleConfigurator/${this.state.regNr}/serviceAgreement/request`, {
        serviceAgreement: this.state.agreementResult,
        mileage: this.state.additionalInfo.mileage?.value,
        months: this.state.additionalInfo.months?.value,
        currentMileage: parseInt(this.state.additionalInfo.currentMileage, 10),
        customerName: `${this.state.customer.firstName} ${this.state.customer.lastName}`,
        customerEmail: this.state.customer.email,
        customerPhone: this.state.customer.phone,
        selectedDepartmentEmail: this.state.selectedDepartment ? this.state.selectedDepartment.value : null
      });

      gtm.serviceAgreementSubmit({brandName: this.state.lookupResult.brand})
      this.state.submitted = true;

    } catch(e) {
      console.log(e);
      this.state.submitError = true;
    }
  },

  _transformAgreementResponse(res) {
    res.data.instances = res.data?.instances.map(i => {
      const date = new Date(this.state.lookupResult?.registrationDate);
      return {
        date: formatYYYYMMDD(new Date(date.setMonth(date.getMonth() + i.months ))),
        tooltip: i.lines.map(l => l.name).join('<br>'),
        ...i
      }
    });
    return res.data;
  }
}
