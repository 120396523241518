<template>
  <div>
    <div
      v-for="section in displaySections"
      :key="section.type"
      class="properties"
    >
      <div class="properties__item properties__item--header">
        <div class="properties__label">
          {{ getTitle(section) }}
        </div>
      </div>
      <div
        v-for="(line, index) in getSectionSummationLines(section.type)"
        :key="index"
        class="properties__item"
      >
        <div class="properties__label" v-html="line.label"></div>
        <div class="properties__value">
          <VcPriceTag
            v-if="line.price"
            :price="line.price"
            :showIfZero="line.price.showIfZero"
          ></VcPriceTag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { VcPriceTag } from "@holmgrensbil/vue-components";
import isDefined from "../../../utils/is-defined";
import SectionTypes from "./constants/SectionTypes";
import InputTypes from "./constants/InputTypes";
import FieldTypes from "./constants/FieldTypes";

export default {
  components: {
    VcPriceTag
  },
  props: {
    section: Object
  },
  computed: {
    ...mapState({
      lang: state => state.config.lang,
      sections: state => state.sections.originalItems,
      validCode: state => state.campaigncode?.validCode,
      campaignCode: state => state.campaigncode?.code
    }),

    ...mapGetters({
      fieldsBySection: "fields/bySection"
    }),

    displaySections() {
      return this.sections.filter(s => this.getSectionFields(s.type).length);
    }
  },
  methods: {
    getSectionFields(section) {
      const blacklist = [FieldTypes.RegDate];

      return this.fieldsBySection(section)
        .filter(field => isDefined(field.value))
        .filter(field => !blacklist.includes(field.type));
    },

    getSectionSummationLines(section) {
      const sectionFields = this.getSectionFields(section);
      const summationLines = [];
      const sentImages = sectionFields
        .filter(field => field.inputType === InputTypes.Image)
        .map(field => {
          return field.shortLabel || field.label;
        });
      const isImageSection = sentImages.length;

      sectionFields.forEach(field => {
        if (field.hiddenInSummary) {
          return;
        }
        if (
          (field.inputType === InputTypes.OptionBoxes ||
            field.inputType === InputTypes.OptionBoxesMulti) &&
          field.nestedOption
        ) {
          field.value.forEach(v => {
            field.options.forEach(fv => {
              fv.options.forEach(nestedValue => {
                if (v === nestedValue.id) {
                  summationLines.push({
                    label: nestedValue.heading,
                    price: nestedValue.price
                  });
                }
              });
            });
          });
        } else if (
          field.inputType === InputTypes.OptionBoxes ||
          field.inputType === InputTypes.OptionBoxesMulti
        ) {
          field.value.forEach(v => {
            const valueData = field.options.find(fv => v.name === fv.name);
            summationLines.push({
              label: valueData.heading,
              price: valueData.price
            });
          });
        } else if (
          field.inputType === InputTypes.SelectObject ||
          field.inputType === InputTypes.SelectObjectNumber
        ) {
          summationLines.push({ label: field.value.name });
        } else if (field.inputType === InputTypes.Image) {
          return;
        } else if (field.type === FieldTypes.CampaignCode) {
          if (this.validCode) {
            summationLines.push({
              label: `${this.campaignCode.campaignCode} / ${this.campaignCode.description}`
            });
          } else {
            summationLines.push({ label: "Nej" });
          }
          return;
        } else if (
          field.inputType === InputTypes.Time &&
          field.value === "00:00"
        ) {
          let timeOption = field.options.find(option =>
            option.times.some(time => time.start === "00:00")
          );
          let returnValue = timeOption
            ? "Tid: " + timeOption.times[0].name
            : "Tid: Lämna kl 07:00, hämta 16:00";
          summationLines.push({
            label: returnValue
          });
        } else {
          const fieldLabel = field.shortLabel || field.label;
          const labelValue = isImageSection
            ? `<span>${fieldLabel.toUpperCase()}:</span> <br> ${field.value}`
            : `${fieldLabel}: ${field.value}`;
          summationLines.push({
            label: labelValue
          });
        }
      });

      if (isImageSection) {
        const imagesLabel = "<span>INSKICKADE BILDER:</span><br>";
        summationLines.push({
          label: `${imagesLabel} ${sentImages.join(", ")}`
        });
      }

      return summationLines;
    },

    getTitle(section) {
      const obj =
        this.lang &&
        this.lang.App &&
        this.lang.App.Sections &&
        this.lang.App.Sections[section.type];
      return obj && obj.Title;
    }
  }
};
</script>
