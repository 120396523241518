var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.field.label
      ? _c("label", [_vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-row" },
      _vm._l(_vm.field.options, function(option) {
        return _c(
          "div",
          {
            key: option.value,
            staticClass: "form-row__col",
            class: !_vm.field.fullRow && "form-row__col--md-50"
          },
          [
            _c("NestedOptionBox", {
              attrs: {
                selected: _vm.optionIsSelected(option),
                heading: option.heading,
                smallText: option.headingMeta,
                expandText: option.subOptionsText,
                modalContent: option.modalContent,
                price: option.price,
                subOptions: option.subOptions,
                image: option.imageUrl,
                checkbox: _vm.multiselect,
                largeImage: option.largeImage,
                externalUrl: option.externalUrl
              },
              on: {
                click: function($event) {
                  return _vm.onClick(option)
                },
                subOptionChange: function($event) {
                  return _vm.onSubOptionChange(option, $event)
                }
              }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }